import React, { memo } from 'react';
import CookieConsent from 'react-cookie-consent';
import './cookie-component.scss';
import { cookiesExpiresPeriod } from '../../../constants/general/tag-status';

const CookieComponent = () => (
  <CookieConsent
    debug
    enableDeclineButton
    location="bottom"
    cookieName="YallaBannana"
    acceptOnScroll
    acceptOnScrollPercentage={50}
    buttonText="Allow all"
    containerClasses="containerCookie"
    declineButtonText="Decline"
    contentClasses="cookie-content"
    buttonWrapperClasses="btns-cookie"
    disableStyles
    expires={cookiesExpiresPeriod}
  >
    <h6 className="cookie-title">This website uses cookies</h6>
    <p className="cookie-text">
      We use cookies to analyze and enhance our web site experience, personalize content and ads, and provide
      social media features. Please review our
      {' '}
      <span className="cookie-policy">
        Cookie
        Policy
      </span>
      {' '}
      for more details.
    </p>
  </CookieConsent>
);
export default memo(CookieComponent);
