import React, { memo } from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import './visible-on-voucher.scss';
import s from '../CouponInfo/CouponInfo.module.scss';

const VisibleOnVoucher = ({ setCouponInputHandler, couponInput }) => {
  const onChangeHandler = (e, key) => {
    const { value } = e.target;
    setCouponInputHandler(key, value);
  };

  return (
    <section className="visible-on-voucher">
      <h5 className="visible-on-voucher__title">Only visible on the voucher:</h5>
      <Form.Item
        label="Booking phone"
        className="visible-on-voucher__booking-item"
      >
        <Input
          className={s.input}
          style={{
            height: '40px',
            border: '1px solid #EDEDED',
            borderRadius: '4px',
          }}
          maxLength={50}
          value={couponInput.bookingPhone}
          onChange={(e) => setCouponInputHandler('bookingPhone', e.target.value)}
        />
      </Form.Item>
      <div className="visible-on-voucher__add-info">
        <p>Additional Information</p>
        <Input.TextArea
          defaultValue={couponInput.additionalInformation}
          value={couponInput.additionalInformation}
          onChange={(e) => onChangeHandler(e, 'additionalInformation')}
          style={{
            border: '1px solid #EDEDED',
            borderRadius: '4px',
            minHeight: '134px',
          }}
        />
      </div>
    </section>
  );
};

VisibleOnVoucher.propTypes = {
  setCouponInputHandler: PropTypes.func.isRequired,
  couponInput: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default memo(VisibleOnVoucher);
