import React, { memo } from 'react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './user.module.scss';
import { King } from '../../../constants/icons';
import AvatarPlaceholder from '../../../assets/images/avatarPlaceholder.png';
import USERS_ROLES from '../../../constants/usersroles';
import useAuthAdmin from '../../../context/context-admin/useAuthAdmin';

const User = memo(({ collapsed }) => {
  const { userInfo } = useAuthAdmin();

  const profileImageUrl = userInfo?.userPicture
    ? `${process.env.REACT_APP_BUCKET_URL}/${userInfo?.userPicture?.key}`
    : AvatarPlaceholder;

  return (
    <Link
      style={{
        color: '#333333',
      }}
      to="/admin/profile-merchant"
    >
      <div className={s.user}>
        <div className={s.avatar}>
          <img
            src={profileImageUrl}
            alt="avatar"
          />
          <div className={s.icon}><King /></div>
        </div>
        {
          !collapsed
        && (
          <div className={s.info}>
            <span className={s.name}>
              {userInfo?.firstName || ''}
              {' '}
              {userInfo?.lastName || ''}
            </span>
            <span className={s.position}>{USERS_ROLES.get(userInfo?.role)}</span>
          </div>
        )
        }
      </div>
    </Link>
  );
});

User.propTypes = {
  collapsed: PropTypes.bool.isRequired,
};

export default User;
