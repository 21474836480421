import React, { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import s from './Header.module.scss';
import {
  CartNav, CatalogNav, Catalogue, HomeNav, ProfileNav,
} from '../../constants/icons';
import LinksBlock from './LinksBlock';
import LogoBlock from './LogoBlock';
import Auth from './Auth/Auth';
import { CART, MAIN, PROFILE } from '../../constants/Routes';
import mobileCondition from '../../constants/mobileCondition';
import useCouponsInCartCount from './hooks/useCouponsInCartCount/useCouponsInCartCount';
import CategoriesContext from '../Layout/context/categoriesContext';
import { clearWindowOffset } from '../../helpers/catalog-helpers/catalog-helpers';

const Header = () => {
  const count = useCouponsInCartCount();

  const navigate = useNavigate();

  const onLogoClick = () => {
    clearWindowOffset();
    navigate('');
    window.scrollTo({ top: 0 });
  };

  const mainClassName = `${s.main} ${mobileCondition && s.mobile}`;

  const containerClassName = `${s.container} ${mobileCondition && s.mobile}`;

  const { fetchAllCategoriesCouponsCallback } = useContext(CategoriesContext);

  return (
    <div className={mainClassName}>
      {!mobileCondition
        ? (
          <div className={containerClassName}>
            <LogoBlock onLogoClick={onLogoClick} />
            <LinksBlock />
            <div
              className={s.categories}
              onClick={fetchAllCategoriesCouponsCallback}
            >
              <Catalogue />
              <span>
                All Deals
              </span>
            </div>
            <Auth count={count} />
          </div>
        )
        : (
          <div className={s.barContainer}>
            <div className={s.wrapper}>
              <NavLink
                to={MAIN}
                replace
                className={({ isActive }) => `${s.navLink} ${isActive && s.active}`}
              >
                <HomeNav />
                <span>Home</span>
              </NavLink>
              <NavLink
                to="catalog"
                className={({ isActive }) => `${s.navLink} ${isActive && s.active}`}
              >
                <CatalogNav />
                <span>Catalog</span>
              </NavLink>
              <div className={s.cartLink}>
                <NavLink
                  to={CART}
                  className={({ isActive }) => `${s.navLink} ${isActive && s.active}`}
                >
                  <CartNav />
                  <span>Cart</span>
                </NavLink>
                <div className={s.couponsCount}>
                  {count}
                </div>
              </div>
              <NavLink
                to={PROFILE}
                className={({ isActive }) => `${s.navLink} ${isActive && s.active}`}
              >
                <ProfileNav />
                <span>Account</span>
              </NavLink>
            </div>
          </div>
        )}
    </div>
  );
};
export default Header;
