import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { REFUND_VOUCHER } from '../../../../api/profile/query';

const useCouponRefund = () => {
  const [selectedId, setSelectedId] = useState(null);

  const [refundCallback, { loading: refundLoading }] = useMutation(REFUND_VOUCHER, {
    variables: {
      voucherId: selectedId,
    },
  });

  const refundVoucher = useCallback((refundReason) => refundCallback({
    variables: {
      refundReason,
    },
  }), [refundCallback]);

  return {
    refundVoucher, refundLoading, setSelectedId,
  };
};

export default useCouponRefund;
