import React from 'react';
import './coupon-table.scss';
import TableCoupons from './TableCoupons';
import HeaderComponent from './Header';

const CouponTable = () => (
  <>
    <HeaderComponent />
    <section className="coupon-table">
      <TableCoupons />
    </section>
  </>
);

export default CouponTable;
