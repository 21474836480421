import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import s from './SecurityScan.module.scss';
import ScannerComponent from './ScannerComponent/ScannerComponent';

const SecurityScan = () => (
  <div className={s.main}>
    <div className={s.wrapper}>
      <ScannerComponent />
      <Link
        className={s.link}
        to="/security"
      >
        Cancel scanning
      </Link>
    </div>
  </div>
);

export default memo(SecurityScan);
