import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

const useSearchFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dropdownValueHandler = useCallback((value, keyForUpdate, keysForDelete = null) => {
    const prevParams = Object.fromEntries([...searchParams]);
    if (value) {
      keysForDelete?.forEach((key) => delete prevParams[key]);
      setSearchParams({ ...prevParams, [keyForUpdate]: value.value });
    } else {
      delete prevParams[keyForUpdate];
      setSearchParams(prevParams);
    }
  }, [searchParams, setSearchParams]);

  const dropdownCollectionHandler = useCallback((value, keysForUpdate, keysForDelete = null) => {
    const prevParams = Object.fromEntries([...searchParams]);
    if (value) {
      keysForDelete?.forEach((key) => delete prevParams[key]);
      keysForUpdate.forEach((key) => {
        if (value.value[key]) prevParams[key] = value.value[key];
      });
      setSearchParams(prevParams);
    } else {
      keysForUpdate.forEach((key) => delete prevParams[key]);
      setSearchParams(prevParams);
    }
  }, [searchParams, setSearchParams]);

  return {
    searchParams, setSearchParams, dropdownValueHandler, dropdownCollectionHandler,
  };
};

export default useSearchFilters;
