import React, { memo } from 'react';
import {
  Menu, Dropdown,
} from 'antd';
import './BtnDropdown.scss';
import PropTypes from 'prop-types';
import { More } from '../../../../../../constants/icons';

const BtnDropdown = ({ optionId, copyCouponOptions, deleteCouponOptions }) => {
  const duplicateOption = () => {
    copyCouponOptions(optionId);
  };
  const deleteOption = () => {
    deleteCouponOptions(optionId);
  };
  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={duplicateOption}
      >
        Duplicate
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={deleteOption}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="btnContainer">
      <Dropdown.Button
        overlay={menu}
        placement="bottomRight"
        icon={<More />}
        overlayClassName="dropdown"
      />
    </div>
  );
};

BtnDropdown.propTypes = {
  optionId: PropTypes.string.isRequired,
  copyCouponOptions: PropTypes.func.isRequired,
  deleteCouponOptions: PropTypes.func.isRequired,
};
export default memo(BtnDropdown);
