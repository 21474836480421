import React, { memo } from 'react';
import { getStoredUser, isUserStored } from '../../helpers/user-storage';
import { ROLE_CUSTOMER } from '../../constants/employ';
import ShowCodePage from '../SecurityVoucher/ShowCodePage';
import ShowSecurity from '../SecurityVoucher/ShowSecurity';
import useActivateOption from './hooks/useActivateOption';
import useGetVoucherOptionById from './hooks/useGetVoucherOptionById';
import Loader from '../Loader';
import s from '../SecurityVoucher/SecurityVoucher.module.scss';

const SecurityOption = () => {
  const isAuth = isUserStored() && getStoredUser() !== ROLE_CUSTOMER;

  const { loading, totalData } = useGetVoucherOptionById();

  const { activateCallback, activateOptionLoading } = useActivateOption();

  if (loading) return <Loader />;

  return (
    <div className={s.main}>
      {isAuth
        ? (
          <ShowSecurity
            totalData={totalData}
            activateLoading={activateOptionLoading}
            activateVoucher={activateCallback}
          />
        )
        : <ShowCodePage />}
    </div>
  );
};

export default memo(SecurityOption);
