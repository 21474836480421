import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { defaultCenter, getBrowserLocation } from '../../../../helpers/map/geo';
import MapComponent from './MapComponent';

const API_KEY = process.env.REACT_APP_MAP_KEY;
const libraries = ['places'];

const GoogleMapComponent = ({
  locations, styleMapWrapper, zoom, titleTargetLinkForMap, selectedAddress,
}) => {
  const [center, setCenter] = useState(locations[locations.length - 1].location || defaultCenter);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${API_KEY}`,
    language: 'en',
    libraries,
  });

  const setCenterHandler = useCallback((position) => {
    setCenter(position);
  }, []);

  useEffect(() => {
    if (!locations) {
      getBrowserLocation()
        .then((curLoc) => {
          setCenter(curLoc);
        })
        .catch((defLoc) => {
          setCenter(defLoc);
        });
    }
  }, [locations]);

  useEffect(() => {
    const coordinatesOfSelectedAddress = locations.find((locate) => locate.address === selectedAddress);
    setCenter(coordinatesOfSelectedAddress
      ? coordinatesOfSelectedAddress.location
      : locations[locations.length - 1].location || defaultCenter);
  }, [locations, selectedAddress]);

  return (
    <div>
      {isLoaded ? (
        <MapComponent
          center={center}
          locations={locations}
          zoom={zoom}
          styleMapWrapper={styleMapWrapper}
          setCenterHandler={setCenterHandler}
          titleTargetLinkForMap={titleTargetLinkForMap}
        />
      )
        : <div>Loading ...</div>}
    </div>
  );
};

GoogleMapComponent.propTypes = {
  locations: PropTypes.oneOfType([PropTypes.array]).isRequired,
  zoom: PropTypes.number,
  styleMapWrapper: PropTypes.oneOfType([PropTypes.object]),
  titleTargetLinkForMap: PropTypes.string.isRequired,
  selectedAddress: PropTypes.string.isRequired,
};

GoogleMapComponent.defaultProps = {
  zoom: null,
  styleMapWrapper: null,
};

export default memo(GoogleMapComponent);
