import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { setRoleUser } from '../../../helpers/user-storage';

export const AuthAdminContext = createContext(null);

export const AuthAdminProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null);

  const checkInAdmin = (newAdmin) => {
    setUserInfo(newAdmin);
    setRoleUser(newAdmin.role);
  };

  const signInAdmin = (newAdmin, cb) => {
    setUserInfo(newAdmin);
    cb?.();
  };

  const signOutAdmin = (cb) => {
    setUserInfo(null);
    cb?.();
  };

  const value = useMemo(() => ({
    userInfo, signInAdmin, signOutAdmin, checkInAdmin,
  }), [userInfo]);

  return (
    <AuthAdminContext.Provider value={value}>
      {children}
    </AuthAdminContext.Provider>
  );
};

AuthAdminProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
