import React from 'react';
import { Outlet } from 'react-router-dom';
import s from './RouteLayout.module.scss';

const RouteLayout = () => (
  <div className={s.main}>
    <Outlet />
  </div>
);

export default RouteLayout;
