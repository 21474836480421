import React from 'react';
import mobileCondition from '../../../constants/mobileCondition';
import s from './SecureBlock.module.scss';
import { MasterCard, Visa } from '../../../constants/icons';
import ElfsWidget from '../../ElfsightWidget';

const SecureBlock = () => {
  const secureClassName = `${s.secure} ${mobileCondition && s.mobile}`;

  const iconsClassName = `${s.icons} ${mobileCondition && s.mobile}`;

  return (
    <div className={secureClassName}>
      <span>Secure payments by</span>
      <div className={iconsClassName}>
        <MasterCard />
        <Visa />
      </div>
      <div>
        <ElfsWidget />
      </div>
    </div>
  );
};

export default SecureBlock;
