import React, { memo } from 'react';
import s from './UserTerms.module.scss';
import mobileCondition from '../../../constants/mobileCondition';

const UserTerms = () => {
  const wrapperClassName = `${s.wrapper} ${mobileCondition && s.mobile}`;

  const titleClassName = `${s.title} ${mobileCondition && s.mobile}`;

  const welcomeClassName = `${s.welcome} ${mobileCondition && s.mobile}`;

  const infoClassName = `${s.info} ${mobileCondition && s.mobile}`;

  return (
    <div className={wrapperClassName}>
      <span className={titleClassName}>
        Terms & conditions of use yallabanana website
      </span>
      <div className={welcomeClassName}>
        <span>Welcome to yallabanana website.</span>
        <span>
          Yallabanana is an online platform operated by Big Fish Online Services DMMC that provides exclusive
          offers and discounts thereby helping you in saving money the address of its Head Office is unit 225,
          DMMC Business Center, level 5, jewellery &complex 2, Dubai, UAE.
        </span>
        <span>
          We understand people’s needs and do our best to ensure the conditions of each deal are clear and
          transparent. Please carefully read and review these terms of use before you access or use the website.
        </span>
        <span>
          Once you access, you acknowledge that you have read, understood, and agree to these terms of use.
        </span>
      </div>
      <div className={infoClassName}>
        <div>
          <span>1. Elementary information you shall know</span>
          <span>
            1.1 You are reading a legal document which is the agreement between you, the customer (whom we refer to as
            &quot;you&quot;, &quot;your&quot; or the &quot;customer&quot; in this document), and us.
            You are agreeing to the terms of use that appearbelow, all of which are called the “agreement.”
            We refer to ourselves as &quot;yallabanana and its parent company The Big Fish Online Services
            DMMC (“The Big Fish Online Services DMMC”) &quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;
            in this document.
          </span>
          <span>
            1.2 The Big Fish Online Services DMMC provides a platform yallabanana (including this site, mobile
            applications, etc.) Where we, our local affiliates and other sellers (collectively, “sellers”) can list
            offers for vouchers, deals, goods, getaways, and other products (collectively, “items”). We provide a
            platform for sellers and you to negotiate and complete transactions. This means that in addition to this
            agreement, you will have other contractual relationships with different legal entities when you interact
            with the site.
          </span>
          <span>
            1.3 Whatever item you purchase via yallabanana online platform will be subject to the terms and conditions
            of that seller. The customer contract of sale will most likely be with a different legal entity.
            But please note that any contracts formed at the completion of a sale for items are solely between you
            and the seller (and not Big Fish Online Services DMMC, unless Big Fish Online Services DMMC is the seller).
            The seller is responsible for the sale and for dealing with any claims or any other issue arising out of or
            in connection with the contract between you and the seller. Please also note that in respect of purchases of
            a voucher, in addition to the contract of sale you will have with the seller of the voucher, there will also
            be a contract between you and the business that actually provides the products when you redeem your voucher
            with that business (the “merchant”). In respect of any vouchers, neither we nor any seller assumes
            responsibility for any contracts regarding the redemption of your voucher with a merchant. Finally, please
            understand that we are never the seller&apos;s or merchant’s agent
          </span>
          <span>
            1.4 We may provide a forum to submit or post user content, as well as send newsletters and other
            communications to you.
          </span>
          <span>
            1.5 You may access the site only through our app, standard web and mobile browsers and similar consumer
            applications, but not with any robot, spider, offline reader, site search/retrieval application or other
            manual or automatic device, tool, or process to retrieve, index, or data mine our site, via scraping,
            spidering or otherwise. As an exception, revocable at any time by us, operators of public search engines
            may use spiders to copy materials from the site for the sole purpose of and solely to the extent necessary
            for creating publicly available searchable indices of the materials, but not caches or archives of such
            materials.
          </span>
          <span>
            1.6 We reserve the right to prevent you from using the site and the services (or any part of them).
          </span>
          <span>
            1.7 Notwithstanding any other term of these terms of use, you shall be permitted to crawl, index, display
            or cache the contents of this site solely for the purposes of (a) displaying the site as part of search
            engine results, and (b) optimizing (through accelerated mobile pages, or otherwise) the delivery of the
            site to other users of the site.  Yallabanana reserves all rights, including copyright, in its html code
            and content (including code that is optimized for accelerated mobile pages) and nothing in these terms of
            use shall act as an express or implied waiver of any of yallabanana&apos;s rights, or its ability to enforce
            the terms of these terms of use
          </span>
        </div>
        <div>
          <span>
            2. Account registration with yallabanana
          </span>
          <span>
            2.1 In order to make a purchase an offer you must register an account with us, we will create an account
            or you based on the information provided to us in connection with the transaction (e.g. Your name address,
            email address, and other transaction information).  You may later claim this account by creating a password
            for the account.  Your account will provide you with easy access to print your voucher, view your past
            purchases, store any yallabanana credit for you and modify your preferences. We reserve the right to decline
            a new registration or to cancel an account at any time.  In regards to register will your good name, email
            address, and some other personal information. Once you register with yallabanana you don’t have to re-enter
            your information every time.
          </span>
          <span>
            2.2 You must keep the password confidential and immediately notify us if any unauthorized third party
            becomes aware of that password or if there is any unauthorized use of your email address or any breach of
            security known to you. You agree that we are not responsible or liable in the event that a person to whom
            your password is disclosed uses (and/or transacts via) our services and site. Please note that you are
            entirely responsible if you do not maintain the confidentiality of your password.
          </span>
          <span>
            2.3 An account must be registered with a valid personal email address that you access regularly. Any
            accounts which have been registered with someone else’s email address or with temporary email addresses may
            be closed without notice. We may require users to re-validate their accounts if we believe they have been
            using an invalid email address.
          </span>
          <span>
            2.4 The Big Fish Online Services DMMC may send you administrative and promotional emails. We may also
            send you information regarding your account activity and purchases, as well as updates about our site and
            services or other promotional offers. We may also send you information regarding purchases you’ve made.
          </span>
        </div>
        <div>
          <span>
            3. User content
          </span>
          <span>
            3.1 Yallabanana may provide account holders and visitors to the site various opportunities to submit or
            post reviews, opinions, advice, ratings, discussions, comments, messages, survey responses, and other
            communications, as well as files, images, photographs, video, sound recordings, musical works and other
            content and it may be submitted or posted through forums, bulletin boards, discussion groups, chat rooms,
            surveys, blogs or other communication facilities that may be offered or in connection with the site or
            services from time to time. You will not upload, post or otherwise make available on the site any material
            protected by copyright, trademark or other proprietary right without the express permission of the owner of
            the copyright, trademark or other proprietary right. The Big Fish DMMC does not have any express burden or
            responsibility to provide you with indications, markings or anything else that may aid you in determining
            whether the material in question is copyrighted or trademarked. You will be solely liable for any damage
            resulting from any infringement of copyrights, trademarks, proprietary rights or any other harm resulting
            from such a submission.
          </span>
          <span>
            3.2 Yallabanana.com is not responsible for, and no reliance should be placed, in any way on any user
            content posted, transmitted through or linked to the websites.
          </span>
          <span>
            3.3 The Big Fish DMMC reserves the right to refuse to share or display, move; or delete any user content
            posted on the website (yallabanana.com)
          </span>
          <span>
            3.4 Yallabanana updates the websites regularly and may change the user content at any time. Any of the user
            content may be out of date at any given time, and we are under no obligation to update such material.
          </span>
        </div>
        <div>
          <span>4. Modifications</span>
          <span>Yallabanana reserves the right to modify this agreement from time to time.</span>
        </div>
        <div>
          <span>5. Privacy policy</span>
          <span>
            Yallabanana may collects user’s personal information for the purpose of registering users for a ‘deal’ and
            for processing payment if the deal is released. Yallabanana may collect user’s personal information for
            future promotional and marketing purposes. In the event that a user’s correct and accurate personal
            information is not provided a registration for a deal will not be processed. Users are responsible for
            ensuring that their details remain current and accurate. A user’s personal information will also be used by
            yallabanana to contact the user to confirm payment has been processed for the deal, receive feedback and
            contact the user concerning the operation of the yallabanana’s service. Except as outlined above,
            yallabanana will not disclose a user’s personal information to any other third parties unless permission
            is granted by the user or it is required by law or to protect the interest of Big Fish DMMC. Users have the
            right to access and correct any personal information held by yallabanana.
          </span>
          <span>
            We don&apos;t store personal payment card details. The information is processed through the payment gateway
            (Stripe), from where we are permitted to check the status of the payment whether it&apos;s successful or
            unsuccessful.
          </span>
        </div>
        <div>
          <span>6. Reservations and cancellations</span>
          <span>
            The Big Fish DMMC is committed to providing superior quality services for all relevant parties involved.
          </span>
        </div>
        <div>
          <span>7. Copyright</span>
          <span>
            All material on this site (collectively “copyright material”) is protected by copyright unless otherwise
            noted and may not be used except as permitted in these terms and conditions or in the text on the site, and
            is protected under the law.
          </span>
        </div>
        <div>
          <span>8. Hyperlinks</span>
          <span>
            This site may contain links to other websites. The Big Fish DMMC is not responsible for and does not
            control the content of any website linked to or from the site. Any links to other websites are for your
            convenience only and yallabanana.com makes no warranty or representation regarding and, unless expressly
            stated by yallabanana.com on the site, does not endorse the owner/sponsor and the content of any linked
            websites. The Big Fish DMMC disclaims all warranties, express and implied, as to the accuracy, validity,
            legality or otherwise of any materials or information contained on such websites. Yallabanana.com neither
            warrants nor represents that a user’s use of any materials on linked websites will not infringe rights of
            third parties not owned by or affiliated with Big Fish DMMC
          </span>
        </div>
        <div>
          <span>9. Warranties</span>
          <span>
            The Big Fish DMMC assumes no responsibility, and will not be liable for any damages to or viruses that may
            infect your computer equipment or other property due to you accessing and/or use of the site or any linked
            websites, or your downloading any materials, data, text, images, video or audio from the site or any linked
            websites. Yallabanana assumes no responsibility for computer systems, hardware, software or program
            malfunctions or for other errors, failures, or delays in computer transmissions or network connections or
            for lost, late, mutilated, illegible, incomplete or misdirected information. Users must provide a valid
            email address.
          </span>
          <span>
            The Big Fish DMMC reserves the right, in its sole discretion, to cancel or suspend this program should any
            virus, bugs, or other causes beyond yallabanana’s control which corrupt the administration, security or
            proper function of this site. The material on this site may contain inaccuracies and typographical errors.
          </span>
          <span>
            Yallabanana.com does not warrant the accuracy or completeness of the material or the reliability of any
            advice, opinion, statement or other information displayed or distributed through the site. You acknowledge
            that any reliance on any such opinion, advice, statement, memorandum or information will be at your own
            risk.
          </span>
        </div>
        <div>
          <span>10. Review of postings & uploads</span>
          <span>
            10.1 You may send and receive electronic mail (“email”), engage in conferences and chats, download and
            upload files and otherwise use this site as permitted by this agreement, the operating policies of
            yallabanana.com and applicable law.
          </span>
          <span>
            10.2 Yallabanana.com reserves the right to block or remove communications or materials that it determines,
            in its sole discretion, to be abusive, libelous, defamatory or obscene, fraudulent, deceptive, or
            misleading, in violation of a copyright or trademark, or other intellectual property right of another is
            to encourage conduct that would be considered a criminal offence or give rise to civil liability or
            otherwise violate any law offensive or otherwise unacceptable; or determines to be harmful, offensive or
            otherwise in violation of this agreement or the operating policies of yallabanana.com for its users.
          </span>
          <span>
            10.3 Yallabanana will fully cooperate with any law enforcement authorities or court order requesting or
            directing yallabanana to disclose the identity of anyone posting such communications or materials.
            Statements made in websites, newsgroups, message boards, email, forums, conferences and chats reflect only
            the views of their authors, and do not necessarily reflect those of yallabanana.com.
          </span>
        </div>
        <div>
          <span>11. Advertisements</span>
          <span>
            Any advertisements may be delivered on our behalf by a third-party advertising company, which may, on our
            behalf, place or recognize a unique &quot;cookie&quot; on your browser.
          </span>
        </div>
        <div>
          <span>12. Term and termination</span>
          <span>
            Either users or yallabanana.com may terminate a user’s right to use the site at any time, with or without
            cause, upon notice. The clauses will survive any termination of this agreement.
          </span>
        </div>
        <div>
          <span>13. Liability limits</span>
          <span>
            Under no circumstances, including but not limited to negligence, the company shall not be liable for any
            damages of any kind arising from tort or any other thing, including but not limited to direct, indirect,
            incidental, special or consequential damages cause by:
          </span>
          <span>
            Any delay or partial or full failure by the company in performing any of its obligations towards subscribers
            or purchasers of any product has been sold or marketed through the application under the terms and
            conditions of using the product in the application or the company’s policy as a whole. The producer shall
            be responsible for the product without any liability on the company. Nevertheless, the company will make
            efforts to deliver the product on schedule.
          </span>
          <span>
            Any content posted, stored or uploaded by you or any third party in addition to any loss or damages
            occurred.
          </span>
          <span>
            Any errors, defamation, vituperating, deletion, lying, obscenity, pornographic material, profanity or
            illegal material that may be posted on the website.
          </span>
        </div>
        <div>
          <span>14. Liability release and indemnification</span>
          <span>
            You hereby release and discharge and agree to indemnify: a) yallabanana.com and any related body
            corporate, b) yallabanana.com ’s organizers, directors, officers, employees and agents; and c) the other
            participants and/or users (“released parties”), for any personal injury, illness or death, claims,
            liabilities or expenses of any kind, including reasonable attorney’s fees (and whether accruing to you,
            your heirs or personal representatives) that are caused or alleged to be caused in whole or in part by
            reason of an act or omission of any released party that arises out of or in connection with your use of
            the site, or resulting from any violation of this agreement or any activity related to your account
            (including infringement of third parties’ worldwide intellectual property rights or negligent or wrongful
            conduct) by you or any other person accessing yallabanana.com using your service account.
          </span>
          <span>
            In no event will yallabanana.com’s total liability to you for all damages, losses, causes of action
            (whether in contract or tort, including but not limited to negligence) exceed the amount paid by you,
            if any, for accessing this site.
          </span>
        </div>
        <div>
          <span>
            15.  Third-party rights
          </span>
          <span>
            The foregoing provisions regarding indemnification and waiver, release and limitation of liability
            are for the benefit of yallabanana.com and its officers, directors, employees, agents, licensors,
            suppliers and information providers. Each of these individuals or entities has the right to assert and
            enforce those provisions directly against you on its own behalf.
          </span>
        </div>
        <div>
          <span>
            16. Force majeure
          </span>
          <span>
            The Big Fish DMMC are not liable for any breach of obligations where there is hindrance or prevention
            from carrying out obligations by any cause outside their reasonable control, including but not limited to
            lightning, fire, flood, extreme or severe weather, strike, lock-out, labor dispute, act of god, war, riot,
            civil commotion, malicious damage, failure of any telecommunications or computer system, compliance with
            any law, accident (or by any damage caused by any of such events).
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(UserTerms);
