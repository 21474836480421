import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { DELETE_FILIAL_BRANCH_BY_ID_ADMIN, GET_ALL_MERCHANTS_ADMIN } from '../../../../../api/create-merchant/query';

const useDeleteFilialBranches = () => {
  const [deleteMerchantByIdAdmin, {
    loading: loadingDeleteMerchant,
    error: errorDeleteMerchant,
  }] = useMutation(DELETE_FILIAL_BRANCH_BY_ID_ADMIN, {
    refetchQueries: [GET_ALL_MERCHANTS_ADMIN],
  });

  const deleteFilialBranch = useCallback((id) => {
    deleteMerchantByIdAdmin({
      variables: {
        id,
      },
    });
  }, [deleteMerchantByIdAdmin]);

  return {
    deleteFilialBranch,
    loadingDeleteMerchant,
    errorDeleteMerchant,
  };
};

export default useDeleteFilialBranches;
