import PropTypes from 'prop-types';
import React, {
  memo, useContext, useEffect, useMemo,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import s from './CategoryItem.module.scss';
import SubCategories from '../../SubCategories/SubCategories';
import Icon from '../../Icon/Icon';
import CategoriesContext from '../../../Layout/context/categoriesContext';
import { ArrowDown } from '../../../../constants/icons';
import { HOT_DEALS } from '../../../../constants/admin-roles';

const CategoryItem = ({ categoryId }) => {
  const { scrollOnCouponsTop, setSearchParams } = useContext(CategoriesContext);

  const {
    categories,
    openedCategories,
    openCategoryDropdown,
    closeCategoryDropdown,
    setOpenedCategories,
  } = useContext(CategoriesContext);

  const categoryItem = categories.find(({ id }) => id === categoryId);
  const {
    title, couponsCount, subCategories, categoryPicture,
  } = categoryItem;

  const isOpen = openedCategories.some((id) => id === categoryId);

  const [searchParams] = useSearchParams();

  const isHotDeal = searchParams.get('isHotDeal');

  const fetchedCategoryId = searchParams.get('categoryId');
  const fetchedSubCategoryId = searchParams.get('subCategoryId');

  const isSubCategoryInCategoryExist = useMemo(() => categoryItem.subCategories
    .some(({ id }) => id === fetchedSubCategoryId), [fetchedSubCategoryId]);

  useEffect(() => {
    if (isSubCategoryInCategoryExist) {
      openCategoryDropdown(categoryId);
    }
  }, [openCategoryDropdown, categoryId, categoryItem, setOpenedCategories]);

  const toggleIsCategoryOpen = () => {
    if (isOpen) {
      closeCategoryDropdown(categoryId);
    } else {
      openCategoryDropdown(categoryId);
    }
  };

  const fetchCouponsByCategoryOnClick = (e) => {
    e.stopPropagation();
    const isClickable = couponsCount && !(fetchedCategoryId === categoryId);
    if (isClickable || title === HOT_DEALS) {
      scrollOnCouponsTop();
      setOpenedCategories([categoryId]);
      if (title === HOT_DEALS) {
        setSearchParams({ isHotDeal: true });
      } else {
        const params = Object.fromEntries([...searchParams]);
        delete params.isHotDeal;
        setSearchParams({ ...params, categoryId });
      }
    } else {
      toggleIsCategoryOpen();
    }
  };

  const isClickable = (couponsCount > 0 || subCategories.length > 0) || title === HOT_DEALS;

  const isColored = (fetchedCategoryId === categoryId || isSubCategoryInCategoryExist)
      || (isHotDeal && title === HOT_DEALS);

  const categoryClassname = `${s.item} ${isColored && s.active} ${isClickable && s.pointer}`;

  const arrowClassName = `${s.arrow} ${isOpen && s.active}`;

  return (
    <div
      className={categoryClassname}
      onClick={fetchCouponsByCategoryOnClick}
    >
      <div className={s.category}>
        <div className={s.container}>
          <Icon
            defaultIcon={categoryPicture?.icon}
            url={categoryPicture?.key}
            name={title}
          />
          <span className={s.title}>
            {title}
          </span>
        </div>
        {(!!couponsCount && subCategories.length > 0) && (
          <div
            onClick={toggleIsCategoryOpen}
            className={arrowClassName}
          >
            <ArrowDown />
          </div>
        )}
      </div>
      <SubCategories
        isOpen={isOpen}
        subCategories={subCategories}
      />
    </div>
  );
};

CategoryItem.propTypes = {
  categoryId: PropTypes.string.isRequired,
};

export default memo(CategoryItem);
