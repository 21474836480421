import React, { memo } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../../../../constants/icons';
import './modal.scss';
import LogoBlock from '../../../../Header/LogoBlock';
import PriceVoucherWithVat from './PriceVoucherWithVat/PriceVoucherWithVat';
import PriceVoucherWithoutVat from './PriceVoucherWithoutVat';

const ModalPayment = ({
  orderData, closeModalHandler, isOpenModal, applyVat,
}) => {
  const {
    electronicInvoice,
    orderNumber,
    paymentDate,
    paymentID,
    vouchers,
    totalOrderPrice,
  } = orderData;
  const mappedVouchers = vouchers?.map(({
    id, voucherNumber, voucherPrice, voucherVat,
  }) => (
    <div
      key={id}
      className="bodyModalReceipt"
    >
      <div className="bodyModalReceipt__finally">Voucher ID</div>
      <div className="bodyModalReceipt__data">{voucherNumber}</div>
      <div className="bodyModalReceipt__finally">Description</div>
      <div className="bodyModalReceipt__data">Yallabanana voucher</div>
      <div className="bodyModalReceipt__finally">Quantity</div>
      <div className="bodyModalReceipt__data">1</div>
      {
        applyVat
          ? (
            <PriceVoucherWithVat
              voucherNumber={voucherNumber}
              voucherVat={voucherVat}
              voucherPrice={voucherPrice}
            />
          )
          : <PriceVoucherWithoutVat voucherPrice={voucherPrice} />
      }
    </div>
  ));

  return (
    <Modal
      title={<LogoBlock />}
      className="modal-receipt"
      centered
      visible={isOpenModal}
      onCancel={closeModalHandler}
      width={460}
      cancelText="Cancel"
      closeIcon={<CloseIcon />}
      footer={null}
    >
      <div className="info-static">
        <span className="invoice-static">
          THE BIG FISH ONLINE SERVICES DMCC
        </span>
        <span className="invoice-static">
          Office 2402, Floor 24th
          <br />
          Fortune Executive Tower, Cluster T, JLT, Dubai, UAE
        </span>
        <span className="invoice-static__bold">
          TAX REGISTRATION NUMBER-100245288400003
        </span>
      </div>

      <div className="invoice-payment">
        <span className="invoice-payment_item">
          Payment ID
        </span>
        <span className="invoice-payment_item__data">
          {paymentID}
        </span>
        <span className="invoice-payment_item">
          Order ID
        </span>
        <span className="invoice-payment_item__data">
          {orderNumber}
        </span>
        <span className="invoice-payment_item">
          Invoice date
        </span>
        <span className="invoice-payment_item__data">
          {paymentDate}
        </span>
        <span className="invoice-payment_item">
          Tax invoice #
        </span>
        <span className="invoice-payment_item__data">
          {electronicInvoice}
        </span>
      </div>

      <div className="invoice-vouchers">
        {mappedVouchers}
        <div className="invoice-total">
          <span className="invoice-total__subtitle">Total, AED</span>
          <span className="invoice-total__data">{totalOrderPrice}</span>
        </div>
      </div>
    </Modal>
  );
};

ModalPayment.propTypes = {
  orderData: PropTypes.shape({
    paymentDate: PropTypes.string.isRequired,
    electronicInvoice: PropTypes.string.isRequired,
    orderNumber: PropTypes.string.isRequired,
    paymentID: PropTypes.string.isRequired,
    totalOrderPrice: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    vouchers: PropTypes.array.isRequired,
  }).isRequired,
  applyVat: PropTypes.bool.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
};

export default memo(ModalPayment);
