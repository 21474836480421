import { gql } from '@apollo/client';

export const FIND_ALL_CUSTOMERS = gql`
    query findAllCustomers (
        $offset: Float, 
        $limit: Float, 
        $sortOrder:  String, 
        $ordersCountFilter: Float, 
        $searchQuery: String
    ) {
        findAllCustomers(
            offset: $offset, 
            limit: $limit, 
            sortOrder: $sortOrder, 
            ordersCountFilter: $ordersCountFilter, 
            searchQuery: $searchQuery
        ) {
            users {
                userNumber
                id
                firstName
                lastName
                email
                role
                phone
                ordersCount
            }
            count
        }
    }
`;

export const EXPORT_CUSTOMERS_TO_EXCEL = gql`
    query exportCustomersToExcel( 
        $sortOrder:  String, 
        $ordersCountFilter: Float, 
        $searchQuery: String
    ) {
        exportCustomersToExcel( 
            sortOrder: $sortOrder, 
            ordersCountFilter: $ordersCountFilter, 
            searchQuery: $searchQuery
        ) {
            customersExcelKey
            message
        }
    }
`;
