import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { Form, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PROFILE_ACCOUNT_INFO_SCHEMA } from '../../../../constants/profileValidatorSchema/validatorSchema';
import useSendMainInfo from '../../hooks/gql/useSendMainInfo';
import Loader from '../../../Loader';
import useGetAccountInfo from '../../hooks/useGetAccountInfo';
import validatorForm from '../../../../helpers/validator';
import EditInput from '../EditInput';
import BtnsBlock from '../../../Common_components/BtnsBlock';
import PhoneInputComponent from '../../../Common_components/PhoneInputComponent';

const { Item } = Form;

const EditMainInformation = () => {
  const {
    userData,
    getUserDataLoading,
  } = useGetAccountInfo();

  const { updateUserLoading, updateUserSelfHandler, updateUserError } = useSendMainInfo();

  const navigate = useNavigate();

  const [dataAccount, setDataAccount] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dialCode: 971, // ae
    countNumberInPhone: 12, // ae
  });
  const [allErrors, setAllErrors] = useState(null);

  const sendDataHandler = useCallback(() => {
    const {
      id, firstName, lastName, phone, dialCode, countNumberInPhone, ...rest
    } = dataAccount;

    const objForCheckErrors = phone
      ? {
        firstName: firstName && firstName.trim(),
        lastName: lastName && lastName.trim(),
        phone,
        countNumberInPhone,
      }
      : {
        firstName: firstName && firstName.trim(),
        lastName: lastName && lastName.trim(),
      };

    const { errors } = validatorForm(objForCheckErrors, PROFILE_ACCOUNT_INFO_SCHEMA);

    if (!Object.keys(errors).length) {
      updateUserSelfHandler({
        ...rest,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        phone,
      });
    } else {
      setAllErrors(errors);
    }
  }, [dataAccount, updateUserSelfHandler]);

  const cancelHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const changeDataAccount = useCallback((key, value) => {
    setDataAccount((prev) => ({
      ...prev,
      [key]: value,
    }));
    setAllErrors((prev) => ({
      ...prev,
      [key]: '',
    }));
  }, []);

  useEffect(() => {
    if (!getUserDataLoading && userData) {
      const { phone } = userData;
      setDataAccount((prev) => ({
        ...prev,
        ...userData,
        countNumberInPhone: phone.length,
        phone: phone || '',
      }));
    }
  }, [getUserDataLoading]);

  useEffect(() => {
    if (updateUserError) {
      message.config({
        maxCount: 1,
      });
      message.open({
        type: 'error',
        content: 'Error, check your data!',
        duration: 3,
      });
    }
  }, [updateUserError]);

  if (getUserDataLoading) {
    return <Loader />;
  }

  return (
    <>
      {updateUserLoading && <Loader />}
      <h6 className="account-title">
        Edit Information
      </h6>
      <Form
        className="account-form"
        labelCol={{
          className: 'account_label-col',
          span: 7,
        }}
        wrapperCol={{
          className: 'account_wrapper-col',
          span: 17,
        }}
      >
        <EditInput
          label="Name"
          value={dataAccount?.firstName}
          inputVariant="input"
          callback={changeDataAccount}
          keyField="firstName"
        />
        <EditInput
          label="Last name"
          value={dataAccount?.lastName}
          inputVariant="input"
          callback={changeDataAccount}
          keyField="lastName"
        />
        <EditInput
          label="Email"
          value={dataAccount?.email}
          inputVariant="input"
          callback={changeDataAccount}
          error={allErrors?.email}
          keyField="email"
          disabledInput
        />
        <Item
          label="Phone"
          className="account-label-class"
        >
          <PhoneInputComponent
            allErrors={allErrors}
            stateInfo={dataAccount}
            setInfoFormHandler={setDataAccount}
            inputClass="input-phone-editInfo"
            flagAddClass="input-phone-editInfoFlag"
          />
        </Item>
        <BtnsBlock
          disableOkBtn={getUserDataLoading}
          cancelHandler={cancelHandler}
          sendDataHandler={sendDataHandler}
          cancelText="Cancel"
          okText="Save"
        />
      </Form>
    </>
  );
};

export default memo(EditMainInformation);
