import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';
import s from './MyCouponItem.module.scss';
import {
  Download, Refund, StopIcon, Voucher,
} from '../../../../constants/icons';
import formatDate from '../../../../helpers/cart-helpers/formatDate';
import useVoucherDownload from '../../../Profile/hooks/gql/useVoucherDownload';

const MyCouponItem = ({
  title, validDueDate, price, disabled, setIsActive, fetchOrderData, invoiceId, setSelectedId, id,
}) => {
  const { downloadAllVouchers, downloadVoucherInFile } = useVoucherDownload();

  const downloadVoucherCallback = useCallback(async (id) => {
    const file = await downloadAllVouchers(id);
    await downloadVoucherInFile([file]);
  }, []);

  const openReceiptModal = useCallback(async () => {
    await fetchOrderData(invoiceId);
    setIsActive('receipt');
  }, []);

  const openRefundModal = useCallback(() => {
    setSelectedId(id);
    setIsActive('refund');
  }, [id]);

  const itemClassName = `${s.item} ${disabled && s.disabled}`;

  const fixedPrice = `${price / 100} AED`;

  const fixedDate = validDueDate ? formatDate(validDueDate) : '-';

  const fixedDeadline = disabled ? 'Time is up' : fixedDate;

  return (
    <div className={itemClassName}>
      <div className={s.upper}>
        <span title={s.title}>{title}</span>
        <div className={s.info}>
          <div className={s.util}>
            <span className={s.subtitle}>Price</span>
            <span className={s.data}>{fixedPrice}</span>
          </div>
          <div className={s.util}>
            <span className={s.subtitle}>Voucher valid</span>
            {disabled
              ? (
                <div>
                  <StopIcon />
                  <span className={s.data}>{fixedDeadline}</span>
                </div>
              )
              : <span className={s.data}>{fixedDate}</span>}
          </div>
        </div>
      </div>
      <Divider className={s.divider} />
      <div className={s.buttons}>
        <button onClick={downloadVoucherCallback}>
          <Download />
          <span className={s.downloadSubtitle}>Voucher</span>
        </button>
        <div className={s.icons}>
          <div onClick={openReceiptModal}>
            <Voucher />
          </div>
          <div onClick={openRefundModal}>
            <Refund />
          </div>
        </div>
      </div>
    </div>
  );
};

MyCouponItem.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  validDueDate: PropTypes.string,
  disabled: PropTypes.bool,
  setIsActive: PropTypes.func.isRequired,
  fetchOrderData: PropTypes.func.isRequired,
  invoiceId: PropTypes.string.isRequired,
  setSelectedId: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

MyCouponItem.defaultProps = {
  disabled: undefined,
  validDueDate: '',
};

export default memo(MyCouponItem);
