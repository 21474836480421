import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { BUY_NOW_COUPON } from '../../../api/coupon-page-api/query';
import { GET_COUPONS_IN_CART_COUNT } from '../../../api/cart-api/query';
import { GET_USER_SELF } from '../../../api/query';

const useBuyNow = () => {
  const [callback, { loading: buyLoading }] = useMutation(BUY_NOW_COUPON, {
    refetchQueries: [GET_COUPONS_IN_CART_COUNT, GET_USER_SELF],
  });

  const buyNowCallback = useCallback(async (order) => {
    try {
      const res = await callback({ variables: { shoppingCartsCouponInput: [order] } });
      const { payUrl } = res.data.buyNow;
      window.location.replace(payUrl);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.message);
    }
  }, [callback]);

  return { buyNowCallback, buyLoading };
};

export default useBuyNow;
