import { useMutation } from '@apollo/client';
import { DeletePromoCodeById } from '../../../../api/PromoCodes/query';

const useDeletePromoCodeById = (refetch) => {
  const [deletePromoCodeById, { data, error }] = useMutation(DeletePromoCodeById);

  return {
    deletePromoCodeById: (id) => deletePromoCodeById({ variables: { id } }).then(() => refetch()),
    data,
    error,
  };
};

export default useDeletePromoCodeById;
