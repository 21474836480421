import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ACTIVATE_VOUCHER_OPTION } from '../../../api/vouchers/query';
import { MERCHANT_VOUCHERS_ACTIVE_STATUS } from '../../../constants/vouchers/vouchers';
import { SECURITY_FAILED, SECURITY_SUCCESS } from '../../../constants/Routes';
import { SCAN_METHOD } from '../../../constants/activateMethods';

const useActivateOption = () => {
  const { voucherOptionId } = useParams();

  const navigate = useNavigate();

  const [callback, { loading: activateOptionLoading }] = useMutation(ACTIVATE_VOUCHER_OPTION);

  const activateCallback = useCallback(async () => {
    try {
      await callback({
        variables: {
          activateMethod: SCAN_METHOD,
          status: MERCHANT_VOUCHERS_ACTIVE_STATUS,
          voucherOptionId,
        },
      });
      navigate(SECURITY_SUCCESS);
    } catch {
      navigate(SECURITY_FAILED);
    }
  }, [callback]);

  return { activateCallback, activateOptionLoading };
};

export default useActivateOption;
