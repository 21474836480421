import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getStoredUser } from '../../../helpers/user-storage';
import mobileCondition from '../../../constants/mobileCondition';

const RequiredAuthUser = ({ children }) => {
  const isAuth = getStoredUser();

  const redirectTo = mobileCondition ? 'profile/auth' : '/checkout/sign-up';

  if (!isAuth) {
    return <Navigate to={redirectTo} />;
  }

  return children;
};
RequiredAuthUser.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequiredAuthUser;
