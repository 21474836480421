import React from 'react';
import { useNavigate } from 'react-router-dom';
import s from './EmptyCart.module.scss';
import { EmptyCartIcon } from '../../../constants/icons';
import mobileCondition from '../../../constants/mobileCondition';

const EmptyCart = () => {
  const navigate = useNavigate();
  const continueShoppingCallback = () => navigate(-1);

  const emptyClassName = `${s.empty} ${mobileCondition && s.mobile}`;

  return (
    <div className={emptyClassName}>
      <span className={s.title}>Your cart is empty</span>
      <EmptyCartIcon />
      {!mobileCondition && (
        <>
          <span className={s.subtitle}>
            Please, add some items to your cart
          </span>
          <button onClick={continueShoppingCallback}>
            Continue Shopping
          </button>
        </>
      )}
    </div>
  );
};

export default EmptyCart;
