import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './button-component.scss';

const ButtonComponent = ({
  title, callback, addClass, iconComponent, disabled,
}) => {
  const onClickHandler = () => {
    callback();
  };
  return (
    <Button
      icon={iconComponent}
      className={`buttonComponent ${addClass}`}
      onClick={onClickHandler}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};
ButtonComponent.propTypes = {
  title: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  addClass: PropTypes.string,
  iconComponent: PropTypes.node,
  disabled: PropTypes.bool,
};
ButtonComponent.defaultProps = {
  addClass: '',
  iconComponent: <PlusOutlined />,
  disabled: false,
};
export default memo(ButtonComponent);
