import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ModalFileDownload from '../../Common_components/ModalFileDownload';
import useExportVouchers from '../hooks/gql/useExportVouchers';

const VouchersModal = ({ isOpenModal, closeModalHandler }) => {
  const customersExcelKey = useExportVouchers();

  return (
    <ModalFileDownload
      isOpenModal={isOpenModal}
      closeModalHandler={closeModalHandler}
      titleDocument=" Vouchers.xls"
      customersExcelKey={customersExcelKey}
    />
  );
};
VouchersModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
};

export default memo(VouchersModal);
