import React, { memo, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import s from './Layout.module.scss';
import Header from '../Header';
import Footer from '../Footer';
import useCouponsCount from '../../hooks/useCouponsCount/useCouponsCount';
import useSignModals from '../../hooks/useSignModals/useSignModals';
import useFetchUserQuery from '../../hooks/useFetchUserQuery/useFetchUserQuery';
import UserContext from './context/userContext';
import CountContext from './context/countContext';
import mobileCondition from '../../constants/mobileCondition';
import CategoriesContext from './context/categoriesContext';
import useFetchCategoriesContext from '../Catalog/hooks/useFetchCategoriesContext/useFetchCategoriesContext';
import ScrollToTopWrapper from '../CommonInfoLayout/ScrollToTopWrapper';
import ForceUpdateWrapper from '../ForceUpdateWrapper/ForceUpdateWrapper';

const Layout = () => {
  const countContext = useCouponsCount();

  const modalContext = useSignModals();

  const categoriesContext = useFetchCategoriesContext();

  const contentClassName = `${s.content} ${mobileCondition && s.mobile}`;

  const {
    fetchUser, userData, fetchUserLoading, setUserData,
  } = useFetchUserQuery();

  const userContext = useMemo(() => ({
    fetchUser, userData, fetchUserLoading, setUserData, ...modalContext,
  }), [fetchUser, userData, fetchUserLoading, setUserData, modalContext]);

  // const googleClientId = `${process.env.REACT_APP_MAP_KEY}.apps.googleusercontent.com`;
  const googleClientId = '907740884820-t5slpa1244uad4b483jquod7nqrhr2fa.apps.googleusercontent.com';

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <UserContext.Provider value={userContext}>
        <CountContext.Provider value={countContext}>
          <CategoriesContext.Provider value={categoriesContext}>
            <ForceUpdateWrapper>
              <ScrollToTopWrapper>
                <div className={s.layout}>
                  {mobileCondition ? (
                    <>
                      <div className={contentClassName}>
                        <Outlet />
                      </div>
                      <Header />
                    </>
                  )
                    : (
                      <>
                        <Header />
                        <div className={contentClassName}>
                          <Outlet />
                        </div>
                        <Footer />
                      </>
                    )}
                </div>
              </ScrollToTopWrapper>
            </ForceUpdateWrapper>
          </CategoriesContext.Provider>
        </CountContext.Provider>
      </UserContext.Provider>
    </GoogleOAuthProvider>
  );
};

export default memo(Layout);
