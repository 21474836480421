import React from 'react';
import Page from '../../components/Page';
import PromoCodes from '../../components/PromoCodes';

const PromoCodesPage = () => (
  <Page>
    <PromoCodes />
  </Page>
);

export default PromoCodesPage;
