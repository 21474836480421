import React, { useContext, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import s from './LinksBlock.module.scss';
import CustomLink from './Link/Link';
import CategoriesContext from '../../Layout/context/categoriesContext';

const LinksBlock = () => {
  const containerRef = useRef(null);

  const [searchParams] = useSearchParams();

  const selectedId = searchParams.get('categoryId');

  const { categories } = useContext(CategoriesContext);
  const filteredCategories = categories && categories.filter(({ showInHeader }) => showInHeader);
  const mappedLinks = filteredCategories?.map(({ id, title }) => (
    <CustomLink
      key={id}
      id={id}
      title={title}
      isSelected={id === selectedId}
    />
  ));
  return (
    <div
      ref={containerRef}
      className={s.linksContainer}
    >
      {mappedLinks}
    </div>
  );
};

export default LinksBlock;
