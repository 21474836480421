import { regLink } from '../../../helpers/regularExpression/regularExpression';

export const a = 1;

export const COUPON_SCHEMA = {
  categoryId: {
    required: true,
    errorMessage: 'This field is required!',
  },
  prevImageKey: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [{
      errorMessage: 'This field is required!',
      ruleValidation: (item) => item !== null,
    }],
  },
  availableAmountInPeriod: {
    rules: [{
      errorMessage: 'Incorrect number!',
      ruleValidation: (item) => item === null || item === '' || +item > 0,
    }],
  },
  videoReviewUrl: {
    rules: [{
      errorMessage: 'Invalid link!',
      ruleValidation: (item) => !item || item.match(regLink),
    }],
  },
  createPrice: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [
      {
        errorMessage: 'Incorrect number!',
        ruleValidation: (item) => item > 0,
      },
    ],
  },
  filialBranches: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [{
      errorMessage: 'This field is required!',
      ruleValidation: (item) => item.length > 0,
    }],
  },
  merchantId: {
    required: true,
    errorMessage: 'This field is required!',
  },
  oldPrice: {
    required: true,
    rules: [
      {
        errorMessage: 'Incorrect number!',
        ruleValidation: (item) => item > 0,
      },
      {
        errorMessage: 'Old price can not be less than created price!',
        ruleProp: 'createPrice',
        ruleValidation: (item, ruleProp) => +item >= +ruleProp,
      },
    ],
    errorMessage: 'This field is required!',
  },
  discount: {
    rules: [
      {
        errorMessage: '> 100',
        ruleValidation: (item) => +item < 100,
      },
    ],
    errorMessage: 'required',
  },
  couponGoldenHours: {
    type: 'array',
    id: 'intervalId',
    value: 'startAt',
    rules: [
      {
        errorMessage: 'This fields are required!',
        ruleProp: 'endAt',
        ruleValidation: (item, ruleProp) => item && ruleProp,
      },
    ],
    errorMessage: 'required',
  },
  publishedDueDate: {
    required: true,
    errorMessage: 'This field is required!',
  },
  terms: {
    required: true,
    errorMessage: 'This field is required!',
  },
  title: {
    required: true,
    errorMessage: 'This field is required!',
  },
  couponOptionsForValid: {
    type: 'array2',
    rules: {
      title: {
        required: true,
        errorMessage: 'This field is required!',
      },
      /*      oldPrice: {
        required: true,
        rules: [
          {
            errorMessage: 'Incorrect number!',
            ruleValidation: (item) => item > 0,
          },
          {
            errorMessage: 'Old price can not be less than created price!',
            ruleProp: 'createPrice',
            ruleValidation: (item, ruleProp) => +item >= +ruleProp,
          },
        ],
        errorMessage: 'This field is required!',
      }, */
      createPrice: {
        required: true,
        errorMessage: 'This field is required!',
      },
      filialBranches: {
        required: true,
        errorMessage: 'This field is required!',
        rules: [{
          errorMessage: 'At least one address!',
          ruleValidation: (item) => item.length > 0,
        }],
      },
      discount: {
        rules: [
          {
            errorMessage: '> 100',
            ruleValidation: (item) => +item < 100,
          },
        ],
        errorMessage: 'required',
      },
    },
  },
};
