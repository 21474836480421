import { gql } from '@apollo/client';

const GET_GOOGLE_TOKEN = gql`
  mutation authGoogle($token: String!) {
      authGoogle(token: $token) {
          email
          role
          firstName
          lastName
          phone
          id
          status
          accessToken
          refreshToken
      }
  }
`;

export default GET_GOOGLE_TOKEN;
