import React from 'react';
import PropTypes from 'prop-types';
import s from './FollowIcon.module.scss';

const FollowIcon = ({ Icon }) => (
  <div className={s.icon}>
    <Icon />
  </div>
);

FollowIcon.propTypes = {
  Icon: PropTypes.elementType.isRequired,
};

export default FollowIcon;
