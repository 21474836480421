import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PageControls from './PageControls';
import usePromoCodes from './hooks/usePromoCodes';
import PromoCodesTable from './PromoCodesTable/PromoCodesTable';
import PromoCodeModalContainer from './PromocodeModalContainer/PromocodeModalContainer';
import Loader from '../Loader/Loader';

const PromoCodes = () => {
  const {
    deletePromoCodeById,
    promoCodes,
    count,
    loadingGetPromoCodes,
  } = usePromoCodes();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id') || '';
  const page = searchParams.get('page') || '1';

  const setIsOpenModal = () => id && id !== 'all-promo-codes';

  const [isAddModalVisible, setIsAddModalVisible] = useState(setIsOpenModal());

  const editModalPromoCodeHandler = useCallback((id) => {
    setSearchParams({
      ...Object.entries(searchParams),
      page,
      id,
    });
    setIsAddModalVisible(true);
  }, [page, searchParams, setSearchParams]);

  const closeAddModal = useCallback(() => {
    setSearchParams({
      ...Object.entries(searchParams),
      id: 'all-promo-codes',
      page,
    });
    setIsAddModalVisible(false);
  }, [page, searchParams, setSearchParams]);

  const createPromoCode = useCallback(() => {
    setSearchParams({
      ...Object.entries(searchParams),
      id: 'create-promo-code',
      page,
    });
    setIsAddModalVisible(true);
  }, [page, searchParams, setSearchParams]);

  if (loadingGetPromoCodes) {
    return <Loader />;
  }

  return (
    <>
      <PageControls
        title="Promo Codes"
        onAdd={createPromoCode}
      />
      <PromoCodesTable
        data={promoCodes}
        deletePromoCodeById={deletePromoCodeById}
        editModalPromoCodeHandler={editModalPromoCodeHandler}
        count={count}
      />
      {
        isAddModalVisible && (
          <PromoCodeModalContainer
            isOpenModal={isAddModalVisible}
            closeAddModal={closeAddModal}
          />
        )
      }
    </>
  );
};

export default PromoCodes;
