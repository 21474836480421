import { useMutation } from '@apollo/client';
import { DeleteCategory } from '../../../../../api/query';

const useDeleteCategory = (refetch) => {
  const [deleteCategory, { data, loading, error }] = useMutation(DeleteCategory);

  const deleteCat = (id) => {
    deleteCategory({
      variables: {
        id,
      },
    });
  };

  if (data) {
    refetch();
  }

  return {
    deleteCategory: deleteCat,
    loading,
    errorDeleteCategory: error,
    data,
  };
};

export default useDeleteCategory;
