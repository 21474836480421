import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import DeleteModalComponent from '../../Common_components/DeleteModalComponent';
import useDeleteEmploy from '../hooks/gql/useDeleteEmploy';
import Loader from '../../Loader';

const DeleteModalEmploy = ({
  isOpenModal, employees, closeModal, closeModalWindowEmployWhenDelete,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { loadingDeleteEmploy, deleteEmployByAdmin } = useDeleteEmploy();

  const id = searchParams.get('id') || '';
  const page = searchParams.get('page') || '1';

  const deleteEmploy = useCallback(async () => {
    try {
      await deleteEmployByAdmin(id);
      const newPage = (employees?.length === 1 || employees?.length === 2) ? +page - 1 : page;
      const finalPage = newPage === 0 ? 1 : newPage;
      setSearchParams({
        page: finalPage,
      });
      closeModalWindowEmployWhenDelete();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [closeModalWindowEmployWhenDelete, deleteEmployByAdmin, employees?.length, id, page, setSearchParams]);

  return (
    <>
      {loadingDeleteEmploy && <Loader />}
      <DeleteModalComponent
        isOpenModal={isOpenModal}
        closeModal={closeModal}
        closeModalWhenDelete={deleteEmploy}
      />
    </>
  );
};

DeleteModalEmploy.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModalWindowEmployWhenDelete: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  employees: PropTypes.oneOfType([PropTypes.array]),
};
DeleteModalEmploy.defaultProps = {
  employees: undefined,
};

export default memo(DeleteModalEmploy);
