import React from 'react';
import EditMerchant from '../EditMerchant';
import Loader from '../../../Loader/Loader';
import useGetMerchantById from '../../MerchantTable/hooks/gql/useGetMerchantById';

const EditMerchantContainer = () => {
  const { merchantData, loading } = useGetMerchantById();

  if (loading) {
    return <Loader />;
  }

  return (
    <EditMerchant
      merchantData={merchantData}
    />
  );
};

export default EditMerchantContainer;
