import { gql } from '@apollo/client';

export const FIND_ALL_ORDERS_COUPONS = gql`
    query findAllOrderCoupons (
        $offset: Float, 
        $limit: Float, 
        $search: String
        $dateRangeStart: DateTime,
        $dateRangeEnd: DateTime,
    ) {
        findAllOrderCoupons(
            offset: $offset, 
            limit: $limit, 
            search: $search,
            dateRangeStart: $dateRangeStart,
            dateRangeEnd: $dateRangeEnd,
        ) {
            orderCoupons {
                id
                amount
                price
                discount
                totalDiscount
                promoCode
                totalPromoDiscount
                promoCode
                total
                totalOldPrice
                totalDiscount
                order {
                    date
                    orderNumber
                    user {
                        userNumber
                    }
                }
            }
            count
        }
    }
`;

export const EXPORT_ORDERS_TO_EXCEL = gql`
    query exportOrdersToExcel(
        $dateRangeStart: DateTime,    
        $dateRangeEnd: DateTime,
        $search: String,
    ) {
        exportOrdersToExcel(
            dateRangeStart: $dateRangeStart,
            dateRangeEnd: $dateRangeEnd,
            search: $search,
        ) {
            ordersExcelKey
            message
        }
    }
`;
