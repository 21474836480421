import { useMutation } from '@apollo/client';
import { CreateCategory } from '../../../../../api/query';

const useCreateCategory = (parentCategoryId) => {
  const [createCategory, { data, loading, error }] = useMutation(CreateCategory);

  const createCat = async (title) => {
    const createCategoryInput = parentCategoryId ? { title, parentCategoryId } : { title };

    return createCategory({
      variables: {
        createCategoryInput,
      },
    });
  };

  return {
    createCategory: createCat,
    categoryData: data,
    loading,
    errorCreateCategory: error,
  };
};

export default useCreateCategory;
