import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  CREATE_BANNER_ADMIN,
  DELETE_BANNER_PICTURE_ADMIN, GET_ALL_BANNERS_ADMIN,
  UPDATE_BANNER_ADMIN,
  UPLOAD_BANNER_PICTURE,
} from '../../../../api/api-banners/query';

const useSendDataBanner = () => {
  const [searchParams] = useSearchParams();
  const idBanner = searchParams.get('id') || '';

  const [createBannerAdmin, { loading: createBannerLoading }] = useMutation(CREATE_BANNER_ADMIN, {
    refetchQueries: [GET_ALL_BANNERS_ADMIN],
  });

  const [uploadBannerPicture, { loading: uploadBannerPictureLoading }] = useMutation(UPLOAD_BANNER_PICTURE);

  const [updateBannerByIdAdmin, { loading: updateBannerLoading }] = useMutation(UPDATE_BANNER_ADMIN, {
    refetchQueries: [GET_ALL_BANNERS_ADMIN],
  });

  const [deleteBannerPicture] = useMutation(DELETE_BANNER_PICTURE_ADMIN);

  const createBannerAdminHandler = useCallback(async (data) => {
    const {
      bannerPicture, queue, title, ...restData
    } = data;

    if (idBanner === 'create-banner') {
      const { data: { createBannerAdmin: { id } } } = await createBannerAdmin({
        variables: {
          createBannerInput: {
            ...restData,
            position: Number(queue),
            title: title.trim(),
          },
        },
      });

      if (bannerPicture) {
        await uploadBannerPicture({
          variables: {
            bannerId: id,
            file: bannerPicture.key,
          },
        });
      }
    } else {
      const { type, ...rest } = restData;
      const { data: { updateBannerByIdAdmin: { id } } } = await updateBannerByIdAdmin({
        variables: {
          updateBannerInput: {
            ...rest,
            position: !queue ? undefined : Number(queue),
            title: title.trim(),
          },
          id: idBanner,
        },
      });

      if (!bannerPicture.key) {
        await deleteBannerPicture({
          variables: {
            bannerPictureId: bannerPicture.id,
          },
        });
      } else if (typeof bannerPicture?.key !== 'string') {
        await uploadBannerPicture({
          variables: {
            bannerId: id,
            file: bannerPicture.key,
          },
        });
      }
    }
  }, [createBannerAdmin, deleteBannerPicture, idBanner, updateBannerByIdAdmin, uploadBannerPicture]);

  return {
    createBannerLoading,
    createBannerAdminHandler,
    uploadBannerPictureLoading,
    updateBannerLoading,
  };
};

export default useSendDataBanner;
