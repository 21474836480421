import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { limit } from '../../constants/tableConstants';

const useLastPageChange = (data) => {
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const [fetchedData] = Object.values(data);
      const [items, count] = Object.values(fetchedData);
      if (items && count) {
        if (count > 0 && items.length === 0) {
          const newPage = Math.floor(count / limit) + 1;
          const prevParams = Object.fromEntries([...searchParams]);
          setSearchParams({ ...prevParams, page: newPage });
        }
      }
    }
  }, [data, searchParams]);
};

export default useLastPageChange;
