import React, { memo } from 'react';
import s from './Contacts.module.scss';
import mobileCondition from '../../../constants/mobileCondition';
import { LocationMarkIcon, MailIcon, PhoneIcon } from '../../../constants/icons';
import {
  infoBigFish, infoFaq,
  infoLocationAddress,
  infoLocationFull,
  infoLocationMap, infoMail, infoPhone, infoServiceTimeInfo, infoSubText,
  infoTradeLicense,
} from '../../../constants/commonInfo';

const Contacts = () => {
  const wrapperClassName = `${s.wrapper} ${mobileCondition && s.mobile}`;

  const titleClassName = `${s.title} ${mobileCondition && s.mobile}`;

  const contactsClassName = `${s.contacts} ${mobileCondition && s.mobile}`;

  const infoClassName = `${s.info} ${mobileCondition && s.mobile}`;

  const mapClassName = `${s.map} ${mobileCondition && s.mobile}`;
  return (
    <div className={s.main}>
      <div className={wrapperClassName}>
        <div className={contactsClassName}>
          <span className={titleClassName}>
            Contact Us
          </span>
          <div className={infoClassName}>
            <span>
              {infoBigFish}
            </span>
            <span>
              {infoTradeLicense}
            </span>
            {mobileCondition
              ? (
                <span>
                  <span className={s.textWithIcon}>
                    <LocationMarkIcon />
                    {infoLocationAddress}
                  </span>
                  <br />
                  <span className={s.subText}>
                    {infoLocationMap}
                  </span>
                </span>
              )
              : (
                <span className={s.textWithIcon}>
                  <LocationMarkIcon />
                  {infoLocationFull}
                </span>
              )}
            <span className={s.textWithIcon}>
              <PhoneIcon />
              {infoPhone}
            </span>
            <span className={s.textWithIcon}>
              <MailIcon />
              <a
                className={s.link}
                href="mailto:info@yallabanana.com"
              >
                {infoMail}
              </a>
            </span>
            <div>
              {
                infoServiceTimeInfo.map((info) => (
                  <div key={info}>
                    {info}
                  </div>
                ))
              }
            </div>
            {infoSubText}
            <span>
              {infoFaq}
            </span>
          </div>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d225.85483076231242!2d55.14902110562472!3d25.
          07892463618675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6ca7e7bf20c1%3A0x421864491009f7f2
          !2zRm9ydHVuZSBFeGVjdXRpdmUgLSBKdW1laXJhaCBMYWtlcyBUb3dlcn
        MgLSBEdWJhaSAtINCe0LHRitC10LTQuNC90LXQvdC90YvQtSDQkN
        GA0LDQsdGB0LrQuNC1INCt0LzQuNGA0LDRgtGL!5e0!3m2!1sen!2sen!4v1654600288315!5m2!1sen!2sen"
          title="office"
          width={mobileCondition ? '100%' : '600'}
          height={mobileCondition ? 'unset' : '450'}
          className={mapClassName}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
    </div>
  );
};

export default memo(Contacts);
