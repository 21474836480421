import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { BANNER_MAIN_PAGE_TYPE } from '../../../../constants/banners/bannerPageTypes';
import useSendDataBanner from '../useSendDataBanner';
import useGetBannerById from '../gql/useGetBannerById';

const useCreateBannerContainer = () => {
  const initCreateStateBanner = {
    title: '',
    description: '',
    link: '',
    page: BANNER_MAIN_PAGE_TYPE,
    queue: '1',
    type: '',
    bannerPicture: {
      id: '',
      bannerId: '',
      key: '',
    },
  };

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') || '';

  const {
    data, getBannerByIdHandler, loadingGetBanner,
  } = useGetBannerById();

  const {
    createBannerAdminHandler,
    createBannerLoading,
    uploadBannerPictureLoading,
    updateBannerLoading,
  } = useSendDataBanner();

  useEffect(() => {
    if (id && id !== 'create-banner' && id !== 'all-banners') {
      getBannerByIdHandler(id);
    }
  }, [getBannerByIdHandler, id]);

  return {
    bannerData: data || initCreateStateBanner,
    setBannerDataHandler: createBannerAdminHandler,
    createBannerLoading,
    uploadBannerPictureLoading,
    loadingGetBanner,
    updateBannerLoading,
  };
};

export default useCreateBannerContainer;
