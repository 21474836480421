import React, {
  memo, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import s from './Days.module.scss';
import './hours.scss';
import { days } from '../../../../constants/date-constant';
import Day from '../Day';

const Days = ({ hours, setHours, allErrors }) => {
  const initHours = useRef(hours);

  const changeIsCheckedIntervals = (isCheckedDay, dayOfWeek) => {
    setHours((prev) => ({
      ...prev,
      couponGoldenHours: prev.couponGoldenHours.map((interval) => (interval.dayOfWeek === dayOfWeek
        ? { ...interval, isCheckedDay } : interval)),
      couponGoldenHoursToCreate: prev.couponGoldenHoursToCreate.map((interval) => (interval.dayOfWeek === dayOfWeek
        ? { ...interval, isCheckedDay } : interval)),
      couponGoldenHoursToUpdate: prev.couponGoldenHoursToUpdate.map((interval) => (interval.dayOfWeek === dayOfWeek
        ? { ...interval, isCheckedDay } : interval)),
    }));
  };

  const setTimeIntervalForDay = useCallback((data) => {
    const findInterval = initHours.current.find((hour) => hour.intervalId === data.intervalId);

    setHours((prev) => {
      const { couponGoldenHoursToCreate: goldenHoursToCreate } = prev;

      const goldenHoursToCreateWithNew = goldenHoursToCreate.map((interval) => (interval.intervalId === data.intervalId
        ? { ...data } : interval));

      const goldenHoursToCreateWithSearchItem = goldenHoursToCreate
        .findIndex((interval) => interval.intervalId === data.intervalId) === -1
        ? [data, ...goldenHoursToCreate]
        : goldenHoursToCreateWithNew;

      return {
        ...prev,
        couponGoldenHours: hours.map((interval) => (interval.intervalId === data.intervalId
          ? { ...data } : interval)),

        couponGoldenHoursToCreate: !findInterval && data.startAt && data.endAt
          ? goldenHoursToCreateWithSearchItem
          : [...prev.couponGoldenHoursToCreate],

        couponGoldenHoursToUpdate: findInterval
          ? [...prev.couponGoldenHoursToUpdate, {
            ...findInterval,
            startAt: data.startAt,
            endAt: data.endAt,
          }] : [...prev.couponGoldenHoursToUpdate],
      };
    });
  }, [hours, setHours]);

  const addTimeIntervalForDay = useCallback((data) => {
    setHours((prev) => ({
      ...prev,
      couponGoldenHours: [...hours, data],
    }));
  }, [hours, setHours]);

  const deleteIntervalForDay = useCallback((id) => {
    const findInterval = initHours.current.find(({ intervalId }) => intervalId === id);

    setHours((prev) => ({
      ...prev,
      couponGoldenHours: hours.filter(({ intervalId }) => intervalId !== id),

      couponGoldenHoursToDelete: findInterval
        ? [...prev.couponGoldenHoursToDelete, findInterval]
        : [...prev.couponGoldenHoursToDelete],

      couponGoldenHoursToCreate: prev.couponGoldenHoursToCreate.filter(({ intervalId }) => intervalId !== id),

      couponGoldenHoursToUpdate: prev.couponGoldenHoursToUpdate.filter(({ intervalId }) => intervalId !== id),
    }));
  }, [hours, setHours]);

  const checkIsHaveInterval = (
    couponGoldenHours,
    dayOfWeek,
  ) => couponGoldenHours.filter((interval) => interval.dayOfWeek === dayOfWeek);

  return (
    <div className="hours">
      <div className={s.days}>
        {days.map((day) => (
          <Day
            key={day.dayOfWeek}
            day={day.day}
            dayOfWeek={day.dayOfWeek}
            setTimeIntervalForDay={setTimeIntervalForDay}
            deleteIntervalForDay={deleteIntervalForDay}
            addTimeIntervalForDay={addTimeIntervalForDay}
            errors={allErrors}
            intervals={checkIsHaveInterval(hours, day.dayOfWeek)}
            changeIsCheckedIntervals={changeIsCheckedIntervals}
          />
        ))}
      </div>
    </div>
  );
};

Days.propTypes = {
  hours: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setHours: PropTypes.func.isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
};
Days.defaultProps = {
  allErrors: null,
};

export default memo(Days);
