import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './Link.module.scss';
import CategoriesContext from '../../../Layout/context/categoriesContext';
import { clearWindowOffset } from '../../../../helpers/catalog-helpers/catalog-helpers';
import { HOT_DEALS } from '../../../../constants/admin-roles';

const CustomLink = ({ title, id, isSelected }) => {
  const { scrollOnCouponsTop, setOpenedCategories } = useContext(CategoriesContext);

  const linkRef = useRef(null);

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const cb = (entries) => {
      const [entry] = entries;
      setIsVisible(entry.intersectionRatio === 1);
    };

    const observer = new IntersectionObserver(cb, { threshold: 1 });

    observer.observe(linkRef.current);

    return () => {
      observer.unobserve(linkRef.current);
    };
  }, []);

  const selectedItemClassName = `${s.item} ${isSelected && s.selected}`;

  const itemClassname = `${selectedItemClassName} ${!isVisible && s.hidden}`;
  const navigateToCategoryOnClick = () => {
    if (!window.location.href.includes('coupon')) {
      scrollOnCouponsTop();
    } else {
      clearWindowOffset();
    }
    setOpenedCategories([id]);
  };

  const getLink = (title, id) => (title === HOT_DEALS ? '/?isHotDeal=true' : `/?categoryId=${id}`);

  return (
    <Link
      to={getLink(title, id)}
      state={{ isScroll: true }}
      ref={linkRef}
      onClick={navigateToCategoryOnClick}
      className={itemClassname}
    >
      {title}
    </Link>
  );
};

CustomLink.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default CustomLink;
