import React from 'react';
import PropTypes from 'prop-types';
import useGetMerchantInfo from './hooks/useGetMerchantInfo';
import MerchantsMaking from './MerchantsMaking';
import Loader from '../../Loader/Loader';

const EditMerchant = ({ merchantData }) => {
  const {
    contacts, filialBranches, __typename, id: idMerchant, ...restMerchantData
  } = merchantData;

  const initialCreateState = contacts.map((contact) => {
    const {
      __typename, phone, id, ...rest
    } = contact;
    return {
      ...restMerchantData,
      ...rest,
      employeeId: id,
      id: idMerchant,
      phone,
      countNumberInPhone: phone?.length,
    };
  });

  const subdivisionsInfoInit = filialBranches.map((filial) => {
    const { __typename, businessHours, ...restFilialBranches } = filial;

    const editBusinessHours = businessHours.map((hours) => {
      const {
        id, startAt, endAt, __typename, ...rest
      } = hours;

      const sliceStartAt = startAt.substring(0, 5);
      const sliceEndAt = endAt.substring(0, 5);

      return {
        ...rest,
        intervalId: id,
        startAt: sliceStartAt,
        endAt: sliceEndAt,
        isCheckedDay: true,
      };
    });
    return {
      ...restFilialBranches,
      couponGoldenHours: editBusinessHours,
      couponGoldenHoursToCreate: [],
      couponGoldenHoursToUpdate: [],
      couponGoldenHoursToDelete: [],
    };
  });

  const {
    stateInfo,
    setStateInfo,
    setStateInfoHandler,
    subDivisionsInfo,
    addSubDivisionsInfoHandler,
    sendDataHandler,
    deleteSubDivisionsInfoHandler,
    editSubDivisionsInfoHandler,
    loadingUpdate,
    isValidMerchantInfo,
    allErrors,
    errorUpdate,
  } = useGetMerchantInfo(initialCreateState[0], subdivisionsInfoInit);

  return (
    <>
      {loadingUpdate && <Loader />}
      <MerchantsMaking
        stateInfo={stateInfo}
        setStateInfo={setStateInfo}
        setStateInfoHandler={setStateInfoHandler}
        subDivisionsInfo={subDivisionsInfo}
        addSubDivisionsInfoHandler={addSubDivisionsInfoHandler}
        sendDataHandler={sendDataHandler}
        deleteSubDivisionsInfoHandler={deleteSubDivisionsInfoHandler}
        editSubDivisionsInfoHandler={editSubDivisionsInfoHandler}
        isValidMerchantInfo={isValidMerchantInfo}
        allErrors={allErrors}
        errorUpdate={errorUpdate}
      />
    </>
  );
};

EditMerchant.propTypes = {
  merchantData: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default EditMerchant;
