import React, { memo, useContext, useState } from 'react';
import { Input } from 'antd';
import s from './MobileOptions.module.scss';
import Modal from '../../Modal';
import { Cross } from '../../../constants/icons';
import CouponContext from '../context/CouponContext';
import MappedOptions from '../MappedOptions';
import MobileOptionItem from './MobileOptionItem';

const MobileOptions = () => {
  const [isActive, setIsActive] = useState(false);

  const [value, setValue] = useState('Select address');

  const { couponOptions } = useContext(CouponContext);

  const addresses = couponOptions.map(({ filialBranches }) => (filialBranches.map(({ address }) => (address)))).flat(1);

  const uniqueAddresses = [...new Set(addresses)];

  const [selectedValues, setSelectedValues] = useState(couponOptions);

  const onAddressChange = (value) => {
    const filteredValues = couponOptions
      .filter(({ filialBranches }) => filialBranches.some(({ address }) => value === address));
    setSelectedValues(value === 'All' ? couponOptions : filteredValues);
    setIsActive(false);
    setValue(value);
  };

  const mappedOptions = uniqueAddresses.map((a) => (
    <MobileOptionItem
      key={a}
      address={a}
      onOptionChange={onAddressChange}
    />
  ));

  return (
    <div className={s.main}>
      <span className={s.header}>Options</span>
      <Input
        className={s.input}
        readOnly
        value={value}
        onClick={() => setIsActive(true)}
      />
      <Modal
        isActive={isActive}
        hideModalHandler={() => setIsActive(false)}
      >
        <div className={s.optionsBlock}>
          <div className={s.head}>
            <span>Select address</span>
            <div
              className={s.icon}
              onClick={() => setIsActive(false)}
            >
              <Cross />
            </div>
          </div>
          <div className={s.options}>
            {mappedOptions}
          </div>
        </div>
      </Modal>
      {selectedValues && <MappedOptions options={selectedValues} />}
    </div>
  );
};

export default memo(MobileOptions);
