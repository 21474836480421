import React, { memo } from 'react';
import banan from '../../../assets/images/banan.png';

const CheckYourPassword = () => (
  <div className="sing-in-card">
    <div className="sing-in-card__image">
      <img
        src={banan}
        alt="img"
      />
    </div>
    <h1 className="sing-in-card__title">Check your email </h1>
    <div className="sing-in-card__text-section">
      <p className="sing-in-card__text">
        If this email address was used to create an account,
        instructions to reset your password will be sent to you. Please check your email.
      </p>
    </div>
  </div>
);

export default memo(CheckYourPassword);
