import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { CREATE_COUPON_COPY_ADMIN } from '../../../../../api/query';
import useFindAllCoupons from './useFindAllCoupons';

const useCreateCouponCopyByAdmin = () => {
  const { totalQuery } = useFindAllCoupons();

  const [createCouponCopyAdmin,
    { loading: loadingCreateCouponCopy, error }] = useMutation(CREATE_COUPON_COPY_ADMIN, {
    refetchQueries: [totalQuery()],
  });

  const createCopyCoupon = useCallback(async (id) => {
    try {
      await createCouponCopyAdmin({
        variables: {
          id,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [createCouponCopyAdmin]);

  return {
    createCopyCoupon,
    loadingCreateCouponCopy,
    errorDeleteCoupon: error,
  };
};
export default useCreateCouponCopyByAdmin;
