import React, { memo } from 'react';
import blogPreview from '../../../assets/images/blog_preview.png';
import blogPreview2 from '../../../assets/images/blog_preview2.jpg';

import s from './Blog.module.scss';
import mobileCondition from '../../../constants/mobileCondition';

const Blog = () => {
  const wrapperClassName = `${s.wrapper} ${mobileCondition && s.mobile}`;

  const titleClassName = `${s.title} ${mobileCondition && s.mobile}`;
  const subTitleClassName = `${s.subTitle} ${mobileCondition && s.mobile}`;

  const infoClassName = `${s.info} ${mobileCondition && s.mobile}`;

  const replicaClassName = `${s.replica} ${mobileCondition && s.mobile}`;

  return (
    <div className={wrapperClassName}>
      <div>
        <img
          className={s.preview}
          src={blogPreview}
          alt="Preview for blog page"
        />
        <div className={infoClassName}>
          <h3 className={titleClassName}>
            Discover the Best Affordable Deals in Dubai and other Emirates:
            Your Ultimate Guide to Saving Money!
          </h3>
          <span className={replicaClassName}>
            <i>
              &quot;Sup! Have you heard about YallaBanana.com?&quot;
            </i>
          </span>
          <span className={replicaClassName}>
            <i>
              &quot;Hehe.. What is it about? Selling Bananas?&quot;
            </i>
          </span>
          <span className={replicaClassName}>
            <i>
              &quot;Nope. It’s all about saving. YallaBanana has the best discount coupons & promo codes.
              They got great deals on indoor, outdoor, and entertainment activities in Dubai, Abu Dhabi &
              other Emirates. They&apos;re great for saving money!&quot;
            </i>
          </span>
          <span className={replicaClassName}>
            <i>
              &quot;WOW! What luck! Tell me more of YallaBanana&apos;s discount coupons!&quot;
            </i>
          </span>
          <span>
            The United Arab Emirates (UAE) is often regarded as a top travel destination because of its reputation
            as a world-class luxury tourist destination. Many still feel that only the wealthy may enjoy luxury&apos;s
            pricey, excellent services, but this is no longer the case. To enjoy the most luxurious venues,
            all you need is a plan, a budget, and YallaBanana discount coupons.
          </span>
          <span>
            Whether you&apos;re looking for an indoor or outdoor activity, YallaBanana.com has you covered with a
            large selection of coupon codes and deals. You may save a lot of money in Dubai & other Emirates
            on a wide variety of activities and interactions such as at the top of Burj Khalifa, Dubai Aquarium,
            Atlantis Aquaventure, Dolphinarium, Yas Waterworld, Ferrari World, Hot Air Balloon Ride over the Desert
            of Dubai, Helicopter Ride, Dhow Cruise, Desert Safari and many more.
          </span>
          <span>
            Get ready for an extraordinary water sport experience with YallaBanana coupons that will leave you with
            memories to last a lifetime. Discover the thrill of gliding through crystal-clear waters with our
            exhilarating kayaking adventures, unleash your inner superhero with Fly Board and Jetovator experience,
            gather your friends and get ready for a laughter-filled water adventure on our thrilling banana boat rides.
          </span>
          <span>
            Looking for the best breakfast offers in Dubai? How about a mouthwatering breakfast with a stunning Burj
            Khalifa view? Or maybe you&apos;re more interested in lunch offers in Dubai? We&apos;ve got you covered
            with our scrumptious lunch deals with the same breathtaking view!
          </span>
          <span>
            Our desert safari Dubai deals offer a range of exhilarating activities, including a thrilling quad bike
            ride, a leisurely camel ride, and adrenaline-pumping sandboarding! Be mesmerized by the captivating belly
            dance performances and indulge in a sumptuous dinner buffet under the stars. Don&apos;t miss our desert
            safari Dubai promo for an unforgettable adventure that combines the best of the desert with
            world-class entertainment.
          </span>
          <span>
            YallaBanana.com is the best resource for cheap coupons and amazing promos, whether you&apos;re looking for
            the best restaurant deals, spa experiences, massage offers, amazing vacation packages or any other
            service or product.
          </span>
          <span>
            Habibi, spend less and get everything “Saving money is our duty!”
          </span>
        </div>
      </div>
      <div>
        <img
          className={s.preview}
          src={blogPreview2}
          alt="Preview for blog page"
        />
        <div className={infoClassName}>
          <h3 className={titleClassName}>
            Best Indoor Activities in Dubai for the Entire Family
          </h3>
          <span>
            Dubai is famous for fun indoor places for families, especially when it's very hot outside.
            You can find many exciting things to do inside. Let's take a look at some fun indoor activities in
            Dubai that are perfect for both kids and grown-ups.
          </span>
          <span>
            Dubai's variety of family-friendly indoor activities guarantees that there's never a dull moment,
            These are some of the indoor family activities in Dubai that are enjoyable for all ages.
          </span>
          <h4 className={subTitleClassName}>
            1. Enjoy winter wonders at Ski Dubai
          </h4>
          <span>
            Ice and snow in the desert? Mall of the Emirates Ski Dubai presents 22,500 sqm of ski slopes, parks,
            ice sculpture caves, and penguin encounters—each inch covered by real snow. Enjoy all kinds of winter
            fun in -4°C temperatures at this Alpine setting, with five slopes of varying difficulties, bumper cars,
            bobsledding, and snowboarding thrills. It is an indoor activity in Dubai for families.
          </span>
          <span>
            It provides a range of activities suitable for the entire family. From snowboarding and tobogganing to
            simply playing in the snow, Ski Dubai offers a cool respite and is a perfect example of indoor activities
            in Dubai during the summer.
          </span>
          <h4 className={subTitleClassName}>
            2. Choose your adventure at IMG Worlds of Adventure
          </h4>
          <span>
            One of the biggest indoor theme parks globally features thrilling rides, attractions, and entertainment
            areas centered around well-known characters from Cartoon Network and Marvel Comics.  KidZania gets top
            marks from children. An edutainment favorite, kids aged four to 16 can enter this 7,000-scale replica of
            a city to get a feel for real-world jobs and experiences, with fun at the forefront. They'll roleplay
            a heroic firefighter, master chef, television presenter, doctor, or more while learning the importance
            of decision-making, teamwork, and creativity.
          </span>
          <h4 className={subTitleClassName}>
            3. Dubai Aquarium & Underwater Zoo: An Aquatic Marvel
          </h4>
          <span>
            The Dubai Aquarium & Underwater Zoo, located in The Dubai Mall, is a mesmerising experience that
            takes you into the depths of the ocean.
          </span>
          <span>
            This indoor activity in Dubai during the summer offers amusing and instructive insights into the marine
            world and is perfect for families. It is home to thousands of aquatic animals, including the enormous
            crocodile known as King Croc and several types of sharks.
          </span>
          <h4 className={subTitleClassName}>
            4. Green Planet: Exploring the Tropics Indoors
          </h4>
          <span>
            Green Planet is an indoor biodome that has over 3,000 different plant and animal species, simulating
            the environment of a tropical rainforest. Let families discover the marvels of nature and learn
            about biodiversity and conservation in a safe setting.
          </span>
          <h4 className={subTitleClassName}>
            5. Museum of Illusions:
          </h4>
          <span>
            Visit the Museum of Illusions in Al Seef, Dubai, to take a deep look into a world where nothing is as
            it seems. This one-of-a-kind museum features more than 80 exhibits that challenge gravity and perception,
            providing visitors with an engaging visual and tactile experience. Families can enjoy the engagement
            and amazement of both kids and adults.
          </span>
          <h4 className={subTitleClassName}>
            6.TEPfactor: The Ultimate Adventure Challenge
          </h4>
          <span>
            TEPfactor, a unique adventure experience located in JBR, allows families to team up to solve puzzles,
            take on physical challenges, and make their way through hidden tunnels.
          </span>
          <h4 className={subTitleClassName}>
            7. Dining Experiences
          </h4>
          <span>
            There are many different places to eat in malls, ranging from fancy restaurants and food courts with
            different cuisines to fast-food chains. Malls are a popular choice for meals and social gatherings because
            of their variety, which fits a range of tastes and budgets.
          </span>
          <h4 className={subTitleClassName}>
            CONCLUSION
          </h4>
          <span>
            These are a few indoor activities in Dubai for families that offer a wide variety of activities to
            suit thrill-seeking and interest levels.  So, next time you're pondering how to spend quality time with
            your family in Dubai, remember that these indoor havens promise memorable experiences away from the
            scorching sun.  If you want adventure,r just a way to stay cool, Dubai's indoor activities in the summer
            have lots of fun things for your family to do.
          </span>
          <span>
            Get in touch with Yallah Banana for more details; we have plenty to offer!
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(Blog);
