export const bannersTypes = {
  CAROUSEL: 'CAROUSEL',
  STATIC_VERTICAL: 'STATIC_VERTICAL',
  STATIC_HORIZONTAL: 'STATIC_HORIZONTAL',
};

export const bannersTypeTitles = {
  CAROUSEL: 'Carousel (8:3)',
  STATIC_VERTICAL: 'Static vertical (9:10)',
  STATIC_HORIZONTAL: 'Static horizontal (10:1)',
};

export const bannersSelectItems = [
  { value: bannersTypes.CAROUSEL, title: bannersTypeTitles.CAROUSEL, key: '1' },
  { value: bannersTypes.STATIC_HORIZONTAL, title: bannersTypeTitles.STATIC_HORIZONTAL, key: '2' },
  { value: bannersTypes.STATIC_VERTICAL, title: bannersTypeTitles.STATIC_VERTICAL, key: '3' },
];

export const getImageAspect = (type) => {
  const { STATIC_VERTICAL, STATIC_HORIZONTAL } = bannersTypes;
  switch (type) {
  case STATIC_VERTICAL: return 9 / 10;
  case STATIC_HORIZONTAL: return 10;
  default: return 8 / 3;
  }
};
