import { useQuery } from '@apollo/client';
import imageMissing from '../../assets/imageMissing.png';
import GET_BANNERS from '../../../../../api/get-banners/query';
import { bannersTypes } from '../../../../../constants/banners/enums';

const { REACT_APP_BUCKET_URL } = process.env;

const useGetBannerById = (type = bannersTypes.CAROUSEL) => {
  const { loading: loadingGetBanners, error: errorGetBanners, data } = useQuery(GET_BANNERS, {
    variables: {
      page: 'MAIN',
      type,
    },
  });

  if (data) {
    const { banners } = data.getBanners;
    const bannersData = banners.reduce((acc, banner) => {
      const { bannerPicture, link, id } = banner;
      const routeIndex = link.indexOf('/coupon');
      const route = routeIndex > -1 ? link.slice(routeIndex) : link;
      return {
        ...acc,
        [id]: {
          link: route,
          bannerPicture: bannerPicture ? `${REACT_APP_BUCKET_URL}/${bannerPicture.key}` : imageMissing,
        },
      };
    }, {});

    return {
      errorGetBanners,
      loadingGetBanners,
      bannersData,
    };
  }

  return {
    errorGetBanners,
    loadingGetBanners,
  };
};

export default useGetBannerById;
