import React, { memo, useContext, useState } from 'react';
import s from './MobileCategories.module.scss';
import Loader from '../Loader';
import MobileCategoryItem from './MobileCategoryItem';
import mobileCatalogContext from '../MobileCatalogWrapper/mobileCatalogContext';

const MobileCategories = () => {
  const { getCategories, mobileCategoriesLoading } = useContext(mobileCatalogContext);

  const [opened, setOpened] = useState([]);

  const mappedCategoryItems = getCategories.length > 0 && getCategories
    .filter(({ isPublished }) => isPublished)
    .map(({
      couponsCount, id, subCategories, title, categoryPicture,
    }) => {
      const isCategoryOpened = opened.findIndex((openedId) => id === openedId) > -1;
      return (
        <MobileCategoryItem
          key={id}
          iconKey={categoryPicture.key}
          id={id}
          title={title}
          couponsCount={couponsCount}
          setOpened={setOpened}
          isOpened={isCategoryOpened}
          subCategories={subCategories}
        />
      );
    });

  if (mobileCategoriesLoading) return <Loader />;

  return (
    <div className={s.main}>
      <div className={s.wrapper}>
        <span className={s.title}>Catalog</span>
        {mappedCategoryItems}
      </div>
    </div>
  );
};

export default memo(MobileCategories);
