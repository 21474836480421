import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import s from './ButtonsAction.module.scss';
import ButtonComponent from '../../../Common_components/ButtonComponent';

const ButtonsAction = ({ sendData }) => {
  const createCouponHandler = () => {
    sendData();
  };
  const navigate = useNavigate();
  const cancelHandler = () => {
    navigate(-1);
  };

  return (
    <div className={s.container}>
      <ButtonComponent
        title="Cancel"
        callback={cancelHandler}
        addClass={s.disabled}
        iconComponent={null}
      />
      <ButtonComponent
        title="Save"
        iconComponent={null}
        callback={createCouponHandler}
      />
    </div>
  );
};

ButtonsAction.propTypes = {
  sendData: PropTypes.func.isRequired,
};

export default ButtonsAction;
