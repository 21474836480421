import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';
import s from './CommonInfoLayout.module.scss';
import Subscribe from '../Catalog/Subscribe';
import mobileCondition from '../../constants/mobileCondition';
import MobileLayoutHeader from '../Catalog/MobileLayoutHeader';
import Footer from '../Footer';

const CommonInfoLayout = () => {
  const layoutClassName = `${s.layout} ${mobileCondition && s.mobile}`;

  return (
    <div className={s.wrapper}>
      <div className={layoutClassName}>
        {mobileCondition && <MobileLayoutHeader />}
        <Outlet />
      </div>
      <Subscribe />
      {mobileCondition && <Footer />}
    </div>
  );
};

export default memo(CommonInfoLayout);
