import React, { memo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MOBILE_CATALOG } from '../../constants/Routes';

const CatalogRedirect = ({ children }) => {
  const [searchParams] = useSearchParams();

  const stringifiedParams = searchParams.toString();

  const subCategoryId = searchParams.get('subCategoryId');
  const categoryId = searchParams.get('categoryId');

  const isAnyParams = !!stringifiedParams && (subCategoryId || categoryId);

  const navigateUrl = isAnyParams && `${MOBILE_CATALOG}?${stringifiedParams}`;

  if (navigateUrl) return <Navigate to={navigateUrl} />;

  return children;
};

CatalogRedirect.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(CatalogRedirect);
