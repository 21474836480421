import React, { memo } from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import style from '../../../ModalForm.module.scss';
import { Tied } from '../../../../../constants/promoCodes/promoCodes';
import SelectComponent from '../../../../Common_components/SelectComponent';

const TiedBlock = (props) => {
  const {
    allErrors,
    setPromoCodeInfoHandler,
    couponId,
    couponOptionId,
    tied,
    loadingGetOptions,
    loadingGetCoupons,
    coupons,
    optionsData,
  } = props;

  const changeTiedHandler = () => {
    setPromoCodeInfoHandler('tied', tied === Tied.COUPON ? Tied.OPTION : Tied.COUPON);
  };

  return (
    <>
      <Radio.Group
        name="tied"
        value={tied}
        onChange={changeTiedHandler}
        className={style.tiedRadio}
      >
        <Radio value={Tied.COUPON}>Coupon</Radio>
        <Radio value={Tied.OPTION}>Option</Radio>
      </Radio.Group>
      <div className={style.inputContainer}>
        <SelectComponent
          disabled={loadingGetCoupons}
          activeOption={couponId}
          keyName="couponId"
          options={coupons}
          onSelectHandler={setPromoCodeInfoHandler}
          errors={allErrors?.couponId}
        />
        {allErrors && allErrors.couponId
          && !couponId
          && <span className={style.errorText}>{allErrors.couponId}</span>}
      </div>
      <div className={style.inputContainer}>
        <SelectComponent
          disabled={tied === Tied.COUPON || loadingGetOptions}
          activeOption={couponOptionId}
          keyName="couponOptionId"
          options={optionsData}
          onSelectHandler={setPromoCodeInfoHandler}
          errors={allErrors?.couponOptionId}
        />
        {allErrors && tied === Tied.OPTION && allErrors.couponOptionId
          && !couponOptionId
          && <span className={style.errorText}>{allErrors.couponOptionId}</span>}
      </div>
    </>
  );
};

TiedBlock.propTypes = {
  tied: PropTypes.string.isRequired,
  loadingGetOptions: PropTypes.bool.isRequired,
  loadingGetCoupons: PropTypes.bool.isRequired,
  setPromoCodeInfoHandler: PropTypes.func.isRequired,
  couponId: PropTypes.string,
  couponOptionId: PropTypes.string,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
  coupons: PropTypes.oneOfType([PropTypes.array]),
  optionsData: PropTypes.oneOfType([PropTypes.array]),
};
TiedBlock.defaultProps = {
  allErrors: null,
  coupons: undefined,
  optionsData: undefined,
  couponId: null,
  couponOptionId: null,
};

export default memo(TiedBlock);
