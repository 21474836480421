import React, { useCallback } from 'react';
import { Typography } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import useUpdateCouponStatusAdmin from '../../hooks/gql/useUpdateCouponStatusAdmin';
import { roundingPrice } from '../../../../../constants/math';
import useDeleteCoupon from '../../hooks/gql/useDeleteCoupon';
import DropdownBtnComponent from '../../../../Common_components/DropdownBtnComponent';
import {
  FORMAT_DATE_TABLE,
  STATUS_TABLE_ACTIVE,
  STATUS_TABLE_DECLINED,
  STATUS_TABLE_ON_APPROVE,
} from '../../../../../constants/couponsAdmin';
import { getRoleUser } from '../../../../../helpers/user-storage';
import { MERCHANT_ADMIN, MERCHANT_SUPER_ADMIN, SUPER_ADMIN } from '../../../../../constants/admin-roles';
import { CATEGORY_ID_FILTER_KEY, SUB_CATEGORY_FILTER_KEY } from '../../../../../constants/general/filters';
import DropdownWithClearComponent
  from '../../../../Common_components/DropdownWithClearComponent';
import useCreateCouponCopyByAdmin from '../../hooks/gql/useCreateCouponCopyAdmin';
import useSearchFilters from '../../hooks/filters/useSearchFilters';
import DoubleDateTableWrapper from '../../../../Common_components/DoubleDateForTableWrapper';
import copyToClipboard from '../../../../../helpers/copyToClipboard';
import TooltipComponent from '../../../../Common_components/TooltipComponent';
import { CopyIcon } from '../../../../../constants/icons';

const useGetOnApproveColumn = (coupons, filterCategories, filterSubCategories, showMessageIdOfCouponWasCopied) => {
  const { updateCouponByAdmin } = useUpdateCouponStatusAdmin();
  const { deleteCouponByAdmin } = useDeleteCoupon();
  const { createCopyCoupon } = useCreateCouponCopyByAdmin();
  const navigate = useNavigate();

  const { searchParams, setSearchParams, dropdownValueHandler } = useSearchFilters();

  const page = searchParams.get('page') || '1';
  const status = searchParams.get('status') || STATUS_TABLE_ACTIVE;
  const role = getRoleUser();

  const filterCategoriesHandler = (value) => dropdownValueHandler(value, CATEGORY_ID_FILTER_KEY);

  const filterSubCategoriesHandler = (value) => dropdownValueHandler(value, SUB_CATEGORY_FILTER_KEY);

  const editCoupon = useCallback((id) => {
    navigate(`edit-coupon/:${id}`);
  }, [navigate]);

  const deleteCoupon = useCallback(async (id) => {
    try {
      await deleteCouponByAdmin(id);

      if (status === STATUS_TABLE_ON_APPROVE) {
        const newPage = coupons?.length === 1 ? +page - 1 : page;
        const finalPage = newPage === 0 ? 1 : newPage;
        setSearchParams({
          ...Object.entries(searchParams),
          status,
          page: finalPage,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [coupons, deleteCouponByAdmin, page, searchParams, setSearchParams, status]);

  const updateCouponStatusHandler = useCallback(async (id, status, isPublished) => {
    try {
      await updateCouponByAdmin(id, status, isPublished);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [updateCouponByAdmin]);

  const totalActionsArray = getRoleUser() === SUPER_ADMIN
    ? [{
      key: '0',
      title: 'Edit',
      callback: editCoupon,
    },
    {
      key: '1',
      title: 'Publish',
      callback: (id) => updateCouponStatusHandler(id, STATUS_TABLE_ACTIVE, true),
    },
    {
      key: '2',
      title: 'Decline',
      callback: (id) => updateCouponStatusHandler(id, STATUS_TABLE_DECLINED, false),
    },
    {
      key: '3',
      title: 'Delete',
      callback: deleteCoupon,
    }]
    : [{
      key: '0',
      title: 'Edit',
      callback: editCoupon,
    },
    {
      key: '1',
      title: 'Copy',
      callback: createCopyCoupon,
    },
    {
      key: '2',
      title: 'Decline',
      callback: (id) => updateCouponStatusHandler(id, STATUS_TABLE_DECLINED, false),
    },
    {
      key: '3',
      title: 'Delete',
      callback: deleteCoupon,
    }];

  const copiedId = useCallback(async (id) => {
    await copyToClipboard(id);
    showMessageIdOfCouponWasCopied(id);
  }, [showMessageIdOfCouponWasCopied]);

  const nameColumn = {
    title: 'Name',
    ellipsis: true,
    dataIndex: 'title',
    key: 'title',
    width: '20%',
    render: (text) => (
      <Typography.Text>
        {text}
      </Typography.Text>
    ),
  };

  const numberColumn = {
    title: 'Coupon ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
    render: (id) => (
      <TooltipComponent
        title={id}
        placement="bottomLeft"
        overlayClassName="tooltipAdminTableColumns maxWidthTooltip"
      >
        <div className="fieldWithIdAdminTable">
          <Typography.Text>
            {id.slice(0, 5)}
            ...
          </Typography.Text>
          <span
            className="copyIdAdminTablesIcon"
            onClick={() => copiedId(id)}
          >
            <CopyIcon />
          </span>
        </div>
      </TooltipComponent>
    ),
  };

  const dateColumn = {
    title: <DoubleDateTableWrapper />,
    dataIndex: 'createdAt',
    key: 'date',
    ellipsis: true,
    width: '13%',
    render: (date) => (
      <Typography.Text>
        {date ? moment(date).format(FORMAT_DATE_TABLE) : '-'}
      </Typography.Text>
    ),

  };

  const categoryColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Category</span>
        <DropdownWithClearComponent
          items={filterCategories}
          minWidth="280px"
          callback={filterCategoriesHandler}
        />
      </div>
    ),
    dataIndex: 'category',
    key: 'category',
    ellipsis: true,
    width: '13%',
    render: (category) => (
      <Typography.Text>
        {category.title}
      </Typography.Text>
    ),
  };

  const subCategoryColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Subcategory</span>
        <DropdownWithClearComponent
          items={filterSubCategories}
          minWidth="280px"
          callback={filterSubCategoriesHandler}
        />
      </div>
    ),
    dataIndex: 'subCategory',
    key: 'subCategory',
    ellipsis: true,
    width: '13%',
    render: (subCategory) => (
      <Typography.Text>
        {subCategory?.title || '-'}
      </Typography.Text>
    ),
  };

  const companyColumn = {
    title: 'Company name',
    dataIndex: 'merchant',
    key: 'merchant',
    ellipsis: true,
    width: '13%',
    render: (merchant) => (
      <Typography.Text>
        {merchant.title}
      </Typography.Text>
    ),
  };

  const couponPriceColumn = {
    title: 'Coupon price, AED',
    dataIndex: 'currentPrice',
    key: 'currentPrice',
    ellipsis: true,
    width: '13%',
    render: (currentPrice) => <Typography.Text>{roundingPrice(currentPrice / 100)}</Typography.Text>,
  };

  const actionsColumn = {
    key: 'actions',
    width: '5%',
    render: (text, record) => (
      <DropdownBtnComponent
        text={text}
        record={record}
        actions={totalActionsArray}
      />
    ),
  };

  if (role !== MERCHANT_ADMIN || role !== MERCHANT_SUPER_ADMIN) {
    return [
      nameColumn,
      numberColumn,
      dateColumn,
      categoryColumn,
      subCategoryColumn,
      companyColumn,
      couponPriceColumn,
      actionsColumn,
    ];
  }
  return [
    nameColumn,
    numberColumn,
    dateColumn,
    categoryColumn,
    subCategoryColumn,
    couponPriceColumn,
    actionsColumn,
  ];
};

export default useGetOnApproveColumn;
