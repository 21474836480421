import { useMutation } from '@apollo/client';
import { useGoogleLogin } from '@react-oauth/google';
import { useContext } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import GET_GOOGLE_TOKEN from '../../api/google/query';
import { ForceUpdateContext } from '../../components/ForceUpdateWrapper/ForceUpdateWrapper';
import { setAccessTokenUser, setRoleUser } from '../../helpers/user-storage';
import { SETTING_OF_COOKIES, TITLE_COOKIES } from '../../constants/general/tag-status';
import { ROLE_CUSTOMER } from '../../constants/employ';
import { LocationContext } from '../../components/MobileProfile/MobileNotAuthRedirect/MobileNotAuthRedirect';
import mobileCondition from '../../constants/mobileCondition';
import { MAIN } from '../../constants/Routes';

const useGoogleAuth = (form) => {
  const [callback] = useMutation(GET_GOOGLE_TOKEN);

  const navigate = useNavigate();

  const redirectPath = useContext(LocationContext);

  const { setIsRerenderNeed } = useContext(ForceUpdateContext);

  const googleAuthHandler = useGoogleLogin({
    onSuccess: async (res) => {
      try {
        const { data: { authGoogle: { accessToken, refreshToken, role } } } = await callback({
          variables: { token: res.access_token },
        });
        if (role === ROLE_CUSTOMER) {
          setRoleUser(role);
          setAccessTokenUser(accessToken);
          Cookies.set(TITLE_COOKIES, refreshToken, SETTING_OF_COOKIES);
          setIsRerenderNeed(true);
          if (mobileCondition) {
            navigate(redirectPath || MAIN);
          }
        } else {
          form.setFields([{ name: 'email', errors: ['You are not a customer'] }]);
        }
      } catch (e) {
        form.setFields([{
          name: 'email',
          errors: [e.message === 'User already exists'
            ? 'User already exist'
            : 'Some error occured'],
        }]);
      }
    },
  });

  return { googleAuthHandler };
};

export default useGoogleAuth;
