import React from 'react';
import { Typography } from 'antd';
import PhoneInput from 'react-phone-input-2';
import DropdownWithClearComponent
  from '../../../Common_components/DropdownWithClearComponent';
import {
  BUYERS_PURCHASES_FILTER_KEY, purchasesFilters, SORT_ORDER_FILTER_KEY,
} from '../../../../constants/general/filters';
import useSearchFilters from '../../../Coupon/CouponTable/hooks/filters/useSearchFilters';

const useGetColumnBuyers = () => {
  const { dropdownCollectionHandler } = useSearchFilters();

  const purchasesFilterHandler = (value) => dropdownCollectionHandler(
    value,
    [SORT_ORDER_FILTER_KEY, BUYERS_PURCHASES_FILTER_KEY],
  );

  return [
    {
      title: 'User ID',
      dataIndex: 'userNumber',
      key: 'userNumber',
      ellipsis: true,
      width: '8%',
      render: (userNumber) => (
        <Typography.Text>
          {userNumber}
        </Typography.Text>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'firstName',
      ellipsis: true,
      width: '16%',
      render: (firstName) => (
        <Typography.Text>
          {firstName}
        </Typography.Text>
      ),
    },
    {
      title: 'Last name',
      dataIndex: 'lastName',
      ellipsis: true,
      width: '16%',
      render: (lastName) => <Typography.Text>{lastName}</Typography.Text>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      render: (email) => <Typography.Text>{email}</Typography.Text>,
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      width: '20%',
      render: (phone) => (
        <Typography.Text>
          {
            phone ? (
              <PhoneInput
                value={phone}
                specialLabel=""
                disabled
                inputClass="telInput"
                buttonStyle={{
                  display: 'none',
                }}
                dropdownStyle={{
                  display: 'none',
                }}
                inputStyle={{
                  border: 'none',
                  cursor: 'text',
                }}
              />
            )
              : '-'
          }

        </Typography.Text>
      ),
    },
    {
      title: () => (
        <div
          className="dropdownWrapperHeader"
        >
          <span>Purchases</span>
          <DropdownWithClearComponent
            items={purchasesFilters}
            minWidth="180px"
            callback={purchasesFilterHandler}
          />
        </div>
      ),
      dataIndex: 'ordersCount',
      key: 'purchases',
      width: '20%',
      render: (ordersCount) => (
        <Typography.Text>
          {ordersCount || '-'}
        </Typography.Text>
      ),
    },
  ];
};

export default useGetColumnBuyers;
