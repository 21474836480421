import React, { memo } from 'react';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { CHECKOUT, CHECKOUT_SIGN_IN } from '../../../constants/Routes';
import s from './CheckoutSingUp.module.scss';
import useSignUpUser from '../hooks/gql/useSignUpUser';
import Loader from '../../Loader/Loader';

const CheckoutSingUp = () => {
  const [form] = Form.useForm();
  const { sendRegisterData, registerLoading, registerError } = useSignUpUser();

  const sendRegisterDataHandler = (values) => {
    const { email } = values;
    sendRegisterData(email);
  };

  return (
    <>
      {registerLoading && <Loader />}
      <div className={s.signUp}>
        <div className={s.singUpContainer}>
          <h6 className={s.title}>
            Join with email address
          </h6>
        </div>
        <Form
          form={form}
          className={s.form}
          onFinish={sendRegisterDataHandler}
          layout="vertical"

        >
          <Form.Item
            name="email"
            label="Email"
            className={s.item}
            validateStatus={registerError?.message && 'error'}
            help={registerError?.message}
            rules={[
              { required: true, message: 'Email is required' },
              {
                type: 'email',
                message: 'Email is not valid!',
              },
            ]}
          >
            <Input
              name="email"
              className={s.input}
            />
          </Form.Item>
          <Button
            className={s.submitButton}
            type="primary"
            htmlType="submit"
            disabled={registerLoading}
          >
            Sign Up
          </Button>
        </Form>
        <div className={s.terms}>
          <span>
            By creating an account, you are agreeing to our
          </span>
          <span className={s.rules}>
            Terms of Service and Privacy Policy
          </span>
        </div>
        <div className={s.or}>
          Or
        </div>
        <Link
          to={`/${CHECKOUT}/${CHECKOUT_SIGN_IN}`}
          className={s.signIn}
        >
          Sign in
        </Link>
      </div>
    </>
  );
};

export default memo(CheckoutSingUp);
