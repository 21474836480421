export const ADD_CATEGORY_SCHEMA = {
  name: {
    required: true,
    errorMessage: 'This field is required!',
  },
  prevImage: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [{
      errorMessage: 'This field is required!',
      ruleValidation: (item) => !!item,
    }],
  },
};

export const EDIT_CATEGORY_SCHEMA = {
  ...ADD_CATEGORY_SCHEMA,
  position: {
    required: true,
    errorMessage: 'Position must be set more than 0',
    rules: [{
      errorMessage: 'Position must be set more than 0',
      ruleValidation: (item) => item > 0,
    }],
  },
};
