import React from 'react';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import SettingsTabs from './SettingsTabs';

const Settings = () => (
  <Page>
    <PageControls
      title="Settings"
    />
    <SettingsTabs />
  </Page>
);

export default Settings;
