import React, {
  memo, useContext, useEffect, useRef,
} from 'react';
import { Button, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import Modal from '../../Modal';
import s from './SignUpModal.module.scss';
import { Cross } from '../../../constants/icons';
import useRegisterMutation from '../../../hooks/useRegisterMutation/useRegisterMutation';
import Loader from '../../Loader';
import UserContext from '../../Layout/context/userContext';
import GoogleButton from '../../Common_components/GoogleButton';

const SignUpModal = () => {
  const { isSignUpActive, setIsSignUpActive } = useContext(UserContext);

  const [form] = Form.useForm();
  const inputRef = useRef(null);

  const {
    sendRegisterData, registerData, registerLoading,
  } = useRegisterMutation();

  const sendDataCallback = async (values) => {
    try {
      await sendRegisterData(values);
    } catch (e) {
      form.setFields([{ name: 'email', errors: [e.message] }]);
    }
  };

  useEffect(() => {
    if (registerData) setIsSignUpActive(false);
  }, [registerData, setIsSignUpActive]);

  const showModalOnClick = () => {
    inputRef.current.focus({ cursor: 'end' });
    setIsSignUpActive(true);
  };

  const hideModalHandler = () => {
    form.resetFields();
    if (isSignUpActive) {
      setIsSignUpActive(false);
    }
  };

  return (
    <>
      {registerLoading && <Loader />}
      <Modal
        isActive={isSignUpActive}
        hideModalHandler={hideModalHandler}
      >
        <div className={s.singUpContainer}>
          <span className={s.title}>
            Sign Up
          </span>
          <div
            className={s.cross}
            onClick={hideModalHandler}
          >
            <Cross />
          </div>
        </div>
        <Form
          form={form}
          className={s.form}
          onFinish={sendDataCallback}
          layout="vertical"
        >
          <Form.Item
            name="email"
            label="Email"
            className={s.item}
            rules={[{ required: true, message: 'Email is required' }]}
          >
            <Input
              name="email"
              ref={inputRef}
              className={s.input}
            />
          </Form.Item>
          <Form.Item>
            <Button
              className={s.submitButton}
              type="primary"
              htmlType="submit"
              disabled={registerLoading}
            >
              Sign Up
            </Button>
          </Form.Item>
        </Form>
        <GoogleButton form={form} />
        <div className={s.terms}>
          <span>
            By creating an account, you are agreeing to our
          </span>
          <Link to="/common/terms">
            <span className={s.rules}>
              Terms of Service and Privacy Policy
            </span>
          </Link>
        </div>
      </Modal>
      <button
        className={s.button}
        onClick={showModalOnClick}
      >
        Sign Up
      </button>
    </>
  );
};

export default memo(SignUpModal);
