import { USER_ACTIVE_INACTIVE } from '../employ';

export const STATUS_EMPLOYEE_USER = 'USER';
export const STATUS_TYPE_ACTIVE = 'ACTIVE';
export const STATUS_TYPE_NOT_USED = 'NOT_USED';
export const STATUS_TYPE_DEACTIVATED = 'DEACTIVATED';
export const STATUS_TYPE_REFUND_REQUEST = 'REFUND_REQUEST';
export const STATUS_TYPE_REFUNDED = 'REFUNDED';

export const finalBackgroundColorTagStatus = (status) => {
  switch (status) {
  case STATUS_TYPE_ACTIVE: return 'green';
  case STATUS_TYPE_NOT_USED: return 'yellow';
  case STATUS_EMPLOYEE_USER: return 'gold';
  case STATUS_TYPE_DEACTIVATED: return '#EDEDED';
  case STATUS_TYPE_REFUNDED: return '#EDEDED';
  case USER_ACTIVE_INACTIVE: return '#EDEDED';
  case STATUS_TYPE_REFUND_REQUEST: return 'blue';
  default: return 'transparent';
  }
};

export const finalColorTagTextStatus = (status) => {
  switch (status) {
  case STATUS_TYPE_ACTIVE: return '#0BAE2F';
  case STATUS_TYPE_NOT_USED: return '#F9C718';
  case STATUS_TYPE_REFUND_REQUEST: return '#2db7f5';
  case STATUS_EMPLOYEE_USER: return '#d48806';
  default: return '#8E8E8E';
  }
};

export const finalTextTagStatus = (status) => {
  switch (status) {
  case STATUS_TYPE_ACTIVE: return 'Activated';
  case STATUS_TYPE_NOT_USED: return 'Not activated';
  case STATUS_TYPE_DEACTIVATED: return 'Deactivated';
  case STATUS_TYPE_REFUNDED: return 'Deactivated';
  case USER_ACTIVE_INACTIVE: return 'Deactivated';
  case STATUS_TYPE_REFUND_REQUEST: return 'Refund requested';
  case STATUS_EMPLOYEE_USER: return 'Current';
  default: return '';
  }
};

export const WORD_FOR_ACCEPT_DELETE_ACTION = 'DELETE';

export const heightMinusForAdminTables = 185; // 170
export const heightMinusForTableCoupons = 247; // 232

export const cookiesExpiresPeriod = 29;
export const TITLE_COOKIES = 'refreshToken';
export const SETTING_OF_COOKIES = {
  expires: cookiesExpiresPeriod,
  secure: true,
/*  sameSite: 'strict',
  path: '/', */
};
