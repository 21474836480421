import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { Table } from 'antd';
import usePromoCodesTable from '../hooks/usePromoCodesTable';
import { limit } from '../../../constants/tableConstants';
import { heightMinusForAdminTables } from '../../../constants/general/tag-status';
import useWindowSize from '../../../hooks/useWindowSize/useWindowSize';

const PromoCodesTable = ({
  data, deletePromoCodeById, editModalPromoCodeHandler, count,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [height] = useWindowSize();

  const { columns } = usePromoCodesTable(deletePromoCodeById, data, editModalPromoCodeHandler);
  const page = searchParams.get('page') || '1';

  const onChangePage = (page) => {
    setSearchParams({
      ...Object.entries(searchParams),
      page,
    });
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      className="adminTable"
      scroll={{
        y: height - heightMinusForAdminTables, x: 0,
      }}
      rowKey={(rec) => rec.id}
      pagination={{
        current: Number(page),
        pageSize: limit,
        total: count,
        position: ['bottomCenter'],
        size: 'small',
        showSizeChanger: false,
        onChange: (page) => onChangePage(page),
      }}
    />
  );
};

PromoCodesTable.propTypes = {
  editModalPromoCodeHandler: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
  deletePromoCodeById: PropTypes.func.isRequired,
};

export default PromoCodesTable;
