import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getStoredUser } from '../../../helpers/user-storage';
import { SIGN_IN } from '../../../constants/Routes';
import useGetInfoUser from '../../Sidebar/hooks/useGetInfoUser';
import Loader from '../../Loader';
import useAuthAdmin from '../../../context/context-admin/useAuthAdmin';
import { ROLE_CUSTOMER } from '../../../constants/employ';

const RequieredAuth = ({ children }) => {
  const { userInfo, loading, error } = useGetInfoUser();
  const isAuth = getStoredUser();
  const { checkInAdmin } = useAuthAdmin();

  if (loading) {
    return <Loader />;
  }

  if (!isAuth
      || !userInfo
      || !(userInfo?.role !== ROLE_CUSTOMER)
      || error) {
    return <Navigate to={SIGN_IN} />;
  }
  checkInAdmin(userInfo);
  return children;
};
RequieredAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequieredAuth;
