import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const MetaTagsDataComponent = ({
  title, description, url, imageUrl,
}) => (
  <Helmet>
    <meta
      property="og:title"
      content={title}
    />
    <meta
      property="og:type"
      content="article"
    />
    <meta
      property="og:description"
      content={description}
    />
    <meta
      property="og:url"
      content={url}
    />
    <meta
      property="og:image"
      content={imageUrl}
    />
    <meta
      name="twitter:card"
      content="summary_large_image"
    />
    <meta
      property="og:site_name"
      content="Yalla banana"
    />
    <meta
      name="twitter:image:alt"
      content={`${title} preview`}
    />
    <meta
      name="twitter:image"
      content={imageUrl}
    />
    <meta
      name="twitter:title"
      content={title}
    />
    <meta
      name="twitter:description"
      content={description}
    />
    <meta
      name="og:type"
      content="website"
    />
  </Helmet>
);

MetaTagsDataComponent.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
};

export default memo(MetaTagsDataComponent);
