import { useMemo } from 'react';

const useLocalCartCost = (items) => useMemo(() => {
  if (items) {
    const totalCouponsCost = items.reduce((
      acc,
      { currentPrice, quantity },
    ) => currentPrice * quantity + acc, 0);

    const totalCostOfCouponsOptionsInCart = items.reduce((acc, el) => {
      const { shoppingCartCouponOptions: couponOptions, quantity } = el;
      return couponOptions?.length > 0
        ? couponOptions
          .reduce((
            optionsAcc,
            { couponOption, amount },
          ) => quantity * (couponOption.currentPrice * amount) + optionsAcc, 0) + acc
        : 0 + acc;
    }, 0);

    const totalCostOfCouponsInCart = totalCouponsCost + totalCostOfCouponsOptionsInCart;

    const totalCouponsOldCost = items.reduce((
      acc,
      { oldPrice, quantity },
    ) => oldPrice * quantity + acc, 0);

    const totalOldCostOfCouponsOptionsInCart = items.reduce((acc, el) => {
      const { shoppingCartCouponOptions: couponOptions, quantity } = el;
      return couponOptions?.length > 0
        ? couponOptions
          .reduce((
            optionsAcc,
            { couponOption, amount },
          ) => quantity * (couponOption.oldPrice) * amount + optionsAcc, 0) + acc
        : 0 + acc;
    }, 0);

    const totalOldCostOfCouponsInCart = totalCouponsOldCost + totalOldCostOfCouponsOptionsInCart;

    const totalDiscountOfCouponsInCart = totalOldCostOfCouponsInCart - totalCostOfCouponsInCart;

    const totalCouponsVat = items.reduce((acc, { vatPrice, quantity }) => acc + vatPrice * quantity, 0);

    const totalCouponsOptionsVat = items.reduce((acc, el) => {
      const { shoppingCartCouponOptions: couponOptions, quantity } = el;
      return couponOptions?.length > 0
        ? couponOptions
          .reduce((
            optionsAcc,
            { couponOption: { vatPrice }, amount },
          ) => quantity * (vatPrice * amount) + optionsAcc, 0) + acc
        : 0 + acc;
    }, 0);

    const totalVatOfCouponsInCart = totalCouponsVat + totalCouponsOptionsVat;

    return {
      totalCostOfCouponsInCart,
      totalOldCostOfCouponsInCart,
      totalDiscountOfCouponsInCart,
      totalPromoDiscountOfCouponsInCart: 0,
      totalVatOfCouponsInCart,
    };
  }
  return null;
}, [items]);

export default useLocalCartCost;
