import React, { memo } from 'react';
import success from '../../../../../assets/images/success.png';
import './success-header.scss';

const SuccessHeader = () => (
  <div className="success-header">
    <div className="success-header__image">
      <img
        src={success}
        alt="success"
      />
    </div>
    <div className="success-header__text">
      <h6>Payment is successful</h6>
      <p>Thank you!</p>
    </div>
  </div>
);

export default memo(SuccessHeader);
