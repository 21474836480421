import React from 'react';
import { Typography } from 'antd';
import moment from 'moment/moment';
import DoubleDateForTableWrapper from '../../../Common_components/DoubleDateForTableWrapper';

const columnOrders = () => [
  {
    title: <DoubleDateForTableWrapper />,
    dataIndex: 'date',
    key: 'date',
    ellipsis: true,
    width: '13%',
    render: (date) => (
      <Typography.Text>
        {
          moment(date).format('DD MMMM, YYYY')
        }
      </Typography.Text>
    ),
  },
  {
    title: 'Time',
    dataIndex: 'time',
    key: 'time',
    width: '6%',
    render: (time) => (
      <Typography.Text>
        {time}
      </Typography.Text>
    ),
  },
  {
    title: 'Order ID',
    dataIndex: 'orderNumber',
    ellipsis: true,
    width: '12%',
    render: (orderNumber) => <Typography.Text>{orderNumber}</Typography.Text>,
  },
  {
    title: 'User ID',
    dataIndex: 'userNumber',
    ellipsis: true,
    width: '12%',
    render: (userId) => <Typography.Text>{userId}</Typography.Text>,
  },
  {
    title: 'Coupons qty',
    dataIndex: 'amount',
    ellipsis: true,
    width: '12%',
    render: (amount) => <Typography.Text>{amount}</Typography.Text>,
  },
  {
    title: 'Price, AED',
    dataIndex: 'price',
    ellipsis: true,
    width: '12%',
    render: (price) => <Typography.Text>{price / 100}</Typography.Text>,
  },
  {
    title: 'Promo code',
    dataIndex: 'promoCode',
    ellipsis: true,
    width: '12%',
    render: (promoCode) => <Typography.Text>{promoCode || '-'}</Typography.Text>,
  },
  {
    title: 'Discount, AED',
    dataIndex: 'totalPromoDiscount',
    ellipsis: true,
    width: '12%',
    render: (totalPromoDiscount) => (
      <Typography.Text>
        {totalPromoDiscount ? totalPromoDiscount / 100 : '-'}
      </Typography.Text>
    ),
  },
  {
    title: 'Total, AED',
    dataIndex: 'total',
    ellipsis: true,
    width: '9%',
    render: (total) => <Typography.Text>{total / 100}</Typography.Text>,
  },
];

export default columnOrders;
