import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { Form, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PROFILE_PASSWORD_SCHEMA } from '../../../../constants/profileValidatorSchema/validatorSchema';
import useSendNewPassword from '../../hooks/gql/useSendNewPassword';
import Loader from '../../../Loader';
import validatorForm from '../../../../helpers/validator';
import EditInput from '../EditInput';
import BtnsBlock from '../../../Common_components/BtnsBlock';
import './EditPassword.scss';

const EditPassword = () => {
  const [dataAccount, setDataAccount] = useState({
    confirmPassword: '',
    currentPassword: '',
    newPassword: '',
  });

  const navigate = useNavigate();

  const {
    changePasswordHandler,
    updatePasswordError,
    updatePasswordLoading,
  } = useSendNewPassword();

  const [allErrors, setAllErrors] = useState(null);

  const sendDataHandler = useCallback(() => {
    const { confirmPassword, currentPassword, newPassword } = dataAccount;
    const { errors } = validatorForm({
      confirmPassword: confirmPassword && confirmPassword.trim(),
      currentPassword: currentPassword && currentPassword.trim(),
      newPassword: newPassword && newPassword.trim(),
    }, PROFILE_PASSWORD_SCHEMA);

    if (!Object.keys(errors).length) {
      changePasswordHandler({
        confirmPassword: confirmPassword.trim(),
        currentPassword: currentPassword.trim(),
        newPassword: newPassword.trim(),
      });
    } else {
      setAllErrors(() => ({ ...errors }));
    }
  }, [changePasswordHandler, dataAccount]);

  const cancelHandler = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const changeDataAccount = useCallback((key, value) => {
    setDataAccount((prev) => ({
      ...prev,
      [key]: value,
    }));
    setAllErrors((prev) => ({
      ...prev,
      [key]: '',
    }));
  }, []);

  useEffect(() => {
    if (updatePasswordError) {
      message.config({
        maxCount: 1,
      });
      message.open({
        type: 'error',
        content: 'Old password isn\'t correct',
        duration: 3,
      });
    }
  }, [updatePasswordError]);

  return (
    <div className="edit-password-wrapper">
      {updatePasswordLoading && <Loader />}
      <h6 className="account-title">
        Edit password
      </h6>
      <Form
        className="account-form edit-password-form"
        labelCol={{
          className: 'account_label-col',
          span: 7,
        }}
        wrapperCol={{
          className: 'account_wrapper-col',
          span: 17,
        }}
      >
        <EditInput
          label="Old password"
          error={allErrors?.currentPassword}
          keyField="currentPassword"
          value={dataAccount.currentPassword}
          callback={changeDataAccount}
          inputVariant="password"
        />
        <EditInput
          label="New password"
          error={allErrors?.newPassword}
          keyField="newPassword"
          value={dataAccount.newPassword}
          callback={changeDataAccount}
          inputVariant="password"
        />
        <EditInput
          label="Confirm password"
          error={allErrors?.confirmPassword}
          keyField="confirmPassword"
          value={dataAccount.confirmPassword}
          callback={changeDataAccount}
          inputVariant="password"
        />
        <BtnsBlock
          disableOkBtn={updatePasswordLoading}
          cancelHandler={cancelHandler}
          sendDataHandler={sendDataHandler}
          cancelText="Cancel"
          okText="Save"
        />
      </Form>
    </div>
  );
};

export default memo(EditPassword);
