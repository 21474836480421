import React from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import MyDoc from './PdfLayout';

// voucherData, preview, urlToCoupon, qrForOptions,
const DownloadVoucher = ({
  voucherData, preview, urlToCoupon,
}) => (
  <MyDoc
    title={voucherData.title}
    //  options={voucherData.voucherOptions}
    previewSrc={preview}
    propsCompanyTitle={voucherData.company}
    fixedVat={voucherData.fixedVat}
    promoCode={voucherData.promoCode}
    additionalInformation={voucherData.additionalInformation}
    bookingPhoneNumber={voucherData.bookingPhoneNumber}
    propsCompanyAddress={voucherData.address}
    fixedCost={voucherData.fixedCost}
    fixedDate={voucherData.totalPayDate}
    qrCodeUrl={urlToCoupon}
    terms={voucherData.terms}
    description={voucherData.description}
    //  qrForOptions={qrForOptions}
    voucherNumber={voucherData.voucherNumber}
  />
);
DownloadVoucher.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  voucherData: PropTypes.object.isRequired,
  preview: PropTypes.string.isRequired,
  urlToCoupon: PropTypes.string.isRequired,
  // qrForOptions: PropTypes.arrayOf(PropTypes.string),
};

DownloadVoucher.defaultProps = {
  // qrForOptions: [],
};

// qrForOptions
const LazyDownloadPDFButton = async (voucherData, preview, urlToCoupon) => {
  const doc = (
    <DownloadVoucher
      voucherData={voucherData}
      preview={preview}
      urlToCoupon={urlToCoupon}
    //  qrForOptions={qrForOptions}
    />
  );
  const asPdf = pdf();
  asPdf.updateContainer(doc);
  const blob = await asPdf.toBlob();
  saveAs(blob, `${voucherData.voucherNumber}.pdf`);
};

export default LazyDownloadPDFButton;
