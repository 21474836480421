import React, { useCallback } from 'react';
import { Typography } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import useDeleteCoupon from '../../hooks/gql/useDeleteCoupon';
import { roundingPrice } from '../../../../../constants/math';
import DropdownBtnComponent from '../../../../Common_components/DropdownBtnComponent';
import { FORMAT_DATE_TABLE, STATUS_TABLE_ACTIVE, STATUS_TABLE_DECLINED } from '../../../../../constants/couponsAdmin';
import { getRoleUser } from '../../../../../helpers/user-storage';
import copyToClipboard from '../../../../../helpers/copyToClipboard';
import TooltipComponent from '../../../../Common_components/TooltipComponent';
import { CopyIcon } from '../../../../../constants/icons';
import { MERCHANT_ADMIN, MERCHANT_SUPER_ADMIN } from '../../../../../constants/admin-roles';
import { CATEGORY_ID_FILTER_KEY, SUB_CATEGORY_FILTER_KEY } from '../../../../../constants/general/filters';
import DropdownWithClearComponent
  from '../../../../Common_components/DropdownWithClearComponent';
import useSearchFilters from '../../hooks/filters/useSearchFilters';
import DoubleDateTableWrapper from '../../../../Common_components/DoubleDateForTableWrapper';

const useGetDeclinedColumn = (coupons, filterCategories, filterSubCategories, showMessageIdOfCouponWasCopied) => {
  const { deleteCouponByAdmin } = useDeleteCoupon();
  const navigate = useNavigate();

  const { searchParams, setSearchParams, dropdownValueHandler } = useSearchFilters();

  const page = searchParams.get('page') || '1';
  const status = searchParams.get('status') || STATUS_TABLE_ACTIVE;
  const role = getRoleUser();

  const deleteCoupon = useCallback(async (id) => {
    try {
      await deleteCouponByAdmin(id);
      if (status === STATUS_TABLE_DECLINED) {
        const newPage = coupons?.length === 1 ? +page - 1 : page;
        const finalPage = newPage === 0 ? 1 : newPage;
        setSearchParams({
          ...Object.entries(searchParams),
          status,
          page: finalPage,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [coupons, deleteCouponByAdmin, page, searchParams, setSearchParams, status]);

  const editCoupon = useCallback((id) => {
    navigate(`edit-coupon/:${id}`);
  }, [navigate]);

  const copiedId = useCallback(async (id) => {
    await copyToClipboard(id);
    showMessageIdOfCouponWasCopied(id);
  }, [showMessageIdOfCouponWasCopied]);

  const categoryFilterHandler = (value) => dropdownValueHandler(value, CATEGORY_ID_FILTER_KEY);

  const subCategoryFilterHandler = (value) => dropdownValueHandler(value, SUB_CATEGORY_FILTER_KEY);

  const isAdmin = role !== MERCHANT_ADMIN || role !== MERCHANT_SUPER_ADMIN;

  const nameColumn = {
    title: 'Name',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    width: isAdmin ? '20%' : '34%',
    render: (text) => <Typography.Text>{text}</Typography.Text>,
  };

  const numberColumn = {
    title: 'Coupon ID',
    dataIndex: 'id',
    key: 'id',
    width: '7%',
    render: (id) => (
      <TooltipComponent
        title={id}
        placement="bottomLeft"
        overlayClassName="tooltipAdminTableColumns maxWidthTooltip"
      >
        <div className="fieldWithIdAdminTable">
          <Typography.Text>
            {id.slice(0, 5)}
            ...
          </Typography.Text>
          <span
            className="copyIdAdminTablesIcon"
            onClick={() => copiedId(id)}
          >
            <CopyIcon />
          </span>
        </div>
      </TooltipComponent>
    ),
  };

  const dateColumn = {
    title: <DoubleDateTableWrapper />,
    dataIndex: 'createdAt',
    key: 'date',
    ellipsis: true,
    width: '14%',
    render: (date) => (
      <Typography.Text>
        {date ? moment(date).format(FORMAT_DATE_TABLE) : '-'}
      </Typography.Text>
    ),

  };

  const categoryColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Category</span>
        <DropdownWithClearComponent
          items={filterCategories}
          minWidth="280px"
          callback={categoryFilterHandler}
        />
      </div>
    ),
    dataIndex: 'category',
    ellipsis: true,
    key: 'category',
    width: '14%',
    render: (category) => (
      <Typography.Text>
        {category.title}
      </Typography.Text>
    ),
  };

  const subCategoryColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Subcategory</span>
        <DropdownWithClearComponent
          items={filterSubCategories}
          minWidth="280px"
          callback={subCategoryFilterHandler}
        />
      </div>
    ),
    dataIndex: 'subCategory',
    key: 'subCategory',
    ellipsis: true,
    width: '14%',
    render: (subCategory) => (
      <Typography.Text>
        {subCategory?.title || '-'}
      </Typography.Text>
    ),
  };

  const companyColumn = {
    title: 'Company name',
    dataIndex: 'merchant',
    key: 'merchant',
    width: '14%',
    ellipsis: true,
    render: (merchant) => (
      <Typography.Text>
        {merchant.title}
      </Typography.Text>
    ),
  };

  const priceColumn = {
    title: 'Coupon price, AED',
    dataIndex: 'currentPrice',
    key: 'currentPrice',
    ellipsis: true,
    width: '14%',
    render: (currentPrice) => <Typography.Text>{roundingPrice(currentPrice / 100)}</Typography.Text>,
  };

  const actionsColumn = {
    key: 'actions',
    width: '5%',
    render: (text, record) => (
      <DropdownBtnComponent
        text={text}
        record={record}
        actions={[
          {
            key: '1',
            title: 'Edit',
            callback: editCoupon,
          },
          {
            key: '2',
            title: 'Delete',
            callback: deleteCoupon,
          },
        ]}
      />
    ),
  };

  if (isAdmin) {
    return [
      nameColumn,
      numberColumn,
      dateColumn,
      categoryColumn,
      subCategoryColumn,
      companyColumn,
      priceColumn,
      actionsColumn,
    ];
  }
  return [
    nameColumn,
    numberColumn,
    dateColumn,
    categoryColumn,
    subCategoryColumn,
    priceColumn,
    actionsColumn,
  ];
};

export default useGetDeclinedColumn;
