import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
    mutation createOrder ($promoCode: String) {
        createOrder (promoCode: $promoCode) {
            id
            orderNumber
            payUrl
        }
    }
`;

export const GET_ORDER_BY_ID = gql`
    query getOrderById ($id: ID!) {
        getOrderById (id: $id) {
            orderNumber
            date
            totalPrice
            vouchers {
                id
            }
        }
    }
`;

export const APPLY_ORDER = gql`
    mutation applyOrder($orderId: String, $isBuyNow: Boolean) {
        applyOrder (orderId: $orderId, isBuyNow: $isBuyNow) {
            orderNumber
            date
            totalPrice
            vouchers {
                id
            }
        }
    }
`;

export const CANCEL_ORDER = gql`
    mutation cancelOrder($orderId: String) {
        cancelOrder (orderId: $orderId)
    }
`;

export const TRY_ORDER = gql`
    query {
        tryCreateOrder {
            id
            amount
            coupon {
                currentAvailableAmount
                title
            }
        }
    }
`;
