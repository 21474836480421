import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import './radioGroupCommon.scss';

const CommonRadioGroup = ({ items, defaultValues, onChange }) => (
  <Checkbox.Group
    className="radioGroupCommon"
    options={items}
    defaultValue={defaultValues}
    onChange={onChange}
  />
);

CommonRadioGroup.propTypes = {
  items: PropTypes.array.isRequired,
  defaultValues: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

CommonRadioGroup.defaultProps = {
  defaultValues: [],
};

export default CommonRadioGroup;
