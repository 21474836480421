import React, {
  memo, useCallback, useRef, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Button, Form, Input, Modal, Select,
} from 'antd';
import PropTypes from 'prop-types';
import { uid } from 'uid';
import { CloseIcon } from '../../../../constants/icons';
import PreviewImage from '../../../Common_components/PreviewImage';
import s from './banner-modal.module.scss';
import './banner-modal-ant.scss';
import ButtonComponent from '../../../Common_components/ButtonComponent';
import { ERROR_UPLOAD_BANNER_IMAGE } from '../../../../constants/errors';
import { bannersSelectItems, bannersTypes, getImageAspect } from '../../../../constants/banners/enums';

const { Item } = Form;

const { Option } = Select;

const BannerModal = ({
  bannerData, closeModalWindowBanner, isOpenModalBanner, setBannerDataHandler,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') || '';

  const [type, setType] = useState(bannerData?.type);

  const finallyTitle = () => {
    if (id !== 'create-banner' && id !== 'all-banners') {
      return 'Edit banner';
    }
    return 'Add banner';
  };

  const [form] = Form.useForm();
  const [isBannerError, setBannerError] = useState(false);
  const bannerPicture = useRef(bannerData.bannerPicture);

  const resetSizeError = () => {
    setBannerError(false);
    form.setFields([{ name: 'bannerPicture', errors: [] }]);
  };

  const sendFormData = async (values) => {
    if (bannerData && bannerPicture.current?.key) {
      const sendOjb = {
        ...values,
        bannerPicture: bannerPicture.current,
      };
      try {
        await setBannerDataHandler(sendOjb);
        closeModalWindowBanner();
        form.resetFields();
      } catch (e) {
        const isErrorForType = e.message === 'Banner already exist';
        form.setFields([{
          name: isErrorForType ? 'type' : 'bannerPicture',
          errors: [isErrorForType ? 'Banner with this type is already exist' : e.message],
        }]);
      }
    } else {
      form.setFields([{ name: 'bannerPicture', errors: ['This field is required!'] }]);
    }
  };

  const onFinishFailed = () => {
    if (!bannerPicture.current.key) {
      setBannerError(true);
      form.setFields([{ name: 'bannerPicture', errors: ['This field is required!'] }]);
    }
  };

  const setBannerSizeError = () => {
    setBannerError(true);
    form.setFields([{ name: 'bannerPicture', errors: [ERROR_UPLOAD_BANNER_IMAGE] }]);
  };

  const getImage = useCallback((img) => {
    if (img) {
      bannerPicture.current = {
        id: bannerData.bannerPicture?.id || uid(),
        bannerId: bannerData.bannerPicture?.bannerId || uid(),
        key: img,
      };
      if (isBannerError) {
        resetSizeError();
      }
    } else {
      bannerPicture.current = null;
      setBannerError(true);
      form.setFields([{ name: 'bannerPicture', errors: ['This field is required!'] }]);
    }
  }, [bannerData.bannerPicture?.bannerId, bannerData.bannerPicture?.id]);

  const closeForm = () => {
    closeModalWindowBanner();
    form.resetFields();
  };

  const queueHandler = (e) => {
    const { name, value } = e.target;
    const numbers = value.replace(/\D/g, '');
    form.setFieldsValue({ [name]: Number(value) ? value : numbers });
  };

  const bannerTypeSelectItems = bannersSelectItems.map(({ value, title, key }) => (
    <Option
      value={value}
      key={key}
    >
      {title}
    </Option>
  ));

  const previewAspect = getImageAspect(type);

  return (
    <Modal
      title={finallyTitle()}
      centered
      visible={isOpenModalBanner}
      closeIcon={<CloseIcon />}
      onCancel={closeForm}
      footer={null}
      width={440}
      wrapClassName="modalBanner"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={sendFormData}
        requiredMark={false}
        className="formBanner"
        name="formBanner"
        onValuesChange={({ type }) => {
          setType(type);
        }}
        initialValues={bannerData}
        onFinishFailed={onFinishFailed}
      >
        <Item
          label="Title"
          name="title"
          className={s.formItem}
          value={bannerData.title}
          rules={[
            {
              required: true,
              message: 'This field is required!',
            },
            {
              max: 100,
              message: 'Max 100 symbols!',
            },
            {
              whitespace: true,
              message: 'This field can not be empty!',
            },
          ]}
        >
          <Input
            autoFocus
            style={{
              height: '40px',
              borderRadius: '4px',
            }}
          />
        </Item>
        <Item
          label="Type"
          name="type"
          className={s.formItem}
          rules={[{ validateTrigger: 'onChange' }]}
        >
          <Select
            style={{
              height: '100%',
              border: '1px solid #d9d9d9',
              borderRadius: '4px',
            }}
            value={bannerData.type}
          >
            {bannerTypeSelectItems}
          </Select>
        </Item>
        <div className={`${s.containerSelect} containerSelectBanner`}>
          <Item
            label="On page"
            name="page"
            className={`${s.formItem} ${s.page}`}
          >
            <Select
              disabled
              style={{
                height: '100%',
                border: '1px solid #d9d9d9',
              }}
              value={bannerData.page}
            >
              <Select.Option value="MAIN">Main</Select.Option>
              <Select.Option value="BLOG">Blog</Select.Option>
            </Select>
          </Item>
          <Item
            label="Queue"
            name="queue"
            className={`${s.formItem} ${s.queue}`}
          >
            <Input
              onChange={queueHandler}
              name="queue"
              value={bannerData.queue}
              disabled={type !== bannersTypes.CAROUSEL}
              style={{
                height: '100%',
                border: '1px solid #d9d9d9',
                borderRadius: '4px',
              }}
            />
          </Item>
        </div>
        <Item
          label="Image"
          name="bannerPicture"
          value={bannerData.bannerPicture}
          className={s.formItem}
        >
          <PreviewImage
            aspect={previewAspect}
            prevImage={bannerData.bannerPicture}
            setPrevImage={getImage}
            addClass={isBannerError ? 'error' : ''}
            containerClass="uploadImageBanner"
            setBannerSizeError={setBannerSizeError}
            resetSizeError={resetSizeError}
          />
        </Item>

        <Item
          label="Link"
          name="link"
          className={s.formItem}
          rules={[
            {
              type: 'url',
              message: 'Incorrect url address!',
            },
          ]}
        >
          <Input
            style={{
              height: '40px',
              borderRadius: '4px',
            }}
          />
        </Item>

        <div className={s.btnModal}>
          <ButtonComponent
            title="Cancel"
            addClass={s.cancel}
            callback={closeForm}
            iconComponent={null}
          />
          <Button
            htmlType="submit"
            type="primary"
            className={`${s.btn} ${s.send}`}
          >
            {id !== 'create-banner' ? 'Save' : 'Add banner'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

BannerModal.propTypes = {
  bannerData: PropTypes.object.isRequired,
  setBannerDataHandler: PropTypes.func.isRequired,
  isOpenModalBanner: PropTypes.bool.isRequired,
  closeModalWindowBanner: PropTypes.func.isRequired,
};

export default memo(BannerModal);
