import { gql } from '@apollo/client';

export const FIND_VOUCHERS_BY_ADMIN = gql`
    query findAllVouchersAdmin (
        $offset: Float,
        $limit: Float,
        $status: String,
        $search: String,
        $sortOrder: String,
        $sortField: String
    ) {
        findAllVouchersAdmin(
            offset: $offset,
            limit: $limit,
            status: $status,
            search: $search,
            sortField: $sortField,
            sortOrder: $sortOrder
        ) {
            vouchers {
                id
                voucherNumber
                status
                couponTitle
                company
                payDate
                activateDate
                totalPrice
                activateMethod
                order {
                    id
                    orderNumber
                }
                voucherOptions {
                    id
                    voucherNumber
                    status
                    optionTitle
                    activateDate
                    activateMethod
                }
                user {
                    userNumber
                }
            }
            count
        }
    }
`;

export const FIND_VOUCHERS_BY_MERCHANT = gql`
    query findAllVouchersMerchant (
        $offset: Float,
        $limit: Float,
        $status: String,
        $search: String,
        $sortOrder: String,
        $sortField: String,

    ) {
        findAllVouchersMerchant(
            offset: $offset,
            limit: $limit,
            status: $status,
            search: $search,
            sortField: $sortField,
            sortOrder: $sortOrder,

        ) {
            vouchers {
                id
                voucherNumber
                status
                couponTitle
                company
                payDate
                activateDate
                totalPrice
                activateMethod
                order {
                    id
                    orderNumber
                }
                voucherOptions {
                    id
                    voucherNumber
                    status
                    optionTitle
                    activateDate
                    activateMethod
                }
                user {
                    userNumber
                }
            }
            count
        }
    }
`;

export const ACTIVATE_VOUCHER_OPTION = gql`
    mutation
    updateVoucherOptionStatus($status: VoucherStatuses, $voucherOptionId: String, $activateMethod: ActivateMethod) {
        updateVoucherOptionStatus(status: $status, voucherOptionId: $voucherOptionId, activateMethod: $activateMethod) {
            id
        }
    }
`;

export const ACTIVATE_VOUCHER_ADMIN = gql`
    mutation updateVoucherStatus($status: VoucherStatuses, $voucherId: String, $activateMethod: ActivateMethod) {
        updateVoucherStatus(status: $status, voucherId: $voucherId, activateMethod: $activateMethod) {
            id
        }
    }
`;

export const ACTIVATE_VOUCHER = gql`
    mutation activateVoucher($voucherId: ID!) {
        activateVoucher(voucherId: $voucherId)
    }
`;

export const EXPORT_VOUCHERS_TO_EXCEL = gql`
    query exportVouchersToExcel(
        $search: String,
        $sortOrder: String,
        $sortField: String,
        $merchantId: String,
        $status: String,
    ) {
        exportVouchersToExcel(
            search: $search,
            sortOrder: $sortOrder,
            sortField: $sortField,
            merchantId: $merchantId,
            status: $status,
        ) {
            vouchersExcelKey
            message
        }
    }
`;
