// eslint-disable-next-line import/prefer-default-export
export const days = [
  {
    dayOfWeek: 'MONDAY',
    day: 'Monday',
  },
  {
    dayOfWeek: 'TUESDAY',
    day: 'Tuesday',
  },
  {
    dayOfWeek: 'WEDNESDAY',
    day: 'Wednesday',
  },
  {
    dayOfWeek: 'THURSDAY',
    day: 'Thursday',
  },
  {
    dayOfWeek: 'FRIDAY',
    day: 'Friday',
  },
  {
    dayOfWeek: 'SATURDAY',
    day: 'Saturday',
  },
  {
    dayOfWeek: 'SUNDAY',
    day: 'Sunday',
  },
];
