import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { message, Table } from 'antd';
import useWindowSize from '../../../hooks/useWindowSize/useWindowSize';
import { limit } from '../../../constants/tableConstants';
import { heightMinusForAdminTables } from '../../../constants/general/tag-status';
import useColumnLogs from './column/useColumnLogs';
import useGetAllLogs from '../hooks/gql/useGetAllLogs';
import Loader from '../../Loader';
import { showCorrectFormatOfTime } from '../../../helpers/general/general';
import { SuccessIcon } from '../../../constants/icons';

const TableLogs = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { count, loading, logs } = useGetAllLogs();
  const [copiedId, setCopiedId] = useState('');
  const [height] = useWindowSize();

  const page = searchParams.get('page') || '1';

  const showMessageLogIdWasCopied = useCallback((id) => {
    setCopiedId(id);
  }, []);
  const columns = useColumnLogs(showMessageLogIdWasCopied);

  const finalLogsData = logs?.map((log) => {
    const {
      createdAt, ...restLog
    } = log;

    const hours = moment(createdAt)
      .get('hours');
    const minute = moment(createdAt)
      .get('minute');

    return {
      ...restLog,
      createdAt,
      time: `${showCorrectFormatOfTime(hours)}:${showCorrectFormatOfTime(minute)}`,
    };
  });

  const onChangePage = (page) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page,
    });
  };

  useEffect(() => {
    if (copiedId) {
      message.config({
        maxCount: 1,
      });
      message.open({
        icon: <SuccessIcon />,
        className: 'messageSuccessTableAdmin',
        content: 'Coupon ID was copied!',
        duration: 2,
      });
    }
  }, [copiedId]);

  if (loading) {
    return <Loader />;
  }

  return (
    <section className="all-admin-table">
      <Table
        className="adminTable tableOrders"
        dataSource={finalLogsData || []}
        columns={columns}
        scroll={{
          y: height - heightMinusForAdminTables, x: 0,
        }}
        rowKey={(rec) => rec.entityId}
        pagination={{
          current: +page,
          pageSize: limit,
          total: count,
          position: ['bottomCenter'],
          size: 'small',
          showSizeChanger: false,
          onChange: (page) => onChangePage(page),
        }}
      />
    </section>
  );
};

export default memo(TableLogs);
