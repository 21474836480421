import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import { message, Tabs } from 'antd';
import { useSearchParams } from 'react-router-dom';
import useGetActiveColumn from './columns/useGetActiveColumn';
import useGetOnApproveColumn from './columns/useGetOnApproveColumn';
import useGetArchivedColumn from './columns/useGetArchivedColumn';
import useFindAllCoupons from '../hooks/gql/useFindAllCoupons';
import Loader from '../../../Loader';

import {
  STATUS_TABLE_ACTIVE,
  STATUS_TABLE_ARCHIVED,
  STATUS_TABLE_DECLINED,
  STATUS_TABLE_ON_APPROVE,
} from '../../../../constants/couponsAdmin';
import './table-coupons.scss';
import TableComponent from './TableComponent';
import useGetCategories from '../../../../pages/Categories/hooks/useGetCategories';
import { SuccessIcon } from '../../../../constants/icons';
import useGetDeclinedColumn from './columns/useGetDeclinedColumn';

const TableCoupons = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';
  const status = searchParams.get('status') || STATUS_TABLE_ACTIVE;
  const [copiedId, setCopiedId] = useState('');

  const showMessageIdOfCouponWasCopied = useCallback((id) => {
    setCopiedId(id);
  }, []);

  const { categories } = useGetCategories(true);

  const { filterCategories, filterSubCategories } = useMemo(() => {
    const nonEmptyCategories = categories?.filter(({ couponsCount }) => !!couponsCount);

    const nonEmptySubCategories = nonEmptyCategories
      ?.map(({ subCategories }) => subCategories)
      .flat(1)
      .filter(({ couponsCount }) => !!couponsCount);

    return {
      filterCategories: nonEmptyCategories?.map(({ id, title }) => ({ value: id, title, id })) || [],
      filterSubCategories: nonEmptySubCategories?.map(({ id, title }) => ({ value: id, title, id })) || [],
    };
  }, [categories]);

  const { coupons, loading, count } = useFindAllCoupons();

  const tabsData = [
    {
      key: '1',
      tab: 'Active',
      columns: useGetActiveColumn(coupons, filterCategories, filterSubCategories, showMessageIdOfCouponWasCopied),
      data: coupons,
    },
    {
      key: '2',
      tab: 'On approve',
      columns: useGetOnApproveColumn(coupons, filterCategories, filterSubCategories, showMessageIdOfCouponWasCopied),
      data: coupons,
    },
    {
      key: '3',
      tab: 'Archived',
      columns: useGetArchivedColumn(coupons, filterCategories, filterSubCategories, showMessageIdOfCouponWasCopied),
      data: coupons,
    },
    {
      key: '4',
      tab: 'Declined',
      columns: useGetDeclinedColumn(coupons, filterCategories, filterSubCategories, showMessageIdOfCouponWasCopied),
      data: coupons,
    },
  ];

  const currentStatus = () => {
    switch (status) {
    case STATUS_TABLE_ON_APPROVE: return '2';
    case STATUS_TABLE_ARCHIVED: return '3';
    case STATUS_TABLE_DECLINED: return '4';
    default: return '1';
    }
  };

  const changeTabHandler = (key) => {
    let status;
    switch (key) {
    case '2': {
      status = STATUS_TABLE_ON_APPROVE;
      break;
    }
    case '3': {
      status = STATUS_TABLE_ARCHIVED;
      break;
    }
    case '4': {
      status = STATUS_TABLE_DECLINED;
      break;
    }
    default: status = STATUS_TABLE_ACTIVE;
    }
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      status,
    });
  };

  useEffect(() => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      status,
      page,
    });
  }, [page, searchParams, setSearchParams, status]);

  useEffect(() => {
    if (copiedId) {
      message.config({
        maxCount: 1,
      });
      message.open({
        icon: <SuccessIcon />,
        className: 'messageSuccessTableAdmin',
        content: 'Coupon ID was copied!',
        duration: 2,
      });
    }
  }, [copiedId]);

  return (
    <div className="containerTable tabsAntForAdminTables">

      <Tabs
        activeKey={currentStatus()}
        onChange={changeTabHandler}
      >
        {
          tabsData.map((tab) => (
            <Tabs.TabPane
              key={tab.key}
              tab={tab.tab}
            >
              { loading
                ? <Loader />
                : (
                  <TableComponent
                    columns={tab.columns}
                    dataSource={tab.data}
                    count={count}
                  />
                )}
            </Tabs.TabPane>
          ))
        }
      </Tabs>
    </div>
  );
};

export default TableCoupons;
