import { Form, Radio, Space } from 'antd';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { currentPriceByDiscount } from '../../../../constants/math';
import './countingBody.scss';
import ByPrice from './ByPrice';
import ByDiscount from './ByDiscount';

const CountingBody = ({
  couponInput, callback, vatValue, allErrors,
}) => {
  const callbackHandler = (key, value) => {
    callback(key, value);
  };

  const correctPrice = (field, key, value) => {
    if (field === '0') {
      callback(key, field === '0.' ? value : value.slice(1));
      return;
    }
    callback(key, value);
  };

  const { countPriceByDiscount } = couponInput;
  const priceOfCoupon = useRef(null);

  priceOfCoupon.current = !countPriceByDiscount
    ? couponInput.createPrice
    : currentPriceByDiscount(couponInput.oldPrice, couponInput.discount);

  return (
    <div className="countingBody">
      <Form.Item
        name="radio-group"
        label="Counting by:"
      >
        <Radio.Group
          onChange={(e) => callbackHandler('countPriceByDiscount', e.target.value !== 1)}
          defaultValue={couponInput.countPriceByDiscount ? 2 : 1}
        >
          <Space direction="vertical">
            <Radio value={1}>By price</Radio>
            <Radio value={2}>By discount</Radio>
          </Space>
        </Radio.Group>
      </Form.Item>
      {
        !couponInput.countPriceByDiscount
          ? (
            <ByPrice
              allErrors={allErrors}
              correctPrice={correctPrice}
              priceOfCoupon={priceOfCoupon}
              couponInput={couponInput}
              vatValue={vatValue}
              callbackHandler={callbackHandler}
            />
          )
          : (
            <ByDiscount
              allErrors={allErrors}
              correctPrice={correctPrice}
              couponInput={couponInput}
              vatValue={vatValue}
              callbackHandler={callbackHandler}
            />
          )

      }
    </div>
  );
};

CountingBody.propTypes = {
  couponInput: PropTypes.oneOfType([PropTypes.object]).isRequired,
  callback: PropTypes.func.isRequired,
  vatValue: PropTypes.number,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
};
CountingBody.defaultProps = {
  vatValue: undefined,
  allErrors: undefined,
};

export default CountingBody;
