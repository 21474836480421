import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../../components/Table';
import EditModal from '../EditModal';
import useCategoriesTable from './hooks/useCategoriesTable';
import AddModal from '../AddModal';

const CategoriesTable = ({ data, refetch }) => {
  const {
    isAddModalVisible,
    addModalHandleOk,
    addModalHandleCancel,
    addModalProps,

    isEditModalVisible,
    editModalHandleOk,
    editModalHandleCancel,
    editModalProps,

    columns,
  } = useCategoriesTable(data, refetch);

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ position: ['bottomCenter'], size: 'small', pageSize: 13 }}
      />
      <EditModal
        isModalVisible={isEditModalVisible}
        handleCancel={editModalHandleCancel}
        handleOk={editModalHandleOk}
        {...editModalProps}
        categories={data}
        refetch={refetch}
      />
      <AddModal
        isModalVisible={isAddModalVisible}
        handleCancel={addModalHandleCancel}
        handleOk={addModalHandleOk}
        categories={data}
        {...addModalProps}
      />
    </>
  );
};
CategoriesTable.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
  refetch: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
};

export default CategoriesTable;
