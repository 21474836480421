import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import s from './Categories.module.scss';
import MappedCategories from './MappedCategories';
import { CategoriesIcon } from '../../../constants/icons';
import CategoriesContext from '../../Layout/context/categoriesContext';
import mobileCondition from '../../../constants/mobileCondition';

const Categories = ({ isCategoryFilterExist }) => {
  const { fetchAllCategoriesCouponsCallback } = useContext(CategoriesContext);

  const mobileFixedClassName = `${s.mobile} ${isCategoryFilterExist && s.fixed}`;

  const mainClassName = ` ${s.main} ${mobileCondition && mobileFixedClassName}`;

  const headerClassName = `${s.header} ${mobileCondition && s.mobile}`;

  return (
    <div className={mainClassName}>
      {!mobileCondition ? (
        <>
          <div
            onClick={fetchAllCategoriesCouponsCallback}
            className={headerClassName}
          >
            <CategoriesIcon />
            <span>
              All Categories
            </span>
          </div>
          <MappedCategories />
        </>
      )
        : (!isCategoryFilterExist
            && (
              <div
                onClick={fetchAllCategoriesCouponsCallback}
                className={headerClassName}
              >
                <CategoriesIcon />
                <span>
                  All Categories
                </span>
              </div>
            )
        )}
    </div>
  );
};

Categories.propTypes = {
  isCategoryFilterExist: PropTypes.bool,
};

Categories.defaultProps = {
  isCategoryFilterExist: undefined,
};

export default memo(Categories);
