import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { FIND_ALL_CUSTOMERS } from '../../../../api/api-buyers/query';
import { limit } from '../../../../constants/tableConstants';
import {
  BUYERS_PURCHASES_FILTER_KEY,
  FROM_HIGHER_KEY,
  SEARCH_FILTER_KEY,
  SORT_ORDER_FILTER_KEY,
} from '../../../../constants/general/filters';
import useLastPageChange from '../../../../hooks/useLastPageChange/useLastPageChange';

const useFindAllCustomers = () => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || '1';

  const sortOrder = searchParams.get(SORT_ORDER_FILTER_KEY) || FROM_HIGHER_KEY;

  const ordersCountFilter = Number(searchParams.get(BUYERS_PURCHASES_FILTER_KEY));

  const searchQuery = searchParams.get(SEARCH_FILTER_KEY) || '';

  const { loading, error, data } = useQuery(FIND_ALL_CUSTOMERS, {
    variables: {
      offset: (Number(page) - 1) * limit,
      limit,
      sortOrder,
      ordersCountFilter,
      searchQuery,
    },
  });

  useLastPageChange(data);

  if (data) {
    const { users, count } = data.findAllCustomers;

    return {
      loading,
      error,
      users,
      count,
    };
  }

  return {
    loading,
    error,
  };
};

export default useFindAllCustomers;
