import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { EXPORT_VOUCHERS_TO_EXCEL } from '../../../../api/vouchers/query';
import {
  CREATED_AT_KEY,
  FROM_HIGHER_KEY,
  SEARCH_FILTER_KEY,
  STATUS_FILTER_KEY,
} from '../../../../constants/general/filters';
import useAuthAdmin from '../../../../context/context-admin/useAuthAdmin';

const useExportVouchers = () => {
  const { userInfo } = useAuthAdmin();

  const [s] = useSearchParams();

  const sortField = CREATED_AT_KEY;

  const sortOrder = s.get(CREATED_AT_KEY) || FROM_HIGHER_KEY;

  const search = s.get(SEARCH_FILTER_KEY) || null;

  const status = s.get(STATUS_FILTER_KEY);

  const { data = {} } = useQuery(EXPORT_VOUCHERS_TO_EXCEL, {
    variables: {
      search,
      sortOrder,
      sortField,
      merchantId: userInfo?.merchantId,
      status,
    },
  });

  const { exportVouchersToExcel = {} } = data;

  const { vouchersExcelKey } = exportVouchersToExcel;

  return `${process.env.REACT_APP_BUCKET_URL}/${vouchersExcelKey}`;
};

export default useExportVouchers;
