import React, { memo } from 'react';
import s from './MerchantTerms.module.scss';
import mobileCondition from '../../../constants/mobileCondition';

const MerchantTerms = () => {
  const wrapperClassName = `${s.wrapper} ${mobileCondition && s.mobile}`;

  const titleClassName = `${s.title} ${mobileCondition && s.mobile}`;

  const infoClassName = `${s.info} ${mobileCondition && s.mobile}`;

  const secondClassName = `${s.second} ${mobileCondition && s.mobile}`;

  return (
    <div className={wrapperClassName}>
      <span className={titleClassName}>
        This is the agreed terms & conditions between yallabanana and other parties
      </span>
      <div className={infoClassName}>
        <span>
          1. Yallabanana is authorized entity to promote and sell the vouchers on merchant’s behalf
          subject to the conditions of this agreement and the “terms of sale” mentioned at the platform yallabanana.com
          located at www.yallabanana.com and other related partner websites (“website”).
        </span>
        <span className={secondClassName}>
          2. Both parties agreed to understand the definitions of this agreement as follows:
          <br />
          <b>“merchant offer / deal”</b>
          {' '}
          means the goods and/or services to be provided by the merchant for an agreed timescale (“deal duration”).
          <br />
          <b>“voucher validity”</b>
          {' '}
          means the period of time during which voucher is redeemable by users.
          <br />
          <b>“maximum number of vouchers”</b>
          {' '}
          means the maximum number of vouchers yallabanana is authorized to administer the sale on behalf of
          the merchant.
          <br />
          <b>“monthly maximum number of vouchers”</b>
          {' '}
          means the maximum number of vouchers yallabanana is authorized to administer the sale on behalf of
          merchant each month.
          <br />
          <b>“full offer value”</b>
          {' '}
          means the amount paid plus the promotional value.
          <br />
          <b>“amount paid”</b>
          {' '}
          means the amount a purchaser pays for each voucher.
          <br />
          <b>“promotional value”</b>
          {' '}
          means the full offer value less the amount paid.
          <br />
          <b>“promotional value expiration date”</b>
          {' '}
          means the date stated on the voucher when the promotional value expires.
          <br />
          <b>“vouchers list”</b>
          {' '}
          means vouchers list  with identification characteristics of each transaction which the merchant
          shall provide the yallabqanana to track the completed transactions and claim for redemptions.
          <br />
          <b>“deal duration”</b>
          {' '}
          means time frame when the deal is valid according to the voucher terms.
          <br />
          <b>“remittance amount”</b>
          {' '}
          means the amount yallabanana shall remit to merchant for each voucher.
          <br />
          <b>“fine print”</b>
          {' '}
          means the conditions and restrictions concerning voucher redemption and the merchant offering
          stated on the website and voucher.
          <br />
        </span>
        <div>
          <span>
            3. The Voucher will evidence the Merchant Offering and will be sent to the purchaser electronically
            once payment is received. The purchaser will then redeem the Voucher with the Merchant by presenting the
            Voucher in paper or electronic form. Merchant is the issuer of the Vouchers and seller of the Merchant
            Offering.
          </span>
          <span>
            a. Yallabanana is authorized to promote and sell Vouchers on Merchant’s behalf through any platform,
            including its feature deal-of-the-day, affiliates, business partner network, marketplace, or referral
            network. The Vouchers may be offered to all or part of Yallabanana’s subscriber base or its affiliate
            subscriber base or referral network and segmented by various variables including gender, age, location, and
            consumer preferences. The features may be offered through a variety of distribution channels, including, the
            Internet, the Website, affiliate websites, business partner network, email, mobile applications, other types
            of electronic offerings and other platforms or distribution channels owned, controlled, or operated by
            Yallabanana, its affiliates or business partners. In addition, in connection with Yallabanana’s promotion
            of a Merchant Offering, Merchant authorizes Yallabanana to shorten or extend the Promotional Value
            Expiration Date.
          </span>
          <span>
            b. For appointment-based Merchant Offerings, YallaBanana may require that Merchant provide
            YallaBanana with a calendar of available appointment times and allow YallaBanana purchasers to schedule
            appointments with the Merchant through YallaBanana and any third-party service YallaBanana may use.
            YallaBanana may audit Merchant response times using various methods, including but not limited to,
            auto-dialers, call forwarding and pre-recorded calls. If YallaBanana, in its sole discretion, determines
            Merchant response times or the quality of service provided to purchasers is unsatisfactory, YallaBanana may
            terminate the Agreement and return the Amount Paid to purchasers for unredeemed Voucher.
          </span>
          <span>
            c. Any Merchant who provides a Merchant Offering that includes massage services shall: (a) conduct
            social media searches for all of its employees, agents and/or independent contractors performing any massage
            service to ensure that they maintain a good reputation; and (b) confirm that its employees, agents and/or
            independent contractors providing massage services have the required licenses and/or certifications, as well
            as meet other applicable state law requirements, for performing the services. Further, Merchant shall
            certify to YallaBanana annually that it is in compliance with (a) and (b) above.
          </span>
          <span>
            d. If Merchant performs background checks on its employees, agents and/or independent contractors
            performing services, Merchant shall provide the results of such background checks to YallaBanana upon
            request. Merchant shall obtain the necessary consent to share with YallaBanana the results requested.
          </span>
          <span>
            e. Merchant shall promptly notify YallaBanana any time it receives a complaint related to potentially
            criminal conduct, including allegations of sexual assault, allegedly engaged in by any of its employees,
            agents or independent contractors, regardless of whether a YallaBanana customer makes the complaint.
          </span>
          <span>
            f. YallaBanana is authorized to promote and sell up to the Maximum Number of Vouchers in multiple
            markets and on dates in its discretion. If Merchant elects to offer recurring month-to-month features, then
            YallaBanana will promote and sell up to the Maximum Number of Vouchers for the initial feature, then promote
            and sell up to the Monthly Maximum Number of Vouchers for subsequent features. Merchant shall specify the
            Maximum Number of Vouchers and, if applicable, specify the Monthly Maximum Number of Vouchers, and may
            increase either number in its discretion.
          </span>
          <span>
            g. YallaBanana reserves the continuing right to reject, revise, or discontinue any Merchant Offering,
            at any time and for any reason in YallaBanana’s sole discretion, and to terminate the Merchant Offering and
            to remove all references to the Merchant Offering and Voucher from the Website; and redirect or delete any
            URL used in connection with the Merchant Offering.
          </span>
          <span>
            h. Merchant shall honor the Vouchers for the Merchant Offering through the Promotional Value Expiration
            Date. After the Promotional Value Expiration Date, MERCHANT AGREES TO REDEEM THE VOUCHER FOR THE AMOUNT PAID
            INDEFINITELY.
          </span>
          <span>
            i. After the Promotional Value Expiration Date, Merchant shall always allow the purchaser to redeem
            the Voucher for the Amount Paid toward the Merchant Offering. If the goods and services constituting the
            Merchant Offering and stated on the Voucher are no longer available, the Merchant shall always allow the
            purchaser to redeem the Voucher toward any goods or services then offered by the Merchant equivalent to at
            least the Amount Paid.
          </span>
          <span>
            j. Partial redemptions: If applicable, and if a purchaser redeems a Voucher for less than the Amount Paid,
            the Merchant is responsible for handling any unredeemed value as required by applicable law.k. Merchant
            agrees that in providing the Merchant Offering, Merchant will not inflate prices or impose any additional
            fees, charges, conditions or restrictions that contradict or are inconsistent with the terms stated on the
            Voucher, including the Fine Print. Unless disclosed in the Fine Print, Merchant further agrees not to impose
            different terms or a different cancellation policy than what is imposed on its non- YallaBanana customers.
          </span>
          <span>
            j. Partial redemptions: If applicable, and if a purchaser redeems a Voucher for less than the Amount
            Paid, the Merchant is responsible for handling any unredeemed value as required by applicable law.k.
            Merchant agrees that in providing the Merchant Offering, Merchant will not inflate prices or impose any
            additional fees, charges, conditions or restrictions that contradict or are inconsistent with the terms
            stated on the Voucher, including the Fine Print. Unless disclosed in the Fine Print, Merchant further agrees
            not to impose different terms or a different cancellation policy than what is imposed on its
            non- YallaBanana customers.
          </span>
          <span>
            k. Merchant agrees that in providing the Merchant Offering, Merchant will not inflate prices or impose
            any additional fees, charges, conditions or restrictions that contradict or are inconsistent with the terms
            stated on the Voucher, including the Fine Print. Unless disclosed in the Fine Print, Merchant further agrees
            not to impose different terms or a different cancellation policy than what is imposed on its non-
            YallaBanana customers.
          </span>
          <span>
            l. Merchant agrees that so long as an appointment or reservation is made to redeem a Voucher, or
            purchaser has made an attempt to make an appointment, before the Voucher’s Promotional Value Expiration Date
            , the Voucher will be honored for the Full Offer Value without restriction, even though the services may be
            provided after the Promotional Value Expiration Date.
          </span>
          <span>
            m. For any seasonal Merchant Offering(s), following the initial Promotional Value Expiration Date
            specified in the YallaBanana Merchant Agreement the: (i) Promotional Value Expiration Date will reset to be
            the end of the immediately following season specified by Merchant; and (ii) Maximum Number of Vouchers will
            reset for the immediately following season. Throughout the Term (as defined herein), the Promotional Value
            Expiration Date and the Maximum Number of Vouchers for the seasonal Merchant Offering(s) will continue to
            reset after each season.
          </span>
          <span>
            n. Merchant is responsible for all customer service in connection with the Merchant Offering and for
            supplying all goods and services stated in the Merchant Offering. Merchant is also responsible for any
            customer loyalty programs associated with the Merchant Offering.
          </span>
          <span>
            o. If applicable, Merchant will hold the Merchant Offering for pick-up by each purchaser at the
            Redemption Site. The “Redemption Site” is the complete and accurate address provided by Merchant to
            YallaBanana where purchasers are able to redeem the Voucher to pick-up the Merchant Offering. Merchant
            also agrees to provide YallaBanana with the hours and dates of operation, complete with any exceptions,
            and a valid phone number for the Redemption Site. If any of the information related to a Redemption Site
            changes, Merchant agrees to notify YallaBanana immediately of such change.
          </span>
          <span>
            p. Merchant agrees to accept returns of the Merchant Offering in compliance with applicable laws and
            the Fine Print, but in any event.
          </span>
          <span>
            q. (i) will accept returns of a defective Merchant Offering or nonconforming items in or a part of
            any Merchant Offering at all times and pay (or reimburse a purchaser for) any and all costs associated with
            the return of such Merchant Offering; and (ii) will not impose a more restrictive return policy on
            purchasers than Merchant’s regular return policy as applied to Merchant’s purchaser in the ordinary course
            of Merchant’s business.
          </span>
        </div>
        <div>
          <span>
            4. Payment:
          </span>
          <span>
            a. Amounts retained by YallaBanana from the proceeds of the Merchant Offering are compensation to
            YallaBanana for marketing, promoting, and advertising the Merchant Offering and distributing the Vouchers on
            behalf of Merchant. Merchant shall retain the Remittance Amount in trust for the benefit of purchasers
            holding unredeemed Vouchers until Merchant delivers the Merchant Offering, refunds the holder of any
            unredeemed Voucher or escheats the amount required to a taxing authority, if applicable. The funds held in
            trust shall be returned to YallaBanana upon demand for refunds to purchasers.
          </span>
          <span>
            b. Merchant will not attempt to bill or collect reimbursement from any third-party payor, including but
            not limited to any insurer, health insurance plan, or any other local governmental program or entity
            (“Third-Party Payor”), for any of Merchant’s services. Merchant will accept the amounts received from
            Yallabanana as payment in full for all services provided by Merchant delivered pursuant to the Merchant
            Offering. Merchant is solely responsible for complying with any contractual requirements imposed by its
            contracts with Third-Party Payors, including but not limited to requirements related to offering discounted
            services.
          </span>
          <span>
            c. Taxes- In the event Yallabanana receives written notice of a validly issued Emirate(s) or federal tax
            relating to past-due taxes owed by Merchant, Yallabanana may, in accordance with applicable law in U.A.E,
            deduct any such amounts from payments due to Merchant.
          </span>
          <span>
            d. Taxes Generally. It is Merchant’s responsibility to determine what, if any, taxes apply to the
            payments Merchant makes or receives, and it is Merchant’s responsibility to collect, report and remit the
            correct tax to the appropriate tax authority. Yallabanana is not responsible for determining whether taxes
            apply to Merchant’s transaction with either purchasers or Yallabanana, or for collecting, reporting or
            remitting any taxes arising from any transaction with or by Merchant and purchaser. Merchant may be asked to
            provide Yallabanana with a valid Tax Identification Number for tax reporting purposes. Notwithstanding
            anything else in this Agreement, Merchant shall be, and will remain, registered for sales, use and other s
            imilar tax collection purposes in all states and localities in which Merchant is required to be so
            registered in connection with the Merchant Offering and pursuant to the terms and redemption of the Voucher,
            and shall be responsible for paying any and all sales, use or any other taxes related to the Merchant
            Offering or the goods and services.
          </span>
          <span>
            e. Transaction Taxes. Merchant bears sole financial responsibility for any and all sales,, including
            any interest penalties and additions related thereto, imposed on or arising from the transactions
            contemplated by this Agreement between Yallabanana and Merchant (“Transaction Taxes”), if any. Yallabanana
            shall apply the applicable Transaction Tax to the amounts it retains and/or other fees remitted to
            Yallabanana pursuant this Agreement. Transaction Taxes are calculated using the Merchant’s billing address
            and will be included on invoices. Tax rates are subject to change. If applied, Transaction Taxes will be
            calculated at the time of each payment using the rates in effect under current law in U.A.E.
          </span>
          <span>
            f. Withholding Taxes. YallaBanana may be required by tax authorities to withhold taxes on behalf of
            Merchant. YallaBanana reserves the right to deduct any such taxes from amounts due to Merchant and to remit
            them to the appropriate tax authority. YallaBanana may also be required to report the withholding tax
            payments to the tax authorities. YallaBanana shall provide evidence of payment of withholding taxes to
            Merchant no later than 60 days after payment of the withholding taxes.
          </span>
          <span>
            g. Notwithstanding anything to the contrary, YallaBanana will have no obligation to advance amounts that
            have been paid to YallaBanana by a purchaser until Merchant has complied with Merchant’s obligations under
            this Agreement. If YallaBanana reasonably believes that Merchant has breached any provision of this
            Agreement, YallaBanana may offset, delay, withhold, or suspend future payments to Merchant, in YallaBanana’s
            sole discretion. In addition, if Merchant is unwilling to, or in YallaBanana’s reasonable discretion appears
            unable to, perform its obligations under this Agreement, YallaBanana is authorized to offset, delay,
            withhold, or suspend future payments to Merchant in addition to such other remedies as may be available
            under this Agreement or at law, to secure payment from Merchant for any refunds and/or other amounts payable
            by Merchant under this Agreement.
          </span>
        </div>
        <div>
          <span>
            5. Customer Data Restrictions
          </span>
          <span>
            a.
            {' '}
            <b>“Customer Data”</b>
            {' '}
            means all identifiable information about purchasers generated or collected by
            YallaBanana or Merchant, including, but not limited to, purchasers’ name, shipping addresses, email
            addresses, phone numbers, purchaser preferences and tendencies, and financial transaction data.
          </span>
          <span>
            b. Merchant shall use Customer Data only to fulfill its redemption obligations in connection with the
            Merchant Offering as authorized by this Agreement. Merchant expressly agrees that any Customer Data shall be
            used only for this purpose (including, but not limited to, the redemption of Vouchers and provision of goods
            and services to purchasers), and not to enhance a file or list owned by Merchant, or any third party.
            Merchant represents, warrants and covenants that it will not resell, broker or otherwise disclose any
            Customer Data to any third party, in whole or in part, for any purpose, unless required by applicable law.
            If Merchant engages any third party to facilitate its redemption obligations hereunder, Merchant shall
            ensure that such third party implements and complies with reasonable security measures in handling any
            Customer Data. If any Customer Data is collected directly by Merchant or a third party engaged by Merchant
            to facilitate its redemption obligations hereunder, Merchant shall ensure that it or such third party adopts
            ,posts and processes the Customer Data in conformity with its posted privacy policy and all applicable laws.
          </span>
          <span>
            c. As long as Merchant uses Customer Data in compliance with applicable law and Merchant’s posted
            privacy policy, restrictions stated in this Agreement on Merchant’s use of Customer Data do not apply to: (i
            ) data from any purchaser who is already a customer of Merchant before the Effective Date, if such data was
            provided to Merchant by such purchaser independent of this Agreement or any transaction hereunder; or (ii)
            data supplied by a purchaser directly to Merchant who becomes a customer of Merchant in connection with such
            purchaser explicitly opting in to receive communications from Merchant.
          </span>
          <span>
            d. Merchant shall immediately notify YallaBanana if Merchant becomes aware of or suspects any
            unauthorized access to or use of Customer Data or any confidential information of YallaBanana, and shall
            cooperate with YallaBanana in the investigation of such breach and the mitigation of any damages. Merchant
            will bear all associated expenses incurred by YallaBanana to comply with applicable laws (including, but not
            limited to, any data breach laws) or arising from any unauthorized access or acquisition of Customer Data
            while such data is in Merchant’s reasonable possession or control. Upon termination or expiration of this
            Agreement, Merchant shall, as directed by YallaBanana, destroy or return to YallaBanana all the Customer
            Data in Merchant’s or any agent of Merchant’s possession.
          </span>
        </div>
        <div>
          <span>
            6. Mobile Redemption Devices
          </span>
          <span>
            If YallaBanana leases or lends Merchant a tablet or mobile redemption device (
            <b>“Device”</b>
            ), Merchant
            agrees to be bound by the terms of use, end user license agreements, or other provisions governing its use,
            unless otherwise authorized by Yallabanana in writing. Unless otherwise stated in writing, Merchant shall
            only use the Device for transmitting redemption data to YallaBanana and processing purchaser payments and
            shall return a loaned Device fourteen (14) days after the Promotional Value Expiration Date, unless a new
            feature is planned or if requested by YallaBanana for any reason. YallaBanana reserves the right to bill
            Merchant for the cost of the Device, or offset any current or future payments due to Merchant under any
            contract between the parties if the device is not returned, or for costs related to damage or other misuse.
          </span>
        </div>
        <div>
          <span>
            7. Promotional Programs
          </span>
          <span>
            In an effort to incentivize Voucher sales, Merchant authorizes YallaBanana, at any time and in
            YallaBanana’s sole discretion, to increase or decrease the Amount Paid for the Merchant Offering (any such
            effort,
            {' '}
            <b>“Promotional Program(s)”</b>
            ). For each Voucher sold as part of a Promotional Program, the Net Remittance
            Amount may be adjusted in an amount equal to the percentage increase or decrease in the Amount Paid
            (
            <b>“Promotional Adjustment”</b>
            ), provided that, any decrease of the Net Remittance Amount will not exceed:
            (i)twenty percent (20%) of the Net Remittance Amount, or (ii) if applicable, the maximum Promotional
            Adjustment specified in the “Payment Terms” section of the YallaBanana Merchant Agreement.
            Promotional Programs includethe following:
          </span>
          <span>
            a. Promotional Codes – A
            {' '}
            <b>“Promotional Code”</b>
            {' '}
            is a code that purchasers may use, in YallaBanana’s sole
            discretion, to receive a discount on the Amount Paid for a Merchant Offering.
          </span>
          <span>
            b. Price Optimization –
            {' '}
            <b>“Price Optimization”</b>
            {' '}
            is any change (excluding Promotional Codes) to the Amount
            Paid for a Merchant Offering.
          </span>
        </div>
        <div>
          <span>
            8. Term and Termination
          </span>
          <span>
            This Agreement will continue in effect until terminated by either party in accordance with this Section
            (
            <b>“Term”</b>
            ). YallaBanana is authorized to terminate this Agreement, at any time for any reason, upon written
            notice to Merchant. Merchant is authorized to terminate this Agreement upon Twenty (20) business days prior
            written notice to YallaBanana.Termination of this Agreement will not in any way affect Merchant’s obligation
            to redeem any Voucher according to the terms of this Agreement, including the obligation to honor the
            Voucher for the Amount Paid after the Promotional Value Expiration Date. Provisions in this Agreement that
            are intended to survive termination will continue in full force and effect after the Term.
          </span>
          <span>
            *The Big Fish Online Services DMCC reserves the right to change the Deal Date subject to prior agreement
            with the Merchant. Such agreement should not be unreasonably denied by the Merchant. The Merchant will be
            informed of any change to the Deal Date forthwith.
          </span>
          <span>
            ** The Big Fish Online Services DMCC may terminate the Agreement by giving written notice if the
            Merchant: (i) Is dissolved; (ii) Ceases to conduct all of its business; (iii) Is or becomes unable to pay
            its debts as they fall due; (iv) Is or becomes insolvent or is declared insolvent.
          </span>
        </div>
        <div>
          <span>
            9. Compliance with Gift Card, Gift Certificate and Abandoned Property Laws
          </span>
          <span>
            Merchant agrees to comply with the Voucher terms and conditions as stated on the Website, including
            but not limited to the “Terms of Use” of the Website, and to ensure that the Vouchers comply with all laws
            that govern vouchers, gift cards, coupons, and gift certificates, and any laws governing the imposition of
            expiration dates, service charges or dormancy fees and all Fine Print related to the Merchant Offering
            stated on the Voucher. Merchant is solely responsible for compliance with any applicable escheat or
            abandoned or unclaimed property laws. Upon written request from Merchant, but only when required,
            YallaBanana will provide Merchant with information in YallaBanana’s possession that the Merchant needs to
            comply with its obligations under this Agreement. Merchant agrees that, regardless of the payment terms,
            Merchant, and not YallaBanana, maintains any obligation for unredeemed Vouchers under applicable escheat
            or abandoned or unclaimed property laws.
          </span>
        </div>
        <div>
          <span>
            10. Intellectual Property Rights
          </span>
          <span>
            a. Merchant grants to YallaBanana a non-exclusive, worldwide, royalty free, paid-up, perpetual,
            irrevocable, transferable and sub-licensable license and right to use, modify, reproduce, sublicense,
            publicly display, distribute, broadcast, transmit, stream, publish and publicly perform: (a) Merchant’s
            name, logos, trademarks, service marks, domain names, and any audiovisual content, video recordings, audio
            recordings, photographs, graphics, artwork, text and any other content provided, specified, recommended,
            directed, authorized or approved to use by Merchant (collectively, “
            <b>Merchant IP”</b>
            ); and (b) any third party’s
            name, logos, trademarks, service marks, domain names, audiovisual recordings, video recordings, audio
            recordings, photographs, graphics, artwork, text and any other content provided, specified, recommended,
            directed, authorized or approved for use by Merchant (collectively,
            {' '}
            <b>“Third Party IP”</b>
            ), in each case in
            connection with the promotion, sale/resale (as may be applicable) or distribution of the Merchant Offering
            in all media or formats now known or hereinafter developed (
            <b>“License”</b>
            ). Any use of the Merchant IP or
            Third-Party IP as contemplated in this Agreement is within YallaBanana’s sole discretion.
          </span>
          <span>
            b. Merchant acknowledges and agrees that, as between the parties, YallaBanana owns all interest in and
            to the Website, Customer Data, YallaBanana trade names, logos, trademarks, service marks, domain names,
            social media identifiers, all data collected through or from the Website, all audiovisual content, video
            recordings, audio recordings, photographs, graphics, artwork, text or any other content created by
            YallaBanana or at YallaBanana’s direction, or assigned to YallaBanana, and any materials, software,
            technology or tools used or provided by YallaBanana to promote, sell/resell (as may be applicable) or
            distribute the Merchant Offering and conduct its business in connection therewith (collectively
            “YallaBanana IP”). Merchant shall not use, sell, rent, lease, sublicense, distribute, broadcast, transmit,
            stream, place shift, transfer, copy, reproduce, download, time shift, display, perform, modify or timeshare
            the YallaBanana IP or any portion thereof, or use such YallaBanana IP as a component of or a base for
            products or services prepared for commercial use, sale, sublicense, lease, access or distribution, except
            that YallaBanana. YallaBanana grants Merchant a limited, non-exclusive, revocable, non-transferable,
            non-sub licensable license during the Term to use one copy of YallaBanana’s mobile merchant software
            application on a single mobile computer, tablet computer, or other device, solely for the purposes
            permitted by that software, and to make one copy of the software for back-up purposes. Merchant shall keep
            the YallaBanana IP confidential, and shall not prepare any derivative work based on the YallaBanana IP
            or translate, reverse engineer, decompile or disassemble the YallaBanana IP. Merchant shall not take any
            action to challenge or object to the validity of YallaBanana’s rights in the YallaBanana IP or
            YallaBanana’s ownership or registration thereof. Except as specifically provided in this Agreement,
            Merchant and any third-party assisting Merchant with its obligations in this Agreement, are not
            authorized to use YallaBanana IP in any medium without prior written approval from an authorized
            representative of YallaBanana. Merchant shall not include any trade name, trademark, service mark, domain
            name, social media identifier, of YallaBanana on or its affiliates, or any variant or misspelling
            thereof, in any trademark, domain name, email address, social network identifier, metadata or search
            engine keyword. Merchant shall not use or display any YallaBanana IP in a manner that could reasonably
            imply an endorsement, relationship, affiliation with, or sponsorship between Merchant or a third party
            and YallaBanana. All rights to the YallaBanana IP not expressly granted in this Agreement are reserved
            by YallaBanana.
          </span>
          <span>
            c. If Merchant provides YallaBanana or any of its affiliates with feedback, suggestions, reviews,
            modifications, data, images, text, or other information or content about a YallaBanana product or service or
            otherwise in connection with this Agreement, any YallaBanana IP, or Merchant’s participation in the Merchant
            Offering or Voucher, (collectively,
            {' '}
            <b>“Feedback”</b>
            ), Merchant irrevocably assigns to YallaBanana all right, title,
            and interest in and to Feedback. In the event your assignment to YallaBanana is invalid for any reason, you
            hereby irrevocably grant YallaBanana and its affiliates a perpetual, paid-up, royalty-free, nonexclusive,
            worldwide, irrevocable, freely transferable right and license to (i) use, reproduce, perform, display, and
            distribute Feedback; (ii) adapt, modify, re-format, and create derivative works of Feedback for any purpose
            and sublicense the foregoing rights to any other person or entity. Merchant warrants that: (A) Feedback is
            Merchant’s original work, or Merchant obtained Feedback in a lawful manner; and (B) YallaBanana and its
            sublicensees’ exercise of rights under the license above will not violate any person’s or entity’s rights,
            including any copyright rights. Merchant agrees to provide YallaBanana such assistance as YallaBanana might
            require to document, perfect, or maintain YallaBanana’s rights in and to Feedback.
          </span>
        </div>
        <div>
          <span>
            11. Representations and Warranties
          </span>
          <span>
            Merchant represents and warrants that: (a) Merchant has the right, power and authority to enter into
            this Agreement; (b) Merchant, if required by applicable law, is registered for sales and use tax collection
            purposes in all jurisdictions where Merchant’s goods and services will be provided; (c) the Voucher, upon
            being delivered by YallaBanana, will be available immediately for redemption and Merchant will have
            sufficient goods and/or services available for redemption through the Promotional Value Expiration Date
            (i.e., a number of goods and/or services sufficient to fulfill its redemption obligations in connection
            with the applicable Maximum Number of Vouchers); (d) the terms and conditions of the Voucher, including
            any discounts or goods and services offered thereunder do not and will not violate any, local, state,
            provincial, territorial or federal law, statute, rule, regulation, or order, including but not limited
            to, any law or regulation governing the use, sale, and distribution of alcohol and any laws governing
            vouchers, gift cards, coupons, and gift certificates; (e) the Merchant’s redemption of the Voucher
            will result in the bona fide provision of goods and/or services by Merchant to the purchaser; (f) Merchant
            owns all interest in and to the Merchant IP and has licensing rights in (with the right to sublicense to
            YallaBanana) the Third Party IP, and has the right to grant the License stated in this Agreement; (g) the
            Merchant IP and the Third Party IP, the Merchant Offering, YallaBanana’s use and promotion thereof, and the
            results of such Merchant Offerings, will not infringe, dilute, misappropriate, or otherwise violate,
            anywhere in the world, any patent, copyright, logo, trademark, service mark, trade name, rights in designs,
            or other intellectual property right or right of privacy or publicity of any third party or any applicable
            law, and does not and will not result from the misappropriation of any trade secret or the breach of any
            confidentiality obligations to any person or entity; (h) the Merchant IP and Third Party IP does not include
            any material that is unlawful, threatening, abusive, defamatory, vulgar, obscene, profane or otherwise
            objectionable, or that encourages conduct that constitutes a criminal offense, gives rise to civil liability
            or otherwise violates any law; (i) the Vouchers and any advertising or promotion of Merchant’s goods and
            services relating thereto will not constitute false, deceptive or unfair advertising or disparagement under
            any applicable law; (j) Merchant and its employees, contractors and agents have had the proper education and
            training and hold all required and up-to-date regulatory authorization, licenses and certifications relating
            to any Merchant Offering to provide the goods or services described in this Agreement; (k) Merchant’s
            business information and direct deposit details as provided in this Agreement, indicating where payments
            should be forwarded are accurate and Merchant is the authorized entity to receive the funds forwarded by
            YallaBanana; (l) Merchant is not authorized to resell, broker or otherwise disclose any Customer Data (as
            defined in this Agreement) to any third party, in whole or in part, for any purpose, and Merchant is not
            authorized to copy or otherwise reproduce any Customer Data other than for the purpose of redeeming or
            verifying the validity of Vouchers in connection with this Agreement and (m) the Merchant Offering is: (i)
            free from defects in workmanship, materials and design, (ii) merchantable and suitable for the purposes, if
            any, stated in the Agreement, and (iii) genuine, bona fide products, as described herein and does not
            violate the rights of any third party.
          </span>
        </div>
        <div>
          <span>
            12. Indemnification
          </span>
          <span>
            To the extent allowed under applicable law, Merchant agrees to defend, indemnify and hold YallaBanana,
            its affiliated and related entities, and any of its respective officers, directors, agents and employees,
            harmless from and against any claims, lawsuits, investigations, penalties, damages, losses or expenses
            (including but not limited to reasonable attorneys’ fees and costs) arising out of or relating to any of the
            following: (a) any breach or alleged breach by Merchant of this Agreement, or the representations and
            warranties made in this Agreement; (b) any claim for state sales, use, or similar tax obligations of
            Merchant arising from the sale and redemption of a Voucher; (c) any claim by any local, state, provincial,
            territorial or federal governmental entity for unredeemed Vouchers or unredeemed cash values of Vouchers or
            any other amounts under any applicable abandoned or unclaimed property or escheat law, including but not
            limited to any claims for penalties and interest; (d) any claim arising out of a violation of any law or
            regulation by Merchant or governing Merchant’s goods and/or services; (e) any claim arising out of Merchant’
            s violation of law or regulation governing the use, sale, and distribution of alcohol; (f) any claim by a
            purchaser or anyone else arising out of or relating to the goods and services provided by Merchant and/or
            pick up of the goods and services at the Redemption Site, including but not limited to, any claims for false
            advertising, product defects, personal injury, death, or property damages; (g) any claim by a purchaser for
            the Amount Paid; (h) any claim arising out of Merchant’s misuse of Customer Data, or any violation of an
            applicable data privacy or security law; and (i) any claim arising out of Merchant’s negligence, fraud or
            willful misconduct. YallaBanana maintains the right to control its own defense and to choose and appoint its
            own defense counsel, regardless of the presence or absence of a conflict of interest between YallaBanana and
            Merchant. Merchant’s duty to defend and indemnify YallaBanana includes the duty to pay YallaBanana’s
            reasonable attorneys’ fees and costs, including any expert fees.
          </span>
        </div>
        <div>
          <span>
            13. Confidentiality
          </span>
          <span>
            The terms for the Merchant Offering described in this Agreement are confidential, and Merchant agrees
            not to disclose the terms described in this Agreement to any party (other than to its employees, parent
            companies, shareholders, lawyers and accountants on a strict need-to-know basis or as required by applicable
            public records and other law, if Merchant has taken the necessary precautions of the kind generally taken
            with confidential information to preserve the confidentiality of the information made available to such
            parties). In the event of a breach, YallaBanana is entitled to injunctive relief and a decree for specific
            performance, and any other relief allowed under applicable law (including monetary damages if appropriate).
          </span>
        </div>
        <div>
          <span>
            14. Limitation of Liability
          </span>
          <span>
            Except for merchant’s indemnification obligations hereunder, in no event is either party liable or
            obligated to the other party or any third party for any lost profits, lost business, special, incidental,
            exemplary, consequential, punitive, or indirect damages regardless of the form of action, whether in
            contract, tort or otherwise, even if informed of the possibility of any such damages in advance. Yallabanana
            ’s sole and complete liability to merchant for any claims arising out of or relating to this agreement, or
            any errors, omissions or misplacements of any voucher is limited to the amount of fees retained by
            yallabanana hereunder for the preceding six (6) months after final calculation and reconciliation of all
            refunds. This limitation of liability applies to the maximum extent permitted by applicable law and not with
            standing the failure of any limited remedy. In addition, any claim by or on behalf of a merchant in
            connection with any payment made by yallabanana, including, but not limited to, claims alleging that a
            merchant was underpaid, must be made in writing to yallabanana within ninety (90) days from the date
            yallabanana remits the payment at issue. All claims not made in accordance with the foregoing shall be
            deemed waived, released and discharged by merchant.
          </span>
        </div>
        <div>
          <span>
            15. Dispute Resolution
          </span>
          <span>
            All disputes arising out of, or relating in any way to this Agreement, shall be resolved pursuant to
            this Section 14 Dispute Resolution.
          </span>
          <span>
            a. Class Action Waiver. We each agree that we shall bring any dispute against the other in our
            respective individual capacities and not as a plaintiff or class member in any purported class,
            representative proceeding or as an association.
          </span>
          <span>
            b. Injunctive Relief/Attorneys’ Fees. Notwithstanding anything to the contrary in this Agreement,
            either party may bring suit in court seeking an injunction or other equitable relief arising out of or
            relating to claims that the other party’s conduct may cause the other irreparable injury. In the event
            YallaBanana is the prevailing party in any Dispute, subject to any exceptions in this Section 14, Merchant
            shall pay to YallaBanana all reasonable attorneys’ fees and costs incurred by YallaBanana in connection with
            any Dispute.
          </span>
        </div>
        <div>
          <span>
            16. Applicable Law and Jurisdiction
          </span>
          <span>
            a. All issues, questions and disputes concerning the validity, interpretation, enforcement, performance
            and termination of this Agreement shall be governed by and construed in accordance with UAE law, and no
            effect shall be given to any other choice-of-law or conflict-of-laws rules or provisions that would cause
            the laws of any other jurisdiction to be applicable.
          </span>
          <span>
            b. All disputes concerning the validity, interpretation, enforcement, performance and termination of
            this Agreement shall be submitted to the exclusive jurisdiction of the __________ Dubai court
          </span>
        </div>
        <div>
          <span>
            17. These Merchant Terms and Conditions (the “Terms and Conditions”) are effective to each transaction
            (“Deal”) between YallaBanana and Merchant and concluded as an Agreement between the Parties.
          </span>
        </div>
        <div>
          <span>
            18. Yallabanana reserves the right, in own discretion, to change or modify all or any part of the
            Agreement at any time, effective immediately upon notice send via email. If at any time the terms and
            conditions of the Agreement are no longer acceptable to Merchant, it should immediately inform Yallabanana
            which shall be considered as Agreement termination.
          </span>
        </div>
        <div>
          <span>
            19. Other
          </span>
          <span>
            a. The parties are independent contractors. Nothing in this Agreement is to be construed to create a
            joint venture, partnership, franchise, or an agency relationship between the parties. Neither party has the
            authority, without the other party’s prior written approval, to bind or commit the other in any way.
          </span>
          <span>
            b. This Agreement constitutes the entire agreement between the parties relating to its subject matter
            and supersedes all prior or contemporaneous oral or written agreements concerning such subject matter.
          </span>
          <span>
            c. Merchant is not authorized to transfer or assign its rights or obligations under this Agreement,
            whether by operation of law or otherwise, without Yallabanana’s prior written consent. Any waiver must be in
            writing and signed by an authorized signatory of Yallabanana. Yallabanana is authorized to transfer or
            assign this Agreement to a present or future affiliate or pursuant to a merger, consolidation,
            reorganization or sale of all or substantially all of the assets or business, or by operation of law,
            without notice to Merchant.
          </span>
          <span>
            d. If any provision of this Agreement should be held to be invalid or unenforceable, the validity and
            enforceability of the remaining provisions of this Agreement are not affected.
          </span>
        </div>
        <div>
          <span>
            For and on behalf of the ((yallabanana))
          </span>
          <span>
            That the terms & conditions
          </span>
          <span>
            Regulation the agreement between yallabanana and the other parties
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(MerchantTerms);
