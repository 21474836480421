import React, { memo, useContext, useEffect } from 'react';
import { Form, Input } from 'antd';
import s from './SecuritySignIn.module.scss';
import securityIcon from '../../assets/images/Security.png';
import { SecurityContext } from '../SecurityLayout/SecurityLayout';
import Loader from '../Loader';

const SecuritySignIn = () => {
  const [form] = Form.useForm();

  const { loginLoading, loginCallback, error } = useContext(SecurityContext);

  const onFormSubmit = () => {
    const values = form.getFieldsValue();
    loginCallback(values);
  };

  useEffect(() => {
    if (error) {
      form.setFields([{ name: 'email', errors: [error] }]);
    }
  }, [error]);

  return (
    <div className={s.main}>
      {loginLoading && <Loader />}
      <div className={s.wrapper}>
        <img
          className={s.logo}
          src={securityIcon}
          alt="Security logo"
        />
        <div className={s.container}>
          <span className={s.title}>
            Security sign in
          </span>
          <Form
            className={s.form}
            form={form}
            onFinish={onFormSubmit}
          >
            <Form.Item
              className={s.item}
              label="Email or username"
              name="email"
              rules={[{ required: true, message: 'This field is required!', validateTrigger: 'onChange' }]}
            >
              <Input className={s.input} />
            </Form.Item>
            <Form.Item
              className={s.item}
              label="Password"
              name="password"
              rules={[{ required: true, message: 'This field is required!' }]}
            >
              <Input.Password className={s.input} />
            </Form.Item>
            <Form.Item>
              <button
                type="submit"
                className={s.button}
              >
                Sign in
              </button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default memo(SecuritySignIn);
