import React, { memo, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import GoogleMapComponent from './GoogleMapComponent';
import MapContext from '../context/MapContext';
import s from './location.module.scss';

const LocationBlock = ({ locations, typeDevice, titleTargetLinkForMap }) => {
  const styleMapWrapper = useMemo(() => ({
    width: '100%',
    height: '300px',
    borderRadius: '4px',
    overflow: 'hidden',
  }), []);

  const { mapData } = useContext(MapContext);

  const finalWrapClass = typeDevice === 'desktop' ? s.locationWrapperDesk : s.locationWrapperMob;
  const finalTitleClass = typeDevice === 'desktop' ? s.titleDesc : s.titleMob;
  return (
    <div className={finalWrapClass}>
      <h5 className={finalTitleClass}>Location</h5>
      <div className={typeDevice === 'mobile' ? s.wrappMap : ''}>
        <GoogleMapComponent
          locations={locations}
          zoom={14}
          styleMapWrapper={styleMapWrapper}
          titleTargetLinkForMap={titleTargetLinkForMap}
          selectedAddress={mapData}
        />
      </div>
    </div>
  );
};

LocationBlock.propTypes = {
  locations: PropTypes.oneOfType([PropTypes.array]).isRequired,
  typeDevice: PropTypes.string.isRequired,
  titleTargetLinkForMap: PropTypes.string.isRequired,
};

export default memo(LocationBlock);
