import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import { useSearchParams } from 'react-router-dom';
import validatorForm from '../../../../helpers/validator';
import { CloseIcon } from '../../../../constants/icons';
import { PROMO_CODE_SCHEMA } from '../../../../constants/promoCodes/promocodeValidatorSchema/validatorSchema';
import PrecentageBlock from './PrecentageBlock/PrecentageBlock';
import QuantityBlock from './QuantityBlock/QuantityBlock';
import TiedBlock from './TiedBlock/TiedBlock';
import DueDateBlock from './DueDateBlock/DueDateBlock';
import ButtonsBlock from './ButtonsBlock/ButtonsBlock';
import CommentBlock from './CommentBlock/CommentBlock';
import ActiveBlock from './ActiveBlock/ActiveBlock';
import style from '../../ModalForm.module.scss';
import './modal-promocode-ant.scss';

const ModalPromocodeComponent = (props) => {
  const {
    isOpenModal,
    promoCodeData,
    closeAddModal,
    setPromoCodeInfoHandler,
    coupons,
    optionsData,
    loadingGetOptions,
    loadingGetCoupons,
    createPromoCodeAdminHandler,
    promoCodeOperationOk,
  } = props;

  const {
    discountUnit,
    discount,
    quantity,
    availableAmount,
    tied,
    comment,
    validDueDate,
    couponId,
    couponOptionId,
    isActive,
  } = promoCodeData;

  const [form] = Form.useForm();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') || '';

  const [allErrors, setAllErrors] = useState(null);

  const onCancelModalHandler = () => {
    closeAddModal();
    form.resetFields();
  };

  const sendFormData = () => {
    const {
      comment, ...rest
    } = promoCodeData;

    const { errors } = validatorForm({
      ...rest,
      comment: comment && comment.trim(),
    }, PROMO_CODE_SCHEMA);

    if (!Object.keys(errors).length) {
      createPromoCodeAdminHandler({
        ...promoCodeData,
        comment: comment.trim(),
      });
    } else {
      setAllErrors(errors);
    }
  };
  if (promoCodeOperationOk) {
    onCancelModalHandler();
  }

  return (
    <Modal
      title={id !== 'create-promo-code' ? 'Edit promo code' : 'Add promo code'}
      centered
      closeIcon={<CloseIcon />}
      visible={isOpenModal}
      onCancel={closeAddModal}
      width={440}
      cancelText="Cancel"
      footer={null}
      wrapClassName="modalPromoCode"
    >
      <Form
        layout="vertical"
        requiredMark={false}
      >
        {
          id !== 'create-promo-code' && (
            <Form.Item
              label="Status"
              className={style.status}
            >
              <ActiveBlock
                setPromoCodeInfoHandler={setPromoCodeInfoHandler}
                isActive={isActive}
              />
            </Form.Item>
          )
        }
        <Form.Item label="Percentage/Sum">
          <PrecentageBlock
            discountUnit={discountUnit}
            discount={String(discount)}
            setPromoCodeInfoHandler={setPromoCodeInfoHandler}
            allErrors={allErrors}
          />
        </Form.Item>
        <Form.Item
          name="Date"
          label="Due date"
          className={style.formItem}
        >
          <DueDateBlock
            validDueDate={validDueDate}
            allErrors={allErrors}
            setPromoCodeInfoHandler={setPromoCodeInfoHandler}
          />
        </Form.Item>
        <Form.Item label="Quantity">
          <QuantityBlock
            availableAmount={availableAmount}
            allErrors={allErrors}
            quantity={quantity}
            setPromoCodeInfoHandler={setPromoCodeInfoHandler}
          />
        </Form.Item>
        <Form.Item
          name="Tied"
          label="Tied to"
          className={style.tied}
        >
          <TiedBlock
            allErrors={allErrors}
            setPromoCodeInfoHandler={setPromoCodeInfoHandler}
            couponOptionId={couponOptionId}
            tied={tied}
            couponId={couponId}
            coupons={coupons}
            loadingGetCoupons={loadingGetCoupons}
            loadingGetOptions={loadingGetOptions}
            optionsData={optionsData}
          />
        </Form.Item>
        <Form.Item
          name="Note"
          label="Note (max 250 symbols)"
          className={style.note}
        >
          <CommentBlock
            allErrors={allErrors}
            setPromoCodeInfoHandler={setPromoCodeInfoHandler}
            comment={comment}
          />
        </Form.Item>
      </Form>
      <ButtonsBlock
        id={id}
        sendFormData={sendFormData}
        onCancelModalHandler={onCancelModalHandler}
      />
    </Modal>
  );
};

ModalPromocodeComponent.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  promoCodeData: PropTypes.object.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  closeAddModal: PropTypes.func.isRequired,
  setPromoCodeInfoHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  promoCodeOperationOk: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  coupons: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  optionsData: PropTypes.array,
  loadingGetOptions: PropTypes.bool.isRequired,
  loadingGetCoupons: PropTypes.bool.isRequired,
  createPromoCodeAdminHandler: PropTypes.func.isRequired,
};
ModalPromocodeComponent.defaultProps = {
  coupons: undefined,
  optionsData: undefined,
  promoCodeOperationOk: undefined,
};

export default memo(ModalPromocodeComponent);
