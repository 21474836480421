import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';
import './checkout.scss';
import mobileCondition from '../../constants/mobileCondition';

const Checkout = () => {
  const containerClassName = `containerCheckout ${mobileCondition ? 'mobile' : ''}`;
  return (<div className={containerClassName}><Outlet /></div>);
};

export default memo(Checkout);
