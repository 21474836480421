import React from 'react';
import { Table as AntTable } from 'antd';
import PropTypes from 'prop-types';
import { Expand, PaginationLeftArrow, PaginationRightArrow } from '../../constants/icons';
import style from './Table.module.scss';
import './Pagination.scss';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { heightMinusForAdminTables } from '../../constants/general/tag-status';

const expandIcon = ({
  expanded, onExpand, record, expandable,
}) => (
  expandable
    && (
      <span
        className={expanded ? style.expanded : style.expand}
        onClick={(e) => onExpand(record, e)}
      >
        <Expand />
      </span>
    )
);

const PaginationItemRender = (current, type, originalElement) => {
  if (type === 'prev') {
    return <PaginationLeftArrow />;
  }

  if (type === 'next') {
    return <PaginationRightArrow />;
  }

  return originalElement;
};

const Table = ({ columns, dataSource, pagination }) => {
  const [height] = useWindowSize();
  return (
    <AntTable
      className="tableCategories adminTable"
      columns={columns}
      dataSource={dataSource}
      pagination={{ ...pagination, itemRender: PaginationItemRender }}
      scroll={{
        y: height - heightMinusForAdminTables, x: 0,
      }}
      expandable={{
        rowExpandable: () => false,
        expandIcon,
        indentSize: 0,
      }}
    />
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    dataIndex: PropTypes.string,
    render: PropTypes.func,
    ellipsis: PropTypes.bool,
    filters: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.bool,
    })),
    onFilter: PropTypes.func,
    filterIcon: PropTypes.node,
  })).isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({
    categoryPicture: PropTypes.shape({
      categoryId: PropTypes.string,
      id: PropTypes.string,
      key: PropTypes.string,
      mimetype: PropTypes.string,
      originalName: PropTypes.string,
    }),
    couponsCount: PropTypes.number,
    id: PropTypes.string,
    isPublished: PropTypes.bool,
    key: PropTypes.string,
    parentCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    position: PropTypes.number,
    subCategories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      position: PropTypes.number,
      isPublished: PropTypes.bool,
      couponsCount: PropTypes.number,
      parentCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      categoryPicture: PropTypes.shape({
        categoryId: PropTypes.string,
        id: PropTypes.string,
        key: PropTypes.string,
        mimetype: PropTypes.string,
        originalName: PropTypes.string,
      }),
    })),
    title: PropTypes.string,
  })).isRequired,
  pagination: PropTypes.shape({
    position: PropTypes.arrayOf(PropTypes.string),
    size: PropTypes.string,
    pageSize: PropTypes.number,
  }).isRequired,
};

export default Table;
