import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_PASSWORD } from '../../../../../api/query';

const useSendNewPassword = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [createPassword, { loading, error }] = useMutation(CREATE_PASSWORD);
  const changePasswordHandler = useCallback(async (data) => {
    const { newPassword, confirmPassword } = data;
    try {
      await createPassword({
        variables: {
          changePasswordInput: {
            newPassword,
            confirmPassword,
          },
          id,
        },
      });
      // navigate('/admin/signIn', { replace: true });
      navigate('/', { replace: true });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [createPassword, id, navigate]);

  return {
    changePasswordHandler,
    loading,
    error,
  };
};

export default useSendNewPassword;
