import { useState, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const Portal = ({ children, className, element }) => {
  const [container] = useState(() => {
    const el = document.createElement(element);
    if (className) {
      el.classList.add(className);
    }
    return el;
  });

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, [container]);

  return createPortal(children, container);
};

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  element: PropTypes.string,
};

Portal.defaultProps = {
  className: '',
  element: 'div',
};

export default memo(Portal);
