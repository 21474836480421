import PropTypes from 'prop-types';
import '../modal.scss';

const PriceVoucherWithoutVat = ({ voucherPrice }) => (
  <>
    <div className="bodyModalReceipt__finally">Price (that price VAT inclusive)</div>
    <div className="bodyModalReceipt__data">{voucherPrice}</div>
  </>
);

PriceVoucherWithoutVat.propTypes = {
  voucherPrice: PropTypes.number.isRequired,
};

export default PriceVoucherWithoutVat;
