import React, { memo } from 'react';
import PhoneInput from 'react-phone-input-2';
import EditPasswordComponent from '../EditPasswordComponent';
import s from './MerchantInfo.module.scss';
import useAuthAdmin from '../../../../context/context-admin/useAuthAdmin';

const MerchantInfo = () => {
  const { userInfo: { email, merchant: totalData, firstName } } = useAuthAdmin();

  const { filialBranches } = totalData;

  const [{ address, phone, title }] = filialBranches;

  return (
    <div className={s.main}>
      {totalData && (
        <>
          <div className={s.container}>
            <span className={s.subtitle}>Company name</span>
            <span className={s.content}>{title}</span>
          </div>
          {address && (
            <div className={s.container}>
              <span className={s.subtitle}>Address</span>
              <span className={s.content}>{address}</span>
            </div>
          )}
          <div className={s.container}>
            <span className={s.subtitle}>Contact person</span>
            <span className={s.content}>{firstName}</span>
          </div>
          <div className={s.container}>
            <span className={s.subtitle}>Phone</span>
            <PhoneInput
              disabled
              inputClass={s.phone}
              value={phone || '-'}
              buttonClass={s.phoneButton}
              containerStyle={{ marginTop: '10px' }}
            />
          </div>
          <div className={s.container}>
            <span className={s.subtitle}>Email</span>
            <span className={s.content}>{email}</span>
          </div>
          <EditPasswordComponent />
        </>
      )}
    </div>
  );
};

export default memo(MerchantInfo);
