import React, { memo, useContext } from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import mobileCondition from '../../../constants/mobileCondition';
import s from './Filter.module.scss';
import './filter.scss';
import { ArrowLeft, FilterIcon, SearchIcon } from '../../../constants/icons';
import useFilterForm from '../hooks/useFilterForm/useFilterForm';
import CatalogContext from '../context/catalogContext';
import CommonRadioGroup from '../../Common_components/CommonRadioGroup';
import { emiratesItems } from '../../../constants/merchantConstant';
import CommonCheckbox from '../../Common_components/CommonCheckbox';

const Filter = ({ isFormShow, setIsFormShow }) => {
  const [form] = Form.useForm();

  const { setSearchParams, params } = useContext(CatalogContext);

  const { emirates } = params;

  const {
    handleDiscountValue, handlePriceValue, resetFormValues, setParamsOnEnterPress, setFilterParams, handleTitleValue,
    checkboxGroupChange, sortByPrice, isSortedPrice,
  } = useFilterForm(form, setSearchParams, params);

  const resetFormOnClick = () => {
    form.resetFields();
    resetFormValues();
  };

  const hideFiltersForm = () => setIsFormShow(false);

  const mainClassName = `${s.main} ${mobileCondition && s.mobile}`;

  const mobileFiltersClassName = `${s.mobileFilters} ${isFormShow && s.active}`;

  const resetMobileForm = () => {
    resetFormOnClick();
    hideFiltersForm();
  };

  const allowMobileFormFilters = () => {
    setFilterParams();
    hideFiltersForm();
  };

  return (
    <div className={`${mainClassName} filterCoupons`}>
      {!mobileCondition ? (
        <>
          <div className={s.header}>
            <div>
              <FilterIcon />
              <span>Filter</span>
            </div>
            <span onClick={resetFormOnClick}>Reset</span>
          </div>
          <Form
            className={s.form}
            form={form}
            layout="vertical"
          >
            <Form.Item
              className={s.item}
              name="title"
            >
              <div>
                <Input
                  name="title"
                  placeholder="Search..."
                  onChange={handleTitleValue}
                  onKeyPress={setParamsOnEnterPress}
                  className={s.titleInput}
                />
                <div
                  className={s.searchIcon}
                  onClick={setFilterParams}
                >
                  <SearchIcon />
                </div>
              </div>
            </Form.Item>
            <Form.Item
              name="emirates"
              label="Emirates"
            >
              <CommonRadioGroup
                defaultValues={emirates}
                onChange={checkboxGroupChange}
                items={emiratesItems}
              />
            </Form.Item>
            <div className={s.inputs}>
              <span className={s.label}>
                Price, AED
              </span>
              <Form.Item
                className={s.item}
                name="priceMin"
              >
                <Input
                  name="priceMin"
                  onChange={handlePriceValue}
                  onKeyPress={setParamsOnEnterPress}
                  className={s.input}
                />
              </Form.Item>
              <div className={s.empty} />
              <Form.Item
                className={s.item}
                name="priceMax"
              >
                <Input
                  name="priceMax"
                  onChange={handlePriceValue}
                  onKeyPress={setParamsOnEnterPress}
                  className={s.input}
                />
              </Form.Item>
            </div>
            <div className={s.inputs}>
              <span className={s.label}>
                Discount, %
              </span>
              <Form.Item
                className={s.item}
                name="discountMin"
              >
                <Input
                  onChange={handleDiscountValue}
                  onKeyPress={setParamsOnEnterPress}
                  name="discountMin"
                  className={s.input}
                />
              </Form.Item>
              <div className={s.empty} />
              <Form.Item
                className={s.item}
                name="discountMax"
              >
                <Input
                  name="discountMax"
                  onChange={handleDiscountValue}
                  onKeyPress={setParamsOnEnterPress}
                  className={s.input}
                />
              </Form.Item>
            </div>
            <CommonCheckbox
              value={isSortedPrice}
              addClass={s.top}
              label="Show from low to high"
              keyValue="sortedByPrice"
              onChange={sortByPrice}
            />
          </Form>
        </>
      )
        : (
          <Form
            className={s.form}
            form={form}
            layout="vertical"
          >
            <Form.Item
              className={s.item}
              name="title"
            >
              <div>
                <Input
                  name="title"
                  placeholder="Search..."
                  onChange={handleTitleValue}
                  onKeyPress={setParamsOnEnterPress}
                  className={s.titleInput}
                />
                <div
                  className={s.searchIcon}
                  onClick={setFilterParams}
                >
                  <SearchIcon />
                </div>
              </div>
            </Form.Item>
            <div
              className={mobileFiltersClassName}
            >
              <div className={s.wrapper}>
                <div className={s.title}>
                  <div onClick={hideFiltersForm}>
                    <ArrowLeft />
                  </div>
                  <span>Filter</span>
                </div>
                <div className={s.inputs}>
                  <span className={s.label}>
                    Price, AED
                  </span>
                  <Form.Item
                    className={s.item}
                    name="priceMin"
                  >
                    <Input
                      name="priceMin"
                      onChange={handlePriceValue}
                      onKeyPress={setParamsOnEnterPress}
                      className={s.input}
                    />
                  </Form.Item>
                  <div className={s.empty} />
                  <Form.Item
                    className={s.item}
                    name="priceMax"
                  >
                    <Input
                      name="priceMax"
                      onChange={handlePriceValue}
                      onKeyPress={setParamsOnEnterPress}
                      className={s.input}
                    />
                  </Form.Item>
                </div>
                <div className={s.inputs}>
                  <span className={s.label}>
                    Discount, %
                  </span>
                  <Form.Item
                    className={s.item}
                    name="discountMin"
                  >
                    <Input
                      onChange={handleDiscountValue}
                      onKeyPress={setParamsOnEnterPress}
                      name="discountMin"
                      className={s.input}
                    />
                  </Form.Item>
                  <div className={s.empty} />
                  <Form.Item
                    className={s.item}
                    name="discountMax"
                  >
                    <Input
                      name="discountMax"
                      onChange={handleDiscountValue}
                      onKeyPress={setParamsOnEnterPress}
                      className={s.input}
                    />
                  </Form.Item>
                </div>
                <CommonCheckbox
                  value={isSortedPrice}
                  addClass={s.top}
                  label="Show from low to high"
                  keyValue="sortedByPrice"
                  onChange={sortByPrice}
                />
              </div>
              <div className={s.buttons}>
                <button
                  onClick={resetMobileForm}
                  className={s.reset}
                >
                  Reset
                </button>
                <button
                  onClick={allowMobileFormFilters}
                  className={s.allow}
                >
                  Allow
                </button>
              </div>
            </div>
          </Form>
        )}
    </div>
  );
};

Filter.propTypes = {
  isFormShow: PropTypes.bool,
  setIsFormShow: PropTypes.func,
};

Filter.defaultProps = {
  isFormShow: undefined,
  setIsFormShow: undefined,
};

export default memo(Filter);
