import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import s from './CartBlock.module.scss';
import { Cart } from '../../../constants/icons';
import { setWindowOffset } from '../../../helpers/catalog-helpers/catalog-helpers';
import { CART } from '../../../constants/Routes';

const CartBlock = ({ count }) => {
  const navigate = useNavigate();
  const navigateToCart = () => {
    setWindowOffset();
    navigate(CART);
  };
  return (
    <div
      onClick={navigateToCart}
      className={s.cart}
    >
      <Cart />
      <span className={s.content}>
        Cart (
        {count}
        )
      </span>
    </div>
  );
};

CartBlock.propTypes = {
  count: PropTypes.number.isRequired,
};

export default memo(CartBlock);
