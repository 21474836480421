import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { LOGOUT } from '../../api/query';
import { clearAccessTokenUser, clearRoleUser } from '../../helpers/user-storage';
import UserContext from '../../components/Layout/context/userContext';
import CountContext from '../../components/Layout/context/countContext';
import { TITLE_COOKIES } from '../../constants/general/tag-status';

const useLogoutMutation = () => {
  const { updateLocalCouponsCount } = useContext(CountContext);

  const navigate = useNavigate();

  const { setUserData } = useContext(UserContext);

  const [logout, { loading: logoutLoading }] = useMutation(LOGOUT);

  const logoutCallback = () => {
    logout({
      variables: {
        refreshToken: Cookies.get(TITLE_COOKIES),
      },
    })
      .then((res) => {
        if (res) {
          clearAccessTokenUser();
          clearRoleUser();
          Cookies.remove(TITLE_COOKIES);
          updateLocalCouponsCount(0);
          setUserData(null);
          navigate('/');
        }
      });
  };

  return { logoutCallback, logoutLoading };
};

export default useLogoutMutation;
