import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { STATUS_TABLE_ACTIVE } from '../../../../../constants/couponsAdmin';
import { limit } from '../../../../../constants/tableConstants';
import useWindowSize from '../../../../../hooks/useWindowSize/useWindowSize';
import { heightMinusForTableCoupons } from '../../../../../constants/general/tag-status';
import './table-component.scss';

const TableComponent = ({ columns, dataSource = [], count }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams.get('status') || STATUS_TABLE_ACTIVE;
  const page = searchParams.get('page') || '1';

  const onChangePage = (page) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      status,
      page,
    });
  };

  const [height] = useWindowSize();

  return (
    <section className="all-admin-table all-admin-table_couponTable">
      <Table
        className="adminTable"
        dataSource={dataSource}
        columns={columns}
        scroll={{
          y: height - heightMinusForTableCoupons, x: 0,
        }}
        rowKey={(rec) => rec.id}
        pagination={{
          current: Number(page),
          pageSize: limit,
          total: count,
          position: ['bottomCenter'],
          size: 'small',
          showSizeChanger: false,
          onChange: (page) => onChangePage(page),
        }}
      />
    </section>
  );
};

TableComponent.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  dataSource: PropTypes.oneOfType([PropTypes.array]),
  count: PropTypes.number,
};
TableComponent.defaultProps = {
  count: undefined,
  dataSource: undefined,
};

export default memo(TableComponent);
