import { Quantity, Tied } from '../promoCodes';

export const a = 1;

export const PROMO_CODE_SCHEMA = {
  comment: {
    required: true,
    errorMessage: 'This field is required!',
  },
  validDueDate: {
    required: true,
    errorMessage: 'This field is required!',
  },
  discount: {
    required: true,
    errorMessage: 'This field is required!',
  },
  availableAmount: {
    rules: [{
      errorMessage: 'This field is required!',
      ruleProp: 'quantity',
      ruleValidation: (item, ruleProp) => {
        if (ruleProp === Quantity.MULTIPLE) {
          return !!+item;
        }
        return true;
      },
    }],
  },
  couponId: {
    required: true,
    errorMessage: 'This field is required!',
  },
  couponOptionId: {
    rules: [{
      errorMessage: 'This field is required!',
      ruleProp: 'tied',
      ruleValidation: (item, ruleProp) => {
        if (ruleProp === Tied.OPTION) {
          return !!item;
        }
        return true;
      },
    }],
  },
};
