import React, { useRef } from 'react';
import './partnersSlider.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import _ from 'lodash';
import { ArrowLeft, ArrowRight } from '../CouponPage/constants/icons';
import mobileCondition from '../../constants/mobileCondition';

const getImagesArray = (count = 33) => Array(count).fill(0)
  .map((_, idx) => idx);

const getSliderGroups = (array, slideSize = 12) => {
  const result = [];
  while (array.length) {
    result.push(array.splice(0, slideSize));
  }
  return result;
};

const PartnersSlider = () => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const slideClassName = `partners-slider-slide ${mobileCondition ? 'partners-slider-slide_mobile' : ''}`;

  const sliderGroups = getSliderGroups(getImagesArray(), mobileCondition ? 6 : 12);

  const preventSelectionHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const slides = sliderGroups.map((group) => {
    const mappedGroup = group.map((src) => (
      <div className="partners-slide-container-image">
        <img
          className="partners-slide-image"
          key={src}
          src={require(`../../assets/images/partners/Partner-${src}.png`)}
          alt="Partner"
        />
      </div>
    ));

    return (
      <SwiperSlide key={_.uniqueId()}>
        <div className={slideClassName}>
          {mappedGroup}
        </div>
      </SwiperSlide>
    );
  });

  const titleClassName = `partners-slider-title ${mobileCondition ? 'partners-slider-title_mobile' : ''}`;

  const sliderClassName = `partners-slider ${mobileCondition ? 'partners-slider_mobile' : ''}`;

  return (
    <div className={sliderClassName}>
      <h2 className={titleClassName}>Our Partners</h2>
      <Swiper
        className="partners-slider-swiper"
        navigation={{
          nextEl: nextRef.current,
          prevEl: prevRef.current,
        }}
        pagination={mobileCondition && {
          horizontalClass: 'bullets',
          clickable: true,
        }}
        loop
        modules={[Navigation]}
      >
        {slides}
        {!mobileCondition && (
          <>
            <div
              onMouseDown={preventSelectionHandler}
              ref={prevRef}
              className="partners-slider-button partners-slider-button-prev"
            >
              <ArrowLeft />
            </div>
            <div
              onMouseDown={preventSelectionHandler}
              ref={nextRef}
              className="partners-slider-button partners-slider-button-next"
            >
              <ArrowRight />
            </div>
          </>
        )}
      </Swiper>
    </div>
  );
};

export default PartnersSlider;
