import React, { memo } from 'react';
import { Checkbox, Select } from 'antd';
import PropTypes from 'prop-types';
import { finallyBorder } from '../../Coupon/CouponMaking/Coupon/helpers/finallyBorder';
import './SelectMutliply.scss';

const { Option } = Select;

const SelectMultiply = ({
  callback, address, merchants, activeOption, error,
}) => {
  const handleChange = (value) => {
    callback(value);
  };
  const addressCurrent = () => {
    const address = [];
    if (merchants) {
      const currentMerchant = merchants.find((m) => m.id === activeOption);
      if (currentMerchant) {
        return currentMerchant.filialBranches;
      }
      return address;
    }
    return address;
  };

  return (
    <div
      style={{ width: '100%' }}
    >
      { merchants && (
        <Select
          disabled={!activeOption}
          mode="multiple"
          style={{
            width: '100%',
            border: error ? finallyBorder(address && address.length > 0) : '1px solid #EDEDED',
            borderRadius: '4px',
            boxSizing: 'border-box',
            lineHeight: '40px',
          }}
          onChange={handleChange}
          optionLabelProp="label"
          menuItemSelectedIcon={<Checkbox checked />}
          maxTagCount={2}
          value={address}
        >

          {
            addressCurrent()
              .map((option) => (
                <Option
                  key={option.id}
                  value={option.id}
                  label={option.title}
                >
                  <div className="demo-option-label-item">
                    <span
                      role="img"
                      aria-label={option.title}
                    >
                      <span className="fake_checkbox" />
                    </span>
                    {option.title}
                  </div>
                </Option>
              ))
          }
        </Select>
      )}
    </div>
  );
};

SelectMultiply.propTypes = {
  activeOption: PropTypes.string,
  merchants: PropTypes.oneOfType([PropTypes.array]),
  address: PropTypes.oneOfType([PropTypes.array]).isRequired,
  callback: PropTypes.func.isRequired,
  error: PropTypes.string,
};

SelectMultiply.defaultProps = {
  merchants: undefined,
  activeOption: null,
  error: undefined,
};

export default memo(SelectMultiply);
