import { useContext, useMemo } from 'react';
import UserContext from '../../../Layout/context/userContext';
import CountContext from '../../../Layout/context/countContext';

const useCouponsInCartCount = () => {
  const { userData } = useContext(UserContext);
  const { localCouponsCount, countData } = useContext(CountContext);

  const localCartCoupons = localCouponsCount || 0;

  return useMemo(() => {
    if (!!userData && countData) {
      const { shoppingCartCoupons } = countData.getAllCouponsInCart;
      return shoppingCartCoupons.reduce((acc, el) => (el.amount + acc), 0);
    }
    if (localCartCoupons) return localCartCoupons;
    return 0;
  }, [userData, localCartCoupons, countData]);
};

export default useCouponsInCartCount;
