import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Portal from '../../Common_components/Portal';
import EmployModal from './EmployModal';
import useCreateEmployContainer from '../hooks/useCreateEmployContainer/useCreateEmployContainer';
import Loader from '../../Loader';

const EmployModalComponent = ({ isOpenModalEmploy, closeModalWindowEmploy }) => {
  const {
    employData,
    allErrors,
    changeInitStateEmploy,
    sendDataHandler,
    loadingCreateEmploy,
    loadingGetEmploy,
    loadingUpdateEmployByIdAdmin,
  } = useCreateEmployContainer(closeModalWindowEmploy);

  if (loadingGetEmploy) {
    return <Loader />;
  }

  return (
    <>
      {(loadingCreateEmploy || loadingUpdateEmployByIdAdmin) && <Loader />}
      <Portal>
        <EmployModal
          closeModalWindowEmploy={closeModalWindowEmploy}
          isOpenModalEmploy={isOpenModalEmploy}
          employData={employData}
          allErrors={allErrors}
          changeInitStateEmploy={changeInitStateEmploy}
          sendDataHandler={sendDataHandler}
          loadingCreateEmploy={loadingCreateEmploy}
          loadingUpdateEmployByIdAdmin={loadingUpdateEmployByIdAdmin}
        />
      </Portal>
    </>
  );
};
EmployModalComponent.propTypes = {
  isOpenModalEmploy: PropTypes.bool.isRequired,
  closeModalWindowEmploy: PropTypes.func.isRequired,
};

export default memo(EmployModalComponent);
