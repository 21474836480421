import React from 'react';
import PropTypes from 'prop-types';
import s from '../../CouponMaking.module.scss';
import Coupon from '../Coupon';
import useGetCoupon from '../../hooks/useGetCoupon';
import Loader from '../../../../Loader/Loader';
import ButtonsAction from '../../ButtonsAction';

const CouponContainer = ({
  couponInputById, couponOptionsById, prevImageById, galleryImagesById, couponId,
}) => {
  const {
    couponInput,
    setCouponInputHandler,
    setCouponInput,
    prevImage,
    setPrevImage,
    setGalleryImages,
    couponOptions,
    copyCouponOptions,
    addNewCouponOptions,
    deleteCouponOptions,
    galleryImages,
    sendDataHandler,
    setOptionForAddOptions,
    setOptionFilialBranchesForAddOptions,
    loadingCreateCoupon,
    loadingUpdateCoupon,
    allErrors,
    deleteImageFromGallery,
    changeOrderImagesInGallery,
  } = useGetCoupon(couponInputById, couponOptionsById, prevImageById, galleryImagesById, couponId);

  return (
    <>
      {
        (loadingCreateCoupon || loadingUpdateCoupon) && <Loader />
      }
      <h1 className={s.title}>Edit coupon</h1>
      <Coupon
        couponInput={couponInput}
        setCouponInputHandler={setCouponInputHandler}
        setCouponInput={setCouponInput}
        prevImage={prevImage}
        setPrevImage={setPrevImage}
        setGalleryImages={setGalleryImages}
        couponOptions={couponOptions}
        copyCouponOptions={copyCouponOptions}
        addNewCouponOptions={addNewCouponOptions}
        deleteCouponOptions={deleteCouponOptions}
        galleryImages={galleryImages}
        setOptionForAddOptions={setOptionForAddOptions}
        setOptionFilialBranchesForAddOptions={setOptionFilialBranchesForAddOptions}
        allErrors={allErrors}
        deleteImageFromGallery={deleteImageFromGallery}
        changeOrderImagesInGallery={changeOrderImagesInGallery}
      />
      <div className={s.btnActions}>
        <ButtonsAction
          sendData={sendDataHandler}
        />
      </div>
    </>
  );
};

CouponContainer.propTypes = {
  couponInputById: PropTypes.oneOfType([PropTypes.object]).isRequired,
  couponOptionsById: PropTypes.oneOfType([PropTypes.array]).isRequired,
  prevImageById: PropTypes.oneOfType([PropTypes.object]).isRequired,
  galleryImagesById: PropTypes.oneOfType([PropTypes.array]).isRequired,
  couponId: PropTypes.string.isRequired,
};

export default CouponContainer;
