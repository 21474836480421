import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './QuantityControls.module.scss';
import { Minus, Plus } from '../constants/icons';
import mobileCondition from '../../../constants/mobileCondition';

const QuantityControls = ({
  itemId, quantity, setQuantity, orderHandler,
}) => {
  const quantityClassName = `${s.quantity} ${mobileCondition && s.mobile}`;

  const headerClassName = `${s.header} ${mobileCondition && s.mobile}`;

  const quantityContainerClassName = `${s.quantityContainer} ${mobileCondition && s.mobile}`;

  const buttonClassName = `${s.button} ${mobileCondition && s.mobile}`;

  const quantityCountClassName = `${s.quantityCount} ${mobileCondition && s.mobile}`;

  const quantityMinus = () => {
    if (quantity > 0) {
      setQuantity((value) => value - 1);
      orderHandler(-1, itemId);
    }
  };
  const quantityPlus = () => {
    setQuantity((value) => value + 1);
    orderHandler(1, itemId);
  };

  return (
    <div className={quantityClassName}>
      <span className={headerClassName}>Quantity</span>
      <div className={quantityContainerClassName}>
        <div
          onClick={quantityMinus}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className={buttonClassName}
        >
          <Minus />
        </div>
        <span className={quantityCountClassName}>
          {' '}
          {quantity}
        </span>
        <div
          onClick={quantityPlus}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className={buttonClassName}
        >
          <Plus />
        </div>
      </div>
    </div>
  );
};

QuantityControls.propTypes = {
  quantity: PropTypes.number.isRequired,
  setQuantity: PropTypes.func.isRequired,
  orderHandler: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
};

export default memo(QuantityControls);
