import React from 'react';
import Page from '../../components/Page';
import LogsTable from '../../components/Logs/LogsTable';

const Logs = () => (
  <Page>
    <LogsTable />
  </Page>
);

export default Logs;
