import React, { memo, useCallback } from 'react';
import {
  Checkbox, Form, Input, Select,
} from 'antd';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import './form-merchant-ant.scss';
import PhoneInputComponent from '../../../../Common_components/PhoneInputComponent';
import EmailComponent from './EmailComponent';
import s from './form-merchant.module.scss';
import {
  emiratesItems,
  STATUS_TYPE_ACTIVE_BY_MERCHANT,
  STATUS_TYPE_INACTIVE_BY_MERCHANT,
} from '../../../../../constants/merchantConstant';
import MultiplySelectCommonComponent from '../../../../Common_components/MulltiplySelectCommonComponent';

const FormMerchant = ({
  stateInfo, setStateInfoHandler, allErrors, errorUpdate, errorCreate, setStateInfo,
}) => {
  const { id } = useParams();

  const setInfoFormHandler = useCallback((key, value) => {
    setStateInfoHandler(key, value);
  }, [setStateInfoHandler]);

  return (
    <section className={s.container}>
      <Form
        labelCol={{ className: s.labelColumn }}
        name="form-merchant"
        layout="vertical"
      >
        {
          id && (
            <Form.Item
              className={`${s.formItem}`}
              label="Status"
            >
              <Select
                style={{
                  width: '100%',
                  border: '1px solid #EDEDED',
                  borderRadius: '4px',
                  boxSizing: 'border-box',
                }}
                value={stateInfo.status}
                onChange={(value) => setInfoFormHandler('status', value)}
              >
                <Select.Option value={STATUS_TYPE_ACTIVE_BY_MERCHANT}>Active</Select.Option>
                <Select.Option value={STATUS_TYPE_INACTIVE_BY_MERCHANT}>Deactivated</Select.Option>
              </Select>
            </Form.Item>
          )
        }
        <Form.Item
          className={s.formItem}
          label="Name"
        >
          <Input
            value={stateInfo.title}
            style={{
              height: '40px',
              borderRadius: '4px',
              border: allErrors?.title && !stateInfo.title.trim()
                ? '1px solid #FF0000FF' : '1px solid #EDEDED',
            }}
            onChange={(e) => setInfoFormHandler('title', e.target.value)}
            maxLength={200}
          />
          {(allErrors?.title && !stateInfo.title.trim())
              && <span className={s.error}>{allErrors.title}</span>}
        </Form.Item>
        <Form.Item
          className={s.formItem}
          label="Contact person"
        >
          <Input
            style={{
              height: '40px',
              borderRadius: '4px',
              border: allErrors?.firstName && !stateInfo.firstName.trim()
                ? '1px solid #FF0000FF' : '1px solid #EDEDED',
            }}
            value={stateInfo.firstName}
            onChange={(e) => setInfoFormHandler('firstName', e.target.value)}
            maxLength={200}
          />
          {(allErrors?.firstName && !stateInfo.firstName.trim())
              && <span className={s.error}>{allErrors.firstName}</span>}
        </Form.Item>
        <Form.Item
          className={s.formItem}
          label="Phone"
        >
          <PhoneInputComponent
            stateInfo={stateInfo}
            setInfoFormHandler={setStateInfo}
            allErrors={allErrors}
          />
        </Form.Item>
        <Form.Item
          className={s.formItem}
          label="Email"
        >
          <EmailComponent
            stateInfo={stateInfo}
            setInfoFormHandler={setInfoFormHandler}
            allErrors={allErrors}
            errorCreate={errorCreate}
            errorUpdate={errorUpdate}
          />
        </Form.Item>
        <Form.Item
          className={s.formItem}
          label="Emirates"
        >
          <MultiplySelectCommonComponent
            onChange={(v) => setInfoFormHandler('emirates', v)}
            defaultValues={stateInfo.emirates}
            items={emiratesItems}
            error={errorCreate || errorUpdate}
          />
          {(!stateInfo.emirates.length && allErrors?.emirates) && (
            <span className={s.error}>
              {allErrors?.emirates}
            </span>
          )}
        </Form.Item>
        <div className={s.checkboxContainer}>
          <h6 className={s.title}>Permissions</h6>
          <Form.Item
            label="Allow to view sales statistics"
            className={`${s.formItemCheckbox} formItemCheckboxMerchant`}
            name="statistics"
          >
            <Checkbox
              name="statistics"
              checked={stateInfo.isAllowedToGetSalesStatistics}
              onChange={(e) => setInfoFormHandler('isAllowedToGetSalesStatistics', e.target.checked)}
            />
          </Form.Item>
          <Form.Item
            name="voucher"
            label="Allow to receive voucher by mail"
            className={`${s.formItemCheckbox} formItemCheckboxMerchant`}
          >
            <Checkbox
              name="voucher"
              checked={stateInfo.isAllowedToGetVoucherEmails}
              onChange={(e) => setInfoFormHandler('isAllowedToGetVoucherEmails', e.target.checked)}
            />
          </Form.Item>
        </div>
      </Form>
    </section>
  );
};

FormMerchant.propTypes = {
  stateInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setStateInfo: PropTypes.func.isRequired,
  setStateInfoHandler: PropTypes.func.isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.array]),
  errorCreate: PropTypes.string,
  errorUpdate: PropTypes.string,
};

FormMerchant.defaultProps = {
  allErrors: undefined,
  errorCreate: undefined,
  errorUpdate: undefined,
};

export default memo(FormMerchant);
