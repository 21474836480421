import React, { memo, useEffect } from 'react';
import './coupon.scss';
import PropTypes from 'prop-types';
import useGetAllMerchants from '../hooks/gql/useGetAllMerchants';
import useGetCommonSettings from '../hooks/gql/useGetCommonSettings';
import AddOption from './Add_Option/AddOption';
import ButtonAddOption from './ButtonAddOption';
import CouponInfo from './CouponInfo';
import { getRoleUser } from '../../../../helpers/user-storage';
import { MERCHANT_ADMIN } from '../../../../constants/admin-roles';

const Coupon = memo(({
  setCouponInputHandler,
  couponInput,
  setCouponInput,
  prevImage,
  setPrevImage,
  setGalleryImages,
  couponOptions,
  copyCouponOptions,
  addNewCouponOptions,
  deleteCouponOptions,
  galleryImages,
  setOptionForAddOptions,
  setOptionFilialBranchesForAddOptions,
  allErrors,
  deleteImageFromGallery,
  changeOrderImagesInGallery,
}) => {
  const role = getRoleUser();
  const { merchants, loadingGetMerchants } = useGetAllMerchants(setCouponInputHandler);
  const { vat: vatValue } = useGetCommonSettings();

  useEffect(() => {
    if (!loadingGetMerchants && role === MERCHANT_ADMIN && !couponInput.merchantId) {
      const [merchant] = merchants;
      setCouponInputHandler('merchantId', merchant.id);
    }
  }, [couponInput.merchantId, loadingGetMerchants, merchants, role, setCouponInputHandler]);

  return (
    <div className="container">
      <CouponInfo
        couponInput={couponInput}
        setCouponInputHandler={setCouponInputHandler}
        setCouponInput={setCouponInput}
        prevImage={prevImage}
        setPrevImage={setPrevImage}
        setGalleryImages={setGalleryImages}
        galleryImages={galleryImages}
        merchants={merchants}
        vatValue={vatValue}
        allErrors={allErrors}
        deleteImageFromGallery={deleteImageFromGallery}
        changeOrderImagesInGallery={changeOrderImagesInGallery}
      />
      <AddOption
        couponOptions={couponOptions}
        copyCouponOptions={copyCouponOptions}
        deleteCouponOptions={deleteCouponOptions}
        merchants={merchants}
        activeMerchant={couponInput.merchantId}
        vatValue={vatValue}
        setOptionForAddOptions={setOptionForAddOptions}
        setOptionFilialBranchesForAddOptions={setOptionFilialBranchesForAddOptions}
        errors={allErrors && allErrors?.couponOptionsForValid}

      />
      <ButtonAddOption addNewCouponOptions={addNewCouponOptions} />
    </div>
  );
});
export default Coupon;

Coupon.propTypes = {
  setCouponInputHandler: PropTypes.func.isRequired,
  couponInput: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setCouponInput: PropTypes.func.isRequired,
  prevImage: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setPrevImage: PropTypes.func.isRequired,
  setGalleryImages: PropTypes.func.isRequired,
  couponOptions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  copyCouponOptions: PropTypes.func.isRequired,
  addNewCouponOptions: PropTypes.func.isRequired,
  deleteCouponOptions: PropTypes.func.isRequired,
  galleryImages: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setOptionForAddOptions: PropTypes.func.isRequired,
  setOptionFilialBranchesForAddOptions: PropTypes.func.isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
  deleteImageFromGallery: PropTypes.func.isRequired,
  changeOrderImagesInGallery: PropTypes.func.isRequired,
};

Coupon.defaultProps = {
  allErrors: null,
};
