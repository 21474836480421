import React, { memo } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { finallyBorder } from '../../../helpers/finallyBorder';
import { ArrowBottom } from '../../../constants/icons';
import './SelectComponent.scss';

const SelectComponent = ({
  options, onSelectHandler, keyName, activeOption, disabled, errors, allowClear, onClearValueHandler,
}) => {
  const handleChange = (value) => {
    onSelectHandler(keyName, value);
  };

  return (
    <Select
      className={disabled ? 'selectAnt disabled' : 'selectAnt'}
      disabled={disabled}
      allowClear={allowClear}
      onClear={onClearValueHandler}
      style={{
        width: '100%',
        border: errors ? finallyBorder(!disabled ? activeOption : false) : '1px solid #EDEDED',
        borderRadius: '4px',
        boxSizing: 'border-box',
        lineHeight: '40px',
        height: '40px',
      }}
      onChange={handleChange}
      optionLabelProp="label"
      value={activeOption}
      suffixIcon={<ArrowBottom />}
    >
      {
        options?.map((option) => (
          <Select.Option
            key={option.id}
            value={option.id}
            label={option.title}
          >
            <div className="demo-option-label-item">
              {option.title}
            </div>
          </Select.Option>
        ))
      }
    </Select>
  );
};

SelectComponent.propTypes = {
  activeOption: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array]),
  onSelectHandler: PropTypes.func.isRequired,
  keyName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  allowClear: PropTypes.bool,
  onClearValueHandler: PropTypes.func,
};

SelectComponent.defaultProps = {
  activeOption: null,
  disabled: false,
  options: undefined,
  errors: undefined,
  allowClear: false,
  onClearValueHandler: null,
};

export default memo(SelectComponent);
