import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import s from './ButtonsAction.module.scss';
import ButtonComponent from '../../../../Common_components/ButtonComponent';

const ButtonsActions = ({ sendData, title, disable }) => {
  const createMerchant = () => {
    sendData();
  };
  const navigate = useNavigate();
  const cancelHandler = () => {
    navigate(-1);
  };

  return (
    <div className={s.container}>
      <ButtonComponent
        addClass={s.cancel}
        callback={cancelHandler}
        title="Cancel"
        iconComponent={null}
      />
      <ButtonComponent
        disabled={disable}
        callback={createMerchant}
        title={title}
        iconComponent={null}
      />
    </div>
  );
};

ButtonsActions.propTypes = {
  sendData: PropTypes.func.isRequired,
  title: PropTypes.string,
  disable: PropTypes.bool,
};
ButtonsActions.defaultProps = {
  title: 'Save',
  disable: false,
};

export default ButtonsActions;
