import React, { memo, useContext } from 'react';
import s from './MappedCategories.module.scss';
import Loader from '../../../Loader';
import CategoryItem from '../CategoryItem/CategoryItem';
import CategoriesContext from '../../../Layout/context/categoriesContext';

const MappedCategories = () => {
  const { categories, loading } = useContext(CategoriesContext);

  const mappedItems = categories && categories
    .filter(({ isPublished }) => isPublished)
    .map(({ id }) => (
      <CategoryItem
        key={id}
        categoryId={id}
      />
    ));

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={s.itemsContainer}>
      {mappedItems}
    </div>
  );
};

export default memo(MappedCategories);
