import React from 'react';
import Page from '../../components/Page';
import VouchersComponent from '../../components/Vouchers/VouchersComponent';

const Vouchers = () => (
  <Page>
    <VouchersComponent />
  </Page>
);

export default Vouchers;
