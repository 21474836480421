import React, { memo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import Portal from '../../../Common_components/Portal';
import { CloseIcon } from '../../../../constants/icons';
import PreviewImage from '../../../Common_components/PreviewImage';
import './add-avatar.scss';
import { UPDATE_PROFILE_PICTURE } from '../../../../api/profile/query';
import useAuthAdmin from '../../../../context/context-admin/useAuthAdmin';
import { GetEmployeeSelf } from '../../../../api/query';
import Loader from '../../../Loader';

const AddAvatarModal = ({ isOpenModal, closeModalHandler }) => {
  const { userInfo } = useAuthAdmin();

  const [prevImage, setPrevImage] = useState({
    id: '',
    key: null,
  });

  const [updatePicture, { loading }] = useMutation(UPDATE_PROFILE_PICTURE, {
    variables: {
      file: prevImage,
      userId: userInfo.id,
    },
    refetchQueries: [GetEmployeeSelf],
  });

  const cancelOnClick = () => {
    closeModalHandler();
    setPrevImage({ id: '', key: null });
  };

  const sendAvatarAdmin = () => {
    updatePicture()
      .then(() => {
        closeModalHandler();
        setPrevImage({ id: '', key: null });
      });
  };

  return (
    <Portal>
      {loading && <Loader />}
      <Modal
        centered
        title="Add avatar"
        closeIcon={<CloseIcon />}
        visible={isOpenModal}
        onOk={closeModalHandler}
        onCancel={closeModalHandler}
        wrapClassName="wrapper"
        width={440}
        footer={null}
      >
        <div style={{ position: 'relative' }}>
          <PreviewImage
            prevImage={prevImage}
            setPrevImage={setPrevImage}
            aspect={1}
            placeHolder="Supports: JPEG, JPG, PNG"
            containerClass="containerArea"
            zoom={false}
            rotate={false}
          />
          <div className="admin-profile-photo-buttons">
            <button onClick={cancelOnClick}>
              Cancel
            </button>
            <button
              className="admin-profile-photo-buttons_save"
              disabled={!prevImage?.uid}
              onClick={sendAvatarAdmin}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </Portal>
  );
};

AddAvatarModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
};

export default memo(AddAvatarModal);
