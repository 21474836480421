import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const { Item } = Form;
const EditInput = (props) => {
  const {
    error, callback, value, keyField, label, inputVariant, disabledInput, style, visibilityToggle,
  } = props;

  const onChangeHandler = (value) => {
    callback(keyField, value);
  };

  const errorText = () => (error ? 'errorTextAccount' : '');

  return (
    <Item
      label={label}
      className="profile-merchant-label-class"
    >
      {
        inputVariant === 'password'
          ? (
            <Input.Password
              visibilityToggle={visibilityToggle}
              style={style}
              className={error ? 'errorInput' : ''}
              value={value}
              onChange={(e) => onChangeHandler(e.target.value)}
              disabled={disabledInput}
            />
          )
          : (
            <Input
              style={{
                border: 'none',
                height: '40px',
                backgroundColor: 'transparent',
                boxShadow: 'none',
              }}
              className={error ? 'errorInput' : ''}
              value={value}
              onChange={(e) => onChangeHandler(e.target.value)}
              disabled={disabledInput}
            />
          )
      }
      {error && <div className={errorText()}>{error}</div>}
    </Item>
  );
};
EditInput.propTypes = {
  value: PropTypes.string.isRequired,
  keyField: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  callback: PropTypes.func,
  error: PropTypes.string,
  inputVariant: PropTypes.string,
  disabledInput: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  visibilityToggle: PropTypes.bool,
};

EditInput.defaultProps = {
  error: undefined,
  inputVariant: 'input',
  disabledInput: false,
  callback: undefined,
  style: {
    border: 'none',
    height: '40px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  visibilityToggle: true,
};

export default memo(EditInput);
