import React, {
  memo, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import AutoComplete from './AutoComplete';
import { defaultCenter, getBrowserLocation } from '../../../../../../../../helpers/map/geo';
import Map, { MODES } from './Map/Map';
import './map-component.scss';
import s from './map-component.module.scss';

const API_KEY = process.env.REACT_APP_MAP_KEY;

// type LibrariesType = "places" | "drawing" | "geometry" | "localContext" | "visualization";
const libraries = ['places'];

const MapComponent = ({
  address, location, setCoordinatesOfLocation, error, setAddressOfLocation,
}) => {
  const [center, setCenter] = useState(location || defaultCenter);
  const wasClickOnMap = useRef(0);
  const mode = useRef(MODES.SET_MARKER);

  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${API_KEY}`,
    language: 'en',
    libraries,
  });

  const centerCoordinates = useMemo(() => ({ ...center }), [center]);

  const onPlaceSelect = useCallback((coordinates, titleLocation) => {
    setCenter(coordinates);
    setCoordinatesOfLocation(coordinates, titleLocation);
  }, [setCoordinatesOfLocation]);

  const onMarkerAdd = useCallback((coordinates) => {
    wasClickOnMap.current += 1;
    setCenter(coordinates);
    setCoordinatesOfLocation(coordinates);
  }, [setCoordinatesOfLocation]);

  useEffect(() => {
    if (!location) {
      getBrowserLocation()
        .then((curLoc) => {
          setCenter(curLoc);
        })
        .catch((defLoc) => {
          setCenter(defLoc);
        });
    }
  }, [location]);

  useEffect(() => {
    if (loadError) {
      // eslint-disable-next-line no-console
      console.error('loadError', loadError);
    }
  }, [loadError]);

  return (
    <div>
      <div className="addressSearchContainer">
        <AutoComplete
          location={location}
          isLoaded={isLoaded}
          onSelect={onPlaceSelect}
          address={address}
          addClass={error && !address?.trim() ? 'errorInputAutocomplete' : ''}
          center={centerCoordinates}
          wasClickOnMap={wasClickOnMap.current}
          setAddressOfLocation={setAddressOfLocation}
        />
      </div>
      {error && !address?.trim() && <span className={s.errorText}>{error}</span>}
      {isLoaded ? (
        <Map
          mode={mode.current}
          center={centerCoordinates}
          onMarkerAdd={onMarkerAdd}
          address={address}
        />
      )
        : <div>Loading ...</div>}
    </div>
  );
};

MapComponent.propTypes = {
  address: PropTypes.string.isRequired,
  setCoordinatesOfLocation: PropTypes.func.isRequired,
  location: PropTypes.oneOfType([PropTypes.object]),
  error: PropTypes.string,
  setAddressOfLocation: PropTypes.func.isRequired,
};

MapComponent.defaultProps = {
  location: undefined,
  error: undefined,
};

export default memo(MapComponent);
