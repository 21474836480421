import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, FilterIcon } from '../../../constants/icons';
import s from './MobileCatalogHeader.module.scss';
import { MOBILE_CATALOG } from '../../../constants/Routes';

const MobileCatalogHeader = ({
  couponsCount, title, backTitle, setIsFormShow,
}) => {
  const navigate = useNavigate();
  const openFiltersModal = () => setIsFormShow(true);

  return (
    <div className={s.main}>
      <div
        onClick={() => navigate(MOBILE_CATALOG)}
        className={s.backTitle}
      >
        <ArrowLeft />
        <span>{backTitle}</span>
      </div>
      <span className={s.title}>{title}</span>
      <div className={s.info}>
        <span>
          {couponsCount}
          {' '}
          coupons
        </span>
        <div onClick={openFiltersModal}>
          <FilterIcon />
        </div>
      </div>
    </div>
  );
};

MobileCatalogHeader.propTypes = {
  couponsCount: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  backTitle: PropTypes.string.isRequired,
  setIsFormShow: PropTypes.func.isRequired,
};

export default memo(MobileCatalogHeader);
