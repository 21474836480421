import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './LogoBlock.module.scss';
import Logo from '../../../assets/images/Logo.svg';

const LogoBlock = ({ onLogoClick }) => {
  const logoContainerClassName = `${s.logoContainer} ${onLogoClick && s.withOnClick}`;

  return (
    <div
      onClick={onLogoClick}
      className={logoContainerClassName}
    >
      <div className={s.logo}>
        <img
          src={Logo}
          alt=""
        />
      </div>
      <div className={s.info}>
        <span className={s.title}>YallaBanana</span>
        <span className={s.subtitle}>If you love great deals, you&apos;ll love me</span>
      </div>
    </div>
  );
};

LogoBlock.propTypes = {
  onLogoClick: PropTypes.func,
};

LogoBlock.defaultProps = {
  onLogoClick: undefined,
};

export default memo(LogoBlock);
