import React, { memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import CouponsComponent from './Coupons/CouponsComponent';
import useCookiesAuth from './hooks/useCookiesAuth';
import Loader from '../Loader';
import AccountComponent from './AccountComponent';
import { ACCOUNT, COUPONS_PROFILE } from '../../constants/Routes';
import useLogoutMutation from '../../hooks/useLogoutMutation/useLogoutMutation';
import CookieComponent from '../Common_components/CookieComponent';
import s from './profile.module.scss';
import './profile-ant.scss';

const Profile = () => {
  const { isShowCookie } = useCookiesAuth();

  const { logoutCallback, logoutLoading } = useLogoutMutation();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const changeTabHandler = (key) => {
    switch (key) {
    case '1': {
      navigate(ACCOUNT);
      return;
    }
    case '2': {
      navigate(COUPONS_PROFILE);
      return;
    }
    default: logoutCallback();
    }
  };
  const tabsData = [
    {
      key: '1',
      tab: 'account',
      title: 'Account Information',
      component: <AccountComponent />,
    },
    {
      key: '2',
      tab: 'coupons-profile',
      title: 'My Coupons',
      component: <CouponsComponent />,
    },
    {
      key: '3',
      tab: 'logout',
      title: 'Logout',
    },
  ];

  if (logoutLoading) {
    return <Loader />;
  }

  const activeTabStatus = () => {
    switch (pathname) {
    case '/profile/coupons': return '2';
    case 'logout': return '3';
    default: return '1';
    }
  };

  const isAppZoomed = document.body.classList.value === 'zoom-app';

  const containerClassName = `${s.container} ${isAppZoomed && s.zoomed}`;

  return (
    <div className={s.main}>
      <section className={`${containerClassName} container-profile`}>
        <Tabs
          activeKey={activeTabStatus()}
          onChange={changeTabHandler}
          tabPosition="left"
          className="tabs"
        >
          {
            tabsData.map((tab) => (
              <Tabs.TabPane
                key={tab.key}
                tab={tab.title}
                className="tabMy"
              >
                <div>{tab.component}</div>
              </Tabs.TabPane>
            ))
          }
        </Tabs>
        {isShowCookie && <CookieComponent />}
      </section>
    </div>
  );
};

export default memo(Profile);
