import React, { useEffect, useState } from 'react';
import {
  Form,
  Input, message, Modal, Switch,
} from 'antd';
import PropTypes from 'prop-types';
import { Close } from '../../../constants/icons';
import style from './EditModal.module.scss';
import useUpdateCategory from './hooks/useUpdateCategory';
import { ERROR_UPDATE_CATEGORY } from '../../../constants/errors';
import useUpdateCategoryPosition from './hooks/useUpdateCategoryPosition';
import PreviewImage from '../AddModal/PreviewImage/PreviewImage';
import getImgBase64Url from '../../../helpers/getImgBase64Url';
import useUploadCategoryPicture from '../AddModal/hooks/useUploadCategoryPicture';
import { WARNING_CATEGORY_EXISTS, WARNING_EMPTY_ADD_CATEOGRY_FIELDS } from '../../../constants/warnings';
import { finallyBorderInput } from '../../../helpers/finallyBorder';
import s from '../AddModal/Error.module.scss';
import validatorForm from '../../../helpers/validator';
import { EDIT_CATEGORY_SCHEMA } from '../../../constants/validatorSchema';

const EditModal = ({
  isModalVisible,
  handleOk,
  handleCancel,
  id,
  published,
  showInHeader,
  name,
  refetch,
  position,
  categoryPicture,
  parentCategoryId,
  categories,
}) => {
  const { sendData, errorCategory } = useUpdateCategory(refetch);
  const { sendPosition, errorPosition } = useUpdateCategoryPosition(refetch);
  const { uploadImage } = useUploadCategoryPicture();

  const [editPublished, setEditPublished] = useState(published);
  const [editShowInHeader, setEditShowInHeader] = useState(showInHeader);
  const [editName, setEditName] = useState(name);
  const [editPosition, setEditPosition] = useState(position);
  const [editPrevImage, setEditPrevImage] = useState('');

  const [errors, setErrors] = useState(null);
  const {
    errors: validationErrors,
  } = validatorForm({ name: editName, prevImage: editPrevImage, position: editPosition }, EDIT_CATEGORY_SCHEMA);

  useEffect(() => {
    if (categoryPicture?.key) {
      getImgBase64Url(categoryPicture.key)
        .then((url) => setEditPrevImage(url));
    }
  }, [categoryPicture, isModalVisible]);

  const onOk = async () => {
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length) {
      return;
    }

    if (!editName || !editPrevImage || !editName.trim()) {
      message.warning(WARNING_EMPTY_ADD_CATEOGRY_FIELDS);
      return;
    }

    if (categories.filter(({ title }) => editName.trim() === title && editName.trim() !== name).length) {
      message.warning(WARNING_CATEGORY_EXISTS);
      return;
    }

    const categoryData = await sendData(id, editName, editPublished, editShowInHeader);
    const positionData = await sendPosition(id, editPosition);

    if (typeof editPrevImage === 'object' && editPrevImage) {
      await uploadImage(id, editPrevImage);
    }

    if (categoryData && positionData) {
      handleOk();
    }
  };

  useEffect(() => {
    setEditName(name);
    setEditPosition(position);
    setEditPublished(published);
    setEditShowInHeader(showInHeader);
  }, [name, position, published, isModalVisible, showInHeader]);

  const positionOnChange = (value) => {
    setErrors({ ...errors, position: '' });
    return (value === '' && setEditPosition(0))
        || (+value && setEditPosition(+value));
  };

  if (errorCategory || errorPosition) {
    message.error(ERROR_UPDATE_CATEGORY);
  }

  return (
    <Modal
      title={`Edit ${parentCategoryId ? 'subcategory' : 'category'}`}
      visible={isModalVisible}
      onOk={onOk}
      onCancel={handleCancel}
      okText="Save"
      okButtonProps={{ className: 'addButton' }}
      cancelButtonProps={{ type: 'text' }}
      closeIcon={<Close color="#A8A8A8" />}
      centered
    >
      <Form
        layout="vertical"
        requiredMark={false}
      >
        {!parentCategoryId && (
          <Form.Item label="Show in header">
            <Switch
              checked={editShowInHeader}
              onChange={setEditShowInHeader}
              size="small"
            />
            <span className={style.publishedLabel}>{editShowInHeader ? 'Show' : 'Do not show'}</span>
          </Form.Item>
        )}
        <Form.Item label="Status">
          <Switch
            checked={editPublished}
            onChange={setEditPublished}
            size="small"
          />
          <span className={style.publishedLabel}>{editPublished ? 'Visible' : 'Hidden'}</span>
        </Form.Item>
        <Form.Item label="Name">
          <Input
            value={editName}
            onChange={(e) => setEditName(e.target.value)}
            style={{
              border: errors?.name && finallyBorderInput(editName.trim()),
            }}
          />
          {errors?.name && !editName && <span className={s.errorMessage}>{errors.name}</span>}
        </Form.Item>
        <Form.Item
          label="Icon"
          className={style.upload}
        >
          <PreviewImage
            prevImage={editPrevImage}
            setPrevImage={setEditPrevImage}
          />
          {errors?.prevImage && !editPrevImage && <span className={s.errorMessage}>{errors.prevImage}</span>}
        </Form.Item>
        <Form.Item label="Position">
          <Input
            value={editPosition}
            onChange={(e) => positionOnChange(e.target.value)}
          />
          {errors?.position && <span className={s.errorMessage}>{errors.position}</span>}
        </Form.Item>
      </Form>
    </Modal>
  );
};

EditModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  published: PropTypes.bool.isRequired,
  showInHeader: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  parentCategoryId: PropTypes.string,
  categoryPicture: PropTypes.shape({
    categoryId: PropTypes.string,
    id: PropTypes.string,
    key: PropTypes.string,
    mimetype: PropTypes.string,
    originalName: PropTypes.string,
  }),
  refetch: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    categoryPicture: PropTypes.shape({
      categoryId: PropTypes.string,
      id: PropTypes.string,
      key: PropTypes.string,
      mimetype: PropTypes.string,
      originalName: PropTypes.string,
    }),
    couponsCount: PropTypes.number,
    id: PropTypes.string,
    isPublished: PropTypes.bool,
    key: PropTypes.string,
    parentCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    position: PropTypes.number,
    subCategories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      position: PropTypes.number,
      isPublished: PropTypes.bool,
      couponsCount: PropTypes.number,
      parentCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      categoryPicture: PropTypes.shape({
        categoryId: PropTypes.string,
        id: PropTypes.string,
        key: PropTypes.string,
        mimetype: PropTypes.string,
        originalName: PropTypes.string,
      }),
    })),
    title: PropTypes.string,
  })).isRequired,
};

EditModal.defaultProps = {
  parentCategoryId: null,
  categoryPicture: undefined,
};

export default EditModal;
