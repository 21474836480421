import {
  adminMenuItems, merchantMenuItems, submenuItems, superAdminMenuItems,
} from '../../constants/dataSidebar';
import { getRoleUser } from '../../helpers/user-storage';
import { ROLE_EMPLOY_ADMIN, ROLE_EMPLOY_MERCHANT, ROLE_EMPLOY_MERCHANT_SUPER } from '../../constants/employ';

const getActiveKeyOfSidebar = (pathName) => {
  const role = getRoleUser();

  const getMenu = () => (role === ROLE_EMPLOY_ADMIN ? adminMenuItems : superAdminMenuItems);

  if (role === ROLE_EMPLOY_MERCHANT || role === ROLE_EMPLOY_MERCHANT_SUPER) {
    return merchantMenuItems.find((item) => pathName.includes(item.url))?.key || '1';
  }

  return getMenu().find((item) => pathName.includes(item.url))?.key
    || submenuItems.find((item) => pathName.includes(item.url))?.key || '2';
};

export default getActiveKeyOfSidebar;
