import React, { memo } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import { ArrowRight, Calendar, LeftArrow } from '../../../../../constants/icons';
import style from '../../../ModalForm.module.scss';
import './data-picker-ant.scss';
import { DATA_FORMAT } from '../../../../../constants/promoCodes/promoCodes';

const DueDateBlock = (props) => {
  const {
    setPromoCodeInfoHandler,
    allErrors,
    validDueDate,
  } = props;

  const finallyDatePickerClass = () => (allErrors?.validDueDate && !validDueDate
    ? `${style.date} ${style.dateError}`
    : style.date);

  return (
    <div className="data-picker-container">
      <DatePicker
        style={{
          height: '40px',
          borderRadius: '4px',
          border: '1px solid #EDEDED',
        }}
        placeholder="Select date"
        nextIcon={<ArrowRight />}
        prevIcon={<LeftArrow />}
        value={validDueDate ? moment(validDueDate) : validDueDate}
        name="date"
        onChange={(date) => setPromoCodeInfoHandler('validDueDate', date)}
        suffixIcon={<Calendar color="#A8A8A8" />}
        disabledDate={(current) => {
          const customDate = moment().format(DATA_FORMAT);
          return current && current < moment(customDate, DATA_FORMAT);
        }}
        dropdownClassName="data-picker-promo-code-wrapper"
        className={finallyDatePickerClass()}
      />
      {allErrors?.validDueDate && !validDueDate
        && <span className={style.errorText}>{allErrors.validDueDate}</span>}
    </div>
  );
};
DueDateBlock.propTypes = {
  validDueDate: PropTypes.string.isRequired,
  setPromoCodeInfoHandler: PropTypes.func.isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
};
DueDateBlock.defaultProps = {
  allErrors: null,
};

export default memo(DueDateBlock);
