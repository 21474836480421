import React, { memo } from 'react';
import s from './SecurityVoucher.module.scss';
import Loader from '../Loader';
import ShowCodePage from './ShowCodePage/ShowCodePage';
import ShowSecurity from './ShowSecurity';
import { getStoredUser, isUserStored } from '../../helpers/user-storage';
import useActivateVoucherBySecurity from './hooks/useActivateVocuherBySecurity';
import useGetVoucherData from './hooks/useGetVoucherData';
import { ROLE_CUSTOMER } from '../../constants/employ';

const SecurityVoucher = () => {
  const isAuth = isUserStored() && getStoredUser() !== ROLE_CUSTOMER;

  const { id, totalData, loading } = useGetVoucherData();

  const { activateLoading, activateVoucher } = useActivateVoucherBySecurity();

  const activateCallback = () => activateVoucher(id);

  if (isAuth && loading) return <Loader />;

  return (
    <div className={s.main}>
      {isAuth
        ? (
          <ShowSecurity
            totalData={totalData}
            activateLoading={activateLoading}
            activateVoucher={activateCallback}
          />
        )
        : <ShowCodePage />}
    </div>
  );
};

export default memo(SecurityVoucher);
