import React, { memo, useCallback, useState } from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import Portal from '../Portal';
import s from '../../Banners/DeleteModalBannerComponent/deleteModal.module.scss';
import { CloseIcon } from '../../../constants/icons';
import InputDelete from './InputDelete';
import { WORD_FOR_ACCEPT_DELETE_ACTION } from '../../../constants/general/tag-status';

const DeleteModalComponent = ({
  closeModal,
  closeModalWhenDelete,
  isOpenModal,
}) => {
  const [valueDelete, setValueDelete] = useState('');

  const setValueDeleteHandler = useCallback((value) => {
    setValueDelete(value);
  }, []);

  const closeDeleteHandler = useCallback(() => {
    closeModalWhenDelete();
    setValueDelete('');
  }, [closeModalWhenDelete]);

  const closeDeleteModal = useCallback(() => {
    setValueDelete('');
    closeModal();
  }, [closeModal]);

  const finallyClassSendBtn = valueDelete === WORD_FOR_ACCEPT_DELETE_ACTION
    ? `${s.btn} ${s.send}`
    : `${s.btn} ${s.disabled}`;

  return (
    <Portal>
      <Modal
        title="Confirm your action"
        centered
        visible={isOpenModal}
        onCancel={closeDeleteModal}
        footer={null}
        width={440}
        className={s.modalComponent}
        closeIcon={<CloseIcon />}
        wrapClassName="modalDeleteBanner"
      >
        <div>
          <p className={s.text}>Please type here “DELETE” to confirm deletion</p>
          <div className={s.input}>
            <InputDelete
              value={valueDelete}
              callbackSetValue={setValueDeleteHandler}
              callbackDelete={closeDeleteHandler}
              valueDelete={valueDelete}
            />
          </div>
          <div className={s.btnModal}>
            <Button
              className={`${s.btn} ${s.cancel}`}
              onClick={closeDeleteModal}
            >
              Cancel
            </Button>

            <Button
              disabled={valueDelete !== 'DELETE'}
              type="primary"
              className={finallyClassSendBtn}
              onClick={closeDeleteHandler}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </Portal>
  );
};

DeleteModalComponent.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModalWhenDelete: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default memo(DeleteModalComponent);
