import React, { memo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table } from 'antd';
import moment from 'moment';
import columnOrders from './column/columnOrders';
import useFindAllOrders from '../hooks/gql/useFindAllOrders';
import Loader from '../../Loader';
import { limit } from '../../../constants/tableConstants';
import './table-orders-ant.scss';
import { heightMinusForAdminTables } from '../../../constants/general/tag-status';
import useWindowSize from '../../../hooks/useWindowSize/useWindowSize';

const TableOrders = () => {
  const {
    loading, count, orders,
  } = useFindAllOrders();

  const [searchParams, setSearchParams] = useSearchParams();
  const [height] = useWindowSize();

  const columns = columnOrders();

  const page = searchParams.get('page') || '1';

  const onChangePage = (page) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page,
    });
  };

  const finalOrdersData = orders?.map((orderCoupon) => {
    const { order } = orderCoupon;
    const { date, orderNumber, user } = order;
    const { userNumber } = user;

    const time = moment(date).format('HH:mm');

    return {
      ...orderCoupon,
      date,
      time,
      orderNumber,
      userNumber,
    };
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <section className="all-admin-table">
      <Table
        className="adminTable tableOrders"
        dataSource={finalOrdersData || []}
        columns={columns}
        scroll={{
          y: height - heightMinusForAdminTables, x: 0,
        }}
        rowKey={(record) => record.id}
        pagination={{
          current: +page,
          pageSize: limit,
          total: count,
          position: ['bottomCenter'],
          size: 'small',
          showSizeChanger: false,
          onChange: (page) => onChangePage(page),
        }}
      />
    </section>
  );
};

export default memo(TableOrders);
