import React, { memo, useCallback, useState } from 'react';
import moment from 'moment';
import DoubleDatePickerComponent from '../DoubleDatePickerComponent';
import DropdownWithClearComponent from '../DropdownWithClearComponent';
import {
  dataFilters,
  DATE_ALL_TIME_KEY,
  DATE_KEYS_FILTER_ARRAY, DATE_LAST_DAY_KEY,
  DATE_LAST_MONTH_KEY, DATE_LAST_WEEK_KEY, DATE_RANGE_END_KEY,
} from '../../../constants/general/filters';
import useSearchFilters from '../../../hooks/useFilters/useSearchFilters';

const DoubleDateForTableWrapper = () => {
  const { dropdownCollectionHandler } = useSearchFilters();

  const [isShow, setIsShow] = useState(false);

  const dateFilterHandler = useCallback((value) => {
    if (value) {
      const { value: keyValue } = value;
      switch (keyValue) {
      case DATE_ALL_TIME_KEY:
        dropdownCollectionHandler(undefined, DATE_KEYS_FILTER_ARRAY);
        break;
      case DATE_LAST_MONTH_KEY:
        dropdownCollectionHandler({
          value: {
            dateRangeStart: moment().subtract(1, 'months').startOf('month').format(),
            dateRangeEnd: moment().subtract(1, 'months').endOf('month').format(),
          },
        }, DATE_KEYS_FILTER_ARRAY);
        break;
      case DATE_LAST_WEEK_KEY:
        dropdownCollectionHandler({
          value: {
            dateRangeStart: moment().subtract(1, 'weeks').startOf('week').format(),
            dateRangeEnd: moment().subtract(1, 'weeks').endOf('week').format(),
          },
        }, DATE_KEYS_FILTER_ARRAY);
        break;
      case DATE_LAST_DAY_KEY:
        dropdownCollectionHandler({
          value: {
            dateRangeStart: moment().subtract(1, 'days').startOf('day').format(),
            dateRangeEnd: moment().subtract(1, 'days').endOf('day').format(),
          },
        }, DATE_KEYS_FILTER_ARRAY);
        break;
      default:
        setIsShow(true);
        break;
      }
    } else {
      dropdownCollectionHandler(value, DATE_KEYS_FILTER_ARRAY);
    }
  }, [dropdownCollectionHandler]);

  const outerCallback = useCallback((value) => {
    if (!value.value[DATE_RANGE_END_KEY]) {
      value.value[DATE_RANGE_END_KEY] = moment().format();
    }
    dropdownCollectionHandler(value, DATE_KEYS_FILTER_ARRAY);
    setIsShow(false);
  }, [dropdownCollectionHandler]);

  return (
    <div
      className="dropdownWrapperHeader"
    >
      <span>Date</span>
      <DropdownWithClearComponent
        items={dataFilters}
        minWidth="150px"
        callback={dateFilterHandler}
      />
      <DoubleDatePickerComponent
        isShow={isShow}
        outerCallback={outerCallback}
        hideDropdownCallback={() => setIsShow(false)}
      />
    </div>
  );
};

export default memo(DoubleDateForTableWrapper);
