import React, { memo, useCallback, useState } from 'react';
import './edit-password.scss';
import ButtonComponent from '../../../Common_components/ButtonComponent';
import { PenChanging } from '../../../../constants/icons';
import ChangePasswordModal from '../../modals/ChangePasswordModal';

const EditPasswordComponent = () => {
  const [isChangePassword, setChangePassword] = useState(false);

  const turnOnChangingPassword = useCallback(() => {
    setChangePassword(true);
  }, []);

  const turnOffChangingPassword = useCallback(() => {
    setChangePassword(false);
  }, []);

  return (
    <>
      <div className="edit-merchant-password">
        <ButtonComponent
          title="Change password"
          callback={turnOnChangingPassword}
          addClass="button-change-password"
          iconComponent={<PenChanging />}
        />
      </div>
      {isChangePassword && (
        <ChangePasswordModal
          isOpenModal={isChangePassword}
          closeModalHandler={turnOffChangingPassword}
        />
      )}
    </>
  );
};

export default memo(EditPasswordComponent);
