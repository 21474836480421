import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../Loader';
import ModalFileDownload from '../../Common_components/ModalFileDownload';
import useExportCustomers from '../hooks/useExportCustomers';

const BuyersModal = ({ isOpenModal, closeModalHandler }) => {
  const {
    loading, customersExcelKey,
  } = useExportCustomers();

  if (loading) {
    return <Loader />;
  }

  return (
    <ModalFileDownload
      isOpenModal={isOpenModal}
      closeModalHandler={closeModalHandler}
      titleDocument="Buyers.xls"
      customersExcelKey={customersExcelKey}
    />
  );
};
BuyersModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
};

export default memo(BuyersModal);
