import React, { memo, useCallback, useState } from 'react';
import './Header/table.scss';
import '../Table/Pagination.scss';
import TableBuyers from './TableBuyers';
import HeaderComponent from './Header';
import BuyersModal from './modal/BuyersModal';

const BuyersTable = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModalHandler = useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const closeModalHandler = useCallback(() => {
    setIsOpenModal(false);
  }, []);
  return (
    <>
      <HeaderComponent
        callback={openModalHandler}
      />
      <section
        style={{
          height: '100%',
        }}
      >
        <TableBuyers />
      </section>
      {
        isOpenModal && (
          <BuyersModal
            closeModalHandler={closeModalHandler}
            isOpenModal={isOpenModal}
          />
        )
      }
    </>
  );
};

export default memo(BuyersTable);
