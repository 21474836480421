import React, { memo, useContext } from 'react';
import s from './VoucherDetails.module.scss';
import { ClockValid, Merchant } from '../constants/icons';
import { getDate } from '../../../helpers/couponPage/formatDate';
import CouponContext from '../context/CouponContext';
// import getCouponHours from '../couponPage/getCouponHours';
import mobileCondition from '../../../constants/mobileCondition';

const VoucherDetails = () => {
  const {
    validDueDate,
    // couponGoldenHours,
    merchant,
    // filialBranches,
  } = useContext(CouponContext);

  const voucherClassName = `${s.voucher} ${mobileCondition && s.mobile}`;

  // const merchantWorkHours = filialBranches[0].businessHours;

  // const resultObject = getCouponHours(couponGoldenHours.length ? couponGoldenHours : merchantWorkHours);

  const date = validDueDate ? getDate(validDueDate) : null;

  return (
    <div className={voucherClassName}>
      <span className={s.title}>Voucher details</span>
      <div className={s.container}>
        <Merchant />
        <span className={s.subtitle}>Merchant:</span>
        <span className={s.info}>{merchant.title}</span>
      </div>
      {/*      <div className={s.container}>
        <Clock />
        <span className={s.subtitle}>Valid:</span>
        <DailySchedule schedule={resultObject} />
      </div> */}
      {date && (
        <div className={s.container}>
          <ClockValid />
          <span className={s.subtitle}>Voucher valid until:</span>
          <span className={s.info}>{date}</span>
        </div>
      )}
    </div>
  );
};

export default memo(VoucherDetails);
