import React, { memo } from 'react';
import { Divider } from 'antd';
import PropTypes from 'prop-types';
import s from './MobileInvoiceModal.module.scss';

const MobileInvoiceModal = ({ totalOrderData }) => {
  const {
    paymentID,
    orderNumber,
    paymentDate,
    electronicInvoice,
    totalOrderPrice,
    vouchers,
  } = totalOrderData;

  const mappedVouchers = vouchers.map(({
    id,
    voucherNumber,
    voucherPrice,
    voucherVat,
  }) => (
    <div
      key={id}
      className={s.item}
    >
      <div>
        <span className={s.subtitle}>Voucher ID</span>
        <span className={s.value}>{voucherNumber}</span>
      </div>
      <div>
        <span className={s.subtitle}>Description</span>
        <span className={s.value}>Yallabanana voucher</span>
      </div>
      <div>
        <span className={s.subtitle}>Quantity</span>
        <span className={s.value}>1</span>
      </div>
      <div>
        <span className={s.subtitle}>Total before VAT</span>
        <span className={s.value}>{voucherPrice}</span>
      </div>
      <div>
        <span className={s.subtitle}>Estimated VAT 5%</span>
        <span className={s.value}>{voucherVat}</span>
      </div>
    </div>
  ));

  return (
    <div className={s.receipt}>

      <div className={s.static}>
        <span>
          THE BIG FISH ONLINE SERVICES DMCC
        </span>
        <span>
          Office 2402, Floor 24th
          <br />
          Fortune Executive Tower, Cluster T, JLT, Dubai, UAE
        </span>
        <span className={s.bold}>
          TAX REGISTRATION NUMBER - 100245288400003
        </span>
      </div>

      <div className={s.payment}>
        <div>
          <span className={s.subtitle}>Payment ID</span>
          <span className={s.value}>{paymentID}</span>
        </div>
        <div>
          <span className={s.subtitle}>Order ID</span>
          <span className={s.value}>{orderNumber}</span>
        </div>
        <div>
          <span className={s.subtitle}>Invoice date</span>
          <span className={s.value}>{paymentDate}</span>
        </div>
        <div>
          <span className={s.subtitle}>Tax invoice #</span>
          <span className={s.value}>{electronicInvoice}</span>
        </div>
      </div>

      <Divider className={s.divider} />

      <div className={s.vouchers}>
        {mappedVouchers}
      </div>

      <Divider className={s.divider} />

      <div className={s.total}>
        <span className={s.subtitle}>Total, AED</span>
        <span className={s.value}>{totalOrderPrice}</span>
      </div>

    </div>
  );
};

MobileInvoiceModal.propTypes = {
  totalOrderData: PropTypes.shape({
    paymentID: PropTypes.string.isRequired,
    orderNumber: PropTypes.string.isRequired,
    paymentDate: PropTypes.string.isRequired,
    electronicInvoice: PropTypes.string.isRequired,
    totalOrderPrice: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    vouchers: PropTypes.array.isRequired,
  }).isRequired,
};

export default memo(MobileInvoiceModal);
