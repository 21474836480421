import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './CartQuantityControls.module.scss';
import { Minus, Plus } from '../../../constants/icons';
import mobileCondition from '../../../constants/mobileCondition';

const CartQuantityControls = ({
  itemId, quantity, orderHandler, optionId,
}) => {
  const quantityMinus = () => orderHandler(itemId, quantity - 1, optionId);
  const quantityPlus = () => orderHandler(itemId, quantity + 1, optionId);

  const buttonClassName = `${s.button} ${mobileCondition && s.mobile}`;

  const countClassName = `${s.count} ${mobileCondition && s.mobile}`;

  const preventSelectionOnCLick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className={s.quantity}>
      <div className={s.quantityContainer}>
        <div
          onClick={quantityMinus}
          onMouseDown={preventSelectionOnCLick}
          className={buttonClassName}
        >
          <Minus />
        </div>
        <span className={countClassName}>
          {' '}
          {quantity}
        </span>
        <div
          onClick={quantityPlus}
          onMouseDown={preventSelectionOnCLick}
          className={buttonClassName}
        >
          <Plus />
        </div>
      </div>
    </div>
  );
};

CartQuantityControls.propTypes = {
  quantity: PropTypes.number.isRequired,
  orderHandler: PropTypes.func.isRequired,
  itemId: PropTypes.string.isRequired,
  optionId: PropTypes.string,
};

CartQuantityControls.defaultProps = {
  optionId: null,
};

export default memo(CartQuantityControls);
