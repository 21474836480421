import React, { memo, useEffect, useState } from 'react';
import { toDataURL } from 'qrcode';
import s from './ShowCodePage.module.scss';
import logo from '../../../assets/images/Logo.svg';

const ShowCodePage = () => {
  const [image, setImage] = useState('');

  useEffect(() => {
    toDataURL(window.location.href)
      .then(setImage);
  }, []);

  return (
    <div className={s.wrapper}>
      <div className={s.user}>
        <img
          className={s.logo}
          src={logo}
          alt="logo"
        />
        <h1 className={s.title}>YallaBanana</h1>
        <h2>Show this QR code at the entrance</h2>
        <img
          src={image}
          alt="voucher link"
        />
      </div>
    </div>
  );
};

export default memo(ShowCodePage);
