import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { FETCH_COUPONS } from '../../../../api/catalog-api/query';
import { limitPerFetch } from '../../constants/fetch';

const useFetchCouponsContext = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const tempEmirates = searchParams.getAll('emirates').length > 0 ? searchParams.getAll('emirates') : undefined;

  const params = { ...Object.fromEntries([...searchParams]), emirates: tempEmirates };

  const fixedSetSearchParams = (p) => {
    const keys = Object.keys(p);
    const newParams = keys.reduce((acc, el) => {
      const valueByKey = p[el];
      return valueByKey ? { ...acc, [el]: valueByKey } : acc;
    }, {});

    setSearchParams(newParams);
  };

  const {
    offset,
    categoryId,
    subCategoryId,
    title,
    priceMin,
    priceMax,
    discountMin,
    discountMax,
    isHotDeal,
    emirates,
    isSortedPrice,
  } = params;

  const couponFilter = {
    emirates,
    categoryId,
    subCategoryId,
    title,
    priceMin: +priceMin,
    priceMax: +priceMax,
    discountMin: +discountMin,
    discountMax: +discountMax,
    isHotDeal: isHotDeal ? JSON.parse(isHotDeal) : undefined,
  };

  if (isSortedPrice) couponFilter.isSortedPrice = true;

  const { data, loading: isCouponsFetching, previousData } = useQuery(FETCH_COUPONS, {
    variables: {
      limit: +offset || limitPerFetch,
      offset: 0,
      couponFilter,
    },
  });

  const { findAllCouponsFilter = {} } = data || previousData || {};

  const { coupons, count } = findAllCouponsFilter;

  return {
    params,
    coupons,
    count,
    isCouponsFetching,
    categoryId,
    subCategoryId,
    searchParams,
    setSearchParams: fixedSetSearchParams,
  };
};

export default useFetchCouponsContext;
