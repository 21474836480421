import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { limit } from '../../../../constants/tableConstants';
import GET_ALL_LOGS from '../../../../api/api-logs/query';
import {
  DATE_RANGE_END_KEY,
  DATE_RANGE_START_KEY,
  LOGS_ACTION_FILTER_KEY,
  SEARCH_FILTER_KEY,
} from '../../../../constants/general/filters';
import useLastPageChange from '../../../../hooks/useLastPageChange/useLastPageChange';

const useGetAllLogs = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';

  const search = searchParams.get(SEARCH_FILTER_KEY) || null;

  const action = searchParams.get(LOGS_ACTION_FILTER_KEY);

  const dateRangeStart = searchParams.get(DATE_RANGE_START_KEY);

  const dateRangeEnd = searchParams.get(DATE_RANGE_END_KEY);

  const { loading, error, data } = useQuery(GET_ALL_LOGS, {
    variables: {
      offset: (+page - 1) * limit,
      limit,
      search,
      action,
      dateRangeStart,
      dateRangeEnd,
    },
  });

  useLastPageChange(data);

  if (data) {
    const { count, logs } = data.getAllLogs;

    return {
      loading,
      error,
      count,
      logs,
    };
  }

  return {
    loading,
    error,
  };
};

export default useGetAllLogs;
