import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { FIND_ALL_ORDERS_COUPONS } from '../../../../api/api-orders/query';
import { limit } from '../../../../constants/tableConstants';
import { DATE_RANGE_END_KEY, DATE_RANGE_START_KEY } from '../../../../constants/general/filters';
import useLastPageChange from '../../../../hooks/useLastPageChange/useLastPageChange';

const useFindAllOrders = () => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || '1';

  const search = searchParams.get('search') || null;

  const dateRangeStart = searchParams.get(DATE_RANGE_START_KEY);

  const dateRangeEnd = searchParams.get(DATE_RANGE_END_KEY);

  const { loading, error, data } = useQuery(FIND_ALL_ORDERS_COUPONS, {
    variables: {
      offset: (+page - 1) * limit,
      limit,
      search,
      dateRangeStart,
      dateRangeEnd,
    },
  });

  useLastPageChange(data);

  if (data) {
    const { orderCoupons, count } = data.findAllOrderCoupons;
    const orders = orderCoupons.map((order) => {
      const { total, amount, totalPromoDiscount } = order;
      return { ...order, price: total / amount + totalPromoDiscount / amount };
    });
    return {
      loading,
      error,
      orders,
      count,
    };
  }

  return {
    loading,
    error,
  };
};

export default useFindAllOrders;
