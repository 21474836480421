import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import s from './CouponPage.module.scss';
import useCouponDataFetch from './hooks/useCouponDataFetch';
import CouponContext from './context/CouponContext';
import Loader from '../Loader';
import mobileCondition from '../../constants/mobileCondition';
import DesktopCouponPage from './DesktopCouponPage/DesktopCouponPage';
import MobileCouponPage from './MobileCouponPage';
import { ERROR_PAGE } from '../../constants/Routes';
import MapContextWrapper from './MapContextWrapper';

const CouponPage = () => {
  const {
    couponData, loading, addCouponToLocalCart, filialBranchesCoordinates,
  } = useCouponDataFetch();

  const locations = useMemo(() => filialBranchesCoordinates, [filialBranchesCoordinates]);

  if (loading) return <Loader />;

  if (couponData?.status !== 'ACTIVE') return <Navigate to={ERROR_PAGE} />;

  return (
    <CouponContext.Provider value={couponData}>
      <MapContextWrapper>
        <div className={s.main}>
          {couponData && (
            mobileCondition
              ? (
                <MobileCouponPage
                  description={couponData.description}
                  addCouponToLocalCart={addCouponToLocalCart}
                  terms={couponData.terms}
                  title={couponData.title}
                  locations={locations}
                  titleTargetLinkForMap="_self"
                />
              )
              : (
                <DesktopCouponPage
                  description={couponData.description}
                  addCouponToLocalCart={addCouponToLocalCart}
                  terms={couponData.terms}
                  title={couponData.title}
                  locations={locations}
                  titleTargetLinkForMap="_blank"
                />
              )
          )}
        </div>
      </MapContextWrapper>
    </CouponContext.Provider>
  );
};

export default CouponPage;
