import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { FIND_VOUCHERS_BY_ADMIN, FIND_VOUCHERS_BY_MERCHANT } from '../../../../api/vouchers/query';
import { limit } from '../../../../constants/tableConstants';
import { getRoleUser } from '../../../../helpers/user-storage';
import { MERCHANT_ADMIN, MERCHANT_SUPER_ADMIN } from '../../../../constants/admin-roles';
import {
  CREATED_AT_KEY,
  FROM_HIGHER_KEY,
  SEARCH_FILTER_KEY,
  STATUS_FILTER_KEY,
} from '../../../../constants/general/filters';
import useLastPageChange from '../../../../hooks/useLastPageChange/useLastPageChange';
import { ROLE_EMPLOY_MERCHANT, ROLE_EMPLOY_MERCHANT_SUPER } from '../../../../constants/employ';
import { MERCHANT_VOUCHERS_ACTIVE_STATUS } from '../../../../constants/vouchers/vouchers';

const useFindVouchersByAdmin = () => {
  const [searchParams] = useSearchParams();

  const role = getRoleUser();

  const page = searchParams.get('page') || '1';

  const tab = searchParams.get('tab');

  const status = searchParams.get(STATUS_FILTER_KEY);

  const merchantStatus = tab === MERCHANT_VOUCHERS_ACTIVE_STATUS ? tab : status;

  const totalStatus = (role === ROLE_EMPLOY_MERCHANT || role === ROLE_EMPLOY_MERCHANT_SUPER)
    ? merchantStatus
    : status || '';

  const querySearch = searchParams.get(SEARCH_FILTER_KEY) || '';

  const sortOrder = searchParams.get(CREATED_AT_KEY) || FROM_HIGHER_KEY;

  const sortedField = CREATED_AT_KEY;

  const totalVouchersQuery = () => {
    switch (getRoleUser()) {
    case MERCHANT_ADMIN:
    case MERCHANT_SUPER_ADMIN:
      return FIND_VOUCHERS_BY_MERCHANT;
    default:
      return FIND_VOUCHERS_BY_ADMIN;
    }
  };

  const { loading, error, data } = useQuery(totalVouchersQuery(), {
    variables: {
      search: querySearch,
      status: totalStatus,
      offset: (+page - 1) * limit,
      limit,
      sortOrder,
      sortedField,
    },
  });

  useLastPageChange(data);

  if (data) {
    if (role === MERCHANT_ADMIN || role === MERCHANT_SUPER_ADMIN) {
      const { vouchers, count } = data.findAllVouchersMerchant;
      return {
        loading,
        error,
        vouchers,
        count,
      };
    }

    const { vouchers, count } = data.findAllVouchersAdmin;
    return {
      loading,
      error,
      vouchers,
      count,
    };
  }

  return {
    loading,
    error,
  };
};

export default useFindVouchersByAdmin;
