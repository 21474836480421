import { gql } from '@apollo/client';

export const CREATE_MERCHANT_BY_ADMIN = gql`
    mutation createMerchantAdmin ($createMerchantInput: CreateMerchantInput!) {
        createMerchantAdmin (createMerchantInput: $createMerchantInput) {
            id
        }
    }
`;

export const GET_ALL_MERCHANTS_ADMIN = gql`
    query getAllMerchantsAdmin(
        $limit: Float, 
        $offset: Float, 
        $order: String, 
        $status: MerchantStatus, 
        $searchQuery: String
    ) {
        getAllMerchantsAdmin( 
            limit: $limit, 
            offset : $offset, 
            order: $order, 
            status: $status,
            searchQuery: $searchQuery
        ) {
            merchants {
                id
                title
                status
                contacts {
                    id
                    firstName
                    email
                    phone
                    status
                }
                filialBranches {
                    id
                    title
                    address
                    phone
                    location
                    businessHours {
                        id
                        dayOfWeek
                        startAt
                        endAt
                    }
                }
            }
            count
        }
    }
`;

export const GET_MERCHANT_BY_ID_ADMIN = gql`
    query getMerchantByIdAdmin($id: ID!) {
        getMerchantByIdAdmin( id: $id) {
            id
            title
            status
            isAllowedToGetSalesStatistics
            isAllowedToGetVoucherEmails
            emirates
            contacts {
                id
                firstName
                email
                phone
            }
            filialBranches {
                id
                title
                address
                phone
                location
                businessHours {
                    id
                    dayOfWeek
                    startAt
                    endAt
                }
            }
        }
    }
`;

export const DELETE_MERCHANT_BY_ID_ADMIN = gql`
    mutation deleteMerchantByIdAdmin($id: ID!) {
        deleteMerchantByIdAdmin( id: $id)
    }
`;

export const UPDATE_MERCHANT_ADMIN = gql`
    mutation updateMerchantAdmin ($id: ID!, $updateMerchantInput: UpdateMerchantInput!) {
        updateMerchantAdmin (id:$id, updateMerchantInput: $updateMerchantInput) {
            id
        }
    }
`;

export const CREATE_FILIAL_BRANCH_ADMIN = gql`
    mutation createFilialBranchAdmin ($createFilialBranchInput:CreateFilialBranchInput!, $merchantId: ID!) {
        createFilialBranchAdmin (createFilialBranchInput:$createFilialBranchInput, merchantId: $merchantId) {
            id
        }
    }
`;

export const DELETE_FILIAL_BRANCH_BY_ID_ADMIN = gql`
    mutation deleteFilialBranchByIdAdmin ($id: ID!) {
        deleteFilialBranchByIdAdmin (id: $id)
    }
`;

export const UPDATE_FILIAL_BRANCH_ADMIN = gql`
    mutation updateFilialBranchAdmin ($updateFilialBranchInput:UpdateFilialBranchInput!, $id: ID!) {
        updateFilialBranchAdmin (updateFilialBranchInput:$updateFilialBranchInput, id: $id) {
            id
        }
    }
`;
