import { useCallback, useState } from 'react';
import { useForm as useAntForm } from 'antd/es/form/Form';
import useCreatePromoCode from '../useCreatePromoCode';
import useGetPromoCodes from '../useGetPromoCodes';
import useDeletePromoCodeById from '../useDeletePromoCodeById';
import { DiscountUnits } from '../../../../constants/promoCodes/promoCodes';

const usePromoCodes = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const {
    promoCodes, refetch, count, loading: loadingGetPromoCodes,
  } = useGetPromoCodes();
  const { deletePromoCodeById } = useDeletePromoCodeById(refetch);
  const { sendAddForm } = useCreatePromoCode(refetch);

  const [form] = useAntForm();

  const addModalHandleOk = (formData) => {
    sendAddForm(formData);
    setIsAddModalVisible(false);
  };

  const addModalHandleCancel = useCallback(() => {
    setIsAddModalVisible(false);
  }, []);

  const openAddModal = useCallback(() => {
    setIsAddModalVisible(true);
  }, []);

  const closeAddModal = useCallback(() => {
    setIsAddModalVisible(false);
  }, []);

  const getTableData = () => {
    if (promoCodes) {
      const { findAllPromoCodesAdmin } = promoCodes;

      return findAllPromoCodesAdmin.promoCodes.map((
        {
          id,
          promoCode,
          discountUnit,
          discount,
          validDueDate,
          usesCount,
          availableAmount,
          coupon,
          couponOption,
          comment,
          isActive,
        },
      ) => (
        {
          id,
          number: promoCode,
          percentage: (discountUnit === DiscountUnits.PERCENTS && discount) || '-',
          sum: (discountUnit === DiscountUnits.ABSOLUTE && discount && +discount / 100) || '-',
          date: (new Date(validDueDate)).toLocaleDateString('en-GB').replaceAll('/', '.'),
          quantity: availableAmount === 1 ? 'Single' : `${availableAmount - usesCount}/${availableAmount}`,
          coupon: coupon ? coupon.title : couponOption?.title || '-',
          note: comment,
          isActive,
        }
      ));
    }

    return [];
  };

  return {
    isAddModalVisible,
    addModalHandleOk,
    addModalHandleCancel,
    openAddModal,
    closeAddModal,

    deletePromoCodeById,
    promoCodes: getTableData(),
    fetchedPromoCodes: promoCodes || {},
    count,
    loadingGetPromoCodes,
    refetch,
    form,
  };
};

export default usePromoCodes;
