import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './TextBlock.module.scss';
import mobileCondition from '../../../constants/mobileCondition';
import splitNewLines from '../../../helpers/couponPage/splitNewLines';

const TextBlock = ({ title, text, children }) => {
  const textBlockClassName = `${s.textBlock} ${mobileCondition && s.mobile}`;

  const fixedText = splitNewLines(text);
  return (
    <div className={textBlockClassName}>
      <span className={s.title}>{title}</span>
      {text && <span className={s.text}>{fixedText}</span>}
      {children}
    </div>
  );
};

TextBlock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: PropTypes.node,
};

TextBlock.defaultProps = {
  children: undefined,
  text: undefined,
};

export default memo(TextBlock);
