import React from 'react';
import { Menu, Dropdown } from 'antd';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import s from './dropdown-days.module.scss';
import { ArrowBottom } from '../../../../../../../../constants/icons';
import DaysMenuItem from './DaysMenuItem';

const DropdownDays = ({ days }) => {
  const groupDays = groupBy(days, 'dayOfWeek');

  const menu = (
    <Menu
      className={s.menu}
    >
      {
        Object.values(groupDays).map((days) => {
          const [day] = days;
          const { id } = day;
          const title = days[0].dayOfWeek?.toLowerCase();
          const fTitle = title && title[0].toUpperCase() + title.slice(1);
          return (
            <DaysMenuItem
              key={id}
              days={days}
              fTitle={fTitle}
            />
          );
        })
      }
    </Menu>
  );

  return (
    <div className={s.drop}>
      <span className={s.numDays}>
        +
        {days.length - 1}
      </span>
      <Dropdown.Button
        overlay={menu}
        icon={<ArrowBottom />}
        className={s.dropdownBtn}
        overlayClassName={s.dropdown}
      />
    </div>
  );
};

DropdownDays.propTypes = {
  days: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default DropdownDays;
