import { gql } from '@apollo/client';

export const UPDATE_USER_SELF = gql`
    mutation updateUserSelf ($updateUserInput: UpdateUserInput!) {
        updateUserSelf (updateUserInput: $updateUserInput) {
            id
        }
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation changePassword($changePasswordInput:ChangePasswordInput!) {
        changePassword (changePasswordInput: $changePasswordInput) 
    }
`;

export const GET_VOUCHERS = gql`
    query findVouchers($offset: Float, $limit: Float, $search: String) {
        findVouchers(offset: $offset, limit: $limit, search: $search) {
            vouchers {
                id
                status
                activateMethod
                couponTitle
                company
                validDueDate
                url
                order {
                    id
                    paymentID
                    date
                    electronicInvoice
                    orderNumber
                    totalVat
                    totalPrice
                    vouchers {
                        voucherNumber
                        totalVatPrice
                        totalPrice
                    }
                }
                orderId
                totalPrice
                payDate
                activateDate
                coupon {
                    id
                    title
                    applyVat
                    
                }
            }
            count
        }
    }
`;

export const GET_ORDER_BY_ID = gql`
    query getOrderById($id: ID!) {
        getOrderById(id: $id) {
            orderNumber
            electronicInvoice
            paymentID
            date
            totalPrice
            vouchers {
                id
                voucherNumber
                totalPrice
                totalVatPrice
            }
        }
    }
`;

export const REFUND_VOUCHER = gql`
    mutation refundVoucher($voucherId: ID!, $refundReason: String) {
        refundVoucher(voucherId: $voucherId, refundReason: $refundReason)
    }
`;

export const UPDATE_PROFILE_PICTURE = gql`
    mutation uploadUserPicture($file: Upload!, $userId: ID!) {
        uploadUserPicture(file: $file, userId: $userId) {
            userId
        }
    }
`;

export const DELETE_PROFILE_PICTURE = gql`
    mutation deleteUserPicture($userPictureId: ID!) {
        deleteUserPicture(userPictureId: $userPictureId)
    }
`;
