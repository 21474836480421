import React from 'react';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import s from './ButtonAddOption.module.scss';

const ButtonAddOption = ({ addNewCouponOptions }) => (
  <div
    className={s.container}
  >
    <Button
      icon={<PlusOutlined />}
      block
      onClick={addNewCouponOptions}
      className={s.btnAddOption}
    >
      Add option
    </Button>
  </div>
);

ButtonAddOption.propTypes = {
  addNewCouponOptions: PropTypes.func.isRequired,
};

export default ButtonAddOption;
