import React, { memo } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import MobileCatalogContext from './mobileCatalogContext';
import Catalog from '../Catalog';
import MobileCategories from '../MobileCategories';
import useMobileCatalog from './hooks/useMobileCatalog';
import mobileCondition from '../../constants/mobileCondition';

const MobileCatalogWrapper = () => {
  const contextValue = useMobileCatalog();

  const { isAnyParamExist } = contextValue;

  const [searchParams] = useSearchParams();

  if (!mobileCondition) {
    const navigateParams = isAnyParamExist ? `/?${searchParams.toString()}` : '/';
    return <Navigate to={navigateParams} />;
  }

  const catalogToRender = isAnyParamExist ? <Catalog /> : <MobileCategories />;

  return (
    <MobileCatalogContext.Provider value={contextValue}>
      {catalogToRender}
    </MobileCatalogContext.Provider>
  );
};

export default memo(MobileCatalogWrapper);
