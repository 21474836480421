import { gql } from '@apollo/client';

export const GET_VOUCHER_BY_ID = gql`
    query getVoucherById($id: ID!) {
        getVoucherById(id: $id) {
            id
            company
            address
            validDueDate
            voucherNumber
            totalPrice
            payDate
            totalVatPrice
            status
            payDate
            activateDate
            additionalInformation
            bookingPhoneNumber
            promoCode
            merchantId
            coupon {
                title
                applyVat
                couponPreviewPicture {
                    key
                }
                description
                terms
            }
            voucherOptions {
                voucherNumber
                address
                id
                optionTitle
                totalVatPrice
                couponOption {
                    generateSeparateVoucher
                }
            }
        }
    }
`;

export const GET_VOUCHER_OPTION_BY_ID = gql`
    query getVoucherOptionById($id: ID!) {
        getVoucherOptionById(id: $id) {
            optionTitle
            voucher {
                company
            }
            payDate
            activateDate
            status
            voucherNumber
        }
    }
`;
