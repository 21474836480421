import React, { memo } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import { getRoleUser } from '../../../../../helpers/user-storage';
import { SUPER_ADMIN } from '../../../../../constants/admin-roles';
import {
  finalBackgroundColorTagStatus, finalColorTagTextStatus, finalTextTagStatus,
  STATUS_TYPE_ACTIVE,
  STATUS_TYPE_NOT_USED,
  STATUS_TYPE_REFUND_REQUEST, STATUS_TYPE_REFUNDED,
} from '../../../../../constants/general/tag-status';
import DropdownBtnComponent from '../../../../Common_components/DropdownBtnComponent';

const SuperAdminTagColumn = ({ status, record, activateCallback }) => {
  const activateHandler = (id, statusToExecute) => {
    if (getRoleUser() === SUPER_ADMIN) {
      const { entity } = record;
      activateCallback(id, statusToExecute, entity);
    }
  };

  const actions = [
    {
      key: 0,
      title: 'Activate',
      disabled: status === STATUS_TYPE_ACTIVE || status === STATUS_TYPE_REFUND_REQUEST,
      callback: (id) => activateHandler(id, STATUS_TYPE_ACTIVE),
    },
    {
      key: 1,
      title: 'Cancel activation',
      disabled: status !== STATUS_TYPE_ACTIVE,
      callback: (id) => activateHandler(id, STATUS_TYPE_NOT_USED),
    },
    {
      key: 2,
      title: 'Refund',
      disabled: status !== STATUS_TYPE_REFUND_REQUEST,
      callback: (id) => activateHandler(id, STATUS_TYPE_REFUNDED),
    },
  ];
  return (
    <DropdownBtnComponent
      record={record}
      actions={actions}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '6px',
          cursor: getRoleUser() === SUPER_ADMIN ? 'pointer' : 'default',
        }}
      >
        <Tag
          color={finalBackgroundColorTagStatus(status)}
          style={{
            color: finalColorTagTextStatus(status),
          }}
        >
          {finalTextTagStatus(status)}
        </Tag>
      </div>
    </DropdownBtnComponent>
  );
};

SuperAdminTagColumn.propTypes = {
  status: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
  activateCallback: PropTypes.func.isRequired,
};

export default memo(SuperAdminTagColumn);
