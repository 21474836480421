import React from 'react';
import { STATUS_TYPE_ACTIVE_BY_MERCHANT } from '../../../../constants/merchantConstant';
import useGetMerchantInfo from '../hooks/useGetMerchantInfo';
import MerchantsMaking from '../MerchantsMaking';
import Loader from '../../../Loader/Loader';

const CreateMerchantContainer = () => {
  const initialCreateState = {
    title: '', // field Name
    status: STATUS_TYPE_ACTIVE_BY_MERCHANT,
    isAllowedToGetSalesStatistics: false,
    isAllowedToGetVoucherEmails: false,
    email: '',
    phone: '',
    emirates: [],
    dialCode: 971, // ae
    countNumberInPhone: 12, // ae
    firstName: '',
  };

  const subDivisionsInitState = [];

  const {
    stateInfo,
    setStateInfo,
    setStateInfoHandler,
    subDivisionsInfo,
    addSubDivisionsInfoHandler,
    sendDataHandler,
    deleteSubDivisionsInfoHandler,
    editSubDivisionsInfoHandler,
    loadingCreate,
    isValidMerchantInfo,
    allErrors,
    errorCreate,
  } = useGetMerchantInfo(initialCreateState, subDivisionsInitState);

  return (
    <>
      {loadingCreate && <Loader />}
      <MerchantsMaking
        stateInfo={stateInfo}
        setStateInfo={setStateInfo}
        setStateInfoHandler={setStateInfoHandler}
        subDivisionsInfo={subDivisionsInfo}
        addSubDivisionsInfoHandler={addSubDivisionsInfoHandler}
        sendDataHandler={sendDataHandler}
        deleteSubDivisionsInfoHandler={deleteSubDivisionsInfoHandler}
        editSubDivisionsInfoHandler={editSubDivisionsInfoHandler}
        isValidMerchantInfo={isValidMerchantInfo}
        allErrors={allErrors}
        errorCreate={errorCreate}
      />
    </>
  );
};

export default CreateMerchantContainer;
