import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import useGetCouponsTitle from './gql/useGetCouponsTitle';
import useGetCouponOptionsTitle from './gql/useGetCouponsOptionsTitle';
import useGetPromoCodeById from './gql/useGetPromoCodeById';
import { DiscountUnits, Quantity, Tied } from '../../../../constants/promoCodes/promoCodes';

const useGetPromoCodeInfo = () => {
  const defaultFormValues = {
    discountUnit: DiscountUnits.PERCENTS,
    discount: '',
    validDueDate: '',
    quantity: Quantity.SINGLE,
    availableAmount: '',
    tied: Tied.COUPON,
    couponId: null,
    couponOptionId: null,
    comment: '',
  };

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') || '';

  const { coupons, loading: loadingGetCoupons } = useGetCouponsTitle();
  const { loadingGetCouponById, getPromoCodeByIdHandler, getPromoCodeByIdAdmin } = useGetPromoCodeById();

  const {
    getOptionsForCoupon,
    optionsData,
    loading: loadingGetOptions,
  } = useGetCouponOptionsTitle();

  const [promoCodeData, setPromoCodeData] = useState(defaultFormValues);

  const setPromoCodeInfoHandler = useCallback((key, value) => {
    switch (key) {
    case 'couponId': {
      getOptionsForCoupon(value);
      setPromoCodeData((prev) => ({
        ...prev,
        couponId: value,
        couponOptionId: null,
      }));
      return;
    }
    case 'tied': {
      if (value === Tied.COUPON) {
        setPromoCodeData((prev) => ({
          ...prev,
          tied: value,
          couponOptionId: null,
        }));
      } else {
        setPromoCodeData((prev) => ({
          ...prev,
          tied: value,
        }));
      }
      return;
    }
    case 'quantity': {
      if (value === Quantity.SINGLE) {
        setPromoCodeData((prev) => ({
          ...prev,
          quantity: value,
          availableAmount: '',
        }));
      } else {
        setPromoCodeData((prev) => ({
          ...prev,
          quantity: value,
        }));
      }
      return;
    }
    case 'discountUnit': {
      setPromoCodeData((prev) => ({
        ...prev,
        discountUnit: value,
        discount: '',
      }));
      return;
    }
    default:
      setPromoCodeData((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  }, [getOptionsForCoupon]);

  useEffect(() => {
    if (id !== 'create-promo-code') {
      getPromoCodeByIdHandler(id);
    }
  }, [getPromoCodeByIdHandler, id]);

  useEffect(
    async () => {
      if (id !== 'create-promo-code' && !loadingGetCouponById && getPromoCodeByIdAdmin) {
        await getOptionsForCoupon(getPromoCodeByIdAdmin.couponId);
        setPromoCodeData(getPromoCodeByIdAdmin);
      }
    },
    [id, loadingGetCouponById],
  );

  return {
    coupons,
    optionsData,
    loadingGetOptions,
    loadingGetCoupons,
    loadingGetCouponById,
    setPromoCodeInfoHandler,
    promoCodeData,
  };
};

export default useGetPromoCodeInfo;
