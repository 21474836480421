import React, { memo } from 'react';
import {
  Button, Checkbox, Form, Input,
} from 'antd';
import { Link, useLocation } from 'react-router-dom';
import useLoginMutation from '../../../hooks/useLoginMutation/useLoginMutation';
import s from './MobileSignIn.module.scss';
import Loader from '../../Loader';
import GoogleButton from '../../Common_components/GoogleButton';

const MobileSignIn = () => {
  const [form] = Form.useForm();

  const { sendLoginData, loginLoading, fetchFromLocalLoading } = useLoginMutation();

  const location = useLocation();

  const stateFrom = location.state?.from ? { from: location.state.from } : {};

  const sendLoginCallback = async (values) => {
    try {
      await sendLoginData(values, stateFrom);
    } catch (e) {
      const error = e.message === 'User not found' ? 'Wrong Email or Password!' : e.message;
      form.setFields([{ name: 'email', errors: [error] }]);
    }
  };

  const isLoadingNow = loginLoading || fetchFromLocalLoading;

  return (
    <div className={s.main}>
      {isLoadingNow && <Loader />}
      <span className={s.title}>
        Sign In
      </span>
      <Form
        form={form}
        className={s.form}
        layout="vertical"
        onFinish={sendLoginCallback}
      >
        <Form.Item
          name="email"
          label="Email or username"
          rules={[{ required: true, message: 'Please, enter email or username' }]}
        >
          <Input
            name="email"
            className={s.input}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: 'Please, enter your password' }]}
        >
          <div className={s.passwordContainer}>
            <Link
              to="/admin/signIn/forgot-password"
              className={s.forgot}
            >
              Forgot password?
            </Link>
            <Input.Password
              type="password"
              name="password"
              className={s.input}
            />
          </div>
        </Form.Item>
        <Form.Item
          name="remember"
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox
            name="remember"
            className={s.checkbox}
          >
            <span className={s.checkbox}>
              Remember me
            </span>
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            className={s.submitButton}
            type="primary"
            htmlType="submit"
            disabled={loginLoading}
          >
            Sign In
          </Button>
        </Form.Item>
      </Form>
      <GoogleButton form={form} />
      <div className={s.memberBlock}>
        <span className={s.label}>
          Not a member?
        </span>
        <Link
          to="/auth/sign-up"
          className={s.signUp}
          state={stateFrom}
        >
          Sign Up
        </Link>
      </div>
    </div>
  );
};

export default memo(MobileSignIn);
