import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserContext from '../../Layout/context/userContext';
import Loader from '../../Loader';
import { isUserStored } from '../../../helpers/user-storage';

const ProfileRequireAuth = ({ children }) => {
  const { fetchUserLoading } = useContext(UserContext);

  if (fetchUserLoading) {
    return <Loader />;
  }

  if (!isUserStored()) {
    return <Navigate to="/auth" />;
  }

  return children;
};

ProfileRequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProfileRequireAuth;
