import React from 'react';
import s from './Footer.module.scss';
import LinksBlock from './LinksBlock';
import { companyItems, learnMoreItems } from './constants/linksItems';
import FollowUsBlock from './FollowUsBlock';
import SecureBlock from './SecureBlock';
import mobileCondition from '../../constants/mobileCondition';

const Footer = () => {
  const mainClassName = `${s.main} ${mobileCondition && s.mobile}`;
  const containerClassName = `${s.container} ${mobileCondition && s.mobile}`;
  const labelClassName = `${s.label} ${mobileCondition && s.mobile}`;

  return (
    <div className={mainClassName}>
      {mobileCondition
        ? (
          <>
            <div className={`${containerClassName} ${s.llll}`}>
              <FollowUsBlock />
              <SecureBlock />
            </div>
            <div className={containerClassName}>
              <LinksBlock
                title="Company"
                items={companyItems}
              />
              <LinksBlock
                title="Learn more"
                items={learnMoreItems}
              />
            </div>
          </>
        )
        : (
          <div className={containerClassName}>
            <LinksBlock
              title="Company"
              items={companyItems}
            />
            <LinksBlock
              title="Learn more"
              items={learnMoreItems}
            />
            <FollowUsBlock />
            <SecureBlock />
          </div>
        )}
      <span className={labelClassName}>
        ©
        {' '}
        {new Date().getFullYear()}
        {' '}
        YallaBanana
      </span>
    </div>
  );
};

export default Footer;
