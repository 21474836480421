import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import s from './FooterLink.module.scss';

const FooterLink = ({ route, subtitle }) => (
  <Link
    to={route}
    className={s.link}
  >
    {subtitle}
  </Link>
);

FooterLink.propTypes = {
  subtitle: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

export default FooterLink;
