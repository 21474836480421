import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './SubCategories.module.scss';
import SubCategoryItem from './SubCategoryItem/SubCategoryItem';

const SubCategories = ({ isOpen, subCategories }) => {
  const mappedSubCategories = subCategories.map(({
    id, title, categoryPicture, couponsCount,
  }) => (
    <SubCategoryItem
      key={id}
      icon={categoryPicture?.key}
      title={title}
      id={id}
      couponsCount={couponsCount}
    />
  ));

  const className = `${s.container} ${isOpen && s.active}`;

  return (
    <div className={className}>
      {mappedSubCategories}
    </div>
  );
};

SubCategories.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  subCategories: PropTypes.array.isRequired,
};

export default memo(SubCategories);
