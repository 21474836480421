import React, { memo } from 'react';
import { Divider, Input, Typography } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import s from '../Summary.module.scss';
import mobileCondition from '../../../../constants/mobileCondition';

const DesktopSummary = ({
  totalWithPromo, payButtonCallback, promoCode, promoDiscount, promoCodeError, totalCost, totalVat, setPromo,
  setPromoCode, totalOriginalPrice, totalWithoutVat, totalSaved, loadingCreateOrder,
}) => {
  const summaryContentClassName = `${s.summaryContent} ${mobileCondition && s.mobile}`;

  const applyClassName = `${s.apply} ${mobileCondition && s.mobile}`;

  return (
    <>
      <span>Cart Summary</span>
      <div className={summaryContentClassName}>
        <div className={s.form}>
          <Input
            className={`${s.input} ${promoCodeError && s.inputError}`}
            placeholder="Insert Promo code here"
            value={promoCode}
            onChange={(e) => setPromoCode(e.currentTarget.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') setPromo(promoCode);
            }}
          />
          <button
            className={applyClassName}
            onClick={() => setPromo(promoCode)}
          >
            Apply
          </button>
        </div>
        <div className={s.prices}>
          {promoCodeError && (
            <Typography.Text
              type="danger"
              style={{ fontSize: '12px', marginLeft: '8px' }}
            >
              {promoCodeError}
            </Typography.Text>
          )}
          <div className={s.originalPrice}>
            <Typography.Text secondary="true">Original price</Typography.Text>
            <Typography.Text
              strong
              delete
            >
              {totalOriginalPrice}
              {' '}
              AED
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>YB price</Typography.Text>
            <Typography.Text strong>
              {totalWithoutVat}
              {' '}
              AED
            </Typography.Text>
          </div>
          {promoDiscount > 0 && (
            <div className={s.promoDiscount}>
              <Typography.Text>Promo code</Typography.Text>
              <Typography.Text strong>
                {promoDiscount}
                {' '}
                AED
              </Typography.Text>
            </div>
          )}
          <div>
            <Typography.Text>Total</Typography.Text>
            <Typography.Text strong>
              {totalWithPromo}
              {' '}
              AED
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>VAT</Typography.Text>
            <Typography.Text strong>
              {totalVat}
              {' '}
              AED
            </Typography.Text>
          </div>
        </div>
        <Divider className={s.divider} />
        <div className={s.submit}>
          <div>
            <Typography.Text>Cart total</Typography.Text>
            <Typography.Text strong>
              {totalCost}
              {' '}
              AED
            </Typography.Text>
          </div>
          <div>
            <Typography.Text>Congrats! You saved</Typography.Text>
            <Typography.Text
              strong
              style={{ whiteSpace: 'nowrap' }}
            >
              {totalSaved}
              {' '}
              AED
            </Typography.Text>
          </div>
          <button
            disabled={loadingCreateOrder}
            onClick={payButtonCallback}
          >
            {loadingCreateOrder
              ? <LoadingOutlined color="#333333" />
              : 'Pay'}
          </button>
        </div>
      </div>
    </>
  );
};

DesktopSummary.propTypes = {
  promoCode: PropTypes.string.isRequired,
  setPromoCode: PropTypes.func.isRequired,
  promoCodeError: PropTypes.string,
  promoDiscount: PropTypes.number.isRequired,
  totalOriginalPrice: PropTypes.number.isRequired,
  totalWithPromo: PropTypes.number.isRequired,
  totalVat: PropTypes.number.isRequired,
  totalCost: PropTypes.number.isRequired,
  payButtonCallback: PropTypes.func.isRequired,
  setPromo: PropTypes.func.isRequired,
  totalWithoutVat: PropTypes.number.isRequired,
  totalSaved: PropTypes.number.isRequired,
  loadingCreateOrder: PropTypes.bool.isRequired,
};

DesktopSummary.defaultProps = {
  promoCodeError: null,
};

export default memo(DesktopSummary);
