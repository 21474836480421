import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { EXPORT_CUSTOMERS_TO_EXCEL } from '../../../api/api-buyers/query';
import {
  BUYERS_PURCHASES_FILTER_KEY, FROM_HIGHER_KEY,
  SEARCH_FILTER_KEY,
  SORT_ORDER_FILTER_KEY,
} from '../../../constants/general/filters';

const { REACT_APP_BUCKET_URL } = process.env;

const useExportCustomers = () => {
  const [s] = useSearchParams();

  const sortOrder = s.get(SORT_ORDER_FILTER_KEY) || FROM_HIGHER_KEY;

  const ordersCountFilter = Number(s.get(BUYERS_PURCHASES_FILTER_KEY)) || null;

  const searchQuery = s.get(SEARCH_FILTER_KEY) || '';

  const { data, loading, error } = useQuery(EXPORT_CUSTOMERS_TO_EXCEL, {
    variables: {
      sortOrder,
      ordersCountFilter,
      searchQuery,
    },
  });

  if (data) {
    const { customersExcelKey, message } = data.exportCustomersToExcel;

    return {
      customersExcelKey: `${REACT_APP_BUCKET_URL}/${customersExcelKey}`,
      message,
      error,
      loading,
    };
  }
  return {
    error,
    loading,
  };
};

export default useExportCustomers;
