import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './SingleImageBanner.module.scss';
import { bannersTypes, getImageAspect } from '../../constants/banners/enums';
import useGetBanners from '../Catalog/ShowBanners/hooks/gql/useGetBanners';

const SingleImageBanner = ({ bannerTarget, additionalContainerClass }) => {
  const aspectRatio = getImageAspect(bannerTarget);

  const { bannersData = {} } = useGetBanners(bannerTarget);

  const [banner = { link: null, bannerPicture: null }] = Object.values(bannersData);

  const { bannerPicture, link } = banner;

  const isExternal = link?.indexOf('/coupon') < 0;

  const linkTarget = isExternal ? '_blank' : '_self';

  const image = (
    <img
      style={{ aspectRatio: `${aspectRatio}` }}
      className={s.image}
      src={bannerPicture}
      alt="Banner"
    />
  );

  const totalLink = isExternal
    ? (
      <a
        href={link}
        target={linkTarget}
      >
        {image}
      </a>
    ) : (
      <Link
        to={{
          pathname: link,
        }}
        target={linkTarget}
      >
        {image}
      </Link>
    );

  const slide = link
    ? totalLink
    : image;

  const totalContainerClass = `${s.wrapper} ${additionalContainerClass}`;

  if (!bannerPicture) return <div />;

  return (
    <div className={totalContainerClass}>
      {slide}
    </div>
  );
};

SingleImageBanner.propTypes = {
  bannerTarget: PropTypes.oneOf(Object.values(bannersTypes)).isRequired,
  additionalContainerClass: PropTypes.string,
};

SingleImageBanner.defaultProps = {
  additionalContainerClass: '',
};

export default SingleImageBanner;
