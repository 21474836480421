import { gql } from '@apollo/client';

export const GetCommonSettings = gql`
    query {
        getCommonSettings {
            vat
        }
    }
`;

export const UpdateCommonSettings = gql`
    mutation updateCommonSettings(
        $updateCommonSettingsInput: UpdateCommonSettingsInput!
    ) {
        updateCommonSettings(
            updateCommonSettingsInput: $updateCommonSettingsInput
        ) {
            id
            vat
        }
    }
`;
