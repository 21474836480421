import {
  Checkbox, Divider, Form, Input,
} from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import '../../CouponInfo/CouponInfo.scss';
import SelectMultiply from '../../../../../Common_components/MultiplySelectAddress/SelectMultiply';
import CountingBody from '../../../CountingBody';
import s from '../../CouponInfo/CouponInfo.module.scss';
import { finallyBorderInput } from '../../helpers/finallyBorder';
import BtnDropdown from '../BtnDropdown';

const Option = ({
  couponInput, copyCouponOptions,
  deleteCouponOptions, merchants,
  activeMerchant, vatValue, setOptionForAddOptions, setOptionFilialBranchesForAddOptions, error,
}) => {
  const setCouponOptionsHandler = (values) => {
    setOptionFilialBranchesForAddOptions(values, couponInput.id);
  };

  const setOptionHandler = (key, values) => {
    setOptionForAddOptions(key, values, couponInput.id);
  };

  return (
    <div
      className="couponInfo"
    >
      <Form
        layout="horizontal"
      >
        <Form.Item label="Option name">
          <Input
            value={couponInput.title}
            maxLength={254}
            onChange={(e) => setOptionHandler('title', e.target.value)}
            style={{
              border: error?.title && finallyBorderInput(couponInput.title && couponInput.title.trim()),
              height: '40px',
              borderRadius: '4px',
            }}
          />
          {error?.title && !couponInput.title && <span className={s.errorMessage}>{error.title}</span>}
        </Form.Item>

        <CountingBody
          couponInput={couponInput}
          callback={setOptionHandler}
          vatValue={vatValue}
          allErrors={error}
        />
        <Form.Item
          name="promo-code"
          label="Can use promo-code"
        >
          <Checkbox
            checked={couponInput.allowToUsePromoCode}
            onChange={(e) => setOptionHandler('allowToUsePromoCode', e.target.checked)}
          />
        </Form.Item>

        <Form.Item
          name="promo-code"
          label="Generate unique ID & qr-code"
        >
          <Checkbox
            checked={couponInput.generateSeparateVoucher}
            onChange={(e) => setOptionHandler('generateSeparateVoucher', e.target.checked)}
          />
        </Form.Item>

        <Form.Item label="Address">
          <SelectMultiply
            activeOption={activeMerchant}
            merchants={merchants}
            address={couponInput.filialBranches}
            callback={setCouponOptionsHandler}
            error={error}
          />
          {error?.filialBranches && !couponInput.filialBranches?.length
              && <span className={s.errorMessage}>This field is required</span>}
        </Form.Item>
        <Divider />
        <BtnDropdown
          optionId={couponInput.id}
          copyCouponOptions={copyCouponOptions}
          deleteCouponOptions={deleteCouponOptions}
        />
      </Form>
    </div>
  );
};
Option.propTypes = {
  couponInput: PropTypes.oneOfType([PropTypes.object]).isRequired,
  copyCouponOptions: PropTypes.func.isRequired,
  deleteCouponOptions: PropTypes.func.isRequired,
  merchants: PropTypes.oneOfType([PropTypes.array]),
  activeMerchant: PropTypes.string,
  vatValue: PropTypes.number,
  setOptionForAddOptions: PropTypes.func.isRequired,
  setOptionFilialBranchesForAddOptions: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.object]),
};
Option.defaultProps = {
  vatValue: undefined,
  merchants: undefined,
  activeMerchant: null,
  error: undefined,
};

export default Option;
