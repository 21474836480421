import { useState } from 'react';

const useTotalOrder = (couponId, couponOptions) => {
  const initOptions = couponOptions?.map(({ id }) => ({ couponOptionId: id, amount: 0 })) || undefined;

  const [totalOrder, setTotalOrder] = useState({
    amount: 1,
    couponId,
    shoppingCartCouponOptions: initOptions,
  });

  const resetOrder = () => setTotalOrder({
    amount: 1,
    couponId,
    shoppingCartCouponOptions: initOptions,
  });

  const handleCouponOrder = (handleAmount, id) => setTotalOrder((values) => ({
    ...values, amount: values.amount + handleAmount, couponId: id,
  }));

  const handleOptionsOrder = (handleAmount, optionId) => setTotalOrder((values) => {
    const { shoppingCartCouponOptions } = values;
    if (shoppingCartCouponOptions) {
      const option = shoppingCartCouponOptions.find(({ couponOptionId }) => couponOptionId === optionId);
      if (option) {
        return {
          ...values,
          shoppingCartCouponOptions: shoppingCartCouponOptions.length > 0
            ? shoppingCartCouponOptions
              .map((o) => (o.couponOptionId === optionId ? { ...o, amount: o.amount + handleAmount } : o))
            : undefined,
        };
      }
      return {
        ...values,
        shoppingCartCouponOptions:
            [...shoppingCartCouponOptions, { amount: handleAmount, couponOptionId: optionId }],
      };
    }
    return { ...values, shoppingCartCouponOptions: [{ amount: handleAmount, couponOptionId: optionId }] };
  });

  return {
    totalOrder, handleCouponOrder, handleOptionsOrder, resetOrder,
  };
};

export default useTotalOrder;
