import React, { memo, useCallback } from 'react';
import './payment-form.scss';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentHeader from './PaymentHeader';
import PaymentBody from './PaymentBody';
import ButtonComponent from '../../../Common_components/ButtonComponent';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIP_KEY}`); // publish key

const PaymentForm = () => {
  // console.log(stripePromise);
  /* const options = {
  // passing the client secret obtained from the server
  clientSecret: '{{CLIENT_SECRET}}',
}; */

  //  const [isProcessing, setProcessingTo] = useState(false);

  // const elements = useElements();
  // const stripe = useStripe();

  const handleSubmit = useCallback(async () => {
    /*

    setProcessingTo(true);

    if (!elements || !stripe) {
      setProcessingTo(false);
      return;
    }

    // create payment intent
    // const {error: errorBackend,clientSecret} = -запрос пошел
    const clientSecret = '231313131';

    /* if (errorBackend) {
      console.log('error');
      //вызвать ошибку
      openModalResult('failed');
      setCheckoutError(errorBackend);
      return;
    } */

    // confirm the payment on the client
    /* const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      /* payment_method: {
        card: elements.getElement(CardElement),
      }, */
    /* });

    if (stripeError) {
      console.log(stripeError.message);
      // вызвать ошибку
      openModalResult('failed');
      setProcessingTo(false);
      return;
    }

    console.log(`Payment (${paymentIntent})`);
    console.log(`Payment (${paymentIntent.id}) ${paymentIntent.status}`);
    openModalResult('success');
    setProcessingTo(false); */
  }, []);

  return (
    <div className="paymentFormContainer">
      <Elements
        stripe={stripePromise}
      >
        <PaymentHeader />
        <PaymentBody />
        <ButtonComponent
          title="Pay"
          callback={handleSubmit}
          // disabled={isProcessing}
          iconComponent={null}
          addClass="paymentButton"
        />
      </Elements>
    </div>
  );
};
export default memo(PaymentForm);
