import { useQuery } from '@apollo/client';
import { GET_COMMON_SETTINGS } from '../../../../../../api/query';

const useGetCommonSettings = () => {
  const { data, loading: loadingGetVat } = useQuery(GET_COMMON_SETTINGS);

  return {
    vat: data?.getCommonSettings.vat,
    loadingGetVat,
  };
};
export default useGetCommonSettings;
