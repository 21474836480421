import { Input } from 'antd';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Search } from '../../../constants/icons';
import './search-component.scss';

const SearchInputComponent = ({
  value, callback, placeholder, onEnterCallback,
}) => (
  <Input
    value={value}
    className="search"
    onKeyPress={onEnterCallback}
    placeholder={placeholder}
    suffix={<Search />}
    onChange={callback}
  />
);
SearchInputComponent.propTypes = {
  value: PropTypes.string.isRequired,
  callback: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  onEnterCallback: PropTypes.func,
};

SearchInputComponent.defaultProps = {
  callback: null,
  onEnterCallback: undefined,
};

export default memo(SearchInputComponent);
