import { useQuery } from '@apollo/client';
import { useContext, useMemo } from 'react';
import { uid } from 'uid';
import { useParams } from 'react-router-dom';
import { FIND_COUPON_BY_ID } from '../../../api/coupon-page-api/query';
import removeCouponFromLocalCart from '../../../helpers/couponPage/local-storage';
import { addCouponToLocalStorageCart } from '../../../helpers/catalog-helpers/catalog-helpers';
import CountContext from '../../Layout/context/countContext';

const useCouponDataFetch = () => {
  const { couponId } = useParams();

  const { updateLocalCouponsCount } = useContext(CountContext);

  const { data, loading, refetch: couponDataRefetch } = useQuery(FIND_COUPON_BY_ID, {
    variables: {
      id: couponId,
    },
  });

  const couponData = useMemo(() => (loading ? null : data.findCouponById), [data, loading]);

  const addCouponToLocalCart = (totalOrder) => {
    if (couponData) {
      const { amount, shoppingCartCouponOptions } = totalOrder;
      const {
        id,
        couponPreviewPicture,
        title,
        vatPrice,
        currentPrice,
        oldPrice,
        publishedDueDate,
        couponOptions,
        status,
      } = couponData;
      const editedOptions = (shoppingCartCouponOptions && couponOptions) ? shoppingCartCouponOptions
        .reduce((acc, el) => {
          const { amount, couponOptionId } = el;
          const optionInOrder = couponOptions.find(({ id }) => id === couponOptionId);
          if (optionInOrder) {
            const {
              title, vatPrice, currentPrice, id,
            } = optionInOrder;
            const newOption = {
              amount,
              id,
              couponOption: {
                vatPrice, oldPrice, currentPrice, title,
              },
            };
            return [...acc, newOption];
          }
          return acc;
        }, []) : [];
      const cartId = uid();
      const localCouponItem = {
        cartId,
        id,
        status,
        couponPreviewPicture,
        title,
        vatPrice,
        currentPrice,
        oldPrice,
        quantity: amount,
        deadline: publishedDueDate,
        shoppingCartCouponOptions: editedOptions,
        removeCallback: removeCouponFromLocalCart,
      };
      addCouponToLocalStorageCart(localCouponItem);
      updateLocalCouponsCount((c) => c + amount);
    }
  };

  if (data) {
    const { findCouponById: { filialBranches } } = data;
    const filialBranchesCoordinates = filialBranches.map((filial) => {
      const { address, location } = filial;
      const [lat, lng] = location;
      return { address, location: { lat, lng } };
    });

    const fixData = { ...couponData, couponDataRefetch };

    return {
      couponData: fixData,
      loading,
      addCouponToLocalCart,
      filialBranchesCoordinates,
    };
  }

  return {
    couponData,
    loading,
    addCouponToLocalCart,
  };
};

export default useCouponDataFetch;
