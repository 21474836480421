import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import cartIcon from '../../../assets/images/cart_icon.svg';
import boxIcon from '../../../assets/images/box_icon.svg';
import './AddToCartButton.scss';

let timeout = null;

const AddToCartButton = ({ addToCartCallback, additionalButtonClass }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const handleAnimationStart = useCallback((e) => {
    e.stopPropagation();
    if (!isAnimated) {
      setIsAnimated(true);
      addToCartCallback();
    }
  }, [isAnimated, addToCartCallback]);

  useEffect(() => () => {
    clearTimeout(timeout);
  }, []);

  const handleAnimationEnd = useCallback(() => {
    clearInterval(timeout);
    timeout = setTimeout(
      () => setIsAnimated(false),
      500,
    );
  }, []);

  const buttonClassName = `cart-button ${isAnimated ? 'clicked' : ''} ${additionalButtonClass}`;

  return (
    <button
      onAnimationEnd={handleAnimationEnd}
      onClick={handleAnimationStart}
      className={buttonClassName}
    >
      <span className="add-to-cart">Add to cart</span>
      <span className="added">Added</span>
      <img
        src={cartIcon}
        alt="cart"
        className="fas fa-shopping-cart"
      />
      <img
        src={boxIcon}
        alt="box"
        className="fas fa-box"
      />
    </button>
  );
};

AddToCartButton.propTypes = {
  addToCartCallback: PropTypes.func.isRequired,
  additionalButtonClass: PropTypes.string,
};

AddToCartButton.defaultProps = {
  additionalButtonClass: '',
};

export default memo(AddToCartButton);
