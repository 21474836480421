import React from 'react';
import PropTypes from 'prop-types';
import style from './SuffixInput.module.scss';
import { DiscountUnits } from '../../../constants/promoCodes/promoCodes';

const SuffixInput = ({
  value, onChange, disabled, className, discountUnit, suffix,
}) => {
  const regNumber = /^[0-9]+$/;
  const regPrice = /^([0-9]{0,20}((.)[0-9]{0,2}))$/;

  const onChangeHandler = (e) => {
    const { value } = e.target;
    switch (discountUnit) {
    case DiscountUnits.PERCENTS: {
      // eslint-disable-next-line no-restricted-globals
      if ((!isNaN(value) && regNumber.test(value)) || value === '') {
        if (+e.target.value <= 100) {
          onChange(value);
          return;
        }
        onChange('100');
        return;
      }
      return;
    }
    default: {
      // eslint-disable-next-line no-restricted-globals
      if ((!isNaN(value) && regPrice.test(value)) || value === '') {
        if (+value < 2147483647) {
          onChange(value);
        } else {
          onChange('2147483647');
        }
      }
    }
    }
  };

  return (
    <div
      className={`${disabled && style.disabled} ${style.suffixInput} ${className}`}
    >
      <input
        autoFocus
        value={value}
        onChange={onChangeHandler}
        disabled={disabled}
        style={{ width: `${String(value).length + 1}ch` }}
        className={`${style.input} suffixInput`}
      />
      <span
        className={style.suffix}
        style={{ width: `${suffix.length + 1}ch` }}
      >
        {suffix}
      </span>
    </div>
  );
};

SuffixInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  suffix: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  discountUnit: PropTypes.string.isRequired,
};

SuffixInput.defaultProps = {
  disabled: false,
  className: '',
};

export default SuffixInput;
