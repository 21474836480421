import React, {
  memo, useCallback, useContext, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import s from './Summary.module.scss';
import CartContext from '../cartContext/cartContext';
import getCost from '../../../helpers/cart-helpers/getCost';
import UserContext from '../../Layout/context/userContext';
import Loader from '../../Loader';
import mobileCondition from '../../../constants/mobileCondition';
import { AUTH } from '../../../constants/Routes';
import useCreateOrder from '../hooks/useCreateOrder';
import SummaryContainer from './SummaryContainer';
import useTryOrder from '../hooks/useTryOrder';
import OutOfStockModal from '../OutOfStockModal';
import useFixCartOrder from '../hooks/useFixCartOrder';

const Summary = () => {
  const { userData, setIsSignUpActive, setProcessingCallback } = useContext(UserContext);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { createOrderHandler, loadingCreateOrder } = useCreateOrder();

  const {
    tryLoading, tryOrderCallback, stockData, removeStockCoupons, removeStockLoading, removeCouponWithZeroCount,
  } = useTryOrder();

  const { fixOrderHandler, fixOrderLoading } = useFixCartOrder();

  const fixOrderCallback = useCallback(async () => {
    await removeCouponWithZeroCount();
    const fixedOrder = stockData
      .filter(({ coupon: { currentAvailableAmount } }) => currentAvailableAmount > 0)
      .map(({ id, coupon: { currentAvailableAmount } }) => ({ id, amount: currentAvailableAmount }));
    await fixOrderHandler(fixedOrder);
  }, [stockData]);

  const [isActive, setIsActive] = useState(false);

  const hideModalHandler = () => setIsActive(false);

  const [promoCode, setPromoCode] = useState('');

  const buyCallback = useCallback(async () => {
    const tryData = await tryOrderCallback();
    if (!tryData) {
      await createOrderHandler(promoCode);
    } else {
      setIsActive(true);
    }
  }, [promoCode]);

  const buyModalCallback = useCallback(async () => {
    await fixOrderCallback();
    await buyCallback();
  }, [fixOrderCallback, buyCallback]);

  const {
    totalCostData, setPromo, isLoadingNow, promoCodeError,
  } = useContext(CartContext);

  const totalOriginalPrice = getCost(totalCostData.totalOldCostOfCouponsInCart / 100);

  const totalWithoutVat = getCost(totalCostData.totalCostOfCouponsInCart / 100);

  const promoDiscount = getCost(totalCostData.totalPromoDiscountOfCouponsInCart / 100);

  const discountCost = getCost(totalCostData.totalDiscountOfCouponsInCart / 100);

  const totalCost = getCost(totalCostData.totalVatOfCouponsInCart / 100);

  const totalVat = getCost((totalCostData.totalVatOfCouponsInCart - totalCostData.totalCostOfCouponsInCart) / 100);

  const summaryClassName = `${s.summary} ${mobileCondition && s.mobile}`;

  const payButtonCallback = useCallback(async (e) => {
    e.stopPropagation();
    if (!userData) {
      if (mobileCondition) {
        navigate(AUTH, { state: pathname });
      } else {
        setIsSignUpActive(true);
      }
      setProcessingCallback(buyCallback);
    } else {
      await buyCallback();
    }
  }, [userData, createOrderHandler, promoCode, setProcessingCallback, pathname]);

  return (
    <div className={summaryClassName}>
      {isLoadingNow && <Loader />}
      <SummaryContainer
        promoCode={promoCode}
        totalWithPromo={totalWithoutVat}
        payButtonCallback={payButtonCallback}
        promoDiscount={promoDiscount}
        totalCost={totalCost}
        totalVat={totalVat}
        promoCodeError={promoCodeError}
        setPromo={setPromo}
        setPromoCode={setPromoCode}
        totalOriginalPrice={totalOriginalPrice}
        totalWithoutVat={totalWithoutVat}
        totalSaved={discountCost}
        loadingCreateOrder={tryLoading || loadingCreateOrder}
      />
      <OutOfStockModal
        isActive={isActive}
        hideModalHandler={hideModalHandler}
        isCancelLoading={removeStockLoading}
        isActionLoading={fixOrderLoading || tryLoading || loadingCreateOrder}
        cancelButtonAction={removeStockCoupons}
        buyButtonCallback={buyModalCallback}
        stockData={stockData}
      />
    </div>
  );
};

export default memo(Summary);
