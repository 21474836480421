import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './search-input.scss';

const SearchInput = ({
  value, callback, disabled, addClass,
}) => (
  <Input
    value={value}
    disabled={disabled}
    className={`search ${addClass}`}
    placeholder="Search..."
    suffix={<SearchOutlined />}
    onChange={callback}
  />
);
SearchInput.propTypes = {
  value: PropTypes.string,
  callback: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  addClass: PropTypes.string,
};
SearchInput.defaultProps = {
  disabled: false,
  addClass: '',
  value: undefined,
};
export default memo(SearchInput);
