import React from 'react';
import Page from '../../components/Page';
import MerchantTable from '../../components/Merchant/MerchantTable';

const Merchants = () => (
  <Page>
    <MerchantTable />
  </Page>
);

export default Merchants;
