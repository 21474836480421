import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { COUPONS_OPTIONS_TITLE } from '../../../../../api/PromoCodes/query';

const useGetCouponOptionsTitle = () => {
  const [couponOptionsTitle, { loading, error, data }] = useLazyQuery(COUPONS_OPTIONS_TITLE);

  const getOptionsForCoupon = useCallback((couponId) => {
    couponOptionsTitle({
      variables: {
        couponId,
      },
    });
  }, [couponOptionsTitle]);

  if (data) {
    const { couponOptionsTitle: optionsData } = data;
    return {
      loading,
      error,
      getOptionsForCoupon,
      optionsData,
    };
  }

  return {
    loading,
    error,
    getOptionsForCoupon,
  };
};

export default useGetCouponOptionsTitle;
