import React, { memo } from 'react';
import UserTerms from '../UserTerms';
import MerchantTerms from '../MerchantTerms';
import s from './TermsAndConditions.module.scss';

const TermsAndConditions = () => (
  <div className={s.wrapper}>
    <UserTerms />
    <MerchantTerms />
  </div>
);

export default memo(TermsAndConditions);
