import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { ArrowBottom } from '../../../../../constants/icons';
import s from './table-merchant.module.scss';
import './table-merchant-ant.scss';
import { limit } from '../../../../../constants/tableConstants';
import useWindowSize from '../../../../../hooks/useWindowSize/useWindowSize';
import { heightMinusForAdminTables } from '../../../../../constants/general/tag-status';

const expandIcon = ({
  expanded, onExpand, record, expandable,
}) => (
  expandable
    && (
      <span
        className={expanded ? s.expanded : s.expand}
        onClick={(e) => onExpand(record, e)}
      >
        <ArrowBottom />
      </span>
    )
);

const TableComponent = ({ columns, dataSource = [], count }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page') || '1';

  const finalData = dataSource.map((item) => {
    const {
      filialBranches, contacts, id: idMerchant, ...rest
    } = item;

    const newBusinessHours = filialBranches?.[0]?.businessHours.map((hours) => {
      const { startAt, endAt, ...rest } = hours;
      const sliceStartAt = startAt.substring(0, 5);
      const sliceEndAt = endAt.substring(0, 5);

      return {
        ...rest,
        startAt: sliceStartAt,
        endAt: sliceEndAt,
      };
    });

    const children = filialBranches.length !== 0
      ? filialBranches.map((filial) => {
        const {
          id, title, address, phone, businessHours, location,
        } = filial;

        const newBusinessHours = businessHours.map((hours) => {
          const { startAt, endAt, ...rest } = hours;
          const sliceStartAt = startAt.substring(0, 5);
          const sliceEndAt = endAt.substring(0, 5);

          return {
            ...rest,
            startAt: sliceStartAt,
            endAt: sliceEndAt,
          };
        });

        return {
          key: id,
          address,
          location,
          title,
          contact: '-',
          email: '-',
          phone: phone || '-',
          status: '',
          businessHours: newBusinessHours,
          parent: false,
          id,
        };
      })
      : null;

    const { firstName, phone, email } = contacts[0];
    return {
      ...rest,
      contact: firstName,
      address: filialBranches?.[0]?.address,
      phone,
      email,
      businessHours: newBusinessHours,
      key: idMerchant,
      id: idMerchant,
      parent: true,
      children,
    };
  });

  const [height] = useWindowSize();

  const onChangePage = (page) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page,
    });
  };
  return (
    <section className="merchant-table">
      <Table
        className="adminTable"
        dataSource={finalData}
        columns={columns}
        scroll={{
          y: height - heightMinusForAdminTables, x: 0,
        }}
        rowKey={(record) => record.id}
        pagination={{
          current: Number(page),
          pageSize: limit,
          total: count,
          position: ['bottomCenter'],
          size: 'small',
          showSizeChanger: false,
          onChange: (page) => onChangePage(page),
        }}
        expandable={{
          expandIcon,
        }}
      />
    </section>
  );
};

TableComponent.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  dataSource: PropTypes.oneOfType([PropTypes.array]),
  count: PropTypes.number,
};
TableComponent.defaultProps = {
  count: undefined,
  dataSource: undefined,
};

export default memo(TableComponent);
