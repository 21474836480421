import React, { useCallback } from 'react';
import { Tag, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import transformTimeForTable from '../../../MerchantsMaking/BodyMerchant/MerchantPage/helper/transformTimeForTable';
import DropdownDays from '../../../MerchantsMaking/BodyMerchant/MerchantPage/columns/subdivisionsColumn/Dropdown';
import useDeleteMerchantByAdmin from '../../hooks/gql/useDeleteMerchantByAdmin';
import useDeleteFilialBranches from '../../hooks/gql/useDeleteFillialBranches';
import DropdownBtnComponent from '../../../../Common_components/DropdownBtnComponent';

import {
  finalBackgroundColorTagStatus,
  finalColorTagTextStatus,
  finalTextTagStatus,
} from '../../../../../constants/general/tag-status';
import DropdownWithClearComponent
  from '../../../../Common_components/DropdownWithClearComponent';
import { STATUS_FILTER_KEY, statusFilters } from '../../../../../constants/general/filters';
import useSearchFilters from '../../../../Coupon/CouponTable/hooks/filters/useSearchFilters';

const useGetMerchantColumn = (merchants, editFilialBranchHandler) => {
  const { deleteMerchant } = useDeleteMerchantByAdmin();
  const { deleteFilialBranch } = useDeleteFilialBranches();

  const { searchParams, setSearchParams, dropdownValueHandler } = useSearchFilters();

  const page = searchParams.get('page') || '1';

  const deleteElement = useCallback(async (id, searchMerchant) => {
    if (searchMerchant.parent) {
      // delete of merchant
      try {
        await deleteMerchant(id);
        const newPage = merchants?.length === 1 ? +page - 1 : page;
        const finalPage = newPage === 0 ? 1 : newPage;
        setSearchParams({
          page: finalPage,
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    } else {
      try {
        await deleteFilialBranch(id);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  }, [deleteFilialBranch, deleteMerchant, merchants, page, setSearchParams]);

  const navigate = useNavigate();

  const editElement = useCallback((id, searchMerchant) => {
    if (searchMerchant.parent) {
      navigate(`edit-merchant/:${id}`);
    } else {
      const { __typename, businessHours, ...restFilialBranches } = searchMerchant;

      const editBusinessHours = businessHours.map((hours) => {
        const {
          id, startAt, endAt, __typename, ...rest
        } = hours;

        const sliceStartAt = startAt.substring(0, 5);
        const sliceEndAt = endAt.substring(0, 5);

        return {
          ...rest,
          intervalId: id,
          startAt: sliceStartAt,
          endAt: sliceEndAt,
          isCheckedDay: true,
        };
      });
      editFilialBranchHandler({
        ...restFilialBranches,
        couponGoldenHours: editBusinessHours,
        couponGoldenHoursToCreate: [],
        couponGoldenHoursToUpdate: [],
        couponGoldenHoursToDelete: [],
      });
    }
  }, [editFilialBranchHandler, navigate]);

  const filterHandlerOneValue = (value) => dropdownValueHandler(value, STATUS_FILTER_KEY);

  return [
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      width: '15%',
      render: (text) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      ellipsis: true,
      key: 'address',
      width: '16%',
      render: (address) => (
        <Typography.Text>
          {address}
        </Typography.Text>
      ),
    },
    {
      title: 'Contact person',
      dataIndex: 'contact',
      ellipsis: true,
      key: 'contact',
      width: '10%',
      render: (contact) => (
        <Typography.Text>
          {contact}
        </Typography.Text>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      width: '14%',
      render: (phone) => (
        <Typography.Text>
          <PhoneInput
            value={phone}
            disabled
            inputClass="telInput"
            buttonStyle={{
              display: 'none',
            }}
            dropdownStyle={{
              display: 'none',
            }}
            inputStyle={{
              border: 'none',
              cursor: 'text',
              background: 'transparent',
              boxShadow: 'none',
            }}
          />
        </Typography.Text>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      ellipsis: true,
      key: 'email',
      width: '12%',
      render: (email) => (
        <Typography.Text>
          {email}
        </Typography.Text>
      ),
    },
    {
      title: () => (
        <div
          className="dropdownWrapperHeader"
        >
          <span>Status</span>
          <DropdownWithClearComponent
            items={statusFilters}
            minWidth="150px"
            callback={filterHandlerOneValue}
          />
        </div>
      ),
      dataIndex: 'status',
      key: 'status',
      width: '9%',
      render: (status) => (
        <Tag
          color={finalBackgroundColorTagStatus(status)}
          style={{
            color: finalColorTagTextStatus(status),
          }}
        >
          {finalTextTagStatus(status)}
        </Tag>
      ),
    },
    {
      title: 'Working',
      dataIndex: 'businessHours',
      key: 'businessHours',
      width: '18%',
      render: (times) => {
        if (times) {
          return (
            <div className="dropDays">
              <div className="timeDrop">{times ? transformTimeForTable(times[0]) : ''}</div>
              {
                times.length > 1 && (
                  <div className="arrowDrop">
                    <DropdownDays days={times} />
                  </div>
                )
              }
            </div>
          );
        }
        return null;
      },
    },
    {
      key: 'actions',
      width: '5%',
      render: (text, record) => (
        <DropdownBtnComponent
          text={text}
          record={record}
          actions={[
            {
              key: '0',
              title: 'Edit',
              callback: (id) => editElement(id, record),
            },
            {
              key: '1',
              title: 'Delete',
              callback: (id) => deleteElement(id, record),
            },
          ]}
        />
      ),
    },
  ];
};

export default useGetMerchantColumn;
