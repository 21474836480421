import { useEffect, useRef, useState } from 'react';
import mobileCondition from '../../../../constants/mobileCondition';

let timeout = null;

const useFilterForm = (form, setSearchParams, params) => {
  const [isSortedPrice, setSortedByPrice] = useState(true);

  const resetFormValues = () => {
    clearTimeout(timeout);
    setSearchParams({
      ...params,
      title: '',
      priceMin: undefined,
      priceMax: undefined,
      discountMin: undefined,
      discountMax: undefined,
      emirates: undefined,
      isSortedPrice: undefined,
    });
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    const keys = Object.keys(values);
    const filtered = keys.filter((el) => Object.hasOwnProperty.call(params, el));
    if (!filtered.length) form.resetFields();
  }, [params, form]);

  const ref = useRef(null);

  ref.current = timeout;

  useEffect(() => {
    const { isSortedPrice } = params || {};
    setSortedByPrice(() => !!isSortedPrice);
  }, []);

  useEffect(() => () => {
    clearTimeout(timeout);
  }, []);

  const setFilterParams = () => setSearchParams({
    ...params, offset: 0, ...form.getFieldsValue(), isSortedPrice,
  });

  const setParamsOnEnterPress = (e) => {
    if (e.key === 'Enter') {
      setFilterParams();
    }
  };

  const checkboxGroupChange = (values) => {
    form.setFieldsValue({ emirates: values });
    clearTimeout(timeout);
    timeout = setTimeout(setFilterParams, 2000);
  };

  const handleTitleValue = (e) => {
    const { name, value } = e.target;
    form.setFieldsValue({ [name]: value });
    clearTimeout(timeout);
    timeout = setTimeout(setFilterParams, 2000);
  };

  const handleSortByPrice = (e) => {
    const { name, value } = e.target;
    form.setFieldsValue({ [name]: value });
    clearTimeout(timeout);
    timeout = setTimeout(setFilterParams, 2000);
  };

  const handleDiscountValue = (e) => {
    const { name, value } = e.target;
    const numbers = value.replace(/\D/g, '');
    const newValue = +numbers > 0 && +numbers <= 100 ? +numbers : +numbers.substring(0, numbers.length - 1);
    form.setFieldsValue({ [name]: (newValue !== 0 ? newValue : null) });
    if (!mobileCondition) {
      clearTimeout(timeout);
      timeout = setTimeout(setFilterParams, 2000);
    }
  };

  const sortByPrice = (value) => {
    setSortedByPrice(value);
    clearTimeout(timeout);
    timeout = setTimeout(() => setSearchParams({
      ...params, offset: 0, ...form.getFieldsValue(), isSortedPrice: value,
    }), 2000);
  };

  const handlePriceValue = (e) => {
    const { name, value } = e.target;
    const numbers = value.replace(/\D/g, '');
    const newValue = +numbers > 0 ? +numbers : +numbers.substring(0, numbers.length - 1);
    form.setFieldsValue({ [name]: (newValue !== 0 ? newValue : null) });
    if (!mobileCondition) {
      clearTimeout(timeout);
      timeout = setTimeout(setFilterParams, 2000);
    }
  };

  return {
    handleTitleValue,
    handleDiscountValue,
    handlePriceValue,
    resetFormValues,
    setParamsOnEnterPress,
    setFilterParams,
    checkboxGroupChange,
    handleSortByPrice,
    sortByPrice,
    isSortedPrice,
  };
};

export default useFilterForm;
