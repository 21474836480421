import React, { useCallback } from 'react';
import { Typography } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import useCreateCouponCopyByAdmin from '../../hooks/gql/useCreateCouponCopyAdmin';
import useUpdateCouponStatusAdmin from '../../hooks/gql/useUpdateCouponStatusAdmin';
import { roundingPrice } from '../../../../../constants/math';
import useDeleteCoupon from '../../hooks/gql/useDeleteCoupon';
import DropdownBtnComponent from '../../../../Common_components/DropdownBtnComponent';
import { FORMAT_DATE_TABLE, STATUS_TABLE_ACTIVE, STATUS_TABLE_ARCHIVED } from '../../../../../constants/couponsAdmin';
import { getRoleUser } from '../../../../../helpers/user-storage';
import {
  CATEGORY_ID_FILTER_KEY,
  defaultFilters,
  PURCHASES_FILTER_KEY,
  SUB_CATEGORY_FILTER_KEY,
  VIEWS_FILTER_KEY,
} from '../../../../../constants/general/filters';
import DropdownWithClearComponent
  from '../../../../Common_components/DropdownWithClearComponent';
import { MERCHANT_ADMIN, MERCHANT_SUPER_ADMIN } from '../../../../../constants/admin-roles';
import copyToClipboard from '../../../../../helpers/copyToClipboard';
import TooltipComponent from '../../../../Common_components/TooltipComponent';
import { CopyIcon } from '../../../../../constants/icons';
import useSearchFilters from '../../hooks/filters/useSearchFilters';
import DoubleDateTableWrapper from '../../../../Common_components/DoubleDateForTableWrapper';

const useGetArchivedColumn = (coupons, filterCategories, filterSubCategories, showMessageIdOfCouponWasCopied) => {
  const { updateCouponByAdmin } = useUpdateCouponStatusAdmin();
  const { createCopyCoupon } = useCreateCouponCopyByAdmin();
  const { deleteCouponByAdmin } = useDeleteCoupon();
  const role = getRoleUser();

  const navigate = useNavigate();

  const { searchParams, setSearchParams, dropdownValueHandler } = useSearchFilters();

  const page = searchParams.get('page') || '1';
  const status = searchParams.get('status') || STATUS_TABLE_ACTIVE;

  const editCoupon = useCallback((id) => {
    navigate(`edit-coupon/:${id}`);
  }, [navigate]);

  const activateCoupon = useCallback(async (id) => {
    try {
      await updateCouponByAdmin(id, STATUS_TABLE_ACTIVE, true);
      if (status === STATUS_TABLE_ARCHIVED) {
        const newPage = coupons?.length === 1 ? +page - 1 : page;
        const finalPage = newPage === 0 ? 1 : newPage;
        setSearchParams({
          ...Object.entries(searchParams),
          status,
          page: finalPage,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [coupons, page, searchParams, setSearchParams, status, updateCouponByAdmin]);

  const deleteCoupon = useCallback(async (id) => {
    try {
      await deleteCouponByAdmin(id);
      if (status === STATUS_TABLE_ARCHIVED) {
        const newPage = coupons?.length === 1 ? +page - 1 : page;
        const finalPage = newPage === 0 ? 1 : newPage;
        setSearchParams({
          ...Object.entries(searchParams),
          status,
          page: finalPage,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [coupons, deleteCouponByAdmin, page, searchParams, setSearchParams, status]);

  const copiedId = useCallback(async (id) => {
    await copyToClipboard(id);
    showMessageIdOfCouponWasCopied(id);
  }, [showMessageIdOfCouponWasCopied]);

  const filterCategoriesHandler = (value) => dropdownValueHandler(value, CATEGORY_ID_FILTER_KEY);

  const filterSubCategoriesHandler = (value) => dropdownValueHandler(value, SUB_CATEGORY_FILTER_KEY);

  const viewsFilterHandler = (value) => dropdownValueHandler(value, VIEWS_FILTER_KEY, [PURCHASES_FILTER_KEY]);

  const purchasesFilterHandler = (value) => dropdownValueHandler(
    value,
    PURCHASES_FILTER_KEY,
    [VIEWS_FILTER_KEY],
  );

  const nameColumn = {
    title: 'Name',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    width: '14%',
    render: (text) => <Typography.Text>{text}</Typography.Text>,
  };

  const numberColumn = {
    title: 'Coupon ID',
    dataIndex: 'id',
    key: 'id',
    width: '7%',
    render: (id) => (
      <TooltipComponent
        title={id}
        placement="bottomLeft"
        overlayClassName="tooltipAdminTableColumns maxWidthTooltip"
      >
        <div className="fieldWithIdAdminTable">
          <Typography.Text>
            {id.slice(0, 5)}
            ...
          </Typography.Text>
          <span
            className="copyIdAdminTablesIcon"
            onClick={() => copiedId(id)}
          >
            <CopyIcon />
          </span>
        </div>
      </TooltipComponent>
    ),
  };

  const createdAtColumn = {
    title: <DoubleDateTableWrapper />,
    dataIndex: 'createdAt',
    key: 'createdAt',
    ellipsis: true,
    width: '10%',
    render: (text) => (
      <Typography.Text>
        {moment(text).format(FORMAT_DATE_TABLE)}
      </Typography.Text>
    ),
  };

  const archivedColumn = {
    title: 'Archived',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    ellipsis: true,
    width: '9%',
    render: (date) => (
      <Typography.Text>
        {date ? moment(date).format(FORMAT_DATE_TABLE) : '-'}
      </Typography.Text>
    ),
  };

  const categoryColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Category</span>
        <DropdownWithClearComponent
          items={filterCategories}
          minWidth="280px"
          callback={filterCategoriesHandler}
        />
      </div>
    ),
    dataIndex: 'category',
    key: 'category',
    ellipsis: true,
    width: '9%',
    render: (category) => (
      <Typography.Text>
        {category.title}
      </Typography.Text>
    ),
  };

  const subCategoryColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Subcategory</span>
        <DropdownWithClearComponent
          items={filterSubCategories}
          minWidth="280px"
          callback={filterSubCategoriesHandler}
        />
      </div>
    ),
    dataIndex: 'subCategory',
    key: 'subCategory',
    ellipsis: true,
    width: '9%',
    render: (subCategory) => (
      <Typography.Text>
        {subCategory?.title || '-'}
      </Typography.Text>
    ),
  };

  const companyColumn = {
    title: 'Company name',
    dataIndex: 'merchant',
    key: 'merchant',
    ellipsis: true,
    width: '12%',
    render: (merchant) => (
      <Typography.Text>
        {merchant.title}
      </Typography.Text>
    ),
  };

  const couponPriceColumn = {
    title: 'Coupon price, AED',
    dataIndex: 'currentPrice',
    key: 'currentPrice',
    ellipsis: true,
    width: '9%',
    render: (currentPrice) => <Typography.Text>{roundingPrice(currentPrice / 100)}</Typography.Text>,
  };

  const viewsColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Views</span>
        <DropdownWithClearComponent
          items={defaultFilters}
          minWidth="130px"
          callback={viewsFilterHandler}
        />
      </div>
    ),
    dataIndex: 'couponViewsCount',
    key: 'couponViewsCount',
    ellipsis: true,
    width: '9%',
    render: (couponViewsCount) => <Typography.Text>{couponViewsCount}</Typography.Text>,
  };

  const purchasesColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Purchases</span>
        <DropdownWithClearComponent
          items={defaultFilters}
          minWidth="130px"
          callback={purchasesFilterHandler}
        />
      </div>
    ),
    dataIndex: 'couponPurchasesCount',
    key: 'couponPurchasesCount',
    ellipsis: true,
    width: '9%',
    render: (couponPurchasesCount) => <Typography.Text>{couponPurchasesCount}</Typography.Text>,
  };

  const actionsColumn = {
    key: 'actions',
    width: '5%',
    render: (text, record) => (
      <DropdownBtnComponent
        text={text}
        record={record}
        actions={[
          {
            key: '0',
            title: 'Edit',
            callback: editCoupon,
          },
          {
            key: '1',
            title: 'Copy',
            callback: createCopyCoupon,
          },
          {
            key: '2',
            title: 'Activate',
            callback: activateCoupon,
          },
          {
            key: '3',
            title: 'Delete',
            callback: deleteCoupon,
          },
        ]}
      />
    ),
  };

  if (role !== MERCHANT_ADMIN && role !== MERCHANT_SUPER_ADMIN) {
    return [
      nameColumn,
      numberColumn,
      createdAtColumn,
      archivedColumn,
      categoryColumn,
      subCategoryColumn,
      companyColumn,
      couponPriceColumn,
      viewsColumn,
      purchasesColumn,
      actionsColumn,
    ];
  }
  return [
    nameColumn,
    numberColumn,
    createdAtColumn,
    archivedColumn,
    categoryColumn,
    subCategoryColumn,
    couponPriceColumn,
    viewsColumn,
    actionsColumn,
  ];
};

export default useGetArchivedColumn;
