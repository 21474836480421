import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { CloseIcon } from '../../../constants/icons';
import useDeleteBanner from '../hooks/gql/useDeleteBanner';
import Portal from '../../Common_components/Portal/Portal';
import InputDelete from './InputDelete';
import './deleteModal-ant.scss';
import s from './deleteModal.module.scss';

const DeleteModalBannerComponent = ({
  isOpenModalBanner, closeModalWindowBannerWhenDelete, banners,
  closeModalWindowBanner,
}) => {
  const [valueDelete, setValueDelete] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const id = searchParams.get('id') || '';
  const page = searchParams.get('page') || '1';
  const { deleteBannerByAdmin } = useDeleteBanner();

  const setValueDeleteHandler = useCallback((value) => {
    setValueDelete(value);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setValueDelete('');
    closeModalWindowBanner();
  }, [closeModalWindowBanner]);

  const deleteBanner = useCallback(async () => {
    try {
      await deleteBannerByAdmin(id);
      const newPage = banners?.length === 1 ? +page - 1 : page;
      const finalPage = newPage === 0 ? 1 : newPage;
      setSearchParams({
        page: finalPage,
      });
      setValueDelete('');
      closeModalWindowBannerWhenDelete();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  }, [banners, closeModalWindowBannerWhenDelete, deleteBannerByAdmin, id, page, setSearchParams]);

  const finallyClassSendBtn = valueDelete === 'DELETE' ? `${s.btn} ${s.send}` : `${s.btn} ${s.disabled}`;

  return (
    <Portal>
      <Modal
        title="Confirm your action"
        centered
        visible={isOpenModalBanner}
        onCancel={closeDeleteModal}
        footer={null}
        width={440}
        className={s.modalComponent}
        closeIcon={<CloseIcon />}
        wrapClassName="modalDeleteBanner"
      >
        <div>
          <p className={s.text}>Please type here “DELETE” to confirm deletion</p>
          <div className={s.input}>
            <InputDelete
              value={valueDelete}
              callback={setValueDeleteHandler}
              deleteBanner={deleteBanner}
              valueDelete={valueDelete}
            />
          </div>
          <div className={s.btnModal}>
            <Button
              className={`${s.btn} ${s.cancel}`}
              onClick={closeDeleteModal}
            >
              Cancel
            </Button>

            <Button
              disabled={valueDelete !== 'DELETE'}
              type="primary"
              className={finallyClassSendBtn}
              onClick={deleteBanner}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </Portal>
  );
};

DeleteModalBannerComponent.propTypes = {
  isOpenModalBanner: PropTypes.bool.isRequired,
  closeModalWindowBannerWhenDelete: PropTypes.func.isRequired,
  closeModalWindowBanner: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  banners: PropTypes.array,
};
DeleteModalBannerComponent.defaultProps = {
  banners: undefined,
};

export default memo(DeleteModalBannerComponent);
