import PropTypes from 'prop-types';
import React, { memo } from 'react';
import OptionItem from './OptionItem/OptionItem';
import s from './MappedOptions.module.scss';

const MappedOptions = ({ options }) => {
  const mappedOptions = options.map(({
    title, oldPrice, currentPrice, discount, id,
  }) => (
    <OptionItem
      key={id}
      id={id}
      title={title}
      discount={discount}
      oldPrice={oldPrice}
      currentPrice={currentPrice}
    />
  ));

  return (
    <div className={s.container}>
      {mappedOptions}
    </div>
  );
};

MappedOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape(
    {
      title: PropTypes.string.isRequired,
      oldPrice: PropTypes.number.isRequired,
      currentPrice: PropTypes.number.isRequired,
      discount: PropTypes.number.isRequired,
    },
  )).isRequired,
};

export default memo(MappedOptions);
