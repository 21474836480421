import React from 'react';
import { Tabs } from 'antd';
import style from './SettingsTabs.module.scss';
import VAT from '../VAT';
import useGetCommonSettings from '../hooks/useGetCommonSettings';

const { TabPane } = Tabs;

const SettingsTabs = () => {
  const { commonSettings } = useGetCommonSettings();

  return (
    <Tabs
      tabPosition="left"
      className={style.tabs}
    >
      <TabPane
        tab="VAT"
        key="vat"
      >
        { commonSettings && <VAT vatValue={commonSettings.vat} /> }
      </TabPane>
    </Tabs>
  );
};

export default SettingsTabs;
