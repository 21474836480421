import React, { memo } from 'react';
import PropTypes from 'prop-types';
import BannerModal from './BannerModal/BannerModal';
import useCreateBannerContainer from '../hooks/useCreateBannerContainer/useCreateBannerContainer';
import Loader from '../../Loader/Loader';
import Portal from '../../Common_components/Portal/Portal';

const BannerModalComponent = ({ isOpenModalBanner, closeModalWindowBanner }) => {
  const {
    bannerData,
    setBannerDataHandler,
    createBannerLoading,
    uploadBannerPictureLoading,
    loadingGetBanner,
    updateBannerLoading,
  } = useCreateBannerContainer();

  if (loadingGetBanner) {
    return <Loader />;
  }

  return (
    <>
      {(createBannerLoading || uploadBannerPictureLoading || updateBannerLoading) && <Loader />}
      <Portal>
        <BannerModal
          bannerData={bannerData}
          setBannerDataHandler={setBannerDataHandler}
          isOpenModalBanner={isOpenModalBanner}
          closeModalWindowBanner={closeModalWindowBanner}
        />
      </Portal>
    </>
  );
};

BannerModalComponent.propTypes = {
  isOpenModalBanner: PropTypes.bool.isRequired,
  closeModalWindowBanner: PropTypes.func.isRequired,
};

export default memo(BannerModalComponent);
