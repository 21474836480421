import {
  useCallback, useMemo, useRef, useState,
} from 'react';
import { uid } from 'uid';
import useGetCommonSettings from '../gql/useGetCommonSettings';
import useCreateCoupon from '../gql/useCreateCoupon';

const useGetCoupon = (couponInputState, couponOptionsState, prevImageState, galleryImagesState, idCoupon = '') => {
  const [couponInput, setCouponInput] = useState(couponInputState);
  const [couponOptions, setCouponOptions] = useState(couponOptionsState);
  const { vat: vatValue } = useGetCommonSettings();

  const couponInputInitState = useRef(couponInput); // delete on click switch
  const initGoldenHoursData = useRef(couponInputState?.couponGoldenHours); // delete on click switch
  const setCouponInputHandler = useCallback((key, value) => {
    if (key === 'merchantId') {
      setCouponInput({
        ...couponInput,
        merchantId: value,
        filialBranches: [],
      });
      setCouponOptions(couponOptions.map((option) => ({
        ...option,
        filialBranches: [],
      })));
    } else if (key === 'categoryId') {
      setCouponInput({
        ...couponInput,
        categoryId: value,
        subCategoryId: null,
      });
    } else if (key === 'countPriceByDiscount') {
      if (couponInput.countPriceByDiscount) {
        setCouponInput((prev) => ({ ...prev, oldPrice: '', countPriceByDiscount: value }));
      } else {
        setCouponInput((prev) => ({ ...prev, discount: '', countPriceByDiscount: value }));
      }
    } else {
      setCouponInput({ ...couponInput, [key]: value });
    }
  }, [couponInput]);

  const couponInputMemo = useMemo(() => ({ couponInput, setCouponInput }), [couponInput]);
  //-----

  // ------
  const couponOptionsMemo = useMemo(() => ({ couponOptions, setCouponOptions }), [couponOptions]);

  // ---create-coupon options---
  const couponOptionsStateInit = useRef(couponOptionsState);
  const couponOptionsToDelete = useRef([]);
  const couponOptionsToUpdate = useRef([]);
  const couponOptionsToCreate = useRef([]);

  const copyCouponOptions = useCallback((id) => {
    const searchCoupon = couponOptions.find((coupon) => coupon.id === id);
    const newId = uid();
    couponOptionsToCreate.current = [
      ...couponOptionsToCreate.current,
      {
        ...searchCoupon,
        id: newId,
      },
    ];

    setCouponOptions((prev) => [...prev, {
      ...searchCoupon,
      id: newId,
    }]);
  }, [couponOptions]);

  const addNewCouponOptions = useCallback(() => {
    const newCoupon = {
      id: uid(),
      title: null,
      createPrice: null,
      oldPrice: null,
      discount: null,
      applyVat: false,
      countPriceByDiscount: false,
      allowToUsePromoCode: false,
      generateSeparateVoucher: false,
      filialBranches: [],
      couponOptionFilialBranchesToAdd: [],
      couponOptionFilialBranchesToRemove: [],
    };
    couponOptionsToCreate.current = [
      ...couponOptionsToCreate.current,
      newCoupon,
    ];

    setCouponOptions((prev) => [...prev, newCoupon]);
  }, []);
  const deleteCouponOptions = useCallback((id) => {
    const findOption = couponOptionsStateInit.current.find((option) => option.id === id);

    couponOptionsToDelete.current = findOption
      ? [...couponOptionsToDelete.current, id]
      : [...couponOptionsToDelete.current];

    couponOptionsToCreate.current = couponOptionsToCreate.current.filter((option) => option.id !== id);

    couponOptionsToUpdate.current = couponOptionsToUpdate.current.filter((option) => option.id !== id);

    setCouponOptions(couponOptions.filter((option) => option.id !== id));
  }, [couponOptions]);

  const setOptionFilialBranchesForAddOptions = useCallback((values, id) => {
    const searchAddOption = couponOptionsStateInit.current.find((coupon) => coupon.id === id);

    const arr = values.filter((item) => !searchAddOption?.filialBranches.find((el) => el === item));
    const removeArr = searchAddOption?.filialBranches.filter((item) => !values.find((el) => el === item));

    if (searchAddOption) {
      couponOptionsToUpdate.current = couponOptionsToUpdate.current.findIndex((option) => option.id === id) === -1
        ? [...couponOptionsToUpdate.current, {
          ...searchAddOption,
          couponOptionFilialBranchesToAdd: arr || [],
          couponOptionFilialBranchesToRemove: removeArr || [],
        }]
        : couponOptionsToUpdate.current.map((option) => (option.id === id
          ? {
            ...option,
            filialBranches: [...values],
            couponOptionFilialBranchesToAdd: arr || [],
            couponOptionFilialBranchesToRemove: removeArr || [],
          } : option));
    } else {
      couponOptionsToCreate.current = couponOptionsToCreate.current.map((option) => (option.id === id
        ? {
          ...option,
          filialBranches: [...values],
          couponOptionFilialBranchesToAdd: arr || [],
          couponOptionFilialBranchesToRemove: removeArr || [],
        }
        : option));
    }

    setCouponOptions(couponOptions.map((option) => (option.id === id
      ? {
        ...option,
        filialBranches: [...values],
        couponOptionFilialBranchesToAdd: arr || [],
        couponOptionFilialBranchesToRemove: removeArr || [],
      }
      : option)));
  }, [couponOptions]);

  const setOptionForAddOptions = useCallback((key, values, id) => {
    const searchAddOption = couponOptionsStateInit.current.find((coupon) => coupon.id === id);

    if (searchAddOption) {
      couponOptionsToUpdate.current = couponOptionsToUpdate.current.findIndex((option) => option.id === id) === -1
        ? [...couponOptionsToUpdate.current, { ...searchAddOption, [key]: values }]

        : couponOptionsToUpdate.current.map((option) => (option.id === id
          ? { ...option, [key]: values } : option));
    } else {
      couponOptionsToCreate.current = couponOptionsToCreate.current.map((option) => (option.id === id
        ? { ...option, [key]: values }
        : option));
    }
    setCouponOptions(couponOptions.map((option) => (option.id === id
      ? { ...option, [key]: values }
      : option)));
  }, [couponOptions]);

  //------
  // ---images---
  const [prevImage, setPrevImage] = useState(prevImageState);

  const initPreviewPicture = useRef(prevImageState);
  const newPicture = useRef(null);
  const idDeletePrevImage = useRef(null);
  const isChangeOrdersInGalleryPictures = useRef(false);

  const setPrevImageHandler = useCallback((file) => {
    idDeletePrevImage.current = prevImage.id;

    if (file && initPreviewPicture.current.id !== file.uid) {
      newPicture.current = file;
    }

    setPrevImage({
      id: '',
      key: file,
    });
  }, [prevImage.id]);

  // gallery images
  const [galleryImages, setGalleryImages] = useState(galleryImagesState);

  const initGalleryImagesState = useRef(galleryImagesState);
  const deleteGalleryImages = useRef([]);
  const uploadNewGalleryImages = useRef([]);

  const setGalleryImagesHandler = useCallback((files) => {
    const searchItems = initGalleryImagesState.current
      .filter((initFile) => files.some((file) => initFile.uid === file.uid));

    if (!searchItems.length) {
      uploadNewGalleryImages.current = [...uploadNewGalleryImages.current, ...files];
    }
    setGalleryImages((prev) => [...prev, ...files]);
  }, []);

  const deleteImageFromGallery = useCallback((file) => {
    const searchItem = initGalleryImagesState.current.find((el) => el.uid === file.uid);
    if (searchItem) {
      deleteGalleryImages.current = [...deleteGalleryImages.current, searchItem];
    }
    setGalleryImages(galleryImages.filter((fileGallery) => fileGallery.uid !== file.uid));
  }, [galleryImages]);

  const changeOrderImagesInGallery = useCallback((files) => {
    isChangeOrdersInGalleryPictures.current = true;
    setGalleryImages(files);
  }, []);

  //-----
  // send data
  const {
    sendData, loadingCreateCoupon, loadingUpdateCoupon, allErrors,
  } = useCreateCoupon();

  const sendDataHandler = useCallback(() => {
    const editPreviewPicture = {
      newPreviewPicture: newPicture.current,
      prevImageForDeleteId: idDeletePrevImage.current,
    };

    const optionsForEdit = {
      couponOptionsToDelete: couponOptionsToDelete.current,
      couponOptionsToUpdate: couponOptionsToUpdate.current,
      couponOptionsToCreate: couponOptionsToCreate.current,
    };

    const editGalleryPicture = {
      deleteGalleryImages: deleteGalleryImages.current,
      uploadNewGalleryImages: uploadNewGalleryImages.current,
    };

    sendData(
      {
        couponInput,
        couponOptions,
        prevImage,
        galleryImages,
        optionsForEdit,
        editPreviewPicture,
        editGalleryPicture,
      },
      vatValue,
      initGoldenHoursData.current,
      couponInputInitState.current,
      isChangeOrdersInGalleryPictures.current,
      idCoupon,
    );
  }, [couponInput, couponOptions, galleryImages, idCoupon, prevImage, sendData, vatValue]);

  return {
    couponInput: couponInputMemo.couponInput,
    setCouponInput: couponInputMemo.setCouponInput,
    setCouponInputHandler,
    couponOptions: couponOptionsMemo.couponOptions,
    copyCouponOptions,
    addNewCouponOptions,
    deleteCouponOptions,
    prevImage,
    setPrevImage: setPrevImageHandler,
    galleryImages,
    setGalleryImages: setGalleryImagesHandler,
    deleteImageFromGallery,
    changeOrderImagesInGallery,
    sendDataHandler,
    setOptionForAddOptions,
    setOptionFilialBranchesForAddOptions,
    loadingCreateCoupon,
    loadingUpdateCoupon,
    allErrors,
  };
};

export default useGetCoupon;
