import React, { memo } from 'react';
import { Button, Form, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useRegisterMutation from '../../../hooks/useRegisterMutation/useRegisterMutation';
import s from './MobileSignUp.module.scss';
import GoogleButton from '../../Common_components/GoogleButton';

const MobileSignUp = () => {
  const [form] = Form.useForm();

  const {
    handleRegisterData, sendRegisterData, registerError, registerLoading,
  } = useRegisterMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const sendRegister = async (data) => {
    try {
      await sendRegisterData(data);
      const from = location.state?.from;

      if (from) {
        navigate(from);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={s.main}>
      <span className={s.title}>
        Sign Up
      </span>
      <Form
        form={form}
        onFinish={sendRegister}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="Email"
          validateStatus={registerError && 'error'}
          help={registerError}
          rules={[{ required: true, message: 'Email is required' }]}
        >
          <Input
            name="email"
            onChange={handleRegisterData}
            className={s.input}
          />
        </Form.Item>
        <Form.Item>
          <Button
            className={s.submitButton}
            type="primary"
            htmlType="submit"
            disabled={registerLoading}
          >
            Sign Up
          </Button>
        </Form.Item>
      </Form>
      <GoogleButton form={form} />
      <div className={s.terms}>
        <span>
          By creating an account, you are agreeing to our
        </span>
        <span className={s.rules}>
          Terms of Service and Privacy Policy
        </span>
      </div>
      <span className={s.or}>
        Or
      </span>
      <Link
        to="/auth/sign-in"
        className={s.signIn}
      >
        Sign In
      </Link>
    </div>
  );
};

export default memo(MobileSignUp);
