import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import './EditInput.scss';

const { Item } = Form;
const EditInput = ({
  error, callback, value, keyField, label, inputVariant, disabledInput, itemClass, placeholderValue, isAutoFocus,
}) => {
  const onChangeHandler = (value) => {
    callback(keyField, value);
  };

  const errorText = () => (error ? 'errorTextAccount' : '');

  return (
    <Item
      label={label}
      className={itemClass}
    >
      {
        inputVariant === 'password'
          ? (
            <Input.Password
              className={error ? 'inputEdit errorInput' : 'inputEdit'}
              value={value}
              onChange={(e) => onChangeHandler(e.target.value)}
              disabled={disabledInput}
              placeholder={placeholderValue}
              autoFocus={isAutoFocus}
            />
          )
          : (
            <Input
              className={error ? 'inputEdit errorInput' : 'inputEdit'}
              value={value}
              onChange={(e) => onChangeHandler(e.target.value)}
              disabled={disabledInput}
              maxLength={100}
              placeholder={placeholderValue}
              autoFocus={isAutoFocus}
            />
          )
      }
      {error && <div className={errorText()}>{error}</div>}
    </Item>
  );
};
EditInput.propTypes = {
  value: PropTypes.string.isRequired,
  keyField: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  error: PropTypes.string,
  inputVariant: PropTypes.string,
  disabledInput: PropTypes.bool,
  itemClass: PropTypes.string,
  placeholderValue: PropTypes.string,
  isAutoFocus: PropTypes.bool,
};

EditInput.defaultProps = {
  error: undefined,
  inputVariant: 'input',
  disabledInput: false,
  isAutoFocus: false,
  itemClass: '',
  placeholderValue: '',
};

export default memo(EditInput);
