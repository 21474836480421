import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { FIND_ALL_EMPLOYEES, UPDATE_EMPLOYEE_BY_ID } from '../../../../api/employees/query';

const useUpdateEmployById = () => {
  const [updateEmployeeById,
    { loading: loadingUpdateEmployByIdAdmin, error: errorUpdateEmploy }] = useMutation(UPDATE_EMPLOYEE_BY_ID, {
    refetchQueries: [FIND_ALL_EMPLOYEES],
  });

  const updateEmployByAdminHandler = useCallback(async (id, data) => {
    try {
      await updateEmployeeById({
        variables: {
          updateEmployeeInput: data,
          id,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [updateEmployeeById]);

  return {
    updateEmployByAdminHandler,
    loadingUpdateEmployByIdAdmin,
    errorUpdateEmploy,
  };
};
export default useUpdateEmployById;
