import { useLocation } from 'react-router-dom';
import { useContext, useLayoutEffect } from 'react';
import UserContext from '../../Layout/context/userContext';

const ScrollToTopWrapper = ({ children }) => {
  const location = useLocation();
  const { setIsSignUpActive } = useContext(UserContext);
  useLayoutEffect(() => {
    setIsSignUpActive(false);
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

export default ScrollToTopWrapper;
