import React, { memo } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import './inputNumber.scss';

const NumericInput = ({
  keyValue, value, callbackHandler, disabled, style, keyOperation,
}) => {
  const onChange = (e) => {
    const { value } = e.target;
    const regNumber = /^[0-9]+$/;
    const regPrice = /^([0-9]{0,20}((.)[0-9]{0,2}))$/;
    const regMobPhone = /^(\+?\d{1,4}[\s-])?(?!0+\s+,?$)\d{10}\s*,?$/g;

    switch (keyOperation) {
    case 'price': {
      // eslint-disable-next-line no-restricted-globals
      if ((!isNaN(value) && regPrice.test(value)) || value === '') {
        if (+value < 2147483647) {
          callbackHandler(keyValue, value);
        } else {
          callbackHandler(keyValue, '2147483647');
        }
      }
      return;
    }
    case 'discount': {
      // eslint-disable-next-line no-restricted-globals
      if ((!isNaN(value) && regNumber.test(value)) || value === '') {
        if (+e.target.value <= 100) {
          callbackHandler(keyValue, value);
          return;
        }
        callbackHandler(keyValue, '100');
        return;
      }
      return;
    }
    case 'mobPhone': {
      // eslint-disable-next-line no-restricted-globals
      if ((regMobPhone.test(value)) || value === '') {
        callbackHandler(keyValue, value);
      }
      return;
    }
    default:
      // eslint-disable-next-line no-restricted-globals
      if ((!isNaN(value) && regNumber.test(value)) || value === '') {
        callbackHandler(keyValue, value);
      }
    }
  };

  return (
    <Input
      className="numericInput"
      style={{ ...style }}
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  );
};

NumericInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  keyValue: PropTypes.string.isRequired,
  value: PropTypes.string,
  callbackHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  keyOperation: PropTypes.string,
};
NumericInput.defaultProps = {
  style: {},
  disabled: false,
  keyOperation: 'number',
  value: '',
};

export default memo(NumericInput);
