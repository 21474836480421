import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { limit } from '../../../../constants/tableConstants';
import { FIND_ALL_EMPLOYEES } from '../../../../api/employees/query';
import { getRoleUser } from '../../../../helpers/user-storage';
import { AuthAdminContext } from '../../../../context/context-admin/hoc/AuthAdminContext';
import useLastPageChange from '../../../../hooks/useLastPageChange/useLastPageChange';

const useFindAllEmployees = () => {
  const { userInfo } = useContext(AuthAdminContext);

  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';

  const { loading, error, data } = useQuery(FIND_ALL_EMPLOYEES, {
    variables: {
      offset: (Number(page) - 1) * limit,
      limit,
      role: getRoleUser(),
    },
  });

  useLastPageChange(data);

  if (data) {
    const { employees, count } = data.findAllEmployees;
    const {
      firstName, lastName, role, id, email, userPicture,
    } = userInfo || {};
    const employeesWithUser = [{
      firstName, lastName, role, id, email, userPicture, status: 'USER',
    }, ...employees];
    return {
      loading,
      error,
      employees: employeesWithUser,
      count,
    };
  }

  return {
    loading,
    error,
  };
};

export default useFindAllEmployees;
