import React, { useState } from 'react';
import {
  Button, Checkbox, Form, Input,
} from 'antd';
import '../SigInAnt.scss';
import { Link } from 'react-router-dom';
import banan from '../../../assets/images/banan.png';
import closeBanan from '../../../assets/images/closeBanan.png';
import pathCloseBanan from '../../../assets/images/pathCloseBanan.png';
import cryBanan from '../../../assets/images/cryBanan.png';
import useLoginUser from '../hooks/useLoginUser';
import { CloseEye, OpenEye } from '../../../constants/icons';
import s from '../SignIn.module.scss';

const SignIn = () => {
  const [form] = Form.useForm();

  const {
    handleChange, sendData, error, loading, errorMessage,
  } = useLoginUser();

  const [showPassword, setShowPassword] = useState(false);
  const [focusPassword, setFocusPassword] = useState(false);

  const triggerShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  const finalyBananImage = () => {
    if (error || errorMessage) return cryBanan;
    if (focusPassword) {
      if (showPassword) {
        return pathCloseBanan;
      }
      return closeBanan;
    }
    if (showPassword) {
      if (!focusPassword) {
        return banan;
      }
      return pathCloseBanan;
    }
    return banan;
  };

  return (
    <div className={s.card}>
      <div className={s.logo}>
        <img
          src={finalyBananImage()}
          alt="logo"
        />
      </div>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={sendData}
        className={s.form}
      >
        <Form.Item
          name="username"
          label="Email or username"
          className={s.formItem}
        >
          <div className={s.userName}>
            <Input
              autoFocus
              style={{
                fontWeight: '400',
                width: 300,
                borderRadius: '4px',
                height: '40px',
              }}
              name="email"
              size="large"
              onChange={handleChange}
              disabled={loading}
              className={error || errorMessage ? s.inputError : ''}
            />
            {error && <div className={s.error}>{error.message}</div>}
            {errorMessage && <div className={s.error}>{errorMessage}</div>}
          </div>
        </Form.Item>
        <Form.Item
          name="password"
          className={s.formItem}
          label={(
            <div className={s.labelPassword}>
              <span>Password</span>
              <Link
                to="/admin/signIn/forgot-password"
                className={s.link}
              >
                Forgot the password?
              </Link>
            </div>
          )}

        >
          <div className={s.password}>
            <Input
              style={{
                fontWeight: '400',
                width: 300,
                borderRadius: '4px',
                height: '40px',
              }}
              name="password"
              size="large"
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              disabled={loading}
              className={s.formItem}
              onFocus={() => setFocusPassword(true)}
              onBlur={() => setFocusPassword(false)}
            />
            {showPassword
              ? (
                <div
                  onClick={triggerShowPassword}
                  className={s.eye}
                >
                  <OpenEye />
                </div>
              )
              : (
                <div
                  onClick={triggerShowPassword}
                  className={s.eye}
                >
                  <CloseEye />
                </div>
              )}
          </div>
        </Form.Item>
        <Form.Item
          name="remember"
          valuePropName="checked"
          initialValue={false}
          className={s.formItem}
        >
          <Checkbox
            className={s.checkbox}
          >
            Remember me
          </Checkbox>
        </Form.Item>
        <Form.Item
          className={s.button}
          wrapperCol={{ span: 24 }}
          shouldUpdate
        >
          <Button
            htmlType="submit"
            className={!form.getFieldsValue().username || !form.getFieldsValue().password
              ? s.btn
              : `${s.btn} ${s.btnGrey}`}
            disabled={!form.getFieldsValue().username || !form.getFieldsValue().password || loading}
          >
            Sign In
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignIn;
