import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import Icon from '../../Catalog/Icon';
import { ArrowDown } from '../../../constants/icons';
import s from './MobileCategoryItem.module.scss';
import MobileSubcategories from '../MobileSubcategories';

const MobileCategoryItem = ({
  iconKey, title, id, couponsCount, subCategories, isOpened, setOpened,
}) => {
  const [, setSearchParams] = useSearchParams();

  const arrowClassName = `${s.arrow} ${isOpened && s.rotate}`;

  const navigateToCategory = (e) => {
    e.stopPropagation();
    if (couponsCount > 0) {
      setSearchParams({ categoryId: id });
    }
  };

  const toggleCategoryCallback = () => {
    if (subCategories.length > 0 && couponsCount > 0) {
      setOpened((prev) => {
        const isCategoryOpened = prev.findIndex((openedId) => id === openedId);
        return isCategoryOpened > -1
          ? prev.filter((openedId) => openedId !== id)
          : [...prev, id];
      });
    }
  };

  return (
    <div
      className={s.item}
      onClick={toggleCategoryCallback}
    >
      <div className={s.wrapper}>
        <div
          onClick={navigateToCategory}
          className={s.content}
        >
          <Icon
            url={iconKey}
            name={`${title} category`}
          />
          <span className={s.title}>
            {title}
          </span>
        </div>
        {subCategories.length > 0 && couponsCount > 0 && (
          <div className={arrowClassName}>
            <ArrowDown />
          </div>
        )}
      </div>
      <MobileSubcategories
        subCategories={subCategories}
        areOpened={isOpened}
        parentCategoryTitle={title}
      />
    </div>
  );
};

MobileCategoryItem.propTypes = {
  iconKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  couponsCount: PropTypes.number.isRequired,
  isOpened: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  subCategories: PropTypes.array.isRequired,
  setOpened: PropTypes.func.isRequired,
};

export default memo(MobileCategoryItem);
