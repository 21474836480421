import React, { useCallback } from 'react';
import { Tag, Typography } from 'antd';
import moment from 'moment/moment';
import { DownLoadIcon } from '../../../../constants/icons';
import {
  finalBackgroundColorTagStatus,
  finalColorTagTextStatus,
  finalTextTagStatus,
} from '../../../../constants/general/tag-status';
import useVoucherDownload from '../../../Profile/hooks/gql/useVoucherDownload';
import { getRoleUser } from '../../../../helpers/user-storage';
import { SUPER_ADMIN } from '../../../../constants/admin-roles';
import { ROLE_EMPLOY_MERCHANT, ROLE_EMPLOY_MERCHANT_SUPER } from '../../../../constants/employ';
import SuperAdminTagColumn from './SuperAdminTagColumn';
import DropdownWithClearComponent
  from '../../../Common_components/DropdownWithClearComponent';
import {
  CREATED_AT_KEY,
  defaultFilters,
  STATUS_FILTER_KEY,
  voucherStatusFilters,
} from '../../../../constants/general/filters';
import useSearchFilters from '../../../Coupon/CouponTable/hooks/filters/useSearchFilters';

const useGetVouchersColumn = (activateCallback) => {
  const { dropdownValueHandler } = useSearchFilters();

  const role = getRoleUser();

  const filterHandlerOneValue = (value) => dropdownValueHandler(
    value,
    STATUS_FILTER_KEY,
    [CREATED_AT_KEY],
  );

  const paymentDateHandlerOneValue = (value) => dropdownValueHandler(
    value,
    CREATED_AT_KEY,
    [STATUS_FILTER_KEY],
  );

  const { downloadAllVouchers, downloadVoucherInFile } = useVoucherDownload();

  const downloadVoucherCallback = useCallback(async (id) => {
    const file = await downloadAllVouchers(id);
    await downloadVoucherInFile([file]);
  }, []);

  const payDateColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Payment</span>
        <DropdownWithClearComponent
          items={defaultFilters}
          minWidth="150px"
          callback={paymentDateHandlerOneValue}
        />
      </div>
    ),
    dataIndex: 'payDate',
    key: 'payDate',
    ellipsis: true,
    width: '10%',
    render: (date) => (
      <Typography.Text>
        {
          date ? moment(date).format('DD MMMM, YYYY') : ''
        }
      </Typography.Text>
    ),
  };

  const payTimeColumn = {
    title: 'Time',
    dataIndex: 'timePay',
    ellipsis: true,
    key: 'timePay',
    width: '5%',
    render: (time) => (
      <Typography.Text>
        {time}
      </Typography.Text>
    ),
  };

  const voucherNumberColumn = {
    title: 'Order ID',
    dataIndex: 'order',
    ellipsis: true,
    key: 'order',
    width: '7%',
    render: (order) => (
      <Typography.Text>
        {order?.orderNumber || '-'}
      </Typography.Text>
    ),
  };

  const couponTitleColumn = {
    title: 'Coupon/Option',
    dataIndex: 'title',
    ellipsis: true,
    key: 'title',
    width: getRoleUser() === ROLE_EMPLOY_MERCHANT || getRoleUser() === ROLE_EMPLOY_MERCHANT_SUPER ? '20%' : '15%',
    render: (title) => (
      <Typography.Text>
        {title}
      </Typography.Text>
    ),
  };

  const orderIdColumn = {
    title: 'Voucher ID',
    dataIndex: 'voucherNumber',
    key: 'voucherNumber',
    ellipsis: true,
    width: '7%',
    render: (voucherNumber) => (
      <Typography.Text>
        {voucherNumber}
      </Typography.Text>
    ),
  };

  const totalPriceColumn = {
    title: 'Total, AED',
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    ellipsis: true,
    width: '7%',
    render: (totalPrice) => (
      <Typography.Text>
        {totalPrice}
      </Typography.Text>
    ),
  };

  const statusColumn = {
    title: role !== ROLE_EMPLOY_MERCHANT ? () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Status</span>
        <DropdownWithClearComponent
          items={voucherStatusFilters}
          minWidth="150px"
          callback={filterHandlerOneValue}
        />
      </div>
    ) : 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '9%',
    render: (status, record) => (getRoleUser() === SUPER_ADMIN
      ? (
        <SuperAdminTagColumn
          status={status}
          record={record}
          activateCallback={activateCallback}
        />
      )
      : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '6px',
            cursor: getRoleUser() === SUPER_ADMIN ? 'pointer' : 'default',
          }}
        >
          <Tag
            color={finalBackgroundColorTagStatus(status)}
            style={{
              color: finalColorTagTextStatus(status),
            }}
          >
            {finalTextTagStatus(status)}
          </Tag>
        </div>
      )),
  };

  const activateDateColumn = {
    title: 'Activation',
    dataIndex: 'activateDate',
    key: 'activateDate',
    ellipsis: true,
    width: '9%',
    render: (date) => (
      <Typography.Text>
        {
          date ? moment(date).format('DD MMMM, YYYY') : ''
        }
      </Typography.Text>
    ),
  };

  const activateTimeColumn = {
    title: 'Time',
    dataIndex: 'timeActivate',
    ellipsis: true,
    key: 'timeActivate',
    width: '6%',
    render: (time) => (
      <Typography.Text>
        {time}
      </Typography.Text>
    ),
  };

  const activateMethodColumn = {
    title: 'Activated',
    dataIndex: 'activateMethod',
    key: 'activateMethod',
    ellipsis: true,
    width: '5%',
    render: (activateMethod) => (
      <Typography.Text>
        {activateMethod || ''}
      </Typography.Text>
    ),
  };

  const downloadColumn = {
    dataIndex: 'isDownloadIcon',
    key: 'isDownloadIcon',
    width: '3%',
    render: (isDownloadIcon, record) => (
      <div
        style={{
          display: isDownloadIcon ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => downloadVoucherCallback(record.id)}
      >
        <DownLoadIcon />
      </div>
    ),
  };

  const companyNameColumn = {
    title: 'Company name',
    dataIndex: 'company',
    ellipsis: true,
    key: 'company',
    width: '11%',
    render: (company) => (
      <Typography.Text>
        {company}
      </Typography.Text>
    ),
  };

  const userIdColumn = {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
    ellipsis: true,
    width: '6%',
    render: (userId) => (
      <Typography.Text>
        {userId}
      </Typography.Text>
    ),
  };

  if (getRoleUser() === ROLE_EMPLOY_MERCHANT || getRoleUser() === ROLE_EMPLOY_MERCHANT_SUPER) {
    return [
      payDateColumn,
      payTimeColumn,
      voucherNumberColumn,
      couponTitleColumn,
      orderIdColumn,
      totalPriceColumn,
      statusColumn,
      activateDateColumn,
      activateTimeColumn,
      activateMethodColumn,
      downloadColumn,
    ];
  }

  return [
    payDateColumn,
    payTimeColumn,
    voucherNumberColumn,
    couponTitleColumn,
    companyNameColumn,
    userIdColumn,
    orderIdColumn,
    totalPriceColumn,
    statusColumn,
    activateDateColumn,
    activateTimeColumn,
    activateMethodColumn,
    downloadColumn,
  ];
};

export default useGetVouchersColumn;
