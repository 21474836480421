import PropTypes from 'prop-types';
import React from 'react';
import s from './LinksBlock.module.scss';
import FooterLink from './FooterLink';
import mobileCondition from '../../../constants/mobileCondition';

const LinksBlock = ({ title, items }) => {
  const containerClassName = `${s.container} ${mobileCondition && s.mobile}`;

  const titleClassName = `${s.title} ${mobileCondition && s.mobile}`;

  const mappedItems = items.map(({ title, route }) => (
    <FooterLink
      key={title}
      subtitle={title}
      route={route}
    />
  ));

  return (
    <div className={containerClassName}>
      <span className={titleClassName}>{title}</span>
      <div>
        {mappedItems}
      </div>
    </div>
  );
};

LinksBlock.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  })).isRequired,
};

export default LinksBlock;
