import {
  LOGS_CREATE_ENTITY, LOGS_DELETE_ENTITY, LOGS_LOGIN_ENTITY, LOGS_UPDATE_ENTITY,
} from '../logs/entities';

export const DATE_ALL_TIME_KEY = 'ALL_TIME';
export const DATE_LAST_MONTH_KEY = 'LAST_MONTH';
export const DATE_LAST_WEEK_KEY = 'LAST_WEEK';
export const DATE_LAST_DAY_KEY = 'LAST_24_HRS';
export const DATE_CUSTOM_KEY = 'CUSTOM';

export const dataFilters = [
  {
    id: '1',
    title: 'All time',
    value: DATE_ALL_TIME_KEY,
  },
  {
    id: '2',
    title: 'Last month',
    value: DATE_LAST_MONTH_KEY,
  },
  {
    id: '3',
    title: 'Last week',
    value: DATE_LAST_WEEK_KEY,
  },
  {
    id: '4',
    title: 'Last 24 hrs',
    value: DATE_LAST_DAY_KEY,
  },
  {
    id: '5',
    title: 'Custom',
    value: DATE_CUSTOM_KEY,
  },
];

export const stateFilters = [
  {
    id: '1',
    title: 'Displayed',
    value: true,
  },
  {
    id: '2',
    title: 'Hidden',
    value: false,
  },
];

export const categoriesFilters = [
  { id: '1', title: 'Hidden', value: false },
  { id: '2', title: 'Visible', value: true },
];

export const PURCHASES_FILTER_KEY = 'couponPurchasesCount';
export const VIEWS_FILTER_KEY = 'couponViewsCount';
export const UPDATED_AT_KEY = 'updatedAt';
export const CREATED_AT_KEY = 'createdAt';
export const IS_PUBLISHED_FILTER_KEY = 'isPublished';
export const CATEGORY_ID_FILTER_KEY = 'categoryId';
export const SUB_CATEGORY_FILTER_KEY = 'subCategoryId';
export const SORT_ORDER_FILTER_KEY = 'sortOrder';
export const BUYERS_PURCHASES_FILTER_KEY = 'purchases';
export const SEARCH_FILTER_KEY = 'search';
export const STATUS_FILTER_KEY = 'status';
export const LOGS_ACTION_FILTER_KEY = 'action';
export const PROMO_IS_ACTIVE_KEY = 'isActive';
export const DATE_RANGE_START_KEY = 'dateRangeStart';
export const DATE_RANGE_END_KEY = 'dateRangeEnd';

export const DATE_KEYS_FILTER_ARRAY = [
  DATE_RANGE_START_KEY,
  DATE_RANGE_END_KEY,
];

export const FROM_LOWER_KEY = 'ASC';
export const FROM_HIGHER_KEY = 'DESC';

export const defaultFilters = [
  { id: '1', title: 'From lower', value: FROM_LOWER_KEY },
  { id: '2', title: 'From higher', value: FROM_HIGHER_KEY },
];

export const voucherStatusFilters = [
  { id: '1', title: 'Active', value: 'ACTIVE' },
  { id: '2', title: 'Not active', value: 'NOT_USED' },
  { id: '3', title: 'Deactivated', value: 'DEACTIVATED' },
  { id: '4', title: 'Refund requested', value: 'REFUND_REQUEST' },
];

export const logsActionEntityFilters = [
  { id: '1', title: 'Login', value: LOGS_LOGIN_ENTITY },
  { id: '2', title: 'Create', value: LOGS_CREATE_ENTITY },
  { id: '3', title: 'Delete', value: LOGS_DELETE_ENTITY },
  { id: '4', title: 'Update', value: LOGS_UPDATE_ENTITY },
];

export const statusFilters = [
  { id: '1', title: 'Activated', value: 'ACTIVE' },
  { id: '2', title: 'Not activated', value: 'INACTIVE' },
];

export const promoCodeFilters = [
  { id: '1', title: 'Active', value: true },
  { id: '2', title: 'Not active', value: false },
];

export const sortFilters = [
  {
    id: '1',
    title: 'A - Z',
    value: 'A-Z',
  },
  {
    id: '2',
    title: 'Z - A',
    value: 'Z-A',
  },
];

export const purchasesFilters = [
  {
    id: '1',
    title: '> 1 (from lower)',
    value: { sortOrder: FROM_LOWER_KEY, purchases: 1 },
  },
  {
    id: '2',
    title: '> 10 (from lower)',
    value: { sortOrder: FROM_LOWER_KEY, purchases: 10 },
  },
  {
    id: '3',
    title: '> 1 (from higher)',
    value: { sortOrder: FROM_HIGHER_KEY, purchases: 1 },
  },
  {
    id: '4',
    title: '> 10 (from higher)',
    value: { sortOrder: FROM_HIGHER_KEY, purchases: 10 },
  },
];

export const filterDataForCategoryName = (coupons) => coupons?.map((coupon) => {
  const { id, category } = coupon;
  const { title } = category;
  return {
    id,
    title,
    isChecked: false,
  };
});

export const filterDataForSubcategoryName = (coupons) => coupons?.map((coupon) => {
  const { id, subCategory } = coupon;
  const { title } = subCategory || {};
  return {
    id,
    title,
    isChecked: false,
  };
}).filter((coupon) => coupon.title);

export const filterDataCompanyName = (coupons) => coupons?.map((coupon) => {
  const { id, merchant } = coupon;
  const { title } = merchant || {};
  return {
    id,
    title,
    isChecked: false,
  };
});
