import React from 'react';
import './SigInAnt.scss';
import { Outlet } from 'react-router-dom';
import useLoginUser from './hooks/useLoginUser';
import Loader from '../Loader';
import s from './SignIn.module.scss';

const SignIn = () => {
  const {
    loading,
  } = useLoginUser();

  const loadingJSX = loading && (<Loader />);
  const sideClassName = `${s.container} ${(window.outerWidth <= 1460 && window.outerWidth > 1191) && s.containerZoomed} 
  containerAnt`;

  return (
    <div className={sideClassName}>
      {loadingJSX}
      <Outlet />
    </div>
  );
};

export default SignIn;
