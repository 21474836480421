const colors = {
  green: '#0BAE2F',
  red: '#D00000',
  yellow: '#F9C718',
  gray: '#A8A8A8',
};

export const STATUSES_NAMES = {
  visible: 'Visible',
  hidden: 'Hidden',
  decline: 'Decline',
  active: 'Active',
  deactivated: 'Deactivated',
};

export const STATUSES = [
  {
    name: STATUSES_NAMES.visible,
    color: colors.green,
  },
  {
    name: STATUSES_NAMES.hidden,
    color: colors.gray,
  },
  {
    name: STATUSES_NAMES.decline,
    color: colors.red,
  },
  {
    name: STATUSES_NAMES.active,
    color: colors.green,
  },
  {
    name: STATUSES_NAMES.deactivated,
    color: colors.gray,
  },
];

export const STATUS_BG_OPACITY_HEX = 20;
