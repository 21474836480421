import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { GET_USER_SELF } from '../../api/query';
import { isUserStored } from '../../helpers/user-storage';

const useFetchUserQuery = () => {
  const [userData, setUserData] = useState(null);
  const [fetchUserCallback, { loading: fetchUserLoading }] = useLazyQuery(GET_USER_SELF);

  const fetchUser = useCallback(() => {
    fetchUserCallback()
      .then((res) => {
        if (res) {
          if (res.data?.getUserSelf) {
            setUserData(res.data.getUserSelf);
          }
        }
      });
  }, [fetchUserCallback]);

  useEffect(() => {
    if (isUserStored()) {
      fetchUser();
    }
  }, [fetchUser]);

  return {
    fetchUser, userData, fetchUserLoading, setUserData,
  };
};

export default useFetchUserQuery;
