import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import s from './Modal.module.scss';
import mobileCondition from '../../constants/mobileCondition';

const Modal = ({
  isActive, hideModalHandler, children, extraModalClass,
}) => {
  const [target, setTarget] = useState(null);

  const contentClassNameWithExtra = `${s.content} ${extraModalClass}`;

  const contentClassName = `${contentClassNameWithExtra} ${mobileCondition && s.mobile}`;

  const mobileContentClassName = `${contentClassName} ${(mobileCondition && isActive) && s.active}`;

  const mobileModalClassName = `${s.modal} ${mobileCondition && s.mobile}`;

  const modalClassName = `${mobileModalClassName} ${isActive && s.active}`;
  return (
    <div
      className={modalClassName}
      onClick={(e) => {
        if (e.target === target) hideModalHandler();
        setTarget(null);
      }}
      onMouseDown={(e) => setTarget(e.target)}
    >
      <div
        className={mobileContentClassName}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  hideModalHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  extraModalClass: PropTypes.string,
};

Modal.defaultProps = {
  extraModalClass: undefined,
};

export default memo(Modal);
