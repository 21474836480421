import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { getLocalCartData, setLocalCartCount } from '../../helpers/cart-helpers/local-storage';
import { GET_COUPONS_IN_CART_COUNT } from '../../api/cart-api/query';
import { isUserStored } from '../../helpers/user-storage';

const useCouponsCount = () => {
  const isAuth = isUserStored();

  const localCount = getLocalCartData()?.reduce((acc, el) => acc + el.quantity, 0) || 0;
  const [localCouponsCount, setLocalCouponsCount] = useState(localCount);

  const { data: countData } = useQuery(GET_COUPONS_IN_CART_COUNT, { skip: !isAuth });

  useEffect(() => {
    setLocalCartCount(localCouponsCount);
  }, [localCouponsCount]);

  const updateLocalCouponsCount = (diff) => setLocalCouponsCount(diff);

  return { countData, localCouponsCount, updateLocalCouponsCount };
};

export default useCouponsCount;
