import React, { memo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import s from './PreviewGallery.module.scss';
import { ArrowLeft, ArrowRight } from '../../../../constants/icons';

const PreviewGallery = ({ images, showButton, keywordsImages }) => {
  const [imageIndex, setImageIndex] = useState(0);

  const setPreviousImageOnClick = useCallback((e) => {
    e.stopPropagation();
    setImageIndex(imageIndex === 0 ? images.length - 1 : imageIndex - 1);
  }, [imageIndex, images.length]);

  const buttonClass = (className) => `${className} ${!showButton && s.hidden}`;

  const setNextImageOnClick = useCallback((e) => {
    e.stopPropagation();
    setImageIndex(imageIndex === images.length - 1 ? 0 : imageIndex + 1);
  }, [imageIndex, images.length]);

  return (
    <div className={s.main}>
      {images.length > 1 && (
        <div
          onClick={setPreviousImageOnClick}
          className={buttonClass(s.prevButton)}
        >
          <ArrowLeft />
        </div>
      )}
      <img
        src={images[imageIndex]}
        alt={keywordsImages}
        className={s.image}
      />
      {images.length > 1 && (
        <div
          onClick={setNextImageOnClick}
          className={buttonClass(s.nextButton)}
        >
          <ArrowRight />
        </div>
      )}
    </div>
  );
};

PreviewGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  showButton: PropTypes.bool.isRequired,
  keywordsImages: PropTypes.bool.isRequired,
};

export default memo(PreviewGallery);
