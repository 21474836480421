import React, { memo } from 'react';
import HeaderComponent from './Header/HeaderComponent';
import './Header/table.scss';
import TableLogs from './TableLogs';

const LogsTable = () => (
  <>
    <HeaderComponent />
    <section
      style={{
        height: '100%',
      }}
    >
      <TableLogs />
    </section>
  </>
);

export default memo(LogsTable);
