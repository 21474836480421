import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { GET_ALL_BANNERS_ADMIN, UPDATE_BANNER_ADMIN } from '../../../../api/api-banners/query';

const useUpdateBannerAdmin = () => {
  const [updateBannerByIdAdmin,
    { loading: loadingUpdateBannerByIdAdmin }] = useMutation(UPDATE_BANNER_ADMIN, {
    refetchQueries: [GET_ALL_BANNERS_ADMIN],
  });

  const updateBannerPublishByAdmin = useCallback(async (id, updateBannerInput) => {
    try {
      await updateBannerByIdAdmin({
        variables: {
          updateBannerInput,
          id,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [updateBannerByIdAdmin]);

  return {
    updateBannerPublishByAdmin,
    loadingUpdateBannerByIdAdmin,
  };
};
export default useUpdateBannerAdmin;
