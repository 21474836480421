import React, { memo } from 'react';
import {
  Button, Checkbox, Form, Input,
} from 'antd';
import s from './signin.module.scss';
import useSignUser from '../hooks/gql/useSignInMutation';
import Loader from '../../Loader/Loader';

const CheckoutSingIn = () => {
  const [form] = Form.useForm();
  const {
    loginError, loginLoading, sendLoginData, fetchFromLocalLoading,
  } = useSignUser();

  const sendLoginDataHandler = (values) => {
    sendLoginData(values);
  };

  return (
    <>
      {(loginLoading || fetchFromLocalLoading) && <Loader />}
      <div className={s.signUp}>
        <div className={s.singInContainer}>
          <span className={s.title}>
            Join with email address
          </span>
        </div>
        <Form
          form={form}
          className={s.form}
          layout="vertical"
          onFinish={sendLoginDataHandler}
        >
          <Form.Item
            name="email"
            label="Email or username"
            className={s.item}
            validateStatus={loginError && 'error'}
            help={loginError}
            rules={[{ required: true, message: 'Please, enter email or username' }]}
          >
            <Input
              name="email"
              className={s.input}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            className={s.item}
            rules={[{ required: true, message: 'Please, enter your password' }]}
          >
            <div className={s.passwordContainer}>
              <span className={s.forgot}>
                Forgot password?
              </span>
              <Input.Password
                type="password"
                name="password"
                className={s.input}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox
              name="remember"
              className={s.checkbox}
            >
              <span className={s.checkbox}>
                Remember me
              </span>
            </Checkbox>
          </Form.Item>
          <Button
            className={s.submitButton}
            type="primary"
            htmlType="submit"
            disabled={loginLoading}
          >
            Sign In
          </Button>
        </Form>
      </div>
    </>
  );
};

export default memo(CheckoutSingIn);
