import React, { memo } from 'react';
import PropTypes from 'prop-types';
import useGetPromoCodeInfo from './hooks/useGetPromoCodeInfo';
import useSendPromoCode from './hooks/gql/useSendPromoCode';
import Loader from '../../Loader/Loader';
import ModalPromocodeComponent from './ModalPromocode/ModalPromocodeComponent';
import Portal from '../../Common_components/Portal';

const PromoCodeModalContainer = (props) => {
  const { isOpenModal, closeAddModal } = props;

  const {
    coupons,
    optionsData,
    loadingGetOptions,
    loadingGetCoupons,
    loadingGetCouponById,
    setPromoCodeInfoHandler,
    promoCodeData,
  } = useGetPromoCodeInfo();

  const {
    createPromoCodeData,
    createPromoCodeLoading,
    createPromoCodeAdminHandler,
    updatePromoCodeData,
    updatePromoCodeLoading,
  } = useSendPromoCode();

  if (loadingGetCouponById) {
    return <Loader />;
  }

  return (
    <>
      {(createPromoCodeLoading || updatePromoCodeLoading) && <Loader />}
      <Portal>
        <ModalPromocodeComponent
          isOpenModal={isOpenModal}
          closeAddModal={closeAddModal}
          promoCodeData={promoCodeData}
          setPromoCodeInfoHandler={setPromoCodeInfoHandler}
          coupons={coupons}
          optionsData={optionsData}
          loadingGetOptions={loadingGetOptions}
          loadingGetCoupons={loadingGetCoupons}
          promoCodeOperationOk={createPromoCodeData || updatePromoCodeData}
          createPromoCodeAdminHandler={createPromoCodeAdminHandler}
          loadingGetCouponById={loadingGetCouponById}
        />
      </Portal>
    </>
  );
};

PromoCodeModalContainer.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeAddModal: PropTypes.func.isRequired,
};

export default memo(PromoCodeModalContainer);
