import { COUNT_IN_CART, COUPONS_IN_CART } from '../../constants/cart-constants/cart-constants';

export const getLocalCartData = () => JSON.parse(localStorage.getItem(COUPONS_IN_CART));

export const clearLocalCartCount = () => localStorage.removeItem(COUNT_IN_CART);

export const setLocalCartCount = (count) => localStorage.setItem(COUNT_IN_CART, JSON.stringify(count));

export const setLocalCartData = (items) => localStorage.setItem(COUPONS_IN_CART, JSON.stringify(items));

export const clearLocalCartData = () => localStorage.removeItem(COUPONS_IN_CART);

export const removeCouponFromLocalCart = (couponId) => {
  const items = JSON.parse(localStorage.getItem(COUPONS_IN_CART));
  const filteredItems = items.filter(({ cartId }) => cartId !== couponId);
  localStorage.setItem(COUPONS_IN_CART, JSON.stringify(filteredItems));
};
