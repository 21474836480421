import React, { memo } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { WORD_FOR_ACCEPT_DELETE_ACTION } from '../../../../constants/general/tag-status';

const InputDelete = ({
  callbackSetValue, value, callbackDelete, valueDelete,
}) => {
  const onChangeHandler = (value) => {
    callbackSetValue(value);
  };

  const onKeyDownHandler = (e) => {
    const { code } = e;
    if (code === 'Enter' && valueDelete === WORD_FOR_ACCEPT_DELETE_ACTION) {
      callbackDelete();
    }
  };

  return (
    <Input
      autoFocus
      value={value}
      onChange={(e) => onChangeHandler(e.target.value)}
      onKeyDown={onKeyDownHandler}
    />
  );
};
InputDelete.propTypes = {
  value: PropTypes.string.isRequired,
  callbackSetValue: PropTypes.func.isRequired,
  callbackDelete: PropTypes.func.isRequired,
  valueDelete: PropTypes.string.isRequired,
};

export default memo(InputDelete);
