import React from 'react';
import { message } from 'antd';
import Page from '../../components/Page';
import PageControls from '../../components/PageControls';
import CategoriesTable from './CategoriesTable';
import useCategories from './hooks/useCategories';
import { ERROR_FETCH_CATEGORIES } from '../../constants/errors';
import AddModal from './AddModal';
import Loader from '../../components/Loader';
import useDebouncedSearch from '../../hooks/useDebouncedSearch/useDebouncedSearch';

const Categories = () => {
  const { search, onFilterChange } = useDebouncedSearch();

  const {
    categories,
    loading,
    error,
    refetch,
    isAddModalVisible,
    showModal,
    handleOk,
    handleCancel,
  } = useCategories();

  if (error) {
    message.error(ERROR_FETCH_CATEGORIES);
  }

  return (
    <Page>
      <PageControls
        title="Categories"
        onAdd={showModal}
        onSearch={onFilterChange}
        search={search}
      />
      { loading
        ? <Loader />
        : (
          <CategoriesTable
            refetch={refetch}
            data={categories}
            search={search}
          />
        )}
      <AddModal
        isModalVisible={isAddModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        categories={categories}
      />
    </Page>
  );
};

export default Categories;
