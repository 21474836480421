import React, { memo, useEffect, useState } from 'react';
import { Row, Switch } from 'antd';
import PropTypes from 'prop-types';
import { uid } from 'uid';
import s from './Day.module.scss';
import TimeInterval from '../TimeInterval';

const Day = ({
  day, dayOfWeek, setTimeIntervalForDay, deleteIntervalForDay, intervals, addTimeIntervalForDay, errors,
  changeIsCheckedIntervals,
}) => {
  const [isSetInterval, setTimeInterval] = useState(intervals.length > 0);

  const setTimeIntervalHandler = () => {
    if (!isSetInterval && !intervals.length) {
      const intervalId = uid();
      addTimeIntervalForDay({
        intervalId, dayOfWeek, startAt: null, endAt: null, isCheckedDay: true,
      });
    }
    changeIsCheckedIntervals(!isSetInterval, dayOfWeek);
    setTimeInterval((prev) => !prev);
  };

  useEffect(() => {
    if (intervals.length === 0) {
      setTimeInterval(false);
    }
  }, [intervals.length]);

  return (
    <>
      <Row
        key={day}
      >
        <h5 className={s.day}>{day}</h5>
        <Switch
          checked={isSetInterval}
          onChange={setTimeIntervalHandler}
          size="small"
          className={s.switch}
        />
      </Row>
      {isSetInterval && (
        <TimeInterval
          dayOfWeek={dayOfWeek}
          setTimeIntervalForDay={setTimeIntervalForDay}
          deleteIntervalForDay={deleteIntervalForDay}
          dayIntervals={intervals}
          addTimeIntervalForDay={addTimeIntervalForDay}
          errors={errors}
        />
      )}
    </>
  );
};

export default memo(Day);

Day.propTypes = {
  day: PropTypes.string.isRequired,
  dayOfWeek: PropTypes.string.isRequired,
  setTimeIntervalForDay: PropTypes.func.isRequired,
  deleteIntervalForDay: PropTypes.func.isRequired,
  intervals: PropTypes.oneOfType([PropTypes.array]).isRequired,
  addTimeIntervalForDay: PropTypes.func.isRequired,
  changeIsCheckedIntervals: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]),
};
Day.defaultProps = {
  errors: undefined,
};
