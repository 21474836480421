const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

const getImgBase64Url = (key) => (
  fetch(`${process.env.REACT_APP_BUCKET_URL}/${key}`)
    .then((res) => res.blob())
    .then((blob) => getBase64(blob))
    .then((url) => `data:image/svg+xml;base64,${url.split(',')[1]}`)
);

export default getImgBase64Url;
