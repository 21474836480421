import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { Tabs } from 'antd';
import HeaderComponent from '../Header';
import VouchersModal from '../modals/VouchersModal';
import { MERCHANT_VOUCHERS_ACTIVE_STATUS, MERCHANT_VOUCHERS_ALL_STATUS } from '../../../constants/vouchers/vouchers';
import VouchersActiveOfMerchant from '../VouchersActiveOfMerchant';
import TableVouchers from '../TableVouchers';
import './vouchers-merchant.scss';
import useAuthAdmin from '../../../context/context-admin/useAuthAdmin';

const VouchersOfMerchant = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams.get('status');

  const currentStatus = () => {
    switch (status) {
    case MERCHANT_VOUCHERS_ACTIVE_STATUS: return '2';
    default: return '1';
    }
  };

  const { userInfo } = useAuthAdmin();

  const changeTabHandler = (key) => {
    switch (key) {
    case '1': {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        status: MERCHANT_VOUCHERS_ALL_STATUS,
      });
      break;
    }
    default: setSearchParams({
      ...Object.fromEntries([...searchParams]),
      status: MERCHANT_VOUCHERS_ACTIVE_STATUS,
    });
    }
  };

  const openModalHandler = useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const closeModalHandler = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const { merchant = {} } = userInfo || {};
  const { isAllowedToGetSalesStatistics } = merchant || {};

  const renderBodyTables = () => {
    if (isAllowedToGetSalesStatistics) {
      return (
        <div className="vouchers-merchant tabsAntForAdminTables">
          <Tabs
            activeKey={currentStatus()}
            onChange={changeTabHandler}
          >

            <Tabs.TabPane
              key="1"
              tab="All vouchers"
            >
              <TableVouchers />
            </Tabs.TabPane>

            <Tabs.TabPane
              key="2"
              tab="Activated"
            >
              <VouchersActiveOfMerchant />
            </Tabs.TabPane>
          </Tabs
          >
        </div>
      );
    }
    return (

      <div className="vouchers-merchant tabsAntForAdminTables">
        <Tabs
          activeKey="2"
        >
          <Tabs.TabPane
            key="2"
            tab="Activated"
          >
            <VouchersActiveOfMerchant />
          </Tabs.TabPane>
        </Tabs
        >
      </div>
    );
  };

  const defaultStatus = isAllowedToGetSalesStatistics
    ? MERCHANT_VOUCHERS_ALL_STATUS
    : MERCHANT_VOUCHERS_ACTIVE_STATUS;

  useEffect(() => {
    const prevParams = Object.fromEntries([...searchParams]);
    setSearchParams({ ...prevParams, status: status || defaultStatus });
  }, [searchParams, setSearchParams, status]);

  return (
    <>
      <HeaderComponent
        callback={openModalHandler}
      />
      {renderBodyTables()}
      {
        isOpenModal && (
          <VouchersModal
            isOpenModal={isOpenModal}
            closeModalHandler={closeModalHandler}
          />
        )
      }
    </>
  );
};

export default memo(VouchersOfMerchant);
