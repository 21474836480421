import React, { memo } from 'react';
import s from './Faq.module.scss';
import mobileCondition from '../../../constants/mobileCondition';

const Faq = () => {
  const wrapperClassName = `${s.wrapper} ${mobileCondition && s.mobile}`;

  const titleClassName = `${s.title} ${mobileCondition && s.mobile}`;

  const subtitleClassName = `${s.subtitle} ${mobileCondition && s.mobile}`;

  return (
    <div className={wrapperClassName}>
      <h1 className={titleClassName}>FAQ</h1>
      <div>
        <h2 className={subtitleClassName}>
          How can I purchase in YallaBanana?
        </h2>
        <span>
          Create your account, look for the offer you want to purchase, add to cart and click “buy”.
          You may now proceed with check out and pay electronically by Visa or Mastercard.
        </span>
      </div>
      <div>
        <h2 className={subtitleClassName}>
          Is it safe to purchase in YallaBanana.com?
        </h2>
        <span>
          Yes! All of your bank details during your purchase are encrypted.
        </span>
      </div>
      <div>
        <h2 className={subtitleClassName}>
          I haven’t received my coupon YallaBanana. What can I do?
        </h2>
        <span>
          Kindly check your junk or spam folder.
        </span>
        <span>
          Some emails are filtered and goes to other folders. Add our email to your contacts.
        </span>
        <span>
          You may also try to log in to your account to check the coupons you have purchased.
        </span>
        <span>
          If still unsuccessful to claim the coupon kindly reach to us at T: +971 4 557 1981 Monday – Friday
          from 9am to 5pm or email us at
          {' '}
          <a href="mailto:info@yallabanana.com">info@yallabanana.com</a>
          {' '}
          will get back to you within 24 hours.
        </span>
      </div>
      <div>
        <h2 className={subtitleClassName}>
          How can I use the voucher I purchased in YallaBanana?
        </h2>

        <span>
          After successfully purchasing our coupons, a detailed voucher will be sent directly to your email. During
          your visit, upon making your reservation, kindly present your electronic YallaBanana Voucher or Printed if
          required by our merchants.
        </span>
      </div>
      <div>
        <h2 className={subtitleClassName}>
          I purchased a voucher. Do I need to use it the same day I bought it?
        </h2>
        <span>
          No. Each voucher has its own validity and Terms & Conditions. Kindly check the conditions of the purchased
          voucher.
        </span>
        <span>
          Vouchers that have expired are forfeited. If you have any difficulties or concerns with booking your
          appointment, please do contact us.
        </span>
      </div>
      <div>
        <h2 className={subtitleClassName}>
          Is it possible to get a refund?
        </h2>
        <span>
          If the merchant permanently closed the coupon will be refundable.
        </span>
        <span>
          Successfully purchased coupons are non-refundable. However, you can always give your coupon as a gift to
          friends or family.
        </span>
        <span>
          Vouchers for that have been used for redeeming or ordering food are considered to be nul
        </span>
      </div>
      <div>
        <h2 className={subtitleClassName}>
          How can I contact YallaBanana for any other inquiries?
        </h2>
        <span>
          You may reach us at T: +971 4 557 1981 Monday – Friday from 9am to 5pm or email us at
          {' '}
          <a href="mailto:info@yallabanana.com">info@yallabanana.com</a>
          {' '}
          will get back to you within 24 hours.
        </span>
      </div>
      <div>
        <h2 className={subtitleClassName}>
          What great deals does YallaBanana offer to its consumers?
        </h2>
        <span>
          We have a lot of varieties! From dining, to entertainment, to health and beauty, to gift packages,
          automotive services, to travel and tourism. Packages that not only bachelors and bachelorettes will
          enjoy but also kids and families.
        </span>
      </div>
    </div>
  );
};

export default memo(Faq);
