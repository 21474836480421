import React from 'react';
import s from './transfromDate.module.scss';

const transformTimeForTable = (obj =
{
  dayOfWeek: '',
  endAt: '',
  startAt: '',
}) => {
  const {
    dayOfWeek,
    endAt,
    startAt,
  } = obj;

  const startDay = dayOfWeek && dayOfWeek.slice(0, 3).toLowerCase();
  const stDay = startDay && startDay[0].toUpperCase() + startDay.slice(1);

  return (
    <div className={s.container}>
      <span>
        {stDay}
      </span>
      <span>
        {startAt}
        {' '}
        -
        {' '}
        {endAt}
      </span>
    </div>
  );
};
export default transformTimeForTable;
