import { useMemo } from 'react';
import useCartCoupons from './useCartCoupons';
import useLocalCartItems from './useLocalCartItems';
import { getLocalCartData } from '../../../helpers/cart-helpers/local-storage';
import useUpdateShoppingCartItems from './useUpdateShoppingCartItems';
import { isUserStored } from '../../../helpers/user-storage';
import useLocalCartCost from './useLocalCartCost';

const useCartContext = () => {
  const {
    loading, cartData, removeLoading, removeCouponFromCart, setPromo, promoCodeError, promoCodeDiscount,
  } = useCartCoupons();

  const {
    localItems, updateLocalCartOption, removeCouponFromLocalCart, updateLocalCartCoupon,
  } = useLocalCartItems();
  const {
    updateShoppingCartCoupon, updateShoppingCartOption, updateLoading,
  } = useUpdateShoppingCartItems();

  const isAnyCartDataExist = useMemo(() => {
    if (isUserStored()) {
      return cartData && cartData.couponsTableData.length > 0;
    }
    return getLocalCartData()?.length > 0;
  }, [cartData, localItems]);

  const totalCartData = isUserStored() ? cartData?.couponsTableData : localItems;

  const localCostCartData = useLocalCartCost(localItems);

  const totalCostData = useMemo(() => {
    const returnData = isUserStored() ? cartData : localCostCartData;
    if (totalCartData) {
      const {
        totalCostOfCouponsInCart,
        totalOldCostOfCouponsInCart,
        totalDiscountOfCouponsInCart,
        totalPromoDiscountOfCouponsInCart,
        totalVatOfCouponsInCart,
      } = returnData;
      return {
        totalCostOfCouponsInCart,
        totalOldCostOfCouponsInCart,
        totalDiscountOfCouponsInCart,
        totalPromoDiscountOfCouponsInCart,
        totalVatOfCouponsInCart,
      };
    }
    return {
      totalCostOfCouponsInCart: 0,
      totalDiscountOfCouponsInCart: 0,
      totalOldCostOfCouponsInCart: 0,
      totalPromoDiscountOfCouponsInCart: 0,
      totalVatOfCouponsInCart: 0,
    };
  }, [cartData, localCostCartData, totalCartData]);

  const isLoadingNow = loading || updateLoading || removeLoading;

  return {
    setPromo,
    cartData,
    totalCostData,
    totalCartData,
    isAnyCartDataExist,
    promoCodeError,
    promoCodeDiscount,
    isLoadingNow,
    removeCouponFromCart,
    updateLocalCartOption,
    removeCouponFromLocalCart,
    updateLocalCartCoupon,
    updateShoppingCartOption,
    updateShoppingCartCoupon,
  };
};

export default useCartContext;
