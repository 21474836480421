import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { GET_PROMO_CODE_BY_ID_ADMIN } from '../../../../../api/PromoCodes/query';
import { DiscountUnits, Quantity, Tied } from '../../../../../constants/promoCodes/promoCodes';

const useGetPromoCodeById = () => {
  const [getPromoCodeById,
    { loading: loadingGetCouponById, error, data }] = useLazyQuery(GET_PROMO_CODE_BY_ID_ADMIN);

  const getPromoCodeByIdHandler = useCallback(async (id) => {
    await getPromoCodeById({
      variables: {
        id,
      },
    });
  }, [getPromoCodeById]);

  if (data) {
    const { getPromoCodeByIdAdmin } = data;

    const {
      __typename,
      promoCode,
      availableAmount: availableAmountValue,
      couponOptionId,
      discount,
      discountUnit,
      ...rest
    } = getPromoCodeByIdAdmin;
    return {
      getPromoCodeByIdAdmin: {
        ...rest,
        tied: couponOptionId ? Tied.OPTION : Tied.COUPON,
        couponOptionId,
        discount: discountUnit === DiscountUnits.ABSOLUTE ? +discount / 100 : discount,
        discountUnit,
        quantity: availableAmountValue === 1 ? Quantity.SINGLE : Quantity.MULTIPLE,
        availableAmount: availableAmountValue === 1 ? '' : String(availableAmountValue),
      },
      getPromoCodeByIdHandler,
      loadingGetCouponById,
      error,
    };
  }

  return {
    loadingGetCouponById,
    getPromoCodeByIdHandler,
    error,
  };
};

export default useGetPromoCodeById;
