import React, { memo } from 'react';
import fail from '../../../../../assets/images/fail.png';
import './success-header.scss';

const FailedHeader = () => (
  <div className="success-header">
    <div className="success-header__image">
      <img
        src={fail}
        alt="success"
      />
    </div>
    <div className="success-header__text">
      <h6>Transaction is failed</h6>
    </div>
  </div>
);

export default memo(FailedHeader);
