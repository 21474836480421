import React, { memo, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import SuccessHeader from './SuccessHeader';
import SuccessBtns from './SuccessBtns';
import './success-component.scss';
import SuccessBody from './SuccessBody';
import Loader from '../../../Loader';
import mobileCondition from '../../../../constants/mobileCondition';
import useGetOrderInfo from '../../hooks/gql/useGetOrderInfo';
import useVoucherDownload from '../../../Profile/hooks/gql/useVoucherDownload';

const SuccessComponent = () => {
  const { downloadAllVouchers, downloadVoucherInFile } = useVoucherDownload();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const containerClassName = `containerCheckoutFinish ${mobileCondition ? 'mobile' : ''}`;

  const { applyOrder, loading } = useGetOrderInfo(id);

  const downloadVoucherCallback = useCallback(async () => {
    if (applyOrder) {
      setIsLoading(true);

      const { vouchers } = applyOrder;

      const promisesDownloadedVouchers = [];

      vouchers.forEach(({ id }) => {
        promisesDownloadedVouchers.push(downloadAllVouchers(id));
      });

      const files = await Promise.all(promisesDownloadedVouchers);
      await downloadVoucherInFile(files);
      setIsLoading(false);
    }
  }, [applyOrder]);

  if (loading) {
    return <Loader />;
  }

  if (!applyOrder) {
    return <div />;
  }

  return (
    <div className={containerClassName}>
      <SuccessHeader />
      <SuccessBody
        applyOrder={applyOrder}
      />
      <SuccessBtns
        callback={downloadVoucherCallback}
      />
      {isLoading && <Loader />}
    </div>
  );
};

export default memo(SuccessComponent);
