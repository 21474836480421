import React from 'react';
import { Link } from 'react-router-dom';
import s from './ShowBannersOrAllCouponsHere.module.scss';
import mobileCondition from '../../../constants/mobileCondition';
import ShowBanners from '../ShowBanners';
import Loader from '../../Loader';
import useGetBanners from '../ShowBanners/hooks/gql/useGetBanners';

const ShowBannersOrAllCouponsHere = () => {
  const oneBannerClassName = `${s.oneBanner} ${mobileCondition && s.mobile}`;

  const { bannersData, loadingGetBanners, errorGetBanners } = useGetBanners();

  if (loadingGetBanners) return <Loader />;

  if (errorGetBanners) return <div>Error</div>;

  const bannersIds = Object.keys(bannersData);

  if (bannersIds.length === 1) {
    const [id] = bannersIds;
    const { link, bannerPicture } = bannersData[id];
    const slide = (
      <img
        src={bannerPicture}
        alt="banner"
      />
    );

    const isExternal = link.includes('/coupon');

    const target = isExternal ? '_self' : '_blank';

    const totalLink = !isExternal
      ? (
        <a
          href={link}
          target={target}
        >
          {slide}
        </a>
      )
      : (
        <Link
          to={link}
          target={target}
        >
          {slide}
        </Link>
      );

    return link
      ? (
        <div className={oneBannerClassName}>
          {totalLink}
        </div>
      )
      : slide;
  }
  return bannersData
    ? <ShowBanners bannersData={bannersData} />
    : <div />;
};

export default ShowBannersOrAllCouponsHere;
