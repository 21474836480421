import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CHANGE_PASSWORD } from '../../../../api/profile/query';
import { GET_USER_SELF } from '../../../../api/query';

const useSendNewPassword = () => {
  const [changePassword, {
    data: updatePasswordUser,
    loading: updatePasswordLoading,
    error: updatePasswordError,

  }] = useMutation(CHANGE_PASSWORD, {
    refetchQueries: [GET_USER_SELF],
  });

  const navigate = useNavigate();

  const changePasswordHandler = useCallback((data) => {
    changePassword({
      variables: {
        changePasswordInput: data,
      },
    });
  }, [changePassword]);

  if (updatePasswordUser) {
    navigate(-1);
    return {
      updatePasswordUser,
      updatePasswordLoading,
      changePasswordHandler,
      updatePasswordError,
    };
  }

  return {
    updatePasswordLoading,
    changePasswordHandler,
    updatePasswordError,
  };
};

export default useSendNewPassword;
