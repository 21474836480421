export const SUBSCRIBE_TOOLTIP_STYLE = {
  WARNING: {
    backgroundColor: '#F9E218',
    color: '#333333',
  },
  ERROR: {
    backgroundColor: '#d000001a',
    color: '#D00000',
  },
  SUCCESS: {
    backgroundColor: '#0bae2f1a',
    color: '#0BAE2F',
  },
};

export const SUBSCRIBE_MESSAGES = {
  SUCCESS: 'You have successfully subscribed',
  ERROR: 'Network error. Try again later',
  NOT_VALID: 'Enter a valid email address',
};
