import { gql } from '@apollo/client';

export const CREATE_BANNER_ADMIN = gql`
    mutation createBannerAdmin ($createBannerInput: CreateBannerInput!) {
        createBannerAdmin(createBannerInput: $createBannerInput) {
            id
        }
    }
`;

export const UPLOAD_BANNER_PICTURE = gql`
    mutation uploadBannerPicture ($file: Upload!, $bannerId: ID!) {
        uploadBannerPicture(file: $file, bannerId: $bannerId) {
            id
        }
    }
`;

export const GET_ALL_BANNERS_ADMIN = gql`
    query getAllBannersAdmin ($offset: Float, $limit: Float) {
        getAllBannersAdmin(offset: $offset, limit: $limit) {
            banners {
                id
                title
                description
                isPublished
                page
                link
                position
                type
            }
            count
        }
    }
`;

export const DELETE_BANNER_BY_ADMIN = gql`
    mutation deleteBannerByIdAdmin ($id: String!) {
        deleteBannerByIdAdmin(id: $id) 
    }
`;

export const UPDATE_BANNER_ADMIN = gql`
    mutation updateBannerByIdAdmin ($updateBannerInput: UpdateBannerInput!, $id: String!) {
        updateBannerByIdAdmin(updateBannerInput: $updateBannerInput, id: $id) {
            id
        }
    }
`;

export const GET_BANNER_BY_ID_ADMIN = gql`
    query getBannerByIdAdmin ($id: String!) {
        getBannerByIdAdmin(id: $id) {
            id
            title
            description
            position
            page
            link
            type
            bannerPicture {
                id
                key
                bannerId
            }
               
        }
    }
`;

export const CREATE_BANNER_COPY_ADMIN = gql`
    mutation createBannerCopyAdmin ($id: ID!) {
        createBannerCopyAdmin(id: $id) {
            id
        }
    }
`;

export const DELETE_BANNER_PICTURE_ADMIN = gql`
    mutation deleteBannerPicture ($bannerPictureId: ID!) {
        deleteBannerPicture(bannerPictureId: $bannerPictureId)
    }
`;
