import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './orders-modal.scss';
import ModalFileDownload from '../../Common_components/ModalFileDownload';
import useExportOrders from '../hooks/gql/useExportOrders';

const OrdersModal = ({ isOpenModal, closeModalHandler }) => {
  const customersExcelKey = useExportOrders();

  return (
    <ModalFileDownload
      isOpenModal={isOpenModal}
      closeModalHandler={closeModalHandler}
      titleDocument=" Orders.xls"
      customersExcelKey={customersExcelKey}
    />
  );
};
OrdersModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
};

export default memo(OrdersModal);
