import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './header-component.scss';
import ButtonComponent from '../../../Common_components/ButtonComponent';
import SearchInputComponent from '../../../Common_components/SearchInputComponent';
import useDebouncedSearch from '../../../../hooks/useDebouncedSearch/useDebouncedSearch';

const HeaderComponent = ({ navigateTo, title }) => {
  const { search, onFilterChange, onEnterFilterChange } = useDebouncedSearch();
  const navigate = useNavigate();

  const callbackBtn = () => {
    navigate(navigateTo);
  };

  return (
    <header className="header">
      <div className="header__row">
        <div className="header__left">
          <h1 className="header__title">{title}</h1>
          <ButtonComponent
            title="Add"
            callback={callbackBtn}
          />
        </div>
        <SearchInputComponent
          value={search}
          callback={onFilterChange}
          placeholder="Search..."
          onEnterCallback={onEnterFilterChange}
        />
      </div>
    </header>
  );
};

HeaderComponent.propTypes = {
  navigateTo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
export default HeaderComponent;
