import { useContext, useState } from 'react';
import { getLocalCartData, setLocalCartData } from '../../../helpers/cart-helpers/local-storage';
import { COUPONS_IN_CART } from '../../../constants/cart-constants/cart-constants';
import CountContext from '../../Layout/context/countContext';

const useLocalCartItems = () => {
  const { updateLocalCouponsCount } = useContext(CountContext);

  const [localItems, setLocalItems] = useState(getLocalCartData());

  const removeCouponFromLocalCart = (couponId) => {
    const items = getLocalCartData();
    if (items) {
      const removedCount = items.find(({ cartId }) => cartId === couponId).quantity;
      const filteredItems = items.filter(({ cartId }) => cartId !== couponId);
      setLocalItems(filteredItems);
      updateLocalCouponsCount((prev) => prev - removedCount);
      if (filteredItems.length < 1) {
        localStorage.removeItem(COUPONS_IN_CART);
      } else {
        localStorage.setItem(COUPONS_IN_CART, JSON.stringify(filteredItems));
      }
    }
  };

  const updateLocalCartCoupon = (couponCartId, amount) => {
    const items = getLocalCartData();
    if (items) {
      const newItems = items.map((el) => (el.cartId === couponCartId ? { ...el, quantity: amount } : el));
      const totalQuantity = newItems.reduce((acc, el) => acc + el.quantity, 0);
      setLocalCartData(newItems);
      setLocalItems(newItems);
      updateLocalCouponsCount(totalQuantity);
    }
  };

  const updateLocalCartOption = (couponCartId, amount, optionId) => {
    const items = getLocalCartData();
    if (items) {
      const couponWithOptions = items.find(({ cartId }) => couponCartId === cartId);
      const couponOptions = couponWithOptions.shoppingCartCouponOptions;
      const newOptions = couponOptions.map((o) => (o.id === optionId ? { ...o, amount } : o));
      const newCoupon = { ...couponWithOptions, shoppingCartCouponOptions: newOptions };
      const newItems = items.map((c) => (c.cartId === couponCartId ? newCoupon : c));
      setLocalCartData(newItems);
      setLocalItems(newItems);
    }
  };

  return {
    localItems, removeCouponFromLocalCart, updateLocalCartCoupon, updateLocalCartOption,
  };
};

export default useLocalCartItems;
