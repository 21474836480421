import React, { memo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Table } from 'antd';
import Loader from '../../Loader/Loader';
import useGetColumnBuyers from './column/useGetColumnBuyers';
import useFindAllCustomers from '../hooks/gql/useFindAllCustomers';
import './table-buyers-ant.scss';
import { limit } from '../../../constants/tableConstants';
import useWindowSize from '../../../hooks/useWindowSize/useWindowSize';
import { heightMinusForAdminTables } from '../../../constants/general/tag-status';

const TableBuyers = () => {
  const {
    loading, count, users,
  } = useFindAllCustomers();

  const [searchParams, setSearchParams] = useSearchParams();
  const [height] = useWindowSize();

  const columns = useGetColumnBuyers();

  const page = searchParams.get('page') || '1';

  const onChangePage = (page) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page,
    });
  };

  if (loading) {
    return <Loader />;
  }
  return (
    <section className="all-admin-table">
      <Table
        className="adminTable tableBuyers"
        dataSource={users}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{
          y: height - heightMinusForAdminTables, x: 0,
        }}
        pagination={{
          current: Number(page),
          pageSize: limit,
          total: count,
          position: ['bottomCenter'],
          size: 'small',
          showSizeChanger: false,
          onChange: (page) => onChangePage(page),
        }}
      />
    </section>
  );
};

export default memo(TableBuyers);
