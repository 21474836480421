import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import s from '../SecuritySuccess.module.scss';
import failedImage from '../../../assets/images/fail.png';

const SecurityFailed = () => (
  <div className={s.main}>
    <div className={s.content}>
      <img
        className={s.image}
        src={failedImage}
        alt="success"
      />
      <h1 className={s.title}>Something wrong!</h1>
      <h3 className={s.subtitle}>
        You do not ave access rights. Please, contact the administrator
        to resolve this issue
      </h3>
      <Link
        to="/security"
        className={s.link}
        replace
      >
        Close
      </Link>
    </div>
  </div>
);

export default memo(SecurityFailed);
