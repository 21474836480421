import React from 'react';
import { Dropdown, Menu } from 'antd';
import PropTypes from 'prop-types';
import './DropdownBtnAnt.scss';
import { More } from '../../../constants/icons';
import { ROLE_EMPLOY_SUPER_ADMIN } from '../../../constants/employ';

const DropdownBtnComponent = ({
  record, actions, children, hidden,
}) => {
  const menu = (
    <Menu>
      {
        actions.map((item) => (
          <Menu.Item
            disabled={(item?.title === 'Delete'
                && (record?.role === ROLE_EMPLOY_SUPER_ADMIN))
                || (item?.title === 'Delete' && record.couponPurchasesCount > 0)
                || item?.disabled
                || false}
            key={item.key}
            onClick={() => item.callback(record.id)}
          >
            {item.title}
          </Menu.Item>
        ))
      }
    </Menu>
  );

  const menuClassName = `dropdown-btn ${children ? 'with-children' : ''} ${hidden ? 'hidden' : ''}`;

  return (
    <Dropdown.Button
      overlay={menu}
      icon={children || <More />}
      className={menuClassName}
    />
  );
};

DropdownBtnComponent.propTypes = {
  record: PropTypes.oneOfType([PropTypes.object]).isRequired,
  actions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  children: PropTypes.node,
  hidden: PropTypes.bool,
};

DropdownBtnComponent.defaultProps = {
  children: undefined,
  hidden: false,
};

export default DropdownBtnComponent;
