import React, { memo, useContext, useEffect } from 'react';
import { Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import s from './MobileAccount.module.scss';
import { ArrowLeft } from '../../../constants/icons';
import UserContext from '../../Layout/context/userContext';
import Loader from '../../Loader';
import useAccountForm from '../hooks/useAccountForm';
import { PROFILE } from '../../../constants/Routes';
import { regPassword } from '../../../helpers/regularExpression/regularExpression';
import { PASSWORD_ERROR } from '../../../constants/errors';

const MobileAccount = () => {
  const { userData, fetchUserLoading } = useContext(UserContext);

  const [form] = Form.useForm();

  const {
    loading,
    password,
    profile,
    totalTitle,
    isFormError,
    cancelOnClick,
    saveProfileOnClick,
    sendPasswordCallback,
    setPasswordOpen,
    setProfileOpen,
    dataEditHandler,
    passwordLoading,
  } = useAccountForm(form);

  useEffect(() => {
    if (userData && !fetchUserLoading) {
      const { firstName, lastName, phone } = userData;
      form.setFieldsValue({
        firstName: firstName || '-', lastName: lastName || '-', phone: phone || '',
      });
    }
  }, [userData, fetchUserLoading]);

  const onEditClick = () => {
    const { firstName, lastName } = userData;
    if (!(firstName || lastName)) {
      form.setFieldsValue({ firstName: '', lastName: '', phone: '' });
    }
  };

  const onProfileEditClick = () => {
    onEditClick();
    setProfileOpen();
  };

  const onPasswordEditClick = () => {
    onEditClick();
    setPasswordOpen();
  };

  const navigate = useNavigate();

  const isNameSet = !!(userData?.firstName || userData?.lastName);

  const profileName = isNameSet ? `${userData?.firstName} ${userData?.lastName}` : 'Account';

  const inputClassName = `${s.item} ${!(password || profile) && s.disabled}`;

  const phoneContainerClassName = `${s.phoneContainer} ${!(password || profile) && s.active}`;

  if (fetchUserLoading) return <Loader />;

  return (
    <div className={s.main}>
      {(loading || passwordLoading) && <Loader />}
      <div className={s.profile}>
        <div
          className={s.back}
          onClick={() => navigate(PROFILE)}
        >
          <ArrowLeft />
          <span>{profileName}</span>
        </div>
        <span className={s.title}>{totalTitle}</span>
        <Form
          form={form}
          layout="vertical"
        >
          {!password
          && (
            <>
              <Form.Item
                label="Name"
                name="firstName"
              >
                <Input
                  maxLength={30}
                  disabled={!(password || profile)}
                  value={userData?.firstName}
                  className={inputClassName}
                  onChange={dataEditHandler}
                />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="lastName"
              >
                <Input
                  maxLength={30}
                  disabled={!(password || profile)}
                  value={userData?.lastName}
                  className={inputClassName}
                  onChange={dataEditHandler}
                />
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  disabled
                  value={userData?.email}
                  className={inputClassName}
                />
              </Form.Item>
              <Form.Item
                label="Phone (optional)"
                name="phone"
              >
                <PhoneInput
                  disabled={!(password || profile)}
                  containerClass={phoneContainerClassName}
                  placeholder={userData?.phone || ''}
                  buttonStyle={{
                    display: !(password || profile) ? 'none' : 'block',
                  }}
                  buttonClass={s.flagDropdown}
                />
              </Form.Item>
            </>
          )}
          {password
              && (
                <>
                  <Form.Item
                    label="Current password"
                    name="currentPassword"
                    rules={[{
                      required: true,
                      message: 'Please, enter your password!',
                    }]}
                  >
                    <Input.Password
                      visibilityToggle={password}
                      className={inputClassName}
                    />
                  </Form.Item>
                  <Form.Item
                    label="New password"
                    name="newPassword"
                    rules={[{
                      pattern: regPassword,
                      required: true,
                      message: PASSWORD_ERROR,
                    }]}
                  >
                    <Input.Password
                      visibilityToggle={password}
                      className={inputClassName}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Confirm password"
                    name="confirmPassword"
                    dependencies={['newPassword']}
                    rules={[{
                      required: true,
                      message: 'This field should not be empty!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        const isPassTouched = form.isFieldTouched('newPassword');
                        if (!isPassTouched) {
                          return Promise.resolve();
                        }
                        if (!value || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The two passwords that you entered do not match!'));
                      },
                    })]}
                  >
                    <Input.Password
                      visibilityToggle={password}
                      className={inputClassName}
                    />
                  </Form.Item>
                </>
              )}
        </Form>
      </div>
      <div className={s.buttons}>
        {(profile || password)
          ? (
            <>
              <button
                className={`${s.button} ${s.cancel}`}
                onClick={cancelOnClick}
              >
                Cancel
              </button>
              <button
                className={s.button}
                disabled={isFormError}
                onClick={profile
                  ? saveProfileOnClick
                  : sendPasswordCallback}
              >
                Save
              </button>
            </>
          )
          : (
            <>
              <button
                className={s.button}
                onClick={onProfileEditClick}
              >
                Edit profile
              </button>
              <button
                className={s.button}
                onClick={onPasswordEditClick}
              >
                Edit password
              </button>
            </>
          )}
      </div>
    </div>
  );
};

export default memo(MobileAccount);
