import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { FIX_CART_ORDER, GET_ALL_COUPONS_IN_CART, GET_COUPONS_IN_CART_COUNT } from '../../../api/cart-api/query';

const useFixCartOrder = () => {
  const [callback, { loading: fixOrderLoading }] = useMutation(FIX_CART_ORDER, {
    refetchQueries: [GET_ALL_COUPONS_IN_CART, GET_COUPONS_IN_CART_COUNT],
  });

  const fixOrderHandler = useCallback(async (updatesInput) => {
    await callback({
      variables: {
        updatesInput,
      },
    });
  }, [callback]);

  return { fixOrderHandler, fixOrderLoading };
};

export default useFixCartOrder;
