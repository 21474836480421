import React, { memo } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';

const InputDelete = ({
  callback, value, deleteBanner, valueDelete,
}) => {
  const onChangeHandler = (value) => {
    callback(value);
  };

  const onKeyDownHandler = (e) => {
    const { code } = e;
    if (code === 'Enter' && valueDelete === 'DELETE') {
      deleteBanner();
    }
  };

  return (
    <Input
      autoFocus
      value={value}
      onChange={(e) => onChangeHandler(e.target.value)}
      onKeyDown={onKeyDownHandler}
    />
  );
};
InputDelete.propTypes = {
  value: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  deleteBanner: PropTypes.func.isRequired,
  valueDelete: PropTypes.string.isRequired,
};

export default memo(InputDelete);
