const breakStringLine = (string, lengthToBreak) => {
  if (string.length > lengthToBreak) {
    let length = 0;
    const arrToSplit = string.split(' ').map((el) => {
      length += el.length;
      if (length >= lengthToBreak) {
        length = 0;
        return `\n${el}`;
      }
      return el;
    });
    return arrToSplit.join(' ');
  }
  return string;
};

export default breakStringLine;
