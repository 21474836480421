import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_VOUCHER_BY_ID } from '../../../api/voucher/query';
import formatDate from '../../../helpers/cart-helpers/formatDate';

const useGetVoucherData = () => {
  const { id } = useParams();

  const { data, loading } = useQuery(GET_VOUCHER_BY_ID, { variables: { id } });

  const totalData = useMemo(() => {
    if (data) {
      const {
        coupon: { title, couponPreviewPicture: { key } },
        company,
        voucherNumber,
        payDate,
        validDueDate,
        activateDate,
        status,
      } = data.getVoucherById;
      const previewImageLink = `${process.env.REACT_APP_BUCKET_URL}/${key}`;

      const paymentDate = formatDate(payDate);

      const validDate = validDueDate ? formatDate(validDueDate) : '-';

      const activeDate = activateDate ? formatDate(activateDate) : null;

      return {
        status,
        title,
        previewImageLink,
        company,
        voucherNumber,
        paymentDate,
        validDate,
        activeDate,
      };
    }
    return null;
  }, [data]);

  return { totalData, loading, id };
};

export default useGetVoucherData;
