import React, { memo, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SecurityContext } from '../SecurityLayout';
import { getRoleUser, getStoredUser } from '../../../helpers/user-storage';
import Loader from '../../Loader';
import { ROLE_CUSTOMER } from '../../../constants/employ';

const SecurityNotAuthRedirect = ({ children }) => {
  const { authLoading } = useContext(SecurityContext);

  const suitableRole = !!(getRoleUser() && getRoleUser() !== ROLE_CUSTOMER);

  if (authLoading) return <Loader />;

  if (!getStoredUser() || !suitableRole) return <Navigate to="/security/auth" />;

  return children;
};

SecurityNotAuthRedirect.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(SecurityNotAuthRedirect);
