import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './MobilePageCount.module.scss';

const MobilePageCount = ({ currentPage, totalPages }) => (
  <div className={s.main}>
    {currentPage}
    /
    {totalPages}
  </div>
);

MobilePageCount.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default memo(MobilePageCount);
