import { memo, useEffect } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { useNavigate } from 'react-router-dom';
import s from './ScannerComponent.module.scss';
import './scanner.scss';

const ScannerComponent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.classList.add('scanner-mode');

    const html5QrcodeScanner = new Html5Qrcode('reader');

    html5QrcodeScanner.start(
      { facingMode: 'environment' },
      {
        fps: 20,
        qrbox: { width: 300, height: 300 },
        aspectRatio: 1.9,
      },
      (result) => {
        if (result) {
          html5QrcodeScanner.stop();
          console.log('result', result);
          console.log('window.location.origin', window.location.origin);
          const fixedResult = result
            .replace(`${window.location.protocol}//www.`, `${window.location.protocol}//`)
            .replace(window.location.origin, '');
          console.log('fixedResult', fixedResult);
          navigate(fixedResult);
        }
      },
      () => {},
    );

    return () => {
      document.body.classList.remove('scanner-mode');
      html5QrcodeScanner.stop();
    };
  }, []);

  return (
    <div className={s.wrapper}>
      <div
        className={s.reader}
        id="reader"
      />
    </div>
  );
};

export default memo(ScannerComponent);
