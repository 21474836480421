import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { FIND_ALL_COUPONS_ADMIN, FIND_ALL_COUPONS_MERCHANT } from '../../../../../api/query';
import { limit } from '../../../../../constants/tableConstants';
import { getRoleUser } from '../../../../../helpers/user-storage';
import { ROLE_EMPLOY_MERCHANT, ROLE_EMPLOY_MERCHANT_SUPER } from '../../../../../constants/employ';
import {
  CATEGORY_ID_FILTER_KEY,
  DATE_RANGE_END_KEY,
  DATE_RANGE_START_KEY,
  FROM_HIGHER_KEY,
  IS_PUBLISHED_FILTER_KEY,
  PURCHASES_FILTER_KEY,
  SUB_CATEGORY_FILTER_KEY,
  UPDATED_AT_KEY,
  VIEWS_FILTER_KEY,
} from '../../../../../constants/general/filters';
import { STATUS_TABLE_ACTIVE } from '../../../../../constants/couponsAdmin';
import useLastPageChange from '../../../../../hooks/useLastPageChange/useLastPageChange';

const useFindAllCoupons = () => {
  const [searchParams] = useSearchParams();

  const dateRangeStart = searchParams.get(DATE_RANGE_START_KEY);

  const dateRangeEnd = searchParams.get(DATE_RANGE_END_KEY);

  const status = searchParams.get('status') || STATUS_TABLE_ACTIVE;

  const page = searchParams.get('page') || '1';

  const isPublished = JSON.parse(searchParams.get(IS_PUBLISHED_FILTER_KEY));

  const search = searchParams.get('search') || null;

  const sortField = useMemo(() => {
    const temp = [...searchParams].find(([key]) => key === PURCHASES_FILTER_KEY
        || key === VIEWS_FILTER_KEY
        || key === UPDATED_AT_KEY);
    if (temp) {
      const [key, value] = temp;
      return { sortField: key, sortOrder: value };
    }
    return { sortField: null, sortOrder: FROM_HIGHER_KEY };
  }, [searchParams]);

  const categoryId = searchParams.get(CATEGORY_ID_FILTER_KEY) || null;

  const subCategoryId = searchParams.get(SUB_CATEGORY_FILTER_KEY) || null;

  const totalQuery = () => {
    switch (getRoleUser()) {
    case ROLE_EMPLOY_MERCHANT:
    case ROLE_EMPLOY_MERCHANT_SUPER:
      return FIND_ALL_COUPONS_MERCHANT;
    default:
      return FIND_ALL_COUPONS_ADMIN;
    }
  };

  const { loading, error, data } = useQuery(totalQuery(), {
    variables: {
      status,
      offset: (+page - 1) * limit,
      limit,
      isPublished,
      search,
      categoryId,
      subCategoryId,
      dateRangeStart,
      dateRangeEnd,
      ...sortField,
    },
  });

  useLastPageChange(data);

  if (data) {
    if (getRoleUser() === ROLE_EMPLOY_MERCHANT || getRoleUser() === ROLE_EMPLOY_MERCHANT_SUPER) {
      const { findAllCouponsMerchant: { coupons, count } } = data;
      return {
        coupons,
        count,
        totalQuery,
      };
    }
    const { findAllCouponsAdmin: { coupons, count } } = data;
    return {
      coupons,
      count,
      totalQuery,
    };
  }

  return {
    loading,
    error,
    totalQuery,
  };
};
export default useFindAllCoupons;
