import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import style from '../../../ModalForm.module.scss';
import NumericInput from '../../../../Common_components/InputNumberComponent/InputNumberComponent';
import { Quantity } from '../../../../../constants/promoCodes/promoCodes';

const QuantityBlock = (props) => {
  const {
    allErrors, setPromoCodeInfoHandler, quantity, availableAmount,
  } = props;
  const finallyInputNumberBorder = () => ({
    border: (allErrors?.availableAmount && quantity === Quantity.MULTIPLE
      && !availableAmount) || (allErrors?.availableAmount && quantity === Quantity.MULTIPLE
      && availableAmount !== '' && +availableAmount === 0) ? '1px solid red' : '1px solid #EDEDED',
  });

  const changeQuantity = () => {
    setPromoCodeInfoHandler('quantity', quantity === Quantity.SINGLE ? Quantity.MULTIPLE : Quantity.SINGLE);
  };

  const setAvailableAmount = (key, value) => {
    if (!Number.isNaN(value)) {
      if (+value < 2147483647) {
        setPromoCodeInfoHandler(key, availableAmount === '0' ? value.slice(1) : value);
      } else {
        setPromoCodeInfoHandler(key, '2147483647');
      }
    }
  };

  return (
    <Radio.Group
      className={style.quantity}
      name="quantity"
      value={quantity}
      onChange={changeQuantity}
    >
      <Radio value={Quantity.SINGLE}>Single</Radio>
      <div />
      <Radio value={Quantity.MULTIPLE}>Multiple</Radio>
      <div className={style.inputContainerTwo}>
        <NumericInput
          keyValue="availableAmount"
          value={String(availableAmount)}
          callbackHandler={setAvailableAmount}
          disabled={quantity !== Quantity.MULTIPLE}
          style={finallyInputNumberBorder()}
        />
        {allErrors?.availableAmount && quantity === Quantity.MULTIPLE && !availableAmount
            && <span className={`${style.errorText} ${style.suf}`}>Incorrect value!</span>}
      </div>
    </Radio.Group>
  );
};

QuantityBlock.propTypes = {
  quantity: PropTypes.string.isRequired,
  setPromoCodeInfoHandler: PropTypes.func.isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
  availableAmount: PropTypes.string,
};
QuantityBlock.defaultProps = {
  allErrors: null,
  availableAmount: null,
};

export default memo(QuantityBlock);
