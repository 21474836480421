import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { GET_VOUCHER_OPTION_BY_ID } from '../../../api/voucher/query';
import formatDate from '../../../helpers/cart-helpers/formatDate';

const useGetVoucherOptionById = () => {
  const { voucherOptionId } = useParams();

  const { data, loading } = useQuery(GET_VOUCHER_OPTION_BY_ID, { variables: { id: voucherOptionId } });

  const totalData = useMemo(() => {
    if (data) {
      const {
        optionTitle: title,
        voucher: {
          company,
        },
        payDate,
        activateDate,
        status,
        voucherNumber,
      } = data.getVoucherOptionById;

      const paymentDate = formatDate(payDate);

      const activeDate = activateDate ? formatDate(activateDate) : null;

      return {
        title,
        company,
        status,
        voucherNumber,
        paymentDate,
        activeDate,
      };
    }
    return null;
  }, [data]);

  return { totalData, loading };
};

export default useGetVoucherOptionById;
