import React, { memo } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import { finallyBorderInput } from '../../helpers/finallyBorder';
import './text-area.scss';

const TextAreas = ({ setCouponInputHandler, couponInput, allErrors }) => {
  const onChangeHandler = (value, key) => {
    setCouponInputHandler(key, value);
  };

  return (
    <div className="text-areas">
      <div className="text-areas__item">
        <p>THE FINE PRINT</p>
        <Input.TextArea
          defaultValue={couponInput.terms}
          value={couponInput.terms}
          onChange={(e) => onChangeHandler(e.target.value, 'terms')}
          style={{
            border: allErrors?.terms && finallyBorderInput(couponInput.terms && couponInput.terms.trim()),
            borderRadius: '4px',
            minHeight: '134px',
          }}
        />
        {allErrors?.terms
        && !couponInput.terms && <span className="errorCouponText">This field is required</span>}
      </div>

      <div className="text-areas__item">
        <p>OFFER HIGHLIGHTS</p>
        <Input.TextArea
          defaultValue={couponInput.description}
          value={couponInput.description}
          onChange={(e) => onChangeHandler(e.target.value, 'description')}
          style={{
            border: '1px solid #EDEDED',
            borderRadius: '4px',
            minHeight: '134px',
          }}
        />
      </div>
    </div>
  );
};

TextAreas.propTypes = {
  setCouponInputHandler: PropTypes.func.isRequired,
  couponInput: PropTypes.oneOfType([PropTypes.object]).isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
};
TextAreas.defaultProps = {
  allErrors: undefined,
};

export default memo(TextAreas);
