import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import validatorForm from '../../../../helpers/validator';
import { PROFILE_ACCOUNT_INFO_SCHEMA } from '../../../../constants/profileValidatorSchema/validatorSchema';
import useCreateEmploy from '../gql/useCreateEmploy';
import useGetEmployById from '../gql/useGetEmployById';
import useUpdateEmployById from '../gql/useUpdateEmploy';
import { ROLE_EMPLOY_ADMIN, ROLE_EMPLOY_MERCHANT, ROLE_EMPLOY_SUPER_ADMIN } from '../../../../constants/employ';
import { getRoleUser } from '../../../../helpers/user-storage';

const useCreateEmployContainer = (closeModalWindowEmploy) => {
  const [stateEmploy, setInitStateEmploy] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    status: null,
    role: getRoleUser() === ROLE_EMPLOY_SUPER_ADMIN ? ROLE_EMPLOY_ADMIN : ROLE_EMPLOY_MERCHANT,
  });
  const [searchParams] = useSearchParams();
  const idSearchParams = searchParams.get('id') || '';

  const [allErrors, setAllErrors] = useState(null);
  const { createEmployByAdmin, loadingCreateEmploy } = useCreateEmploy(closeModalWindowEmploy);
  const { loadingUpdateEmployByIdAdmin, updateEmployByAdminHandler } = useUpdateEmployById();
  const { getEmployeeByIdHandler, loadingGetEmploy, data } = useGetEmployById();

  const changeInitStateEmploy = useCallback((key, value) => {
    setInitStateEmploy((prev) => ({
      ...prev,
      [key]: value,
    }));
    setAllErrors((prev) => ({
      ...prev,
      [key]: '',
    }));
  }, []);

  const sendDataHandler = useCallback(async () => {
    const {
      id, firstName, lastName, email, ...rest
    } = stateEmploy;

    const { errors } = validatorForm({
      ...rest,
      firstName: firstName && firstName.trim(),
      lastName: lastName && lastName.trim(),
      email: email && email.trim(),
    }, PROFILE_ACCOUNT_INFO_SCHEMA);

    if (!Object.keys(errors).length) {
      try {
        if (idSearchParams === 'create-employ') {
          await createEmployByAdmin(stateEmploy);
        } else {
          const {
            id, firstName, lastName, status, role,
          } = stateEmploy;

          await updateEmployByAdminHandler(id, {
            firstName, lastName, status, role,
          });
        }
        closeModalWindowEmploy();
      } catch (e) {
        setAllErrors({ email: 'Email is already exist!' });
        // eslint-disable-next-line no-console
        console.error(e.message);
      }
    } else {
      setAllErrors(errors);
    }
  }, [closeModalWindowEmploy, createEmployByAdmin, idSearchParams, stateEmploy, updateEmployByAdminHandler]);

  useEffect(() => {
    if (idSearchParams !== 'create-employ') {
      getEmployeeByIdHandler(idSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data && !stateEmploy.id) {
      setInitStateEmploy(data);
    }
  }, [data, stateEmploy.id]);

  return {
    employData: stateEmploy,
    allErrors,
    changeInitStateEmploy,
    sendDataHandler,
    loadingCreateEmploy,
    loadingGetEmploy,
    loadingUpdateEmployByIdAdmin,
  };
};

export default useCreateEmployContainer;
