import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const TooltipComponent = ({
  title, overlayClassName, placement, children,
}) => (
  <Tooltip
    placement={placement}
    title={title}
    overlayClassName={overlayClassName}
    mouseEnterDelay={1}
  >
    {children}
  </Tooltip>
);

TooltipComponent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  overlayClassName: PropTypes.string,
  placement: PropTypes.string,
};
TooltipComponent.defaultProps = {
  overlayClassName: 'tooltipAdminTableColumns',
  placement: 'topLeft',
};

export default memo(TooltipComponent);
