import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { GetPromoCodes } from '../../../../api/PromoCodes/query';
import { limit } from '../../../../constants/tableConstants';
import { PROMO_IS_ACTIVE_KEY, SEARCH_FILTER_KEY } from '../../../../constants/general/filters';
import useLastPageChange from '../../../../hooks/useLastPageChange/useLastPageChange';

const useGetPromoCodes = () => {
  const [searchParams] = useSearchParams();

  const page = searchParams.get('page') || '1';

  const search = searchParams.get(SEARCH_FILTER_KEY);

  const isActive = JSON.parse(searchParams.get(PROMO_IS_ACTIVE_KEY));

  const {
    data, loading, error, refetch,
  } = useQuery(GetPromoCodes, {
    variables: {
      search: search || undefined,
      offset: (Number(page) - 1) * limit,
      limit,
      isActive,
    },
  });

  useLastPageChange(data);

  return {
    promoCodes: data || {
      findAllPromoCodesAdmin: {
        promoCodes: [],
      },
    },
    count: data?.findAllPromoCodesAdmin?.count,
    loading,
    error,
    refetch,
  };
};

export default useGetPromoCodes;
