import React, { useCallback } from 'react';
import { STATUSES_NAMES } from '../../../../constants/statuses';
import StatusTag from '../../StatusTag';
import DropdownBtnComponent from '../../../Common_components/DropdownBtnComponent';
import { PROMO_IS_ACTIVE_KEY, promoCodeFilters } from '../../../../constants/general/filters';
import DropdownWithClearComponent from '../../../Common_components/DropdownWithClearComponent';
import useSearchFilters from '../../../Coupon/CouponTable/hooks/filters/useSearchFilters';

const usePromoCodesTable = (deletePromoCodeById, dataSource, editModalPromoCodeHandler) => {
  const { searchParams, setSearchParams, dropdownValueHandler } = useSearchFilters();

  const promoStatusFilterHandler = (value) => dropdownValueHandler(
    value,
    PROMO_IS_ACTIVE_KEY,
  );

  const page = searchParams.get('page') || '1';

  const deletePromoCodeHandler = useCallback(async (idCode) => {
    await deletePromoCodeById(idCode);
    const newPage = dataSource?.length === 1 ? +page - 1 : page;
    const finalPage = newPage === 0 ? 1 : newPage;
    setSearchParams({
      page: finalPage,
    });
  }, [dataSource?.length, deletePromoCodeById, page, setSearchParams]);

  const openEditModal = useCallback((id) => {
    editModalPromoCodeHandler(id);
  }, [editModalPromoCodeHandler]);

  const columns = [
    {
      title: 'Number',
      dataIndex: 'number',
      width: '13%',
      ellipsis: true,
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      ellipsis: true,
    },
    {
      title: 'Sum, AED',
      dataIndex: 'sum',
      ellipsis: true,
    },
    {
      title: 'Due date',
      dataIndex: 'date',
      width: '13%',
      ellipsis: true,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Coupon/option',
      dataIndex: 'coupon',
      ellipsis: true,
    },
    {
      title: 'Note',
      dataIndex: 'note',
      width: '25%',
      ellipsis: true,
    },
    {
      title: () => (
        <div
          className="dropdownWrapperHeader"
        >
          <span>Status</span>
          <DropdownWithClearComponent
            items={promoCodeFilters}
            minWidth="130px"
            callback={promoStatusFilterHandler}
          />
        </div>
      ),
      dataIndex: 'isActive',
      render: (active) => (<StatusTag name={active ? STATUSES_NAMES.active : STATUSES_NAMES.deactivated} />),
      width: '120px',
    },
    {
      key: 'actions',
      width: '5%',
      render: (text, record) => (
        <DropdownBtnComponent
          text={text}
          record={record}
          actions={[
            {
              key: '0',
              title: 'Edit',
              callback: openEditModal,
            },
            {
              key: '2',
              title: 'Delete',
              callback: (id) => deletePromoCodeHandler(id),
            },
          ]}
        />
      ),
    },
  ];

  return {
    columns,
  };
};

export default usePromoCodesTable;
