import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UPDATE_USER_SELF } from '../../../../api/profile/query';
import { GET_USER_SELF } from '../../../../api/query';

const useSendMainInfo = () => {
  const [updateUserSelf, {
    data: updateDataUser,
    loading: updateUserLoading,
    error: updateUserError,

  }] = useMutation(UPDATE_USER_SELF, {
    refetchQueries: [GET_USER_SELF],
  });

  const navigate = useNavigate();

  const updateUserSelfHandler = useCallback((data) => {
    const { email, ...rest } = data;

    updateUserSelf({
      variables: {
        updateUserInput: rest,
      },
    });
  }, [updateUserSelf]);

  if (updateDataUser) {
    navigate(-1);
    return {
      updateDataUser,
      updateUserLoading,
      updateUserSelfHandler,
      updateUserError,
    };
  }

  return {
    updateUserSelfHandler,
    updateUserLoading,
    updateUserError,
  };
};

export default useSendMainInfo;
