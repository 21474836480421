import React from 'react';
import mobileCondition from '../../../constants/mobileCondition';
import FollowIcon from './FollowIcon/FollowIcon';
import s from './FollowUsBlock.module.scss';
import {
  FacebookFollow, InstagramFollow, PinterestIcon, TiktokFollow, YoutubeIcon,
} from '../../../constants/icons';

const FollowUsBlock = () => {
  const followClassName = `${s.follow} ${mobileCondition && s.mobile}`;

  const containerClassName = `${s.container} ${mobileCondition && s.mobile}`;

  return (
    <div className={followClassName}>
      <span>Follow us</span>
      <div className={containerClassName}>
        <a
          target="_blank"
          href="https://www.facebook.com/people/Yallabananaofficial/100086944201670/"
          rel="noreferrer"
        >
          <FollowIcon Icon={FacebookFollow} />
        </a>
        <a
          target="_blank"
          href="https://www.tiktok.com/@yallabananaofficial?lang=en"
          rel="noreferrer"
        >
          <FollowIcon Icon={TiktokFollow} />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/yallabananauae/"
          rel="noreferrer"
        >
          <FollowIcon Icon={InstagramFollow} />
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCoF43kEFkrhwFR7F8xdM28g"
          rel="noreferrer"
        >
          <FollowIcon Icon={YoutubeIcon} />
        </a>
        <a
          target="_blank"
          href="https://www.pinterest.com/YallaBananaOfficial/"
          rel="noreferrer"
        >
          <FollowIcon Icon={PinterestIcon} />
        </a>
      </div>
    </div>
  );
};

export default FollowUsBlock;
