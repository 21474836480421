import { gql } from '@apollo/client';

export const GET_COUPONS_IN_CART_COUNT = gql`
    query getAllCouponsInCart($promoCode: String) {
        getAllCouponsInCart(promoCode: $promoCode) {
            shoppingCartCoupons {
                amount
                coupon {
                    id
                    currentAvailableAmount
                }
            }
        }
    }
`;

export const GET_ALL_COUPONS_IN_CART = gql`
    query getAllCouponsInCart($promoCode: String) {
        getAllCouponsInCart(promoCode: $promoCode) {
            totalCostOfCouponsInCart
            totalOldCostOfCouponsInCart
            totalDiscountOfCouponsInCart
            totalPromoDiscountOfCouponsInCart
            totalVatOfCouponsInCart
            shoppingCartCoupons {
                totalCost
                totalDiscount
                promoCodeApplied
                id
                amount
                coupon {
                    id
                    vatPrice
                    currentPrice
                    oldPrice
                    publishedDueDate
                    title
                    currentAvailableAmount
                    availableAmountInPeriod
                    couponPreviewPicture {
                        key
                    }
                    couponOptions {
                        title
                        id
                        currentPrice
                        oldPrice
                    }
                }
                shoppingCartCouponOptions {
                    id
                    amount
                    couponOption {
                        id
                        title
                        currentPrice
                        clearPrice
                    }
                }
            }
            totalCouponsInCart
        }
    }
`;

export const REMOVE_COUPON_FROM_CART = gql`
    mutation removeCouponFromShoppingCart($id: ID!, $promoCode: String) {
        removeCouponFromShoppingCart(id: $id, promoCode: $promoCode) {
            totalCostOfCouponsInCart
            totalDiscountOfCouponsInCart
            shoppingCartCoupons {
                id
                amount
                coupon {
                    id
                    clearPrice
                    oldPrice
                    validDueDate
                    description
                    couponPreviewPicture {
                        key
                    }
                }
                shoppingCartCouponOptions {
                    id
                    amount
                    couponOption {
                        title
                        oldPrice
                        clearPrice
                        
                    }
                }
            }
        }
    }
`;

export const UPDATE_SHOPPING_CART_COUPON = gql`
    mutation updateShoppingCartCouponById(
        $promoCode: String, 
        $shoppingCartCouponUpdateInput: ShoppingCartCouponUpdateInput!, 
        $id: String!) {
            updateShoppingCartCouponById( 
                promoCode: $promoCode, 
                shoppingCartCouponUpdateInput: $shoppingCartCouponUpdateInput, 
                id: $id) {
                    totalCouponsInCart
            }
        }
`;

export const REMOVE_STOCK_COUPONS_FROM_CART = gql`
    mutation removeCouponsFromShoppingCartByIdArray($ids: [ID!]!) {
        removeCouponsFromShoppingCartByIdArray(ids: $ids) {
            totalCouponsInCart
        }
    }
`;

export const FIX_CART_ORDER = gql`
    mutation updateCouponsAmountInShoppingCartToMaxAvailableByIdArray(
        $updatesInput: [ShoppingCartCouponAmountUpdateInput!]!
    ) {
        updateCouponsAmountInShoppingCartToMaxAvailableByIdArray(updatesInput: $updatesInput) {
            totalCouponsInCart
        }
    }
`;
