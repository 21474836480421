import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_COUPON_BY_ID } from '../../../../../api/query';

const { REACT_APP_BUCKET_URL } = process.env;
const useGetCouponByAdminId = () => {
  const { id } = useParams();
  const idValue = id.slice(1);
  const { loading, error, data } = useQuery(GET_COUPON_BY_ID, {
    variables: {
      id: idValue,
    },
  });

  if (data) {
    //------
    const {
      couponOptions, couponGalleryPictures,
      merchant, category, subCategory, __typename, couponPreviewPicture, currentPrice, oldPrice, id,
      couponGoldenHours, createPrice, filialBranches, isHotDeal,
      ...rest
    } = data.getCouponByIdAdmin;

    const prevImageById = {
      id: '',
      key: '',
    };

    const galleryImagesId = couponGalleryPictures ? couponGalleryPictures.map((image) => {
      const {
        id, key, originalName, position,
      } = image;
      return {
        uid: id,
        name: originalName,
        url: `${REACT_APP_BUCKET_URL}/${key}`,
        position,
      };
    }).sort((a, b) => a.position - b.position) : null;

    const couponGoldenHoursCor = couponGoldenHours ? couponGoldenHours.map((coup) => {
      const { __typename, id, ...rest } = coup;
      return {
        ...rest,
        intervalId: id,
        isCheckedDay: true,
      };
    }) : null;

    if (couponPreviewPicture) {
      const { id: idPicture, key: keyPicture } = couponPreviewPicture;
      prevImageById.id = idPicture;
      prevImageById.key = `${REACT_APP_BUCKET_URL}/${keyPicture}`;
    }

    const couponInputById = {
      ...rest,
      isHotDeal,
      merchantId: merchant.id,
      categoryId: category.id,
      subCategoryId: subCategory?.id || null,
      filialBranches: filialBranches.map((address) => address.id),
      createPrice: createPrice / 100,
      oldPrice: oldPrice ? oldPrice / 100 : oldPrice,
      couponGoldenHours: couponGoldenHoursCor,
      couponGoldenHoursToCreate: [],
      couponGoldenHoursToUpdate: [],
      couponGoldenHoursToDelete: [],
      couponFilialBranchesToAdd: [],
      couponFilialBranchesToRemove: [],
    };

    //------
    const couponOptionsById = couponOptions ? couponOptions.map((coup) => {
      const {
        filialBranches, createPrice, oldPrice, discount, __typename, ...rest
      } = coup;

      const createPriceMyOptions = createPrice / 100;
      const oldPriceMyOptions = oldPrice ? oldPrice / 100 : null;

      const newFilialBranches = filialBranches.map((filial) => filial.id);
      return {
        ...rest,
        filialBranches: newFilialBranches,
        createPrice: createPriceMyOptions,
        oldPrice: oldPriceMyOptions,
        discount: discount === null ? 0 : discount,
        couponOptionFilialBranchesToAdd: [],
        couponOptionFilialBranchesToRemove: [],
      };
    }) : [];

    return {
      couponInputById,
      couponOptionsById: [...couponOptionsById],
      prevImageById,
      galleryImagesId,
      loadingGetCouponById: loading,
      errorGetCouponById: error,
      couponId: idValue,
    };
  }

  return {
    loadingGetCouponById: loading,
    errorGetCouponById: error,
  };
};
export default useGetCouponByAdminId;
