import React from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
} from 'react-router-dom';
import Layout from '../Layout';
import SignIn from '../SignIn';
import {
  ACCOUNT,
  ADMIN,
  AUTH,
  BANNERS,
  BLOG,
  BUYERS,
  CART,
  CATEGORIES,
  CHECK_EMAIL,
  CHECKOUT,
  CHECKOUT_FAILED,
  CHECKOUT_SIGN_IN,
  CHECKOUT_SIGN_UP,
  CHECKOUT_SUCCESS,
  COMMON,
  COMMON_SETTINGS,
  CONTACTS,
  COOKIES_INFO,
  COUPON_PAGE,
  COUPONS,
  COUPONS_PROFILE,
  CREATE_COUPON,
  CREATE_MERCHANT,
  EDIT_COUPON_BY_ID,
  EDIT_INFO,
  EDIT_MERCHANT_BY_ID,
  EDIT_PASSWORD,
  EMPLOYEES,
  FAQ,
  FORGOT_PASSWORD,
  LOGS,
  MERCHANTS,
  MOBILE_CATALOG,
  ORDERS,
  PROFILE,
  PROFILE_MERCHANT,
  PROMO_CODES,
  REPORTS,
  RESET_PASSWORD,
  SECURITY,
  SECURITY_AUTH,
  SHOW_INFO,
  SIGN_IN,
  SITE_SIGN_IN,
  SITE_SIGN_UP,
  TEMP_MAIN,
  TERMS,
  VOUCHERS,
} from '../../constants/Routes';
import Catalog from '../Catalog';
import Banners from '../../pages/Banners';
import Main from '../Main';
import { AuthAdminProvider } from '../../context/context-admin/hoc/AuthAdminContext';
import RequieredAuth from './RequireAuth/RequieredAuth';
import PromoCodesPage from '../../pages/PromoCodesPage/PromoCodesPage';
import CouponPage from '../CouponPage';
import Cart from '../Cart';
import mobileCondition from '../../constants/mobileCondition';
import MobileCatalogWrapper from '../MobileCatalogWrapper';
import Merchants from '../../pages/Merchants';
import EditMerchantContainer from '../Merchant/MerchantsMaking/EditMerchantContainer';
import CreateMerchantContainer from '../Merchant/MerchantsMaking/CreateMerchantContainer';
import Buyers from '../../pages/Byuers';
import Profile from '../Profile';
import AccountComponent from '../Profile/AccountComponent';
import ShowInformation from '../Profile/AccountComponent/ShowInformation';
import EditMainInformation from '../Profile/AccountComponent/EditMainInforamtion';
import EditPassword from '../Profile/AccountComponent/EditPassword';
import CouponsComponent from '../Profile/Coupons/CouponsComponent';
import Categories from '../../pages/Categories';
import Coupons from '../../pages/Coupons';
import CouponMaking from '../Coupon/CouponMaking';
import CouponEdit from '../Coupon/CouponMaking/CouponEdit';
import Settings from '../../pages/Settings';
import Employees from '../../pages/Employes/Employees';
import MobileProfile from '../MobileProfile';
import MobileAccount from '../MobileProfile/MobileAccount';
import MobileSignIn from '../MobileProfile/MobileSIgnIn';
import MobileSignUp from '../MobileProfile/MobileSignUp';
import ProfileRequireAuth from '../MobileProfile/ProfileRequireAuth/ProfileRequireAuth';
import MobileNotAuthRedirect from '../MobileProfile/MobileNotAuthRedirect';
import RouteLayout from '../MobileProfile/RouteLayout';
import MobileMyCoupons from '../MobileProfile/MobileMyCoupons';
import ActivatedVouchers from '../ActivatedVouchers';
import Checkout from '../CheckOut/Checkout';
import PaymentForm from '../CheckOut/Payment/PaymentForm/PaymentForm';
import FailedComponent from '../CheckOut/Payment/FailedComponent/FailedComponent';
import SuccessComponent from '../CheckOut/Payment/SuccessComponent/SuccessComponent';
import CheckoutSingUp from '../CheckOut/CheckoutSingUp/CheckoutSingUp';
import CheckoutSingIn from '../CheckOut/CheckoutSingIn/CheckoutSingIn';
import RequiredAuthUser from './RequiredAuthUser/RequiredAuthUser';
import OrdersPage from '../../pages/OrdersPage';
import Vouchers from '../../pages/Vouchers';
import Logs from '../../pages/Logs';
import ProfileMerchant from '../../pages/ProfileMerchant';
import SecuritySignIn from '../SecuritySignIn';
import SecurityLayout from '../SecurityLayout';
import SecurityNotAuthRedirect from '../SecurityLayout/SecurityNotAuthRedirect';
import SecurityMain from '../SecurityMain';
import CommonInfoLayout from '../CommonInfoLayout';
import Contacts from '../CommonInfoLayout/Contacts';
import CookiesInfo from '../CommonInfoLayout/CookiesInfo';
import VoucherRoute from '../VoucherRoute';
import ErrorPage from '../404/ErrorPage';
import TermsAndConditions from '../CommonInfoLayout/TermsAndConditions';
import Blog from '../CommonInfoLayout/Blog';
import Faq from '../CommonInfoLayout/Faq';
import SecuritySuccess from '../SecurityCheckout/SecuritySuccess';
import SecurityFailed from '../SecurityCheckout/SecurityFailed';
import SecurityVoucher from '../SecurityVoucher';
import SecurityScan from '../SecurityScan';
import SignInComponent from '../SignIn/SignInComponent';
import ForgotPassword from '../SignIn/ForgotPassord/ForgotPassword';
import CheckYourEmail from '../SignIn/CheckYourEmail';
import ResetPassword from '../SignIn/ResetPassword';
import SecurityOption from '../SecurityOption';
import CatalogRedirect from '../CatalogRedirect';
import Loader from '../Loader';

const LazyReports = React.lazy(() => import('../../pages/Reports'));

const AllRoutes = () => (
  <Routes>
    <Route
      path={`${ADMIN}`}
      element={(
        <AuthAdminProvider>
          <RequieredAuth>
            <Main />
          </RequieredAuth>
        </AuthAdminProvider>
      )}
    >
      <Route
        path="/admin"
        element={<Navigate to={COUPONS} />}
      />
      <Route
        path={PROFILE_MERCHANT}
        element={<ProfileMerchant />}
      />
      <Route
        path={MERCHANTS}
        element={<Merchants />}
      />
      <Route
        path={CREATE_MERCHANT}
        element={<CreateMerchantContainer />}
      />
      <Route
        path={EDIT_MERCHANT_BY_ID}
        element={<EditMerchantContainer />}
      />
      <Route
        path={COUPONS}
        element={<Coupons />}
      />
      <Route
        path={CREATE_COUPON}
        element={<CouponMaking />}
      />
      <Route
        path={EDIT_COUPON_BY_ID}
        element={<CouponEdit />}
      />
      <Route
        path={BUYERS}
        element={<Buyers />}
      />
      <Route
        path={VOUCHERS}
        element={<Vouchers />}
      />
      <Route
        path={LOGS}
        element={<Logs />}
      />
      <Route
        path={BANNERS}
        element={<Banners />}
      />
      <Route
        path={ORDERS}
        element={<OrdersPage />}
      />
      <Route
        path={REPORTS}
        element={(
          <React.Suspense fallback={<Loader />}>
            <LazyReports />
          </React.Suspense>
        )}
      />
      <Route
        path={PROMO_CODES}
        element={<PromoCodesPage />}
      />
      <Route
        path={COMMON_SETTINGS}
        element={<Settings />}
      />
      <Route
        path={CATEGORIES}
        element={<Categories />}
      />
      <Route
        path={EMPLOYEES}
        element={<Employees />}
      />
    </Route>
    <Route
      path={SIGN_IN}
      element={<SignIn />}
    >
      <Route
        index
        element={<SignInComponent />}
      />
      <Route
        path={FORGOT_PASSWORD}
        element={<ForgotPassword />}
      />
      <Route
        path={CHECK_EMAIL}
        element={<CheckYourEmail />}
      />
      <Route
        path={RESET_PASSWORD}
        element={<ResetPassword />}
      />
    </Route>
    <Route
      path=""
      element={<Layout />}
    >
      <Route
        index
        element={mobileCondition ? (
          <CatalogRedirect>
            <Catalog />
          </CatalogRedirect>
        ) : <Catalog />}
      />
      <Route
        path={MOBILE_CATALOG}
        element={<MobileCatalogWrapper />}
      />
      {mobileCondition && (
        <Route
          path={TEMP_MAIN}
          element={<Navigate to="/" />}
        />
      )}
      <Route
        path={COUPON_PAGE}
        element={<CouponPage />}
      />
      <Route
        path={CART}
        element={<Cart />}
      />
      {mobileCondition
        ? (
          <>
            <Route
              path={PROFILE}
              element={(
                <ProfileRequireAuth>
                  <RouteLayout />
                </ProfileRequireAuth>
              )}
            >
              <Route
                index
                element={<MobileProfile />}
              />
              <Route
                path={ACCOUNT}
                element={<MobileAccount />}
              />
              <Route
                path={COUPONS}
                element={<MobileMyCoupons />}
              />
              <Route
                path={VOUCHERS}
                element={<ActivatedVouchers />}
              />
            </Route>
            <Route
              path={AUTH}
              element={(
                <MobileNotAuthRedirect>
                  <RouteLayout />
                </MobileNotAuthRedirect>
              )}
            >
              <Route
                index
                element={<Navigate to={SITE_SIGN_IN} />}
              />
              <Route
                path={SITE_SIGN_IN}
                element={<MobileSignIn />}
              />
              <Route
                path={SITE_SIGN_UP}
                element={<MobileSignUp />}
              />
            </Route>
          </>
        )
        : (
          <>
            <Route
              path={PROFILE}
              element={<Navigate to="/profile/account" />}
            />
            <Route
              path={PROFILE}
              element={<Profile />}
            >
              <Route
                path={ACCOUNT}
                element={<AccountComponent />}
              >
                <Route
                  index
                  element={<ShowInformation />}
                />
                <Route
                  path={SHOW_INFO}
                  element={<ShowInformation />}
                />
                <Route
                  path={EDIT_INFO}
                  element={<EditMainInformation />}
                />
                <Route
                  path={EDIT_PASSWORD}
                  element={<EditPassword />}
                />
              </Route>
              <Route
                path={COUPONS_PROFILE}
                element={<CouponsComponent />}
              />
            </Route>
          </>
        )}
      <Route
        path={CHECKOUT}
        element={<Checkout />}
      >
        <Route
          index
          element={<RequiredAuthUser><PaymentForm /></RequiredAuthUser>}
        />
        <Route
          path={CHECKOUT_FAILED}
          element={<FailedComponent />}
        />
        <Route
          path={CHECKOUT_SUCCESS}
          element={<SuccessComponent />}
        />
        <Route
          path={CHECKOUT_SIGN_UP}
          element={<CheckoutSingUp />}
        />
        <Route
          path={CHECKOUT_SIGN_IN}
          element={<CheckoutSingIn />}
        />
      </Route>
      <Route
        path={COMMON}
        element={<CommonInfoLayout />}
      >
        <Route
          path={CONTACTS}
          element={<Contacts />}
        />
        <Route
          path={TERMS}
          element={<TermsAndConditions />}
        />
        <Route
          path={COOKIES_INFO}
          element={<CookiesInfo />}
        />
        <Route
          path={BLOG}
          element={<Blog />}
        />
        <Route
          path={FAQ}
          element={<Faq />}
        />
      </Route>
      <Route
        path="*"
        element={<ErrorPage />}
      />
    </Route>
    <Route
      path={SECURITY}
      element={<SecurityLayout />}
    >
      <Route
        path={SECURITY}
        element={(
          <SecurityNotAuthRedirect>
            <Outlet />
          </SecurityNotAuthRedirect>
        )}
      >
        <Route
          index
          element={<SecurityMain />}
        />
        <Route
          path={VOUCHERS}
          element={<ActivatedVouchers />}
        />
        <Route
          path="success"
          element={<SecuritySuccess />}
        />
        <Route
          path="scanner"
          element={<SecurityScan />}
        />
      </Route>
      <Route
        path={SECURITY_AUTH}
        element={<SecuritySignIn />}
      />
      <Route
        path="voucher/:id"
        element={<SecurityVoucher />}
      />
      <Route
        path="option/:voucherOptionId"
        element={<SecurityOption />}
      />
      <Route
        path="failed"
        element={<SecurityFailed />}
      />
    </Route>
    <Route
      path="/voucher/:voucherId"
      element={<VoucherRoute />}
    />
  </Routes>
);

export default AllRoutes;
