import React, { memo } from 'react';
import TextArea from 'antd/es/input/TextArea';
import PropTypes from 'prop-types';
import style from '../../../ModalForm.module.scss';

const CommentBlock = (props) => {
  const { allErrors, comment, setPromoCodeInfoHandler } = props;
  return (
    <>
      <TextArea
        autoSize
        maxLength={250}
        name="note"
        value={comment}
        onChange={(e) => setPromoCodeInfoHandler('comment', e.target.value)}
        style={{
          border: allErrors?.comment && !comment.trim()
            ? '1px solid #D00000'
            : '1px solid #EDEDED',
        }}
      />
      {allErrors?.comment && !comment.trim()
        && <span className={style.errorText}>{allErrors.comment}</span>}
    </>
  );
};

CommentBlock.propTypes = {
  comment: PropTypes.string.isRequired,
  setPromoCodeInfoHandler: PropTypes.func.isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
};
CommentBlock.defaultProps = {
  allErrors: null,
};
export default memo(CommentBlock);
