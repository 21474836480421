import React, { memo, useState } from 'react';
import { Layout, Menu } from 'antd';
import Icon from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import {
  adminMenuItems, merchantMenuItems, submenuAdminItems, submenuItems, superAdminMenuItems,
} from '../../constants/dataSidebar';
import {
  DownArrowFill, LeftArrow, LogOut, RightArrow, Settings,
} from '../../constants/icons';
import useLogoutUser from './hooks/useLogoutUser';
import User from './User/User';
import style from './Sidebar.module.scss';
import Loader from '../Loader';
import './sidebar-ant.scss';
import useAuthAdmin from '../../context/context-admin/useAuthAdmin';
import getActiveKeyOfSidebar from './getActiveKeyOfSidebar';
import { getRoleUser } from '../../helpers/user-storage';
import {
  ROLE_EMPLOY_MERCHANT,
  ROLE_EMPLOY_MERCHANT_SUPER,
  ROLE_EMPLOY_ADMIN,
  ROLE_EMPLOY_SUPER_ADMIN,
} from '../../constants/employ';
import { TITLE_COOKIES } from '../../constants/general/tag-status';

const { Sider } = Layout;
const { SubMenu, Item } = Menu;

const Sidebar = memo(() => {
  const [collapsed, setCollapsed] = useState(true);
  const [submenuOpened, setSubmenuOpened] = useState(false);
  const { logout, loading } = useLogoutUser();
  const { signOutAdmin } = useAuthAdmin();
  const { pathname } = useLocation();

  const logOutHandler = () => {
    signOutAdmin(async () => {
      await logout({
        variables: {
          refreshToken: Cookies.get(TITLE_COOKIES),
        },
      });
    });
  };

  const totalMenuItems = () => {
    switch (getRoleUser()) {
    case ROLE_EMPLOY_MERCHANT:
    case ROLE_EMPLOY_MERCHANT_SUPER:
      return merchantMenuItems;
    case ROLE_EMPLOY_ADMIN: return adminMenuItems;
    default: return superAdminMenuItems;
    }
  };

  const showActiveSidebarPoint = () => (pathname === '/admin/profile-merchant'
    ? null
    : getActiveKeyOfSidebar(pathname.slice(7)));

  const sideClassName = pathname === '/admin/profile-merchant'
    ? `${style.sidebar} ${(window.outerWidth <= 1460 && window.outerWidth > 1191) && style.zoomed} turnOfBgInSidebar`
    : `${style.sidebar} ${(window.outerWidth <= 1460 && window.outerWidth > 1191) && style.zoomed}`;

  return (
    <>
      {loading && <Loader />}
      <Sider
        className={sideClassName}
        collapsed={collapsed}
        width={280}
        collapsedWidth={62}
      >
        <User collapsed={collapsed} />
        <Menu
          mode="inline"
          inlineIndent={0}
          openKeys={submenuOpened && ['settings']}
          defaultSelectedKeys={showActiveSidebarPoint()}
        >
          {totalMenuItems().map(({
            key, icon, text, url,
          }) => (
            <Item
              key={key}
              icon={<Icon component={icon} />}
            >
              <Link to={url}>
                {text}
              </Link>
            </Item>
          ))}
          {(getRoleUser() === ROLE_EMPLOY_SUPER_ADMIN) && (
            <SubMenu
              key="settings"
              icon={<Icon component={Settings} />}
              title="Settings"
              mode="inline"
              expandIcon={<Icon component={DownArrowFill} />}
              onTitleClick={() => {
                setCollapsed(false);
                setSubmenuOpened(!submenuOpened);
              }}
            >
              {submenuItems.map(({
                key, icon, text, url,
              }) => (
                <Item
                  key={key}
                  icon={<Icon component={icon} />}
                >
                  <Link to={url}>
                    {text}
                  </Link>
                </Item>
              ))}
            </SubMenu>
          )}
          {(getRoleUser() === ROLE_EMPLOY_ADMIN) && (
            <SubMenu
              key="settings"
              icon={<Icon component={Settings} />}
              title="Settings"
              mode="inline"
              expandIcon={<Icon component={DownArrowFill} />}
              onTitleClick={() => {
                setCollapsed(false);
                setSubmenuOpened(!submenuOpened);
              }}
            >
              {submenuAdminItems.map(({
                key, icon, text, url,
              }) => (
                <Item
                  key={key}
                  icon={<Icon component={icon} />}
                >
                  <Link to={url}>
                    {text}
                  </Link>
                </Item>
              ))}
            </SubMenu>
          )}
        </Menu>
        <Menu
          className={style.controls}
          mode="inline"
        >
          <Item
            key="logout"
            icon={<Icon component={LogOut} />}
            onClick={logOutHandler}
          >
            Logout
          </Item>
          <Item
            className="arrowBtnSidebar"
            key="collapse"
            icon={(
              <Icon component={collapsed ? RightArrow : LeftArrow} />
            )}
            onClick={() => (
              submenuOpened ? setTimeout(() => setCollapsed(!collapsed), 150) : setCollapsed(!collapsed))
            && (!collapsed && setSubmenuOpened(false)
            )}
          />
        </Menu>
      </Sider>
    </>
  );
});

export default Sidebar;
