import React, { memo, useContext, useRef } from 'react';
import {
  Button, Checkbox, Form, Input,
} from 'antd';
import { Link } from 'react-router-dom';
import Modal from '../../Modal';
import s from './SignInModal.module.scss';
import { Cross } from '../../../constants/icons';
import useLoginMutation from '../../../hooks/useLoginMutation/useLoginMutation';
import Loader from '../../Loader';
import UserContext from '../../Layout/context/userContext';
import GoogleButton from '../../Common_components/GoogleButton';

const SignInModal = () => {
  const { isSignInActive, setIsSignInActive, goToSignUpModalOnClick } = useContext(UserContext);

  const [form] = Form.useForm();

  const inputRef = useRef(null);

  const { sendLoginData, loginLoading, fetchFromLocalLoading } = useLoginMutation();

  const showModalOnClick = () => {
    inputRef.current.focus({ cursor: 'end' });
    setIsSignInActive(true);
  };

  const sendLoginCallback = async (values) => {
    try {
      await sendLoginData(values);
      setIsSignInActive(false);
    } catch (e) {
      const error = e.message === 'User not found' ? 'Wrong Email or Password!' : e.message;
      form.setFields([{ name: 'email', errors: [error] }]);
    }
  };

  const hideModalHandler = () => {
    form.resetFields();
    setIsSignInActive(false);
  };

  const isLoadingNow = loginLoading || fetchFromLocalLoading;

  return (
    <>
      {isLoadingNow && <Loader />}
      <Modal
        isActive={isSignInActive}
        hideModalHandler={hideModalHandler}
      >
        <div className={s.singInContainer}>
          <span className={s.title}>
            Sign In
          </span>
          <div
            className={s.cross}
            onClick={hideModalHandler}
          >
            <Cross />
          </div>
        </div>
        <Form
          form={form}
          className={s.form}
          layout="vertical"
          onFinish={sendLoginCallback}
        >
          <Form.Item
            name="email"
            label="Email or username"
            className={s.item}
            rules={[{ required: true, message: 'Please, enter email or username' }]}
          >
            <Input
              name="email"
              ref={inputRef}
              className={s.input}
            />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            className={s.item}
            rules={[{ required: true, message: 'Please, enter your password' }]}
          >
            <div className={s.passwordContainer}>
              <Link
                to="/admin/signIn/forgot-password"
                className={s.forgot}
              >
                Forgot password?
              </Link>
              <Input.Password
                type="password"
                name="password"
                className={s.input}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox
              name="remember"
              className={s.checkbox}
            >
              <span className={s.checkbox}>
                Remember me
              </span>
            </Checkbox>
          </Form.Item>
          <Form.Item className={s.item}>
            <Button
              className={s.submitButton}
              type="primary"
              htmlType="submit"
              disabled={loginLoading}
            >
              Sign In
            </Button>
          </Form.Item>
        </Form>
        <GoogleButton form={form} />
        <div className={s.memberBlock}>
          <span className={s.label}>
            Not a member?
          </span>
          <span
            onClick={goToSignUpModalOnClick}
            className={s.signUp}
          >
            Sign Up
          </span>
        </div>
      </Modal>
      <button
        className={s.button}
        onClick={showModalOnClick}
      >
        Sign In
      </button>
    </>
  );
};

export default memo(SignInModal);
