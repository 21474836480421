import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Form, Input } from 'antd';
import s from '../countingBody.module.scss';
import { finallyBorderInput } from '../../Coupon/helpers/finallyBorder';
import { currentAndOldPrice, currentPriceByDiscount } from '../../../../../constants/math';
import NumericInput from '../../../../Common_components/InputNumberComponent';

const ByDiscount = (props) => {
  const {
    correctPrice,
    couponInput,
    vatValue,
    allErrors,
    callbackHandler,
  } = props;

  return (
    <>
      {' '}
      <Form.Item label="Old Price, AED">
        <NumericInput
          keyValue="createPrice"
          value={couponInput.createPrice}
          callbackHandler={(key, value) => {
            correctPrice(couponInput.createPrice, key, value);
          }}
          style={{
            border: allErrors?.createPrice && !+couponInput.createPrice
              ? '1px solid #D00000' : '1px solid #EDEDED',
            height: '40px',
            borderRadius: '4px',
          }}
          keyOperation="price"
        />
        {allErrors?.createPrice && !+couponInput.createPrice
            && <span className="errorCouponText">{allErrors.createPrice}</span>}
      </Form.Item>
      <Form.Item
        label="Discount"
      >
        <NumericInput
          style={{
            border: allErrors?.discount
                        && couponInput.countPriceByDiscount && finallyBorderInput(
              couponInput.discount
                            && +couponInput.discount <= 100,
            ),
            height: '40px',
            borderRadius: '4px',
          }}
          keyValue="discount"
          value={couponInput.discount}
          callbackHandler={(key, value) => correctPrice(couponInput.discount, key, value)}
          disabled={!couponInput.countPriceByDiscount}
          keyOperation="discount"
        />

        {allErrors?.discount && couponInput.countPriceByDiscount
                && !couponInput.discount
                && <span className="errorCouponText">This field is required</span>}

      </Form.Item>
      <Form.Item label={(
        <div className={s.label}>
          <p>Price, AED</p>
          <p>[1500-1500*(30/100)]</p>
        </div>
      )}
      >
        <NumericInput
          keyValue="oldPrice"
          style={{
            border: allErrors?.oldPrice && !couponInput.countPriceByDiscount
              ? finallyBorderInput(+couponInput.oldPrice !== 0
                            && +couponInput.oldPrice >= couponInput.createPrice)
              : finallyBorderInput(true),
            height: '40px',
            borderRadius: '4px',
          }}
          value={currentPriceByDiscount(couponInput.createPrice, couponInput.discount)}
          callbackHandler={(key, value) => correctPrice(couponInput.oldPrice, key, value)}
          disabled={couponInput.countPriceByDiscount}
          keyOperation="price"
        />
      </Form.Item>
      <Form.Item
        name="checkbox-group"
        label={`Use VAT (${vatValue}%)`}
      >
        <Checkbox
          checked={couponInput.applyVat}
          onChange={(e) => callbackHandler('applyVat', e.target.checked)}
        />
      </Form.Item>
      <Form.Item
        label={(
          <div className={s.label}>
            <p>Coupon price, AED</p>
            <p>(1050+5%)</p>
          </div>
        )}
      >
        <Input
          disabled
          value={!couponInput.countPriceByDiscount
            ? couponInput.oldPrice
            : currentAndOldPrice(currentPriceByDiscount(
              couponInput.createPrice,
              couponInput.discount,
            ), couponInput.applyVat, vatValue)}
          style={{
            height: '40px',
            border: '1px solid #EDEDED',
            borderRadius: '4px',
          }}
        />
      </Form.Item>
    </>
  );
};

ByDiscount.propTypes = {
  couponInput: PropTypes.oneOfType([PropTypes.object]).isRequired,
  vatValue: PropTypes.number,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
  correctPrice: PropTypes.func.isRequired,
  callbackHandler: PropTypes.func.isRequired,
};
ByDiscount.defaultProps = {
  vatValue: undefined,
  allErrors: undefined,
};

export default memo(ByDiscount);
