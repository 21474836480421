import {
  useCallback, useRef, useEffect, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import useSendMerchantInfo from '../useSendMerchantInfo/useSendMerchantInfo';

const useGetMerchantInfo = (initStateInfo, subDivisionsInitState) => {
  const { id: idUser } = useParams();

  const [stateInfo, setStateInfo] = useState(initStateInfo);
  const {
    sendData,
    deleteFilialBranch,
    updateFilialBranch,
    loadingCreate,
    loadingUpdate,
    createFilialBranch,
    allErrors,
    errorUpdate,
    errorCreate,
  } = useSendMerchantInfo();

  const [subDivisionsInfo, setSubDivisionsInfo] = useState(subDivisionsInitState);

  const isValidMerchantInfo = useRef(false);

  const setStateInfoHandler = useCallback((key, value) => {
    setStateInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const addSubDivisionsInfoHandler = useCallback(async (obj) => {
    if (idUser) {
      try {
        await createFilialBranch(obj);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e.message);
      }
    } else {
      setSubDivisionsInfo((prev) => [...prev, obj]);
    }
  }, [createFilialBranch, idUser]);

  const deleteSubDivisionsInfoHandler = useCallback(async (id) => {
    if (idUser) {
      try {
        await deleteFilialBranch(id);
        setSubDivisionsInfo(subDivisionsInfo.filter((sub) => sub.id !== id));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e.message);
      }
    } else {
      setSubDivisionsInfo(subDivisionsInfo.filter((sub) => sub.id !== id));
    }
  }, [deleteFilialBranch, idUser, subDivisionsInfo]);

  const editSubDivisionsInfoHandler = useCallback(async (item) => {
    if (idUser) {
      try {
        await updateFilialBranch(item);
        setSubDivisionsInfo(subDivisionsInfo.map((sub) => (sub.id === item.id ? { ...item } : sub)));
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e.message);
      }
    } else {
      setSubDivisionsInfo(subDivisionsInfo.map((sub) => (sub.id === item.id ? { ...item } : sub)));
    }
  }, [idUser, subDivisionsInfo, updateFilialBranch]);

  // ---send on server
  const sendDataHandler = useCallback(() => {
    const dataForSend = {
      stateInfo,
      subDivisionsInfo,
    };
    sendData(dataForSend);
  }, [sendData, stateInfo, subDivisionsInfo]);

  useEffect(() => {
    if (idUser && !isEqual(subDivisionsInitState, subDivisionsInfo)) {
      setSubDivisionsInfo(subDivisionsInitState);
    }
  }, [idUser, subDivisionsInfo, subDivisionsInitState]);

  return {
    stateInfo,
    setStateInfo,
    subDivisionsInfo,
    setStateInfoHandler,
    addSubDivisionsInfoHandler,
    sendDataHandler,
    deleteSubDivisionsInfoHandler,
    editSubDivisionsInfoHandler,
    loadingCreate,
    loadingUpdate,
    isValidMerchantInfo: isValidMerchantInfo.current,
    allErrors,
    errorUpdate,
    errorCreate,
  };
};
export default useGetMerchantInfo;
