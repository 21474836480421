import React, { memo } from 'react';
import { Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import { EDIT_INFO, EDIT_PASSWORD } from '../../../../constants/Routes';
import useGetAccountInfo from '../../hooks/useGetAccountInfo';
import Loader from '../../../Loader/Loader';

const { Item } = Form;

const ShowInformation = () => {
  const {
    userData,
    getUserDataLoading,
  } = useGetAccountInfo();

  if (getUserDataLoading) {
    return <Loader />;
  }
  const {
    firstName, lastName, email, phone,
  } = userData;

  return (
    <>
      <h6 className="account-title">
        Account information
      </h6>
      {
        getUserDataLoading
          ? <Loader />
          : (
            <Form
              className="account-form show-form"
              labelCol={{
                className: 'account_label-col show-label',
                span: 7,
              }}
              wrapperCol={{
                className: 'account_wrapper-col show wrapper',
                span: 17,
              }}
            >
              <Item
                label="Name"
                className="account-label-class show-label"
              >
                <Input
                  disabled
                  value={firstName || '-'}
                  maxLength={30}
                />
              </Item>
              <Item
                label="Last name"
                className="account-label-class show-label"
              >
                <Input
                  disabled
                  value={lastName || '-'}
                  maxLength={30}
                />
              </Item>
              <Item
                label="Email"
                className="account-label-class show-label"
              >
                <Input
                  disabled
                  type="email"
                  value={email}
                />
              </Item>
              <Item
                label="Phone (optional)"
                className="account-label-class show-label"
              >
                {
                  phone ? (
                    <PhoneInput
                      value={phone}
                      disabled
                      inputClass="account-label-class__tel-input"
                      buttonStyle={{
                        display: 'none',
                      }}
                      dropdownStyle={{
                        display: 'none',
                      }}
                      inputStyle={{
                        border: 'none',
                        cursor: 'text',
                        background: 'transparent',
                        boxShadow: 'none',
                      }}
                    />
                  )
                    : <div className="account-phone__empty">-</div>
                }

              </Item>
              <div className="account-btns">
                <Link
                  to={`${EDIT_INFO}`}
                  className="btnLink active"
                >
                  Edit
                </Link>
                <Link
                  to={EDIT_PASSWORD}
                  className="btnLink cancel"
                >
                  Edit password
                </Link>
              </div>
            </Form>
          )
      }
    </>
  );
};

export default memo(ShowInformation);
