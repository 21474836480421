import { useQuery } from '@apollo/client';
import { GetEmployeeSelf } from '../../../../api/query';

const useGetInfoUser = () => {
  const { data, error, loading } = useQuery(GetEmployeeSelf);

  if (data) {
    const { getEmployeeSelf } = data;
    return {
      error,
      loading,
      userInfo: getEmployeeSelf,
    };
  }
  return {
    error,
    loading,
  };
};

export default useGetInfoUser;
