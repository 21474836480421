import React, { memo } from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import style from '../../../ModalForm.module.scss';
import SuffixInput from '../../../../Common_components/SuffixInput';
import { DiscountUnits } from '../../../../../constants/promoCodes/promoCodes';

const PrecentageBlock = (props) => {
  const {
    discountUnit,
    setPromoCodeInfoHandler,
    discount,
    allErrors,
  } = props;

  const changeDiscountUnit = () => {
    setPromoCodeInfoHandler('discountUnit', discountUnit === DiscountUnits.ABSOLUTE
      ? DiscountUnits.PERCENTS
      : DiscountUnits.ABSOLUTE);
    setPromoCodeInfoHandler('discount', '');
  };
  const setCorrectPrice = (field, key, value) => {
    if (field === '0') {
      setPromoCodeInfoHandler(key, field === '0.' ? value : value.slice(1));
      return;
    }
    setPromoCodeInfoHandler(key, value);
  };

  return (
    <Radio.Group
      value={discountUnit}
      className={style.discount}
      name="discountUnit"
      onChange={changeDiscountUnit}
    >
      <Radio
        className={style.radioPercents}
        value={DiscountUnits.PERCENTS}
      >
        Percentage
      </Radio>
      <div className={style.inputContainer}>
        <SuffixInput
          value={discountUnit === DiscountUnits.PERCENTS ? discount : ''}
          disabled={discountUnit !== DiscountUnits.PERCENTS}
          onChange={(number) => setCorrectPrice(discount, 'discount', number)}
          suffix="%"
          discountUnit={discountUnit}
          error={allErrors?.discount}
          className={allErrors?.discount && discountUnit === DiscountUnits.PERCENTS
            && !discount && style.errorInput}
        />
        {allErrors?.discount && discountUnit === DiscountUnits.PERCENTS && !discount
          && <span className={`${style.errorText} ${style.suf}`}>{allErrors.discount}</span>}
      </div>
      <Radio value={DiscountUnits.ABSOLUTE}>Sum</Radio>
      <div className={style.inputContainer}>
        <SuffixInput
          value={discountUnit === DiscountUnits.ABSOLUTE ? discount : ''}
          disabled={discountUnit !== DiscountUnits.ABSOLUTE}
          onChange={(number) => setCorrectPrice(discount, 'discount', number)}
          suffix="AED"
          discountUnit={discountUnit}
          error={allErrors?.discount}
          className={allErrors?.discount && discountUnit === DiscountUnits.ABSOLUTE && !discount && style.errorInput}
        />
        {allErrors?.discount && discountUnit === DiscountUnits.ABSOLUTE && !discount
          && <span className={`${style.errorText} ${style.suf}`}>{allErrors.discount}</span>}
      </div>
    </Radio.Group>
  );
};

PrecentageBlock.propTypes = {
  discountUnit: PropTypes.string.isRequired,
  setPromoCodeInfoHandler: PropTypes.func.isRequired,
  discount: PropTypes.string,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
};
PrecentageBlock.defaultProps = {
  allErrors: null,
  discount: '',
};

export default memo(PrecentageBlock);
