import React from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';

import style from './PageControls.module.scss';
import ButtonComponent from '../../Common_components/ButtonComponent';

const PageControls = ({
  title, onAdd,
}) => (
  <div className={style.controls}>
    <Space size={20}>
      <span className={style.title}>
        {title}
      </span>
      {onAdd && (
        <ButtonComponent
          title="Add"
          callback={onAdd}
        />
      )}
    </Space>
  </div>
);

PageControls.propTypes = {
  title: PropTypes.string.isRequired,
  onAdd: PropTypes.func,
};

PageControls.defaultProps = {
  onAdd: null,
};

export default PageControls;
