import React, { useCallback } from 'react';
import { Typography } from 'antd';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import useUpdateCouponStatusAdmin from '../../hooks/gql/useUpdateCouponStatusAdmin';
import { CloseEye, CopyIcon, OpenEye } from '../../../../../constants/icons';
import useCreateCouponCopyByAdmin from '../../hooks/gql/useCreateCouponCopyAdmin';
import { roundingPrice } from '../../../../../constants/math';
import useDeleteCoupon from '../../hooks/gql/useDeleteCoupon';
import DropdownBtnComponent from '../../../../Common_components/DropdownBtnComponent';
import { FORMAT_DATE_TABLE, STATUS_TABLE_ACTIVE } from '../../../../../constants/couponsAdmin';
import { getRoleUser } from '../../../../../helpers/user-storage';
import { MERCHANT_ADMIN, MERCHANT_SUPER_ADMIN } from '../../../../../constants/admin-roles';
import DropdownWithClearComponent
  from '../../../../Common_components/DropdownWithClearComponent';
import {
  CATEGORY_ID_FILTER_KEY,
  defaultFilters,
  IS_PUBLISHED_FILTER_KEY,
  PURCHASES_FILTER_KEY,
  stateFilters,
  SUB_CATEGORY_FILTER_KEY,
  UPDATED_AT_KEY,
  VIEWS_FILTER_KEY,
} from '../../../../../constants/general/filters';
import useSearchFilters from '../../hooks/filters/useSearchFilters';
import DoubleDateTableWrapper from '../../../../Common_components/DoubleDateForTableWrapper';
import copyToClipboard from '../../../../../helpers/copyToClipboard';
import TooltipComponent from '../../../../Common_components/TooltipComponent';
import infinityIcon from '../../../../../assets/images/Infinity.svg';

const useGetActiveColumn = (coupons, filterCategories, filterSubCategories, showMessageIdOfCouponWasCopied) => {
  const { updateCouponByAdmin } = useUpdateCouponStatusAdmin();
  const { deleteCouponByAdmin } = useDeleteCoupon();
  const { createCopyCoupon } = useCreateCouponCopyByAdmin();
  const role = getRoleUser();

  const { searchParams, setSearchParams, dropdownValueHandler } = useSearchFilters();

  const page = searchParams.get('page') || '1';
  const status = searchParams.get('status') || STATUS_TABLE_ACTIVE;

  const navigate = useNavigate();
  const editCoupon = useCallback((id) => {
    navigate(`edit-coupon/:${id}`);
  }, [navigate]);

  const copiedId = useCallback(async (id) => {
    await copyToClipboard(id);
    showMessageIdOfCouponWasCopied(id);
  }, [showMessageIdOfCouponWasCopied]);

  const deleteCoupon = useCallback(async (id) => {
    try {
      await deleteCouponByAdmin(id);
      if (status === STATUS_TABLE_ACTIVE) {
        const newPage = coupons?.length === 1 ? +page - 1 : page;
        const finalPage = newPage === 0 ? 1 : newPage;
        setSearchParams({
          ...Object.entries(searchParams),
          status,
          page: finalPage,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [coupons, deleteCouponByAdmin, page, searchParams, setSearchParams, status]);

  const updateCouponStatusHandler = async (id, status, isPublished) => {
    try {
      await updateCouponByAdmin(id, status, isPublished);
      if (status === STATUS_TABLE_ACTIVE) {
        const newPage = coupons?.length === 1 ? +page - 1 : page;
        const finalPage = newPage === 0 ? 1 : newPage;
        setSearchParams({
          ...Object.entries(searchParams),
          status,
          page: finalPage,
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  };

  const categoryFilterHandler = (value) => dropdownValueHandler(value, CATEGORY_ID_FILTER_KEY);

  const subCategoryFilterHandler = (value) => dropdownValueHandler(value, SUB_CATEGORY_FILTER_KEY);

  const viewsFilterHandler = (value) => dropdownValueHandler(
    value,
    VIEWS_FILTER_KEY,
    [PURCHASES_FILTER_KEY, UPDATED_AT_KEY],
  );

  const purchasesFilterHandler = (value) => dropdownValueHandler(
    value,
    PURCHASES_FILTER_KEY,
    [VIEWS_FILTER_KEY, UPDATED_AT_KEY],
  );

  const stateFilterHandler = (value) => dropdownValueHandler(value, IS_PUBLISHED_FILTER_KEY);

  const nameColumn = {
    title: 'Name',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    width: '14%',
    render: (text) => <Typography.Text>{text}</Typography.Text>,
  };

  const numberColumn = {
    title: 'Coupon ID',
    dataIndex: 'id',
    key: 'id',
    width: '7%',
    render: (id) => (
      <TooltipComponent
        title={id}
        placement="bottomLeft"
        overlayClassName="tooltipAdminTableColumns maxWidthTooltip"
      >
        <div className="fieldWithIdAdminTable">
          <Typography.Text>
            {id.slice(0, 5)}
            ...
          </Typography.Text>
          <span
            className="copyIdAdminTablesIcon"
            onClick={() => copiedId(id)}
          >
            <CopyIcon />
          </span>
        </div>
      </TooltipComponent>
    ),
  };

  const dateDropdownColumn = {
    title: <DoubleDateTableWrapper />,
    dataIndex: 'createdAt',
    key: 'date',
    ellipsis: true,
    width: '10%',
    render: (date) => (
      <Typography.Text>
        {date ? moment(date).format(FORMAT_DATE_TABLE) : '-'}
      </Typography.Text>
    ),

  };

  const categoryDropdownColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Category</span>
        <DropdownWithClearComponent
          items={filterCategories}
          callback={categoryFilterHandler}
        />
      </div>
    ),
    dataIndex: 'category',
    key: 'category',
    ellipsis: true,
    width: '10%',
    render: (category) => (
      <Typography.Text>
        {category.title}
      </Typography.Text>
    ),
  };

  const subCategoryDropdownColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Subcategory</span>
        <DropdownWithClearComponent
          items={filterSubCategories}
          callback={subCategoryFilterHandler}
        />
      </div>
    ),
    dataIndex: 'subCategory',
    key: 'subCategory',
    ellipsis: true,
    width: '10%',
    render: (subCategory) => (
      <Typography.Text>
        {subCategory?.title || '-'}
      </Typography.Text>
    ),
  };

  const companyNameColumn = {
    title: 'Company name',
    dataIndex: 'merchant',
    key: 'merchant',
    ellipsis: true,
    width: '13%',
    render: (merchant) => (
      <Typography.Text>
        {merchant.title}
      </Typography.Text>
    ),
  };

  const couponPriceColumn = {
    title: 'Coupon price, AED',
    dataIndex: 'currentPrice',
    key: 'currentPrice',
    ellipsis: true,
    width: '10%',
    render: (currentPrice) => (
      <Typography.Text>
        {roundingPrice(currentPrice / 100)}
      </Typography.Text>
    ),
  };

  const viewsColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Views</span>
        <DropdownWithClearComponent
          items={defaultFilters}
          minWidth="130px"
          callback={viewsFilterHandler}
        />
      </div>
    ),
    dataIndex: 'couponViewsCount',
    key: 'couponViewsCount',
    ellipsis: true,
    width: '9%',
    render: (couponViewsCount) => <Typography.Text>{couponViewsCount}</Typography.Text>,
  };

  const purchasesColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>Purchases</span>
        <DropdownWithClearComponent
          items={defaultFilters}
          minWidth="130px"
          callback={purchasesFilterHandler}
        />
      </div>
    ),
    dataIndex: 'couponPurchasesCount',
    key: 'couponPurchasesCount',
    ellipsis: true,
    width: '8%',
    render: (couponPurchasesCount) => <Typography.Text>{couponPurchasesCount}</Typography.Text>,
  };

  const remainsColumn = {
    title: 'Remains',
    dataIndex: 'currentAvailableAmount',
    key: 'currentAvailableAmount',
    width: '8%',
    render: (amount, { availableAmountInPeriod }) => (
      <Typography.Text>
        {availableAmountInPeriod ? amount : (
          <img
            src={infinityIcon}
            alt="infinity"
          />
        )}
      </Typography.Text>
    ),
  };

  const stateColumn = {
    title: () => (
      <div
        className="dropdownWrapperHeader"
      >
        <span>State</span>
        <DropdownWithClearComponent
          items={stateFilters}
          minWidth="150px"
          callback={stateFilterHandler}
        />
      </div>
    ),
    dataIndex: 'isPublished',
    key: 'state',
    width: '6%',
    render: (isPublished, record) => (
      <div
        style={{ color: '#A8A8A8', cursor: 'pointer' }}
        onClick={() => updateCouponStatusHandler(record.id, 'ACTIVE', !isPublished)}
      >
        {
          isPublished
            ? <OpenEye />
            : <CloseEye />
        }
      </div>
    ),
  };

  const dropdownColumn = {
    key: 'actions',
    width: '5%',
    render: (text, record) => (
      <DropdownBtnComponent
        text={text}
        record={record}
        actions={[
          {
            key: '0',
            title: 'Edit',
            callback: editCoupon,
          },
          {
            key: '1',
            title: 'Copy',
            callback: createCopyCoupon,
          },
          {
            key: '2',
            title: 'Archive',
            callback: (id) => updateCouponStatusHandler(id, 'ARCHIVED', false),
          },
          {
            key: '3',
            title: 'Delete',
            callback: deleteCoupon,
          },
        ]}
      />
    ),
  };

  if (role !== MERCHANT_ADMIN && role !== MERCHANT_SUPER_ADMIN) {
    return [
      nameColumn,
      numberColumn,
      dateDropdownColumn,
      categoryDropdownColumn,
      subCategoryDropdownColumn,
      companyNameColumn,
      couponPriceColumn,
      viewsColumn,
      purchasesColumn,
      remainsColumn,
      stateColumn,
      dropdownColumn,
    ];
  }
  return [
    nameColumn,
    numberColumn,
    dateDropdownColumn,
    categoryDropdownColumn,
    subCategoryDropdownColumn,
    couponPriceColumn,
    viewsColumn,
    remainsColumn,
    stateColumn,
    dropdownColumn,
  ];
};

export default useGetActiveColumn;
