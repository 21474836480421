import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import getImgBase64Url from '../helpers/getImgBase64Url';
import s from './Icon.module.scss';

const Icon = ({ url, name, defaultIcon }) => {
  const [result, setResult] = useState('');

  if (url) getImgBase64Url(url).then((res) => setResult(res));

  return (
    <span className={s.span}>
      {result
        ? (
          <img
            className={s.icon}
            src={result}
            alt={name}
          />
        ) : defaultIcon}
    </span>
  );
};

Icon.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string.isRequired,
  defaultIcon: PropTypes.node,
};

Icon.defaultProps = {
  defaultIcon: undefined,
  url: undefined,
};

export default memo(Icon);
