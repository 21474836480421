import React, { memo } from 'react';
import {
  Modal,
} from 'antd';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../../constants/icons';
import exelIcon from '../../../assets/images/exelIcon.png';
import './modal.scss';
import ButtonComponent from '../ButtonComponent';
import Portal from '../Portal';

const ModalFileDownload = ({
  isOpenModal, closeModalHandler, customersExcelKey, titleDocument,
}) => (
  <Portal>
    <Modal
      centered
      title="Export to Excel"
      closeIcon={<CloseIcon />}
      visible={isOpenModal}
      onOk={closeModalHandler}
      onCancel={closeModalHandler}
      wrapClassName="wrapper"
      width={440}
      footer={null}
    >
      <div>
        <div className="body">
          <div>
            <img
              src={exelIcon}
              alt="exel"
            />
          </div>
          <div className="text">
            <p>
              Your file
              <span className="titleDocument">{titleDocument}</span>
              {' '}
              is ready to download.
            </p>
            <p>Click on download</p>
          </div>
        </div>
        <div className="btn-container">
          <a
            href={customersExcelKey}
          >
            <ButtonComponent
              title="Download"
              callback={closeModalHandler}
              iconComponent={null}
            />
          </a>
        </div>
      </div>
    </Modal>
  </Portal>
);

ModalFileDownload.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
  titleDocument: PropTypes.string.isRequired,
  customersExcelKey: PropTypes.string,
};
ModalFileDownload.defaultProps = {
  customersExcelKey: '#',
};

export default memo(ModalFileDownload);
