import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from '../../../Common_components/ButtonComponent';
import SearchInputComponent from '../../../Common_components/SearchInputComponent';
import './header-component.scss';
import useDebouncedSearch from '../../../../hooks/useDebouncedSearch/useDebouncedSearch';

const HeaderComponent = () => {
  const { search, onFilterChange, onEnterFilterChange } = useDebouncedSearch();
  const navigate = useNavigate();

  const callbackBtn = () => {
    navigate('create-coupon');
  };

  return (
    <header className="header">
      <div className="header__row">
        <div className="header__left">
          <h1 className="header__title">Coupons</h1>
          <ButtonComponent
            title="Add"
            callback={callbackBtn}
          />
        </div>
        <SearchInputComponent
          placeholder="Search..."
          value={search}
          callback={onFilterChange}
          onEnterCallback={onEnterFilterChange}
        />
      </div>
    </header>
  );
};
export default HeaderComponent;
