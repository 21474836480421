import { gql } from '@apollo/client';

export const FETCH_COUPONS = gql`
    query findAllCouponsFilter($offset: Float, $limit: Float, $couponFilter: CouponFilter) {
        findAllCouponsFilter(offset: $offset, limit: $limit, couponFilter: $couponFilter) {
            count
            coupons {
                id
                title
                oldPrice
                vatPrice
                createPrice
                currentPrice
                discount
                validDueDate
                publishedDueDate
                keywordsImages
                couponOptions {
                    id
                    title
                    vatPrice
                    currentPrice
                    oldPrice
                }
                category {
                    title
                }
                subCategory {
                    id
                    title
                }
                description
                couponPreviewPicture {
                    key
                }
                couponGalleryPictures {
                    id
                    key
                }
            }
        }
    }
`;

export const GET_CATEGORIES = gql`
    query {
        getCategories {
            id
            title
            couponsCount
            showInHeader
            isPublished
            categoryPicture {
                key
                mimetype
            }
            subCategories {
                id
                title
                position
                parentCategoryId
                couponsCount
                categoryPicture {
                    key
                    mimetype
                }
            }
        }
    }
`;

export const ADD_COUPON_TO_CART = gql`
    mutation addCouponToShoppingCart($shoppingCartCouponInput: ShoppingCartCouponCreateInput!) {
        addCouponToShoppingCart(shoppingCartCouponInput: $shoppingCartCouponInput)
    }
`;
