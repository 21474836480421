import React, { memo, useMemo } from 'react';
import { Form } from 'antd';
import './profile-info.scss';
import EditInput from './EditInput';
import EditPasswordComponent from './EditPasswordComponent';
import useAuthAdmin from '../../../context/context-admin/useAuthAdmin';
import { getRoleUser } from '../../../helpers/user-storage';
import { ROLE_EMPLOY_MERCHANT, ROLE_EMPLOY_MERCHANT_SUPER, ROLE_EMPLOY_SUPER_ADMIN } from '../../../constants/employ';
import MerchantInfo from './MerchantInfo';
import UpdatePriceNotifications from './UpdatePriceNotifications';

const ProfileInfo = () => {
  const { userInfo } = useAuthAdmin();

  const fullName = useMemo(() => {
    if (userInfo) {
      const { firstName, lastName } = userInfo;
      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      }
      return firstName || lastName || '-';
    }
    return '-';
  }, [userInfo]);

  return (
    <div>
      {(getRoleUser() === ROLE_EMPLOY_MERCHANT
      || getRoleUser() === ROLE_EMPLOY_MERCHANT_SUPER) && userInfo?.merchant
        ? <MerchantInfo />
        : (userInfo && (
          <Form
            layout="vertical"
            className="merchant-profile-form"
            labelCol={{
              className: 'profile-merchant_label-col',
            }}
            wrapperCol={{
              className: 'profile-merchant-col',
            }}
          >
            <EditInput
              label="Full name"
              value={fullName}
              inputVariant="input"
              keyField="fullName"
              disabledInput
            />
            <EditInput
              label="Email"
              value={userInfo?.email}
              inputVariant="input"
              keyField="email"
              disabledInput
            />
            <EditInput
              label="Role"
              value={userInfo?.role}
              inputVariant="input"
              keyField="role"
              disabledInput
            />
            <EditPasswordComponent />
          </Form>
        )
        )}
      {getRoleUser() === ROLE_EMPLOY_SUPER_ADMIN && <UpdatePriceNotifications />}
    </div>
  );
};

export default memo(ProfileInfo);
