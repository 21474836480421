import React, { memo, useEffect, useState } from 'react';
import { Upload, message } from 'antd';
import './PreviewImage.scss';
import './DragAndDropStyles.scss';
import PropTypes from 'prop-types';
import { Close } from '../../../../constants/icons';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isSvg = file.type === 'image/svg+xml';

  if (!isSvg) {
    return message.error('You can only upload SVG file!');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    return message.error('Image must smaller than 2MB!');
  }

  return isSvg && isLt2M;
}

const PreviewImage = ({ prevImage, setPrevImage }) => {
  const [state, setState] = useState({
    loading: false,
    imageUrl: prevImage || '',
  });

  useEffect(() => {
    setState({
      loading: false,
      imageUrl: prevImage || '',
    });
  }, [prevImage]);

  const handleChange = ({ file }) => {
    const { originFileObj } = file;

    setPrevImage(originFileObj);
    getBase64(originFileObj, (imageUrl) => setState((prev) => ({
      ...prev,
      imageUrl,
      loading: false,
    })));
  };

  const deleteImage = () => {
    setPrevImage(null);
    setState({
      loading: false,
      imageUrl: '',
    });
  };

  return (
    <div className={`uploadImageContainer ${state.imageUrl && 'hasImage'}`}>
      <Upload
        name="avatar"
        listType="picture-card"
        className="uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={handleChange}
        accept="image/svg+xml"
      >
        {state.imageUrl ? (
          <img
            className="image"
            src={state.imageUrl}
            alt="avatar"
            style={{ width: '100%' }}
          />
        ) : (
          <div className="upload_body">
            <h5 className="upload_title">
              Drag & drop or
              {' '}
              <u>Upload file</u>
            </h5>
            <h6 className="upload_subtitle">Supports: SVG</h6>
          </div>
        )}
      </Upload>
      {state.imageUrl && (
        <div
          className="deleteImage"
          onClick={deleteImage}
        >
          <Close color="#333333" />
        </div>
      )}
    </div>
  );
};

PreviewImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  prevImage: PropTypes.object,
  setPrevImage: PropTypes.func,
};

PreviewImage.defaultProps = {
  prevImage: null,
  setPrevImage: null,
};

export default memo(PreviewImage);
