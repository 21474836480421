import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';
import { setAccessTokenUser, setRoleUser } from '../../helpers/user-storage';
import { GET_USER_SELF, LOGIN } from '../../api/query';
import useAddCouponsFromLocal from '../../components/Header/hooks/useAddCouponsFromLocal/useAddCouponsFromLocal';
import UserContext from '../../components/Layout/context/userContext';
import { GET_COUPONS_IN_CART_COUNT } from '../../api/cart-api/query';
import mobileCondition from '../../constants/mobileCondition';
import { ForceUpdateContext } from '../../components/ForceUpdateWrapper/ForceUpdateWrapper';
import { SETTING_OF_COOKIES, TITLE_COOKIES } from '../../constants/general/tag-status';
import { LocationContext } from '../../components/MobileProfile/MobileNotAuthRedirect/MobileNotAuthRedirect';
import { ROLE_CUSTOMER } from '../../constants/employ';

const useLoginMutation = () => {
  const navigate = useNavigate();

  const redirectPath = useContext(LocationContext);

  const { isRerenderNeed, setIsRerenderNeed } = useContext(ForceUpdateContext);

  const { fetchUser, processingCallback, clearProcessingCallback } = useContext(UserContext);

  const [loginUser, { data: loginData, loading: loginLoading }] = useMutation(LOGIN, {
    refetchQueries: [GET_COUPONS_IN_CART_COUNT, GET_USER_SELF],
  });

  const state = useRef({});

  const { uploadCoupons, fetchFromLocalLoading } = useAddCouponsFromLocal();

  useEffect(async () => {
    if (isRerenderNeed) {
      const { from } = state.current || {};
      await uploadCoupons();
      fetchUser();
      if (processingCallback) {
        processingCallback();
        clearProcessingCallback();
      } else if (mobileCondition) {
        navigate(from || redirectPath || '/profile');
      }
      setIsRerenderNeed(false);
    }
  }, [isRerenderNeed]);

  const sendLoginData = async (values, stateFrom) => {
    const { email, password } = values;
    const { data: { login: { accessToken, refreshToken, role } } } = await loginUser({
      variables: {
        loginUserInput: {
          email: email.trim().toLowerCase(),
          password,
        },
      },
    });
    if (role === ROLE_CUSTOMER) {
      state.current = stateFrom;
      setRoleUser(role);
      setAccessTokenUser(accessToken);
      Cookies.set(TITLE_COOKIES, refreshToken, SETTING_OF_COOKIES);
      setIsRerenderNeed(true);
    } else {
      throw new Error('You are not a customer');
    }
  };

  return {
    sendLoginData,
    loginData,
    loginLoading,
    fetchFromLocalLoading,
  };
};

export default useLoginMutation;
