import React, { memo } from 'react';
import CouponsHeader from './CouponsHeader';
import CouponsTable from './CouponsTable';
import './coupons.scss';

const CouponsComponent = () => (
  <section className="coupons-section">
    <CouponsHeader />
    <CouponsTable />
  </section>
);

export default memo(CouponsComponent);
