import React, { memo, useContext, useState } from 'react';
import { Select } from 'antd';
import s from './Options.module.scss';
import MappedOptions from '../MappedOptions';
import CouponContext from '../context/CouponContext';
import { SelectArrow } from '../constants/icons';
import MapContext from '../context/MapContext';

const Options = () => {
  const { couponOptions } = useContext(CouponContext);

  const { setMapDataCallback } = useContext(MapContext);

  const { Option } = Select;

  const [selectedValues, setSelectedValues] = useState(couponOptions);

  const addresses = couponOptions.map(({ filialBranches }) => (filialBranches.map(({ address }) => (address)))).flat(1);

  const uniqueAddresses = new Set(addresses);
  const optionValues = ['All', ...uniqueAddresses];

  const handleChange = (value) => {
    const filteredValues = couponOptions
      .filter(({ filialBranches }) => filialBranches.some(({ address }) => value === address));
    setSelectedValues(value === 'All' ? couponOptions : filteredValues);
    setMapDataCallback(value);
  };

  const mappedAddresses = optionValues.map((address) => (
    <Option key={address}>
      {address}
    </Option>
  ));

  return (
    <div className={s.container}>
      <span className={s.header}>
        Options
      </span>
      <span className={s.subtitle}>
        Check the availability of the option by selecting the address
      </span>
      <Select
        suffixIcon={<SelectArrow />}
        onChange={handleChange}
        defaultValue="Select address"
        className={s.select}
      >
        {mappedAddresses}
      </Select>
      {selectedValues && <MappedOptions options={selectedValues} />}
    </div>
  );
};

export default memo(Options);
