import { gql } from '@apollo/client';

export const GeneratePromoCodeSequence = gql`
    query {
        generatePromoCodeSequence
    }
`;

export const GetCoupons = gql`
    query {
        findAllCouponsAdmin(
            status: ACTIVE
        ) {
            coupons {
                id
                title
            }
        }
    }
`;

export const GetCouponOptions = gql`
    query GetCouponOptions($id: ID!) {
        getCouponByIdAdmin(id: $id) {
            couponOptions {
                id
                title
            }
        }
    }
`;

export const CREATE_PROMO_CODE_ADMIN = gql`
    mutation createPromoCodeAdmin($createPromoCodeInput: CreatePromoCodeInput!) {
        createPromoCodeAdmin(createPromoCodeInput: $createPromoCodeInput) {
            id
        }
    }
`;

export const GetPromoCodes = gql`
    query GetPromoCodes($limit: Float, $offset: Float, $isActive: Boolean) {
        findAllPromoCodesAdmin(limit: $limit, offset: $offset, isActive: $isActive) {
            promoCodes {
                id
                promoCode
                discountUnit
                discount
                validDueDate
                usesCount
                availableAmount
                coupon {
                    id
                    title
                }
                couponOption {
                    id
                    title
                }
                comment
                isActive
            }
            count
        }
    }
`;

export const DeletePromoCodeById = gql`
    mutation DeletePromoCodeById($id: ID!) {
        deletePromoCodeByIdAdmin(id: $id)
    }
`;

export const UPDATE_PROMO_CODE_BY_ID_ADMIN = gql`
    mutation updatePromoCodeByIdAdmin($updatePromoCodeInput: UpdatePromoCodeInput! $id: ID!) {
        updatePromoCodeByIdAdmin(updatePromoCodeInput: $updatePromoCodeInput id: $id) {
            id
        }
    }
`;

export const COUPONS_TITLE = gql`
    query couponsTitle($offset: Float, $limit: Float,  $couponTitleFilter: CouponTitleFilter!) {
        couponsTitle(offset:$offset, limit:$limit, couponTitleFilter: $couponTitleFilter) {
            coupons {
                id
                title
            }
            count
        }
    }
`;

export const COUPONS_OPTIONS_TITLE = gql`
    query couponOptionsTitle($couponId: String!) {
        couponOptionsTitle(couponId: $couponId) {
            id
            title
        }
    }
`;

export const GET_PROMO_CODE_BY_ID_ADMIN = gql`
    query getPromoCodeByIdAdmin($id: ID!) {
        getPromoCodeByIdAdmin(id: $id) {
            id
            promoCode
            isActive
            discount
            discountUnit
            validDueDate
            availableAmount
            comment
            couponId
            couponOptionId
        }
    }
`;
