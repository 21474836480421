import React from 'react';
import mobileCondition from '../mobileCondition';

export const infoBigFish = 'THE BIG FISH ONLINE SERVICES DMCC';
export const infoTradeLicense = 'Trade License No. JLT-68783';
export const infoLocationAddress = 'Office 2402 Fortune Executive Tower,';
export const infoLocationMap = 'Cluster T, JLT, Dubai, UAE';
export const infoLocationFull = 'Office 2402 Fortune Executive Tower, Cluster T, JLT, Dubai, UAE';
export const infoPhone = '+971 45571981';
export const infoMail = 'info@yallabanana.com';
export const infoServiceTimeInfo = [
  'Our Operating Timings:',
  'Mondays to Thursdays - 8ꓽ00 AM to 4ꓽ00 PM',
  'Fridays - 8ꓽ00 AM to 1ꓽ00 PM',
];
export const infoSubText = (
  <span>
    Any inquiries received outside working hours will be answered
    {' '}
    {!mobileCondition && <br />}
    {' '}
    by the next 24 hours or working day.
  </span>
);
export const infoFaq = 'To help you answer some questions, please visit our FAQ page.';
