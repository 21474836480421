import React, { memo } from 'react';
import './failed-body.scss';
import mobileCondition from '../../../../../constants/mobileCondition';

const FailedBody = () => {
  const bodyClassName = `failed-body ${mobileCondition ? 'mobile' : ''}`;
  return (
    <div className={bodyClassName}>
      <p>
        The transaction failed due to technical error.
      </p>
      <p>
        If your money was debited, you should
      </p>
      <p>
        get a refound within the next 24 hrs.
      </p>
    </div>
  );
};

export default memo(FailedBody);
