import {
  Image, Page, Text, View,
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import {
  addInformation,
  additional,
  addPhone,
  address,
  addressSubtitle,
  addressTitle,
  company,
  companySubtitle,
  companyTitle,
  conditionView,
  couponInfo,
  customerData,
  dataBlock,
  dataText,
  dataTitle,
  descriptionText,
  descriptionTitle,
  optionDescription,
  descriptionView,
  header,
  couponMainInfo,
  couponMainDescription,
  headerSubtitle,
  headerTitle,
  infoDescription,
  infoHeader,
  information,
  infoText,
  infoTitle,
  logo,
  merchant,
  page, pageNumber, pageTitle, pagination,
  preview,
  qrCodeSize,
  security,
  short,
  shortTitle,
  titles,
  footer,
} from './pdfStyles';
import pdfLogo from '../../assets/images/pdfLogo.png';
import breakStringLine from '../../helpers/pdfFunctions/breakStringLine';

const MyDoc = ({
  title, previewSrc, propsCompanyTitle, propsCompanyAddress, fixedCost, fixedDate, qrCodeUrl,
  description, terms, voucherNumber, bookingPhoneNumber, additionalInformation, promoCode, fixedVat, isOptionVoucher,
  optionTitle,
}) => {
  const fixedAddress = breakStringLine(propsCompanyAddress, 30);

  const styleAddInformation = {
    ...addInformation,
    marginTop: bookingPhoneNumber ? '20px' : '0',
  };

  return (
    <Page style={page}>
      <View
        style={header}
        fixed
      >
        <Image
          style={logo}
          src={pdfLogo}
        />
        <View style={titles}>
          <Text style={headerTitle}>
            YallaBanana
          </Text>
          <Text style={headerSubtitle}>
            If you love great deals, you&apos;ll love me
          </Text>
        </View>
        <View style={pagination}>
          <Text style={pageTitle}>
            Voucher
          </Text>
          <Text
            style={pageNumber}
            render={({ subPageNumber, subPageTotalPages }) => (
              `${subPageNumber} / ${subPageTotalPages}`
            )}
          />
        </View>
      </View>
      <View style={couponMainInfo}>
        <View style={couponMainDescription}>
          <View style={short}>
            <View style={couponInfo}>
              <View style={infoHeader}>
                <Text style={shortTitle}>
                  Voucher
                </Text>
              </View>
              <View style={infoDescription}>
                <Text style={descriptionTitle}>
                  Coupon
                </Text>
                <Text style={descriptionText}>
                  {title}
                </Text>
              </View>
            </View>
            <Image
              style={qrCodeSize}
              src={qrCodeUrl}
            />
          </View>
          {
            isOptionVoucher && (
              <View style={optionDescription}>
                <Text style={descriptionTitle}>
                  Option
                </Text>
                <Text style={descriptionText}>
                  {optionTitle}
                </Text>
              </View>
            )
          }
          <View style={customerData}>
            <View style={dataBlock}>
              <Text style={dataTitle}>
                Payment date
              </Text>
              <Text style={dataText}>
                {fixedDate}
              </Text>
            </View>
            <View style={dataBlock}>
              <Text style={dataTitle}>
                Total Value
              </Text>
              <Text style={dataText}>
                {fixedCost}
              </Text>
            </View>
            {
              fixedVat && (
                <View style={dataBlock}>
                  <Text style={dataTitle}>
                    VAT
                  </Text>
                  <Text style={dataText}>
                    {fixedVat}
                  </Text>
                </View>
              )
            }

            <View style={dataBlock}>
              <Text style={dataTitle}>
                Voucher ID
              </Text>
              <Text style={dataText}>
                {voucherNumber}
              </Text>
            </View>
            {promoCode && (
              <View style={dataBlock}>
                <Text style={dataTitle}>
                  Promo code
                </Text>
                <Text style={dataText}>
                  {promoCode}
                </Text>
              </View>
            )}
          </View>
        </View>
        <Image
          style={preview}
          src={previewSrc}
        />
      </View>
      <View style={information}>
        <View style={descriptionView}>
          <Text style={infoTitle}>
            OFFER HIGHLIGHTS
          </Text>
          <Text style={infoText}>
            {description}
          </Text>
        </View>
        <View style={conditionView}>
          <Text style={infoTitle}>
            THE FINE PRINT
          </Text>
          <Text style={infoText}>
            {terms}
          </Text>
        </View>
      </View>

      {(!!bookingPhoneNumber || !!additionalInformation) && (
        <View style={additional}>
          {!!bookingPhoneNumber && (
            <View style={addPhone}>
              <Text style={infoTitle}>
                Booking phone number
              </Text>
              <Text style={infoText}>
                {bookingPhoneNumber}
              </Text>
            </View>
          )}
          {!!additionalInformation && (
            <View style={styleAddInformation}>
              <Text style={infoTitle}>
                Additional information
              </Text>
              <Text style={infoText}>
                {additionalInformation}
              </Text>
            </View>
          )}
        </View>
      )}

      <View
        fixed
        style={footer}
      >
        <View
          style={merchant}
        >
          <View style={company}>
            <Text style={companySubtitle}>
              Company
            </Text>
            <Text style={companyTitle}>
              {propsCompanyTitle}
            </Text>
          </View>
          <View style={address}>
            <Text style={addressSubtitle}>
              Address
            </Text>
            <Text style={addressTitle}>
              {fixedAddress}
            </Text>
          </View>
        </View>
        <View
          style={security}
        >
          <Text>
            We are not keeping sensitive personal information
          </Text>
        </View>
      </View>
    </Page>
  );
};

MyDoc.propTypes = {
  previewSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  propsCompanyTitle: PropTypes.string.isRequired,
  propsCompanyAddress: PropTypes.string.isRequired,
  fixedDate: PropTypes.string.isRequired,
  fixedCost: PropTypes.string.isRequired,
  fixedVat: PropTypes.string.isRequired,
  voucherNumber: PropTypes.string.isRequired,
  qrCodeUrl: PropTypes.string.isRequired,
  terms: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  additionalInformation: PropTypes.string,
  bookingPhoneNumber: PropTypes.string,
  promoCode: PropTypes.string,
  isOptionVoucher: PropTypes.bool.isRequired,
  optionTitle: PropTypes.string,
};

MyDoc.defaultProps = {
  additionalInformation: '',
  bookingPhoneNumber: '',
  optionTitle: '',
  promoCode: null,
};

export default memo(MyDoc);
