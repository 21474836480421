import { useQuery } from '@apollo/client';
import { GetCommonSettings } from '../../../../api/CommonSettings/query';

const useGetCommonSettings = () => {
  const { data } = useQuery(GetCommonSettings);

  return {
    commonSettings: data?.getCommonSettings,
  };
};

export default useGetCommonSettings;
