import React, { memo } from 'react';
import {
  CardCvcElement, CardNumberElement, CardExpiryElement,
} from '@stripe/react-stripe-js';
import './payment-body.scss';

const PaymentBody = () => {
  // stripe.com/docs/js
  const cardElementsOptions = {
    classes: {
      webkitAutofill: 'StripeElement--webkit-autofill',
    },
    // add styles into iframe
    style: {
      base: {
        fontFamily: 'Inter, sans-serif',
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        '::placeholder': {
          color: '#8E8E8E',
        },
      },
      invalid: {
        color: '#D00000',
        iconColor: '#D00000',
        ':focus': {
          color: 'red',
        },
      },
      complete: {

      },
      hidePostalCode: true, // уберутся cvc после заполнения
    },
  };
  return (
    <div className="payment-body">
      <div className="payment-body__top">
        <div className="payment-body__item">
          <div className="payment-body__label">Card number</div>
          <CardNumberElement
            className="payment-body__element"
            options={cardElementsOptions}
          />
        </div>
      </div>
      <div className="payment-body__bottom">
        <div className="payment-body__item">
          <div className="payment-body__label">Expire date</div>
          <CardExpiryElement
            className="payment-body__element"
            options={cardElementsOptions}
          />
        </div>
        <div className="payment-body__item">
          <div className="payment-body__label">CVC/CVV</div>
          <CardCvcElement
            className="payment-body__element"
            options={cardElementsOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(PaymentBody);
