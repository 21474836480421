import React, { memo, useState } from 'react';
import { Upload } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import './PreviewImage.scss';
import ImgCrop from 'antd-img-crop';
import bannerSize from '../../../constants/banners/bannerSize';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const PreviewImage = ({
  prevImage, setPrevImage, addClass, placeHolder, aspect, containerClass, rotate, zoom, setBannerSizeError,
  resetSizeError,
}) => {
  const [state, setState] = useState({
    loading: false,
    imageUrl: prevImage?.key || '',
  });

  const deleteImage = () => {
    setPrevImage(null);
    setState({
      loading: false,
      imageUrl: '',
    });
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const test = async (e) => {
    const { file } = e;
    getBase64(file, (imageUrl) => setState((prev) => ({
      ...prev,
      imageUrl,
      loading: false,
    })));
    setPrevImage(file);
  };

  const className = `${containerClass}`;

  return (
    <div className={className}>
      <ImgCrop
        rotate={rotate}
        aspect={aspect}
        modalOk="Save"
        zoom={zoom}
        beforeCrop={(file) => {
          if (file.size > bannerSize) {
            setBannerSizeError();
            return false;
          }
          resetSizeError?.();
          return true;
        }}
      >
        <Upload
          name="avatar"
          listType="picture-card"
          className={`uploader ${addClass}`}
          showUploadList={false}
          customRequest={test}
          accept="image/jpeg, image/png"
          onPreview={onPreview}
        >
          {state.imageUrl ? (
            <div
              className="prevImage"
            >
              <img
                src={state.imageUrl}
                alt="avatar"
              />
            </div>
          ) : (
            <div className="upload_body">
              <h5 className="upload_title">
                Drag & drop or
                <span>Upload file</span>
              </h5>
              <h6 className="upload_subtitle">{placeHolder}</h6>
            </div>
          )}
        </Upload>
      </ImgCrop>
      {state.imageUrl && (
        <div
          className="deleteImage"
          onClick={deleteImage}
        >
          <CloseOutlined className="iconDelete" />
        </div>
      )}
    </div>
  );
};
PreviewImage.propTypes = {
  prevImage: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setPrevImage: PropTypes.func.isRequired,
  addClass: PropTypes.string,
  containerClass: PropTypes.string,
  placeHolder: PropTypes.string,
  setBannerSizeError: PropTypes.func.isRequired,
  resetSizeError: PropTypes.func,
  aspect: PropTypes.number,
  zoom: PropTypes.bool,
  rotate: PropTypes.bool,
};
PreviewImage.defaultProps = {
  resetSizeError: undefined,
  addClass: '',
  containerClass: 'uploadImageBanner',
  placeHolder: 'Supports: JPEG, JPG, PNG',
  aspect: 1.777,
  zoom: true,
  rotate: true,
};

export default memo(PreviewImage);
