import { useQuery } from '@apollo/client';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { GET_CATEGORIES } from '../../../../api/catalog-api/query';
import { MAIN } from '../../../../constants/Routes';
import { Hot } from '../../../../constants/icons';
import { HOT_DEALS } from '../../../../constants/admin-roles';

const useFetchCategoriesContext = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { pathname } = useLocation();

  const fixedSetSearchParams = (p) => {
    const keys = Object.keys(p);
    const newParams = keys.reduce((acc, el) => {
      const valueByKey = p[el];
      return valueByKey ? { ...acc, [el]: valueByKey } : acc;
    }, {});
    setSearchParams(newParams);
  };

  const catalogRef = useRef(null);

  const scrollOnCouponsTop = () => catalogRef.current.scrollIntoView(true);

  const categoryId = searchParams.get('categoryId');

  const { data, loading } = useQuery(GET_CATEGORIES);

  const [openedCategories, setOpenedCategories] = useState(categoryId ? [categoryId] : []);

  const openCategoryDropdown = (categoryId) => {
    setOpenedCategories((values) => {
      if (!values.some((id) => id === categoryId)) {
        if (values) {
          return [...values, categoryId];
        }
        return [categoryId];
      }
      return values;
    });
  };

  const closeCategoryDropdown = (categoryId) => {
    setOpenedCategories((values) => {
      if (values) {
        return values.filter((id) => id !== categoryId);
      }
      return [];
    });
  };

  const [categories, setCategories] = useState(null);

  const fetchAllCategoriesCouponsCallback = () => {
    setOpenedCategories([]);
    setSearchParams({ });
    if (pathname === MAIN) {
      scrollOnCouponsTop();
    }
  };

  useEffect(() => {
    const hotDealsCategory = {
      title: HOT_DEALS,
      subCategories: [],
      showInHeader: true,
      couponsCount: undefined,
      categoryPicture: {
        icon: <Hot />,
      },
      isPublished: true,
      id: 'hot-deals',
    };
    if (data) setCategories([hotDealsCategory, ...data.getCategories]);
  }, [data]);

  return useMemo(() => ({
    categories,
    loading,
    openedCategories,
    setOpenedCategories,
    openCategoryDropdown,
    closeCategoryDropdown,
    scrollOnCouponsTop,
    catalogRef,
    setSearchParams: fixedSetSearchParams,
    fetchAllCategoriesCouponsCallback,
  }), [categories, loading, openedCategories]);
};

export default useFetchCategoriesContext;
