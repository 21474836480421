import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './MobileCartOptionItem.module.scss';
import CartQuantityControls from '../../CartQuantityControls';

const MobileCartOptionItem = ({
  cartId, optionId, currentPrice, orderHandler, title, quantity,
}) => {
  const fixedPrice = `${currentPrice / 100} AED`;
  return (
    <div className={s.item}>
      <span className={s.title}>{title}</span>
      <div className={s.info}>
        <span className={s.cost}>{fixedPrice}</span>
        <CartQuantityControls
          quantity={quantity}
          orderHandler={orderHandler}
          itemId={cartId}
          optionId={optionId}
        />
      </div>
    </div>
  );
};

MobileCartOptionItem.propTypes = {
  quantity: PropTypes.number.isRequired,
  orderHandler: PropTypes.func.isRequired,
  cartId: PropTypes.string.isRequired,
  optionId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  currentPrice: PropTypes.number.isRequired,
};

export default memo(MobileCartOptionItem);
