import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './MobileOptionItem.module.scss';

const MobileOptionItem = ({ address, onOptionChange }) => (
  <div
    onClick={() => onOptionChange(address)}
    className={s.item}
  >
    {address}
  </div>
);

MobileOptionItem.propTypes = {
  address: PropTypes.string.isRequired,
  onOptionChange: PropTypes.func.isRequired,
};

export default memo(MobileOptionItem);
