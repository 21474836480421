import React, { useState } from 'react';
import { STATUSES_NAMES } from '../../../../../constants/statuses';
import StatusTag from '../../../../../components/StatusTag';
import { DownArrow } from '../../../../../constants/icons';
import useDeleteCategory from '../useDeleteCategory';
import Name from '../../../Name';
import DropdownBtnComponent from '../../../../../components/Common_components/DropdownBtnComponent';
import DropdownWithClearComponent from '../../../../../components/Common_components/DropdownWithClearComponent';
import { categoriesFilters, IS_PUBLISHED_FILTER_KEY } from '../../../../../constants/general/filters';
import useSearchFilters from '../../../../../components/Coupon/CouponTable/hooks/filters/useSearchFilters';

const useCategoriesTable = (categories, refetch) => {
  const { deleteCategory } = useDeleteCategory(refetch);

  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [addModalProps, setAddModalProps] = useState({ parentCategoryId: null });

  const showAddModal = (id) => {
    setAddModalProps({
      parentCategoryId: id,
    });

    setIsAddModalVisible(true);
  };

  const addModalHandleOk = () => {
    setIsAddModalVisible(false);
    refetch();
  };

  const addModalHandleCancel = () => {
    setIsAddModalVisible(false);
  };

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editModalProps, setEditModalProps] = useState({
    id: '',
    published: false,
    name: '',
    showInHeader: false,
    position: 0,
    parentCategoryId: null,
  });

  const showEditModal = (id) => {
    const category = categories.find(({ id: categoryId }) => id === categoryId)
        || categories.reduce((acc, el) => el.subCategories.find(({
          id: subCategoryId,
        }) => subCategoryId === id) || acc, {});
    const {
      isPublished,
      title,
      position,
      showInHeader,
      parentCategoryId,
      categoryPicture,
    } = category;
    setEditModalProps({
      id,
      published: isPublished,
      name: title,
      position,
      showInHeader,
      parentCategoryId,
      categoryPicture,
    });

    setIsEditModalVisible(true);
  };

  const editModalHandleOk = () => {
    setIsEditModalVisible(false);
    refetch();
  };

  const editModalHandleCancel = () => {
    setIsEditModalVisible(false);
  };

  const { dropdownValueHandler } = useSearchFilters();

  const filterCategoriesCallback = (value) => dropdownValueHandler(value, IS_PUBLISHED_FILTER_KEY);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'title',
      width: '25%',
      render: (text, record) => (
        <Name
          text={text}
          record={record}
        />
      ),
      ellipsis: true,
    },
    {
      title: 'Position',
      dataIndex: 'position',
      ellipsis: true,
      width: '15%',
      render: (position, { parentCategoryId }) => {
        if (parentCategoryId) {
          const [parentCategory] = categories.filter(({ id }) => id === parentCategoryId);
          const parentPosition = parentCategory.position;
          const firstPositionPart = parentPosition < 10 ? `0${parentPosition}` : parentPosition;
          const secondPositionPart = position < 100 ? `0${position}` : position;

          return `${firstPositionPart}-${position < 10 ? `00${position}` : secondPositionPart}`;
        }

        return position < 10 ? `0${position}` : position;
      },
    },
    {
      title: 'Coupons',
      dataIndex: 'couponsCount',
      ellipsis: true,
      width: '15%',
      render: (count) => (count || '-'),
    },
    {
      title: () => (
        <div
          className="dropdownWrapperHeader"
        >
          <span>Status</span>
          <DropdownWithClearComponent
            items={categoriesFilters}
            minWidth="150px"
            callback={filterCategoriesCallback}
          />
        </div>
      ),
      dataIndex: 'isPublished',
      width: '40%',
      render: (published) => (<StatusTag name={published ? STATUSES_NAMES.visible : STATUSES_NAMES.hidden} />),
      filterIcon: <DownArrow />,
    },
    {
      width: '5%',
      render: (_, record) => {
        const items = [
          { key: '0', title: 'Add subcategory', callback: (record) => showAddModal(record) },
          { key: '1', title: 'Edit', callback: (record) => showEditModal(record) },
          { key: '2', title: 'Delete', callback: (record) => deleteCategory(record) },
        ];

        return (
          <DropdownBtnComponent
            record={record}
            actions={record.parentCategoryId ? items.slice(1) : items}
          />
        );
      },
    },
  ];

  return {
    isAddModalVisible,
    addModalHandleOk,
    addModalHandleCancel,
    addModalProps,

    isEditModalVisible,
    editModalHandleOk,
    editModalHandleCancel,
    editModalProps,

    columns,
  };
};

export default useCategoriesTable;
