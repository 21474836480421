import { useMutation } from '@apollo/client';
import { UploadCategoryPicture } from '../../../../../api/query';

const useUploadCategoryPicture = () => {
  const [uploadCategoryPicture, { data, loading, error }] = useMutation(UploadCategoryPicture);

  const uploadImage = (id, file) => uploadCategoryPicture({
    variables: {
      file,
      categoryId: id,
    },
  });

  return {
    uploadImage,
    uploadImageData: data,
    loading,
    errorUploadImage: error,
  };
};

export default useUploadCategoryPicture;
