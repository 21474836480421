import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  GET_ALL_COUPONS_IN_CART, GET_COUPONS_IN_CART_COUNT, REMOVE_COUPON_FROM_CART,
} from '../../../api/cart-api/query';

const useRemoveCouponFromCart = () => {
  const [removeCallback, { loading: removeLoading }] = useMutation(REMOVE_COUPON_FROM_CART, {
    refetchQueries: [GET_ALL_COUPONS_IN_CART, GET_COUPONS_IN_CART_COUNT],
  });

  const removeCouponFromCart = useCallback(async (couponId) => {
    await removeCallback({
      variables: { id: couponId },
    });
  }, [removeCallback]);

  return { removeLoading, removeCouponFromCart };
};

export default useRemoveCouponFromCart;
