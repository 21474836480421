import { useQuery } from '@apollo/client';
import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { FIND_VOUCHERS_BY_ADMIN, FIND_VOUCHERS_BY_MERCHANT } from '../../../api/vouchers/query';
import { STATUS_TYPE_ACTIVE } from '../../../constants/general/tag-status';
import { getRoleUser } from '../../../helpers/user-storage';
import { ROLE_EMPLOY_MERCHANT, ROLE_EMPLOY_MERCHANT_SUPER, ROLE_EMPLOY_SECURITY } from '../../../constants/employ';

const useActivatedVouchers = () => {
  const [date, setDate] = useState({
    startDate: undefined,
    currentDate: undefined,
  });

  const { startDate, currentDate } = date;

  const switchStartFilterDate = useCallback((value) => {
    switch (value) {
    case 'Last 24 hours':
      return moment().subtract(24, 'hours');
    case 'Last week':
      return moment().subtract(7, 'days');
    case 'Last month':
      return moment().subtract(1, 'month');
    default:
      return undefined;
    }
  }, []);

  const vouchersQuery = getRoleUser() === ROLE_EMPLOY_MERCHANT
  || getRoleUser() === ROLE_EMPLOY_SECURITY
  || getRoleUser() === ROLE_EMPLOY_MERCHANT_SUPER
    ? FIND_VOUCHERS_BY_MERCHANT
    : FIND_VOUCHERS_BY_ADMIN;

  const { data, loading } = useQuery(vouchersQuery, {
    variables: {
      filter: {
        start: startDate,
        end: currentDate,
        status: STATUS_TYPE_ACTIVE,
      },
    },
  });

  const totalData = useMemo(() => {
    if (getRoleUser() === ROLE_EMPLOY_MERCHANT
        || getRoleUser() === ROLE_EMPLOY_SECURITY
        || getRoleUser() === ROLE_EMPLOY_MERCHANT_SUPER) {
      if (data) {
        const { findAllVouchersMerchant: { vouchers } } = data;
        if (vouchers.length > 0) {
          return vouchers.map(({
            id, couponTitle, activateDate, order: { orderNumber },
          }) => {
            const date = moment(activateDate);
            const activationDate = date.format('DD MMM, YYYY');
            const activationTime = date.format('HH:mm');
            return {
              id, title: couponTitle, activationTime, activationDate, orderNumber,
            };
          });
        }
      }
    }
    if (data) {
      const { findAllVouchersAdmin: { vouchers } } = data;
      if (vouchers.length > 0) {
        return vouchers.map(({
          id, couponTitle, activateDate, order: { orderNumber },
        }) => {
          const date = moment(activateDate);
          const activationDate = date.format('DD MMM, YYYY');
          const activationTime = date.format('HH:mm');
          return {
            id, title: couponTitle, activationTime, activationDate, orderNumber,
          };
        });
      }
    }
    return null;
  }, [data]);

  return {
    totalData,
    loading,
    setDate,
    startDate,
    currentDate,
    switchStartFilterDate,
  };
};

export default useActivatedVouchers;
