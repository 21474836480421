import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { DELETE_COUPON } from '../../../../../api/query';
import useFindAllCoupons from './useFindAllCoupons';

const useDeleteCoupon = () => {
  const { totalQuery } = useFindAllCoupons();

  const [deleteCouponByIdAdmin, { loading, error }] = useMutation(DELETE_COUPON, {
    refetchQueries: [totalQuery()],
  });

  const deleteCouponByAdmin = useCallback(async (id) => {
    try {
      await deleteCouponByIdAdmin({
        variables: {
          id,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [deleteCouponByIdAdmin]);

  return {
    deleteCouponByAdmin,
    loadingDeleteCoupon: loading,
    errorDeleteCoupon: error,
  };
};
export default useDeleteCoupon;
