import React, { memo, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import placeholderImage from '../../../assets/images/avatarPlaceholder.png';
import './profile-image.scss';
import DropdownMenu from '../DropdownMenu';
import AddAvatarModal from '../modals/AddAvatarModal';
import useAuthAdmin from '../../../context/context-admin/useAuthAdmin';
import { DELETE_PROFILE_PICTURE } from '../../../api/profile/query';
import { GetEmployeeSelf } from '../../../api/query';

const ProfileImage = () => {
  const { userInfo } = useAuthAdmin();

  const [removeAvatarCallback] = useMutation(DELETE_PROFILE_PICTURE, {
    refetchQueries: [GetEmployeeSelf],
  });

  const profileImageUrl = userInfo?.userPicture
    ? `${process.env.REACT_APP_BUCKET_URL}/${userInfo?.userPicture?.key}`
    : placeholderImage;

  const [isShowDropdown, setShowDropdown] = useState(false);
  const [isOpenAddAvatarModal, setOpenAddAvatarModal] = useState(false);

  const closeDropdownOnBg = (e) => {
    e.stopPropagation();
    setShowDropdown(false);
  };

  const closeAddAvatarModal = useCallback(() => {
    setOpenAddAvatarModal(false);
  }, []);

  const addAvatar = useCallback(() => {
    setOpenAddAvatarModal(true);
    setShowDropdown(false);
  }, []);

  const deleteAvatar = useCallback(() => {
    if (userInfo?.userPicture) {
      const { id: userPictureId } = userInfo.userPicture;
      removeAvatarCallback({
        variables: { userPictureId },
      })
        .then(() => {
          setShowDropdown(false);
        });
    }
  }, [userInfo]);

  return (
    <div className="profile-container">
      { isShowDropdown && (
        <div
          className="closeDropdown"
          onClick={closeDropdownOnBg}
        />
      )}
      <div
        className="profile-photo"
        onClick={() => setShowDropdown(!isShowDropdown)}
      >
        <img
          src={profileImageUrl}
          alt="avatar"
        />
      </div>
      {
        isShowDropdown && (
          <div>
            {' '}
            <DropdownMenu
              actions={[
                {
                  key: '0',
                  title: 'Add avatar',
                  callback: addAvatar,
                },
                {
                  key: '1',
                  title: 'Delete avatar',
                  callback: deleteAvatar,
                  disabled: userInfo?.userPicture === null,
                },
              ]}
            />
          </div>
        )
      }
      {
        isOpenAddAvatarModal && (
          <AddAvatarModal
            isOpenModal={isOpenAddAvatarModal}
            closeModalHandler={closeAddAvatarModal}
          />
        )
      }
    </div>
  );
};

export default memo(ProfileImage);
