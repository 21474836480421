import { GoogleMap, Marker } from '@react-google-maps/api';
import React, { useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import s from './map.module.scss';
import { containerStyle } from '../../../../../../../../../constants/googleMap';

export const MODES = {
  MOVE: 0,
  SET_MARKER: 1,
};

const Map = ({
  mode, center, onMarkerAdd, address,
}) => {
  const mapRef = useRef(undefined);

  const defaultOptions = useMemo(() => ({
    panControl: true,
    zoomControl: false,
    zoomControlOptions: {
      // eslint-disable-next-line no-undef
      position: google.maps.ControlPosition.LEFT_BOTTOM,
    },
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    clickableIcons: false,
    keyboardShortcuts: false,
    scrollwheel: true, // scale
    disableDoubleClickZoom: false,
    fullscreenControl: true,
    fullscreenControlOptions: {
      // eslint-disable-next-line no-undef
      position: google.maps.ControlPosition.LEFT_TOP,
    },
  }), []);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = undefined;
  }, []);

  const onClickHandler = useCallback((loc) => {
    if (mode === MODES.SET_MARKER) {
      const lat = loc.latLng.lat();
      const lng = loc.latLng.lng();
      onMarkerAdd({ lat, lng });
    }
  }, [mode, onMarkerAdd]);

  const onDragEndHandler = useCallback((loc) => {
    const lat = loc.latLng.lat();
    const lng = loc.latLng.lng();
    onMarkerAdd({ lat, lng });
  }, [onMarkerAdd]);

  return (
    <div className={s.container}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={14}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={defaultOptions}
        onClick={onClickHandler}
      >
        { address && (
          <Marker
            draggable
            onDragEnd={onDragEndHandler}
            position={center}
          />
        ) }
      </GoogleMap>
    </div>
  );
};

Map.propTypes = {
  mode: PropTypes.number.isRequired,
  center: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onMarkerAdd: PropTypes.func.isRequired,
  address: PropTypes.string,
};

Map.defaultProps = {
  address: undefined,
};

export default Map;
