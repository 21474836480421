import React, { memo } from 'react';
import useGetCouponByAdminId from '../CouponTable/hooks/gql/useGetCouponById';
import s from './CouponMaking.module.scss';
import Loader from '../../Loader';
import CouponContainer from './Coupon/CouponContainer';

const CouponEdit = () => {
  const {
    couponInputById, loadingGetCouponById, couponOptionsById, prevImageById, galleryImagesId, couponId,
  } = useGetCouponByAdminId();

  return (
    <section className={`${s.body} couponMaking`}>
      {
        loadingGetCouponById ? <Loader />
          : (
            <CouponContainer
              couponInputById={couponInputById}
              couponOptionsById={couponOptionsById}
              galleryImagesById={galleryImagesId}
              prevImageById={prevImageById}
              couponId={couponId}
            />
          )
      }
    </section>
  );
};
export default memo(CouponEdit);
