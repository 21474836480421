import React from 'react';
import { Table } from 'antd';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { limit } from '../../../../constants/tableConstants';
import useGetEmployeesColumn from './column/columnEmployees';
import defaultAvatar from '../../../../assets/images/avatarPlaceholder.png';
import useWindowSize from '../../../../hooks/useWindowSize/useWindowSize';
import { heightMinusForAdminTables } from '../../../../constants/general/tag-status';

const { REACT_APP_BUCKET_URL } = process.env;

const TableEmployees = ({
  editModalEmployHandler, deleteModalEmployHandler, employees, count,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [height] = useWindowSize();

  const columns = useGetEmployeesColumn(editModalEmployHandler, employees, deleteModalEmployHandler);

  const page = searchParams.get('page') || '1';

  const findData = employees.map((employ) => {
    const {
      firstName, lastName, userPicture, ...rest
    } = employ;

    const pictureUrl = userPicture
      ? `${REACT_APP_BUCKET_URL}/${userPicture.key}`
      : defaultAvatar;
    return {
      ...rest,
      fullName: `${firstName || ''} ${lastName || ''}`,
      userPicture: pictureUrl,
    };
  });

  const onChangePage = (page) => {
    setSearchParams({
      ...Object.entries(searchParams),
      page,
    });
  };

  return (
    <section className="all-admin-table">
      <Table
        className="adminTable"
        dataSource={findData}
        columns={columns}
        rowKey={(record) => record.id}
        scroll={{
          y: height - heightMinusForAdminTables, x: 0,
        }}
        pagination={{
          current: Number(page),
          pageSize: limit,
          total: count,
          position: ['bottomCenter'],
          size: 'small',
          showSizeChanger: false,
          onChange: (page) => onChangePage(page),
        }}
      />
    </section>
  );
};

TableEmployees.propTypes = {
  editModalEmployHandler: PropTypes.func.isRequired,
  deleteModalEmployHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  employees: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
};

export default TableEmployees;
