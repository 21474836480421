import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import mobileCondition from '../../../constants/mobileCondition';
import s from './Coupons.module.scss';
import CouponItem from '../CouponItem';
import CatalogContext from '../context/catalogContext';
import { limitPerFetch } from '../constants/fetch';
import { ArrowUp } from '../../../constants/icons';
import CategoriesContext from '../../Layout/context/categoriesContext';
import Loader from '../../Loader';

const Coupons = () => {
  const {
    coupons, count, isCouponsFetching, params,
  } = useContext(CatalogContext);

  const { scrollOnCouponsTop, setSearchParams, catalogRef } = useContext(CategoriesContext);

  const [isArrowShow, setIsArrowShow] = useState(false);

  const isLoadMoreShow = coupons && coupons.length > 0 && coupons.length < count;

  const goToTopButtonClassName = `${s.arrowUp} ${isArrowShow && s.active}`;

  const couponsListClassName = `${s.couponsBlock} ${mobileCondition && s.mobile}`;

  const loadMoreClassName = `${s.loadMore} ${mobileCondition && s.mobile}`;

  useEffect(() => {
    const toggleIsArrowShowCallback = () => {
      const heightToTrigger = window.pageYOffset - catalogRef.current.offsetTop > 200;
      setIsArrowShow(heightToTrigger);
    };
    window.addEventListener('scroll', toggleIsArrowShowCallback);
    return () => {
      window.removeEventListener('scroll', toggleIsArrowShowCallback);
    };
  }, []);

  const loadMoreCouponsCallback = () => {
    setSearchParams({
      ...params,
      offset: count - coupons.length > limitPerFetch
        ? limitPerFetch + coupons.length
        : count,
    });
  };

  const mappedCoupons = coupons && coupons.map(({
    id,
    title,
    vatPrice,
    currentPrice,
    couponGalleryPictures,
    couponPreviewPicture,
    discount,
    oldPrice,
    publishedDueDate,
    couponOptions,
    keywordsImages,
  }) => (
    <CouponItem
      key={id}
      id={id}
      title={title}
      couponPreviewPicture={couponPreviewPicture}
      discount={discount}
      vatPrice={vatPrice}
      currentPrice={currentPrice}
      publishedDueDate={publishedDueDate}
      oldPrice={oldPrice}
      couponGalleryPictures={couponGalleryPictures}
      couponOptions={couponOptions}
      keywordsImages={keywordsImages}
    />
  ));

  return (
    <div className={s.container}>
      <div className={couponsListClassName}>
        {((!isLoadMoreShow || !coupons) && isCouponsFetching) && <Loader />}
        {mappedCoupons}
      </div>
      {isLoadMoreShow && (
        <div
          className={loadMoreClassName}
          onClick={loadMoreCouponsCallback}
        >
          <span>
            {isCouponsFetching ? <LoadingOutlined color="#333333" /> : 'Load more coupons'}
          </span>
        </div>
      )}
      {!mobileCondition && (
        <div
          onClick={scrollOnCouponsTop}
          className={goToTopButtonClassName}
        >
          <ArrowUp />
        </div>
      )}
    </div>
  );
};

export default memo(Coupons);
