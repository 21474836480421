import React, { memo } from 'react';
import PropTypes from 'prop-types';
import style from '../../../ModalForm.module.scss';
import ButtonComponent from '../../../../Common_components/ButtonComponent';

const ButtonsBlock = (props) => {
  const {
    id,
    onCancelModalHandler,
    sendFormData,
  } = props;

  return (
    <div className={style.btnModal}>
      <ButtonComponent
        callback={onCancelModalHandler}
        title="Cancel"
        addClass={style.cancel}
        iconComponent={null}
      />
      <ButtonComponent
        callback={sendFormData}
        title={id !== 'create-promo-code' ? 'Save' : 'Add promo code'}
        iconComponent={null}
      />
    </div>
  );
};

ButtonsBlock.propTypes = {
  id: PropTypes.string.isRequired,
  onCancelModalHandler: PropTypes.func.isRequired,
  sendFormData: PropTypes.func.isRequired,
};

export default memo(ButtonsBlock);
