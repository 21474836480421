import React from 'react';
import { pdf, Document } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import MyDoc from './PdfLayout';

const DownloadVoucherFile = ({
  voucherData, preview, urlToCoupon,
}) => (
  <MyDoc
    title={voucherData.title}
    previewSrc={preview}
    propsCompanyTitle={voucherData.company}
    fixedVat={voucherData.fixedVat}
    promoCode={voucherData.promoCode}
    additionalInformation={voucherData.additionalInformation}
    bookingPhoneNumber={voucherData.bookingPhoneNumber}
    propsCompanyAddress={voucherData.address}
    fixedCost={voucherData.fixedCost}
    fixedDate={voucherData.totalPayDate}
    qrCodeUrl={urlToCoupon}
    terms={voucherData.terms}
    description={voucherData.description}
    isOptionVoucher={!!voucherData.isOptionVoucher}
    optionTitle={voucherData.optionTitle}
    voucherNumber={voucherData.voucherNumber}
  />
);
DownloadVoucherFile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  voucherData: PropTypes.object.isRequired,
  preview: PropTypes.string.isRequired,
  urlToCoupon: PropTypes.string.isRequired,
};

const DownloadVouchers = async (vouchers, orderId) => {
  const doc = (
    <Document>
      {
        vouchers.map((voucher, index) => (
          <DownloadVoucherFile
            key={`page_${index + 1}`}
            voucherData={voucher.data}
            preview={voucher.imageUrl}
            urlToCoupon={voucher.couponQRData}
          />
        ))
      }
    </Document>
  );
  const asPdf = pdf();
  asPdf.updateContainer(doc);
  const blob = await asPdf.toBlob();
  saveAs(blob, `${orderId}.pdf`);
};

export default DownloadVouchers;
