import React, { memo } from 'react';
import { Checkbox, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { currentAndOldPrice, currentPriceByDiscount, discountByPrice } from '../../../../../constants/math';
import { finallyBorderInput } from '../../Coupon/helpers/finallyBorder';
import s from '../countingBody.module.scss';
import NumericInput from '../../../../Common_components/InputNumberComponent';

const ByPrice = (props) => {
  const {
    correctPrice,
    couponInput,
    vatValue,
    allErrors,
    priceOfCoupon,
    callbackHandler,
  } = props;

  const valueOfDiscount = () => {
    if (couponInput.createPrice && couponInput.oldPrice) {
      return Number.isFinite(discountByPrice(priceOfCoupon.current, couponInput.oldPrice))
        ? discountByPrice(priceOfCoupon.current, couponInput.oldPrice)
        : 0;
    }
    return 0;
  };

  return (
    <>
      <Form.Item label="Price, AED">
        <NumericInput
          keyValue="createPrice"
          value={couponInput.createPrice}
          callbackHandler={(key, value) => correctPrice(couponInput.createPrice, key, value)}
          style={{
            border: allErrors?.createPrice && !+couponInput.createPrice
              ? '1px solid #D00000' : '1px solid #EDEDED',
            height: '40px',
            borderRadius: '4px',
          }}
          keyOperation="price"
        />
        {allErrors?.createPrice && !+couponInput.createPrice
                && <span className="errorCouponText">{allErrors.createPrice}</span>}
      </Form.Item>
      <Form.Item
        name="checkbox-group"
        label={`Use VAT (${vatValue}%)`}
      >
        <Checkbox
          checked={couponInput.applyVat}
          onChange={(e) => callbackHandler('applyVat', e.target.checked)}
        />
      </Form.Item>
      <Form.Item
        label={(
          <div className={s.label}>
            <p>Coupon price, AED</p>
            <p>(1000 + 5%)</p>
          </div>
        )}
      >
        <Input
          disabled
          value={
            !couponInput.countPriceByDiscount
              ? currentAndOldPrice(couponInput.createPrice, couponInput.applyVat, vatValue)
              : currentPriceByDiscount(
                currentAndOldPrice(couponInput.createPrice, couponInput.applyVat, vatValue),
                couponInput.discount,
              )
          }
          style={{
            height: '40px',
            border: '1px solid #EDEDED',
            borderRadius: '4px',
          }}
        />
      </Form.Item>
      <Form.Item label="Old price, AED">
        <NumericInput
          keyValue="oldPrice"
          style={{
            border: allErrors?.oldPrice && !couponInput.countPriceByDiscount
              ? finallyBorderInput(+couponInput.oldPrice !== 0
                && (!couponInput.applyVat
                  ? +couponInput.oldPrice >= +couponInput.createPrice
                  : +couponInput.oldPrice >= (+couponInput.createPrice + (+couponInput.createPrice * (vatValue / 100)))
                ))
              : finallyBorderInput(true),
            height: '40px',
            borderRadius: '4px',
          }}
          value={
            !couponInput.countPriceByDiscount
              ? couponInput.oldPrice
              : currentAndOldPrice(couponInput.createPrice, couponInput.applyVat, vatValue)
          }
          callbackHandler={(key, value) => correctPrice(couponInput.oldPrice, key, value)}
          disabled={couponInput.countPriceByDiscount}
          keyOperation="price"
        />
        {allErrors?.oldPrice && !couponInput.countPriceByDiscount
          && !couponInput.oldPrice
          && <span className="errorCouponText">This field is required</span>}

        {allErrors?.oldPrice && couponInput.oldPrice
          && +couponInput.oldPrice === 0 && !couponInput.countPriceByDiscount
          && <span className="errorCouponText">Incorrect number!</span>}

        {allErrors?.oldPrice && !couponInput.countPriceByDiscount && couponInput.oldPrice
          && +couponInput.oldPrice !== 0
          && (couponInput.applyVat
            ? +couponInput.oldPrice < (+couponInput.createPrice + (+couponInput.createPrice * (vatValue / 100)))
            : +couponInput.oldPrice < couponInput.createPrice)
          && <span className="errorCouponText">Old price can not be less than created price!</span>}
      </Form.Item>

      <Form.Item
        label={(
          <div className={s.label}>
            <p>Discount</p>
            <p>[100-(100*1050/1500)]</p>
          </div>
        )}
      >
        <NumericInput
          style={{
            border: allErrors?.discount
              && couponInput.countPriceByDiscount && finallyBorderInput(
              couponInput.discount
                && +couponInput.discount <= 100
                && +couponInput.discount !== 0,
            ),
            height: '40px',
            borderRadius: '4px',
          }}
          keyValue="discount"
          value={valueOfDiscount()}
          callbackHandler={(key, value) => correctPrice(couponInput.discount, key, value)}
          disabled={!couponInput.countPriceByDiscount}
        />
      </Form.Item>
    </>
  );
};

ByPrice.propTypes = {
  couponInput: PropTypes.oneOfType([PropTypes.object]).isRequired,
  vatValue: PropTypes.number,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
  correctPrice: PropTypes.func.isRequired,
  priceOfCoupon: PropTypes.oneOfType([PropTypes.object]).isRequired,
  callbackHandler: PropTypes.func.isRequired,
};
ByPrice.defaultProps = {
  vatValue: undefined,
  allErrors: undefined,
};

export default memo(ByPrice);
