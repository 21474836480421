import React, { memo, useCallback, useState } from 'react';
import { Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Portal from '../../../Common_components/Portal';
import { CloseIcon } from '../../../../constants/icons';
import BtnsBlock from '../../../Common_components/BtnsBlock';
import EditInput from '../../ProfileInfo/EditInput';
import './change-password-modal.scss';
import validatorForm from '../../../../helpers/validator';
import { PROFILE_MERCHANT_PASSWORD_SCHEMA } from '../../../../constants/profileValidatorSchema/validatorSchema';
import { CHANGE_PASSWORD } from '../../../../api/profile/query';
import Loader from '../../../Loader';

const ChangePasswordModal = ({ isOpenModal, closeModalHandler }) => {
  const [dataAccount, setDataAccount] = useState({
    confirmPassword: '',
    currentPassword: '',
    newPassword: '',
  });

  const { confirmPassword, currentPassword, newPassword } = dataAccount;

  const inputStyle = {
    border: '1px solid #EDEDED',
    height: '40px',
    borderRadius: '4px',
  };

  const [allErrors, setAllErrors] = useState(null);

  const changeDataAccount = useCallback((key, value) => {
    setDataAccount((prev) => ({
      ...prev,
      [key]: value,
    }));
    setAllErrors((prev) => ({
      ...prev,
      [key]: '',
    }));
  }, []);

  const [changePassword, { loading }] = useMutation(CHANGE_PASSWORD, {
    variables: { changePasswordInput: { confirmPassword, currentPassword, newPassword } },
  });

  const sendDataHandler = useCallback(() => {
    const { errors } = validatorForm({
      confirmPassword: confirmPassword && confirmPassword.trim(),
      currentPassword: currentPassword && currentPassword.trim(),
      newPassword: newPassword && newPassword.trim(),
    }, PROFILE_MERCHANT_PASSWORD_SCHEMA);

    if (!Object.keys(errors).length) {
      changePassword()
        .then(() => {
          closeModalHandler();
        })
        .catch((err) => {
          if (err.message === 'Wrong password') {
            setAllErrors((prev) => ({
              ...prev,
              currentPassword: err.message,
            }));
          }
        });
    } else {
      setAllErrors(() => ({ ...errors }));
    }
  }, [closeModalHandler, dataAccount]);

  const cancelHandler = useCallback(() => {
    closeModalHandler();
  }, [closeModalHandler]);

  return (
    <Portal>
      {loading && <Loader />}
      <Modal
        centered
        title="Change password"
        closeIcon={<CloseIcon />}
        visible={isOpenModal}
        onOk={closeModalHandler}
        onCancel={closeModalHandler}
        wrapClassName="wrapper"
        width={440}
        footer={null}
      >
        <Form
          layout="vertical"
          className="edit-password-form"
          labelCol={{
            className: 'merchant-modal-password_label-col',
          }}
          wrapperCol={{
            className: 'merchant-modal-password-col',
          }}
        >
          <EditInput
            label="Old password"
            error={allErrors?.currentPassword}
            keyField="currentPassword"
            value={dataAccount.currentPassword}
            callback={changeDataAccount}
            inputVariant="password"
            style={inputStyle}
          />
          <EditInput
            label="New password"
            error={allErrors?.newPassword}
            keyField="newPassword"
            value={dataAccount.newPassword}
            callback={changeDataAccount}
            inputVariant="password"
            style={inputStyle}
          />
          <EditInput
            label="Confirm password"
            error={allErrors?.confirmPassword}
            keyField="confirmPassword"
            value={dataAccount.confirmPassword}
            callback={changeDataAccount}
            inputVariant="password"
            style={inputStyle}
          />
        </Form>
        <BtnsBlock
          wrapperClass="profile-merchant-btns"
          cancelHandler={cancelHandler}
          sendDataHandler={sendDataHandler}
          cancelText="Cancel"
          okText="Save"
          addClassForBtnCancel="profile-merchant-btns__btn-cancel"
        />
      </Modal>
    </Portal>
  );
};

ChangePasswordModal.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
};

export default memo(ChangePasswordModal);
