import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import mobileCondition from '../../../constants/mobileCondition';
import s from './Subscribe.module.scss';
import SUBSCRIBE_EMAIL from '../../../api/mailchimp-api/query';
import { regEmail } from '../../../helpers/regularExpression/regularExpression';
import { SUBSCRIBE_MESSAGES, SUBSCRIBE_TOOLTIP_STYLE } from '../../../constants/subscribeConstants/tooltipData';

let timeout;

const Subscribe = () => {
  const [email, setEmail] = useState('');
  const onMailChange = (e) => setEmail(e.currentTarget.value);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const [tooltipStyle, setTooltipStyle] = useState({});

  const [tooltipTitle, setTooltipTitle] = useState('');

  const [callback, { loading }] = useLazyQuery(SUBSCRIBE_EMAIL);

  const subscribeByEmail = useCallback(async () => {
    try {
      if (email.match(regEmail)) {
        await callback({ variables: { email } });
        setEmail('');
        setTooltipStyle(SUBSCRIBE_TOOLTIP_STYLE.SUCCESS);
        setTooltipTitle(SUBSCRIBE_MESSAGES.SUCCESS);
        setIsTooltipVisible(true);
      } else {
        setTooltipStyle(SUBSCRIBE_TOOLTIP_STYLE.WARNING);
        setTooltipTitle(SUBSCRIBE_MESSAGES.NOT_VALID);
        setIsTooltipVisible(true);
      }
    } catch (e) {
      setTooltipStyle(SUBSCRIBE_TOOLTIP_STYLE.ERROR);
      setTooltipTitle(SUBSCRIBE_MESSAGES.ERROR);
      setIsTooltipVisible(true);
    } finally {
      timeout = setTimeout(() => {
        setIsTooltipVisible(false);
        setTooltipTitle('');
      }, 4000);
    }
  }, [email, callback]);

  useEffect(() => () => clearTimeout(timeout), []);

  const mainClassName = `${s.main} ${mobileCondition && s.mobile}`;

  return (
    <div className={mainClassName}>
      <span>Subscribe for updates on deals & promotions</span>
      <div>
        <Tooltip
          placement="bottomLeft"
          title={tooltipTitle}
          visible={isTooltipVisible}
          overlayInnerStyle={tooltipStyle}
        >
          <input
            value={email}
            placeholder="Your email address"
            onChange={onMailChange}
            type="text"
          />
        </Tooltip>
        <button
          disabled={loading}
          onClick={subscribeByEmail}
        >
          {loading
            ? <LoadingOutlined color="#333333" />
            : 'Subscribe'}
        </button>
      </div>
    </div>
  );
};

export default memo(Subscribe);
