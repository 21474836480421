import React from 'react';
import Page from '../../components/Page';
import EmployeesTable from '../../components/Employees/EmployeesTable';

const Employees = () => (
  <Page>
    <EmployeesTable />
  </Page>
);

export default Employees;
