import React, { memo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Form, Modal, Radio, Space,
} from 'antd';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../../../constants/icons';
import s from './Employ.module.scss';
import ButtonComponent from '../../../Common_components/ButtonComponent';
import './Employ-ant.scss';
import {
  ROLE_EMPLOY_ADMIN,
  ROLE_EMPLOY_MERCHANT,
  ROLE_EMPLOY_SECURITY,
  ROLE_EMPLOY_SUPER_ADMIN,
} from '../../../../constants/employ';
import EditInput from './EditInput';
import ActiveBlock from '../ActiveBlock';
import { getRoleUser } from '../../../../helpers/user-storage';

const { Item } = Form;

const EmployModal = ({
  closeModalWindowEmploy,
  isOpenModalEmploy,
  employData,
  sendDataHandler,
  changeInitStateEmploy,
  allErrors,
  loadingCreateEmploy,
  loadingUpdateEmployByIdAdmin,
}) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') || '';

  const {
    status, role, firstName, lastName, email,
  } = employData;

  const finallyTitle = () => {
    if (id !== 'create-employ') {
      return 'Edit employee';
    }
    return 'Add employee';
  };

  const closeForm = useCallback(() => {
    closeModalWindowEmploy();
  }, [closeModalWindowEmploy]);

  const changeTiedHandler = (e) => {
    changeInitStateEmploy('role', e.target.value);
  };

  return (
    <Modal
      title={finallyTitle()}
      centered
      visible={isOpenModalEmploy}
      closeIcon={<CloseIcon />}
      onCancel={closeForm}
      footer={null}
      width={440}
      className={s.modalComponent}
      wrapClassName="modalBanner"
    >
      <Form
        layout="vertical"
        className="formEmploy"
        name="formEmploy"
      >
        {
          status && (
            <Item
              label="Status"
              className={`${s.formItem}`}
            >
              <ActiveBlock
                status={status}
                changeInitStateEmploy={changeInitStateEmploy}
              />
            </Item>
          )
        }
        <EditInput
          label="Name"
          error={allErrors?.firstName}
          callback={changeInitStateEmploy}
          value={firstName}
          itemClass={s.formItem}
          keyField="firstName"
          placeholderValue="Name"
          isAutoFocus
        />
        <EditInput
          label="Last name"
          value={lastName}
          callback={changeInitStateEmploy}
          error={allErrors?.lastName}
          keyField="lastName"
          itemClass={s.formItem}
          placeholderValue="Last name"
        />
        <EditInput
          label="Email"
          value={email}
          itemClass={`${s.formItem} ${s.formItemEmail}`}
          callback={changeInitStateEmploy}
          error={allErrors?.email}
          keyField="email"
          disabledInput={id !== 'create-employ'}
          placeholderValue="Email"
        />
        <Item
          label="Role"
          className={`${s.formItem} ${s.formItemRadio}`}
        >
          <Radio.Group
            value={role}
            onChange={changeTiedHandler}
          >
            <Space direction="vertical">
              <Radio
                className="radioRoleEmploy"
                value={getRoleUser() === ROLE_EMPLOY_SUPER_ADMIN ? ROLE_EMPLOY_SUPER_ADMIN : ROLE_EMPLOY_SECURITY}
              >
                {getRoleUser() === ROLE_EMPLOY_SUPER_ADMIN ? 'Super - admin' : 'Security'}
              </Radio>
              <Radio
                value={getRoleUser() === ROLE_EMPLOY_SUPER_ADMIN ? ROLE_EMPLOY_ADMIN : ROLE_EMPLOY_MERCHANT}
                className="radioRoleEmploy"
              >
                Admin
              </Radio>
            </Space>
          </Radio.Group>
        </Item>
        <div className={s.btnModal}>
          <ButtonComponent
            title="Cancel"
            addClass={s.cancel}
            callback={closeForm}
            iconComponent={null}
          />
          <ButtonComponent
            title={id !== 'create-employ' ? 'Save' : 'Add Employee'}
            callback={sendDataHandler}
            iconComponent={null}
            disabled={loadingCreateEmploy || loadingUpdateEmployByIdAdmin}
          />
        </div>
      </Form>
    </Modal>
  );
};
EmployModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  employData: PropTypes.object.isRequired,
  // setBannerDataHandler: PropTypes.func.isRequired,
  isOpenModalEmploy: PropTypes.bool.isRequired,
  closeModalWindowEmploy: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allErrors: PropTypes.object,
  changeInitStateEmploy: PropTypes.func.isRequired,
  sendDataHandler: PropTypes.func.isRequired,
  loadingCreateEmploy: PropTypes.bool,
  loadingUpdateEmployByIdAdmin: PropTypes.bool,
};
EmployModal.defaultProps = {
  allErrors: null,
  loadingCreateEmploy: false,
  loadingUpdateEmployByIdAdmin: false,
};

export default memo(EmployModal);
