import PropTypes from 'prop-types';
import React, { memo, useContext, useState } from 'react';
import s from './OptionItem.module.scss';
import QuantityControls from '../../QuantityControls/QuantityControls';
import getPrice from '../../../../helpers/couponPage/getPrice';
import OrderContext from '../../CustomerBlock/orderContext';
import mobileCondition from '../../../../constants/mobileCondition';

const OptionItem = ({
  currentPrice, oldPrice, discount, title, id,
}) => {
  const { handleOptionsOrder } = useContext(OrderContext);

  const [quantity, setQuantity] = useState(0);

  const itemClassName = `${s.item} ${mobileCondition && s.mobile}`;

  const totalDiscount = `${Math.round(discount)}% OFF`;
  const isHaveDiscount = !!discount && Math.round(discount) !== 0;

  return (
    <div className={itemClassName}>
      <span className={s.title}>{title}</span>
      {mobileCondition
        ? (
          <div className={s.mobilePricesWrapper}>
            <div>
              <div className={s.priceContainer}>
                <span>{getPrice(currentPrice)}</span>
                {
                  isHaveDiscount && <span className={s.discount}>{totalDiscount}</span>
                }
              </div>
              {
                oldPrice && <span className={s.oldPrice}>{getPrice(oldPrice)}</span>
              }
            </div>
            <QuantityControls
              itemId={id}
              orderHandler={handleOptionsOrder}
              quantity={quantity}
              setQuantity={setQuantity}
            />
          </div>
        )
        : (
          <>
            <div className={s.priceContainer}>
              <span>{getPrice(currentPrice)}</span>
              {
                isHaveDiscount && <span className={s.discount}>{totalDiscount}</span>
              }
            </div>
            {
              oldPrice && <span className={s.oldPrice}>{getPrice(oldPrice)}</span>
            }
            <QuantityControls
              itemId={id}
              orderHandler={handleOptionsOrder}
              quantity={quantity}
              setQuantity={setQuantity}
            />
          </>
        )}

    </div>
  );
};

OptionItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  oldPrice: PropTypes.number.isRequired,
  currentPrice: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
};

export default memo(OptionItem);
