import React, { memo } from 'react';
import {
  Button,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { uid } from 'uid';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DeleteBasket } from '../../../../constants/icons';
import TimeRangePickerComponent from '../../TimeRangePickerComponent';
import './TimeInterval.scss';
import { MERCHANT_TIME_FORMAT } from '../../../../constants/merchantConstant';

const TimeInterval = ({
  dayOfWeek, setTimeIntervalForDay, deleteIntervalForDay, dayIntervals, addTimeIntervalForDay, errors,
}) => {
  const addIntervalHandler = () => {
    addTimeIntervalForDay({
      intervalId: uid(), dayOfWeek, startAt: null, endAt: null, isCheckedDay: true,
    });
  };
  const addTimeHandler = (fieldsValue, intervalId) => {
    const startAt = fieldsValue ? moment(fieldsValue[0]).format(MERCHANT_TIME_FORMAT) : null;
    const endAt = fieldsValue ? moment(fieldsValue[1]).format(MERCHANT_TIME_FORMAT) : null;
    setTimeIntervalForDay({
      intervalId, dayOfWeek, startAt, endAt, isCheckedDay: true,
    });
  };

  const deleteIntervalHandler = (id) => {
    deleteIntervalForDay(id);
  };

  return (
    <div>
      {
        dayIntervals.map((interval) => (
          <div
            className="row"
            key={Math.random()}
          >
            <TimeRangePickerComponent
              timeInterval={interval}
              callback={(fieldsValue) => addTimeHandler(fieldsValue, interval.intervalId)}
              error={errors && errors[interval.intervalId]}
            />
            <div
              className="deleteBacketInterval"
              style={{ color: '#333333' }}
              onClick={() => deleteIntervalHandler(interval.intervalId)}
            >
              <DeleteBasket />
            </div>
          </div>
        ))
      }
      <Button
        style={{
          marginTop: '2px',
        }}
        icon={<PlusOutlined />}
        type="text"
        onClick={addIntervalHandler}
      >
        Add interval
      </Button>
    </div>
  );
};
TimeInterval.propTypes = {
  dayOfWeek: PropTypes.string.isRequired,
  setTimeIntervalForDay: PropTypes.func.isRequired,
  deleteIntervalForDay: PropTypes.func.isRequired,
  dayIntervals: PropTypes.oneOfType([PropTypes.array]).isRequired,
  addTimeIntervalForDay: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]),
};
TimeInterval.defaultProps = {
  errors: undefined,
};

export default memo(TimeInterval);
