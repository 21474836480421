import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Radio } from 'antd';
import { FilterArrow } from '../../../constants/icons';
import './DropdownWithClearComponent.scss';
import '../DropdownWithCheckboxComponent/dropdwon-with-checkbox.scss';

const DropdownWithClearComponent = ({
  items, minWidth, callback, isHaveClearButton, isHaveRadioButton,
}) => {
  const [isVisibleDropdown, setVisibleDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);

  const openDropdown = () => {
    setVisibleDropdown(true);
  };

  const closeDropdown = () => {
    setVisibleDropdown(false);
  };
  const dropdownClickInsideHandler = (e) => {
    e.stopPropagation();
  };

  const clearAllChecked = () => {
    callback(undefined);
    setSelectedItem(undefined);
    closeDropdown();
  };

  const selectItemHandler = (item) => {
    if (isHaveRadioButton) {
      const { target: { value: id } } = item;
      const searchItem = items.find((element) => element.id === id);
      setSelectedItem(searchItem);
      callback(searchItem);
    } else {
      callback(item);
      setSelectedItem(item);
    }
    closeDropdown();
  };

  const renderMenuItems = () => {
    if (isHaveRadioButton) {
      return (
        <Radio.Group
          onChange={selectItemHandler}
          value={selectedItem?.id}
        >
          {
            items?.map((item) => (
              <div
                className="menuFilterWrapper__item"
                key={item.id}
              >
                <Radio
                  className="menuFilterRadio"
                  value={item.id}
                >
                  <span className="menuFilterRadio__text">{item.title}</span>
                </Radio>
              </div>
            ))
          }
        </Radio.Group>
      );
    }

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {
          items?.map((item) => (
            <div
              key={item.id}
              className={`${selectedItem?.id === item.id ? 'activeItemSelected' : ''} menuFilterWrapper__item`}
              onClick={() => selectItemHandler(item)}
            >
              <div className="menuFilterWrapper__text">
                {item.title}
              </div>
            </div>
          ))
        }
      </>
    );
  };

  const menu = (
    <div
      className="menuFilterWrapper"
      onClick={dropdownClickInsideHandler}
      style={{
        minWidth,
      }}
    >
      {isHaveClearButton && (
        <span
          className="menuFilterWrapper__clear clearDropdownFilter"
          onClick={clearAllChecked}
        >
          Clear all
        </span>
      ) }
      { renderMenuItems() }
    </div>
  );

  return (
    <>
      {isVisibleDropdown && (
        <div
          className="closeDropdownOutSide"
          onClick={closeDropdown}
        />
      )}
      <span
        onClick={openDropdown}
      >
        <Dropdown.Button
          visible={isVisibleDropdown}
          overlay={menu}
          icon={<FilterArrow />}
          className="dropdownBtnFilter"
        />
      </span>
    </>
  );
};

DropdownWithClearComponent.propTypes = {
  items: PropTypes.oneOfType([PropTypes.array]).isRequired,
  callback: PropTypes.func.isRequired,
  minWidth: PropTypes.string,
  isHaveClearButton: PropTypes.bool,
  isHaveRadioButton: PropTypes.bool,
};

DropdownWithClearComponent.defaultProps = {
  minWidth: '150px',
  isHaveClearButton: true,
  isHaveRadioButton: false,
};

export default memo(DropdownWithClearComponent);
