import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { intersectionBy, isEqual } from 'lodash';

import {
  CREATE_COUPON_COMMON_INPUT,
  DELETE_COUPON_GALLERY_PICTURE,
  DELETE_PREVIEW_IMAGE,
  SET_ORDER_COUPON_GALLERY_PICTURES,
  UPDATE_COUPON_BY_ID,
  UPLOAD_COUPON_GALLERY_PICTURE,
  UPLOAD_COUPON_PREVIEW_PICTURE,
} from '../../../../../../api/query';
import { COUPON_SCHEMA } from '../../../../../../constants/couponsAdmin/couponValidatoSchema/validatorSchema';
import validatorForm from '../../../../../../helpers/validator';

const useCreateCoupon = () => {
  const navigate = useNavigate();

  const [createCouponAdmin,
    { loading: loadingCreateCoupon, data: dataCreateCoupon }] = useMutation(CREATE_COUPON_COMMON_INPUT);

  const [updateCouponByIdAdmin,
    { loading: loadingUpdateCoupon, data: dataUpdateCoupon }] = useMutation(UPDATE_COUPON_BY_ID);
  const [uploadPreviewImage] = useMutation(UPLOAD_COUPON_PREVIEW_PICTURE);

  const [uploadCouponGalleryPicture] = useMutation(UPLOAD_COUPON_GALLERY_PICTURE);
  const [setOrderCouponGalleryPictures] = useMutation(SET_ORDER_COUPON_GALLERY_PICTURES);
  const [deleteCouponGalleryPicture] = useMutation(DELETE_COUPON_GALLERY_PICTURE);

  const [deleteCouponPreviewPicture] = useMutation(DELETE_PREVIEW_IMAGE);

  const [allErrors, setAllErrors] = useState(null);

  const sendData = useCallback(async (
    data,
    vatValue,
    initGoldenHours,
    couponInputInitState,
    isChangeOrdersInGalleryPictures,
    idCoupon = '',
  ) => {
    const {
      couponInput,
      couponOptions,
      galleryImages,
      prevImage,
      optionsForEdit,
      editPreviewPicture,
      editGalleryPicture,
    } = data;

    const {
      countPriceByDiscount,
      couponGoldenHours: couponGoldenHoursValues,
    } = couponInput;

    const isCheckedCouponGoldenHours = couponGoldenHoursValues.filter((hours) => hours.isCheckedDay);

    const notIsCheckedCouponGoldenHours = couponGoldenHoursValues.filter((hours) => !hours.isCheckedDay);

    const couponOptionsForValid = couponOptions.map((option) => {
      const {
        couponOptionFilialBranchesToAdd, couponOptionFilialBranchesToRemove, countPriceByDiscount,
        oldPrice, discount, createPrice, applyVat, ...rest
      } = option;

      if (!countPriceByDiscount) {
        return {
          ...rest,
          discount: undefined,
          oldPrice,
          countPriceByDiscount,
          createPrice: !applyVat ? createPrice : +createPrice + (+createPrice * (vatValue / 100)),
          applyVat,
        };
      }
      return {
        ...rest,
        oldPrice: undefined,
        discount,
        countPriceByDiscount,
        createPrice,
        applyVat,
      };
    });

    const getFormForValidation = () => {
      if (!countPriceByDiscount) {
        return couponOptions.length
          ? {
            ...couponInput,
            couponGoldenHours: isCheckedCouponGoldenHours,
            discount: undefined,
            couponOptionsForValid,
          }
          : {
            ...couponInput,
            couponGoldenHours: isCheckedCouponGoldenHours,
            discount: undefined,
          };
      }

      return couponOptions.length
        ? {
          ...couponInput,
          couponGoldenHours: isCheckedCouponGoldenHours,
          oldPrice: undefined,
          couponOptionsForValid,
        }
        : { ...couponInput, couponGoldenHours: isCheckedCouponGoldenHours, oldPrice: undefined };
    };

    const form = getFormForValidation();

    const { errors } = validatorForm({ ...form, prevImageKey: prevImage.key }, COUPON_SCHEMA);

    if (!Object.keys(errors).length) {
      if (idCoupon) {
        const { couponOptionsToDelete, couponOptionsToUpdate, couponOptionsToCreate } = optionsForEdit;
        const { newPreviewPicture } = editPreviewPicture;
        const { deleteGalleryImages, uploadNewGalleryImages } = editGalleryPicture;

        if (!couponOptionsToDelete.length
          && !couponOptionsToUpdate.length
          && !couponOptionsToCreate.length
          && !deleteGalleryImages.length
          && !uploadNewGalleryImages.length
          && !newPreviewPicture
          && isEqual(couponInput, couponInputInitState)
          && !isChangeOrdersInGalleryPictures
        ) {
          navigate(-1);
          return;
        }
      }

      //---------------
      const { merchantId, ...couponOptionsAdd } = couponInput;

      const { couponGoldenHoursToDelete, couponGoldenHoursToCreate, couponGoldenHoursToUpdate } = couponInput;

      const finallyGoldenHoursDeleteInterval = couponGoldenHoursToDelete.map((interval) => {
        const { intervalId } = interval;
        return intervalId;
      });

      const finallyGoldenHoursCreateInterval = couponGoldenHoursToCreate
        .filter((interval) => interval.isCheckedDay)
        .map((interval) => {
          const { intervalId, isCheckedDay, ...rest } = interval;
          return rest;
        });

      const finallyGoldenHoursUpdateInterval = couponGoldenHoursToUpdate
        .filter((interval) => interval.isCheckedDay)
        .map((interval) => {
          const { intervalId, isCheckedDay, ...rest } = interval;
          return {
            ...rest,
            id: intervalId,
          };
        });

      const uniqueArrayForDeleteForSwitch = intersectionBy(
        initGoldenHours,
        notIsCheckedCouponGoldenHours,
        'intervalId',
      );

      const couponGoldenHoursWithoutIsChecked = isCheckedCouponGoldenHours.map((interval) => {
        const { isCheckedDay, ...rest } = interval;
        return rest;
      });

      const {
        availableAmountInPeriod,
        videoReviewUrl,
        createPrice,
        oldPrice,
        discount,
        bookingPhone,
        additionalInformation,
        countPriceByDiscount,
      } = couponOptionsAdd;

      const discountCouponOptionsAdd = () => {
        if (!countPriceByDiscount) return null;
        return discount === null ? 0 : +discount;
      };

      const multiplyPriceCouponInput = {
        ...couponOptionsAdd,
        couponGoldenHours: couponGoldenHoursWithoutIsChecked,
        couponGoldenHoursToDelete: [...finallyGoldenHoursDeleteInterval,
          ...uniqueArrayForDeleteForSwitch.map((interval) => interval.intervalId),
        ],
        couponGoldenHoursToCreate: finallyGoldenHoursCreateInterval,
        couponGoldenHoursToUpdate: finallyGoldenHoursUpdateInterval,
        availableAmountInPeriod: availableAmountInPeriod
          ? +availableAmountInPeriod
          : null,
        videoReviewUrl: videoReviewUrl || null,
        bookingPhone: bookingPhone?.trim() || '',
        additionalInformation: additionalInformation?.trim() || '',
        createPrice: +createPrice * 100,
        oldPrice: oldPrice ? +oldPrice * 100 : null,
        discount: discountCouponOptionsAdd(),
      };

      multiplyPriceCouponInput.couponGoldenHours = multiplyPriceCouponInput.couponGoldenHours.map((hours) => {
        const {
          intervalId, ...rest
        } = hours;
        return {
          ...rest,
        };
      });

      //---------------
      const multiplyPriceCouponOptions = couponOptions.map((option) => {
        const { id, countPriceByDiscount, ...rest } = option;
        const discountNumber = countPriceByDiscount ? +rest.discount ?? 0 : null;
        const oldPriceNumber = !countPriceByDiscount && rest.oldPrice ? +rest.oldPrice * 100 : null;

        return {
          ...rest,
          createPrice: +option.createPrice * 100,
          oldPrice: oldPriceNumber,
          discount: discountNumber,
        };
      });
      //---------------

      if (!idCoupon) {
        const {
          couponGoldenHoursToCreate,
          couponGoldenHoursToUpdate,
          couponGoldenHoursToDelete,
          couponFilialBranchesToAdd,
          couponFilialBranchesToRemove,
          ...rest
        } = multiplyPriceCouponInput;

        const restOptions = multiplyPriceCouponOptions.map((option) => {
          const {
            couponOptionFilialBranchesToAdd,
            couponOptionFilialBranchesToRemove,
            ...rest
          } = option;

          return rest;
        });
        try {
          const { data: { createCouponAdmin: { id } } } = await createCouponAdmin({
            variables: {
              merchantId,
              createCouponInput: {
                ...rest,
                couponOptions: restOptions,
              },
            },
          });

          if (prevImage.key) {
            await uploadPreviewImage({
              variables: {
                file: prevImage.key,
                couponId: id,
              },
            });
          }
          // eslint-disable-next-line no-restricted-syntax
          for await (const image of galleryImages) {
            uploadCouponGalleryPicture({
              variables: {
                position: image.position,
                file: image.file,
                couponId: id,
              },
            });
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e.message);
        }
      } else {
        const {
          id,
          filialBranches,
          couponGoldenHours,
          ...restInputOptions
        } = multiplyPriceCouponInput;

        const { couponOptionsToCreate, couponOptionsToUpdate, couponOptionsToDelete } = optionsForEdit;

        const {
          discount: discountInit,
        } = couponInputInitState;

        const restCouponOptionsToCreate = couponOptionsToCreate.map((option) => {
          const {
            id, currentPrice, couponOptionFilialBranchesToAdd, couponOptionFilialBranchesToRemove, countPriceByDiscount,
            ...rest
          } = option;

          return {
            ...rest,
            createPrice: +rest.createPrice * 100,
            discount: countPriceByDiscount ? +rest.discount ?? 0 : null,
            oldPrice: !countPriceByDiscount && rest.oldPrice ? +rest.oldPrice * 100 : null,
          };
        });
        const restCouponOptionsToUpdate = couponOptionsToUpdate.map((option) => {
          const {
            currentPrice,
            filialBranches,
            countPriceByDiscount,
            oldPrice,
            discount,
            couponOptionFilialBranchesToAdd,
            couponOptionFilialBranchesToRemove,
            ...rest
          } = option;

          const discountCouponOptionsToUpdate = () => {
            if (!countPriceByDiscount) return null;
            return discount === null ? 0 : +discount;
          };

          return {
            ...rest,
            createPrice: +rest.createPrice * 100,
            countPriceByDiscount,
            discount: discountCouponOptionsToUpdate(),
            oldPrice: oldPrice ? +oldPrice * 100 : null,
            couponOptionFilialBranchesToAdd: couponOptionFilialBranchesToAdd.length > 0
              ? couponOptionFilialBranchesToAdd : null,
            couponOptionFilialBranchesToRemove: couponOptionFilialBranchesToRemove.length > 0
              ? couponOptionFilialBranchesToRemove : null,
          };
        });

        const { deleteGalleryImages, uploadNewGalleryImages } = editGalleryPicture;
        const { newPreviewPicture, prevImageForDeleteId } = editPreviewPicture;

        const {
          couponFilialBranchesToAdd,
          couponFilialBranchesToRemove,
          couponGoldenHoursToCreate,
          couponGoldenHoursToDelete,
          couponGoldenHoursToUpdate,
          countPriceByDiscount,
          createPrice,
          discount,
          oldPrice,
          ...restValueInputOptions
        } = restInputOptions;

        const { subCategoryId } = couponInput;

        const updateOnlyChangedFields = (newValue, initValue, name = 'all') => {
          if (!newValue) return false;
          if (name === 'discount') return +newValue !== +initValue;
          return +newValue === +initValue * 100;
        };

        const pricesChangedFields = () => {
          if (countPriceByDiscount) {
            return {
              createPrice: createPrice ? +createPrice : undefined,
              discount: updateOnlyChangedFields(discount, discountInit, 'discount') ? discount : 0,
            };
          }
          return {
            createPrice: createPrice ? +createPrice : undefined,
            oldPrice: oldPrice ? +oldPrice : undefined,
          };
        };

        try {
          await updateCouponByIdAdmin({
            variables: {
              couponUpdateInput: {
                ...restValueInputOptions,
                ...pricesChangedFields(),
                countPriceByDiscount,
                merchantId,
                subCategoryId: subCategoryId || null,
                isHotDeal: couponInput.isHotDeal,
                couponOptionsToCreate: restCouponOptionsToCreate.length > 0 ? restCouponOptionsToCreate : null,
                couponOptionsToUpdate: restCouponOptionsToUpdate.length > 0 ? restCouponOptionsToUpdate : null,
                couponOptionsToDelete: couponOptionsToDelete.length > 0 ? couponOptionsToDelete : null,
                couponFilialBranchesToAdd: couponFilialBranchesToAdd.length > 0
                  ? couponFilialBranchesToAdd : null,
                couponFilialBranchesToRemove: couponFilialBranchesToRemove.length > 0
                  ? couponFilialBranchesToRemove : null,
                couponGoldenHoursToCreate: couponGoldenHoursToCreate.length > 0
                  ? couponGoldenHoursToCreate : null,
                couponGoldenHoursToDelete: couponGoldenHoursToDelete.length > 0
                  ? couponGoldenHoursToDelete : null,
                couponGoldenHoursToUpdate: couponGoldenHoursToUpdate.length > 0
                  ? couponGoldenHoursToUpdate : null,
              },
              id: idCoupon,
            },
          });

          if (newPreviewPicture) {
            await uploadPreviewImage({
              variables: {
                file: prevImage.key,
                couponId: idCoupon,
              },
            });
          }
          if (prevImageForDeleteId) {
            await deleteCouponPreviewPicture({
              variables: {
                couponPreviewPictureId: prevImageForDeleteId,
              },
            });
          }

          if (deleteGalleryImages.length > 0) {
            // eslint-disable-next-line no-restricted-syntax,no-unused-vars
            for await (const image of deleteGalleryImages) {
              deleteCouponGalleryPicture({
                variables: {
                  couponGalleryPictureId: image.uid,
                },
              });
            }
          }
          const galleryImagesWithIdFromBacket = galleryImages
            .filter((images) => images.name)
            .map((images) => images.uid);

          if (uploadNewGalleryImages.length > 0) {
            // eslint-disable-next-line no-restricted-syntax
            for await (const image of uploadNewGalleryImages) {
              const { data: { uploadCouponGalleryPicture: { id } } } = await uploadCouponGalleryPicture({
                variables: {
                  position: image.position,
                  file: image.file,
                  couponId: idCoupon,
                },
              });
              const indexImageForAddInNeedPosition = galleryImages
                .findIndex((imgGallery) => imgGallery.uid === image.uid);

              // add id in need position
              // galleryImagesWithIdFromBacket.splice(indexImageForAddInNeedPosition, 0, id);

              setOrderCouponGalleryPictures({
                variables: {
                  ids: galleryImagesWithIdFromBacket.splice(indexImageForAddInNeedPosition, 0, id),
                },
              });
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e.message);
        }
      }
    } else {
      setAllErrors(errors);
    }
  }, [createCouponAdmin, deleteCouponGalleryPicture, deleteCouponPreviewPicture, navigate,
    setOrderCouponGalleryPictures, updateCouponByIdAdmin, uploadCouponGalleryPicture, uploadPreviewImage]);

  if (dataCreateCoupon) {
    navigate('/admin/coupons?status=ON_APPROVE', { replace: true });
  }
  if (dataUpdateCoupon) {
    navigate(-1, { replace: true });
  }

  return {
    sendData,
    loadingCreateCoupon,
    loadingUpdateCoupon,
    allErrors,
  };
};

export default useCreateCoupon;
