import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { CREATE_ORDER } from '../../../api/api-order/query';

const useCreateOrder = () => {
  const [createOrder, { loading }] = useMutation(CREATE_ORDER);

  const createOrderHandler = useCallback(async (promoCode = undefined) => {
    try {
      const { data: { createOrder: createOrderData } } = await createOrder({
        variables: {
          promoCode,
        },
      });
      const { payUrl } = createOrderData;
      window.location.replace(payUrl);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [createOrder]);

  return {
    createOrderHandler,
    loadingCreateOrder: loading,
  };
};
export default useCreateOrder;
