import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import cryBanan from '../../../assets/images/cryBanan.png';
import InputComponent from '../../Common_components/InputComponent';
import ButtonComponent from '../../Common_components/ButtonComponent';
import { regEmail } from '../../../helpers/regularExpression/regularExpression';
import './forgot-password.scss';
import useSendEmail from './hooks/gql/useSendEmail';
import Loader from '../../Loader';

const ForgotPassword = () => {
  const [emailValue, setEmailValue] = useState('');
  const [error, serError] = useState('');
  const { loading, errorForgot, sendEmailHandler } = useSendEmail();

  const setEmailValueHandler = useCallback((_, value) => {
    serError('');
    setEmailValue(value);
  }, []);

  const sendLetterOnEmail = useCallback(() => {
    if (emailValue.match(regEmail)) {
      sendEmailHandler(emailValue);
    } else {
      serError('Incorrect email format!');
    }
  }, [emailValue, sendEmailHandler]);

  useEffect(() => {
    if (errorForgot) {
      serError('Email address does not exist!');
    }
  }, [errorForgot]);

  return (
    <>
      {loading && <Loader />}
      <div className="sing-in-card">
        <div className="sing-in-card__image">
          <img
            src={cryBanan}
            alt="img"
          />
        </div>
        <h1 className="sing-in-card__title">Forgot Password?</h1>
        <div className="sing-in-card__text-section">
          <p className="sing-in-card__text">
            Enter the email address you used when you joined
            and we’ll send you instructions to reset your password.
          </p>
          <p className="sing-in-card__text">
            For security reasons, we do NOT store your password.
            So rest assured that we will never send your password via email.
          </p>
        </div>
        <div className="sing-in-card__form">
          <InputComponent
            value={emailValue}
            callback={setEmailValueHandler}
            keyField="email"
            label="Email"
            isAutoFocus
            labelClass="label-display-block"
            error={error}
          />
        </div>
        <ButtonComponent
          title="Send Reset Instructions"
          iconComponent={null}
          addClass="sing-in-card__action-btn"
          callback={sendLetterOnEmail}
        />
      </div>
    </>
  );
};

export default memo(ForgotPassword);
