import React from 'react';
import TableMerchant from './TableMerchant/TableMerchant';
import HeaderComponent from './Header';

const MerchantTable = () => (
  <>
    <HeaderComponent
      navigateTo="create-merchant"
      title="Merchants"
    />
    <TableMerchant />
  </>
);

export default MerchantTable;
