import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import s from './ShowSecurity.module.scss';
import Loader from '../../Loader';
import { ArrowLeft } from '../../../constants/icons';

const ShowSecurity = ({
  activateLoading, activateVoucher, totalData,
}) => (
  <div className={s.wrapper}>
    {activateLoading && <Loader />}
    <Link
      className={s.link}
      to="/security"
    >
      <ArrowLeft />
      Back
    </Link>
    <div className={s.content}>
      {totalData?.previewImageLink && (
        <img
          className={s.preview}
          src={totalData?.previewImageLink}
          alt="voucher preview"
        />
      )}
      <div className={`${s.description} ${!totalData.previewImageLink && s.option}`}>
        <span className={s.title}>{totalData?.title}</span>
        <div>
          <span className={s.subtitle}>
            Company
          </span>
          <span className={s.data}>
            {totalData?.company}
          </span>
        </div>
        <div>
          <span className={s.subtitle}>
            Voucher ID
          </span>
          <span className={s.data}>
            {totalData?.voucherNumber}
          </span>
        </div>
        <div>
          <span className={s.subtitle}>
            Payment date
          </span>
          <span className={s.data}>
            {totalData?.paymentDate}
          </span>
        </div>
        {totalData.validDate && (
          <div>
            <span className={s.subtitle}>
              Voucher Valid
            </span>
            <span className={s.data}>
              {totalData?.validDate}
            </span>
          </div>
        )}
        {totalData?.activeDate && (
          <div>
            <span className={s.subtitle}>
              Activated
            </span>
            <span className={s.data}>
              {totalData?.activeDate}
            </span>
          </div>
        )}
      </div>
    </div>
    <div className={s.bottom}>
      {totalData?.status === 'ACTIVE'
        ? (
          <>
            <span>This voucher is already activated</span>
            <Link
              className={s.backLink}
              to="/security"
              replace
            >
              Back
            </Link>
          </>
        )
        : (
          <button
            onClick={activateVoucher}
            className={s.button}
          >
            Activate
          </button>
        )}
    </div>
  </div>
);

ShowSecurity.propTypes = {
  activateVoucher: PropTypes.func.isRequired,
  activateLoading: PropTypes.bool.isRequired,
  totalData: PropTypes.shape({
    status: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    previewImageLink: PropTypes.string,
    company: PropTypes.string.isRequired,
    voucherNumber: PropTypes.string.isRequired,
    paymentDate: PropTypes.string.isRequired,
    validDate: PropTypes.string,
    activeDate: PropTypes.string,
  }),
};

ShowSecurity.defaultProps = {
  totalData: null,
};

export default memo(ShowSecurity);
