import React from 'react';
import { Tag, Typography } from 'antd';
import moment from 'moment/moment';
import {
  finalBackgroundColorTagStatus,
  finalColorTagTextStatus,
  finalTextTagStatus,
} from '../../../../constants/general/tag-status';

const useGetActiveVouchers = () => [
  {
    title: 'ID',
    dataIndex: 'voucherNumber',
    ellipsis: true,
    key: 'voucherNumber',
    width: '12%',
    render: (title) => (
      <Typography.Text>
        {title}
      </Typography.Text>
    ),
  },
  {
    title: 'Coupon/Option',
    dataIndex: 'title',
    ellipsis: true,
    key: 'title',
    width: '58%',
    render: (title) => (
      <Typography.Text>
        {title}
      </Typography.Text>
    ),
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '10%',
    render: (status) => (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          columnGap: '6px',
        }}
      >
        <Tag
          color={finalBackgroundColorTagStatus(status)}
          style={{
            color: finalColorTagTextStatus(status),
          }}
        >
          {finalTextTagStatus(status)}
        </Tag>
      </div>
    ),
  },
  {
    title: 'Activation',
    dataIndex: 'activateDate',
    key: 'activateDate',
    ellipsis: true,
    width: '12%',
    render: (date) => (
      <Typography.Text>
        {
          date ? moment(date).format('DD MMMM, YYYY') : ''
        }
      </Typography.Text>
    ),
  },
  {
    title: 'Time',
    dataIndex: 'timeActivate',
    ellipsis: true,
    key: 'timeActivate',
    width: '8%',
    render: (time) => (
      <Typography.Text>
        {time}
      </Typography.Text>
    ),
  },
];

export default useGetActiveVouchers;
