import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { CREATE_EMPLOYEE, FIND_ALL_EMPLOYEES } from '../../../../api/employees/query';

const useCreateEmploy = () => {
  const [createEmployee, { loading, error: errorCreateEmploy }] = useMutation(CREATE_EMPLOYEE, {
    refetchQueries: [FIND_ALL_EMPLOYEES],
  });

  const createEmployByAdmin = useCallback(async (data) => {
    const {
      firstName, lastName, email, role,
    } = data;
    await createEmployee({
      variables: {
        createEmployeeInput: {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.trim(),
          role,
        },
      },
    });
  }, [createEmployee]);

  return {
    createEmployByAdmin,
    loadingCreateEmploy: loading,
    errorCreateEmploy,
  };
};
export default useCreateEmploy;
