import React, { memo } from 'react';
import '../coupon.scss';
import PropTypes from 'prop-types';
import Option from './Option';

const AddOption = (props) => {
  const {
    couponOptions,
    copyCouponOptions,
    deleteCouponOptions,
    merchants,
    activeMerchant,
    vatValue,
    setOptionForAddOptions,
    setOptionFilialBranchesForAddOptions,
    errors,
  } = props;
  return (
    <div>
      {
        couponOptions && couponOptions.map((addOpt) => (
          <Option
            key={addOpt.id}
            couponInput={addOpt}
            copyCouponOptions={copyCouponOptions}
            deleteCouponOptions={deleteCouponOptions}
            merchants={merchants}
            activeMerchant={activeMerchant}
            vatValue={vatValue}
            setOptionForAddOptions={setOptionForAddOptions}
            setOptionFilialBranchesForAddOptions={setOptionFilialBranchesForAddOptions}
            error={errors && errors[addOpt.id]}
          />
        ))
      }
    </div>
  );
};

AddOption.propTypes = {
  couponOptions: PropTypes.oneOfType([PropTypes.array]).isRequired,
  copyCouponOptions: PropTypes.func.isRequired,
  deleteCouponOptions: PropTypes.func.isRequired,
  merchants: PropTypes.oneOfType([PropTypes.object]),
  activeMerchant: PropTypes.string,
  vatValue: PropTypes.number,
  setOptionForAddOptions: PropTypes.func.isRequired,
  setOptionFilialBranchesForAddOptions: PropTypes.func.isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]),
};
AddOption.defaultProps = {
  vatValue: undefined,
  merchants: undefined,
  activeMerchant: null,
  errors: undefined,
};
export default memo(AddOption);
