import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  CREATE_PROMO_CODE_ADMIN,
  GeneratePromoCodeSequence,
  GetPromoCodes, UPDATE_PROMO_CODE_BY_ID_ADMIN,
} from '../../../../../api/PromoCodes/query';
import { DiscountUnits, Quantity } from '../../../../../constants/promoCodes/promoCodes';

const useSendPromoCode = () => {
  const [createPromoCodeAdmin,
    {
      data,
      loading: createPromoCodeLoading,
    },
  ] = useMutation(CREATE_PROMO_CODE_ADMIN, {
    refetchQueries: [GetPromoCodes],
  });

  const [updatePromoCodeByIdAdmin,
    {
      data: dataUpdate,
      loading: updatePromoCodeLoading,
    },
  ] = useMutation(UPDATE_PROMO_CODE_BY_ID_ADMIN, {
    refetchQueries: [GetPromoCodes],
  });

  const [generatePromoCode] = useLazyQuery(GeneratePromoCodeSequence);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id') || '';

  const createPromoCodeAdminHandler = useCallback(async (data) => {
    const {
      discount: discountValue,
      availableAmount: availableAmountValue,
      quantity,
      tied,
      discountUnit,
      ...restValue
    } = data;

    if (id === 'create-promo-code') {
      const { data: promoCode } = await generatePromoCode();

      const { generatePromoCodeSequence } = promoCode;

      createPromoCodeAdmin({
        variables: {
          createPromoCodeInput: {
            ...restValue,
            promoCode: generatePromoCodeSequence,
            discountUnit,
            discount: discountUnit === DiscountUnits.ABSOLUTE ? +discountValue * 100 : +discountValue,
            availableAmount: quantity === Quantity.SINGLE ? 1 : +availableAmountValue,
          },
        },
      });
    } else {
      const { id, ...restV } = restValue;
      updatePromoCodeByIdAdmin({
        variables: {
          id,
          updatePromoCodeInput: {
            ...restV,
            discountUnit,
            discount: discountUnit === DiscountUnits.ABSOLUTE ? +discountValue * 100 : +discountValue,
            availableAmount: quantity === Quantity.SINGLE ? 1 : +availableAmountValue,
          },
        },
      });
    }
  }, [createPromoCodeAdmin, generatePromoCode, id, updatePromoCodeByIdAdmin]);

  if (data) {
    const { createPromoCodeAdmin } = data;
    return {
      createPromoCodeLoading,
      createPromoCodeData: createPromoCodeAdmin,
      createPromoCodeAdminHandler,
    };
  }

  if (dataUpdate) {
    const { updatePromoCodeByIdAdmin } = dataUpdate;
    return {
      updatePromoCodeLoading,
      updatePromoCodeData: updatePromoCodeByIdAdmin,
      createPromoCodeAdminHandler,
    };
  }
  return {
    createPromoCodeLoading,
    updatePromoCodeLoading,
    createPromoCodeAdminHandler,
  };
};

export default useSendPromoCode;
