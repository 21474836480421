import { regEmail, regPassword } from '../../helpers/regularExpression/regularExpression';
import { PASSWORD_ERROR } from '../errors';

export const PROFILE_PASSWORD_SCHEMA = {
  currentPassword: {
    required: true,
    errorMessage: 'This field is required!',
  },
  newPassword: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [
      {
        errorMessage: 'New password and old password are equal!',
        ruleProp: 'currentPassword',
        ruleValidation: (item, ruleProp) => item !== ruleProp,
      },
      {
        errorMessage: PASSWORD_ERROR,
        ruleValidation: (item) => item.match(regPassword),
      },
    ],
  },
  confirmPassword: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [{
      errorMessage: 'Passwords are not equal!',
      ruleProp: 'newPassword',
      ruleValidation: (item, ruleProp) => (item === ruleProp),
    }],
  },
};

export const PROFILE_MERCHANT_PASSWORD_SCHEMA = {
  currentPassword: {
    required: true,
    errorMessage: 'This field is required!',
  },
  newPassword: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [
      {
        errorMessage: 'New password and old password are equal!',
        ruleProp: 'currentPassword',
        ruleValidation: (item, ruleProp) => item !== ruleProp,
      },
      {
        errorMessage: PASSWORD_ERROR,
        ruleValidation: (item) => item.match(regPassword),
      },
    ],
  },
  confirmPassword: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [{
      errorMessage: 'Passwords are not equal!',
      ruleProp: 'newPassword',
      ruleValidation: (item, ruleProp) => (item === ruleProp),
    }],
  },
};

export const PROFILE_ACCOUNT_INFO_SCHEMA = {
  email: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [{
      errorMessage: 'Incorrect email address!',
      ruleValidation: (item) => item.match(regEmail),
    }],
  },
  phone: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [
      {
        errorMessage: 'Incorrect phone number!',
        ruleProp: 'countNumberInPhone',
        ruleValidation: (item, ruleProp) => item.length === ruleProp,
      },
    ],
  },
};
