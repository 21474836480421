import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  GET_ALL_MERCHANTS_ADMIN,
  UPDATE_FILIAL_BRANCH_ADMIN,
} from '../../../../../api/create-merchant/query';

const useUpdateFilialBranches = () => {
  const [updateFilialBranchAdmin, { loading: loadingUpdateFilialBranch }] = useMutation(UPDATE_FILIAL_BRANCH_ADMIN, {
    refetchQueries: [GET_ALL_MERCHANTS_ADMIN],
  });
  const updateFilialBranch = useCallback((filialUpdate) => {
    const {
      id, couponGoldenHours, couponGoldenHoursToCreate, couponGoldenHoursToDelete,
      couponGoldenHoursToUpdate, countNumberInPhone, dialCode, location, ...rest
    } = filialUpdate;

    const { lat, lng } = location;

    const couponGoldenHoursToUpdateForSend = couponGoldenHoursToUpdate.map((hours) => {
      const { id, ...updateBusinessHourInput } = hours;
      return {
        id,
        updateBusinessHourInput,
      };
    });

    updateFilialBranchAdmin({
      variables: {
        id,
        updateFilialBranchInput: {
          ...rest,
          location: [lat, lng],
          businessHoursToCreate: couponGoldenHoursToCreate,
          businessHoursToDelete: couponGoldenHoursToDelete,
          businessHoursToUpdate: couponGoldenHoursToUpdateForSend,
        },
      },
    });
  }, [updateFilialBranchAdmin]);

  return {
    updateFilialBranch,
    loadingUpdateFilialBranch,
  };
};

export default useUpdateFilialBranches;
