const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

const getImgBase64Url = (key, format = 'svg+xml') => (
  fetch(`${process.env.REACT_APP_BUCKET_URL}/${key}`, { mode: 'cors', cache: 'no-cache' })
    .then((res) => res.blob())
    .then((blob) => getBase64(blob))
    .then((url) => `data:image/${format};base64,${url.split(',')[1]}`)
);

export default getImgBase64Url;
