import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Cookies from 'js-cookie';
import useForm from '../useForm/useForm';
import { setAccessTokenUser, setRoleUser } from '../../../../helpers/user-storage';
import { LOGIN } from '../../../../api/query';
import { COUPONS } from '../../../../constants/Routes';
import { ROLE_CUSTOMER, ROLE_EMPLOY_SECURITY } from '../../../../constants/employ';
import { SETTING_OF_COOKIES, TITLE_COOKIES } from '../../../../constants/general/tag-status';

const useLoginUser = () => {
  const [loginUser, { data, loading, error }] = useMutation(LOGIN);
  const { form, handleChange } = useForm({
    email: '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const sendData = async () => {
    const { email, password } = form;

    try {
      await loginUser({
        variables: {
          loginUserInput: {
            email: email.trim().toLowerCase(),
            password: password.trim(),
          },
        },
      });
      setErrorMessage('');
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.message);
    }
  };

  if (data && !errorMessage) {
    const { login: { role, accessToken, refreshToken } } = data;
    setRoleUser(role);
    setAccessTokenUser(accessToken);
    Cookies.set(TITLE_COOKIES, refreshToken, SETTING_OF_COOKIES);

    if (role === ROLE_EMPLOY_SECURITY) {
      setErrorMessage('Access denied with Security account');
    } else if (role !== ROLE_CUSTOMER) {
      navigate(`/admin/${COUPONS}`, { replace: true });
    } else {
      setErrorMessage('User not found!');
    }
  }
  // aleksandrkosmatkov@gmail.com - 123456789

  return {
    handleChange,
    sendData,
    loading,
    error,
    errorMessage,
  };
};

export default useLoginUser;
