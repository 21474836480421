import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import s from './table-info.module.scss';
import ModalSubdivisionsContainer from '../modals/subdivisions/ModalSubdivisionsContainer';
import TableMerchant from './TableMerchant';

export const TableInfo = (props) => {
  const {
    tab,
    data,
    columns,
    addMethod,
    error,
    lengthSubdivision,
  } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const subdivisionsInfoInit = {
    id: '',
    title: '',
    address: '',
    phone: '',
    couponGoldenHours: [],
    couponGoldenHoursToCreate: [],
    couponGoldenHoursToUpdate: [],
    couponGoldenHoursToDelete: [],
  };

  const setOpenModalHandler = () => {
    setIsOpenModal((prev) => !prev);
  };

  const finalBtnClass = error && !lengthSubdivision ? `${s.btn} ${s.error}` : s.btn;

  const chooseModalWindow = () => {
    switch (tab) {
    default: return (
      <ModalSubdivisionsContainer
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        addMethod={addMethod}
        state={subdivisionsInfoInit}
      />
    );
    }
  };

  return (
    <>
      <TableMerchant
        dataSource={data}
        columns={columns}
      />
      <Button
        icon={<PlusOutlined />}
        className={finalBtnClass}
        onClick={setOpenModalHandler}
      >
        Add Subdivision
      </Button>
      {error && !lengthSubdivision && <div className={s.errorText}>{error}</div>}
      {isOpenModal && chooseModalWindow()}
    </>
  );
};

TableInfo.propTypes = {
  tab: PropTypes.string.isRequired,
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  data: PropTypes.oneOfType([PropTypes.array]),
  addMethod: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  lengthSubdivision: PropTypes.number.isRequired,
};

TableInfo.defaultProps = {
  data: undefined,
};

export default memo(TableInfo);
