import { useMutation } from '@apollo/client';
import { UpdateCategory } from '../../../../../api/query';

const useUpdateCategory = () => {
  const [updateCategory, { data, loading, error }] = useMutation(UpdateCategory);

  const sendData = (id, title, isPublished, showInHeader) => updateCategory({
    variables: {
      updateCategoryInput: {
        title: title.trim(),
        isPublished,
        showInHeader,
      },
      id,
    },
  });

  return {
    sendData,
    data,
    loading,
    errorCategory: error,
  };
};

export default useUpdateCategory;
