import { Button } from 'antd';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './button.module.scss';

const ButtonComponent = ({
  title, callback, addClass, disabled,
}) => {
  const onClickHandler = () => {
    callback();
  };
  return (
    <Button
      disabled={disabled}
      type="primary"
      className={`${s.button} ${addClass}`}
      onClick={onClickHandler}
    >
      {title}
    </Button>
  );
};
ButtonComponent.propTypes = {
  title: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  addClass: PropTypes.string,
  disabled: PropTypes.bool,
};
ButtonComponent.defaultProps = {
  addClass: '',
  disabled: false,
};
export default memo(ButtonComponent);
