import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { UpdateCommonSettings } from '../../../../api/CommonSettings/query';

const useUpdateCommonSettings = () => {
  const [update, { data }] = useMutation(UpdateCommonSettings);

  const updateCommonSettings = useCallback((vat) => {
    update({
      variables: {
        updateCommonSettingsInput: {
          vat,
        },
      },
    });
  }, [update]);

  return {
    updateCommonSettings,
    data,
  };
};

export default useUpdateCommonSettings;
