import React from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from '../../Common_components/ButtonComponent';
import './header.scss';
import { DownLoadIconExelFile } from '../../../constants/icons';
import SearchInputComponent from '../../Common_components/SearchInputComponent';
import useDebouncedSearch from '../../../hooks/useDebouncedSearch/useDebouncedSearch';

const HeaderComponent = ({ callback }) => {
  const { search, onFilterChange, onEnterFilterChange } = useDebouncedSearch();

  const callbackBtn = () => {
    callback();
  };

  return (
    <header className="header">
      <div className="header__row">
        <div className="header__left">
          <h1 className="header__title">Customers</h1>
        </div>
        <div className="header__right">
          <ButtonComponent
            title="Export to Excel"
            callback={callbackBtn}
            addClass="btn-exel"
            iconComponent={<DownLoadIconExelFile className="icon-exel" />}
          />
          <SearchInputComponent
            value={search}
            callback={onFilterChange}
            placeholder="ID, Name, Email, Phone..."
            addClass="searchInput"
            onEnterCallback={onEnterFilterChange}
          />
        </div>
      </div>
    </header>
  );
};

HeaderComponent.propTypes = {
  callback: PropTypes.func.isRequired,
};
export default HeaderComponent;
