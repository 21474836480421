import React, { memo, useContext, useState } from 'react';
import { Form, Input, Pagination } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import MyCouponItem from './MyCouponItem';
import s from './MobileMyCoupons.module.scss';
import { ArrowLeft, Cross, SearchIcon } from '../../../constants/icons';
import { PROFILE } from '../../../constants/Routes';
import UserContext from '../../Layout/context/userContext';
import useProfileCoupons from '../../Profile/hooks/gql/useProfileCoupons';
import Modal from '../../Modal';
import Loader from '../../Loader';
import useCouponRefund from '../../Profile/hooks/gql/useCouponRefund';
import MobileInvoiceModal from './MobileInvoiceModal';

const MobileMyCoupons = () => {
  const [form] = Form.useForm();

  const { userData } = useContext(UserContext);

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page') || '1';

  const onPageChange = (page) => {
    setSearchParams({ ...Object.entries(searchParams), page });
    document.documentElement.scrollTo(0, 0);
  };

  const [isActive, setIsActive] = useState('none');

  const profileName = `${userData?.firstName} ${userData?.lastName}`;

  const {
    count, vouchersData, getCouponsLoading, search, onFilterChange, totalOrderData, fetchOrderData,
  } = useProfileCoupons();

  const { refundLoading, refundVoucher, setSelectedId } = useCouponRefund();

  const refundCallback = (values) => {
    const { comment } = values;
    if (comment) {
      refundVoucher(comment)
        .then(() => {
          setIsActive('none');
          form.setFields([{
            name: 'comment',
            value: '',
          }]);
        });
    } else {
      form.setFields([{
        name: 'comment',
        errors: ['This field cannot be empty'],
      }]);
    }
  };

  const mappedCoupons = vouchersData?.length > 0 && vouchersData.map(({
    price, couponTitle, validDueDate, id, invoiceId,
  }) => {
    const isDisabled = validDueDate && moment().diff(validDueDate, 'seconds') >= 0;
    return (
      <MyCouponItem
        id={id}
        key={id}
        setIsActive={setIsActive}
        invoiceId={invoiceId}
        fetchOrderData={fetchOrderData}
        title={couponTitle}
        disabled={isDisabled}
        price={price}
        validDueDate={validDueDate}
        setSelectedId={setSelectedId}
      />
    );
  });

  return (
    <div className={s.main}>
      {(getCouponsLoading || refundLoading) && <Loader />}
      <Modal
        isActive={isActive !== 'none'}
        hideModalHandler={() => setIsActive('none')}
      >
        <div className={s.header}>
          <span className={s.title}>
            {isActive === 'receipt' ? 'Tax invoice' : 'Refund'}
          </span>
          <div onClick={() => setIsActive('none')}>
            <Cross />
          </div>
        </div>
        {isActive === 'receipt'
          ? <MobileInvoiceModal totalOrderData={totalOrderData} />
          : (
            <Form
              form={form}
              onFinish={refundCallback}
            >
              <div className={s.refund}>
                <span>
                  Please leave a comment for the merchant indicating the reasons for the refund.
                </span>
                <Form.Item
                  label="Comment"
                  name="comment"
                  className={s.formItem}
                  rules={[
                    { required: true, message: 'This field is required!' },
                    { whitespace: true, message: 'This field is required!' },
                  ]}
                >
                  <Input.TextArea
                    placeholder="Write your comment..."
                    autoSize={{ minRows: 3, maxRows: 7 }}
                    maxLength={300}
                  />
                </Form.Item>
                <span>
                  Kindly expect a response within 24-48 business hours.
                </span>
                <button
                  type="submit"
                  onClick={refundCallback}
                >
                  Request refund
                </button>
              </div>
            </Form>
          )}
      </Modal>
      <div
        className={s.back}
        onClick={() => navigate(PROFILE)}
      >
        <ArrowLeft />
        <span>{profileName}</span>
      </div>
      <span className={s.title}>My Coupons</span>
      <div className={s.search}>
        <Input
          placeholder="Search..."
          type="text"
          value={search}
          onChange={onFilterChange}
        />
        <SearchIcon />
      </div>
      <div className={s.container}>
        {mappedCoupons}
      </div>
      <Pagination
        className={s.pagination}
        current={Number(page)}
        total={count}
        onChange={onPageChange}
      />
    </div>
  );
};

export default memo(MobileMyCoupons);
