import React, { Fragment } from 'react';
import { uid } from 'uid';

const splitNewLines = (string) => {
  const splitArray = string?.split('\n');

  return splitArray?.length > 1
    ? splitArray.map((line) => {
      const id = uid();
      return (
        <Fragment key={id}>
          {line}
          <br />
        </Fragment>
      );
    })
    : string;
};

export default splitNewLines;
