import React, {
  memo, useCallback, useState,
} from 'react';
import PropTypes from 'prop-types';
import ModalSubdivisions from './ModalSubdivisions';
import Portal from '../../../../../../Common_components/Portal';

const ModalSubdivisionsContainer = ({
  state, isOpenModal, setIsOpenModal, addMethod,
}) => {
  const [subdivisionsInfo, setSubdivisionsInfoHandler] = useState(state);

  const clearSubdivisionsInfo = useCallback(() => {
    setSubdivisionsInfoHandler(state);
  }, [state]);

  const cancelSaveInfo = useCallback(() => {
    clearSubdivisionsInfo();
    setIsOpenModal();
  }, [clearSubdivisionsInfo, setIsOpenModal]);

  const setSubdivisionsInfoValue = useCallback((key, value) => {
    setSubdivisionsInfoHandler((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  return (
    <Portal>
      <ModalSubdivisions
        subdivisionsInfo={subdivisionsInfo}
        setSubdivisionsInfoHandler={setSubdivisionsInfoHandler}
        visibleModal={isOpenModal}
        onCancelModal={cancelSaveInfo}
        addMethod={addMethod}
        setSubdivisionsInfoValue={setSubdivisionsInfoValue}
      />
    </Portal>
  );
};

ModalSubdivisionsContainer.propTypes = {
  state: PropTypes.oneOfType([PropTypes.object]).isRequired,
  addMethod: PropTypes.func.isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  setIsOpenModal: PropTypes.func.isRequired,
};

export default memo(ModalSubdivisionsContainer);
