import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ModalPayment from './ModalPayment';
import Portal from '../../../../Common_components/Portal';

const ModalPaymentContainer = ({
  orderData, closeModalHandler, isOpenModal, isApplyVatCouponOfVoucher,
}) => (
  <Portal>
    <ModalPayment
      orderData={orderData}
      isOpenModal={isOpenModal}
      closeModalHandler={closeModalHandler}
      applyVat={isApplyVatCouponOfVoucher}
    />
  </Portal>
);

ModalPaymentContainer.propTypes = {
  orderData: PropTypes.shape({
    paymentDate: PropTypes.string.isRequired,
    electronicInvoice: PropTypes.string.isRequired,
    orderNumber: PropTypes.string.isRequired,
    paymentID: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    vouchers: PropTypes.array.isRequired,
  }).isRequired,
  isOpenModal: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
  isApplyVatCouponOfVoucher: PropTypes.bool.isRequired,
};

export default memo(ModalPaymentContainer);
