import { useLazyQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { TRY_ORDER } from '../../../api/api-order/query';
import useRemoveStockCoupons from './useRemoveStockCoupons';

const useTryOrder = () => {
  const [tryOrder, { data, loading: tryLoading }] = useLazyQuery(TRY_ORDER);

  const tryOrderCallback = useCallback(async () => {
    const { data: { tryCreateOrder } } = await tryOrder();
    if (tryCreateOrder.length) {
      return tryCreateOrder;
    }
    return null;
  }, [tryOrder]);

  const stockData = useMemo(() => {
    if (data) {
      return data.tryCreateOrder;
    }
    return [];
  }, [data]);

  const { removeCartStockCallback, removeStockLoading } = useRemoveStockCoupons();

  const removeCouponWithZeroCount = useCallback(async () => {
    const emptyIsd = stockData
      .filter(({ coupon: { currentAvailableAmount } }) => currentAvailableAmount === 0)
      .map(({ id }) => (id));
    await removeCartStockCallback(emptyIsd);
  }, [data]);

  const removeStockCoupons = useCallback(async () => {
    const ids = stockData.map(({ id }) => id);
    await removeCartStockCallback(ids);
  }, [data]);
  return {
    tryOrderCallback, tryLoading, removeStockCoupons, removeStockLoading, stockData, removeCouponWithZeroCount,
  };
};

export default useTryOrder;
