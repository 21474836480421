import { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MapContext from '../context/MapContext';

const MapContextWrapper = ({ children }) => {
  const [mapData, setMapData] = useState('');
  const setMapDataCallback = (data) => setMapData(data);

  const mapContext = useMemo(() => ({ mapData, setMapDataCallback }), [mapData]);

  return (
    <MapContext.Provider value={mapContext}>
      {children}
    </MapContext.Provider>
  );
};

MapContextWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(MapContextWrapper);
