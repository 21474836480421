import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'uid';
import './upload-gally-images.scss';
import { message } from 'antd';
import { CloseIcon } from '../../../constants/icons';

const UploadGalleryImages = ({
  images,
  setImages,
  changeOrderImagesInGallery,
  deleteImageFromGallery,
}) => {
  const [currentImage, setCurrentImage] = useState(null);
  const [isErrorDownload, setErrorDownload] = useState(false);

  const deleteImageHandler = (imgDelete) => {
    deleteImageFromGallery(imgDelete);
  };

  const onSelectFile = (e) => {
    setErrorDownload(false);
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    if (images.length + selectedFilesArray.length > 10) return;
    if (selectedFilesArray.length && selectedFilesArray.every((file) => file.size > 2097150)) {
      setErrorDownload(true);
      return;
    }

    const createdObjUrl = selectedFilesArray.map((file, index) => ({
      uid: uid(),
      file,
      url: URL.createObjectURL(file),
      position: index + images.length,
    }));

    setImages(createdObjUrl);
  };

  function dragStartHandler(e, img) {
    setCurrentImage(img);
  }

  function dragOverHandler(e) {
    e.preventDefault();
  }

  function dropHandler(e, img) {
    e.preventDefault();
    changeOrderImagesInGallery(images.map((i) => {
      if (i.url === img.url) {
        return { ...i, position: currentImage.position };
      }
      if (i.url === currentImage.url) {
        return { ...i, position: img.position };
      }
      return i;
    }));
  }

  const sortCards = (a, b) => a.position - b.position;

  const finallyUploadClass = images.length
    ? 'upload-images-gallery__upload-image upload-image'
    : 'upload-images-gallery__upload-image upload-image full';

  useEffect(() => {
    if (isErrorDownload) {
      message.config({
        maxCount: 1,
      });
      message.open({
        type: 'error',
        content: 'Images must smaller than 2MB!',
        duration: 3,
      });
    }
  }, [isErrorDownload]);

  return (
    <div className="gallery">
      {images?.sort(sortCards).map((img) => (
        <div
          key={img.url}
          className="upload-images-gallery__image"
          draggable
          onDragStart={(e) => dragStartHandler(e, img)}
          onDragOver={dragOverHandler}
          onDrop={(e) => dropHandler(e, img)}
        >
          <img
            src={img.url}
            alt="img"
          />
          <button
            className="delete-image"
            onClick={() => deleteImageHandler(img)}
          >
            <CloseIcon />
          </button>
          <div className="overlay" />
        </div>
      ))}
      {
        images.length < 10 && (
          <section className="upload-images-gallery">
            <label
              className={finallyUploadClass}
            >
              <p className="upload-image__title">
                Drag & drop or
                <span> Upload files</span>
              </p>
              {!images.length && (
                <div className="upload-image__info">
                  <p className="upload-image__support">Supports: JPEG, JPG, PNG</p>
                </div>
              ) }
              <p className="upload-image__count">
                {10 - images.length}
                {' '}
                images left
              </p>
              <input
                type="file"
                name="images"
                accept="image/jpeg, image/png"
                multiple
                onChange={onSelectFile}
              />
            </label>
          </section>
        )
      }
    </div>
  );
};

UploadGalleryImages.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  images: PropTypes.array.isRequired,
  setImages: PropTypes.func.isRequired,
  deleteImageFromGallery: PropTypes.func.isRequired,
  changeOrderImagesInGallery: PropTypes.func.isRequired,
};

export default memo(UploadGalleryImages);
