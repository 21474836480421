import { useMutation } from '@apollo/client';
import { useContext, useMemo, useState } from 'react';
import { CHANGE_PASSWORD, UPDATE_USER_SELF } from '../../../api/profile/query';
import { GET_USER_SELF } from '../../../api/query';
import UserContext from '../../Layout/context/userContext';

const useAccountForm = (form) => {
  const { userData } = useContext(UserContext);

  const [updateUser, { loading }] = useMutation(UPDATE_USER_SELF, {
    refetchQueries: [GET_USER_SELF],
  });

  const [sendPassword, { loading: passwordLoading, error: passwordError }] = useMutation(CHANGE_PASSWORD, {
    refetchQueries: [GET_USER_SELF],
  });

  const [isFormError, setIsFormError] = useState(false);

  const [isOpen, setIsOpen] = useState({ profile: false, password: false });

  const { password, profile } = isOpen;

  const setPasswordOpen = () => {
    setIsOpen((prev) => ({ ...prev, password: true }));
  };

  const setProfileOpen = () => {
    const { firstName, lastName, phone } = userData;
    setIsOpen((prev) => ({ ...prev, profile: true }));
    form.setFieldsValue({ firstName: firstName || '', lastName: lastName || '', phone: phone || '' });
  };

  const closeFormOnClick = () => {
    if (password) setIsOpen((prev) => ({ ...prev, password: false }));
    else setIsOpen((prev) => ({ ...prev, profile: false }));
  };

  const cancelOnClick = () => {
    closeFormOnClick();
    const { firstName, lastName, phone } = userData;
    form.setFieldsValue({ firstName: firstName || '-', lastName: lastName || '-', phone: phone || '-' });
    form.resetFields(['currentPassword', 'newPassword', 'confirmPassword']);
    if (isFormError) {
      setIsFormError(false);
    }
  };

  const totalTitle = useMemo(() => {
    if (profile) {
      return 'Edit information';
    }
    if (password) {
      return 'Edit Password';
    }
    return 'Account Information';
  }, [profile, password]);

  const saveProfileOnClick = () => {
    const values = form.getFieldsValue(['firstName', 'lastName', 'phone']);
    updateUser({ variables: { updateUserInput: values } })
      .then(() => {
        closeFormOnClick();
      });
  };

  const sendPasswordCallback = () => {
    const passData = form.getFieldsValue(['currentPassword', 'newPassword', 'confirmPassword']);
    const { currentPassword, newPassword, confirmPassword } = passData;
    if (currentPassword && newPassword && confirmPassword) {
      if (newPassword === confirmPassword) {
        sendPassword({
          variables: {
            changePasswordInput: passData,
          },
        })
          .then(() => {
            closeFormOnClick();
          })
          .catch((res) => {
            if (res.graphQLErrors[0].message === 'Passwords must differ') {
              form.setFields([{
                name: 'newPassword',
                errors: ['New and old passwords must be different'],
              }]);
            }
          });
      }
    }
  };

  const dataEditHandler = (e) => {
    const { name, value } = e.target;
    form.setFieldsValue({ [name]: value });
  };

  const handleErrorByValue = (value) => {
    if (!value) {
      if (!isFormError) {
        setIsFormError(true);
      }
    } else if (isFormError) {
      setIsFormError(false);
    }
  };

  return {
    loading,
    password,
    profile,
    totalTitle,
    isFormError,
    cancelOnClick,
    saveProfileOnClick,
    sendPasswordCallback,
    dataEditHandler,
    setPasswordOpen,
    setProfileOpen,
    handleErrorByValue,
    passwordLoading,
    passwordError,
  };
};

export default useAccountForm;
