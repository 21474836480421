import React, { memo } from 'react';
import {
  Outlet,
} from 'react-router-dom';
import './account-ant.scss';

const AccountComponent = () => (
  <Outlet />
);

export default memo(AccountComponent);
