import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import './commonCheckbox.scss';

const CommonCheckbox = ({
  value, onChange, keyValue, label, addClass,
}) => {
  const handleCheckboxChange = () => {
    onChange(!value, keyValue);
  };

  const classWrapper = `custom-checkbox ${addClass}`;

  return (
    <label className={classWrapper}>
      <Checkbox
        checked={value}
        onChange={handleCheckboxChange}
      />
      <span className="custom-checkbox__label">
        {label}
      </span>
    </label>
  );
};

CommonCheckbox.propTypes = {
  value: PropTypes.bool.isRequired,
  keyValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  addClass: PropTypes.string,
};

CommonCheckbox.defaultProps = {
  addClass: '',
};

export default CommonCheckbox;
