import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { DatePicker, Input, Radio } from 'antd';
import s from './ActivatedVouchers.module.scss';
import { ArrowLeft, Cross } from '../../constants/icons';
import Modal from '../Modal';
import VoucherItem from './VoucherItem';
import { SECURITY } from '../../constants/Routes';
import Loader from '../Loader';
import useActivatedVouchers from './hooks/useActivatedVouchers';

const ActivatedVouchers = () => {
  const navigate = useNavigate();

  const {
    startDate, currentDate, switchStartFilterDate, loading, setDate, totalData,
  } = useActivatedVouchers();

  const [value, setValue] = useState('Last 24 hours');

  const [isActive, setIsActive] = useState(false);

  const [tempData, setTempData] = useState(null);

  useEffect(() => {
    const callback = (e) => e.stopPropagation();
    const elements = document.querySelectorAll('.ant-picker-dropdown');
    elements.forEach((el) => el.addEventListener('onmousedown', callback));
    return () => elements.forEach((el) => el.removeEventListener('onmousedown', callback));
  }, []);

  const [isOpened, setIsOpened] = useState({ startOpened: false, endOpened: false });

  const { endOpened, startOpened } = isOpened;

  const setDateOnChange = (date, key) => {
    setDate((prev) => ({ ...prev, [key]: date }));
  };

  const closeDataPicker = () => {
    if (startOpened) {
      setIsOpened((prev) => ({ ...prev, startOpened: false }));
    } else if (endOpened) {
      setIsOpened((prev) => ({ ...prev, endOpened: false }));
    }
    setTempData(null);
  };

  const radioOnChange = useCallback((e) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (newValue !== 'Custom') {
      const startDateFilter = switchStartFilterDate(newValue);
      setDate((prev) => ({ ...prev, startDate: startDateFilter }));
      setIsActive(false);
    }
  }, [switchStartFilterDate, setDate]);

  const mappedVouchers = totalData?.length > 0 && totalData.map(({
    activationTime, activationDate, title, id, orderNumber,
  }) => (
    <VoucherItem
      activationTime={activationTime}
      activationDate={activationDate}
      title={title}
      id={orderNumber}
      key={id}
    />
  ));

  return (
    <div className={s.main}>
      {loading && <Loader />}
      {(startOpened || endOpened) && (
        <div
          className={s.closer}
          onClick={closeDataPicker}
        />
      )}
      <div
        className={s.back}
        onClick={() => navigate(SECURITY)}
      >
        <ArrowLeft />
        <span>Back</span>
      </div>
      <span className={s.title}>Activated vouchers</span>
      <Input
        className={s.input}
        onClick={() => setIsActive(true)}
        readOnly
        value={value}
      />
      <Modal
        hideModalHandler={() => setIsActive(false)}
        isActive={isActive}
        extraModalClass={(startOpened || endOpened) ? s.extraModal : ''}
      >
        <div className={s.modalTitle}>
          <span>Date</span>
          <div
            onClick={() => setIsActive(false)}
            className={s.icon}
          >
            <Cross />
          </div>
        </div>
        <Radio.Group
          onChange={radioOnChange}
          value={value}
          className={s.buttons}
        >
          <Radio value="All time">All time</Radio>
          <Radio value="Last month">Last month</Radio>
          <Radio value="Last week">Last week</Radio>
          <Radio value="Last 24 hours">Last 24 hours</Radio>
          <Radio value="Custom">Custom</Radio>
        </Radio.Group>
        <div className={s.pickers}>
          <DatePicker
            dropdownClassName={s.dropdown}
            inputReadOnly
            value={startDate}
            onChange={(date) => setTempData(date)}
            open={startOpened}
            onOpenChange={(open) => {
              if (open) setIsOpened((prev) => ({ ...prev, startOpened: open }));
            }}
            renderExtraFooter={() => (
              <div className={s.extra}>
                <button
                  onClick={() => {
                    setIsOpened((prev) => ({ ...prev, startOpened: false }));
                    setTempData(null);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setDateOnChange(tempData, 'startDate');
                    setIsOpened((prev) => ({ ...prev, startOpened: false }));
                  }}
                  className={s.set}
                >
                  Set date
                </button>
              </div>
            )}
            className={s.picker}
          />
          <DatePicker
            dropdownClassName={s.dropdown}
            value={currentDate}
            inputReadOnly
            onChange={(date) => setTempData(date)}
            open={endOpened}
            onOpenChange={(open) => {
              if (open) setIsOpened((prev) => ({ ...prev, endOpened: open }));
            }}
            renderExtraFooter={() => (
              <div className={s.extra}>
                <button
                  onClick={() => {
                    setIsOpened((prev) => ({ ...prev, endOpened: false }));
                    setTempData(null);
                  }}
                >
                  Cancel
                </button>
                <button
                  onClick={() => {
                    setDateOnChange(tempData, 'currentDate');
                    setIsOpened((prev) => ({ ...prev, endOpened: false }));
                  }}
                  className={s.set}
                >
                  Set date
                </button>
              </div>
            )}
            className={s.picker}
          />
        </div>
      </Modal>
      <div className={s.container}>
        {mappedVouchers}
      </div>
    </div>
  );
};

export default memo(ActivatedVouchers);
