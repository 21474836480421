import React, { memo, useContext } from 'react';
import s from './SignModals.module.scss';
import SignInModal from './SignInModal';
import SignUpModal from './SignUpModal';
import UserContext from '../Layout/context/userContext';

const SignModals = () => {
  const {
    isSignInActive,
    isSignUpActive,
    goToSignUpModalOnClick,
    goToSignInModalOnClick,
    setIsSignInActive,
    setIsSignUpActive,
  } = useContext(UserContext);

  return (
    <div className={s.modals}>
      <SignInModal
        isSignInActive={isSignInActive}
        setIsSignInActive={setIsSignInActive}
        goToSignUpModalOnClick={goToSignUpModalOnClick}
      />
      <SignUpModal
        isSignUpActive={isSignUpActive}
        setIsSignUpActive={setIsSignUpActive}
        goToSignInModalOnClick={goToSignInModalOnClick}
      />
    </div>
  );
};
export default memo(SignModals);
