import React, { memo } from 'react';
import s from './CookiesInfo.module.scss';
import mobileCondition from '../../../constants/mobileCondition';

const CookiesInfo = () => {
  const wrapperClassName = `${s.wrapper} ${mobileCondition && s.mobile}`;

  return (
    <div className={s.main}>
      <div className={wrapperClassName}>
        <div>
          <span>Cookies</span>
          <span>
            Form & Refine uses “cookies”. Cookies are text files that are stored on your computer,
            cell phone or tablet and help us to remember your preferences, analyse your interactions with the website,
            and personalise advertising. Cookies are reliable files and do not contain damaging malware.
          </span>
          <span>
            If you do not want information to be gathered about you, you should delete your cookies or deactivate
            cookies on the website. If you delete or deactivate cookies, the website might not operate at the optimal
            level and advertisements may be less relevant to you.
          </span>
          <span>
            If you do not delete and deactivate your cookies, you thereby accept our gathering and storing of your
            personal information using cookies without any legal liability for us.
          </span>
          <span>
            Form & Refine uses cookies to improve your user experience on our website, to target our marketing to your
            needs and to generate usage statistics for our website.
          </span>
          <span>
            Some cookies are stored until you shut down your browser, while others may be stored for longer.
          </span>
          <span>
            Form & Refine uses third-party cookies. A third-party cookie is a cookie that is placed by someone other
            than form and refine.com, for example by other websites that offer content on our website, suppliers for
            some of the functions on our website or statistical analysis programs. We also share information about
            your use of our site with our social media, advertising and analytics partners who may combine it with
            other information that you’ve provided to them or that they’ve collected from your use of their services.
          </span>
        </div>
        <div>
          <span>Google Analytics</span>
          <span>
            We use services from Google Analytics to collect web statistics. Among other things, cookies from
            this provider allow us to see which pages are visited, where the traffic comes from, etc. We use
            the information to measure and improve the user experience on our website and to follow up on sales
            and marketing campaigns.
          </span>
        </div>
        <div>
          <span>Google Ad Words/Remarketing</span>
          <span>
            The cookies from Google Ad Words make it possible to present interest-based advertising to website
            visitors as part of the Google Network. They are used to remember your information and settings when
            you visit websites that are part of Google’s advertising network. These websites can then show you
            relevant ads that refer to the content you previously viewed on websites that use the remarketing
            feature of Google
          </span>
        </div>
        <div>
          <span>Meta</span>
          <span>
            Cookies from Meta allow us to measure, optimize and build audience and catalogue products for
            advertising campaigns on Facebook and Instagram.
          </span>
        </div>
        <div>
          <span>Contact</span>
          <span>If you have questions or concerns regarding our Cookie Policy, please contact us via email:</span>
        </div>
      </div>
    </div>
  );
};

export default memo(CookiesInfo);
