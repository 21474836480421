import { useMutation } from '@apollo/client';
import { DELETE_BANNER_BY_ADMIN, GET_ALL_BANNERS_ADMIN } from '../../../../api/api-banners/query';

const useDeleteBanner = () => {
  const [deleteBannerByIdAdmin, { loading }] = useMutation(DELETE_BANNER_BY_ADMIN, {
    refetchQueries: [GET_ALL_BANNERS_ADMIN],
  });

  const deleteBannerByAdmin = (id) => {
    (async () => {
      try {
        await deleteBannerByIdAdmin({
          variables: {
            id,
          },
        });
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    })();
  };

  return {
    deleteBannerByAdmin,
    loadingDeleteBanner: loading,
  };
};
export default useDeleteBanner;
