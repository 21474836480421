import { gql } from '@apollo/client';

const GET_ALL_LOGS = gql`
    query getAllLogs (
        $offset: Float, 
        $limit: Float, 
        $entityId: String,
        $search: String,
        $action: LogActions,
        $dateRangeStart: DateTime,
        $dateRangeEnd: DateTime,
    ) {
        getAllLogs(
            offset: $offset, 
            limit: $limit, 
            entityId: $entityId,
            search: $search,
            action: $action,
            dateRangeStart: $dateRangeStart,
            dateRangeEnd: $dateRangeEnd,
        ) {
            logs {
                createdAt
                entityId
                user {
                    email
                    firstName
                    lastName
                }
                action
                description
            }
            count
        }
    }
`;

export default GET_ALL_LOGS;
