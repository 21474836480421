import { useQuery } from '@apollo/client';
import { GET_ORDER_BY_ID } from '../../../../api/api-order/query';

const useGetOrderInfo = (id) => {
  const { data = null, loading } = useQuery(GET_ORDER_BY_ID, {
    variables: {
      id,
    },
  });

  if (data && !loading) {
    const { getOrderById } = data;
    const { totalPrice } = getOrderById;

    return {
      applyOrder: { ...getOrderById, totalPrice: totalPrice / 100 },
      loading,
    };
  }
  return {
    loading,
  };
};
export default useGetOrderInfo;
