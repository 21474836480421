import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import s from './table-merchant.module.scss';
import './table-create-merch-ant.scss';

const TableComponent = ({ columns, dataSource }) => (
  <div className={`${s.table} tableMerch`}>
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      scroll={{
        y: '45vh', x: 0,
      }}
      rowKey={(rec) => rec.id}
    />
  </div>
);

TableComponent.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  dataSource: PropTypes.oneOfType([PropTypes.array]),
};
TableComponent.defaultProps = {
  dataSource: undefined,
};

export default memo(TableComponent);
