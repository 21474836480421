import React, { useCallback } from 'react';
import { Typography } from 'antd';
// import moment from 'moment';
import { roundingPrice } from '../../../../../constants/math';
import { DownLoadIcon, PayCheckIcon, RefundIcon } from '../../../../../constants/icons';
import DropdownBtnComponent from '../../../../Common_components/DropdownBtnComponent';
import useVoucherDownload from '../../../hooks/gql/useVoucherDownload';
import { formatDate } from '../../../../../helpers/couponPage/formatDate';

const useGetCouponsColumn = (openModalHandler, fetchOrderData, setSelectedId, setIsApplyVatCouponOfVoucher) => {
  const refundCouponHandler = useCallback((id) => {
    setSelectedId(id);
    openModalHandler('refund');
  }, [openModalHandler]);

  const { downloadAllVouchers, downloadVoucherInFile } = useVoucherDownload();

  const downloadVoucherCallback = useCallback(async (id) => {
    const file = await downloadAllVouchers(id);
    await downloadVoucherInFile([file]);
  }, []);

  const downloadPayCheckHandler = async (invoiceId, record) => {
    const { applyVat } = record;
    setIsApplyVatCouponOfVoucher(applyVat);
    await fetchOrderData(invoiceId);
    openModalHandler('payment');
  };

  return [
    {
      title: 'Coupon',
      dataIndex: 'couponTitle',
      key: 'couponTitle',
      ellipsis: true,
      width: '39%',
      render: (text) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: 'Price, AED',
      dataIndex: 'price',
      key: 'price',
      ellipsis: true,
      width: '13%',
      render: (currentPrice) => (
        <Typography.Text>
          {roundingPrice(currentPrice / 100)}
        </Typography.Text>
      ),
    },
    {
      title: 'Voucher valid',
      dataIndex: 'validDueDate',
      key: 'date',
      ellipsis: true,
      width: '20%',
      render: (text) => (
        <Typography.Text style={{ paddingLeft: !text ? '27%' : 'unset' }}>
          {text ? formatDate(text) : '-'}
        </Typography.Text>
      ),
    },
    {
      title: 'Voucher',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: '15%',
      render: (record) => (
        <Typography.Text
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            downloadVoucherCallback(record);
          }}
        >
          <DownLoadIcon />
          {' '}
          <span>Download</span>
        </Typography.Text>
      ),
    },
    {
      title: 'Receipt',
      dataIndex: 'invoiceId',
      key: 'invoiceId',
      width: '13%',
      render: (invoiceId, record) => (
        <Typography.Text
          style={{
            padding: '5px',
            cursor: 'pointer',
          }}
          onClick={() => downloadPayCheckHandler(invoiceId, record)}
        >
          <PayCheckIcon />
        </Typography.Text>
      ),
    },
    {
      key: 'actions',
      width: '10%',
      render: (text, record) => (
        <DropdownBtnComponent
          text={text}
          record={record}
          actions={[
            {
              key: '0',
              title: 'Refund',
              callback: refundCouponHandler,
              icon: <RefundIcon />,
            },
          ]}
        />
      ),
    },
  ];
};

export default useGetCouponsColumn;
