import PropTypes from 'prop-types';
import '../modal.scss';

const PriceVoucherWithVat = ({ voucherPrice, voucherVat }) => (
  <>
    <div className="bodyModalReceipt__finally">Price</div>
    <div className="bodyModalReceipt__data">{voucherPrice}</div>
    <div className="bodyModalReceipt__finally">Total before VAT</div>
    <div className="bodyModalReceipt__data">{voucherPrice}</div>
    <div className="bodyModalReceipt__finally">Estimated VAT 5%</div>
    <div className="bodyModalReceipt__data">{voucherVat}</div>
  </>
);

PriceVoucherWithVat.propTypes = {
  voucherPrice: PropTypes.number.isRequired,
  voucherVat: PropTypes.number.isRequired,
};

export default PriceVoucherWithVat;
