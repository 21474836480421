import React, { useCallback } from 'react';
import { Tag, Typography } from 'antd';
import DropdownBtnComponent from '../../../../Common_components/DropdownBtnComponent';
import './columnt-employ.scss';
import {
  finalBackgroundColorTagStatus,
  finalColorTagTextStatus,
  finalTextTagStatus,
  STATUS_EMPLOYEE_USER,
} from '../../../../../constants/general/tag-status';
import {
  ROLE_EMPLOY_ADMIN,
  ROLE_EMPLOY_MERCHANT, ROLE_EMPLOY_MERCHANT_SUPER,
  ROLE_EMPLOY_SECURITY,
  ROLE_EMPLOY_SUPER_ADMIN,
} from '../../../../../constants/employ';
import { King } from '../../../../../constants/icons';

const getEmployeeRole = (role) => {
  switch (role) {
  case ROLE_EMPLOY_SUPER_ADMIN: {
    return 'Super-admin';
  }
  case ROLE_EMPLOY_ADMIN: {
    return 'Admin';
  }
  case ROLE_EMPLOY_SECURITY: {
    return 'Security';
  }
  case ROLE_EMPLOY_MERCHANT_SUPER: {
    return 'Merchant super-admin';
  }
  case ROLE_EMPLOY_MERCHANT: {
    return 'Merchant-admin';
  }
  default:
    return null;
  }
};

const useGetEmployeesColumn = (editModalEmployHandler, employees, deleteModalEmployHandler) => {
  const editEmploy = useCallback((id) => {
    editModalEmployHandler(id);
  }, [editModalEmployHandler]);

  const deleteEmploy = useCallback(async (id) => {
    deleteModalEmployHandler(id);
  }, [deleteModalEmployHandler]);

  const fullNameRow = (data) => {
    const { userPicture, fullName, status } = data;

    return (
      <div className="column-employ">
        <div className="column-employ__image">
          <img
            src={userPicture}
            alt="img"
          />
          {status === STATUS_EMPLOYEE_USER && <div className="crown-icon"><King /></div>}
        </div>
        <Typography.Text>
          {fullName}
        </Typography.Text>
      </div>
    );
  };

  return [
    {
      title: 'Full name',
      dataIndex: 'fullName',
      key: 'fullName',
      ellipsis: true,
      width: '40%',
      render: (text, record) => fullNameRow(record),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: '20%',
      render: (email) => (
        <Typography.Text>
          {email}
        </Typography.Text>
      ),
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: '10%',
      render: (role) => (
        <Typography.Text>
          {getEmployeeRole(role)}
        </Typography.Text>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '25%',
      render: (status) => (
        <Tag
          color={finalBackgroundColorTagStatus(status)}
          style={{
            color: finalColorTagTextStatus(status),
          }}
        >
          {finalTextTagStatus(status)}
        </Tag>
      ),
    },
    {
      key: 'actions',
      width: '5%',
      render: (text, record) => (
        <DropdownBtnComponent
          text={text}
          record={record}
          actions={[
            {
              key: '0',
              title: 'Edit',
              callback: editEmploy,
            },
            {
              key: '1',
              title: 'Delete',
              callback: (id) => deleteEmploy(id),
            },
          ]}
          hidden={record.status === STATUS_EMPLOYEE_USER}
        />
      ),
    },
  ];
};

export default useGetEmployeesColumn;
