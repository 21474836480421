import React, { memo } from 'react';
import './payment-header.scss';

const PaymentHeader = () => (
  <div className="payment-header">
    <h6 className="payment-header__title">Payment</h6>
    <div className="payment-header__body">
      <div className="payment-header__item title">Order ID</div>
      <div className="payment-header__item left">956112233</div>
      <div className="payment-header__item title">Sum</div>
      <div className="payment-header__item left">5780 AED</div>
    </div>
  </div>
);

export default memo(PaymentHeader);
