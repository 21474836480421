import React, { memo } from 'react';
import PropTypes from 'prop-types';
import FormMerchant from './FormMerchant';
import MerchantPage from './MerchantPage';
import s from './body-merchant.module.scss';

const BodyMerchant = ({
  stateInfo,
  setStateInfo,
  setStateInfoHandler,
  subDivisionsInfo,
  addSubDivisionsInfoHandler,
  deleteSubDivisionsInfoHandler,
  editSubDivisionsInfoHandler,
  allErrors,
  errorCreate,
  errorUpdate,
}) => (
  <section className={s.container}>
    <FormMerchant
      stateInfo={stateInfo}
      setStateInfo={setStateInfo}
      setStateInfoHandler={setStateInfoHandler}
      allErrors={allErrors}
      errorCreate={errorCreate}
      errorUpdate={errorUpdate}
    />
    <MerchantPage
      subDivisionsInfo={subDivisionsInfo}
      addSubDivisionsInfoHandler={addSubDivisionsInfoHandler}
      deleteSubDivisionsInfoHandler={deleteSubDivisionsInfoHandler}
      editSubDivisionsInfoHandler={editSubDivisionsInfoHandler}
      error={allErrors?.filialBranches || ''}
    />
  </section>
);

BodyMerchant.propTypes = {
  stateInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setStateInfo: PropTypes.func.isRequired,
  setStateInfoHandler: PropTypes.func.isRequired,
  subDivisionsInfo: PropTypes.oneOfType([PropTypes.array]).isRequired,
  addSubDivisionsInfoHandler: PropTypes.func.isRequired,
  deleteSubDivisionsInfoHandler: PropTypes.func.isRequired,
  editSubDivisionsInfoHandler: PropTypes.func.isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
  errorCreate: PropTypes.string,
  errorUpdate: PropTypes.string,
};
BodyMerchant.defaultProps = {
  allErrors: undefined,
  errorCreate: undefined,
  errorUpdate: undefined,
};

export default memo(BodyMerchant);
