import React, { memo, useEffect, useState } from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import s from '../form-merchant.module.scss';

const EmailComponent = ({
  stateInfo, setInfoFormHandler, allErrors, id, errorCreate, errorUpdate,
}) => {
  const [isError, setIsError] = useState(false);

  const finallyBorderEmail = () => {
    if (isError) return '1px solid #FF0000FF';
    return '1px solid #EDEDED';
  };

  useEffect(() => {
    if (allErrors?.email || allErrors?.emailBack || errorCreate || errorUpdate) {
      setIsError(true);
    }
  }, [allErrors, errorCreate, errorUpdate]);

  const onChangeHandler = (value) => {
    setIsError(false);
    setInfoFormHandler('emailBack', value);
    setInfoFormHandler('email', value);
  };
  return (
    <>
      <Input
        disabled={!!id}
        type="email"
        style={{
          height: '40px',
          borderRadius: '4px',
          border: finallyBorderEmail(stateInfo.email),
          backgroundColor: '#fff',
          boxShadow: 'none',
        }}
        value={stateInfo.email}
        onChange={(e) => onChangeHandler(e.target.value)}
      />
      {isError && (!errorCreate && !errorUpdate) && (
        <span className={s.error}>
          {allErrors?.email || allErrors?.emailBack}
        </span>
      )}
      {(isError && (errorCreate || errorUpdate))
        && <span className={s.error}>Email must be unique</span>}
    </>
  );
};

EmailComponent.propTypes = {
  stateInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setInfoFormHandler: PropTypes.func.isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.array]),
  id: PropTypes.string,
  errorCreate: PropTypes.string,
  errorUpdate: PropTypes.string,
};

EmailComponent.defaultProps = {
  allErrors: undefined,
  id: undefined,
  errorCreate: undefined,
  errorUpdate: undefined,
};

export default memo(EmailComponent);
