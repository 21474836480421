import React, { useCallback } from 'react';
import { Typography } from 'antd';
import { CloseEye, OpenEye } from '../../../../constants/icons';
import useUpdateBannerAdmin from '../../hooks/gql/useUpdateBanner';
import useCreateCopyBanner from '../../hooks/gql/useCreateCopyBanner';
import DropdownBtnComponent from '../../../Common_components/DropdownBtnComponent';
import { bannersTypeTitles } from '../../../../constants/banners/enums';

const useGetBannersColumn = (editModalBannerHandler, banners, deleteModalBannerHandler) => {
  const { updateBannerPublishByAdmin } = useUpdateBannerAdmin();
  const { createCopyBannerByAdmin } = useCreateCopyBanner();

  const editBanner = useCallback((id) => {
    editModalBannerHandler(id);
  }, [editModalBannerHandler]);

  const deleteBanner = useCallback(async (id) => {
    deleteModalBannerHandler(id);
  }, [deleteModalBannerHandler]);

  const copyBanner = useCallback((id) => {
    createCopyBannerByAdmin(id);
  }, [createCopyBannerByAdmin]);

  return [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      ellipsis: true,
      width: 'auto',
      render: (text) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      ellipsis: true,
      width: '20%',
      render: (type) => <Typography.Text>{bannersTypeTitles[type]}</Typography.Text>,
    },
    {
      title: 'On page',
      dataIndex: 'page',
      key: 'page',
      width: '8%',
      render: (page) => (
        <Typography.Text>
          {
            page === 'MAIN'
              ? 'Main'
              : 'Blog'
          }
        </Typography.Text>
      ),
    },
    {
      title: 'Queue',
      dataIndex: 'position',
      key: 'position',
      ellipsis: true,
      width: '20%',
      render: (position) => <Typography.Text>{position}</Typography.Text>,
    },
    {
      title: 'State',
      dataIndex: 'isPublished',
      key: 'isPublished',
      width: '7%',
      render: (isPublished, record) => (
        <div
          style={{ color: '#A8A8A8', cursor: 'pointer' }}
          onClick={() => updateBannerPublishByAdmin(record.id, { isPublished: !isPublished })}
        >
          {
            isPublished
              ? <OpenEye />
              : <CloseEye />
          }
        </div>
      ),
    },
    {
      key: 'actions',
      width: '5%',
      render: (text, record) => (
        <DropdownBtnComponent
          text={text}
          record={record}
          actions={[
            {
              key: '0',
              title: 'Edit',
              callback: editBanner,
            },
            {
              key: '1',
              title: 'Copy',
              callback: (id) => copyBanner(id),
            },
            {
              key: '2',
              title: 'Delete',
              callback: (id) => deleteBanner(id),
            },
          ]}
        />
      ),
    },
  ];
};

export default useGetBannersColumn;
