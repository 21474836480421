import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  GET_ALL_COUPONS_IN_CART,
  GET_COUPONS_IN_CART_COUNT,
  REMOVE_STOCK_COUPONS_FROM_CART,
} from '../../../api/cart-api/query';

const useRemoveStockCoupons = () => {
  const [callback, { loading: removeStockLoading }] = useMutation(REMOVE_STOCK_COUPONS_FROM_CART, {
    refetchQueries: [GET_ALL_COUPONS_IN_CART, GET_COUPONS_IN_CART_COUNT],
  });

  const removeCartStockCallback = useCallback(async (ids) => {
    await callback({ variables: { ids } });
  }, [callback]);

  return { removeCartStockCallback, removeStockLoading };
};

export default useRemoveStockCoupons;
