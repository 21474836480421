import { useMutation } from '@apollo/client';
import {
  GET_ALL_COUPONS_IN_CART,
  GET_COUPONS_IN_CART_COUNT,
  UPDATE_SHOPPING_CART_COUPON,
} from '../../../api/cart-api/query';

const useUpdateShoppingCartItems = () => {
  const [updateShoppingCart, { loading: updateLoading }] = useMutation(UPDATE_SHOPPING_CART_COUPON, {
    refetchQueries: [GET_ALL_COUPONS_IN_CART, GET_COUPONS_IN_CART_COUNT],
  });

  const updateShoppingCartCoupon = (couponId, amount) => {
    if (amount >= 1) {
      updateShoppingCart({
        variables: {
          id: couponId,
          shoppingCartCouponUpdateInput: {
            amount,
          },
        },
      });
    }
  };

  const updateShoppingCartOption = (couponId, amount, optionId) => {
    if (amount >= 0) {
      updateShoppingCart(({
        variables: {
          id: couponId,
          shoppingCartCouponUpdateInput: {
            shoppingCartCouponOptionsToUpdate: {
              id: optionId,
              amount,
            },
          },
        },
      }));
    }
  };

  return { updateShoppingCartCoupon, updateShoppingCartOption, updateLoading };
};

export default useUpdateShoppingCartItems;
