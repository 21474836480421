import React, { memo, useCallback, useState } from 'react';
import HeaderComponent from '../Header/HeaderComponent';
import '../Header/table.scss';
import TableVouchers from '../TableVouchers';
import VouchersModal from '../modals/VouchersModal';

const VouchersTable = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModalHandler = useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const closeModalHandler = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  return (
    <>
      <HeaderComponent
        callback={openModalHandler}
      />
      <section
        style={{
          height: '100%',
        }}
      >
        <TableVouchers />
      </section>
      {
        isOpenModal && (
          <VouchersModal
            isOpenModal={isOpenModal}
            closeModalHandler={closeModalHandler}
          />
        )
      }
    </>
  );
};

export default memo(VouchersTable);
