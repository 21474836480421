import React, { memo } from 'react';
import {
  Form, Modal, Input, Button,
} from 'antd';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../../../../constants/icons';
import Portal from '../../../../Common_components/Portal';
import './modal.scss';

const ModalRefund = ({ isOpenModal, closeModalHandler, refundVoucher }) => {
  const [form] = Form.useForm();

  const onFinishHandler = (values) => {
    const { comment } = values;
    refundVoucher(comment)
      .then(() => {
        closeModalHandler();
      });
  };

  return (
    <Portal>
      <Modal
        title="Refund"
        className="modal-refund"
        centered
        visible={isOpenModal}
        onCancel={closeModalHandler}
        width={460}
        closeIcon={<CloseIcon />}
        footer={null}
        wrapClassName="wrapperRefund"
      >
        <p className="modal-refund__text">
          Please leave a comment for the merchant indicating the reasons for the refund.
        </p>
        <div>
          <Form
            form={form}
            name="refund"
            onFinish={onFinishHandler}
            requiredMark={false}
            layout="vertical"
            scrollToFirstError
          >
            <Form.Item
              name="comment"
              label="Comment"
              className="comment__label"
              rules={[
                { required: true, message: 'This field is required!' },
                { whitespace: true, message: 'This field is required!' },
              ]}
            >
              <Input.TextArea
                autoFocus
                maxLength={300}
              />
            </Form.Item>
            <p className="modal-refund__text">
              Kindly expect a response within 24-48 business hours.
            </p>
            <Button
              block
              type="primary"
              htmlType="submit"
              className="refund__button"
            >
              Request refund
            </Button>
          </Form>
        </div>
      </Modal>
    </Portal>
  );
};

ModalRefund.propTypes = {
  isOpenModal: PropTypes.bool.isRequired,
  closeModalHandler: PropTypes.func.isRequired,
  refundVoucher: PropTypes.func.isRequired,
};

export default memo(ModalRefund);
