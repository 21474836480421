import React, { memo, useContext, useState } from 'react';
import PropTypes, { shape } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { uid } from 'uid';
import s from './CouponItem.module.scss';
import PreviewGallery from './PreviewGallery';
import { ADD_COUPON_TO_CART } from '../../../api/catalog-api/query';
import { isUserStored } from '../../../helpers/user-storage';
import { removeCouponFromLocalCart } from '../../../helpers/cart-helpers/local-storage';
import { Hot } from '../../../constants/icons';
import { GET_COUPONS_IN_CART_COUNT } from '../../../api/cart-api/query';
import CountContext from '../../Layout/context/countContext';
import {
  addCouponToLocalStorageCart,
  getGalleryArray,
  setWindowOffset,
} from '../../../helpers/catalog-helpers/catalog-helpers';
import AddToCartButton from '../../Common_components/AddToCartButton/AddToCartButton';
import mobileCondition from '../../../constants/mobileCondition';

const CouponItem = ({
  id, title, vatPrice, currentPrice, couponPreviewPicture, discount, oldPrice, couponGalleryPictures,
  couponOptions, publishedDueDate, keywordsImages,
}) => {
  const navigate = useNavigate();

  const [isShowButtons, setShowButtons] = useState(mobileCondition);

  const showButtons = () => {
    if (!mobileCondition) setShowButtons(true);
  };

  const hideButtons = () => {
    if (!mobileCondition) setShowButtons(false);
  };

  const { updateLocalCouponsCount } = useContext(CountContext);

  const [addToCart] = useMutation(
    ADD_COUPON_TO_CART,
    { refetchQueries: [GET_COUPONS_IN_CART_COUNT] },
  );

  const price = (amount) => (amount / 100).toFixed(2);

  const galleryArray = getGalleryArray(couponPreviewPicture, couponGalleryPictures);

  const roundedDiscount = Math.round(discount);
  const totalDiscount = `${roundedDiscount}% OFF`;

  const fixedCurrentPrice = `${price(currentPrice)} AED`;
  const fixedOldPrice = `${price(oldPrice)} AED`;

  const addCouponToCart = () => {
    if (isUserStored()) {
      const shoppingCartCouponOptions = couponOptions.length > 0
        ? couponOptions.map(({ id }) => ({ couponOptionId: id, amount: 0 }))
        : undefined;
      addToCart({
        variables: {
          shoppingCartCouponInput: {
            amount: 1,
            couponId: id,
            shoppingCartCouponOptions,
          },
        },
      });
    } else {
      const cartId = uid();
      const shoppingCartCouponOptions = couponOptions.length > 0
        ? couponOptions.map(({
          vatPrice, id, title, oldPrice, currentPrice,
        }) => ({
          amount: 0,
          id,
          couponOption: {
            id, vatPrice, title, oldPrice, currentPrice,
          },
        }))
        : [];
      addCouponToLocalStorageCart({
        cartId,
        id,
        title,
        quantity: 1,
        vatPrice,
        currentPrice,
        couponPreviewPicture,
        oldPrice,
        shoppingCartCouponOptions,
        removeCallback: removeCouponFromLocalCart,
        deadline: publishedDueDate,
      });
      updateLocalCouponsCount((value) => value + 1);
    }
  };

  const onCouponPageNavigateOnClick = () => {
    setWindowOffset();
    navigate(`/coupon/${id}`);
  };

  return (
    <div
      className={s.item}
      onClick={onCouponPageNavigateOnClick}
      onMouseEnter={showButtons}
      onMouseLeave={hideButtons}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className={s.hot}
      >
        <Hot />
      </div>
      <PreviewGallery
        showButton={isShowButtons}
        images={galleryArray}
        keywordsImages={keywordsImages}
      />
      <span className={s.description}>
        {title}
      </span>
      <div className={s.discountContainer}>
        <span>
          {fixedCurrentPrice}
        </span>
        {discount > 0 && (
          <span className={s.discount}>
            {totalDiscount}
          </span>
        )}
      </div>
      {discount > 0 && (
        <span className={s.oldPrice}>
          {fixedOldPrice}
        </span>
      )}
      <AddToCartButton
        addToCartCallback={addCouponToCart}
        additionalButtonClass={s.button}
      />
    </div>
  );
};

CouponItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  couponPreviewPicture: PropTypes.object,
  vatPrice: PropTypes.number.isRequired,
  currentPrice: PropTypes.number.isRequired,
  discount: PropTypes.number.isRequired,
  oldPrice: PropTypes.number.isRequired,
  couponGalleryPictures: PropTypes.arrayOf(shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  })).isRequired,
  publishedDueDate: PropTypes.string.isRequired,
  couponOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  keywordsImages: PropTypes.string.isRequired,
};

CouponItem.defaultProps = {
  couponPreviewPicture: null,
};

export default memo(CouponItem);
