import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import Icon from '../../Catalog/Icon';
import s from './MobileSubCategoryItem.module.scss';

const MobileSubCategoryItem = ({
  id, iconKey, title, couponsCount,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [, setSearchParams] = useSearchParams();

  const navigateToSubcategory = () => {
    if (couponsCount > 0) {
      setSearchParams({ subCategoryId: id });
    }
  };

  return (
    <div
      onClick={navigateToSubcategory}
      className={s.item}
    >
      <Icon
        url={iconKey}
        name={`${title} subcategory`}
      />
      <span className={s.title}>{title}</span>
    </div>
  );
};

MobileSubCategoryItem.propTypes = {
  iconKey: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  couponsCount: PropTypes.number.isRequired,
};

export default memo(MobileSubCategoryItem);
