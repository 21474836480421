import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    width: '930px',
    padding: '12px 0 115px',
  },
  header: {
    padding: '0 20px 6px',
    display: 'flex',
    width: '100%',
    marginBottom: '20px',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px solid #EDEDED',
  },
  logo: {
    height: '60px',
    width: '60px',
  },
  titles: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerTitle: {
    fontWeight: 700,
    fontSize: '30px',
    lineHeight: '140%',
    color: '#333',
  },
  headerSubtitle: {
    marginLeft: '5px',
    fontSize: '10px',
    color: '#838383',
    lineHeight: '140%',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    alignItems: 'flex-end',
  },
  pageTitle: {
    fontSize: '20px',
    fontWeight: '700',
    color: '#333333',
    lineHeight: '140%',
  },
  pageNumber: {
    marginTop: '5px',
    fontSize: '12px',
    fontWeight: '400',
    color: '#8E8E8E',
    lineHeight: '140%',
  },
  couponMainInfo: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0 20px 15px 20px',
    borderBottom: '1px solid #EDEDED',
  },
  couponMainDescription: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flex: '1 1 auto',
  },
  preview: {
    flex: '0 0 230px',
    borderRadius: '10px',
    marginLeft: '20px',
    minWidth: '230px',
    height: '130px',
    objectFit: 'cover',
  },
  short: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  couponInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '10px',
  },
  qrCodeSize: {
    minWidth: '100px',
    minHeight: '100px',
    width: '100px',
    height: '100px',
  },
  infoHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  infoDescription: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '7cm',
    width: '7cm',
    marginTop: '10px',
  },
  optionDescription: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '7cm',
    width: '7cm',
    marginBottom: '10px',
  },
  shortTitle: {
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '140%',
    color: '#333',
  },
  descriptionTitle: {
    fontSize: '10px',
    color: '#838383',
    lineHeight: '140%',
    fontWeight: 400,
  },
  descriptionText: {
    marginTop: '5px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '140%',
  },

  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
  },

  merchant: {
    display: 'flex',
    flexDirection: 'row',
  },
  company: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    marginRight: '38px',
    flexShrink: 1,
  },
  companySubtitle: {
    fontSize: '10px',
    color: '#838383',
    lineHeight: '140%',
    fontWeight: 400,
  },
  companyTitle: {
    fontWeight: 400,
    marginTop: '5px',
    fontSize: '12px',
    color: '#333',
  },
  address: {
    display: 'flex',
    flexDirection: 'column',
  },
  addressSubtitle: {
    fontSize: '10px',
    color: '#838383',
    lineHeight: '140%',
    fontWeight: 400,
  },
  addressTitle: {
    fontWeight: 400,
    marginTop: '5px',
    fontSize: '12px',
    color: '#333',
  },
  customerData: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  dataBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  dataTitle: {
    color: '#838383',
    lineHeight: '140%',
    fontWeight: 400,
    fontSize: '10px',
  },
  dataText: {
    lineHeight: '140%',
    color: '#333',
    marginTop: '5px',
    fontSize: '12px',
    fontWeight: 500,
  },
  information: {
    display: 'flex',
    flexDirection: 'row',
    padding: '13px 20px',
    borderBottom: '1px solid #EDEDED',
  },
  conditionView: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100%/2)',
    marginLeft: '20px',
  },
  descriptionView: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100%/2)',
  },
  infoTitle: {
    color: '#838383',
    lineHeight: '140%',
    fontWeight: 400,
    fontSize: '10px',
  },
  infoText: {
    lineHeight: '140%',
    marginTop: '10px',
    wordBreak: 'break-all',
    color: '#333',
    fontSize: '9px',
    fontWeight: 500,
    overflow: 'hidden',
  },
  security: {
    textAlign: 'center',
    color: '#8E8E8E',
    padding: '10px',
  },
  additional: {
    display: 'flex',
    flexDirection: 'column',
    padding: ' 13px 20px',
    borderBottom: '1px solid #EDEDED',
  },
  addPhone: {
    display: 'flex',
    flexDirection: 'column',
  },
  addInformation: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionsStyles: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
  optionsHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  headerOption: {
    width: '24%',
    fontSize: '10px',
    fontWeight: 400,
    color: '#838383',
  },
  headerAddress: {
    width: '24%',
    fontSize: '10px',
    fontWeight: 400,
    color: '#838383',
    marginLeft: '20px',
  },
  headerPrice: {
    fontSize: '10px',
    fontWeight: 400,
    color: '#838383',
    marginLeft: '20px',
    width: '9%',
  },
  headerId: {
    fontSize: '10px',
    fontWeight: 400,
    color: '#838383',
    marginLeft: '5px',
    width: '133px',
  },
  headerQR: {
    fontSize: '10px',
    fontWeight: 400,
    color: '#838383',
    marginLeft: '10px',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  optionItem: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  optionTitle: {
    width: '27.5%',
    fontSize: '12px',
    fontWeight: 400,
    color: '#333',
    marginTop: '20px',
  },
  optionAddress: {
    width: '27.5%',
    fontSize: '12px',
    fontWeight: 400,
    color: '#333',
    marginLeft: '20px',
    marginTop: '20px',
  },
  optionPrice: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#333',
    marginLeft: '20px',
    width: '7%',
    marginTop: '20px',
  },
  optionId: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#333',
    marginLeft: '20px',
    width: '160px',
    marginTop: '20px',
  },
  optionQR: {
    width: '50px',
    height: '50px',
    marginTop: '14px',
  },
});

export const {
  short, headerSubtitle, couponInfo, header, headerTitle, titles, page, logo, infoDescription, infoHeader, shortTitle,
  descriptionText, descriptionTitle, preview, merchant, company, address, addressSubtitle,
  addressTitle, companyTitle, companySubtitle, customerData, dataBlock, dataText, dataTitle, qrCodeSize,
  conditionView, descriptionView, information, infoText, infoTitle, security, optionsStyles, optionsHeader,
  headerAddress, headerId, headerOption, headerPrice, headerQR, optionsContainer, additional, addPhone,
  addInformation, pagination, pageNumber, pageTitle, couponMainInfo, couponMainDescription, footer, optionDescription,
} = styles;
