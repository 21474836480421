import mobileCondition from '../../../constants/mobileCondition';

export const companyItems = !mobileCondition
  ? [
    { title: 'Contact Us', route: '/common/contacts' },
    { title: 'Cookie Policy', route: '/common/cookies' },
    { title: 'Terms & Conditions', route: '/common/terms' },
    { title: 'Merchant login', route: 'admin' },
  ]
  : [
    { title: 'Contact Us', route: '/common/contacts' },
    { title: 'Cookie Policy', route: '/common/cookies' },
    { title: 'Terms & Conditions', route: '/common/terms' },
  ];

export const learnMoreItems = [
  { title: 'FAQ', route: '/common/faq' },
  { title: 'Our Blogs', route: '/common/blog' },
];
