import React, {
  memo, useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import s from './MobileCartCouponItem.module.scss';
import { TrashBin } from '../../../../constants/icons';
import CartQuantityControls from '../../CartQuantityControls';
import formatDate from '../../../../helpers/cart-helpers/formatDate';
import MobileCartOptionItem from '../MobileCartOptionItem';

const MobileCartCouponItem = ({
  cartId,
  shoppingCartCouponOptions,
  quantity,
  availableAmountInPeriod,
  currentPrice,
  oldPrice,
  deadline,
  title,
  previewKey,
  removeIconOnCLick,
  couponOrderHandler,
  optionOrderHandler,
  promoCodeApplied,
}) => {
  const fixedCurrentPrice = `${currentPrice / 100} AED`;
  const [height, setHeight] = useState(0);

  const refImage = useRef(null);

  const fixedOldPrice = `${oldPrice / 100} AED`;

  const fixedDeadline = formatDate(deadline);

  const itemClassName = `${s.item} ${promoCodeApplied && s.applied}`;

  const mappedOptions = shoppingCartCouponOptions.map(({ amount, id, couponOption: { title, currentPrice } }) => (
    <MobileCartOptionItem
      key={id}
      optionId={id}
      orderHandler={optionOrderHandler}
      cartId={cartId}
      quantity={amount}
      currentPrice={currentPrice}
      title={title}
    />
  ));

  useEffect(() => {
    const element = refImage.current;
    const { width } = element.getBoundingClientRect();
    setHeight(Math.ceil(width / 1.777));
  }, []);

  return (
    <div className={itemClassName}>
      <div className={s.coupon}>
        <div className={s.logoPriceRemove}>
          <div className={s.logoWithPrice}>
            <div
              ref={refImage}
              className={s.logo}
              style={{
                height: `${height}px`,
              }}
            >
              <img
                src={`${process.env.REACT_APP_BUCKET_URL}/${previewKey}`}
                alt=""
              />
            </div>
            <div className={s.cost}>
              <span className={s.currentPrice}>
                {fixedCurrentPrice}
              </span>
              <span className={s.oldPrice}>
                {fixedOldPrice}
              </span>
            </div>
          </div>
          <div
            onClick={() => removeIconOnCLick(cartId)}
            className={s.remove}
          >
            <TrashBin />
          </div>
        </div>
        <span className={s.couponTitle}>
          {title}
        </span>
        <div className={s.info}>
          <div className={s.date}>
            <span className={s.subtitle}>
              Offer deadline
            </span>
            <span className={s.deadline}>
              {fixedDeadline}
            </span>
          </div>
          <CartQuantityControls
            quantity={quantity}
            limit={availableAmountInPeriod}
            itemId={cartId}
            orderHandler={couponOrderHandler}
          />
        </div>
      </div>
      {shoppingCartCouponOptions.length > 0 && (
        <div className={s.options}>
          <span className={s.title}>
            Option:
          </span>
          <div className={s.optionsList}>
            {mappedOptions}
          </div>
        </div>
      )}
    </div>
  );
};

MobileCartCouponItem.propTypes = {
  availableAmountInPeriod: PropTypes.number,
  cartId: PropTypes.string.isRequired,
  deadline: PropTypes.string.isRequired,
  currentPrice: PropTypes.number.isRequired,
  oldPrice: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  shoppingCartCouponOptions: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  previewKey: PropTypes.string.isRequired,
  removeIconOnCLick: PropTypes.func.isRequired,
  couponOrderHandler: PropTypes.func.isRequired,
  optionOrderHandler: PropTypes.func.isRequired,
  promoCodeApplied: PropTypes.bool,
};

MobileCartCouponItem.defaultProps = {
  availableAmountInPeriod: undefined,
  promoCodeApplied: undefined,
};

export default memo(MobileCartCouponItem);
