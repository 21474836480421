import { useState } from 'react';
import useGetCategories from '../useGetCategories';

const useCategories = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const {
    categories, loading, error, refetch,
  } = useGetCategories();

  const showModal = () => {
    setIsAddModalVisible(true);
  };

  const handleOk = () => {
    setIsAddModalVisible(false);
    refetch();
  };

  const handleCancel = () => {
    setIsAddModalVisible(false);
  };

  return {
    categories,
    loading,
    error,
    refetch,
    isAddModalVisible,
    showModal,
    handleOk,
    handleCancel,
  };
};

export default useCategories;
