import React from 'react';
import { Table } from 'antd';
import { useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import columnBanner from './column/columnBanner';
import './table-banners-ant.scss';
import { limit } from '../../../constants/tableConstants';
import { heightMinusForAdminTables } from '../../../constants/general/tag-status';
import useWindowSize from '../../../hooks/useWindowSize/useWindowSize';

const TableBanners = ({
  editModalBannerHandler, deleteModalBannerHandler, banners, count,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const columns = columnBanner(editModalBannerHandler, banners, deleteModalBannerHandler);

  const page = searchParams.get('page') || '1';

  const onChangePage = (page) => {
    setSearchParams({
      ...Object.entries(searchParams),
      page,
    });
  };

  const [height] = useWindowSize();

  return (
    <section className="all-admin-table">
      <Table
        className="adminTable"
        dataSource={banners}
        columns={columns}
        scroll={{
          y: height - heightMinusForAdminTables, x: 0,
        }}
        rowKey={(rec) => rec.id}
        pagination={{
          current: Number(page),
          pageSize: limit,
          total: count,
          position: ['bottomCenter'],
          size: 'small',
          showSizeChanger: false,
          onChange: (page) => onChangePage(page),
        }}
      />
    </section>
  );
};

TableBanners.propTypes = {
  editModalBannerHandler: PropTypes.func.isRequired,
  deleteModalBannerHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  banners: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
};

export default TableBanners;
