import React, { memo } from 'react';
import './coupons-header.scss';
import SearchInput from '../../Common_components/SearchInput';
import useProfileCoupons from '../../hooks/gql/useProfileCoupons';

const CouponsHeader = () => {
  const { search, onFilterChange } = useProfileCoupons();

  return (
    <section className="coupons-header">
      <h6 className="account-title">My Coupons</h6>
      <SearchInput
        value={search}
        callback={onFilterChange}
        addClass="searchInputAddClass"
      />
    </section>
  );
};
export default memo(CouponsHeader);
