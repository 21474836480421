import React, { memo } from 'react';
import PropTypes from 'prop-types';
import './dropdown-menu.scss';

const DropdownMenu = ({ actions }) => (
  <div className="dropdown-component">
    {
      actions.map((item) => (
        <div
          className="dropdown-component__item"
          aria-disabled={item.disabled}
          key={item.title}
          onClick={() => item.callback()}
        >
          {item.title}
        </div>
      ))
    }
  </div>
);

DropdownMenu.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default memo(DropdownMenu);
