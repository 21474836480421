import React, { memo } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import s from '../TableVouchers/table-vouchers.module.scss';
import { ArrowBottom } from '../../../constants/icons';
import Loader from '../../Loader';
import { heightMinusForAdminTables } from '../../../constants/general/tag-status';
import { limit } from '../../../constants/tableConstants';
import useWindowSize from '../../../hooks/useWindowSize/useWindowSize';
import useFindVouchersByAdmin from '../hooks/gql/useFindVouchersByAdmin';
import useGetActiveVouchers from './columns/useGetActiveVouchers';

const expandIcon = ({
  expanded, onExpand, record, expandable,
}) => (
  expandable
  && (
    <span
      className={expanded ? s.expanded : s.expand}
      onClick={(e) => onExpand(record, e)}
    >
      <ArrowBottom />
    </span>
  )
);

const VouchersActiveOfMerchant = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { count, loading, vouchers } = useFindVouchersByAdmin();
  const columns = useGetActiveVouchers();
  const finalVouchersData = vouchers?.map((voucher) => {
    const {
      payDate, couponTitle, user, order, activateDate, voucherOptions, totalPrice, id,
    } = voucher;
    const { userNumber } = user;
    const { orderNumber } = order;

    const timePay = payDate
      ? `${moment(payDate).format('HH:mm')}`
      : '';

    const timeActivate = activateDate
      ? `${moment(activateDate).format('HH:mm')}`
      : '';

    const children = voucherOptions.length
      ? voucherOptions.map((option) => {
        const {
          id,
          voucherNumber,
          status,
          optionTitle,
          activateDate,
          activateMethod,
        } = option;

        const timeActivate = activateDate
          ? `${moment(activateDate).format('HH:mm')}`
          : '';
        return {
          id,
          key: id,
          entity: 'OPTION',
          payDate: null,
          timePay: '',
          voucherNumber,
          title: optionTitle,
          company: '',
          userId: '',
          orderId: '',
          totalPrice: '',
          status,
          activateDate,
          timeActivate,
          activateMethod,
          isDownloadIcon: false,
        };
      })
      : null;

    return {
      ...voucher,
      entity: 'VOUCHER',
      key: id,
      userId: userNumber,
      orderId: orderNumber,
      totalPrice: totalPrice / 100,
      title: couponTitle,
      timePay,
      timeActivate,
      isDownloadIcon: true,
      parent: true,
      children,
    };
  });
  const [height] = useWindowSize();

  const page = searchParams.get('page') || '1';

  const onChangePage = (page) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page,
    });
  };

  if (loading) return <Loader />;
  return (
    <section className="all-admin-table voucher-table">
      <Table
        className="adminTable narrow"
        dataSource={finalVouchersData}
        columns={columns}
        scroll={{
          y: height - heightMinusForAdminTables, x: 0,
        }}
        pagination={{
          current: Number(page),
          pageSize: limit,
          total: count,
          position: ['bottomCenter'],
          size: 'small',
          showSizeChanger: false,
          onChange: (page) => onChangePage(page),
        }}
        expandable={{
          expandIcon,
        }}
      />
    </section>
  );
};

export default memo(VouchersActiveOfMerchant);
