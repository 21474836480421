import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { ADD_COUPONS_TO_CART } from '../../../../api/query';
import {
  clearLocalCartCount,
  clearLocalCartData,
  getLocalCartData,
} from '../../../../helpers/cart-helpers/local-storage';
import { GET_COUPONS_IN_CART_COUNT } from '../../../../api/cart-api/query';

const useAddCouponsFromLocal = () => {
  const [uploadCouponsCallback, { fetchFromLocalLoading }] = useMutation(ADD_COUPONS_TO_CART, {
    refetchQueries: [GET_COUPONS_IN_CART_COUNT],
  });

  const items = getLocalCartData();
  const shoppingCartsCouponInput = items ? items.map(({ id, quantity, shoppingCartCouponOptions }) => {
    if (shoppingCartCouponOptions && shoppingCartCouponOptions.length > 0) {
      const uploadOptions = shoppingCartCouponOptions.map(({ amount, id }) => ({ amount, couponOptionId: id }));
      return { couponId: id, amount: quantity, shoppingCartCouponOptions: uploadOptions };
    }
    return { couponId: id, amount: quantity };
  }) : null;

  const uploadCoupons = useCallback(async () => {
    if (shoppingCartsCouponInput) {
      await uploadCouponsCallback({ variables: { shoppingCartsCouponInput } });
      clearLocalCartData();
      clearLocalCartCount();
    }
  }, [fetch, shoppingCartsCouponInput, uploadCouponsCallback]);

  return { uploadCoupons, fetchFromLocalLoading };
};

export default useAddCouponsFromLocal;
