import React, { memo, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import s from './OutOfStockModal.module.scss';
import warning from '../../../assets/images/warning.png';
import Modal from '../../Modal';
import CountContext from '../../Layout/context/countContext';

const OutOfStockModal = ({
  isActive, hideModalHandler, cancelButtonAction, isCancelLoading, isActionLoading, stockData, isInCouponPage,
  buyButtonCallback,
}) => {
  const removeCallback = async () => {
    await cancelButtonAction();
    hideModalHandler();
  };

  const { countData } = useContext(CountContext);

  const allAreSold = useMemo(() => {
    if (isInCouponPage) {
      const [{ amount }] = stockData;
      return amount === 0;
    }
    if (countData?.getAllCouponsInCart?.shoppingCartCoupons.length === 1) {
      return !isInCouponPage && stockData.length > 0 && stockData.reduce((
        acc,
        { coupon: { currentAvailableAmount } },
      ) => currentAvailableAmount + acc, 0) === 0;
    }
    return false;
  }, [countData, stockData]);

  const totalRemoveText = isInCouponPage ? 'Reset coupon order' : 'Delete them from cart';

  const stockCoupons = !isInCouponPage && stockData?.length > 0 && stockData.map(({
    id,
    amount,
    coupon: { currentAvailableAmount, title },
  }) => (
    <div
      key={id}
      style={{ display: 'flex', justifyContent: 'space-between', padding: '0 20px' }}
    >
      <span>{title}</span>
      <span>
        ordered:
        {' '}
        {amount}
        , left:
        {' '}
        {currentAvailableAmount}
      </span>
    </div>
  ));

  const stockAmount = isInCouponPage && stockData.reduce((_, { amount }) => amount, 0);

  const stockText = useMemo(() => {
    if (stockAmount === 0 && countData?.getAllCouponsInCart?.shoppingCartCoupons.length === 1) {
      return 'Unfortunately, all coupons have been sold';
    }
    if (stockAmount === 1) {
      return `Unfortunately, only ${stockAmount} coupon left.`;
    }
    return `Unfortunately, only ${stockAmount} coupons left.`;
  }, [countData, stockAmount]);

  return (
    <Modal
      isActive={isActive}
      hideModalHandler={hideModalHandler}
    >
      <div className={s.main}>
        <img
          src={warning}
          alt="Warning icon"
        />
        <h1 className={s.title}>Out of stock</h1>
        <div className={s.subtitle}>
          {allAreSold
            ? <h2>Unfortunately, all coupons have been sold.</h2>
            : (
              <div>
                {isInCouponPage
                  ? (
                    <h2>
                      {stockText}
                    </h2>
                  )
                  : (
                    <>
                      <h2>
                        Unfortunately, we do not have enough following coupons:
                      </h2>
                      {stockCoupons}
                    </>
                  )}
              </div>
            )}
        </div>
        {allAreSold
          ? (
            <button
              disabled={isActionLoading}
              onClick={removeCallback}
            >
              {isCancelLoading
                ? <LoadingOutlined color="#333333" />
                : 'Close and delete from the cart'}
            </button>
          )
          : (
            <div className={s.buttons}>
              {stockAmount === 0 && isInCouponPage
                ? (
                  <button onClick={hideModalHandler}>
                    Close window
                  </button>
                )
                : (
                  <>
                    <button
                      onClick={removeCallback}
                      className={s.cancel}
                      disabled={isCancelLoading}
                    >
                      {isCancelLoading
                        ? <LoadingOutlined color="#333333" />
                        : totalRemoveText}
                    </button>
                    <button
                      onClick={buyButtonCallback}
                      disabled={isActionLoading}
                    >
                      {isActionLoading
                        ? <LoadingOutlined color="#333333" />
                        : 'Buy'}
                    </button>
                  </>
                )}
            </div>
          )}
      </div>
    </Modal>
  );
};

OutOfStockModal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  hideModalHandler: PropTypes.func.isRequired,
  cancelButtonAction: PropTypes.func.isRequired,
  buyButtonCallback: PropTypes.func.isRequired,
  isActionLoading: PropTypes.bool.isRequired,
  isCancelLoading: PropTypes.bool,
  isInCouponPage: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  stockData: PropTypes.array,
};

OutOfStockModal.defaultProps = {
  stockData: [],
  isInCouponPage: false,
  isCancelLoading: false,
};

export default memo(OutOfStockModal);
