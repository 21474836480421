import { gql } from '@apollo/client';

const GET_BANNERS = gql`
    query getBanners ($offset: Float, $limit: Float, $page: BannerPageTypes!, $type: BannerTypes) {
        getBanners(offset: $offset, limit: $limit, page: $page, type: $type) {
            banners {
                id
                type
                bannerPicture {
                    key
                }
                link
            }
        }
    }
`;

export default GET_BANNERS;
