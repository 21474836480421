import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import s from './PriceBlock.module.scss';
import QuantityControls from '../QuantityControls/QuantityControls';
import getPrice from '../../../helpers/couponPage/getPrice';
import CouponContext from '../context/CouponContext';
import OrderContext from '../CustomerBlock/orderContext';
import mobileCondition from '../../../constants/mobileCondition';

const PriceBlock = () => {
  const {
    currentPrice, oldPrice, discount, id,
  } = useContext(CouponContext);

  const { handleCouponOrder, totalOrder: { amount } } = useContext(OrderContext);

  const [quantity, setQuantity] = useState(amount);

  useEffect(() => {
    setQuantity(amount);
  }, [amount]);

  const mainClassName = `${s.main} ${mobileCondition && s.mobile}`;

  const whiteClassName = `${s.white} ${discount === 0 && s.whiteNoDiscount} ${mobileCondition && s.mobile}`;

  const greenClassName = `${s.green} ${mobileCondition && s.mobile}`;

  const totalDiscount = `${Math.round(discount)}% OFF`;
  return (
    <div className={mainClassName}>
      {mobileCondition ? (
        <>
          <div className={s.mobileContainer}>
            <div className={whiteClassName}>
              <div className={s.cost}>
                <span className={s.currentPrice}>
                  {getPrice(currentPrice)}
                </span>
                {discount > 0 && (
                  <span className={s.oldPrice}>
                    {getPrice(oldPrice)}
                  </span>
                )}
              </div>
            </div>
            {discount > 0 && (
              <div className={greenClassName}>
                <span className={s.discount}>
                  {totalDiscount}
                </span>
              </div>
            )}
          </div>
          <QuantityControls
            itemId={id}
            orderHandler={handleCouponOrder}
            quantity={quantity}
            setQuantity={setQuantity}
          />
        </>
      )
        : (
          <>
            <div className={whiteClassName}>
              <div className={s.cost}>
                <span className={s.currentPrice}>
                  {getPrice(currentPrice)}
                </span>
                {discount > 0 && (
                  <span className={s.oldPrice}>
                    {getPrice(oldPrice)}
                  </span>
                )}
              </div>
              <QuantityControls
                itemId={id}
                orderHandler={handleCouponOrder}
                quantity={quantity}
                setQuantity={setQuantity}
              />
            </div>
            {discount > 0 && (
              <div className={s.green}>
                <span className={s.discount}>
                  {totalDiscount}
                </span>
              </div>
            )}
          </>
        )}
    </div>
  );
};

export default memo(PriceBlock);
