import React, { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import s from './CouponPath.module.scss';
import { SmallArrowRight } from '../constants/icons';
import CouponContext from '../context/CouponContext';
import { clearWindowOffset } from '../../../helpers/catalog-helpers/catalog-helpers';

const CouponPath = () => {
  const { category, subCategory, title } = useContext(CouponContext);

  const { id: categoryId, title: categoryTitle } = category;

  return (
    <div className={s.path}>
      <Link
        className={s.first}
        to="/"
      >
        Catalog
      </Link>
      <SmallArrowRight />
      <Link
        to={`/?categoryId=${categoryId}`}
        onClick={clearWindowOffset}
      >
        {categoryTitle}
      </Link>
      {subCategory && (
        <>
          <SmallArrowRight />
          <Link
            to={`/?subCategoryId=${subCategory.id}`}
            onClick={clearWindowOffset}
          >
            {subCategory.title}
          </Link>
        </>
      )}
      <SmallArrowRight />
      <span className={s.last}>
        {title}
      </span>
    </div>
  );
};

export default memo(CouponPath);
