import React, { memo, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Thumbs, Virtual } from 'swiper';
import { isTablet } from 'react-device-detect';
import s from './SwiperComponent.module.scss';
import './swiper.scss';
import { ArrowLeft, ArrowRight } from '../../constants/icons';
import useFrameVideo from '../../hooks/useFrameVideo';
import mobileCondition from '../../../../constants/mobileCondition';
import MobilePageCount from '../MobilePageCount/MobilePageCount';

const SwiperComponent = ({ images, videoUrl, keywordsImages }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [index, setIndex] = useState(mobileCondition
    ? null
    : thumbsSwiper?.activeIndex || 0);

  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const { bottomVideoSlide, UpperFrameSlide } = useFrameVideo(videoUrl);

  const upperSwiperClassName = `higherSwiper ${mobileCondition ? 'mobile' : ''}`;

  const wrapperClassName = `${s.wrapper} ${mobileCondition && s.mobile}`;

  const swiperSlideClassName = `swiper-slide ${mobileCondition ? 'mobile' : ''}`;

  const imageSlides = images.map((l) => (
    <img
      src={l}
      alt={keywordsImages}
    />
  ));

  const links = videoUrl ? [videoUrl, ...images] : images;

  const totalTopSlides = videoUrl ? [<UpperFrameSlide />, ...imageSlides]
    .map((el, i) => (
      <SwiperSlide
        key={links[i]}
        virtualIndex={i}
        className={swiperSlideClassName}
      >
        {el}
      </SwiperSlide>
    ))
    : imageSlides.map((el, i) => (
      <SwiperSlide
        key={links[i]}
        virtualIndex={i}
      >
        {el}
      </SwiperSlide>
    ));

  const preventSelectOnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const totalBottomSlides = videoUrl
    ? [bottomVideoSlide, ...imageSlides]
      .map((el, i) => (
        <SwiperSlide
          key={links[i]}
          virtualIndex={i}
        >
          {el}
        </SwiperSlide>
      ))
    : imageSlides.map((el, i) => (
      <SwiperSlide
        key={links[i]}
        virtualIndex={i}
      >
        {el}
      </SwiperSlide>
    ));

  return (
    <div className={wrapperClassName}>
      <Swiper
        onSwiper={mobileCondition
          ? (s) => setIndex({ currentPage: s.activeIndex + 1, totalPages: s.slides.length })
          : null}
        onSlideChange={(s) => {
          setIndex(mobileCondition
            ? { currentPage: s.activeIndex + 1 || 0, totalPages: s.slides.length || 0 }
            : s.activeIndex);
        }}
        spaceBetween={10}
        thumbs={{
          autoScrollOffset: isTablet ? 1 : 2,
          swiper: mobileCondition ? null : thumbsSwiper,
        }}
        modules={[Navigation, Thumbs, Virtual]}
        navigation={{
          nextEl: nextRef.current,
          prevEl: totalTopSlides.length > 1 ? prevRef.current : null,
        }}
        className={upperSwiperClassName}
      >
        {totalTopSlides}
        {mobileCondition && index
          && (
            <MobilePageCount
              currentPage={index.currentPage}
              totalPages={index.totalPages}
            />
          )}
        {(totalTopSlides.length > 1
          && (
            <>
              <div
                aria-disabled={index < 1}
                onMouseDown={preventSelectOnClick}
                ref={prevRef}
                className={`${s.button} ${s.prev}`}
              >
                <ArrowLeft />
              </div>
              <div
                aria-disabled={index === totalTopSlides.length - 1}
                onMouseDown={preventSelectOnClick}
                ref={nextRef}
                className={`${s.button} ${s.next}`}
              >
                <ArrowRight />
              </div>
            </>
          ))}
      </Swiper>
      {!mobileCondition && (
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={20}
          slidesPerView="auto"
          watchSlidesProgress
          modules={[Navigation, Thumbs, Virtual]}
          className="lowerSwiper"
        >
          {totalBottomSlides}
        </Swiper>
      )}
    </div>
  );
};

SwiperComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  videoUrl: PropTypes.string,
  keywordsImages: PropTypes.string.isRequired,
};

SwiperComponent.defaultProps = {
  videoUrl: null,
};

export default memo(SwiperComponent);
