export const STATUS_TYPE_ACTIVE_BY_MERCHANT = 'ACTIVE';
export const STATUS_TYPE_INACTIVE_BY_MERCHANT = 'INACTIVE';

export const MERCHANT_TIME_FORMAT = 'HH:mm';

export const TAB_SUBDIVISIONS_STATUSES_MERCHANT = 'Subdivisions';
export const TAB_EMPLOYEES_STATUSES_MERCHANT = 'Employees';
export const TAB_COUPONS_STATUSES_MERCHANT = 'Coupons';

export const emiratesItems = [
  { value: 'ABU_DHABI', label: 'Abu Dhabi' },
  { value: 'DUBAI', label: 'Dubai' },
  { value: 'SHARJAH', label: 'Sharjah' },
  { value: 'AJMAN', label: 'Ajman' },
  { value: 'FUJAIRAH', label: 'Fujairah' },
  { value: 'RAS_AL_KHAIMAH', label: 'Ras al-Khaimah' },
  { value: 'UMM_AL_QUWAIN', label: 'Umm al-Quwain' },
];

export const sortItems = [
  { value: false, label: 'Show from low to high' },
];
