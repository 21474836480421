import { useSwiperSlide } from 'swiper/react';
import React, { memo } from 'react';
import ReactPlayer from 'react-player';
import s from '../PictureGallery/SwiperComponent/SwiperComponent.module.scss';
import { VideoPreview } from '../constants/icons';

const useFrameVideo = (videoUrl) => {
  const framePreviewSrc = videoUrl && `https://img.youtube.com/vi/${videoUrl.slice(32, videoUrl.length)}/hqdefault.jpg`;

  const UpperFrameSlide = memo(() => {
    const { isActive } = useSwiperSlide();
    return (
      <ReactPlayer
        width="100%"
        height="100%"
        url={videoUrl}
        muted
        controls
        playing={isActive}
      />
    );
  });

  const bottomVideoSlide = videoUrl && (
    <div className={s.videoPreview}>
      <VideoPreview />
      <img
        src={framePreviewSrc}
        alt="preview"
      />
    </div>
  );

  return { UpperFrameSlide, bottomVideoSlide };
};

export default useFrameVideo;
