import { useMutation } from '@apollo/client';
import {
  CREATE_BANNER_COPY_ADMIN,
  GET_ALL_BANNERS_ADMIN,
} from '../../../../api/api-banners/query';

const useCreateCopyBanner = () => {
  const [createBannerCopyAdmin, { loading }] = useMutation(CREATE_BANNER_COPY_ADMIN, {
    refetchQueries: [GET_ALL_BANNERS_ADMIN],
  });

  const createCopyBannerByAdmin = (id) => {
    (async () => {
      try {
        await createBannerCopyAdmin({
          variables: {
            id,
          },
        });
        // eslint-disable-next-line no-empty
      } catch (e) {
      }
    })();
  };

  return {
    createCopyBannerByAdmin,
    loadingDeleteBanner: loading,
  };
};
export default useCreateCopyBanner;
