import React from 'react';
import PropTypes from 'prop-types';
import style from './Page.module.scss';

const Page = ({ children, addClass }) => (
  <div className={`${style.page} ${addClass}`}>
    {children}
  </div>
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  addClass: PropTypes.string,
};
Page.defaultProps = {
  addClass: '',
};

export default Page;
