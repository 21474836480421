import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import s from './Auth.module.scss';
import CartBlock from '../CartBlock/CartBlock';
import LoggedUser from '../LoggedUser/LoggedUser';
import AccountActionModals from '../../SignModals/SignModals';
import Loader from '../../Loader';
import UserContext from '../../Layout/context/userContext';
import useLogoutMutation from '../../../hooks/useLogoutMutation/useLogoutMutation';

const Auth = ({ count }) => {
  const { userData, fetchUserLoading } = useContext(UserContext);

  const { logoutCallback, logoutLoading } = useLogoutMutation();

  const userName = userData?.firstName || 'Account';

  if (fetchUserLoading || logoutLoading) {
    return <Loader />;
  }

  return (
    <div className={s.account}>
      <CartBlock count={count} />
      {userData
        ? (
          <LoggedUser
            loading={logoutLoading}
            logoutCallback={logoutCallback}
            firstName={userName}
          />
        )
        : <AccountActionModals />}
    </div>
  );
};

Auth.propTypes = {
  count: PropTypes.number.isRequired,
};

export default memo(Auth);
