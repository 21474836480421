import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';

const { Item } = Form;
const EditInput = (props) => {
  const {
    error, callback, value, keyField, label, inputVariant, disabledInput,
  } = props;

  const onChangeHandler = (value) => {
    callback(keyField, value);
  };

  const errorText = () => (error ? 'errorTextAccount' : '');

  return (
    <Item
      label={label}
      className="account-label-class"
    >
      {
        inputVariant === 'password'
          ? (
            <Input.Password
              className={error ? 'errorInput' : ''}
              value={value}
              onChange={(e) => onChangeHandler(e.target.value)}
              disabled={disabledInput}
            />
          )
          : (
            <Input
              style={{
                border: '1px solid #EDEDED',
              }}
              className={error ? 'errorInput' : ''}
              value={value}
              onChange={(e) => onChangeHandler(e.target.value)}
              disabled={disabledInput}
            />
          )
      }
      {error && <div className={errorText()}>{error}</div>}
    </Item>
  );
};
EditInput.propTypes = {
  value: PropTypes.string.isRequired,
  keyField: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  error: PropTypes.string,
  inputVariant: PropTypes.string,
  disabledInput: PropTypes.bool,
};

EditInput.defaultProps = {
  error: undefined,
  inputVariant: 'input',
  disabledInput: false,
};

export default memo(EditInput);
