import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { GET_BANNER_BY_ID_ADMIN } from '../../../../api/api-banners/query';

const { REACT_APP_BUCKET_URL } = process.env;

const useGetBannerById = () => {
  const [getBannerById, { loading: loadingGetBanner, error, data }] = useLazyQuery(GET_BANNER_BY_ID_ADMIN);
  const getBannerByIdHandler = useCallback((id) => {
    getBannerById({
      variables: {
        id,
      },
    });
  }, [getBannerById]);

  if (data) {
    const {
      __typename, bannerPicture, position, ...rest
    } = data.getBannerByIdAdmin;

    if (bannerPicture) {
      const {
        __typename: __typenamePicture, key: keyPicture, ...bannerPictureEdit
      } = bannerPicture;

      return {
        getBannerByIdHandler,
        loadingGetBanner,
        error,
        data: {
          ...rest,
          queue: position,
          bannerPicture: {
            ...bannerPictureEdit,
            key: `${REACT_APP_BUCKET_URL}/${keyPicture}`,
          },
        },
      };
    }

    return {
      getBannerByIdHandler,
      loadingGetBanner,
      error,
      data: {
        ...rest,
        bannerPicture,
      },
    };
  }

  return {
    getBannerByIdHandler,
    loadingGetBanner,
    error,
  };
};

export default useGetBannerById;
