import { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CREATE_FILIAL_BRANCH_ADMIN,
  CREATE_MERCHANT_BY_ADMIN,
  DELETE_FILIAL_BRANCH_BY_ID_ADMIN, GET_MERCHANT_BY_ID_ADMIN, UPDATE_FILIAL_BRANCH_ADMIN, UPDATE_MERCHANT_ADMIN,
} from '../../../../../api/create-merchant/query';
import { MERCHANTS } from '../../../../../constants/Routes';
import { MERCHANT_SCHEMA } from '../../../../../constants/validationMerchantSchema';
import validator from '../../../../../helpers/validator';
import { UPDATE_EMPLOYEE_BY_ID } from '../../../../../api/employees/query';

const useSendMerchantInfo = () => {
  const { id } = useParams();
  const idMerchant = id ? id.slice(1) : null;
  const navigate = useNavigate();

  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE_BY_ID);

  const [createMerchantAdmin,
    { data: dataCreateMerchant, loading: loadingCreate, error: errorCreate },
  ] = useMutation(CREATE_MERCHANT_BY_ADMIN);

  const [updateMerchantAdmin,
    { data: dataUpdateMerchant, loading: loadingUpdate, error: errorUpdate },
  ] = useMutation(UPDATE_MERCHANT_ADMIN);

  const [deleteFilialBranchByIdAdmin,
    { loading: loadingDeleteFilialBranch }] = useMutation(DELETE_FILIAL_BRANCH_BY_ID_ADMIN, {
    refetchQueries: [GET_MERCHANT_BY_ID_ADMIN, {
      variables: {
        id: idMerchant,
      },
    }],
  });

  const [updateFilialBranchAdmin, { loading: loadingUpdateFilialBranch }] = useMutation(UPDATE_FILIAL_BRANCH_ADMIN, {
    refetchQueries: [GET_MERCHANT_BY_ID_ADMIN, {
      variables: {
        id: idMerchant,
      },
    }],
  });

  const [createFilialBranchAdmin] = useMutation(CREATE_FILIAL_BRANCH_ADMIN, {
    refetchQueries: [GET_MERCHANT_BY_ID_ADMIN, {
      variables: {
        id: idMerchant,
      },
    }],
  });

  const [allErrors, setAllErrors] = useState(undefined);

  const sendData = useCallback((data) => {
    const { stateInfo, subDivisionsInfo } = data;
    const { errors } = validator({ ...stateInfo, filialBranches: subDivisionsInfo }, MERCHANT_SCHEMA);

    if (!Object.keys(errors).length) {
      const {
        email, firstName, phone, title, countNumberInPhone, dialCode, emailBack, ...restInfo
      } = stateInfo;

      const filialBranchesFin = subDivisionsInfo.map((item) => {
        const {
          address, couponGoldenHours: businessHours, phone, title, location,
        } = item;

        const { lat, lng } = location;

        const finalBusinessHours = businessHours.map((hours) => {
          const { intervalId, isCheckedDay, ...rest } = hours;
          return rest;
        });

        return {
          title: title.trim(),
          address: address.trim(),
          phone: phone.trim(),
          location: [lat, lng],
          businessHours: finalBusinessHours,
        };
      });

      if (!id) {
        createMerchantAdmin({
          variables: {
            createMerchantInput: {
              ...restInfo,
              title: title.trim(),
              contact: {
                email: emailBack.trim(),
                firstName: firstName.trim(),
                phone: phone.trim(),
              },
              filialBranches: filialBranchesFin,
            },
          },
        });
      } else {
        const {
          id, title, status, isAllowedToGetSalesStatistics, isAllowedToGetVoucherEmails, employeeId, firstName,
          emailBack, emirates,
        } = stateInfo;
        updateEmployee({ variables: { id: employeeId, updateEmployeeInput: { firstName, email: emailBack } } })
          .then(() => {
            setAllErrors(null);
          })
          .catch((err) => {
            if (err.message === 'Validation error') {
              const errMsg = 'This email is already exist';
              setAllErrors((prev) => (prev ? { ...prev, emailBack: errMsg } : { emailBack: errMsg }));
            }
          });

        updateMerchantAdmin({
          variables: {
            id,
            updateMerchantInput: {
              emirates,
              title: title.trim(),
              status,
              isAllowedToGetSalesStatistics,
              isAllowedToGetVoucherEmails,
            },
          },
        });
      }
    } else {
      setAllErrors(errors);
    }
  }, [createMerchantAdmin, id, updateMerchantAdmin]);

  const createFilialBranch = useCallback((filialCreate) => {
    const {
      address, couponGoldenHours, phone, title, location,
    } = filialCreate;

    const { lat, lng } = location;

    const businessHours = couponGoldenHours.map((hours) => {
      const { intervalId, isCheckedDay, ...rest } = hours;
      return rest;
    });

    createFilialBranchAdmin({
      variables: {
        merchantId: idMerchant,
        createFilialBranchInput: {
          title: title.trim(),
          location: [lat, lng],
          address: address.trim(),
          phone: phone.trim(),
          businessHours,
        },
      },
    });
  }, [createFilialBranchAdmin, idMerchant]);

  const deleteFilialBranch = useCallback((id) => {
    deleteFilialBranchByIdAdmin({
      variables: {
        id,
      },
    });
  }, [deleteFilialBranchByIdAdmin]);

  const updateFilialBranch = useCallback((filialUpdate) => {
    const {
      id, couponGoldenHours, couponGoldenHoursToCreate, couponGoldenHoursToDelete,
      couponGoldenHoursToUpdate, countNumberInPhone, dialCode, location, ...rest
    } = filialUpdate;

    const { lat, lng } = location;

    const couponGoldenHoursToUpdateForSend = couponGoldenHoursToUpdate.map((hours) => {
      const { id, ...updateBusinessHourInput } = hours;
      return {
        id,
        updateBusinessHourInput,
      };
    });

    updateFilialBranchAdmin({
      variables: {
        id,
        updateFilialBranchInput: {
          ...rest,
          location: [lat, lng],
          businessHoursToCreate: couponGoldenHoursToCreate,
          businessHoursToDelete: couponGoldenHoursToDelete,
          businessHoursToUpdate: couponGoldenHoursToUpdateForSend,
        },
      },
    });
  }, [updateFilialBranchAdmin]);

  if ((dataCreateMerchant || dataUpdateMerchant) && !allErrors) {
    navigate(`/admin/${MERCHANTS}`, { replace: true });
  }

  return {
    sendData,
    loadingCreate,
    deleteFilialBranch,
    loadingDeleteFilialBranch,
    updateFilialBranch,
    loadingUpdateFilialBranch,
    loadingUpdate,
    createFilialBranch,
    allErrors,
    errorCreate: errorCreate?.message,
    errorUpdate: errorUpdate?.message,
  };
};
export default useSendMerchantInfo;
