import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import Cookies from 'js-cookie';
import { REGISTER } from '../../../../api/query';
import { setAccessTokenUser, setRoleUser } from '../../../../helpers/user-storage';
import { MAIN } from '../../../../constants/Routes';
import { SETTING_OF_COOKIES, TITLE_COOKIES } from '../../../../constants/general/tag-status';

const useSignUpUser = () => {
  const navigate = useNavigate();

  const [registerUser, { data: registerData, loading: registerLoading, error: registerError }] = useMutation(REGISTER);

  const sendRegisterData = useCallback((email) => {
    registerUser({
      variables: {
        registerUserInput: {
          email,
          role: 'CUSTOMER',
        },
      },
    })
      .then(({ data }) => {
        const { accessToken, refreshToken, role } = data.register;
        setAccessTokenUser(accessToken);
        Cookies.set(TITLE_COOKIES, refreshToken, SETTING_OF_COOKIES);
        setRoleUser(role);
        navigate(MAIN);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [navigate, registerData, registerUser]);

  return {
    sendRegisterData,
    registerError,
    registerLoading,
  };
};

export default useSignUpUser;
