import React, {
  memo, useCallback, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap, Marker, MarkerClusterer, InfoWindow,
} from '@react-google-maps/api';
import { containerStyle } from '../../../../constants/googleMap';
import './goodle-style.scss';

const MapComponent = ({
  locations, center, styleMapWrapper, zoom, setCenterHandler, titleTargetLinkForMap,
}) => {
  // save link
  const mapRef = useRef(undefined);

  const [activeAddress, setActiveAddress] = useState('');

  const onLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const onUnmount = useCallback(() => {
    mapRef.current = undefined;
  }, []);

  const defaultOptionsSite = useMemo(() => ({
    panControl: true,
    zoomControl: true,
    zoomControlOptions: {
      // eslint-disable-next-line no-undef
      position: google.maps.ControlPosition.LEFT_BOTTOM,
    },
    mapTypeControl: true,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    clickableIcons: true,
    keyboardShortcuts: false,
    scrollwheel: true, // scale
    disableDoubleClickZoom: false,
    fullscreenControl: true,
    fullscreenControlOptions: {
      // eslint-disable-next-line no-undef
      position: google.maps.ControlPosition.RIGHT_TOP,
    },
  }), []);

  const fetchDirections = (coupon) => {
    if (!center) return;
    const { location } = coupon;
    setActiveAddress(coupon.address);
    setCenterHandler(location);
  };

  return (
    <div style={styleMapWrapper}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom || 10}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={defaultOptionsSite}
      >
        <MarkerClusterer>
          {
            (cluster) => locations.map((locate) => (
              <Marker
                key={locate.location.lat}
                position={locate.location}
                clusterer={cluster}
                onClick={() => fetchDirections(locate)}
              >
                { activeAddress === locate.address && (
                  <InfoWindow
                    position={locate.location}
                    key={locate.location.lat}
                    onCloseClick={() => setActiveAddress('')}
                  >
                    <div>
                      <a
                        className="redirectLink"
                        href={`https://maps.google.com?saddr=My+Location&dirflg=d&&daddr=${locate.address}`}
                        target={titleTargetLinkForMap}
                        rel="noreferrer"
                      >
                        Show direction
                      </a>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    </div>
  );
};
MapComponent.propTypes = {
  locations: PropTypes.oneOfType([PropTypes.array]).isRequired,
  center: PropTypes.oneOfType([PropTypes.object]).isRequired,
  zoom: PropTypes.number,
  styleMapWrapper: PropTypes.oneOfType([PropTypes.object]),
  setCenterHandler: PropTypes.func.isRequired,
  titleTargetLinkForMap: PropTypes.string.isRequired,
};

MapComponent.defaultProps = {
  zoom: null,
  styleMapWrapper: null,
};

export default memo(MapComponent);
