import React from 'react';
import moment from 'moment';
import { TimePicker } from 'antd';
import PropTypes from 'prop-types';
import './timeRangePickerAnt.scss';
import { MERCHANT_TIME_FORMAT } from '../../../constants/merchantConstant';

const TimeRangePickerComponent = ({ timeInterval, callback, error }) => {
  const onChangeHandler = (e) => {
    callback(e);
  };

  const finalClass = (!timeInterval.startAt || !timeInterval.endAt) && error ? 'errorMY' : 'timeRangePicker';

  return (
    <div>
      <TimePicker.RangePicker
        value={[
          timeInterval.startAt ? moment(`${timeInterval.startAt}`, MERCHANT_TIME_FORMAT) : null,
          timeInterval.endAt ? moment(`${timeInterval.endAt}`, MERCHANT_TIME_FORMAT) : null,
        ]}
        showTime={{ format: MERCHANT_TIME_FORMAT }}
        format={MERCHANT_TIME_FORMAT}
        onChange={onChangeHandler}
        className={finalClass}
        popupClassName="timeRangePickerMerchant"
        order={false}
        allowClear={false}
      />
      {(!timeInterval.startAt || !timeInterval.endAt) && error
        && <span className="errorCouponText errorTimePciker">{error}</span>}
    </div>
  );
};
TimeRangePickerComponent.propTypes = {
  timeInterval: PropTypes.oneOfType([PropTypes.object]).isRequired,
  callback: PropTypes.func.isRequired,
  error: PropTypes.string,
};
TimeRangePickerComponent.defaultProps = {
  error: undefined,
};

export default TimeRangePickerComponent;
