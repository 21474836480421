import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { DELETE_USER, FIND_ALL_EMPLOYEES } from '../../../../api/employees/query';

const useDeleteEmploy = () => {
  const [deleteUser, { loading }] = useMutation(DELETE_USER, {
    refetchQueries: [FIND_ALL_EMPLOYEES],
  });

  const deleteEmployByAdmin = useCallback(async (id) => {
    try {
      await deleteUser({
        variables: {
          id,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [deleteUser]);

  return {
    deleteEmployByAdmin,
    loadingDeleteEmploy: loading,
  };
};
export default useDeleteEmploy;
