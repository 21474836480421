import { useApolloClient } from '@apollo/client';
import { toDataURL } from 'qrcode';
import { GET_VOUCHER_BY_ID } from '../../../../api/voucher/query';
import formatDate from '../../../../helpers/cart-helpers/formatDate';
import getImgBase64Url from '../../../../helpers/getImgBase64Url';
import LazyDownloadPDFButton from '../../../DownloadVoucher';
import DownloadVouchers from '../../../DownloadVoucher/DownloadVoucherArray';

const useVoucherDownload = () => {
  const client = useApolloClient();
  const downloadAllVouchers = async (id, isSeparateDownload = false) => {
    const res = await client.query({ query: GET_VOUCHER_BY_ID, variables: { id } });

    if (res) {
      const {
        id,
        promoCode,
        payDate,
        address,
        company,
        totalPrice,
        totalVatPrice,
        additionalInformation,
        bookingPhoneNumber,
        voucherNumber,
        coupon: {
          couponPreviewPicture,
          description,
          terms,
          title,
          applyVat,
        },
        voucherOptions,
      } = res.data.getVoucherById;

      const { key } = couponPreviewPicture || {};

      const totalPayDate = payDate ? formatDate(payDate) : '-';

      const fixedVat = applyVat ? `${(totalVatPrice - totalPrice) / 100} AED` : applyVat;

      const fixedCost = `${totalVatPrice / 100} AED`;

      const fixedOptions = voucherOptions?.length > 0
        ? voucherOptions.map((option) => {
          const { totalVatPrice, couponOption: { generateSeparateVoucher } } = option;

          const fixedVatPrice = totalVatPrice / 100;

          return { ...option, totalVatPrice: fixedVatPrice, generateSeparateVoucher };
        })
        : [];

      const data = {
        description,
        terms,
        id,
        title,
        voucherOptions: fixedOptions,
        additionalInformation,
        bookingPhoneNumber,
        address,
        promoCode,
        company,
        totalPayDate,
        fixedVat,
        fixedCost,
        voucherNumber,
        key,
      };

      const imageUrl = await getImgBase64Url(key, 'jpeg');
      const urlToCoupon = `${window.location.origin}/security/voucher/${id}`;
      const couponQRData = await toDataURL(urlToCoupon);
      if (voucherOptions.length > 0) {
        const urlPromises = voucherOptions.map(async ({
          id,
        }) => toDataURL(`${window.location.origin}/security/option/${id}`));
        const optionQRCodesUrl = await Promise.all(urlPromises);

        if (isSeparateDownload) {
          await LazyDownloadPDFButton(data, imageUrl, couponQRData, optionQRCodesUrl);
          return null;
        }

        return {
          data, imageUrl, couponQRData, optionQRCodesUrl,
        };
      }

      if (isSeparateDownload) {
        await LazyDownloadPDFButton(data, imageUrl, couponQRData);
        return null;
      }

      return { data, imageUrl, couponQRData };
    }
    return null;
  };

  const downloadVoucherInFile = async (files) => {
    const docs = [];
    let pdfTitle = '';

    files.forEach((file) => {
      const { data, optionQRCodesUrl, imageUrl } = file;
      const { voucherOptions = [], ...restData } = data;

      docs.push(file);

      if (pdfTitle) {
        pdfTitle += `, ${data.voucherNumber}`;
      } else {
        pdfTitle = data.voucherNumber;
      }

      if (voucherOptions.length) {
        const docOptions = voucherOptions.map((option) => ({
          ...restData,
          ...option,
          fixedCost: `${option.totalVatPrice} AED`,
          isOptionVoucher: true,
        }));

        docOptions.forEach((doc, index) => {
          pdfTitle += `, ${doc.voucherNumber}`;
          docs.push({
            data: doc,
            couponQRData: optionQRCodesUrl[index],
            imageUrl,
            optionQRCodesUrl: [],
          });
        });
      }
    });

    await DownloadVouchers(docs, pdfTitle);
  };

  return { downloadAllVouchers, downloadVoucherInFile };
};

export default useVoucherDownload;
