import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import {
  useCallback, useContext, useEffect, useState,
} from 'react';
import Cookies from 'js-cookie';
import { setAccessTokenUser } from '../../../../helpers/user-storage';
import { CART } from '../../../../constants/Routes';
import { LOGIN } from '../../../../api/query';
import UserContext from '../../../Layout/context/userContext';
import useAddCouponsFromLocal from '../../../Header/hooks/useAddCouponsFromLocal/useAddCouponsFromLocal';
import { SETTING_OF_COOKIES, TITLE_COOKIES } from '../../../../constants/general/tag-status';

const useSignUser = () => {
  const navigate = useNavigate();

  const { fetchUser } = useContext(UserContext);

  const [isRerenderNeed, setIsRerenderNeed] = useState(false);

  const [loginUser, { loading: loginLoading, error }] = useMutation(LOGIN);

  const { uploadCoupons, fetchFromLocalLoading } = useAddCouponsFromLocal();

  useEffect(async () => {
    if (isRerenderNeed) {
      await uploadCoupons();
      fetchUser();
      navigate(CART, { replace: true }); // `/${CHECKOUT}`
      setIsRerenderNeed(false);
    }
  }, [isRerenderNeed]);

  const [loginError, setLoginError] = useState(error);

  const sendLoginData = useCallback((values) => {
    const { email, password } = values;
    loginUser({
      variables: {
        loginUserInput: {
          email,
          password,
        },
      },
    })
      .then((res) => {
        const { accessToken, refreshToken } = res.data.login;
        setAccessTokenUser(accessToken);
        Cookies.set(TITLE_COOKIES, refreshToken, SETTING_OF_COOKIES);
        setIsRerenderNeed(true);
      })
      .catch((err) => {
        setLoginError(err.message === 'User not found'
          ? 'Wrong Email or Password'
          : err.message);
      });
  }, [fetchUser, loginUser, navigate]);

  return {
    sendLoginData,
    loginLoading,
    loginError,
    fetchFromLocalLoading,
  };
};

export default useSignUser;
