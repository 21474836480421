import React, { memo, useState } from 'react';
import { Switch } from 'antd';
import PropTypes from 'prop-types';
import { USER_ACTIVE_INACTIVE, USER_ACTIVE_STATUS } from '../../../../constants/employ';
import './active-block.scss';

const ActiveBlock = ({ status, changeInitStateEmploy }) => {
  const [isCheckedStatus, setCheckedStatus] = useState(
    status === USER_ACTIVE_STATUS,
  );

  const setStatus = () => {
    setCheckedStatus(!isCheckedStatus);
    changeInitStateEmploy(
      'status',
      status === USER_ACTIVE_STATUS ? USER_ACTIVE_INACTIVE : USER_ACTIVE_STATUS,
    );
  };

  return (
    <div className="statusToggle">
      <Switch
        checked={isCheckedStatus}
        onChange={setStatus}
        size="small"
      />
      <span className="statusToggle__title">{isCheckedStatus ? 'Active' : 'Deactivated'}</span>
    </div>
  );
};

ActiveBlock.propTypes = {
  changeInitStateEmploy: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
};

export default memo(ActiveBlock);
