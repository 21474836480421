import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UserIcon } from '../../../constants/icons';
import s from './LoggedUser.module.scss';
import { COUPONS_PROFILE, PROFILE } from '../../../constants/Routes';

const LoggedUser = ({ loading, logoutCallback, firstName }) => {
  const [isShow, setIsShow] = useState(false);

  const showPopUpOnCLick = () => setIsShow(true);

  const hidePopUpCallback = useCallback(() => {
    if (isShow) {
      setIsShow(false);
    }
  }, [isShow]);

  useEffect(() => {
    window.addEventListener('click', hidePopUpCallback);
    return () => {
      window.removeEventListener('click', hidePopUpCallback);
    };
  }, [isShow, hidePopUpCallback]);

  return (
    <div
      onClick={showPopUpOnCLick}
      className={s.container}
    >
      <UserIcon />
      <div className={s.account}>
        {firstName}
      </div>
      {isShow && (
        <div className={s.popup}>
          <span>
            <Link
              className={s.linkPopun}
              style={{
                color: 'currentColor',
                width: '100%',
                height: '100%',
              }}
              to={PROFILE}
            >
              Account Information
            </Link>
          </span>
          <span>
            <Link
              className={s.linkPopun}
              style={{
                color: 'currentColor',
                width: '100%',
                height: '100%',
              }}
              to={`${PROFILE}/${COUPONS_PROFILE}`}
            >
              My Coupons
            </Link>
          </span>
          <span
            aria-disabled={loading}
            onClick={logoutCallback}
          >
            Logout
          </span>
        </div>
      )}
    </div>
  );
};

LoggedUser.propTypes = {
  logoutCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  firstName: PropTypes.string.isRequired,
};

export default memo(LoggedUser);
