import React, { memo } from 'react';
import { MERCHANT_ADMIN, MERCHANT_SUPER_ADMIN } from '../../constants/admin-roles';
import VouchersTable from './VouchersTable';
import VouchersOfMerchant from './VouchersOfMerchant';
import { getRoleUser } from '../../helpers/user-storage';

const VouchersComponent = () => {
  const role = getRoleUser();
  if (role === MERCHANT_ADMIN || role === MERCHANT_SUPER_ADMIN) {
    return <VouchersOfMerchant />;
  }

  return <VouchersTable />;
};

export default memo(VouchersComponent);
