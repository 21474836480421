import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { UPDATE_COUPON_STATUS_IS_PUBLISHED_ADMIN } from '../../../../../api/query';
import useFindAllCoupons from './useFindAllCoupons';

const useUpdateCouponStatusAdmin = () => {
  const { totalQuery } = useFindAllCoupons();

  const [updateCouponStatusIsPublishedAdmin,
    { loading: loadingUpdateCouponByAdmin, error }] = useMutation(UPDATE_COUPON_STATUS_IS_PUBLISHED_ADMIN, {
    refetchQueries: [totalQuery()],
  });

  const updateCouponByAdmin = useCallback(async (id, status, isPublished) => {
    try {
      await updateCouponStatusIsPublishedAdmin({
        variables: {
          updateCouponStatusInput: {
            status,
            isPublished,
          },
          id,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [updateCouponStatusIsPublishedAdmin]);

  return {
    updateCouponByAdmin,
    loadingUpdateCouponByAdmin,
    errorUpdateCouponByAdminLoading: error,
  };
};
export default useUpdateCouponStatusAdmin;
