import React from 'react';
import Page from '../../components/Page';
import OrdersTable from '../../components/Orders/OrdersTable';

const Buyers = () => (
  <Page>
    <OrdersTable />
  </Page>
);

export default Buyers;
