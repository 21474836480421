import React, {
  memo, useCallback, useEffect, useState,
} from 'react';
import { message } from 'antd';
import closeBanan from '../../../assets/images/closeBanan.png';
import InputComponent from '../../Common_components/InputComponent';
import validatorForm from '../../../helpers/validator';
import useSendNewPassword from './hooks/gql/useSendNewPassword';
import RESET_PASSWORD_SCHEMA from '../../../constants/signIn/validatorSchema';
import ButtonComponent from '../../Common_components/ButtonComponent';
import Loader from '../../Loader';

const ResetPassword = () => {
  const [dataPassword, setDataPassword] = useState({
    confirmPassword: '',
    newPassword: '',
  });
  const [allErrors, setAllErrors] = useState(null);

  const { loading, error, changePasswordHandler } = useSendNewPassword();

  const changeDataAccount = useCallback((key, value) => {
    setDataPassword((prev) => ({
      ...prev,
      [key]: value,
    }));
    setAllErrors((prev) => ({
      ...prev,
      [key]: '',
    }));
  }, []);

  const sendDataHandler = useCallback(() => {
    const { confirmPassword, newPassword } = dataPassword;
    const { errors } = validatorForm({
      confirmPassword: confirmPassword && confirmPassword.trim(),
      newPassword: newPassword && newPassword.trim(),
    }, RESET_PASSWORD_SCHEMA);

    if (!Object.keys(errors).length) {
      changePasswordHandler({
        confirmPassword: confirmPassword.trim(),
        newPassword: newPassword.trim(),
      });
    } else {
      setAllErrors(() => ({ ...errors }));
    }
  }, [changePasswordHandler, dataPassword]);

  useEffect(() => {
    if (error) {
      message.config({
        maxCount: 1,
      });
      message.open({
        type: 'error',
        content: 'Error! Please repeat later',
        duration: 3,
      });
    }
  }, [error]);

  return (
    <>
      {loading && <Loader />}
      <div className="sing-in-card">
        <div className="sing-in-card__image">
          <img
            src={closeBanan}
            alt="img"
          />
        </div>
        <h1 className="sing-in-card__title">Reset your password</h1>
        <div className="sing-in-card__form">
          <InputComponent
            label="New password"
            error={allErrors?.newPassword}
            keyField="newPassword"
            value={dataPassword.newPassword}
            callback={changeDataAccount}
            inputVariant="password"
            isAutoFocus
          />
          <InputComponent
            label="Confirm password"
            error={allErrors?.confirmPassword}
            keyField="confirmPassword"
            value={dataPassword.confirmPassword}
            callback={changeDataAccount}
            inputVariant="password"
          />
        </div>
        <ButtonComponent
          title="Create New Password"
          iconComponent={null}
          addClass="sing-in-card__action-btn"
          callback={sendDataHandler}
        />
      </div>
    </>
  );
};

export default memo(ResetPassword);
