import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { clearAccessTokenUser, clearRoleUser } from '../../../../helpers/user-storage';
import { SIGN_IN } from '../../../../constants/Routes';
import { LOGOUT } from '../../../../api/query';
import { TITLE_COOKIES } from '../../../../constants/general/tag-status';

const useLogoutUser = () => {
  const [logout, { loading, error, data }] = useMutation(LOGOUT);
  const navigate = useNavigate();

  if (data) {
    clearAccessTokenUser();
    clearRoleUser();
    Cookies.remove(TITLE_COOKIES);
    navigate(`${SIGN_IN}`, { replace: true });
  }

  return {
    logout,
    loading,
    error,
  };
};

export default useLogoutUser;
