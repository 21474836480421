import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './auto-complete.module.scss';

const AutoComplete = React.memo((props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    init,
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    initOnMount: false,
    debounce: 300,
  });

  const {
    isLoaded, onSelect, address, addClass, center, setAddressOfLocation, wasClickOnMap,
  } = props;

  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    setValue(description, false);
    clearSuggestions();
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        onSelect({ lat, lng }, description);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('😱 Error: ', error);
      });
  };

  const renderSuggestions = () => data.map((suggestion) => {
    const {
      place_id: placeId,
      structured_formatting: { main_text: mainText, secondary_text: secondaryText },
    } = suggestion;

    return (
      <li
        className={s.listItem}
        key={placeId}
        onClick={handleSelect(suggestion)}
      >
        <strong>{mainText}</strong>
        {' '}
        <small>{secondaryText}</small>
      </li>
    );
  });

  useEffect(() => {
    if (isLoaded) {
      init();
    }
  }, [isLoaded, init]);

  useEffect(() => {
    if (address) setValue(address, false);
  }, []);

  useEffect(() => {
    if (wasClickOnMap) {
      getGeocode({ location: center })
        .then((result) => {
          const [{ formatted_address: formattedAddress }] = result;

          setValue(formattedAddress, false);
          setAddressOfLocation(result[0].formatted_address);
        });
    }
  }, [wasClickOnMap]);

  return (
    <div
      className={s.root}
      ref={ref}
    >
      <input
        value={value}
        className={`${s.input} ${addClass}`}
        onChange={handleInput}
        disabled={!ready}
        placeholder=""
      />
      {status === 'OK' && <ul className={s.suggestion}>{renderSuggestions()}</ul>}
    </div>
  );
});

AutoComplete.propTypes = {
  isLoaded: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  address: PropTypes.string.isRequired,
  addClass: PropTypes.string,
  center: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setAddressOfLocation: PropTypes.func.isRequired,
  wasClickOnMap: PropTypes.number.isRequired,
};

AutoComplete.defaultProps = {
  addClass: null,
};

export default memo(AutoComplete);
