import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { EXPORT_ORDERS_TO_EXCEL } from '../../../../api/api-orders/query';
import { DATE_RANGE_END_KEY, DATE_RANGE_START_KEY, SEARCH_FILTER_KEY } from '../../../../constants/general/filters';

const useExportOrders = () => {
  const [s] = useSearchParams();

  const dateRangeStart = s.get(DATE_RANGE_START_KEY);

  const dateRangeEnd = s.get(DATE_RANGE_END_KEY);

  const search = s.get(SEARCH_FILTER_KEY) || '';

  const { data = {} } = useQuery(EXPORT_ORDERS_TO_EXCEL, {
    variables: {
      search,
      dateRangeStart,
      dateRangeEnd,
    },
  });

  const { exportOrdersToExcel = {} } = data;

  const { ordersExcelKey } = exportOrdersToExcel;

  return `${process.env.REACT_APP_BUCKET_URL}/${ordersExcelKey}`;
};

export default useExportOrders;
