import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { RESET_PASSWORD } from '../../../../../api/query';

const useSendEmail = () => {
  const navigate = useNavigate();
  const [resetPassword, { loading, error }] = useMutation(RESET_PASSWORD);
  const sendEmailHandler = useCallback(async (email) => {
    try {
      await resetPassword({
        variables: {
          email,
        },
      });
      navigate('/admin/signIn/check-email', { replace: true });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e.message);
    }
  }, [navigate, resetPassword]);

  return {
    sendEmailHandler,
    loading,
    errorForgot: error,
  };
};

export default useSendEmail;
