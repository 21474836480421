import { COUPONS_IN_CART } from '../../constants/cart-constants/cart-constants';
import placeholder from '../../assets/images/placeholder.svg';

export const setWindowOffset = () => {
  const prevOffset = localStorage.getItem('window-offset');
  if (!prevOffset) {
    const offset = JSON.stringify(window.pageYOffset);
    localStorage.setItem('window-offset', offset);
  }
};

export const clearWindowOffset = () => {
  localStorage.removeItem('window-offset');
};

export const getGalleryArray = (preview, gallery) => {
  if (gallery.length > 0) {
    if (preview) {
      const previewLink = `${process.env.REACT_APP_BUCKET_URL}/${preview.key}`;
      return [previewLink, ...gallery.map(({ key }) => (key))
        .map((l) => (`${process.env.REACT_APP_BUCKET_URL}/${l}`))];
    }
    return gallery.map(({ key }) => (key))
      .map((l) => (`${process.env.REACT_APP_BUCKET_URL}/${l}`));
  }
  if (preview) {
    const previewLink = `${process.env.REACT_APP_BUCKET_URL}/${preview.key}`;
    return [previewLink];
  }
  return [placeholder];
};

export const scrollToPreviousOffset = () => {
  const offset = JSON.parse(localStorage.getItem('window-offset'));
  if (offset) {
    window.scrollTo({ top: offset, left: 0 });
    localStorage.removeItem('window-offset');
  }
};

export const addCouponToLocalStorageCart = (coupon) => {
  const { shoppingCartCouponOptions, quantity, id } = coupon;
  const couponsInLocalCart = JSON.parse(localStorage.getItem(COUPONS_IN_CART));
  if (couponsInLocalCart) {
    const item = couponsInLocalCart.find((c) => c.id === id);
    if (item) {
      const updatedItems = couponsInLocalCart
        .map((c) => {
          if (c.id === id) {
            if (shoppingCartCouponOptions.length > 0) {
              const newOptions = c.shoppingCartCouponOptions.map((oldOpt) => {
                const optionById = shoppingCartCouponOptions.find((newOpt) => newOpt.id === oldOpt.id);
                if (optionById) {
                  return { ...oldOpt, amount: oldOpt.amount + optionById.amount };
                }
                return oldOpt;
              });
              return { ...c, quantity: c.quantity + quantity, shoppingCartCouponOptions: newOptions };
            }
            return {
              ...c,
              quantity: c.quantity + quantity,
            };
          }
          return c;
        });
      localStorage.setItem(COUPONS_IN_CART, JSON.stringify(updatedItems));
    } else {
      const updatedItems = [...couponsInLocalCart, coupon];
      localStorage.setItem(COUPONS_IN_CART, JSON.stringify(updatedItems));
    }
  } else {
    localStorage.setItem(COUPONS_IN_CART, JSON.stringify([coupon]));
  }
};
