import React, { memo } from 'react';
import PropTypes from 'prop-types';
import mobileCondition from '../../../../constants/mobileCondition';
import MobileSummary from '../MobileSummary';
import DesktopSummary from '../DesktopSummary';

const SummaryContainer = ({
  totalWithPromo, promoCode, promoCodeError, totalCost, promoDiscount, setPromo, totalOriginalPrice, totalVat,
  setPromoCode, payButtonCallback, totalWithoutVat, totalSaved, loadingCreateOrder,
}) => {
  if (mobileCondition) {
    return (
      <MobileSummary
        promoCode={promoCode}
        totalWithPromo={totalWithPromo}
        payButtonCallback={payButtonCallback}
        promoDiscount={promoDiscount}
        totalCost={totalCost}
        totalVat={totalVat}
        promoCodeError={promoCodeError}
        setPromo={setPromo}
        setPromoCode={setPromoCode}
        totalOriginalPrice={totalOriginalPrice}
        totalWithoutVat={totalWithoutVat}
        totalSaved={totalSaved}
        loadingCreateOrder={loadingCreateOrder}
      />
    );
  }

  return (
    <DesktopSummary
      promoCode={promoCode}
      totalWithPromo={totalWithPromo}
      payButtonCallback={payButtonCallback}
      promoDiscount={promoDiscount}
      totalCost={totalCost}
      totalVat={totalVat}
      promoCodeError={promoCodeError}
      setPromo={setPromo}
      setPromoCode={setPromoCode}
      totalOriginalPrice={totalOriginalPrice}
      totalWithoutVat={totalWithoutVat}
      totalSaved={totalSaved}
      loadingCreateOrder={loadingCreateOrder}
    />
  );
};

SummaryContainer.propTypes = {
  promoCode: PropTypes.string.isRequired,
  setPromoCode: PropTypes.func.isRequired,
  promoCodeError: PropTypes.string,
  promoDiscount: PropTypes.number.isRequired,
  totalOriginalPrice: PropTypes.number.isRequired,
  totalWithPromo: PropTypes.number.isRequired,
  totalVat: PropTypes.number.isRequired,
  totalCost: PropTypes.number.isRequired,
  payButtonCallback: PropTypes.func.isRequired,
  setPromo: PropTypes.func.isRequired,
  totalWithoutVat: PropTypes.number.isRequired,
  totalSaved: PropTypes.number.isRequired,
  loadingCreateOrder: PropTypes.bool.isRequired,
};

SummaryContainer.defaultProps = {
  promoCodeError: null,
};

export default memo(SummaryContainer);
