import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const useLocationContext = () => {
  const { state } = useLocation();

  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (state) {
      setRedirectTo(state);
    }
  }, [state]);

  return redirectTo;
};

export default useLocationContext;
