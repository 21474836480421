import { useMutation } from '@apollo/client';
import { CREATE_PROMO_CODE_ADMIN } from '../../../../api/PromoCodes/query';
import { Quantity, Tied } from '../../../../constants/promoCodes/promoCodes';

const useCreatePromoCode = (refetch) => {
  const [createPromoCode, { data, loading, error }] = useMutation(CREATE_PROMO_CODE_ADMIN);

  const sendAddForm = (formData) => {
    const {
      number,
      discountUnit,
      discount,
      date,
      quantity,
      availableAmount,
      tied,
      couponId,
      optionId,
      note,
    } = formData;

    createPromoCode({
      variables: {
        createPromoCodeInput: {
          promoCode: number,
          discount: +discount,
          discountUnit,
          validDueDate: date,
          availableAmount: quantity === Quantity.SINGLE ? 1 : +availableAmount,
          comment: note,
          couponId,
          couponOptionId: tied === Tied.OPTION ? optionId : undefined,
        },
      },
    }).then(() => refetch());
  };

  return {
    sendAddForm,
    data,
    loading,
    error,
  };
};

export default useCreatePromoCode;
