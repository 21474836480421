import placeholder from '../../assets/images/placeholder-min.png';

const getCouponGalleryArray = (preview, gallery) => {
  if (gallery.length > 0) {
    const galleryUrls = gallery.map(({ key }) => (`${process.env.REACT_APP_BUCKET_URL}/${key}`));
    if (preview) {
      const previewUrl = `${process.env.REACT_APP_BUCKET_URL}/${preview.key}`;
      return [previewUrl, ...galleryUrls];
    }
    return galleryUrls;
  }
  if (preview) {
    const previewUrl = `${process.env.REACT_APP_BUCKET_URL}/${preview.key}`;
    return [previewUrl];
  }
  return [placeholder];
};

export default getCouponGalleryArray;
