export const currentAndOldPrice = (createPrice, vat, vatValue) => {
  if (vat) {
    const num = +createPrice + (+createPrice * (vatValue / 100)) + Number.EPSILON;
    return Number(Math.round(num * 100) / 100);
  }
  return createPrice;
};

// By price
export const discountByPrice = (currentPrice, oldPrice) => Number(100 - (100 * (+currentPrice / +oldPrice)).toFixed(0));
// By discount
export const currentPriceByDiscount = (oldPrice, discount) => {
  const num = +oldPrice - (+oldPrice * (+discount / 100)) + Number.EPSILON;
  return Number(Math.round(num * 100) / 100);
};

export const roundingPrice = (price) => {
  const num = +price + Number.EPSILON;
  return Number(Math.round(num * 100) / 100);
};

// export const divideAndRoundingPrice = (price) => Math.round((price + Number.EPSILON) / 100);
