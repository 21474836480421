import React, { useCallback, useEffect, useState } from 'react';
import {
  Input,
  Modal, message, Form,
} from 'antd';
import PropTypes from 'prop-types';
import useCreateCategory from './hooks/useCreateCategory';
import { ERROR_CREATE_CATEGORY, ERROR_UPLOAD_CATEGORY_IMAGE } from '../../../constants/errors';
import PreviewImage from './PreviewImage/PreviewImage';
import useUploadCategoryPicture from './hooks/useUploadCategoryPicture';
import validatorForm from '../../../helpers/validator';
import { ADD_CATEGORY_SCHEMA } from '../../../constants/validatorSchema';
import { finallyBorderInput } from '../../../helpers/finallyBorder';
import s from './Error.module.scss';
import style from '../EditModal/EditModal.module.scss';
import { Close } from '../../../constants/icons';
import './modalCategories.scss';

const AddModal = ({
  isModalVisible, handleOk, handleCancel, parentCategoryId, categories,
}) => {
  const [errors, setErrors] = useState(null);
  const [name, setName] = useState('');
  const [prevImage, setPrevImage] = useState(null);
  const { createCategory, errorCreateCategory } = useCreateCategory(parentCategoryId);
  const { uploadImage, errorUploadImage } = useUploadCategoryPicture();
  const { errors: validationErrors } = validatorForm({ name, prevImage }, ADD_CATEGORY_SCHEMA);
  const [nameInputError, setNameInputError] = useState('');

  const clearModal = () => {
    setName('');
    setPrevImage(null);
  };

  const onOk = async () => {
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length) {
      return;
    }

    const categoryData = await createCategory(name);
    const uploadImageData = await uploadImage(categoryData.data.createCategory.id, prevImage);

    if (uploadImageData && categoryData) {
      clearModal();
      handleOk();
    }

    clearModal();
  };

  const onCancel = () => {
    clearModal();
    handleCancel();
  };

  if (errorCreateCategory) {
    message.error(ERROR_CREATE_CATEGORY);
  }

  if (errorUploadImage) {
    message.error(ERROR_UPLOAD_CATEGORY_IMAGE);
  }

  const containsSameName = useCallback(() => categories && !!(parentCategoryId
    ? categories.filter(({ id }) => id === parentCategoryId)[0].subCategories.filter(({ title }) => title === name)
      .length
    : categories.filter(({ title }) => title === name)).length, [categories, name, parentCategoryId]);

  useEffect(() => {
    if (errors?.name) {
      setNameInputError(errors.name);
    }

    if (containsSameName()) {
      setNameInputError(`A ${parentCategoryId ? 'subcategory' : 'category'} with the same name already exists!`);
    }

    if (!errors?.name && !containsSameName()) {
      setNameInputError('');
    }
  }, [containsSameName, errors, name, parentCategoryId]);

  return (
    <Modal
      title={`Add ${parentCategoryId ? 'subcategory' : 'category'}`}
      visible={isModalVisible}
      onOk={onOk}
      onCancel={() => {
        onCancel();
        setErrors(null);
      }}
      okText={`Add ${parentCategoryId ? 'Subcategory' : 'Category'}`}
      okButtonProps={{ className: 'addButtonCategories' }}
      cancelButtonProps={{ type: 'text' }}
      closeIcon={<Close color="#A8A8A8" />}
      centered
    >
      <Form
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          label="Name"
        >
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              border: (errors?.name && finallyBorderInput(!errors.name))
                || (containsSameName() && finallyBorderInput(!containsSameName())),
              borderRadius: '4px',
              height: '40px',
            }}
          />
          <span className={s.errorMessage}>{nameInputError}</span>
        </Form.Item>
        <Form.Item
          label="Icon"
          className={style.upload}
        >
          <PreviewImage
            prevImage={prevImage}
            setPrevImage={setPrevImage}
          />
          {errors?.prevImage && !prevImage && <span className={s.errorMessage}>{errors.prevImage}</span>}
        </Form.Item>
      </Form>
    </Modal>
  );
};

AddModal.propTypes = {
  isModalVisible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  parentCategoryId: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.shape({
    categoryPicture: PropTypes.shape({
      categoryId: PropTypes.string,
      id: PropTypes.string,
      key: PropTypes.string,
      mimetype: PropTypes.string,
      originalName: PropTypes.string,
    }),
    couponsCount: PropTypes.number,
    id: PropTypes.string,
    isPublished: PropTypes.bool,
    key: PropTypes.string,
    parentCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    position: PropTypes.number,
    subCategories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      position: PropTypes.number,
      isPublished: PropTypes.bool,
      couponsCount: PropTypes.number,
      parentCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      categoryPicture: PropTypes.shape({
        categoryId: PropTypes.string,
        id: PropTypes.string,
        key: PropTypes.string,
        mimetype: PropTypes.string,
        originalName: PropTypes.string,
      }),
    })),
    title: PropTypes.string,
  })),
};

AddModal.defaultProps = {
  parentCategoryId: null,
  categories: [],
};

export default AddModal;
