import PropTypes from 'prop-types';
import React from 'react';
import s from './DaysMenuItem.module.scss';

const DaysMenuItem = ({ fTitle, days }) => (
  <div className={s.dropdownContainer}>
    <h3 className={s.dayName}>{fTitle}</h3>
    <div className={s.allDays}>
      {
        days.map((day) => (
          <div
            key={day.id}
            className={s.dayTime}
          >
            <span>{day.startAt}</span>
            <span>-</span>
            <span>{day.endAt}</span>
          </div>
        ))
      }
    </div>
  </div>
);

DaysMenuItem.propTypes = {
  fTitle: PropTypes.string.isRequired,
  days: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default DaysMenuItem;
