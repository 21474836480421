import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { finallyBorder } from '../../Coupon/CouponMaking/Coupon/helpers/finallyBorder';

const { Option } = Select;

const MultiplySelectCommonComponent = ({
  defaultValues, onChange, error, items,
}) => {
  const mappedOptions = items.map(({ label, value }) => (
    <Option
      key={value}
      value={value}
    >
      {label}
    </Option>
  ));

  return (
    <Select
      mode="multiple"
      className="commonMultiSelect"
      defaultValue={defaultValues}
      onChange={onChange}
      maxTagCount={2}
      style={{
        width: '100%',
        border: error ? finallyBorder(defaultValues.length > 0) : '1px solid #EDEDED',
        borderRadius: '4px',
        boxSizing: 'border-box',
        lineHeight: '40px',
      }}
    >
      {mappedOptions}
    </Select>
  );
};

MultiplySelectCommonComponent.propTypes = {
  defaultValues: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  items: PropTypes.array.isRequired,
};

MultiplySelectCommonComponent.defaultProps = {
  error: null,
};

export default MultiplySelectCommonComponent;
