import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { GET_ALL_BANNERS_ADMIN } from '../../../../api/api-banners/query';
import { limit } from '../../../../constants/tableConstants';
import useLastPageChange from '../../../../hooks/useLastPageChange/useLastPageChange';

const useGetAllBanners = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';

  const { loading, error, data } = useQuery(GET_ALL_BANNERS_ADMIN, {
    variables: {
      offset: (Number(page) - 1) * limit,
      limit,
    },
  });

  useLastPageChange(data);

  if (data) {
    const { banners, count } = data.getAllBannersAdmin;

    return {
      loading,
      error,
      banners,
      count,
    };
  }

  return {
    loading,
    error,
  };
};

export default useGetAllBanners;
