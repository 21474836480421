import { memo, useCallback, useState } from 'react';
import { DatePicker } from 'antd';
import './DoubleDatePickerComponent.scss';
import PropTypes from 'prop-types';
import { LeftArrow, RightArrow } from '../../../constants/icons';
import { DATE_KEYS_FILTER_ARRAY } from '../../../constants/general/filters';

const { RangePicker } = DatePicker;

const DoubleDatePickerComponent = ({
  containerClassName, isShow, hideDropdownCallback, outerCallback,
}) => {
  const [dateState, setDateState] = useState({ value: {} });

  const onCloseChangeCallback = useCallback((open) => {
    if (!open && !!Object.keys(dateState.value).length) {
      outerCallback(dateState);
      setDateState({ value: {} });
    }
  }, [dateState, outerCallback]);

  const onCalendarChangeCallback = useCallback((dates) => {
    const nonNullDates = dates
      .filter((date) => !!date)
      .reduce((acc, date, i) => ({
        ...acc,
        [DATE_KEYS_FILTER_ARRAY[i]]: date.set(i === 0 ? {
          hour: 0, minute: 0, second: 0, millisecond: 0,
        } : {
          hour: 23, minute: 59, second: 59, millisecond: 999,
        })
          .format(),
      }), {});
    setDateState({ value: nonNullDates });
    if (Object.keys(nonNullDates).length === 2) {
      outerCallback({ value: nonNullDates });
      setDateState({ value: {} });
      hideDropdownCallback();
    }
  }, []);

  return (
    <div className={`rangePickerContainer ${containerClassName}`}>
      {isShow && (
        <div
          onClick={hideDropdownCallback}
          className="DoublePickerCloser"
        />
      )}
      <RangePicker
        open={isShow}
        nextIcon={<RightArrow />}
        prevIcon={<LeftArrow />}
        onOpenChange={onCloseChangeCallback}
        onCalendarChange={onCalendarChangeCallback}
        placement="bottomLeft"
        className="doublePickerInput"
        dropdownClassName="doublePicker"
      />
    </div>
  );
};

DoubleDatePickerComponent.propTypes = {
  containerClassName: PropTypes.string,
  isShow: PropTypes.bool.isRequired,
  hideDropdownCallback: PropTypes.func.isRequired,
  outerCallback: PropTypes.func.isRequired,
};

DoubleDatePickerComponent.defaultProps = {
  containerClassName: '',
};

export default memo(DoubleDatePickerComponent);
