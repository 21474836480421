import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import s from './merchant-making.module.scss';
import BodyMerchant from './BodyMerchant';
import HeaderComponent from './HeaderComponent';

const MerchantsMaking = (props) => {
  const {
    stateInfo,
    setStateInfo,
    setStateInfoHandler,
    subDivisionsInfo,
    addSubDivisionsInfoHandler,
    sendDataHandler,
    deleteSubDivisionsInfoHandler,
    editSubDivisionsInfoHandler,
    isValidMerchantInfo,
    allErrors,
    errorCreate,
    errorUpdate,
  } = props;

  const { id } = useParams();

  return (
    <section className={s.body}>
      <HeaderComponent
        sendDataHandler={sendDataHandler}
        title={id && stateInfo.title}
        isValidMerchantInfo={isValidMerchantInfo}
      />
      <BodyMerchant
        stateInfo={stateInfo}
        setStateInfo={setStateInfo}
        setStateInfoHandler={setStateInfoHandler}
        subDivisionsInfo={subDivisionsInfo}
        addSubDivisionsInfoHandler={addSubDivisionsInfoHandler}
        deleteSubDivisionsInfoHandler={deleteSubDivisionsInfoHandler}
        editSubDivisionsInfoHandler={editSubDivisionsInfoHandler}
        allErrors={allErrors}
        errorCreate={errorCreate}
        errorUpdate={errorUpdate}
      />
    </section>
  );
};

export default MerchantsMaking;

MerchantsMaking.propTypes = {
  stateInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setStateInfo: PropTypes.func.isRequired,
  setStateInfoHandler: PropTypes.func.isRequired,
  subDivisionsInfo: PropTypes.oneOfType([PropTypes.array]).isRequired,
  addSubDivisionsInfoHandler: PropTypes.func.isRequired,
  sendDataHandler: PropTypes.func.isRequired,
  deleteSubDivisionsInfoHandler: PropTypes.func.isRequired,
  editSubDivisionsInfoHandler: PropTypes.func.isRequired,
  isValidMerchantInfo: PropTypes.bool.isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
  errorCreate: PropTypes.string,
  errorUpdate: PropTypes.string,
};

MerchantsMaking.defaultProps = {
  allErrors: undefined,
  errorCreate: undefined,
  errorUpdate: undefined,
};
