import React from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from '../../../Common_components/ButtonComponent';
import './header-component.scss';

const HeaderComponent = ({ callback }) => {
  const callbackBtn = () => {
    callback();
  };

  return (
    <header className="header">
      <div className="header__row">
        <div className="header__left">
          <h1 className="header__title">Employees</h1>
          <ButtonComponent
            title="Add"
            callback={callbackBtn}
          />
        </div>
      </div>
    </header>
  );
};
HeaderComponent.propTypes = {
  callback: PropTypes.func.isRequired,
};

export default HeaderComponent;
