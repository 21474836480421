import { gql } from '@apollo/client';

export const REFRESH = gql`
    mutation refresh ($refreshToken: String!){
        refresh (refreshToken:$refreshToken){
            accessToken
            refreshToken
        }
    }
`;

export const LOGIN = gql`
    mutation login($loginUserInput: LoginUserInput!) {
        login(loginUserInput: $loginUserInput) {
            id
            firstName
            accessToken
            refreshToken
            role
            merchantId
            merchant {
                isAllowedToGetSalesStatistics
            }
        }
    }
`;

export const LOGOUT = gql`
    mutation logout ($refreshToken: String!){
        logout (refreshToken:$refreshToken)
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword ($email: String!) {
        resetPassword (email: $email) 
    }
`;

export const CREATE_PASSWORD = gql`
    mutation createPassword ($changePasswordInput: ChangePasswordInput!, $id: String!) {
        createPassword (changePasswordInput: $changePasswordInput, id: $id)
    }
`;

export const GetEmployeeSelf = gql`
    query {
        getEmployeeSelf {
            id
            firstName
            email
            lastName
            role
            merchantId
            merchant {
                isAllowedToGetSalesStatistics
                filialBranches {
                    address
                    phone
                    title
                }
                contacts {
                    firstName
                    lastName
                }
            }
            userPicture {
                id
                key
            }
        }
    }
`;

export const GET_NOTIFICATION_SETTINGS = gql`
    query {
        getNotificationSettings {
            id
            userId
            priceUpdates
        }
    }
`;

export const GET_MERCHANT_BY_ID_ADMIN = gql`
    query getMerchantByIdAdmin($id: ID!) {
        getMerchantByIdAdmin(id: $id) {
            contacts {
                firstName
                phone
                email
            }
            emirates
            title
            filialBranches {
                address
            }
        }
    }
`;

export const ADD_COUPONS_TO_CART = gql`
    mutation addCouponsToShoppingCart($shoppingCartsCouponInput: [ShoppingCartCouponCreateInput!]!) {
        addCouponsToShoppingCart(shoppingCartsCouponInput: $shoppingCartsCouponInput)
    }
`;

export const CREATE_COUPON_COMMON_INPUT = gql`
    mutation createCouponAdmin ($createCouponInput: CreateCouponCommonInput!, $merchantId: ID!) {
        createCouponAdmin(createCouponInput: $createCouponInput, merchantId: $merchantId) {
            id
        }
    }
`;

export const FIND_ALL_COUPONS_ADMIN = gql`
    query findAllCouponsAdmin( 
        $status: CouponStatuses!, 
        $limit: Float, 
        $offset: Float
        $isPublished: Boolean,
        $sortField: String,
        $sortOrder: String,
        $subCategoryId: String,
        $categoryId: String,
        $dateRangeStart: DateTime,
        $dateRangeEnd: DateTime,
        $search: String,
    ) {
        findAllCouponsAdmin(
            status:$status , 
            limit: $limit, 
            offset : $offset
            sortField: $sortField,
            isPublished: $isPublished,
            sortOrder: $sortOrder,
            subCategoryId: $subCategoryId,
            categoryId: $categoryId,
            dateRangeStart: $dateRangeStart,
            dateRangeEnd: $dateRangeEnd,
            search: $search,
        ) {
            coupons {
                title
                id
                publishedDueDate
                validDueDate
                isPublished
                updatedAt
                terms
                category {
                    title
                }
                subCategory {
                    title
                }
                merchant {
                    title
                }
                currentPrice
                status
                createdAt
                couponViewsCount
                couponPurchasesCount
                currentAvailableAmount
                availableAmountInPeriod
            }
            count
        }
    }
`;

export const FIND_ALL_COUPONS_MERCHANT = gql`
    query findAllCouponsMerchant(
        $status: CouponStatuses!, 
        $limit: Float, 
        $offset: Float
        $isPublished: Boolean,
        $sortField: String,
        $sortOrder: String,
        $subCategoryId: String,
        $categoryId: String,
        $dateRangeStart: DateTime,
        $dateRangeEnd: DateTime,
        $search: String,
    ) {
        findAllCouponsMerchant(
            status:$status ,
            limit: $limit, 
            offset : $offset
            sortField: $sortField,
            isPublished: $isPublished,
            sortOrder: $sortOrder,
            subCategoryId: $subCategoryId,
            categoryId: $categoryId,
            dateRangeStart: $dateRangeStart,
            dateRangeEnd: $dateRangeEnd,
            search: $search,
        ) {
            coupons {
                title
                id
                publishedDueDate
                validDueDate
                isPublished
                updatedAt
                emirates
                terms
                category {
                    title
                }
                subCategory {
                    title
                }
                merchant {
                    title
                }
                currentPrice
                status
                createdAt
                couponViewsCount
                currentAvailableAmount
                availableAmountInPeriod
            }
            count
        }
    }
`;

export const getAllMerchants = gql`
    query getAllMerchantsAdmin {
        getAllMerchantsAdmin {
            merchants {
                id
                title
                filialBranches {
                    id
                    title
                }
            }
        }
    }
`;

export const GET_CATEGORIES = gql`
    query adminCategories{
        adminCategories{
            id
            title
            subCategories {
                id
                title
            }
        }
    }
`;

export const DELETE_COUPON = gql`
    mutation deleteCouponByIdAdmin ($id: ID!) {
        deleteCouponByIdAdmin (id: $id)
    }
`;

export const GET_COUPON_BY_ID = gql`
    query getCouponByIdAdmin ($id: ID!) {
        getCouponByIdAdmin (id: $id) {
            id
            title
            createPrice
            oldPrice
            currentPrice
            discount
            applyVat
            emirates
            countPriceByDiscount
            additionalInformation
            keywordsImages
            bookingPhone
            merchant {
                id
                title
            }
            validDueDate
            publishedDueDate
            availableAmountInPeriod
            limitPeriod
            isPublished
            isHotDeal
            couponOptions {
                id
                title
                createPrice
                oldPrice
                currentPrice
                discount
                applyVat
                countPriceByDiscount
                generateSeparateVoucher
                allowToUsePromoCode
                filialBranches {
                    id
                    title
                    address
                }
            }
            category {
                id
                title
                subCategories {
                    id
                    title
                }
            }
            subCategory {
                id
                title
                subCategories {
                    id
                    title
                }
            }
            couponGoldenHours {
                id
                dayOfWeek
                startAt
                endAt
            }
            status
            description
            terms
            videoReviewUrl
            allowToUsePromoCode
            filialBranches {
                id
                title
                address
            }
            couponPreviewPicture {
                id
                key
            }
            couponGalleryPictures {
                id
                key
                originalName
                position
            }
        }
    }
`;

export const UPDATE_COUPON_BY_ID = gql`
    mutation updateCouponByIdAdmin($couponUpdateInput: UpdateCouponCommonInput!, $id: ID!) {
        updateCouponByIdAdmin(couponUpdateInput: $couponUpdateInput, id: $id) {
            id
        }
    }
`;

export const GET_COMMON_SETTINGS = gql`
    query getCommonSettings {
        getCommonSettings {
            vat
        }
    }
`;

export const UPLOAD_COUPON_PREVIEW_PICTURE = gql`
    mutation uploadCouponPreviewPicture ($file: Upload!, $couponId: ID!) {
        uploadCouponPreviewPicture(file: $file, couponId: $couponId)  {
            id
        }
    }
`;

export const DELETE_PREVIEW_IMAGE = gql`
    mutation deleteCouponPreviewPicture ($couponPreviewPictureId: ID!) {
        deleteCouponPreviewPicture (couponPreviewPictureId: $couponPreviewPictureId)
    }
`;

export const UPLOAD_COUPON_GALLERY_PICTURE = gql`
    mutation uploadCouponGalleryPicture ($position: Int!, $file: Upload!, $couponId: ID! ) {
        uploadCouponGalleryPicture (position: $position, file: $file, couponId:  $couponId) {
            id
        }
    }
`;

export const SET_ORDER_COUPON_GALLERY_PICTURES = gql`
    mutation setOrderCouponGalleryPictures ($ids: [ID!]!) {
        setOrderCouponGalleryPictures (ids: $ids)
    }
`;

export const DELETE_COUPON_GALLERY_PICTURE = gql`
    mutation deleteCouponGalleryPicture ($couponGalleryPictureId: ID!) {
        deleteCouponGalleryPicture (couponGalleryPictureId: $couponGalleryPictureId)
    }
`;

export const UPDATE_COUPON_STATUS_IS_PUBLISHED_ADMIN = gql`
    mutation updateCouponStatusIsPublishedAdmin ($updateCouponStatusInput: UpdateCouponStatusIsPublishedInput!,
        $id: String!) {
        updateCouponStatusIsPublishedAdmin (updateCouponStatusInput: $updateCouponStatusInput, id: $id) {
            id
        }
    }
`;

export const CREATE_COUPON_COPY_ADMIN = gql`
    mutation createCouponCopyAdmin ($id: ID!) {
        createCouponCopyAdmin (id: $id) {
            id
        }
    }
`;

export const AdminCategories = gql`
    query adminCategories($isPublished: Boolean, $title: String) {
        adminCategories(isPublished: $isPublished, title: $title) {
            id
            title
            position
            isPublished
            showInHeader
            couponsCount
            parentCategoryId
            subCategories {
                id
                title
                position
                isPublished
                couponsCount
                parentCategoryId
                categoryPicture {
                    id
                    categoryId
                    key
                    originalName
                    mimetype
                }
            }
            categoryPicture {
                id
                categoryId
                key
                originalName
                mimetype
            }
        }
    }
`;

export const CreateCategory = gql`
    mutation createCategory($createCategoryInput: CreateCategoryInput!) {
        createCategory(createCategoryInput: $createCategoryInput) {
            id
            title
            position
            isPublished
        }
    }
`;

export const UploadCategoryPicture = gql`
    mutation uploadCategoryPicture(
        $file: Upload!
        $categoryId: ID!
    ) {
        uploadCategoryPicture(
            file: $file
            categoryId: $categoryId
        ) {
            id
            categoryId
            key
            originalName
            mimetype
        }
    }
`;

export const UpdateCategory = gql`
    mutation updateCategory(
        $updateCategoryInput: UpdateCategoryInput!
        $id: ID!
    ) {
        updateCategory(
            updateCategoryInput: $updateCategoryInput
            id: $id
        ) {
            id
            title
            position
            isPublished
        }
    }
`;

export const UpdateCategoryPosition = gql`
    mutation updateCategoryPosition(
        $updateCategoryPositionInput: UpdateCategoryPositionInput!
        $id: ID!
    ) {
        updateCategoryPosition(
            updateCategoryPositionInput: $updateCategoryPositionInput
            id: $id
        ) {
            id
            title
            position
            isPublished
        }
    }
`;

export const DeleteCategory = gql`
    mutation deleteCategory(
        $id: ID!
    ) {
        deleteCategory(
            id: $id
        )
    }
`;

export const GET_USER_SELF = gql`
    query {
        getUserSelf {
            id
            firstName
            lastName
            email
            role
            status
            phone
        }
    }
`;

export const REGISTER = gql`
    mutation register($registerUserInput: RegisterUserInput!) {
        register(registerUserInput: $registerUserInput) {
            role
            accessToken
            refreshToken
        }
    }
`;

export const UPDATE_PRICE_NOTIFICATION_SETTINGS = gql`
    mutation updateNotificationSettings($updateNotificationSettingsInput: UpdateNotificationSettingsInput!) {
        updateNotificationSettings(updateNotificationSettingsInput: $updateNotificationSettingsInput) {
            id
        }
    }
`;
