import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import s from './SubCategoryItem.module.scss';
import Icon from '../../Icon/Icon';
import CategoriesContext from '../../../Layout/context/categoriesContext';

const SubCategoryItem = ({
  id, title, icon, couponsCount,
}) => {
  const { scrollOnCouponsTop, setSearchParams } = useContext(CategoriesContext);

  const [searchParams] = useSearchParams();

  const fetchedSubCategoryId = searchParams.get('subCategoryId');

  const selectedClassName = `${s.main} ${fetchedSubCategoryId === id && s.selected}`;

  const fetchSubCategoryCoupons = (e) => {
    e.stopPropagation();
    if (couponsCount) {
      scrollOnCouponsTop();
      setSearchParams({ subCategoryId: id });
    }
  };

  return (
    <div
      className={selectedClassName}
      onClick={fetchSubCategoryCoupons}
    >
      <div className={s.container}>
        <Icon
          url={icon}
          name={title}
        />
        <span className={s.title}>
          {title}
        </span>
      </div>
    </div>
  );
};

SubCategoryItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  couponsCount: PropTypes.number.isRequired,
};

export default memo(SubCategoryItem);
