import React, { memo } from 'react';
import './profile-merchant.scss';
import ProfileImage from './ProfileImage';
import ProfileInfo from './ProfileInfo';

const ProfileMerchantSettings = () => (
  <section className="merchant-profile">
    <h2 className="merchant-profile__title">My Profile</h2>
    <section className="merchant__info">
      <ProfileImage />
      <ProfileInfo />
    </section>
  </section>
);

export default memo(ProfileMerchantSettings);
