import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Radio, Space } from 'antd';
import Days from '../../../../Common_components/DaysIntervalComponent/Days';
import './CanUse.scss';

const CanUse = ({ couponGoldenHours, setCouponGoldenHoursHandler, allErrors }) => {
  const initHours = useRef(couponGoldenHours);

  const [isWorkingHours, setWorkingHours] = useState(!!couponGoldenHours?.length);

  const onChangeHandler = (e) => {
    if (e.target.value === 1) {
      setWorkingHours(false);
      setCouponGoldenHoursHandler((prev) => ({
        ...prev,
        couponGoldenHours: [],
        couponGoldenHoursToUpdate: [],
        couponGoldenHoursToCreate: [],
        couponGoldenHoursToDelete: [...initHours.current],
      }));
    } else {
      setWorkingHours(true);
    }
  };

  return (
    <div className="hours">
      <div className="radiousBtn">
        <Radio.Group
          onChange={onChangeHandler}
          defaultValue={couponGoldenHours?.length ? 2 : 1}
        >
          <Space direction="vertical">
            <Radio value={1}>In working hours</Radio>
            <Radio value={2}>Set</Radio>
          </Space>
        </Radio.Group>
      </div>
      {isWorkingHours && (
        <Days
          hours={couponGoldenHours}
          setHours={setCouponGoldenHoursHandler}
          allErrors={allErrors}
        />
      )}
    </div>
  );
};
CanUse.propTypes = {
  couponGoldenHours: PropTypes.oneOfType([PropTypes.array]).isRequired,
  setCouponGoldenHoursHandler: PropTypes.func.isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
};
CanUse.defaultProps = {
  allErrors: null,
};
export default CanUse;
