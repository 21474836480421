import React from 'react';
import Page from '../../components/Page';
import ProfileMerchantSettings from '../../components/ProfileMerchant';

const ProfileMerchant = () => (
  <Page>
    <ProfileMerchantSettings />
  </Page>
);

export default ProfileMerchant;
