import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { AdminCategories } from '../../../../api/query';
import { IS_PUBLISHED_FILTER_KEY } from '../../../../constants/general/filters';
import useLastPageChange from '../../../../hooks/useLastPageChange/useLastPageChange';

const addChildren = (categories) => categories.map((category) => (
  category.subCategories.length
    ? {
      ...category,
      children: category.subCategories
        .map((subCategory) => ({ ...subCategory, position: subCategory.position + 1, key: subCategory.id })),
    }
    : category
)).map((category) => ({ ...category, key: category.id }));

const useGetCategories = (noQuery = false) => {
  const [searchParams] = useSearchParams();

  const isPublished = noQuery ? null : JSON.parse(searchParams.get(IS_PUBLISHED_FILTER_KEY));
  const search = noQuery ? '' : searchParams.get('search');

  const {
    data = {}, loading, error, refetch,
  } = useQuery(AdminCategories, {
    variables: { isPublished, title: search },
  });

  useLastPageChange(noQuery ? undefined : data);

  const { adminCategories } = data;

  return {
    categories: adminCategories
      && addChildren(adminCategories).map((category) => ({ ...category, position: category.position + 1 })),
    loading,
    error,
    refetch,
  };
};

export default useGetCategories;
