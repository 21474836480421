import React from 'react';
import { Typography } from 'antd';
import moment from 'moment/moment';
import { makeUpperFirstLetter } from '../../../../helpers/general/general';
import './column-logs.scss';
import TooltipComponent from '../../../Common_components/TooltipComponent';
import copyToClipboard from '../../../../helpers/copyToClipboard';
import { CopyIcon } from '../../../../constants/icons';
import { LOGS_CREATE_ENTITY, LOGS_DELETE_ENTITY, LOGS_LOGIN_ENTITY } from '../../../../constants/logs/entities';
import DropdownWithClearComponent from '../../../Common_components/DropdownWithClearComponent';
import { LOGS_ACTION_FILTER_KEY, logsActionEntityFilters } from '../../../../constants/general/filters';
import useSearchFilters from '../../../Coupon/CouponTable/hooks/filters/useSearchFilters';
import DoubleDateForTableWrapper from '../../../Common_components/DoubleDateForTableWrapper';

const useColumnLogs = (showMessageLogIdWasCopied) => {
  const showAdminName = (firstName, lastName) => {
    if (!firstName && !lastName) return '-';
    if (!firstName && lastName) return makeUpperFirstLetter(lastName);
    if (firstName && !lastName) return makeUpperFirstLetter(firstName);

    return `${makeUpperFirstLetter(firstName)} ${makeUpperFirstLetter(lastName)}`;
  };

  const { dropdownValueHandler } = useSearchFilters();

  const logsActionHandler = (value) => dropdownValueHandler(value, LOGS_ACTION_FILTER_KEY);

  const textAction = (action) => {
    switch (action) {
    case LOGS_LOGIN_ENTITY: return 'Login';
    case LOGS_CREATE_ENTITY: return 'Create';
    case LOGS_DELETE_ENTITY: return 'Delete';
    default: return 'Update';
    }
  };

  const copiedId = async (id) => {
    await copyToClipboard(id);
    showMessageLogIdWasCopied(id);
  };

  return [
    {
      title: <DoubleDateForTableWrapper />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '10%',
      render: (date) => (
        <Typography.Text>
          {
            moment(date).format('DD MMMM, YYYY')
          }
        </Typography.Text>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      ellipsis: true,
      width: '5%',
      render: (time) => (
        <Typography.Text>
          {time}
        </Typography.Text>
      ),
    },
    {
      title: 'Coupon ID',
      dataIndex: 'entityId',
      key: 'entityId',
      ellipsis: true,
      width: '8%',
      render: (id) => (
        <TooltipComponent
          title={id}
          placement="bottomLeft"
          overlayClassName="tooltipAdminTableColumns maxWidthTooltip"
        >
          <div className="fieldWithIdAdminTable">
            <Typography.Text>
              {id.slice(0, 5)}
              ...
            </Typography.Text>
            <span
              className="copyIdAdminTablesIcon"
              onClick={() => copiedId(id)}
            >
              <CopyIcon />
            </span>
          </div>
        </TooltipComponent>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'user',
      key: 'user',
      ellipsis: true,
      width: '24%',
      render: (user) => (
        <div className="user-column-logs">
          <div className="user-column-logs__item">
            {showAdminName(user.firstName, user.lastName)}
          </div>
          <div className="user-column-logs__email ">
            {user.email}
          </div>
        </div>
      ),
    },
    {
      title: () => (
        <div
          className="dropdownWrapperHeader"
        >
          <span>Action</span>
          <DropdownWithClearComponent
            items={logsActionEntityFilters}
            minWidth="130px"
            callback={logsActionHandler}
          />
        </div>
      ),
      dataIndex: 'action',
      key: 'action',
      ellipsis: true,
      width: '8%',
      render: (action) => <Typography.Text>{textAction(action)}</Typography.Text>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: '45%',
      render: (description) => <Typography.Text>{description}</Typography.Text>,
    },
  ];
};

export default useColumnLogs;
