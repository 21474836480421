import React, { memo } from 'react';
import s from './CouponMaking.module.scss';
import useGetCoupon from './hooks/useGetCoupon';
import Loader from '../../Loader';
import ButtonsAction from './ButtonsAction';
import Coupon from './Coupon';

const CouponMaking = () => {
  const initStateForCreateCreateCoupon = {
    title: null,
    merchantId: null,
    categoryId: null,
    subCategoryId: null,
    isHotDeal: false,
    isPublished: false,
    validDueDate: null,
    publishedDueDate: null,
    description: null,
    terms: null,
    emirates: [],
    videoReviewUrl: null,
    allowToUsePromoCode: false,
    availableAmountInPeriod: null, // Quantity limit
    countPriceByDiscount: false,
    createPrice: null,
    oldPrice: null, // depend on countPriceByDiscount ( must be false)
    discount: null, // depend on countPriceByDiscount (must be true )
    applyVat: false,
    filialBranches: [],
    couponGoldenHours: [],
    couponGoldenHoursToCreate: [],
    couponGoldenHoursToUpdate: [],
    couponGoldenHoursToDelete: [],
    couponFilialBranchesToAdd: [],
    couponFilialBranchesToRemove: [],
    bookingPhone: '',
    additionalInformation: '',
    keywordsImages: '',
  };
  const initStateForCouponOptionsCreateCoupon = [];
  const initStateForPrevImageCreateCoupon = {
    id: '',
    key: null,
  };
  const initStateGalleryImagesCreateCoupon = [];

  const idCreateCoupon = '';
  const {
    couponInput,
    setCouponInputHandler,
    setCouponInput,
    prevImage,
    setPrevImage,
    setGalleryImages,
    couponOptions,
    copyCouponOptions,
    addNewCouponOptions,
    deleteCouponOptions,
    galleryImages,
    sendDataHandler,
    setOptionForAddOptions,
    setOptionFilialBranchesForAddOptions,
    loadingCreateCoupon,
    loadingUpdateCoupon,
    allErrors,
    deleteImageFromGallery,
    changeOrderImagesInGallery,
  } = useGetCoupon(
    initStateForCreateCreateCoupon,
    initStateForCouponOptionsCreateCoupon,
    initStateForPrevImageCreateCoupon,
    initStateGalleryImagesCreateCoupon,
    idCreateCoupon,
  );

  return (
    <section className={`${s.body} couponMaking`}>
      {
        (loadingCreateCoupon || loadingUpdateCoupon) && <Loader />
      }
      <h1 className={s.title}>Creating coupon</h1>
      <Coupon
        couponInput={couponInput}
        setCouponInputHandler={setCouponInputHandler}
        setCouponInput={setCouponInput}
        prevImage={prevImage}
        setPrevImage={setPrevImage}
        setGalleryImages={setGalleryImages}
        couponOptions={couponOptions}
        copyCouponOptions={copyCouponOptions}
        addNewCouponOptions={addNewCouponOptions}
        deleteCouponOptions={deleteCouponOptions}
        galleryImages={galleryImages}
        setOptionForAddOptions={setOptionForAddOptions}
        setOptionFilialBranchesForAddOptions={setOptionFilialBranchesForAddOptions}
        allErrors={allErrors}
        deleteImageFromGallery={deleteImageFromGallery}
        changeOrderImagesInGallery={changeOrderImagesInGallery}
      />
      <div className={s.btnActions}>
        <ButtonsAction
          sendData={sendDataHandler}
        />
      </div>
    </section>

  );
};
export default memo(CouponMaking);
