import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import Item from '../Item';
import style from './VAT.module.scss';
import useUpdateCommonSettings from '../hooks/useUpdateCommonSettings';
import SuffixInput from '../../../components/Common_components/SuffixInput';
import { DiscountUnits } from '../../../constants/promoCodes/promoCodes';

const VAT = ({ vatValue }) => {
  const [value, setValue] = useState(vatValue);
  const [edit, setEdit] = useState(false);
  const { updateCommonSettings } = useUpdateCommonSettings();

  return (
    <>
      <Item label="VAT">
        <SuffixInput
          discountUnit={DiscountUnits.PERCENTS}
          value={`${value}`}
          suffix="%"
          onChange={(value) => (value >= 100 ? setValue(100) : setValue(value))}
          className={style.vatInput}
          disabled={!edit}
        />
      </Item>
      <Button
        type="primary"
        onClick={() => {
          if (edit) {
            updateCommonSettings(+value);
          }

          setEdit(!edit);
        }}
        className={style.button}
      >
        {edit ? 'Save' : 'Edit'}
      </Button>
    </>
  );
};

VAT.propTypes = {
  vatValue: PropTypes.number.isRequired,
};

export default VAT;
