import React from 'react';
import PropTypes from 'prop-types';
import s from './GoogleButton.module.scss';
import { GoogleIcon } from '../../../constants/icons';
import useGoogleAuth from '../../../hooks/useGoogleAuth/useGoogleAuth';

const GoogleButton = ({ form }) => {
  const { googleAuthHandler } = useGoogleAuth(form);

  return (
    <>
      <div className={s.subText}>
        <span className={s.label}>
          Or continue with
        </span>
      </div>
      <div
        className={s.google}
        onClick={googleAuthHandler}
      >
        <GoogleIcon />
        <span className={s.text}>Google</span>
      </div>
    </>
  );
};

GoogleButton.propTypes = {
  form: PropTypes.object,
};

GoogleButton.defaultProps = {
  form: undefined,
};

export default GoogleButton;
