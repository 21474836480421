import {
  Button, DatePicker, Divider, TimePicker,
} from 'antd';
import React, { memo, useEffect, useState } from 'react';
import './DatePickerComponet.scss';
import moment from 'moment';
import PropTypes from 'prop-types';

const DatePickerComponent = ({
  keyValue, callback, activeDate, error,
}) => {
  const [open, setOpen] = useState(false);
  const [isError, setError] = useState(false);
  const [data, setData] = useState({
    date: activeDate ? moment(`${activeDate}`) : '',
    hours: activeDate ? moment(`${activeDate}`) : '',
    minute: activeDate ? moment(`${activeDate}`) : '',
  });

  const finallyClassSendBtn = data.date && data.hours && data.minute
    ? 'btn' : 'btn disabled';

  const onChangeHandler = (fieldsValue) => {
    setData({
      ...data,
      date: fieldsValue,
    });
  };

  const onOpenChangeHandler = () => {
    setOpen(true);
  };
  const onSendDataHandler = () => {
    const { date, hours, minute } = data;

    const finalData = moment(date, 'MM-DD-YYYY')
      .set('hours', moment(hours).get('hours'))
      .set('minute', moment(minute).get('minute'));

    callback(keyValue, finalData);
    setOpen((prev) => !prev);
    setError(false);
  };

  const canselHandler = () => {
    setData({
      date: '',
      hours: '',
      minute: '',
    });
    setOpen(false);
    callback(keyValue, null);
  };

  const setHoursHandler = (fieldsValue) => {
    setData({
      ...data,
      hours: fieldsValue,
    });
  };
  const setMinuteHandler = (fieldsValue) => {
    setData({
      ...data,
      minute: fieldsValue,
    });
  };

  function getDisabledHours() {
    if (moment(data.date).format('YYYY MM DD') === moment().format('YYYY MM DD')) {
      return Array.from(Array(moment().hour() + 1).keys());
    }
    return [];
  }

  const closeDatepickerHandler = (e) => {
    e.stopPropagation();
    setData({
      date: activeDate ? moment(`${activeDate}`) : '',
      hours: activeDate ? moment(`${activeDate}`) : '',
      minute: activeDate ? moment(`${activeDate}`) : '',
    });
    setOpen(false);
  };

  useEffect(() => {
    if (error && !data.date && !data.hours && !data.minute) {
      setError(true);
    }
  }, [data.date, data.hours, data.minute, error]);
  return (
    <div className={isError ? 'timePicke222r inputError' : 'timePicke222r'}>
      <div
        className={open ? 'BGC' : 'BGC close'}
        onClick={closeDatepickerHandler}
      />
      <DatePicker
        className={open ? 'activeInput' : ''}
        open={open}
        value={data.date && data.hours && data.minute ? data.date : ''}
        onChange={onChangeHandler}
        onOpenChange={onOpenChangeHandler}
        allowClear={false}
        style={{
          borderRadius: '4px',
        }}
        dropdownClassName="datePickerCouponDropdown"
        disabledDate={(current) => moment().add(-1, 'days') >= current}
        renderExtraFooter={() => (
          <>
            <div className="timePickerContainer">
              <div>
                <h6 className="timePicker__title">Hours</h6>
                <TimePicker
                  value={data.hours}
                  format="HH"
                  popupClassName="timePicker"
                  disabledHours={() => getDisabledHours()}
                  onSelect={(value) => (setHoursHandler(value))}
                />
              </div>
              <div>
                <h6 className="timePicker__title">Minutes</h6>
                <TimePicker
                  value={data.minute}
                  format="mm"
                  popupClassName="timePicker"
                  onSelect={(value) => (setMinuteHandler(value))}
                />
              </div>
            </div>
            <Divider />
            <div className="buttons">
              <Button
                className="btn cancel"
                onClick={canselHandler}
              >
                Cancel
              </Button>
              <Button
                className={finallyClassSendBtn}
                disabled={!data.date || !data.hours || !data.minute}
                onClick={onSendDataHandler}
              >
                Set date
              </Button>
            </div>
          </>
        )}
      />
    </div>
  );
};

DatePickerComponent.propTypes = {
  keyValue: PropTypes.string.isRequired,
  activeDate: PropTypes.string,
  callback: PropTypes.func.isRequired,
  error: PropTypes.string,
};

DatePickerComponent.defaultProps = {
  activeDate: null,
  error: undefined,
};

export default memo(DatePickerComponent);
