import React, { createContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { isUserStored } from '../../../helpers/user-storage';
import { PROFILE } from '../../../constants/Routes';
import useLocationContext from '../hooks/useLocationContext';

export const LocationContext = createContext(null);

const MobileNotAuthRedirect = ({ children }) => {
  const redirectTo = useLocationContext();

  if (isUserStored()) return <Navigate to={PROFILE} />;

  return (
    <LocationContext.Provider value={redirectTo}>
      {children}
    </LocationContext.Provider>
  );
};

MobileNotAuthRedirect.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(MobileNotAuthRedirect);
