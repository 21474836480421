import React from 'react';
import { useNavigate } from 'react-router-dom';
import s from './Cart.module.scss';
import CartContext from './cartContext/cartContext';
import Loader from '../Loader';
import Summary from './Summary';
import CouponsTable from './CouponsTable';
import useCartContext from './hooks/useCartContext';
import EmptyCart from './EmptyCart';
import mobileCondition from '../../constants/mobileCondition';

const Cart = () => {
  const context = useCartContext();

  const navigate = useNavigate();

  const { isAnyCartDataExist, isLoadingNow } = context;

  const contentClassName = `${s.content} ${mobileCondition && s.mobile}`;

  const mainClassName = `${s.main} ${mobileCondition && s.mobile} ${!isAnyCartDataExist && s.center}`;

  const containerClassName = `${s.container} ${mobileCondition && s.mobile}`;

  if (isLoadingNow && !isAnyCartDataExist) return <Loader />;

  return (
    <CartContext.Provider value={context}>
      {isLoadingNow && <Loader />}
      <div className={mainClassName}>
        <div className={containerClassName}>
          {isAnyCartDataExist ? (
            <>
              <div className={s.cartHeader}>
                <span className={s.title}>Cart</span>
                {!mobileCondition && (
                  <span
                    onClick={() => navigate(-1)}
                    className={s.continue}
                  >
                    Continue Shopping
                  </span>
                )}
              </div>
              <div className={contentClassName}>
                <div className={s.coupons}>
                  <div className={s.couponsList}>
                    <CouponsTable />
                  </div>
                </div>
                <Summary />
              </div>
            </>
          ) : <EmptyCart />}
        </div>
      </div>
    </CartContext.Provider>
  );
};

export default Cart;
