import { useQuery } from '@apollo/client';
import { GET_USER_SELF } from '../../../api/query';

const useGetAccountInfo = () => {
  const { data, loading: getUserDataLoading, error: getUserDataError } = useQuery(GET_USER_SELF);

  if (data) {
    const {
      userPicture, status, role, __typename, firstName, lastName, phone, ...rest
    } = data.getUserSelf;

    return {
      userData: {
        ...rest,
        firstName: firstName || '',
        lastName: lastName || '',
        phone: phone || '',
      },
      getUserDataLoading,
      getUserDataError,
    };
  }

  return {
    getUserDataLoading,
    getUserDataError,
  };
};

export default useGetAccountInfo;
