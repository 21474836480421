import React, { memo, useCallback, useRef } from 'react';
import {
  Checkbox, Divider, Form, Input,
} from 'antd';
import PropTypes from 'prop-types';
import PreviewImageSimple from '../../../../Common_components/PrevImageSimple';
import SelectMultiply from '../../../../Common_components/MultiplySelectAddress/SelectMultiply';
import DatePickerComponent from '../../../../Common_components/DatePickerComponent';
import CountingBody from '../../CountingBody';
import useGetCategories from '../../hooks/gql/useGetCategories';
import s from './CouponInfo.module.scss';
import './CouponInfo.scss';
import TextAreas from './TextAreas';
import SelectComponent from '../../../../Common_components/SelectComponent';
import NumericInput from '../../../../Common_components/InputNumberComponent';
import { regLink } from '../../../../../helpers/regularExpression/regularExpression';
import CanUse from '../CanUse';
import UploadGalleryImages from '../../../../Common_components/UploadGalleryImages';
import VisibleOnVoucher from '../VisibleOnVoucher';
import { getRoleUser } from '../../../../../helpers/user-storage';
import { MERCHANT_ADMIN } from '../../../../../constants/admin-roles';
import MultiplySelectCommonComponent from '../../../../Common_components/MulltiplySelectCommonComponent';
import { emiratesItems } from '../../../../../constants/merchantConstant';

const CouponInfo = (
  {
    setCouponInputHandler,
    couponInput,
    setCouponInput,
    prevImage,
    setPrevImage,
    setGalleryImages,
    galleryImages,
    merchants,
    vatValue,
    allErrors,
    deleteImageFromGallery,
    changeOrderImagesInGallery,
  },
) => {
  const { categories } = useGetCategories();
  const onSelectHandler = (key, value) => {
    setCouponInputHandler(key, value);
  };
  const initFilialBranches = useRef(couponInput.filialBranches);

  const role = getRoleUser();

  const setAddressHandler = (value) => {
    const arr = value.filter((item) => !initFilialBranches.current.find((el) => el === item));
    const removeArr = initFilialBranches.current.filter((item) => !value.find((el) => el === item));

    setCouponInput((prev) => ({
      ...prev,
      filialBranches: [...value],
      couponFilialBranchesToAdd: arr,
      couponFilialBranchesToRemove: removeArr,
    }));
  };

  const setEmiratesHandler = (value) => {
    setCouponInput((prev) => ({
      ...prev,
      emirates: [...value],
    }));
  };

  const findSubcategories = () => {
    if (categories) {
      const sub = categories.find((cat) => cat.id === couponInput.categoryId);
      return sub?.subCategories || [];
    }
    return [];
  };

  const onClearSubCategoryHandler = useCallback(() => {
    setCouponInputHandler('subCategoryId', null);
  }, []);

  return (
    <div
      className="couponInfo"
    >
      <Form
        layout="horizontal"
      >
        <div className="inputs-from">
          <Form.Item
            label="Coupon name"
            className={s.formItem}
          >
            <Input
              autoFocus
              className={allErrors?.title && !couponInput.title ? `${s.input} ${s.inputError}` : s.input}
              style={{
                height: '40px',
                border: '1px solid #EDEDED',
                borderRadius: '4px',
              }}
              maxLength={254}
              value={couponInput.title}
              onChange={(e) => setCouponInputHandler('title', e.target.value)}
            />
            {allErrors?.title && !couponInput.title
            && <span className="errorCouponText">{allErrors.title}</span>}
          </Form.Item>

          <Form.Item
            label="Merchant"
            className={`${s.formItem} selectItemCoupon`}
          >
            <SelectComponent
              activeOption={couponInput.merchantId}
              options={merchants}
              onSelectHandler={onSelectHandler}
              keyName="merchantId"
              errors={allErrors?.merchantId}
              disabled={role === MERCHANT_ADMIN}
            />
            {allErrors?.merchantId && !couponInput.merchantId
              && <span className="errorCouponText">{allErrors.merchantId}</span>}
          </Form.Item>
          <Form.Item label="Address">
            <SelectMultiply
              activeOption={couponInput.merchantId}
              merchants={merchants}
              address={couponInput.filialBranches}
              callback={setAddressHandler}
              error={allErrors?.filialBranches}
            />
            {allErrors?.filialBranches && couponInput.filialBranches
              && couponInput.filialBranches.length === 0
              && <span className="errorCouponText">{allErrors.filialBranches}</span>}
          </Form.Item>

          <Form.Item label="Emirate">
            <MultiplySelectCommonComponent
              defaultValues={couponInput.emirates}
              onChange={setEmiratesHandler}
              error={allErrors?.emirates}
              items={emiratesItems}
            />
            {allErrors?.emirates && couponInput.emirates
              && couponInput.emirates.length === 0
              && <span className="errorCouponText">{allErrors.emirates}</span>}
          </Form.Item>

          <Form.Item
            label="Category"
            className={`${s.formItem} selectItemCoupon`}
          >
            <SelectComponent
              activeOption={couponInput.categoryId}
              options={categories}
              onSelectHandler={onSelectHandler}
              keyName="categoryId"
              errors={allErrors?.categoryId}
            />
            {allErrors?.categoryId && !couponInput.categoryId
              && <span className="errorCouponText">{allErrors.categoryId}</span>}
          </Form.Item>

          <Form.Item
            label="Subcategory"
            className={`${s.formItem} selectItemCoupon`}
          >
            <SelectComponent
              activeOption={couponInput.subCategoryId}
              options={findSubcategories()}
              onSelectHandler={onSelectHandler}
              keyName="subCategoryId"
              disabled={!couponInput.categoryId}
              errors={allErrors?.subCategoryId}
              allowClear
              onClearValueHandler={onClearSubCategoryHandler}

            />
            {allErrors?.categoryId && !couponInput.subCategoryId
              && <span className="errorCouponText">{allErrors.subCategoryId}</span>}
          </Form.Item>

          <Form.Item
            name="isHotDeal"
            label="Add to best sellers"
          >
            <Checkbox
              keyValue="isHotDeal"
              checked={couponInput.isHotDeal}
              onChange={(e) => setCouponInputHandler('isHotDeal', e.target.checked)}
            />
          </Form.Item>

          <Form.Item label="Quantity limit">
            <NumericInput
              style={{
                border: allErrors?.availableAmountInPeriod && +couponInput.availableAmountInPeriod === 0
                  ? '1px solid red' : '1px solid #EDEDED',
                height: '40px',
              }}
              keyValue="availableAmountInPeriod"
              value={couponInput.availableAmountInPeriod}
              callbackHandler={setCouponInputHandler}
            />
            {allErrors?.availableAmountInPeriod && !+couponInput.availableAmountInPeriod
              && <span className="errorCouponText">{allErrors.availableAmountInPeriod}</span>}
          </Form.Item>

          <Form.Item
            className={s.formItem}
            label="Offer deadline (coupon visible)"
          >
            <DatePickerComponent
              keyValue="publishedDueDate"
              activeDate={couponInput.publishedDueDate}
              callback={setCouponInputHandler}
              error={allErrors?.publishedDueDate}
            />
            {allErrors?.publishedDueDate && !couponInput.publishedDueDate
              && <span className="errorCouponText">This field is required</span>}
          </Form.Item>

          <Form.Item
            label="Voucher valid"
            className={s.formItem}
          >
            <DatePickerComponent
              keyValue="validDueDate"
              activeDate={couponInput.validDueDate}
              callback={setCouponInputHandler}
              error={allErrors?.validDueDate}
            />
            {allErrors?.validDueDate && !couponInput.validDueDate
              && <span className="errorCouponText">This field is required</span>}
          </Form.Item>

          <Divider />

          <Form.Item
            label="Can use"
            className="labelStatic"
          >
            <CanUse
              couponGoldenHours={couponInput.couponGoldenHours}
              setCouponGoldenHoursHandler={setCouponInput}
              allErrors={allErrors && allErrors.couponGoldenHours}
            />
          </Form.Item>

          <Divider />

          <CountingBody
            couponInput={couponInput}
            callback={setCouponInputHandler}
            vatValue={vatValue}
            allErrors={allErrors}
          />

          <Form.Item
            name="promo-code"
            label="Can use promo-code"
          >
            <Checkbox
              checked={couponInput.allowToUsePromoCode}
              onChange={(e) => setCouponInputHandler('allowToUsePromoCode', e.target.checked)}
            />
          </Form.Item>

          <Form.Item
            label="Preview image"
            className="labelStatic"
          >
            <PreviewImageSimple
              prevImage={prevImage}
              setPrevImage={setPrevImage}
              addClass={allErrors?.prevImageKey && !prevImage.key ? 'error' : ''}
            />
            {
              allErrors?.prevImageKey && !prevImage.key
              && <span className="errorCouponTextPrevImage">{allErrors.prevImageKey}</span>
            }
          </Form.Item>

          <Form.Item
            className="labelStatic"
            label="Gallery (optional)"
          >
            <UploadGalleryImages
              images={galleryImages}
              setImages={setGalleryImages}
              deleteImageFromGallery={deleteImageFromGallery}
              changeOrderImagesInGallery={changeOrderImagesInGallery}
            />
          </Form.Item>

          <Form.Item label="Keywords of images (for SEO)">
            <Input
              value={couponInput.keywordsImages}
              maxLength={254}
              onChange={(e) => setCouponInputHandler('keywordsImages', e.target.value)}
              style={{
                border: '1px solid #EDEDED',
                height: '40px',
                borderRadius: '4px',
              }}
            />
          </Form.Item>

          <Form.Item label="Link on Youtube  (optional)">
            <Input
              value={couponInput.videoReviewUrl}
              maxLength={254}
              onChange={(e) => setCouponInputHandler('videoReviewUrl', e.target.value)}
              style={{
                border: allErrors?.videoReviewUrl && !couponInput?.videoReviewUrl.match(regLink)
                  ? '1px solid #D00000'
                  : '1px solid #EDEDED',
                height: '40px',
                borderRadius: '4px',
              }}
            />
            {
              allErrors?.videoReviewUrl && !couponInput?.videoReviewUrl.match(regLink)
              && <span className="errorCouponText">Invalid link!</span>
            }
          </Form.Item>
        </div>

        <Divider />
        <TextAreas
          couponInput={couponInput}
          allErrors={allErrors}
          setCouponInputHandler={setCouponInputHandler}
        />

        <Divider />
        <VisibleOnVoucher
          couponInput={couponInput}
          setCouponInputHandler={setCouponInputHandler}
        />
      </Form>
    </div>
  );
};

CouponInfo.propTypes = {
  setCouponInputHandler: PropTypes.func.isRequired,
  couponInput: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setCouponInput: PropTypes.func.isRequired,
  prevImage: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setPrevImage: PropTypes.func.isRequired,
  setGalleryImages: PropTypes.func.isRequired,
  galleryImages: PropTypes.oneOfType([PropTypes.array]).isRequired,
  merchants: PropTypes.oneOfType([PropTypes.array]),
  vatValue: PropTypes.number,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
  deleteImageFromGallery: PropTypes.func.isRequired,
  changeOrderImagesInGallery: PropTypes.func.isRequired,
};
CouponInfo.defaultProps = {
  merchants: undefined,
  vatValue: undefined,
  allErrors: undefined,
};

export default memo(CouponInfo);
