import React, { memo, useCallback, useState } from 'react';
import HeaderComponent from './Header';
import TableOrders from './TableOrders';
import OrdersModal from './modal/OrdersModal';

const OrdersTable = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModalHandler = useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const closeModalHandler = useCallback(() => {
    setIsOpenModal(false);
  }, []);
  return (
    <>
      <HeaderComponent
        callback={openModalHandler}
      />
      <section
        style={{
          height: '100%',
        }}
      >
        <TableOrders />
      </section>
      {
        isOpenModal && (
          <OrdersModal
            isOpenModal={isOpenModal}
            closeModalHandler={closeModalHandler}
          />
        )
      }
    </>
  );
};

export default memo(OrdersTable);
