import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { GET_ALL_MERCHANTS_ADMIN } from '../../../../../api/create-merchant/query';
import { limit } from '../../../../../constants/tableConstants';
import useLastPageChange from '../../../../../hooks/useLastPageChange/useLastPageChange';

const useGetAllMerchantsByAdmin = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.get('page') || '1';
  const searchQuery = searchParams.get('search') || '';
  const status = searchParams.get('status') || null;

  const { loading, error, data } = useQuery(GET_ALL_MERCHANTS_ADMIN, {
    variables: {
      searchQuery,
      status,
      offset: (+page - 1) * limit,
      limit,
      order: 'ASC',
    },
  });

  useLastPageChange(data);

  if (data) {
    const { merchants, count } = data.getAllMerchantsAdmin;

    const merchObjCoord = merchants.map((merch) => {
      const { filialBranches, ...restMerch } = merch;
      const filialBranchesWithLocationObject = filialBranches.map((filial) => {
        const { location, ...restFilial } = filial;
        if (location) {
          return {
            ...restFilial,
            location: { lat: location[0], lng: location[1] },
          };
        }

        return filial;
      });

      return {
        ...restMerch,
        filialBranches: filialBranchesWithLocationObject,
      };
    });

    return {
      loading,
      error,
      merchants: merchObjCoord,
      count,
    };
  }

  return {
    loading,
    error,
  };
};

export default useGetAllMerchantsByAdmin;
