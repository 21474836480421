export const USER_LOCALSTORAGE_KEY = 'access-token-banana';
export const USER_LOCALSTORAGE_REFRESH_KEY = 'refresh-token-banana';
export const ROLE_BANANA = 'role-banana';

export const getRoleUser = () => {
  const storedUser = localStorage.getItem(ROLE_BANANA);
  return storedUser || null;
};
export const setRoleUser = (role) => {
  localStorage.setItem(ROLE_BANANA, role);
};
export const clearRoleUser = () => {
  localStorage.removeItem(ROLE_BANANA);
};

export const getStoredUser = () => {
  const storedUser = localStorage.getItem(USER_LOCALSTORAGE_KEY);
  return storedUser || null;
};

export const getOldRefreshTokenUser = () => {
  const storedUser = localStorage.getItem(USER_LOCALSTORAGE_REFRESH_KEY);
  return storedUser || null;
};

export const setAccessTokenUser = (token) => {
  localStorage.setItem(USER_LOCALSTORAGE_KEY, token);
};
export const setRefreshTokenUser = (token) => {
  localStorage.setItem(USER_LOCALSTORAGE_REFRESH_KEY, token);
};
export const clearAccessTokenUser = () => {
  localStorage.removeItem(USER_LOCALSTORAGE_KEY);
  localStorage.removeItem(USER_LOCALSTORAGE_REFRESH_KEY);
};

export const isUserStored = () => {
  const storedUser = localStorage.getItem(USER_LOCALSTORAGE_KEY);
  return !!storedUser;
};
