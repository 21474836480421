import { useLazyQuery } from '@apollo/client';
import { useCallback } from 'react';
import { GET_EMPLOYEE_BY_ID } from '../../../../api/employees/query';

const useGetEmployById = () => {
  const [getEmployeeById, { loading: loadingGetEmploy, error, data }] = useLazyQuery(GET_EMPLOYEE_BY_ID);

  const getEmployeeByIdHandler = useCallback((id) => {
    getEmployeeById({
      variables: {
        id,
      },
    });
  }, [getEmployeeById]);

  if (data) {
    const { __typename, ...restData } = data.getEmployeeById;
    return {
      data: restData,
      getEmployeeByIdHandler,
      loadingGetEmploy,
      error,
    };
  }

  return {
    getEmployeeByIdHandler,
    loadingGetEmploy,
    error,
  };
};

export default useGetEmployById;
