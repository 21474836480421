import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { ACTIVATE_VOUCHER } from '../../../api/vouchers/query';
import { SECURITY_FAILED, SECURITY_SUCCESS } from '../../../constants/Routes';

const useActivateVoucherBySecurity = () => {
  const [callback, { loading: activateLoading }] = useMutation(ACTIVATE_VOUCHER);

  const navigate = useNavigate();

  const activateVoucher = useCallback((voucherId) => {
    callback({
      variables: {
        voucherId,
      },
    })
      .then(() => navigate(SECURITY_SUCCESS))
      .catch(() => navigate(SECURITY_FAILED));
  }, []);

  return { activateLoading, activateVoucher };
};

export default useActivateVoucherBySecurity;
