import React, { memo, useContext, useState } from 'react';
import s from './CouponsTable.module.scss';
import './table.scss';
import CartContext from '../cartContext/cartContext';
import { isUserStored } from '../../../helpers/user-storage';
import Modal from '../../Modal';
import mobileCondition from '../../../constants/mobileCondition';
import MobileCouponsTable from '../MobileCouponsTable';
import DesktopCouponsTable from '../DesktopCouponsTable';

const CouponsTable = () => {
  const {
    totalCartData,
    removeCouponFromCart,
    updateLocalCartOption,
    removeCouponFromLocalCart,
    updateLocalCartCoupon,
    updateShoppingCartOption,
    updateShoppingCartCoupon,
  } = useContext(CartContext);

  const [modalState, setModalState] = useState({ isActive: false, id: null });

  const { id, isActive } = modalState;

  const removeIconOnClick = (id) => {
    setModalState({ id, isActive: true });
  };

  const cancelButtonOnClick = () => setModalState({ id: null, isActive: false });

  const removeCouponHandler = () => removeCouponFromCart(id);

  const removeHandler = () => {
    if (isUserStored()) {
      removeCouponHandler();
    } else {
      removeCouponFromLocalCart(id);
    }
    cancelButtonOnClick();
  };

  return (
    <div className={s.tableWrapper}>
      <Modal
        isActive={isActive}
        hideModalHandler={cancelButtonOnClick}
      >
        <div className={s.removeModal}>
          <span>Do you really want to remove coupon from cart?</span>
          <div>
            <button
              className={s.removeButton}
              onClick={removeHandler}
            >
              Remove
            </button>
            <button
              className={s.cancelButton}
              onClick={cancelButtonOnClick}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      {!mobileCondition ? (
        <DesktopCouponsTable
          totalCartData={totalCartData}
          couponHandler={isUserStored() ? updateShoppingCartCoupon : updateLocalCartCoupon}
          optionHandler={isUserStored() ? updateShoppingCartOption : updateLocalCartOption}
          removeIconOnClick={removeIconOnClick}
        />
      ) : (
        <MobileCouponsTable
          removeIconOnCLick={removeIconOnClick}
          totalCartData={totalCartData}
          optionOrderHandler={isUserStored() ? updateShoppingCartOption : updateLocalCartOption}
          couponOrderHandler={isUserStored() ? updateShoppingCartCoupon : updateLocalCartCoupon}
        />
      )}
    </div>
  );
};

export default memo(CouponsTable);
