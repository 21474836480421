import { useQuery } from '@apollo/client';
import { GET_ALL_MERCHANTS_ADMIN } from '../../../../../../api/create-merchant/query';
import useAuthAdmin from '../../../../../../context/context-admin/useAuthAdmin';
import { MERCHANT_ADMIN } from '../../../../../../constants/admin-roles';

const useGetAllMerchants = () => {
  const { userInfo: { merchantId, role } } = useAuthAdmin();
  const { data, loading: loadingGetMerchants, error } = useQuery(GET_ALL_MERCHANTS_ADMIN, {
    variables: {
      offset: 0,
      limit: 1000,
      order: 'ASC',
    },
  });

  if (data) {
    const { getAllMerchantsAdmin: { merchants: allMerchants } } = data;

    if (role === MERCHANT_ADMIN) {
      const merchants = allMerchants.filter((merch) => merch.id === merchantId);

      return {
        merchants,
        loadingGetMerchants,
        error,
      };
    }

    return {
      merchants: allMerchants,
      loadingGetMerchants,
      error,
    };
  }

  return {
    loadingGetMerchants,
    error,
  };
};
export default useGetAllMerchants;
