import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import HeaderComponent from './Header';
import DeleteModalEmploy from '../DeleteModalEmploy';
import EmployModalComponent from '../EmployModalComponent';
import useFindAllEmployees from '../hooks/gql/useFindAllEmployees';
import Loader from '../../Loader';
import TableEmployees from './TableEmployees';

const EmployeesTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    employees, loading, count,
  } = useFindAllEmployees();

  const id = searchParams.get('id') || '';
  const page = searchParams.get('page') || '1';
  const actionOperation = searchParams.get('action') || '';

  const [isOpenModalEmploy, setOpenModalEmploy] = useState(!!id);

  const openModalEmployHandler = useCallback(() => {
    setSearchParams({
      ...Object.entries(searchParams),
      page,
      id: 'create-employ',
    });
    setOpenModalEmploy(true);
  }, [page, searchParams, setSearchParams]);

  const deleteModalEmployHandler = useCallback((id) => {
    setSearchParams({
      ...Object.entries(searchParams),
      page,
      id,
      action: 'delete',
    });
    setOpenModalEmploy(true);
  }, [page, searchParams, setSearchParams]);

  const editModalEmployHandler = useCallback((id) => {
    setSearchParams({
      ...Object.entries(searchParams),
      page,
      id,
    });
    setOpenModalEmploy(true);
  }, [page, searchParams, setSearchParams]);

  const closeModalWindowEmploy = useCallback(() => {
    setSearchParams({
      page,
    });
    setOpenModalEmploy(false);
  }, [page, setSearchParams]);

  const closeModalWindowEmployWhenDelete = useCallback(() => {
    setOpenModalEmploy(false);
  }, []);

  return (
    <>
      <HeaderComponent
        callback={openModalEmployHandler}
      />
      <section
        className="employ-table"
        style={{
          height: '100%',
        }}
      >
        {
          loading
            ? <Loader />
            : (
              <TableEmployees
                editModalEmployHandler={editModalEmployHandler}
                count={count}
                deleteModalEmployHandler={deleteModalEmployHandler}
                employees={employees}
              />
            )
        }
      </section>
      {
        isOpenModalEmploy && actionOperation !== 'delete'
          ? (
            <EmployModalComponent
              isOpenModalEmploy={isOpenModalEmploy}
              closeModalWindowEmploy={closeModalWindowEmploy}
            />
          )
          : (
            <DeleteModalEmploy
              closeModal={closeModalWindowEmploy}
              isOpenModal={isOpenModalEmploy}
              employees={employees}
              closeModalWindowEmployWhenDelete={closeModalWindowEmployWhenDelete}
            />
          )
      }
    </>
  );
};

export default EmployeesTable;
