import React, {
  memo, useCallback, useContext, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import useGetCouponsColumn from './column/useGetCouponsColumn';
import TableComponent from './TableComponent/TableComponent';
import ModalPaymentContainer from '../modals/modalPayment/ModalPaymentContainer';
import ModalRefund from '../modals/modalRefund';
import './coupons-table.scss';
import s from './table.module.scss';
import useProfileCoupons from '../../hooks/gql/useProfileCoupons';
import Loader from '../../../Loader';
import useCouponRefund from '../../hooks/gql/useCouponRefund';
import UserContext from '../../../Layout/context/userContext';

const CouponsTable = () => {
  const { fetchUserLoading } = useContext(UserContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const modalStatus = searchParams.get('modalStatus') || '';
  const [isApplyVatCouponOfVoucher, setIsApplyVatCouponOfVoucher] = useState(true);

  const {
    vouchersData, count, getCouponsLoading, totalOrderData, fetchOrderData,
  } = useProfileCoupons();

  const { refundVoucher, refundLoading, setSelectedId } = useCouponRefund();

  const setIsOpenBanner = () => modalStatus && (modalStatus === 'payment' || modalStatus === 'refund');

  const [isOpenModal, setIsOpenModal] = useState(setIsOpenBanner());

  const openModalHandler = useCallback((modalStatus) => {
    setIsOpenModal(true);
    setSearchParams({
      ...Object.entries(searchParams),
      modalStatus,
    });
  }, [searchParams, setSearchParams]);

  const closeModalHandler = useCallback(() => {
    setIsOpenModal(false);
    setSearchParams({});
  }, [setSearchParams]);

  const columns = useGetCouponsColumn(openModalHandler, fetchOrderData, setSelectedId, setIsApplyVatCouponOfVoucher);

  const chooseModalWindow = () => {
    switch (modalStatus) {
    case 'refund':
      return (
        <ModalRefund
          isOpenModal={isOpenModal}
          closeModalHandler={closeModalHandler}
          refundVoucher={refundVoucher}
        />
      );
    default: return (
      <ModalPaymentContainer
        orderData={totalOrderData}
        isOpenModal={isOpenModal}
        closeModalHandler={closeModalHandler}
        isApplyVatCouponOfVoucher={isApplyVatCouponOfVoucher}
      />
    );
    }
  };

  if (fetchUserLoading || (vouchersData && getCouponsLoading)) return <Loader />;

  return (
    <div className={`${s.table} tableProfileCoupons`}>
      {(getCouponsLoading || refundLoading) && <Loader />}
      {vouchersData && (
        <TableComponent
          dataSource={vouchersData}
          columns={columns}
          total={count}
        />
      )}
      {isOpenModal && chooseModalWindow()}
    </div>
  );
};

export default memo(CouponsTable);
