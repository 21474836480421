import React, { memo, useRef, useState } from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import useGetSubdivisionsColumn from './columns/subdivisionsColumn/subdivisionsColumn';
import ModalSubdivisionsContainer from './modals/subdivisions/ModalSubdivisionsContainer';
import TableInfo from './TableInfo';
import {
  TAB_COUPONS_STATUSES_MERCHANT,
  TAB_EMPLOYEES_STATUSES_MERCHANT, TAB_SUBDIVISIONS_STATUSES_MERCHANT,
} from '../../../../../constants/merchantConstant';
import s from './merchant-page.module.scss';
import './merchant-page-ant.scss';

const MerchantPage = (props) => {
  const {
    subDivisionsInfo,
    addSubDivisionsInfoHandler,
    deleteSubDivisionsInfoHandler,
    editSubDivisionsInfoHandler,
    error,
  } = props;

  const [searchParams, setSearchParams] = useSearchParams();
  const tabStatus = searchParams.get('tabStatus') || 'Subdivisions';

  const [isOpenModal, setIsOpenModal] = useState(false);
  const editItemSubdivisions = useRef({});

  const changeTabHandler = (key) => {
    let tabStatus;
    switch (key) {
    case '2': {
      tabStatus = TAB_EMPLOYEES_STATUSES_MERCHANT;
      break;
    }
    case '3': {
      tabStatus = TAB_COUPONS_STATUSES_MERCHANT;
      break;
    }
    default: tabStatus = TAB_SUBDIVISIONS_STATUSES_MERCHANT;
    }
    setSearchParams({
      ...Object.entries(searchParams),
      tabStatus,
    });
  };

  const editInfoModal = (id) => {
    if (tabStatus === TAB_SUBDIVISIONS_STATUSES_MERCHANT) {
      const findItem = subDivisionsInfo.find((item) => item.id === id);
      editItemSubdivisions.current = { ...findItem, countNumberInPhone: findItem.phone.length };
      setIsOpenModal(true);
    }
  };

  const chooseModalWindow = () => {
    switch (tabStatus) {
    default: return (
      <ModalSubdivisionsContainer
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        state={editItemSubdivisions.current}
        addMethod={editSubDivisionsInfoHandler}
      />
    );
    }
  };

  const tabsData = [
    {
      key: '1',
      tab: 'Subdivisions',
      columns: useGetSubdivisionsColumn(deleteSubDivisionsInfoHandler, editInfoModal),
      data: subDivisionsInfo,
      addMethod: addSubDivisionsInfoHandler,
    },
  ];

  const activeTabStatus = () => {
    switch (tabStatus) {
    case TAB_EMPLOYEES_STATUSES_MERCHANT: return '2';
    case TAB_COUPONS_STATUSES_MERCHANT: return '3';
    default: return '1';
    }
  };

  return (
    <section className={`${s.container} container`}>

      <Tabs
        activeKey={activeTabStatus()}
        onChange={changeTabHandler}
      >
        {
          tabsData.map((tab) => (
            <Tabs.TabPane
              key={tab.key}
              tab={tab.tab}
              disabled={tab.key === '2' || tab.key === '3'}
            >
              <TableInfo
                {...tab}
                error={error}
                lengthSubdivision={subDivisionsInfo.length}
              />
            </Tabs.TabPane>
          ))
        }
      </Tabs>
      {isOpenModal && chooseModalWindow()}
    </section>
  );
};

MerchantPage.propTypes = {
  subDivisionsInfo: PropTypes.oneOfType([PropTypes.array]).isRequired,
  addSubDivisionsInfoHandler: PropTypes.func.isRequired,
  deleteSubDivisionsInfoHandler: PropTypes.func.isRequired,
  editSubDivisionsInfoHandler: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default memo(MerchantPage);
