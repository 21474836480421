import { useRef, useState } from 'react';

const useSignModals = () => {
  const processingCallbackRef = useRef(null);

  const processingCallback = processingCallbackRef.current;

  const setProcessingCallback = (callback) => {
    processingCallbackRef.current = callback;
  };

  const clearProcessingCallback = () => {
    processingCallbackRef.current = null;
  };

  const [isSignInActive, setIsSignInActive] = useState(false);

  const [isSignUpActive, setIsSignUpActive] = useState(false);

  const goToSignUpModalOnClick = () => {
    setIsSignInActive(false);
    setIsSignUpActive(true);
  };

  const goToSignInModalOnClick = () => {
    setIsSignUpActive(false);
    setIsSignInActive(true);
  };

  const setSignUpActive = () => setIsSignUpActive(true);

  return {
    isSignInActive,
    isSignUpActive,
    goToSignUpModalOnClick,
    goToSignInModalOnClick,
    setSignUpActive,
    setIsSignInActive,
    setIsSignUpActive,
    processingCallback,
    setProcessingCallback,
    clearProcessingCallback,
  };
};

export default useSignModals;
