import React, { memo, useRef } from 'react';
import PropTypes from 'prop-types';
import s from './header-component.module.scss';
import ButtonsActions from './ButtonsActions/ButtonsActions';

const HeaderComponent = ({ sendDataHandler, title, isValidMerchantInfo }) => {
  const sendData = () => {
    sendDataHandler();
  };

  const currentTitle = useRef(title);

  return (
    <header
      style={{ marginBottom: '20px' }}
      className={s.controls}
    >
      <h1 className={s.title}>{currentTitle.current}</h1>
      <ButtonsActions
        disable={isValidMerchantInfo}
        sendData={sendData}
      />
    </header>
  );
};

HeaderComponent.propTypes = {
  sendDataHandler: PropTypes.func.isRequired,
  title: PropTypes.string,
  isValidMerchantInfo: PropTypes.bool.isRequired,
};

HeaderComponent.defaultProps = {
  title: 'Add merchant',
};

export default memo(HeaderComponent);
