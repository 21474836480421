import {
  Banners,
  Buyers, Categories, CommonSettings, Coupons, Discount, Logs, Merchants, Orders, Reports, Vouchers,
} from './icons';
import {
  BANNERS,
  BUYERS, CATEGORIES, COMMON_SETTINGS, COUPONS, EMPLOYEES, LOGS, MERCHANTS, ORDERS, PROMO_CODES, REPORTS, VOUCHERS,
} from './Routes';

export const merchantMenuItems = [
  {
    key: '1', icon: Coupons, text: 'Coupons', url: COUPONS,
  },
  {
    key: '2', icon: Vouchers, text: 'Vouchers', url: VOUCHERS,
  },
  {
    key: '3', icon: Buyers, text: 'Employees', url: EMPLOYEES,
  },
];

export const adminMenuItems = [
  {
    key: '1',
    icon: Merchants,
    text: 'Merchants',
    url: MERCHANTS,
  },
  {
    key: '2',
    icon: Coupons,
    text: 'Coupons',
    url: COUPONS,
  },
  {
    key: '8',
    icon: Banners,
    text: 'Banners',
    url: BANNERS,
  },
  {
    key: '9',
    icon: Discount,
    text: 'Promo codes',
    url: PROMO_CODES,
  },
];
export const superAdminMenuItems = [
  {
    key: '1',
    icon: Merchants,
    text: 'Merchants',
    url: MERCHANTS,
  },
  {
    key: '2',
    icon: Coupons,
    text: 'Coupons',
    url: COUPONS,
  },
  {
    key: '3',
    icon: Buyers,
    text: 'Customers',
    url: BUYERS,
  },
  {
    key: '4',
    icon: Orders,
    text: 'Orders',
    url: ORDERS,
  },
  {
    key: '5',
    icon: Vouchers,
    text: 'Vouchers',
    url: VOUCHERS,
  },
  {
    key: '6',
    icon: Reports,
    text: 'Reports',
    url: REPORTS,
  },
  {
    key: '7',
    icon: Logs,
    text: 'Logs',
    url: LOGS,
  },
  {
    key: '8',
    icon: Banners,
    text: 'Banners',
    url: BANNERS,
  },
  {
    key: '9',
    icon: Discount,
    text: 'Promo codes',
    url: PROMO_CODES,
  },
];

export const submenuItems = [
  {
    key: '10',
    icon: CommonSettings,
    text: 'Common Settings',
    url: COMMON_SETTINGS,
  },
  {
    key: '11',
    icon: Categories,
    text: 'Categories',
    url: CATEGORIES,
  },
  {
    key: '12',
    icon: Buyers,
    text: 'Employees',
    url: EMPLOYEES,
  },
];

export const submenuAdminItems = [
  {
    key: '11',
    icon: Categories,
    text: 'Categories',
    url: CATEGORIES,
  },
];
