import React, { memo, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Autoplay, Keyboard, Mousewheel, Navigation, Pagination,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import './swiper.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import mobileCondition from '../../../constants/mobileCondition';
import s from './ShowBanners.module.scss';
import { ArrowLeft, ArrowRight } from '../../CouponPage/constants/icons';

SwiperCore.use([
  Keyboard,
  Navigation,
  Pagination,
  Mousewheel,
  Autoplay,
]);

const ShowBanners = ({ bannersData }) => {
  const spaceBetween = window.outerWidth <= 1460 && window.outerWidth > 1191 ? -60 : -100;

  const [isHidden, setIsHidden] = useState(false);

  const hideButtons = () => setIsHidden(true);

  const showButtons = () => setIsHidden(false);

  const slideClassName = `swiper-slide ${mobileCondition && 'mobile'}`;

  const prevRef = useRef(null);

  const nextRef = useRef(null);

  const containerClassName = `swiper-container ${mobileCondition && 'mobile'}`;

  const mainClassName = mobileCondition ? 'main' : '';

  const preventSelectOnClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const allBannersId = Object.keys(bannersData);

  const slides = allBannersId.map((id) => {
    const { link, bannerPicture } = bannersData[id];
    const slide = (
      <div className="slide-img">
        <img
          src={bannerPicture}
          alt="img"
        />
      </div>

    );

    const isExternal = link.indexOf('/coupon') < 0;

    const linkTarget = isExternal ? '_blank' : '_self';

    const totalLink = isExternal
      ? (
        <a
          href={link}
          target={linkTarget}
        >
          {slide}
        </a>
      ) : (
        <Link
          to={{
            pathname: link,
          }}
          target={linkTarget}
        >
          {slide}
        </Link>
      );
    return (
      <SwiperSlide
        className={slideClassName}
        key={id}
      >
        {link
          ? totalLink
          : slide}
      </SwiperSlide>
    );
  });

  return (
    <div
      style={{ marginTop: !mobileCondition ? '20px' : 'unset', width: '100%' }}
      className={mainClassName}
    >
      {allBannersId.length > 0 && (
        <Swiper
          className={containerClassName}
          slidesPerView="auto"
          spaceBetween={mobileCondition ? 30 : spaceBetween}
          centeredSlides
          simulateTouch
          onSlideChangeTransitionStart={hideButtons}
          onSlideChangeTransitionEnd={showButtons}
          grabCursor
          slideToClickedSlide
          speed={1000}
          pagination={mobileCondition && {
            horizontalClass: 'bullets',
            clickable: true,
          }}
          autoplay={{
            delay: 5000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          navigation={!mobileCondition && {
            nextEl: nextRef.current,
            prevEl: prevRef.current,
          }}
          keyboard={{
            enabled: true,
            onlyInViewport: true,
            pageUpDown: true,
          }}
          loop
          modules={[Keyboard, Navigation, Pagination, Autoplay]}
        >
          {slides}
          {!mobileCondition && (
            <>
              <div
                onMouseDown={preventSelectOnClick}
                ref={prevRef}
                className={`${s.button} ${s.prev} ${isHidden && s.hidden}`}
              >
                <ArrowLeft />
              </div>
              <div
                onMouseDown={preventSelectOnClick}
                ref={nextRef}
                className={`${s.button} ${s.next} ${isHidden && s.hidden}`}
              >
                <ArrowRight />
              </div>
            </>
          )}
        </Swiper>
      )}
    </div>
  );
};

ShowBanners.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  bannersData: PropTypes.object.isRequired,
};

export default memo(ShowBanners);
