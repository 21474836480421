import { regPassword } from '../../helpers/regularExpression/regularExpression';
import { PASSWORD_ERROR } from '../errors';

const RESET_PASSWORD_SCHEMA = {
  newPassword: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [
      {
        errorMessage: 'New password and old password are equal!',
        ruleProp: 'currentPassword',
        ruleValidation: (item, ruleProp) => item !== ruleProp,
      },
      {
        errorMessage: PASSWORD_ERROR,
        ruleValidation: (item) => item.match(regPassword),
      },
    ],
  },
  confirmPassword: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [{
      errorMessage: 'Passwords are not equal!',
      ruleProp: 'newPassword',
      ruleValidation: (item, ruleProp) => (item === ruleProp),
    }],
  },
};

export default RESET_PASSWORD_SCHEMA;
