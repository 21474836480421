import React from 'react';
import Page from '../../components/Page';
import BuyersTable from '../../components/Buyers/BuyersTable';

const Buyers = () => (
  <Page>
    <BuyersTable />
  </Page>
);

export default Buyers;
