import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ButtonComponent from '../../Profile/Common_components/Button/ButtonComponent';
import './btns-block.scss';

export const BtnsBlock = ({
  sendDataHandler, cancelHandler, cancelText, okText, disableOkBtn, wrapperClass, addClassForBtnCancel,
}) => (
  <div className={wrapperClass}>
    <ButtonComponent
      title={cancelText}
      callback={cancelHandler}
      addClass={addClassForBtnCancel || 'cancel'}
    />
    <ButtonComponent
      disabled={disableOkBtn}
      title={okText}
      callback={sendDataHandler}
      addClass="active"
    />
  </div>
);

BtnsBlock.propTypes = {
  sendDataHandler: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  cancelText: PropTypes.string.isRequired,
  okText: PropTypes.string.isRequired,
  disableOkBtn: PropTypes.bool,
  wrapperClass: PropTypes.string,
  addClassForBtnCancel: PropTypes.string,
};

BtnsBlock.defaultProps = {
  disableOkBtn: false,
  wrapperClass: 'account-btns',
  addClassForBtnCancel: '',
};

export default memo(BtnsBlock);
