import React, { memo, useCallback, useState } from 'react';
import Loader from '../../../Loader/Loader';
import useGetAllMerchantsByAdmin from '../hooks/gql/useGetAllMerchantsByAdmin';
import TableComponent from './TableComponent';
import useGetMerchantColumn from './columns/useGetMerchantColumn';
import ModalSubdivisionsContainer
  from '../../MerchantsMaking/BodyMerchant/MerchantPage/modals/subdivisions/ModalSubdivisionsContainer';
import useUpdateFilialBranches from '../hooks/gql/useUpdateFilialBranches';
import './merchant-table.scss';

const TableMerchant = () => {
  const { merchants = [], loading, count = 0 } = useGetAllMerchantsByAdmin();
  const { updateFilialBranch } = useUpdateFilialBranches();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [data, setData] = useState(null);

  const editFilialBranchHandler = useCallback((data) => {
    const {
      contact, email, key, parent, status, ...restData
    } = data;
    setData({
      ...restData,
      countNumberInPhone: restData.phone.length,
    });
    setIsOpenModal(true);
  }, []);
  const columns = useGetMerchantColumn(merchants, editFilialBranchHandler);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <TableComponent
        columns={columns}
        dataSource={merchants}
        count={count}
      />
      {isOpenModal && (
        <ModalSubdivisionsContainer
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          state={data}
          addMethod={updateFilialBranch}
        />
      )}
    </>
  );
};
export default memo(TableMerchant);
