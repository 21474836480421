import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GET_MERCHANT_BY_ID_ADMIN } from '../../../../../api/create-merchant/query';

const useGetMerchantById = () => {
  const { id } = useParams();
  const idValue = id.slice(1);

  const { loading, error, data } = useQuery(GET_MERCHANT_BY_ID_ADMIN, {
    variables: {
      id: idValue,
    },
  });

  if (data) {
    const { getMerchantByIdAdmin } = data;
    const { filialBranches, ...restMerchantInfo } = getMerchantByIdAdmin;
    const filialBranchesWithLocationObject = filialBranches.map((filial) => {
      const { location, ...restFilial } = filial;
      if (location) {
        return {
          ...restFilial,
          location: { lat: location[0], lng: location[1] },
        };
      }

      return filial;
    });
    return {
      merchantData: { ...restMerchantInfo, filialBranches: filialBranchesWithLocationObject },
      loading,
      error,
    };
  }

  return {
    loading,
    error,
  };
};

export default useGetMerchantById;
