import React, { memo, useState } from 'react';
import { Upload, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import './PreviewImage.scss';

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 1.95;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const PreviewImageSimple = ({
  prevImage, setPrevImage, addClass,
}) => {
  const [state, setState] = useState({
    loading: false,
    imageUrl: prevImage?.key || '',
  });

  const deleteImage = () => {
    setPrevImage(null);
    setState({
      loading: false,
      imageUrl: '',
    });
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const customRequestHandler = async (e) => {
    const { file } = e;
    getBase64(file, (imageUrl) => setState((prev) => ({
      ...prev,
      imageUrl,
      loading: false,
    })));
    setPrevImage(file);
  };

  return (
    <div className={state.imageUrl ? 'uploadImageContainer active' : 'uploadImageContainer'}>
      <Upload
        name="avatar"
        listType="picture-card"
        className={`uploader ${addClass}`}
        showUploadList={false}
        customRequest={customRequestHandler}
        accept="image/jpeg, image/png"
        onPreview={onPreview}
        beforeUpload={beforeUpload}
      >
        {state.imageUrl ? (
          <div
            className="prevImage"
          >
            <img
              src={state.imageUrl}
              alt="avatar"
            />
          </div>
        ) : (
          <div className="upload_body">
            <h5 className="upload_title">
              Drag & drop or
              <span>Upload file</span>
            </h5>
            <h6 className="upload_subtitle">Supports: JPEG, JPG, PNG </h6>
          </div>
        )}
      </Upload>
      {state.imageUrl && (
        <div
          className="deleteImage"
          onClick={deleteImage}
        >
          <CloseOutlined className="iconDelete" />
        </div>
      )}
    </div>
  );
};
PreviewImageSimple.propTypes = {
  prevImage: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setPrevImage: PropTypes.func.isRequired,
  addClass: PropTypes.string,
};
PreviewImageSimple.defaultProps = {
  addClass: '',
};

export default memo(PreviewImageSimple);
