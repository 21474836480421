export const formatDate = (date) => {
  const temp = new Date(date);
  const splitDate = temp.toString().split(' ');
  return [splitDate[2], splitDate[1].concat(','), splitDate[3]].join(' ');
};

export const getDate = (date) => {
  const dateItem = new Date(date);
  const year = dateItem.toLocaleString('en', { year: 'numeric' });
  const month = dateItem.toLocaleString('en', { month: 'long' });
  const day = dateItem.toLocaleString('en', { day: '2-digit' });
  return `${day} ${month} ${year}`;
};
