import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import s from './ErrorPage.module.scss';
import { MAIN } from '../../constants/Routes';
import errorImage from '../../assets/images/404.svg';
import mobileCondition from '../../constants/mobileCondition';

const ErrorPage = () => {
  const imageClassName = `${s.image} ${mobileCondition && s.mobile}`;

  const titleClassName = `${s.title} ${mobileCondition && s.mobile}`;

  const linkClassName = `${s.link} ${mobileCondition && s.mobile}`;

  return (
    <div className={s.main}>
      <div className={s.container}>
        <div className={s.error}>
          <img
            className={imageClassName}
            src={errorImage}
            alt="404"
          />
          <h1 className={titleClassName}>Ooops! Page not found.</h1>
          <Link
            className={linkClassName}
            to={MAIN}
            replace
          >
            Go Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default memo(ErrorPage);
