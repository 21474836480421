import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CouponPath from '../CouponPath';
import s from '../CouponPage.module.scss';
import PictureGallery from '../PictureGallery';
import VoucherDetails from '../VoucherDetails';
import TextBlock from '../TextBlock';
import CustomerBlock from '../CustomerBlock';
import mobileCondition from '../../../constants/mobileCondition';
import ShareBlock from '../ShareBlock';
import LocationBlock from '../LocationBlock';

const DesktopCouponPage = ({
  title, terms, description, addCouponToLocalCart, locations, titleTargetLinkForMap,
}) => {
  const containerClassName = `${s.container} ${mobileCondition && s.mobile}`;

  return (
    <div className={containerClassName}>
      <CouponPath />
      <div className={s.header}>
        {title}
      </div>
      <div className={s.content}>
        <div className={s.details}>
          <PictureGallery />
          <VoucherDetails />
          {description && (
            <TextBlock
              title="OFFER HIGHLIGHTS"
              text={description}
            />
          )}
          {terms && (
            <TextBlock
              title="THE FINE PRINT"
              text={terms}
            />
          )}
          <LocationBlock
            locations={locations}
            typeDevice="desktop"
            titleTargetLinkForMap={titleTargetLinkForMap}
          />
          <TextBlock title="Share this deal">
            <ShareBlock />
          </TextBlock>
        </div>
        <CustomerBlock addCouponToLocalCart={addCouponToLocalCart} />
      </div>
    </div>
  );
};

DesktopCouponPage.propTypes = {
  title: PropTypes.string.isRequired,
  terms: PropTypes.string,
  description: PropTypes.string,
  addCouponToLocalCart: PropTypes.func.isRequired,
  locations: PropTypes.oneOfType([PropTypes.array]),
  titleTargetLinkForMap: PropTypes.string.isRequired,
};

DesktopCouponPage.defaultProps = {
  terms: null,
  description: null,
  locations: null,
};

export default memo(DesktopCouponPage);
