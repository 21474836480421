import React, { memo, useCallback } from 'react';
import './success-btn.scss';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { MAIN } from '../../../../../constants/Routes';
import ButtonComponent from '../../../../Common_components/ButtonComponent';

const FailedBtns = ({ callback }) => {
  const navigate = useNavigate();

  const cancelHandler = useCallback(() => {
    navigate(MAIN, { replace: true });
  }, [navigate]);

  return (
    <div className="success-btns">
      <ButtonComponent
        title="Close"
        iconComponent={null}
        addClass="success-btns__btn cancel"
        callback={cancelHandler}
      />
      <ButtonComponent
        title="Try again"
        iconComponent={null}
        addClass="success-btns__btn"
        callback={callback}
      />
    </div>
  );
};
FailedBtns.propTypes = {
  callback: PropTypes.func.isRequired,
};

export default memo(FailedBtns);
