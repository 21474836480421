import { regEmail } from '../helpers/regularExpression/regularExpression';

export const MERCHANT_SCHEMA = {
  title: {
    required: true,
    errorMessage: 'This field is required!',
  },
  firstName: {
    required: true,
    errorMessage: 'This field is required!',
  },
  phone: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [
      {
        errorMessage: 'Incorrect phone number!',
        ruleProp: 'countNumberInPhone',
        ruleValidation: (item, ruleProp) => item.length === ruleProp,
      },
    ],
  },
  email: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [{
      errorMessage: 'Incorrect email address!',
      ruleValidation: (item) => item === null || item.match(regEmail),
    }],
  },
  emailBack: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [{
      errorMessage: 'This email is already exist',
      ruleValidation: (item) => item === null || item.match(regEmail),
    }],
  },
  filialBranches: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [{
      errorMessage: 'Add at least one subdivision!',
      ruleValidation: (item) => !!item.length,
    }],
  },
  emirates: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [{
      errorMessage: 'Add at least one emirate!',
      ruleValidation: (item) => !!item.length,
    }],
  },
};

export const MERCHANT_MODAL_SCHEMA = {
  title: {
    required: true,
    errorMessage: 'This field is required!',
  },
  address: {
    required: true,
    errorMessage: 'This field is required!',
  },
  phone: {
    required: true,
    errorMessage: 'This field is required!',
    rules: [
      {
        errorMessage: 'Incorrect phone number!',
        ruleProp: 'countNumberInPhone',
        ruleValidation: (item, ruleProp) => item.length === ruleProp,
      },
    ],
  },
  couponGoldenHours: {
    type: 'array',
    id: 'intervalId',
    value: 'startAt',
    rules: [
      {
        errorMessage: 'This fields are required!',
        ruleProp: 'endAt',
        ruleValidation: (item, ruleProp) => item && ruleProp,
      },
    ],
    errorMessage: 'required',
  },
};
