import React, { useCallback } from 'react';
import { Typography } from 'antd';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import transformTimeForTable from '../../helper/transformTimeForTable';
import DropdownBtnComponent from '../../../../../../Common_components/DropdownBtnComponent';
import DropdownDays from './Dropdown';
import './subdivcolumn.scss';

const useGetSubdivisionsColumn = (deleteSubDivisionsInfoHandler, editInfoModal) => {
  const editCoupon = useCallback((id) => {
    editInfoModal(id);
  }, [editInfoModal]);

  const deleteCoupon = useCallback((id) => {
    deleteSubDivisionsInfoHandler(id);
  }, [deleteSubDivisionsInfoHandler]);

  return [
    {
      title: 'Location name',
      dataIndex: 'title',
      ellipsis: true,
      width: '22%',
      key: 'title',
      render: (text) => <Typography.Text>{text}</Typography.Text>,
    },
    {
      title: 'Address',
      dataIndex: 'address',
      ellipsis: true,
      key: 'address',
      width: '22%',
      render: (address) => (
        <Typography.Text>
          {address}
        </Typography.Text>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '23%',
      render: (phone) => (
        <Typography.Text className="telContainer">
          <PhoneInput
            value={phone}
            disabled
            inputClass="telInput"
            dropdownStyle={{
              display: 'none',
            }}
            inputStyle={{
              border: 'none',
              cursor: 'text',
            }}
          />
        </Typography.Text>
      ),
    },
    {
      title: 'Working',
      dataIndex: 'couponGoldenHours',
      key: 'working',
      ellipsis: true,
      width: '24%',
      render: (times) => (
        <div className="dropDays">
          <div className="timeDrop">{transformTimeForTable(times[0])}</div>
          {
            times.length > 1 && (
              <div className="arrowDrop">
                <DropdownDays days={times} />
              </div>
            )
          }
        </div>
      ),
    },
    {
      key: 'actions',
      width: '9%',
      render: (text, record) => (
        <DropdownBtnComponent
          text={text}
          record={record}
          actions={[
            {
              key: '0',
              title: 'Edit',
              callback: editCoupon,
            },
            {
              key: '1',
              title: 'Delete',
              callback: deleteCoupon,
            },
          ]}
        />
      ),
    },
  ];
};

useGetSubdivisionsColumn.propTypes = {
  editInfoModal: PropTypes.func.isRequired,
  deleteSubDivisionsInfoHandler: PropTypes.func.isRequired,
};

export default useGetSubdivisionsColumn;
