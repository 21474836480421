export const DiscountUnits = {
  ABSOLUTE: 'ABSOLUTE',
  PERCENTS: 'PERCENTS',
};

export const DATA_FORMAT = 'YYYY-MM-DD';

export const Quantity = {
  SINGLE: 'SINGLE',
  MULTIPLE: 'MULTIPLE',
};

export const Tied = {
  COUPON: 'COUPON',
  OPTION: 'OPTION',
};

export const CouponTitleStatuses = {
  ACTIVE: 'ACTIVE',
  ON_APPROVE: 'ON_APPROVE',
  DECLINED: 'DECLINED',
  ARCHIVED: 'ARCHIVED',
};

export const limitCouponsTitle = 2000;
