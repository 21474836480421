import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { GetEmployeeSelf, LOGIN, LOGOUT } from '../../../api/query';
import {
  clearAccessTokenUser,
  clearRoleUser, getRoleUser,
  isUserStored,
  setAccessTokenUser,
  setRoleUser,
} from '../../../helpers/user-storage';
import { SECURITY, SECURITY_AUTH } from '../../../constants/Routes';
import { ROLE_CUSTOMER } from '../../../constants/employ';
import { SETTING_OF_COOKIES, TITLE_COOKIES } from '../../../constants/general/tag-status';

const useSecurityAuth = () => {
  const [security, setSecurity] = useState(null);

  const navigate = useNavigate();

  const [error, setError] = useState(null);

  const [fetchSecurity, { loading: authLoading }] = useLazyQuery(GetEmployeeSelf, { skip: !isUserStored() });

  useEffect(() => {
    if (isUserStored() && getRoleUser() !== ROLE_CUSTOMER) {
      fetchSecurity()
        .then((res) => {
          const { getEmployeeSelf } = res.data;
          setSecurity(getEmployeeSelf);
        });
    }
  }, []);

  const [login, { loading: loginLoading }] = useMutation(LOGIN, {
    refetchQueries: [GetEmployeeSelf],
  });

  const loginCallback = useCallback((loginUserInput) => {
    if (error) setError(null);
    login({ variables: { loginUserInput } })
      .then((res) => {
        const { login } = res.data;
        const { role } = login;
        if (role === ROLE_CUSTOMER) {
          navigate('/security/failed');
        } else {
          setSecurity(login);
          const { accessToken, refreshToken } = login;
          setAccessTokenUser(accessToken);
          Cookies.set(TITLE_COOKIES, refreshToken, SETTING_OF_COOKIES);
          setRoleUser(role);
          navigate(SECURITY);
        }
      })
      .catch((e) => {
        if (e.message === 'User not found') {
          setError('User not found');
        } else if (e.message === 'Wrong email or password') {
          setError(e.message);
        }
      });
  }, []);

  const [logout, { loading: logoutLoading }] = useMutation(LOGOUT, {
    refetchQueries: [GetEmployeeSelf],
  });

  const logoutCallback = useCallback(() => {
    logout({
      variables: {
        refreshToken: Cookies.get(TITLE_COOKIES),
      },
    })
      .then(() => {
        setSecurity(null);
        clearAccessTokenUser();
        Cookies.remove(TITLE_COOKIES);
        clearRoleUser();
        navigate(SECURITY_AUTH);
      });
  }, []);

  return {
    authLoading, loginLoading, logoutLoading, loginCallback, logoutCallback, security, error,
  };
};

export default useSecurityAuth;
