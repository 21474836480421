import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import {
  ACTIVATE_VOUCHER_ADMIN,
  ACTIVATE_VOUCHER_OPTION,
  FIND_VOUCHERS_BY_ADMIN,
} from '../../../../api/vouchers/query';
import { MANUAL_METHOD } from '../../../../constants/activateMethods';

const useActivateVoucher = () => {
  const [voucherCallback, { loading: activateVoucherLoading }] = useMutation(ACTIVATE_VOUCHER_ADMIN, {
    refetchQueries: [FIND_VOUCHERS_BY_ADMIN],
  });

  const [optionCallback, { loading: activateVoucherOptionLoading }] = useMutation(ACTIVATE_VOUCHER_OPTION, {
    refetchQueries: [FIND_VOUCHERS_BY_ADMIN],
  });

  const activateCallback = useCallback((id, status, entity) => {
    switch (entity) {
    case 'VOUCHER':
      voucherCallback({
        variables: {
          activateMethod: MANUAL_METHOD,
          voucherId: id,
          status,
        },
      });
      break;
    case 'OPTION':
      optionCallback({
        variables: {
          activateMethod: MANUAL_METHOD,
          status,
          voucherOptionId: id,
        },
      });
      break;
    default:
    }
  }, []);

  return {
    activateCallback, activateVoucherLoading, activateVoucherOptionLoading,
  };
};

export default useActivateVoucher;
