import { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie-consent';

const useCookiesAuth = () => {
  const [isShowCookie, setShowCookie] = useState(false);
  useEffect(() => {
    const auth = Cookies.get('YallaBannana'); // => 'value'
    if (!auth || auth === 'false') {
      setShowCookie(true);
    }
  }, []);

  return {
    isShowCookie,
  };
};

export default useCookiesAuth;
