import { useLazyQuery, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { GET_ORDER_BY_ID, GET_VOUCHERS } from '../../../../api/profile/query';
import { profileCouponsTableLimit } from '../../../../constants/tableConstants';
import getPrice from '../../../../helpers/couponPage/getPrice';
import { formatDate } from '../../../../helpers/couponPage/formatDate';
import useLastPageChange from '../../../../hooks/useLastPageChange/useLastPageChange';
import useDebouncedSearch from '../../../../hooks/useDebouncedSearch/useDebouncedSearch';

const useProfileCoupons = () => {
  const [fetchOrder, { data: orderData, loading: loadingOrderData }] = useLazyQuery(GET_ORDER_BY_ID);

  const totalOrderData = useMemo(() => {
    if (orderData) {
      const {
        getOrderById: {
          date, electronicInvoice, orderNumber, paymentID, vouchers, totalPrice,
        },
      } = orderData;

      const fixedDate = formatDate(date);

      const totalOrderPrice = totalPrice / 100;

      const totalVouchers = vouchers.map(({
        id, voucherNumber, totalPrice, totalVatPrice,
      }) => {
        const voucherPrice = getPrice(totalPrice);

        const voucherVat = getPrice(totalVatPrice - totalPrice);

        return {
          id, voucherNumber, voucherPrice, voucherVat,
        };
      });

      return {
        paymentDate: fixedDate, electronicInvoice, orderNumber, paymentID, vouchers: totalVouchers, totalOrderPrice,
      };
    }
    return null;
  }, [orderData]);

  const fetchOrderData = useCallback(async (id) => {
    await fetchOrder({ variables: { id } });
  }, [fetchOrder]);

  const [searchParams] = useSearchParams();

  const { search, onFilterChange } = useDebouncedSearch();

  const querySearch = searchParams.get('search') || '';

  const page = searchParams.get('page') || '1';

  const { data, loading: getCouponsLoading, previousData } = useQuery(GET_VOUCHERS, {
    variables: {
      search: querySearch,
      limit: profileCouponsTableLimit,
      offset: ((Number(page) - 1) * profileCouponsTableLimit),
    },
  });

  useLastPageChange(data);

  const myCouponsData = useMemo(() => {
    const totalData = data || previousData;
    if (totalData) {
      const { findVouchers: { vouchers, count } } = totalData;
      const vouchersData = vouchers.map(({
        id,
        totalPrice,
        couponTitle,
        validDueDate,
        order,
        coupon: { applyVat },
      }) => {
        const { id: invoiceId } = order;
        return {
          id,
          key: id,
          price: totalPrice,
          couponTitle,
          validDueDate,
          invoiceId,
          applyVat,
        };
      });
      return { vouchersData, count };
    } return null;
  }, [data]);

  return {
    ...myCouponsData,
    getCouponsLoading,
    search,
    onFilterChange,
    loadingOrderData,
    fetchOrderData,
    totalOrderData,
  };
};

export default useProfileCoupons;
