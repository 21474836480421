import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import successImage from '../../../assets/images/success.png';
import s from '../SecuritySuccess.module.scss';

const SecuritySuccess = () => (
  <div className={s.main}>
    <div className={s.content}>
      <img
        className={s.image}
        src={successImage}
        alt="success"
      />
      <h1 className={s.title}>Voucher activated</h1>
      <h3 className={s.subtitle}>Voucher successfully activated</h3>
      <Link
        to="/security"
        className={s.link}
        replace
      >
        Close
      </Link>
    </div>
  </div>
);

export default memo(SecuritySuccess);
