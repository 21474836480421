import { useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';

let timeout = null;

const useDebouncedSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const querySearch = searchParams.get('search');

  const [search, setSearch] = useState(querySearch || '');

  const onFilterChange = useCallback((e, searchValue = 'event') => {
    let value;
    if (searchValue !== 'event') {
      value = e;
    } else {
      value = e.currentTarget.value;
    }

    setSearch(value);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      const prevParams = Object.fromEntries([...searchParams]);
      if (value) {
        setSearchParams({
          ...prevParams,
          search: value,
        });
      } else {
        if (prevParams.search) delete prevParams.search;
        setSearchParams(prevParams);
      }
    }, 2000);
  }, [searchParams, setSearchParams]);

  const onEnterFilterChange = useCallback((e) => {
    if (e.key === 'Enter') {
      const { value } = e.currentTarget;
      setSearch(value);
      clearTimeout(timeout);
      const prevParams = Object.fromEntries([...searchParams]);
      if (value) {
        setSearchParams({
          ...prevParams,
          search: value,
        });
      } else {
        if (prevParams.search) delete prevParams.search;
        setSearchParams(prevParams);
      }
    }
  }, [onFilterChange, searchParams]);

  useEffect(() => () => clearTimeout(timeout), []);

  return { search, onFilterChange, onEnterFilterChange };
};

export default useDebouncedSearch;
