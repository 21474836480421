import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import style from '../CategoriesTable/CategoriesTable.module.scss';
import getImgBase64Url from '../../../helpers/getImgBase64Url';

const Name = ({ text, record }) => {
  const [imgUrl, setImgUrl] = useState('');
  const { categoryPicture } = record;

  useEffect(() => {
    if (categoryPicture) {
      getImgBase64Url(categoryPicture.key).then((url) => setImgUrl(url));
    }
  }, [categoryPicture]);
  return (
    <>
      <span className={style.image}>
        {categoryPicture && (
          <img
            src={imgUrl}
            alt=""
          />
        )}
      </span>
      <span className={style.text}>{text}</span>
    </>
  );
};

Name.propTypes = {
  text: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.shape({
    categoryPicture: PropTypes.shape({
      categoryId: PropTypes.string,
      id: PropTypes.string,
      key: PropTypes.string,
      mimetype: PropTypes.string,
      originalName: PropTypes.string,
      __typename: PropTypes.string,
    }),
    couponsCount: PropTypes.number,
    id: PropTypes.string,
    isPublished: PropTypes.bool,
    key: PropTypes.string,
    parentCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    position: PropTypes.number,
    subCategories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      position: PropTypes.number,
      isPublished: PropTypes.bool,
      couponsCount: PropTypes.number,
      parentCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      categoryPicture: PropTypes.shape({
        categoryId: PropTypes.string,
        id: PropTypes.string,
        key: PropTypes.string,
        mimetype: PropTypes.string,
        originalName: PropTypes.string,
      }),
    })),
    title: PropTypes.string,
    __typename: PropTypes.string,
  }).isRequired,
};

export default Name;
