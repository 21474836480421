const validatorForm = (from, schema) => {
  const errors = {};
  Object.keys(schema).forEach((key) => {
    const {
      errorMessage, required, rules, type,
    } = schema[key];
    const { [key]: filedValue } = from;
    if (filedValue !== undefined) {
      if (required) {
        if (!filedValue) {
          errors[key] = errorMessage;
        } else if (rules) {
          rules.some((rule) => {
            const { errorMessage: ruleError, ruleValidation, ruleProp } = rule;
            const { [ruleProp]: ruleValue } = from;
            const isValid = ruleValidation(filedValue, ruleValue);
            if (!isValid) {
              errors[key] = ruleError || errorMessage;
            }
            return !isValid;
          });
        }
      }

      if (type === 'array2') {
        filedValue.forEach((item) => {
          const { id } = item;
          const err = validatorForm(item, rules);
          if (Object.keys(err.errors).length) {
            if (!errors[key]) {
              errors[key] = {};
            }
            errors[key][id] = err.errors;
          }
        });
      } else {
        if (!type && rules && !errors[key]) {
          rules.some((rule) => {
            const { errorMessage: ruleError, ruleValidation, ruleProp } = rule;
            const { [ruleProp]: ruleValue } = from;
            const isValid = ruleValidation(filedValue, ruleValue);
            if (!isValid) {
              errors[key] = ruleError || errorMessage;
            }
            return !isValid;
          });
        }
        if (type && !errors[key]) {
          if (type === 'array') {
            const { value, id } = schema[key];
            rules.forEach((rule) => {
              const { ruleProp, errorMessage, ruleValidation } = rule;
              filedValue.forEach((item) => {
                const { [value]: itemValue, [id]: itemId, [ruleProp]: secondValue } = item;
                const isValid = ruleValidation(itemValue, secondValue);
                if (!isValid) {
                  if (!errors[key]) {
                    errors[key] = {};
                  }
                  errors[key][itemId] = errorMessage;
                }
              });
            });
          }
        }
      }
    }
  });

  return {
    errors,
  };
};

export default validatorForm;
