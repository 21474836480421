import Column from 'antd/lib/table/Column';
import { Image, Table, Typography } from 'antd';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import placeholder from '../../../assets/images/preview-placeholder.png';
import s from '../CouponsTable/CouponsTable.module.scss';
import getCost from '../../../helpers/cart-helpers/getCost';
import CartQuantityControls from '../CartQuantityControls';
import formatDate from '../../../helpers/cart-helpers/formatDate';
import { TrashBin } from '../../../constants/icons';

const DesktopCouponsTable = ({
  totalCartData, couponHandler, optionHandler, removeIconOnClick,
}) => (
  <Table
    rowClassName={(record) => (record.promoCodeApplied ? 'with-promo' : '')}
    rowKey={(record) => record.cartId}
    pagination={false}
    className="cartTable"
    dataSource={totalCartData}
  >
    <Column
      title="Coupon"
      dataIndex="couponPreviewPicture"
      key="preview"
      render={(couponPreviewPicture, record) => {
        const previewUrl = couponPreviewPicture
          ? `${process.env.REACT_APP_BUCKET_URL}/${couponPreviewPicture.key}`
          : placeholder;
        return (
          <Link
            to={`/coupon/${record.id}`}
            target="_blank"
          >
            <Image
              preview={false}
              className={s.preview}
              src={previewUrl}
              alt="preview"
            />
          </Link>
        );
      }}
    />
    <Column
      title=""
      dataIndex="title"
      key="title"
      render={(title, record) => {
        const { shoppingCartCouponOptions: options } = record;
        const mappedOptions = options.length > 0 && options.map(({
          amount,
          couponOption: {
            title: couponTitle,
            id,
          },
        }) => (
          <Typography.Text
            className={s.optionTitle}
            key={id}
            type={amount === 0 ? 'secondary' : ''}
          >
            {couponTitle}
          </Typography.Text>
        ));
        return (
          <>
            <Link
              to={`/coupon/${record.id}`}
              target="_blank"
            >
              <Typography.Text>{title}</Typography.Text>
            </Link>
            {mappedOptions && (
              <div className={s.rowOptions}>
                {mappedOptions}
              </div>
            )}
          </>
        );
      }}
    />
    <Column
      title="Price, AED"
      dataIndex="currentPrice"
      key="currentPrice"
      render={(currentPrice, record) => {
        const { shoppingCartCouponOptions: options } = record;
        const mappedOptionsPrice = options.length > 0 && options
          .map(({
            amount, couponOption: { currentPrice, id },
          }) => (
            <Typography.Text
              type={amount === 0 ? 'secondary' : ''}
              key={id}
            >
              {getCost(currentPrice / 100)}
            </Typography.Text>
          ));
        return (
          <div className={s.prices}>
            <Typography.Text>{getCost(currentPrice / 100)}</Typography.Text>
            {mappedOptionsPrice && <div className={s.options}>{mappedOptionsPrice}</div>}
          </div>
        );
      }}
    />
    <Column
      title="Old price, AED"
      dataIndex="oldPrice"
      key="oldPrice"
      render={(oldPrice, record) => {
        const { currentPrice, oldPrice: price } = record;
        return (
          currentPrice !== price ? (
            <Typography.Text
              delete
              type="secondary"
            >
              {getCost(oldPrice / 100)}
            </Typography.Text>
          ) : <div />
        );
      }}
    />
    <Column
      title="Quantity"
      dataIndex="quantity"
      key="quantity"
      render={(_, record) => {
        const {
          cartId, availableAmountInPeriod, shoppingCartCouponOptions, quantity,
        } = record;
        const mappedOptionsQuantity = shoppingCartCouponOptions.length > 0 && shoppingCartCouponOptions
          .map(({ id, amount }) => (
            <CartQuantityControls
              key={id}
              itemId={cartId}
              optionId={id}
              orderHandler={optionHandler}
              quantity={amount}
            />
          ));
        return (
          <div className={s.quantities}>
            <CartQuantityControls
              quantity={quantity}
              itemId={cartId}
              limit={availableAmountInPeriod}
              orderHandler={couponHandler}
            />
            {mappedOptionsQuantity && (
              <div className={s.optionQuantities}>
                {mappedOptionsQuantity}
              </div>
            )}
          </div>
        );
      }}
    />
    <Column
      title="Offer deadline"
      dataIndex="deadline"
      key="deadline"
      render={(publishedDueDate) => {
        const fixedDate = formatDate(publishedDueDate);
        return (<Typography.Text className={s.date}>{fixedDate}</Typography.Text>);
      }}
    />
    <Column
      title=""
      dataIndex="cartId"
      key="removeCoupon"
      render={(key) => (
        <div
          className={s.remove}
          onClick={() => removeIconOnClick(key)}
        >
          <TrashBin />
        </div>
      )}
    />
  </Table>
);

DesktopCouponsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  totalCartData: PropTypes.array,
  removeIconOnClick: PropTypes.func.isRequired,
  optionHandler: PropTypes.func.isRequired,
  couponHandler: PropTypes.func.isRequired,
};

DesktopCouponsTable.defaultProps = {
  totalCartData: [],
};

export default memo(DesktopCouponsTable);
