const IS_BUY_NOW_KEY = 'is-buy-now';
const COUPON_ORDER_KEY = 'coupon-order';

export const setCouponOrder = (order) => {
  const stringifyOrder = JSON.stringify(order);
  localStorage.setItem(COUPON_ORDER_KEY, stringifyOrder);
};

export const clearCouponOrder = () => localStorage.removeItem(COUPON_ORDER_KEY);

export const getCouponOrder = () => {
  const order = localStorage.getItem(COUPON_ORDER_KEY);
  return JSON.parse(order) || null;
};

export const setIsByNow = (isBuyNow) => localStorage.setItem(IS_BUY_NOW_KEY, isBuyNow);

export const clearIsBuyNow = () => localStorage.removeItem(IS_BUY_NOW_KEY);

export const getIsBuyNow = () => {
  const isBuyNow = localStorage.getItem(IS_BUY_NOW_KEY);
  return isBuyNow || null;
};
