import React, {
  memo, useCallback, useRef, useState,
} from 'react';
import { Form, Input, Modal } from 'antd';
import PropTypes from 'prop-types';
import 'react-phone-input-2/lib/style.css';
import { uid } from 'uid';
import { intersectionBy } from 'lodash';
import { MERCHANT_MODAL_SCHEMA } from '../../../../../../../constants/validationMerchantSchema';
import Days from '../../../../../../Common_components/DaysIntervalComponent/Days/Days';
import './modal-subdivisions.scss';
import PhoneInputComponent from '../../../../../../Common_components/PhoneInputComponent';
import { CloseIcon } from '../../../../../../../constants/icons';
import validator from '../../../../../../../helpers/validator';
import s from '../../../FormMerchant/form-merchant.module.scss';
import ButtonComponent from '../../../../../../Common_components/ButtonComponent';
import MapComponent from './MapComponent';

const { Item } = Form;

const ModalSubdivisions = (props) => {
  const {
    subdivisionsInfo,
    setSubdivisionsInfoHandler,
    visibleModal,
    onCancelModal,
    addMethod,
    setSubdivisionsInfoValue,
  } = props;

  const [allErrors, setAllErrors] = useState(null);

  const onCancelModalHandler = () => {
    onCancelModal();
  };

  const initGoldenHours = useRef(subdivisionsInfo.couponGoldenHours);

  const setCoordinatesOfLocation = useCallback((coordinates, titleCity) => {
    setSubdivisionsInfoValue('location', coordinates);
    setSubdivisionsInfoValue('address', titleCity);
  }, []);

  const setAddressOfLocation = useCallback((titleCity) => { setSubdivisionsInfoValue('address', titleCity); }, []);

  const sendFormData = () => {
    const {
      title: titleValue, address: addressValue, couponGoldenHours, ...rest
    } = subdivisionsInfo;

    const isCheckedCouponGoldenHours = couponGoldenHours.filter((hours) => hours.isCheckedDay);
    const notIsCheckedCouponGoldenHours = couponGoldenHours.filter((hours) => !hours.isCheckedDay);

    const { errors } = validator({
      ...rest,
      title: titleValue && titleValue.trim(),
      address: (titleValue && addressValue.trim()) || '',
      couponGoldenHours: isCheckedCouponGoldenHours,
    }, MERCHANT_MODAL_SCHEMA);

    if (!Object.keys(errors).length && isCheckedCouponGoldenHours.length) {
      const {
        couponGoldenHours,
        couponGoldenHoursToCreate,
        couponGoldenHoursToDelete,
        couponGoldenHoursToUpdate,
        ...restSubdivisions
      } = subdivisionsInfo;
      const { id: idFilial } = subdivisionsInfo;

      const finallyGoldenHoursDeleteInterval = couponGoldenHoursToDelete.map((interval) => {
        const { intervalId } = interval;
        return intervalId;
      });

      const finallyGoldenHoursCreateInterval = couponGoldenHoursToCreate
        .filter((interval) => interval.isCheckedDay)
        .map((interval) => {
          const { intervalId, isCheckedDay, ...rest } = interval;
          return rest;
        });

      const finallyGoldenHoursUpdateInterval = couponGoldenHoursToUpdate
        .filter((interval) => interval.isCheckedDay)
        .map((interval) => {
          const { intervalId, isCheckedDay, ...rest } = interval;
          return {
            id: intervalId,
            ...rest,
          };
        });

      const uniqueArrayForDeleteForSwitch = intersectionBy(
        initGoldenHours.current,
        notIsCheckedCouponGoldenHours,
        'intervalId',
      );
      addMethod({
        ...restSubdivisions,
        title: titleValue.trim(),
        address: addressValue.trim(),
        id: idFilial || uid(),
        couponGoldenHours: isCheckedCouponGoldenHours,
        couponGoldenHoursToCreate: finallyGoldenHoursCreateInterval,
        couponGoldenHoursToDelete: [...finallyGoldenHoursDeleteInterval,
          ...uniqueArrayForDeleteForSwitch.map((interval) => interval.intervalId),
        ],
        couponGoldenHoursToUpdate: finallyGoldenHoursUpdateInterval,
      });
      onCancelModalHandler();
    } else {
      setAllErrors(errors);
    }
  };

  return (
    <Modal
      title={subdivisionsInfo.id ? 'Edit contact' : 'Add contact'}
      className="modal-subdivisions"
      centered
      closeIcon={<CloseIcon />}
      visible={visibleModal}
      onCancel={onCancelModal}
      width={440}
      okText={subdivisionsInfo.id ? 'Save' : 'Add'}
      cancelText="Cancel"
      footer={null}
    >
      <Form
        name="modalSubdivisions"
        layout="vertical"
      >
        <Item
          className={s.formItem}
          label="Local name"
          name="title"
        >
          <Input
            style={{
              height: '40px',
              borderRadius: '4px',
              border: allErrors?.title && !subdivisionsInfo.title.trim()
                ? '1px solid #FF0000FF' : '1px solid #EDEDED',
            }}
            value={subdivisionsInfo.title}
            onChange={(e) => setSubdivisionsInfoValue('title', e.target.value)}
            maxLength={200}
          />
          {allErrors?.title && !subdivisionsInfo.title.trim()
            && <span className="errorCouponText">{allErrors.title}</span>}
        </Item>
        <Item
          className={s.formItem}
          label="Address"
          name="address"
        >
          <MapComponent
            address={subdivisionsInfo.address}
            location={subdivisionsInfo.location}
            setCoordinatesOfLocation={setCoordinatesOfLocation}
            error={allErrors?.address}
            setAddressOfLocation={setAddressOfLocation}
          />
        </Item>
        <Item
          className={s.formItem}
          label="Phone"
          name="phone"
        >
          <PhoneInputComponent
            stateInfo={subdivisionsInfo}
            setInfoFormHandler={setSubdivisionsInfoHandler}
            allErrors={allErrors}
          />
        </Item>
        <Item
          className={s.formItem}
          label="Working"
          name="working"
        >
          <Days
            hours={subdivisionsInfo.couponGoldenHours}
            setHours={setSubdivisionsInfoHandler}
            allErrors={allErrors?.couponGoldenHours}
          />

          {
            (allErrors && subdivisionsInfo?.couponGoldenHours.every((interval) => !interval.isCheckedDay))
            && <span className="errorCouponText">Add at least one working interval!</span>
          }
        </Item>

        <div className={s.btnModal}>
          <ButtonComponent
            title="Cancel"
            addClass={s.cancel}
            iconComponent={false}
            callback={onCancelModalHandler}
          />
          <ButtonComponent
            title="Save"
            iconComponent={false}
            callback={sendFormData}
          />
        </div>
      </Form>
    </Modal>
  );
};

ModalSubdivisions.propTypes = {
  subdivisionsInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setSubdivisionsInfoHandler: PropTypes.func.isRequired,
  visibleModal: PropTypes.bool.isRequired,
  onCancelModal: PropTypes.func.isRequired,
  addMethod: PropTypes.func.isRequired,
  setSubdivisionsInfoValue: PropTypes.func.isRequired,
};

export default memo(ModalSubdivisions);
