import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import s from './Catalog.module.scss';
import Coupons from './Coupons';
import CatalogContext from './context/catalogContext';
import useFetchCouponsContext from './hooks/useFetchCoupons/useFetchCouponsContext';
import { scrollToPreviousOffset } from '../../helpers/catalog-helpers/catalog-helpers';
import Subscribe from './Subscribe';
import Filter from './Filter';
import Categories from './Categories';
import Footer from '../Footer';
import MobileCatalogHeader from './MobileCatalogHeader';
import mobileCondition from '../../constants/mobileCondition';
import mobileCatalogContext from '../MobileCatalogWrapper/mobileCatalogContext';
import CategoriesContext from '../Layout/context/categoriesContext';
import MobileLayoutHeader from './MobileLayoutHeader';
import { clearCouponOrder, clearIsBuyNow } from '../../helpers/order-storage';
import SingleImageBanner from '../SingleImageBanner';
import { bannersTypes } from '../../constants/banners/enums';
import PartnersSlider from '../PartnersSlider';
import ShowBannersOrAllCouponsHere from './ShowBannersOrAllCouponsHere';

const Catalog = () => {
  const { catalogRef, scrollOnCouponsTop } = useContext(CategoriesContext);

  const { state } = useLocation();

  const context = useFetchCouponsContext();

  const { headerData } = useContext(mobileCatalogContext);

  const { count } = context;

  useEffect(() => {
    if (state?.isScroll) {
      scrollOnCouponsTop();
    }
    scrollToPreviousOffset();
    clearIsBuyNow();
    clearCouponOrder();
  }, []);

  const titleClassName = `${s.title} ${mobileCondition && s.mobile}`;
  const contentClassName = `${s.catalogContent} ${mobileCondition && s.mobile}`;

  const [isFormShow, setIsFormShow] = useState(false);

  const filterContainerClassName = `${s.filterContainer} ${mobileCondition && s.mobile}`;

  const mainClassName = `${s.main} ${mobileCondition && s.mobile}`;
  const containerClassName = `${s.container} ${mobileCondition && s.mobile}`;

  const isCategoryFilterExist = Object.keys(context.params)
    .findIndex((p) => p === 'categoryId' || p === 'subCategoryId') > -1;

  return (
    <CatalogContext.Provider value={context}>
      <div className={mainClassName}>
        {!mobileCondition && (
          <SingleImageBanner
            bannerTarget={bannersTypes.STATIC_HORIZONTAL}
            additionalContainerClass={s.wideSlider}
          />
        )}
        {mobileCondition && !isCategoryFilterExist && <MobileLayoutHeader />}
        {mobileCondition
          ? !isCategoryFilterExist && <ShowBannersOrAllCouponsHere />
          : <ShowBannersOrAllCouponsHere /> }
        <div
          ref={catalogRef}
          className={containerClassName}
        >
          {(mobileCondition && headerData)
            ? (
              <MobileCatalogHeader
                title={headerData.title}
                couponsCount={count || 0}
                backTitle={headerData.backTitle}
                setIsFormShow={setIsFormShow}
              />
            )
            : (
              <span className={titleClassName}>
                {mobileCondition ? 'Coupons' : 'Catalog'}
              </span>
            )}
          {mobileCondition ? (
            <div className={contentClassName}>
              <Filter
                setIsFormShow={setIsFormShow}
                isFormShow={isFormShow}
              />
              <Coupons />
              <Categories isCategoryFilterExist={isCategoryFilterExist} />
            </div>
          )
            : (
              <div className={contentClassName}>
                <div className={filterContainerClassName}>
                  <Filter />
                  <Categories />
                  <SingleImageBanner bannerTarget={bannersTypes.STATIC_VERTICAL} />
                </div>
                <Coupons />
              </div>
            )}
        </div>
        <PartnersSlider />
        <Subscribe />
        {mobileCondition && <Footer />}
      </div>
    </CatalogContext.Provider>
  );
};

export default memo(Catalog);
