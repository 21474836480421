import React, { memo, useCallback } from 'react';
import FailedHeader from './FailedHeader';
import FailedBody from './FailedBody';
import FailedBtns from './FailedBtns';
import './failed-component.scss';
import '../SuccessComponent/success-component.scss';
import useCreateOrder from '../../../Cart/hooks/useCreateOrder';
import Loader from '../../../Loader/Loader';
import mobileCondition from '../../../../constants/mobileCondition';
import useBuyNow from '../../../CouponPage/hooks/useBuyNow';
import { getCouponOrder, getIsBuyNow } from '../../../../helpers/order-storage';

const FailedComponent = () => {
  const { loadingCreateOrder, createOrderHandler } = useCreateOrder();
  const { buyNowCallback, buyLoading } = useBuyNow();
  const tryAgainHandler = useCallback(async () => {
    if (getIsBuyNow()) {
      const order = getCouponOrder();
      await buyNowCallback(order);
    }
    await createOrderHandler();
  }, [createOrderHandler]);

  const containerClassName = `containerCheckoutFinish ${mobileCondition ? 'mobile' : ''}`;

  return (
    <>
      {(loadingCreateOrder || buyLoading) && <Loader />}
      <div className={containerClassName}>
        <FailedHeader />
        <FailedBody />
        <FailedBtns
          callback={tryAgainHandler}
        />
      </div>
    </>
  );
};

export default memo(FailedComponent);
