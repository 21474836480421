import React, {
  memo, useContext, useLayoutEffect, useState,
} from 'react';
import { toDataURL } from 'qrcode';
import { Link } from 'react-router-dom';
import s from './SecurityMain.module.scss';
import { LogOut } from '../../constants/icons';
import { SecurityContext } from '../SecurityLayout/SecurityLayout';
import Loader from '../Loader';
import { VOUCHERS } from '../../constants/Routes';

const SecurityMain = () => {
  const { logoutLoading, logoutCallback } = useContext(SecurityContext);

  const [qrcodeUrl, setQrcodeUrl] = useState(null);

  useLayoutEffect(() => {
    toDataURL('They constantly force me to make edits... Save me please!!!')
      .then(setQrcodeUrl);
  }, []);

  return (
    <div className={s.main}>
      {logoutLoading && <Loader />}
      <div className={s.logout}>
        <div onClick={logoutCallback}>
          <LogOut />
        </div>
      </div>
      <div className={s.qrcode}>
        <img
          src={qrcodeUrl}
          alt="secret qrcode"
        />
      </div>
      <div className={s.buttons}>
        <Link
          to="scanner"
          className={s.scan}
        >
          Scan QR-Code
        </Link>
        <Link
          to={VOUCHERS}
          className={s.vouchers}
        >
          Activated vouchers
        </Link>
      </div>
    </div>
  );
};

export default memo(SecurityMain);
