import React from 'react';
import './header.scss';
import SearchInputComponent from '../../Common_components/SearchInputComponent';
import useDebouncedSearch from '../../../hooks/useDebouncedSearch/useDebouncedSearch';

const HeaderComponent = () => {
  const { search, onFilterChange, onEnterFilterChange } = useDebouncedSearch();

  return (
    <header className="header">
      <div className="header__row">
        <div className="header__left">
          <h1 className="header__title">Logs</h1>
        </div>
        <div className="header__right">
          <SearchInputComponent
            value={search}
            callback={onFilterChange}
            placeholder="Coupon ID, Name..."
            addClass="searchInput"
            onEnterCallback={onEnterFilterChange}
          />
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
