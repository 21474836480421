import React, { memo } from 'react';
import Logo from '../../../assets/images/Logo.svg';
import s from './MobileLayoutHeader.module.scss';

const MobileLayoutHeader = () => (
  <div className={s.logoContainer}>
    <div className={s.logo}>
      <img
        src={Logo}
        alt="Logo"
      />
    </div>
    <div className={s.info}>
      <span className={s.title}>YallaBanana</span>
      <span className={s.subtitle}>If you love great deals, you&apos;ll love me</span>
    </div>
  </div>
);

export default memo(MobileLayoutHeader);
