import React, { memo, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import HeaderComponent from './Header';
import useGetAllBanners from './hooks/gql/useGetAllBanners';
import Loader from '../Loader';
import TableBanners from './TableBaners';
import DeleteModalBannerComponent from './DeleteModalBannerComponent';
import BannerModalComponent from './BannerModalComponent';
import './Header/table.scss';
import '../Table/Pagination.scss';

const BannersTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    banners, count, loading,
  } = useGetAllBanners();

  const id = searchParams.get('id') || '';
  const page = searchParams.get('page') || '1';
  const actionOperation = searchParams.get('action') || '';

  const [isOpenModalBanner, setOpenModalBanner] = useState(!!id);

  const openModalBannerHandler = useCallback(() => {
    setSearchParams({
      ...Object.entries(searchParams),
      page,
      id: 'create-banner',
    });
    setOpenModalBanner(true);
  }, [page, searchParams, setSearchParams]);

  const deleteModalBannerHandler = useCallback((id) => {
    setSearchParams({
      ...Object.entries(searchParams),
      page,
      id,
      action: 'delete',
    });
    setOpenModalBanner(true);
  }, [page, searchParams, setSearchParams]);

  const editModalBannerHandler = useCallback((id) => {
    setSearchParams({
      ...Object.entries(searchParams),
      page,
      id,
    });
    setOpenModalBanner(true);
  }, [page, searchParams, setSearchParams]);

  const closeModalWindowBanner = useCallback(() => {
    setSearchParams({
      page,
    });
    setOpenModalBanner(false);
  }, [page, setSearchParams]);

  const closeModalWindowBannerWhenDelete = useCallback(() => {
    setOpenModalBanner(false);
  }, []);

  return (
    <>
      <HeaderComponent
        callback={openModalBannerHandler}
      />
      <section
        className="banner-table"
        style={{
          height: '100%',
        }}
      >
        {
          loading
            ? <Loader />
            : (
              <TableBanners
                editModalBannerHandler={editModalBannerHandler}
                deleteModalBannerHandler={deleteModalBannerHandler}
                banners={banners}
                count={count}
              />
            )
        }
      </section>
      {
        isOpenModalBanner && actionOperation !== 'delete'
          ? (
            <BannerModalComponent
              isOpenModalBanner={isOpenModalBanner}
              closeModalWindowBanner={closeModalWindowBanner}
            />
          )
          : (
            <DeleteModalBannerComponent
              isOpenModalBanner={isOpenModalBanner}
              closeModalWindowBannerWhenDelete={closeModalWindowBannerWhenDelete}
              banners={banners}
              closeModalWindowBanner={closeModalWindowBanner}
            />
          )

      }
    </>
  );
};

export default memo(BannersTable);
