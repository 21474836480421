import React from 'react';
import Page from '../../components/Page';
import BannersTable from '../../components/Banners/BannersTable';

const Banners = () => (
  <Page>
    <BannersTable />
  </Page>
);

export default Banners;
