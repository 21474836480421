import React, { createContext, memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import s from './SecurityLayout.module.scss';
import useSecurityAuth from './hooks/useSecurityAuth';
import mobileCondition from '../../constants/mobileCondition';
import { MAIN } from '../../constants/Routes';

export const SecurityContext = createContext(null);

const SecurityLayout = () => {
  const context = useSecurityAuth();

  if (!mobileCondition) return <Navigate to={MAIN} />;

  return (
    <SecurityContext.Provider value={context}>
      <div className={s.layout}>
        <Outlet />
      </div>
    </SecurityContext.Provider>
  );
};

export default memo(SecurityLayout);
