import { useQuery } from '@apollo/client';
import { COUPONS_TITLE } from '../../../../../api/PromoCodes/query';
import { CouponTitleStatuses, limitCouponsTitle } from '../../../../../constants/promoCodes/promoCodes';

const useGetCouponsTitle = () => {
  const { loading, error, data } = useQuery(COUPONS_TITLE, {
    variables: {
      offset: 0,
      limit: limitCouponsTitle,
      couponTitleFilter: {
        status: CouponTitleStatuses.ACTIVE,
        allowToUsePromoCode: true,
        isPublished: true,
      },
    },
  });

  if (data) {
    const { couponsTitle } = data;
    return {
      coupons: couponsTitle.coupons,
      loading,
      error,
    };
  }

  return {
    loading,
    error,
  };
};

export default useGetCouponsTitle;
