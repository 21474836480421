import React, { memo, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from '../../../constants/icons';
import { MOBILE_CATALOG } from '../../../constants/Routes';
import s from './MobileCouponPath.module.scss';
import CouponContext from '../context/CouponContext';

const MobileCouponPath = () => {
  const { subCategory } = useContext(CouponContext);
  const navigate = useNavigate();
  const backUrl = subCategory ? `${MOBILE_CATALOG}?subCategoryId=${subCategory.id}` : MOBILE_CATALOG;
  return (
    <div
      onClick={() => navigate(backUrl)}
      className={s.main}
    >
      <ArrowLeft />
      <span className={s.title}>
        {subCategory?.title || 'Categories'}
      </span>
    </div>
  );
};

export default memo(MobileCouponPath);
