import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { profileCouponsTableLimit } from '../../../../../constants/tableConstants';

const TableComponent = ({ dataSource, columns, total }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get('page') || '1';

  const onPageChange = (page) => setSearchParams({ ...Object.fromEntries([...searchParams]), page });

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={{
        current: Number(page),
        pageSize: profileCouponsTableLimit,
        position: ['bottomCenter'],
        total,
        size: 'small',
        showSizeChanger: false,
        onChange: (page) => onPageChange(page),
      }}
    />
  );
};
TableComponent.propTypes = {
  dataSource: PropTypes.oneOfType([PropTypes.array]).isRequired,
  columns: PropTypes.oneOfType([PropTypes.array]).isRequired,
  total: PropTypes.number.isRequired,
};

export default memo(TableComponent);
