import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import Cookies from 'js-cookie';
import { REGISTER } from '../../api/query';
import { MAIN } from '../../constants/Routes';
import { setAccessTokenUser, setRoleUser } from '../../helpers/user-storage';
import UserContext from '../../components/Layout/context/userContext';
import { LocationContext } from '../../components/MobileProfile/MobileNotAuthRedirect/MobileNotAuthRedirect';
import useAddCouponsFromLocal from '../../components/Header/hooks/useAddCouponsFromLocal/useAddCouponsFromLocal';
import { SETTING_OF_COOKIES, TITLE_COOKIES } from '../../constants/general/tag-status';

const useRegisterMutation = () => {
  const navigate = useNavigate();

  const redirectPath = useContext(LocationContext);

  const { fetchUser, processingCallback, clearProcessingCallback } = useContext(UserContext);

  const [registerUser, { data: registerData, loading: registerLoading }] = useMutation(REGISTER);

  const { uploadCoupons } = useAddCouponsFromLocal();

  const sendRegisterData = async (values) => {
    const { email } = values;
    const { data: { register: { accessToken, refreshToken, role } } } = await registerUser({
      variables: {
        registerUserInput: {
          email: email.trim().toLowerCase(),
          role: 'CUSTOMER',
        },
      },
    });
    setAccessTokenUser(accessToken);
    Cookies.set(TITLE_COOKIES, refreshToken, SETTING_OF_COOKIES);
    setRoleUser(role);
    fetchUser();
    await uploadCoupons();
    if (processingCallback) {
      processingCallback();
      clearProcessingCallback();
    } else {
      navigate(redirectPath || MAIN);
    }
  };

  return {
    sendRegisterData,
    registerData,
    registerLoading,
  };
};

export default useRegisterMutation;
