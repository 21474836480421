import { gql } from '@apollo/client';

export const CREATE_EMPLOYEE = gql`
    mutation createEmployee ($createEmployeeInput: CreateEmployeeInput!) {
        createEmployee (createEmployeeInput: $createEmployeeInput) {
            id
        }
    }
`;

export const UPDATE_EMPLOYEE_BY_ID = gql`
    mutation updateEmployeeById ($updateEmployeeInput: UpdateEmployeeInput!, $id: ID!) {
        updateEmployeeById (updateEmployeeInput: $updateEmployeeInput, id:$id) {
            id
        }
    }
`;

export const FIND_ALL_EMPLOYEES = gql`
    query findAllEmployees ($offset: Float, $limit: Float) {
        findAllEmployees(offset: $offset, limit: $limit) {
            employees {
                id
                firstName
                lastName
                email
                role
                status
                userPicture {
                    key
                }
            }
            count
        }
    }
`;

export const GET_EMPLOYEE_BY_ID = gql`
    query getEmployeeById ($id: ID!) {
        getEmployeeById(id: $id,) {
            id
            firstName
            lastName
            email
            role
            status
        }
    }
`;

export const DELETE_USER = gql`
    mutation deleteUser ($id: ID!) {
        deleteUser (id: $id) 
    }
`;
