import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './MobileSubcategories.module.scss';
import MobileSubCategoryItem from '../MobileSubCategoryItem';

const MobileSubcategories = ({ subCategories, areOpened, parentCategoryTitle }) => {
  const mainClassName = `${s.main} ${areOpened && s.opened}`;

  const mappedSubcategories = subCategories.length > 0 && subCategories.map(({
    categoryPicture, id, title, couponsCount,
  }) => (
    <MobileSubCategoryItem
      key={id}
      title={title}
      id={id}
      couponsCount={couponsCount}
      iconKey={categoryPicture.key}
      parentCategoryTitle={parentCategoryTitle}
    />
  ));

  return (
    <div className={mainClassName}>
      {mappedSubcategories}
    </div>
  );
};

MobileSubcategories.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  subCategories: PropTypes.array.isRequired,
  areOpened: PropTypes.bool.isRequired,
  parentCategoryTitle: PropTypes.string.isRequired,
};

export default memo(MobileSubcategories);
