import React, { memo } from 'react';
import './success-body.scss';
import PropTypes from 'prop-types';
import moment from 'moment';

const SuccessBody = ({ applyOrder: { date, orderNumber, totalPrice } }) => (
  <div className="success-body">
    <div className="success-body__total total">
      <div className="total__item">Total</div>
      <div className="total__item right">
        {totalPrice}
        {' '}
        AED
      </div>
    </div>
    <div className="success-body__info info">
      <div className="info__item">Date</div>
      <div className="info__item right">{moment(date).format('DD MMMM, YYYY')}</div>
      <div className="info__item">Transaction ID</div>
      <div className="info__item right">{orderNumber}</div>
    </div>
  </div>
);

SuccessBody.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  applyOrder: PropTypes.object.isRequired,
};

export default memo(SuccessBody);
