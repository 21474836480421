import { gql } from '@apollo/client';

export const FIND_COUPON_BY_ID = gql`
    query findCouponById($id: ID!) {
        findCouponById(id: $id) {
            id
            title
            category {
                id
                title
            }
            subCategory {
                id
                title
            }
            oldPrice
            vatPrice
            currentPrice
            discount
            validDueDate
            publishedDueDate
            keywordsImages
            limitPeriod
            description
            terms
            status
            currentAvailableAmount
            videoReviewUrl
            couponOptions {
                id
                title
                vatPrice
                currentPrice
                oldPrice
                discount
                filialBranches {
                    address
                }
            }
            couponGoldenHours {
                dayOfWeek
                startAt
                endAt
            }
            merchant {
                id
                title
            }
            filialBranches {
                title
                address
                phone
                location
                businessHours {
                    dayOfWeek
                    startAt
                    endAt
                }
            }
            couponGalleryPictures {
                key
            }
            couponPreviewPicture {
                key
            }
        }
    }
`;

export const ADD_COUPON_TO_CART = gql`
    mutation addCouponToShoppingCart($shoppingCartCouponInput: ShoppingCartCouponCreateInput!) {
        addCouponToShoppingCart(shoppingCartCouponInput: $shoppingCartCouponInput)
    }
`;

export const BUY_NOW_COUPON = gql`
    mutation buyNow($shoppingCartsCouponInput: [ShoppingCartCouponCreateInput!]!) {
        buyNow(shoppingCartsCouponInput: $shoppingCartsCouponInput) {
            payUrl
            id
            orderNumber
        }
    }
`;
