import { useQuery } from '@apollo/client';
import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GET_ALL_COUPONS_IN_CART } from '../../../api/cart-api/query';
import { isUserStored } from '../../../helpers/user-storage';
import UserContext from '../../Layout/context/userContext';
import useRemoveCouponFromCart from './useRemoveCouponFromCart';
import mobileCondition from '../../../constants/mobileCondition';
import { AUTH, CART, SITE_SIGN_IN } from '../../../constants/Routes';

const useCartCoupons = () => {
  const [promoCode, setPromo] = useState(undefined);
  const navigate = useNavigate();

  const isAuth = isUserStored();

  const { setSignUpActive, userData } = useContext(UserContext);

  const setPromocodeCallback = (promoCode) => {
    if (isAuth) {
      setPromo(promoCode.trim());
    } else if (mobileCondition) {
      navigate(`${AUTH}/${SITE_SIGN_IN}`, { state: { from: CART } });
    } else {
      setSignUpActive();
    }
  };

  const { data, loading, previousData } = useQuery(GET_ALL_COUPONS_IN_CART, {
    variables: { promoCode },
    skip: !isAuth,
  });

  const { removeLoading, removeCouponFromCart } = useRemoveCouponFromCart();

  const promoCodeError = useMemo(() => {
    if (promoCode) {
      if (previousData && !loading) {
        const totalData = data || previousData;
        const { shoppingCartCoupons } = totalData.getAllCouponsInCart;
        const isPromoCodeExist = !!shoppingCartCoupons.find(({ promoCodeApplied }) => (promoCodeApplied === true));
        return !isPromoCodeExist ? 'No coupons for this promo code' : null;
      }
    }
    return null;
  }, [loading, data, previousData, promoCode]);

  const cartData = useMemo(() => {
    if (data || previousData) {
      const totalData = data || previousData || {};
      const {
        shoppingCartCoupons,
        totalCostOfCouponsInCart,
        totalOldCostOfCouponsInCart,
        totalDiscountOfCouponsInCart,
        totalPromoDiscountOfCouponsInCart,
        totalVatOfCouponsInCart,
      } = totalData.getAllCouponsInCart;
      const couponsTableData = shoppingCartCoupons.map(({
        amount,
        coupon,
        shoppingCartCouponOptions,
        id: cartId,
        promoCodeApplied,
      }) => {
        const {
          couponPreviewPicture,
          currentPrice,
          oldPrice,
          publishedDueDate,
          title,
          id,
          currentAvailableAmount,
          couponOptions,
        } = coupon;
        return {
          cartId,
          id,
          promoCodeApplied,
          couponPreviewPicture,
          title,
          currentPrice,
          oldPrice,
          couponOptions,
          quantity: amount,
          deadline: publishedDueDate,
          shoppingCartCouponOptions,
          currentAvailableAmount,
        };
      });
      return {
        couponsTableData,
        totalCostOfCouponsInCart,
        totalOldCostOfCouponsInCart,
        totalDiscountOfCouponsInCart,
        totalPromoDiscountOfCouponsInCart,
        totalVatOfCouponsInCart,
      };
    }
    return null;
  }, [previousData, data, loading, userData]);

  return {
    cartData,
    loading,
    removeCouponFromCart,
    removeLoading,
    setPromo: setPromocodeCallback,
    promoCodeError,
  };
};

export default useCartCoupons;
