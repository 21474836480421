import React, { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import s from './MobileProfile.module.scss';
import UserContext from '../Layout/context/userContext';
import Loader from '../Loader';
import useLogoutMutation from '../../hooks/useLogoutMutation/useLogoutMutation';

const MobileProfile = () => {
  const { userData, fetchUserLoading } = useContext(UserContext);

  const { logoutCallback, logoutLoading } = useLogoutMutation();

  const isNameSet = !!(userData?.firstName || userData?.lastName);

  const profileName = isNameSet ? `${userData?.firstName} ${userData?.lastName}` : 'Account';

  if (fetchUserLoading) return <Loader />;

  return (
    <div className={s.main}>
      {logoutLoading && <Loader />}
      <span className={s.name}>{profileName}</span>
      <div className={s.links}>
        <Link
          className={s.link}
          to="account"
        >
          Account Information
        </Link>
        <Link
          className={s.link}
          to="coupons"
        >
          My Coupons
        </Link>
        <span
          onClick={logoutCallback}
          className={s.link}
        >
          Logout
        </span>
      </div>
    </div>
  );
};

export default memo(MobileProfile);
