import React, { useEffect } from 'react';
import './App.less';
import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import { isTablet } from 'react-device-detect';
import AllRoutes from './components/Routes/AllRoutes';

const App = () => {
  useEffect(() => {
    const app = document.body;
    if (window.outerWidth <= 1460 && window.outerWidth > 1023) {
      app.classList.add('zoom-app');
    } else if (isTablet) {
      app.classList.add('zoom-tablet');
    }
  }, []);

  return (
    <BrowserRouter>
      <AllRoutes />
    </BrowserRouter>
  );
};

export default App;
