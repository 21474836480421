import { useMutation } from '@apollo/client';
import { UpdateCategoryPosition } from '../../../../../api/query';

const useUpdateCategoryPosition = () => {
  const [updateCategoryPosition, { data, loading, error }] = useMutation(UpdateCategoryPosition);

  const sendPosition = (id, position = 1) => updateCategoryPosition({
    variables: {
      updateCategoryPositionInput: {
        position: position - 1,
      },
      id,
    },
  });

  return {
    sendPosition,
    data,
    loading,
    errorPosition: error,
  };
};

export default useUpdateCategoryPosition;
