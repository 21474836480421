import React, { memo, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import PropTypes from 'prop-types';
import s from '../../Merchant/MerchantsMaking/BodyMerchant/FormMerchant/form-merchant.module.scss';
import getCountNumberInPhoneCountry from '../../../helpers/getCountNumberInPhoneCountry';

const PhoneInputComponent = ({
  stateInfo,
  setInfoFormHandler,
  allErrors,
  inputClass,
  flagAddClass,
}) => {
  const [isError, setError] = useState(false);

  const onChangeHandler = (value, country) => {
    const { format, dialCode } = country;
    if (stateInfo.dialCode !== dialCode) {
      setInfoFormHandler((prev) => ({
        ...prev,
        phone: dialCode,
        dialCode,
        countNumberInPhone: getCountNumberInPhoneCountry(format),
      }));
    } else {
      setInfoFormHandler((prev) => ({
        ...prev,
        phone: value,
        dialCode,
        countNumberInPhone: getCountNumberInPhoneCountry(format),
      }));
      setError(false);
    }
  };

  useEffect(() => {
    if (allErrors && allErrors.phone) {
      setError(true);
    }
  }, [allErrors]);

  return (
    <>
      <PhoneInput
        placeholder=""
        value={stateInfo?.phone || null}
        inputClass={inputClass}
        buttonClass={flagAddClass}
        buttonStyle={{
          border: isError
            ? '1px solid #FF0000FF' : '1px solid #EDEDED',
        }}
        inputStyle={{
          width: '100%',
          height: '40px',
          border: isError
            ? '1px solid #FF0000FF' : '1px solid #EDEDED',
          boxShadow: 'none',
        }}
        onChange={onChangeHandler}
        maxLength={stateInfo?.countNumberInPhone || 0}
        masks={{
          ae: '(...) ..-..-..',
          qa: '(...) ....-....',
          om: '........',
        }}
      />
      {isError && <span className={s.error}>Incorrect phone number!</span>}
    </>
  );
};
PhoneInputComponent.propTypes = {
  stateInfo: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setInfoFormHandler: PropTypes.func.isRequired,
  allErrors: PropTypes.oneOfType([PropTypes.object]),
  inputClass: PropTypes.string,
  flagAddClass: PropTypes.string,
};

PhoneInputComponent.defaultProps = {
  allErrors: undefined,
  inputClass: '',
  flagAddClass: 'FLAGS',
};

export default memo(PhoneInputComponent);
