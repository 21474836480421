export const defaultOptions = {
  panControl: true,
  zoomControl: false,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  clickableIcons: false,
  keyboardShortcuts: false,
  scrollwheel: true, // scale
  disableDoubleClickZoom: false,
  fullscreenControl: false,
};

export const containerStyle = {
  width: '100%',
  height: '100%',
};
