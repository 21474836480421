import { useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';
import { GET_CATEGORIES } from '../../../api/catalog-api/query';

const useMobileCatalog = () => {
  const { data = {}, loading: mobileCategoriesLoading } = useQuery(GET_CATEGORIES);

  const { getCategories = [] } = data;

  const [searchParams] = useSearchParams();
  const isAnyParamExist = [...searchParams].length > 0;

  const headerData = useMemo(() => {
    const params = Object.fromEntries([...searchParams]);
    const keys = Object.keys(params);
    if (getCategories.length > 0) {
      if (keys.findIndex((param) => param === 'categoryId') > -1) {
        const idByParams = params.categoryId;
        const { title } = (getCategories.length > 0 && getCategories.filter(({ id }) => id === idByParams))[0];
        return { title, backTitle: 'Catalog' };
      }
      if (keys.findIndex((param) => param === 'subCategoryId') > -1) {
        const idByParams = params.subCategoryId;
        const filteredSubCategories = getCategories
          .find(({ subCategories }) => subCategories.find(({ id }) => id === idByParams));
        const backTitle = getCategories
          .filter(({ subCategories }) => subCategories
            .find(({ id }) => id === idByParams))[0].title;
        const { title } = (filteredSubCategories.subCategories.find(({ id }) => id === idByParams));
        return { title, backTitle };
      }
    }
    return null;
  }, [getCategories, searchParams]);

  return useMemo(
    () => ({
      getCategories, mobileCategoriesLoading, headerData, isAnyParamExist,
    }),
    [getCategories, headerData, isAnyParamExist, mobileCategoriesLoading],
  );
};

export default useMobileCatalog;
