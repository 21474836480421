import React from 'react';
import PropTypes from 'prop-types';
import useStatus from './hooks/useStatus';
import { STATUS_BG_OPACITY_HEX } from '../../constants/statuses';

import style from './StatusTag.module.scss';

const StatusTag = ({ name }) => {
  const { color } = useStatus(name);
  return (
    <span
      className={style.tag}
      style={{ backgroundColor: `${color}${STATUS_BG_OPACITY_HEX}`, color }}
    >
      {name}
    </span>
  );
};

StatusTag.propTypes = {
  name: PropTypes.string.isRequired,
};

export default StatusTag;
