import React from 'react';
import Page from '../../components/Page';
import CouponTable from '../../components/Coupon/CouponTable/CouponTable';

const Coupons = () => (
  <Page>
    <CouponTable />
  </Page>
);

export default Coupons;
