import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './VoucherItem.module.scss';

const VoucherItem = ({
  activationDate, activationTime, id, title,
}) => (
  <div className={s.item}>
    <span className={s.title}>{title}</span>
    <div className={s.info}>
      <div>
        <span className={s.subtitle}>
          ID
        </span>
        <span>
          {id}
        </span>
      </div>
      <div>
        <span className={s.subtitle}>
          Activation
        </span>
        <span>
          {activationDate}
        </span>
      </div>
      <div>
        <span className={s.subtitle}>
          Time
        </span>
        <span>
          {activationTime}
        </span>
      </div>
    </div>
  </div>
);

VoucherItem.propTypes = {
  id: PropTypes.string.isRequired,
  activationDate: PropTypes.string.isRequired,
  activationTime: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(VoucherItem);
