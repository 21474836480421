import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { GET_NOTIFICATION_SETTINGS, UPDATE_PRICE_NOTIFICATION_SETTINGS } from '../../../api/query';

const useNotificationSettings = () => {
  const { data, loading } = useQuery(GET_NOTIFICATION_SETTINGS);

  const priceUpdates = useMemo(() => {
    if (data) return data.getNotificationSettings.priceUpdates;
    return null;
  }, [data]);

  const [callback] = useMutation(UPDATE_PRICE_NOTIFICATION_SETTINGS, { refetchQueries: [GET_NOTIFICATION_SETTINGS] });

  const changePriceNotificationSettingsCallback = useCallback(async () => {
    try {
      await callback({ variables: { updateNotificationSettingsInput: { priceUpdates: !priceUpdates } } });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e.message);
    }
  }, [priceUpdates]);

  return { priceUpdates, changePriceNotificationSettingsCallback, loading };
};

export default useNotificationSettings;
