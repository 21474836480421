import React, { memo, useContext } from 'react';
import s from './ShareBlock.module.scss';
import {
  ShareFacebook, ShareMail, ShareTwitter, ShareWhatsapp,
} from '../../../constants/icons';
import MetaTagsDataComponent from '../../MetaTagsDataComponent';
import CouponContext from '../context/CouponContext';

const ShareBlock = () => {
  const { title, description, couponPreviewPicture: { key } } = useContext(CouponContext);

  const url = window.location.href;

  const imageUrl = `${process.env.REACT_APP_BUCKET_URL}/${key}`;

  return (
    <div className={s.social}>
      <MetaTagsDataComponent
        url={url}
        title={title}
        description={description}
        imageUrl={imageUrl}
      />
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        target="_blank"
        rel="noreferrer"
        className={s.icon}
      >
        <ShareFacebook />
      </a>
      <a
        href={`https://twitter.com/share?url=${url}`}
        target="_blank"
        rel="noreferrer"
        className={s.icon}
      >
        <ShareTwitter />
      </a>
      <a
        href={`https://api.whatsapp.com/send?text=${url}`}
        data-action="share/whatsapp/share"
        target="_blank"
        rel="noreferrer"
        className={s.icon}
      >
        <ShareWhatsapp />
      </a>
      <a
        href={`mailto:?body=${url}`}
        target="_blank"
        rel="noreferrer"
        className={s.icon}
      >
        <ShareMail />
      </a>
    </div>
  );
};
export default memo(ShareBlock);
