import React, { memo, useContext } from 'react';
import s from './PictureGallery.module.scss';
import CouponContext from '../context/CouponContext';
import SwiperComponent from './SwiperComponent';
import getCouponGalleryArray from '../../../helpers/couponPage/getCouponGallery';

const PictureGallery = () => {
  const {
    couponGalleryPictures,
    couponPreviewPicture,
    videoReviewUrl,
    keywordsImages,
  } = useContext(CouponContext);

  const images = getCouponGalleryArray(couponPreviewPicture, couponGalleryPictures);

  return (
    <div className={s.main}>
      <SwiperComponent
        keywordsImages={keywordsImages}
        images={images}
        videoUrl={videoReviewUrl}
      />
    </div>
  );
};

export default memo(PictureGallery);
