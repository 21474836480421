import React, { memo } from 'react';
import PropTypes from 'prop-types';
import s from './MobileCouponsTable.module.scss';
import MobileCartCouponItem from './MobileCartCouponItem';

const MobileCouponsTable = ({
  totalCartData, removeIconOnCLick, couponOrderHandler, optionOrderHandler,
}) => {
  const mappedItems = totalCartData && totalCartData.map(({
    cartId,
    shoppingCartCouponOptions,
    quantity,
    availableAmountInPeriod,
    currentPrice,
    oldPrice,
    deadline,
    title,
    couponPreviewPicture,
    promoCodeApplied,
  }) => (
    <MobileCartCouponItem
      key={cartId}
      quantity={quantity}
      availableAmountInPeriod={availableAmountInPeriod}
      cartId={cartId}
      title={title}
      currentPrice={currentPrice}
      shoppingCartCouponOptions={shoppingCartCouponOptions}
      deadline={deadline}
      oldPrice={oldPrice}
      previewKey={couponPreviewPicture.key}
      removeIconOnCLick={removeIconOnCLick}
      couponOrderHandler={couponOrderHandler}
      optionOrderHandler={optionOrderHandler}
      promoCodeApplied={promoCodeApplied}
    />
  ));

  return (
    <div className={s.main}>
      {mappedItems}
    </div>
  );
};

MobileCouponsTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  totalCartData: PropTypes.array,
  removeIconOnCLick: PropTypes.func.isRequired,
  couponOrderHandler: PropTypes.func.isRequired,
  optionOrderHandler: PropTypes.func.isRequired,
};

MobileCouponsTable.defaultProps = {
  totalCartData: [],
};

export default memo(MobileCouponsTable);
