import React, {
  createContext, memo, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';

export const ForceUpdateContext = createContext(null);

const ForceUpdateWrapper = ({ children }) => {
  const [isRerenderNeed, setIsRerenderNeed] = useState(false);

  const context = useMemo(() => ({ isRerenderNeed, setIsRerenderNeed }), [isRerenderNeed]);

  return (
    <ForceUpdateContext.Provider value={context}>
      {children}
    </ForceUpdateContext.Provider>
  );
};

ForceUpdateWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(ForceUpdateWrapper);
