import React from 'react';
import PropTypes from 'prop-types';

import style from './Item.module.scss';

const Item = ({ label, children }) => (
  <>
    <span className={style.label}>{label}</span>
    <div className={style.children}>
      { children }
    </div>
  </>
);

Item.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Item;
