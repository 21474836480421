import React, { memo } from 'react';
import PropTypes from 'prop-types';
import MobileCouponPath from '../MobileCouponPath';
import PictureGallery from '../PictureGallery';
import CustomerBlock from '../CustomerBlock';
import VoucherDetails from '../VoucherDetails';
import TextBlock from '../TextBlock';
import s from '../CouponPage.module.scss';
import mobileCondition from '../../../constants/mobileCondition';
import LocationBlock from '../LocationBlock';
import ShareBlock from '../ShareBlock';

const MobileCouponPage = ({
  addCouponToLocalCart, description, title, terms, locations, titleTargetLinkForMap,
}) => {
  const containerClassName = `${s.container} ${mobileCondition && s.mobile}`;

  const headerClassName = `${s.header} ${mobileCondition && s.mobile}`;

  const detailsClassName = `${s.details} ${mobileCondition && s.mobile}`;

  return (
    <div className={containerClassName}>
      <MobileCouponPath />
      <PictureGallery />
      <div className={detailsClassName}>
        <div className={headerClassName}>
          {title}
        </div>
        <CustomerBlock addCouponToLocalCart={addCouponToLocalCart} />
        <VoucherDetails />
        {description && (
          <TextBlock
            title="OFFER HIGHLIGHTS"
            text={description}
          />
        )}
        {terms && (
          <TextBlock
            title="THE FINE PRINT"
            text={terms}
          />
        )}
        <LocationBlock
          locations={locations}
          typeDevice="mobile"
          titleTargetLinkForMap={titleTargetLinkForMap}
        />
        <TextBlock title="Share this deal">
          <ShareBlock />
        </TextBlock>
      </div>
    </div>
  );
};

MobileCouponPage.propTypes = {
  title: PropTypes.string.isRequired,
  terms: PropTypes.string,
  description: PropTypes.string,
  addCouponToLocalCart: PropTypes.func.isRequired,
  locations: PropTypes.oneOfType([PropTypes.array]),
  titleTargetLinkForMap: PropTypes.string.isRequired,
};

MobileCouponPage.defaultProps = {
  terms: null,
  description: null,
  locations: null,
};

export default memo(MobileCouponPage);
