export const MAIN = '/';
export const MOBILE_CATALOG = '/catalog';
export const COUPON_PAGE = '/coupon/:couponId';
export const CART = '/cart';

export const SIGN_IN = '/admin/signIn';
export const FORGOT_PASSWORD = 'forgot-password';
export const CHECK_EMAIL = 'check-email';
export const RESET_PASSWORD = 'reset-password/:id';

export const ADMIN = 'admin';
export const MERCHANTS = 'merchants';
export const CREATE_MERCHANT = 'merchants/create-merchant';
export const EDIT_MERCHANT_BY_ID = 'merchants/edit-merchant/:id';
export const COUPONS = 'coupons';
export const CREATE_COUPON = 'coupons/create-coupon';
export const EDIT_COUPON_BY_ID = 'coupons/edit-coupon/:id';
export const BUYERS = 'buyers';
export const ORDERS = 'orders';
export const VOUCHERS = 'vouchers';
export const REPORTS = 'reports';
export const LOGS = 'logs';
export const BANNERS = 'banners';
export const PROMO_CODES = 'promocodes';
export const COMMON_SETTINGS = 'settings';
export const CATEGORIES = 'categories';
export const EMPLOYEES = 'employees';
export const PROFILE_MERCHANT = 'profile-merchant';

export const ERROR_PAGE = '/error';

export const COMMON = '/common';
export const CONTACTS = 'contacts';
export const TERMS = 'terms';
export const COOKIES_INFO = 'cookies';
export const BLOG = 'blog';
export const FAQ = 'faq';

export const SITE_SIGN_IN = 'sign-in';
export const SITE_SIGN_UP = 'sign-up';
export const AUTH = '/auth';

export const TEMP_MAIN = '/main';

export const PROFILE = '/profile';
export const ACCOUNT = 'account';
export const COUPONS_PROFILE = 'coupons';
export const SHOW_INFO = 'show-info';
export const EDIT_INFO = 'edit-info';
export const EDIT_PASSWORD = 'edit-password';

export const CHECKOUT = 'checkout';
export const CHECKOUT_SUCCESS = 'success/:id';
export const CHECKOUT_FAILED = 'failed/:id';
export const CHECKOUT_SIGN_UP = 'sign-up';
export const CHECKOUT_SIGN_IN = 'sign-in';

export const SECURITY = '/security';
export const SECURITY_FAILED = '/security/failed';
export const SECURITY_SUCCESS = '/security/success';
export const SECURITY_AUTH = 'auth';
