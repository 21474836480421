import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Sidebar from '../Sidebar';
import style from './Main.module.css';
import { getStoredUser } from '../../helpers/user-storage';
import { SIGN_IN } from '../../constants/Routes';

const Main = () => {
  const isAuth = getStoredUser();
  if (!isAuth) {
    return <Navigate to={SIGN_IN} />;
  }
  return (
    <div className={style.main}>
      <Sidebar />
      <Outlet />
    </div>
  );
};

export default Main;
