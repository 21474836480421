import React, { memo, useState } from 'react';
import { Divider, Typography } from 'antd';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import s from '../Summary.module.scss';
import { ArrowUp, Cross } from '../../../../constants/icons';
import Modal from '../../../Modal';
import mobileCondition from '../../../../constants/mobileCondition';

const MobileSummary = ({
  totalWithPromo, payButtonCallback, promoCode, promoDiscount, promoCodeError, totalCost, totalVat, setPromo,
  setPromoCode, totalOriginalPrice, totalWithoutVat, totalSaved, loadingCreateOrder,
}) => {
  const [isActive, setIsActive] = useState(false);

  const applyClassName = `${s.apply} ${mobileCondition && s.mobile}`;

  const summaryContentClassName = `${s.summaryContent} ${mobileCondition && s.mobile}`;

  const applyPromoCodeHandler = () => {
    setPromo(promoCode);
  };

  const changePromoCode = (e) => {
    setPromoCode(e.target.value);
  };

  return (
    <>
      <div
        onClick={() => setIsActive(true)}
        className={s.mobileTotal}
      >
        <div className={s.mobileCost}>
          <span className={s.subtitle}>Cart total</span>
          <div className={s.costWithIcon}>
            <span className={s.cost}>
              {totalCost}
              , AED
            </span>
            <div className={s.icon}>
              <ArrowUp />
            </div>
          </div>
        </div>
        <button
          disabled={loadingCreateOrder}
          onClick={payButtonCallback}
        >
          { loadingCreateOrder
            ? <LoadingOutlined color="#333333" />
            : 'Pay'}
        </button>
      </div>
      <Modal
        isActive={isActive}
        hideModalHandler={() => setIsActive(false)}
      >
        <div className={s.summaryTitle}>
          <span>Cart summary</span>
          <div
            onClick={() => setIsActive(false)}
            className={s.closeIcon}
          >
            <Cross />
          </div>
        </div>
        <div className={summaryContentClassName}>
          <div className={s.form}>
            <input
              className={`${s.input} ${promoCodeError && s.inputError}`}
              placeholder="Insert Promo code here"
              value={promoCode}
              onChange={changePromoCode}
              onKeyUp={(e) => {
                if (e.key === 'Enter') applyPromoCodeHandler();
              }}
            />
            <button
              className={applyClassName}
              onClick={applyPromoCodeHandler}
            >
              Apply
            </button>
          </div>
          <div className={s.prices}>
            {promoCodeError && (
              <Typography.Text
                type="danger"
                style={{ fontSize: '12px', marginLeft: '8px' }}
              >
                {promoCodeError}
              </Typography.Text>
            )}
            <div>
              <Typography.Text type="secondary">Original price</Typography.Text>
              <Typography.Text strong>
                {totalOriginalPrice}
                {' '}
                AED
              </Typography.Text>
            </div>
            <div>
              <Typography.Text type="secondary">YB price</Typography.Text>
              <Typography.Text strong>
                {totalWithoutVat}
                {' '}
                AED
              </Typography.Text>
            </div>
            {promoDiscount > 0 && (
              <div>
                <Typography.Text type="secondary">Promo code</Typography.Text>
                <Typography.Text strong>
                  {promoDiscount}
                  {' '}
                  AED
                </Typography.Text>
              </div>
            )}
            <div>
              <Typography.Text type="secondary">Total</Typography.Text>
              <Typography.Text strong>
                {totalWithPromo}
                {' '}
                AED
              </Typography.Text>
            </div>
            <div>
              <Typography.Text type="secondary">VAT</Typography.Text>
              <Typography.Text strong>
                {totalVat}
                {' '}
                AED
              </Typography.Text>
            </div>
          </div>
          <Divider className={s.divider} />
          <div className={s.submit}>
            <div>
              <Typography.Text type="secondary">Cart total</Typography.Text>
              <Typography.Text strong>
                {totalCost}
                {' '}
                AED
              </Typography.Text>
            </div>
            <div>
              <Typography.Text type="secondary">Congrats! you saved</Typography.Text>
              <Typography.Text strong>
                {totalSaved}
                {' '}
                AED
              </Typography.Text>
            </div>
          </div>
        </div>
      </Modal>

    </>
  );
};

MobileSummary.propTypes = {
  promoCode: PropTypes.string.isRequired,
  setPromoCode: PropTypes.func.isRequired,
  promoCodeError: PropTypes.string,
  promoDiscount: PropTypes.number.isRequired,
  totalOriginalPrice: PropTypes.number.isRequired,
  totalWithPromo: PropTypes.number.isRequired,
  totalVat: PropTypes.number.isRequired,
  totalCost: PropTypes.number.isRequired,
  payButtonCallback: PropTypes.func.isRequired,
  setPromo: PropTypes.func.isRequired,
  totalWithoutVat: PropTypes.number.isRequired,
  totalSaved: PropTypes.number.isRequired,
  loadingCreateOrder: PropTypes.bool.isRequired,
};

MobileSummary.defaultProps = {
  promoCodeError: null,
};

export default memo(MobileSummary);
