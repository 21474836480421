import React, { memo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useVoucherDownload from '../Profile/hooks/gql/useVoucherDownload';

const VoucherRoute = () => {
  const { voucherId } = useParams();

  const { downloadAllVouchers, downloadVoucherInFile } = useVoucherDownload();

  const downloadVoucherCallback = useCallback(async (id) => {
    const file = await downloadAllVouchers(id);
    await downloadVoucherInFile([file]);
  }, []);

  useEffect(async () => {
    if (voucherId) {
      await downloadVoucherCallback(voucherId);
    }
  }, []);
  return (
    <div />
  );
};

export default memo(VoucherRoute);
