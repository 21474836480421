import React, { memo } from 'react';
import { Checkbox, Form } from 'antd';
import './updatePriceNotifications.scss';
import Loader from '../../../Loader';
import useNotificationSettings from '../../hooks/useGetNotificationSettings';

const UpdatePriceNotifications = () => {
  const { priceUpdates, changePriceNotificationSettingsCallback, loading } = useNotificationSettings();

  if (loading) return <Loader />;

  return (
    <div className="profile-notification">
      <Form>
        <Form.Item
          name="sendNotification"
          label="Send notifications about Percetage/Sum changes by Email"
          className="profile-merchant-notification"
        >
          <Checkbox
            name="notification"
            checked={priceUpdates}
            onChange={changePriceNotificationSettingsCallback}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default memo(UpdatePriceNotifications);
