import React, { memo } from 'react';
import { Switch } from 'antd';
import PropTypes from 'prop-types';

const ActiveBlock = (props) => {
  const { isActive, setPromoCodeInfoHandler } = props;

  return (
    <>
      <Switch
        checked={isActive}
        onChange={() => setPromoCodeInfoHandler('isActive', !isActive)}
        size="small"
      />
      <span>{isActive ? 'Active' : 'Deactivated'}</span>
    </>
  );
};

ActiveBlock.propTypes = {
  setPromoCodeInfoHandler: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};
ActiveBlock.defaultProps = {
  isActive: undefined,
};

export default memo(ActiveBlock);
