import React, {
  memo, useCallback, useContext, useState,
} from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import s from './CustomerBlock.module.scss';
import PriceBlock from '../PriceBlock';
import Options from '../Options';
import useTotalOrder from '../hooks/useTotalOrder';
import OrderContext from './orderContext';
import CouponContext from '../context/CouponContext';
import { ADD_COUPON_TO_CART } from '../../../api/coupon-page-api/query';
import Loader from '../../Loader';
import { isUserStored } from '../../../helpers/user-storage';
import mobileCondition from '../../../constants/mobileCondition';
import MobileOptions from '../MobileOptions';
import UserContext from '../../Layout/context/userContext';
import AddToCartButton from '../../Common_components/AddToCartButton';
import useBuyNow from '../hooks/useBuyNow';
import { GET_COUPONS_IN_CART_COUNT } from '../../../api/cart-api/query';
import { clearLocalCartData } from '../../../helpers/cart-helpers/local-storage';
import OutOfStockModal from '../../Cart/OutOfStockModal';
import { setCouponOrder, setIsByNow } from '../../../helpers/order-storage';

const CustomerBlock = ({ addCouponToLocalCart }) => {
  const { buyNowCallback, buyLoading } = useBuyNow();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [isActive, setIsActive] = useState(false);

  const hideModal = () => setIsActive(false);

  const {
    id, couponOptions, couponDataRefetch, currentAvailableAmount,
  } = useContext(CouponContext);

  const { setSignUpActive, setProcessingCallback } = useContext(UserContext);

  const costContext = useTotalOrder(id, couponOptions);

  const { totalOrder, resetOrder } = costContext;

  const [addToCart, { loading }] = useMutation(ADD_COUPON_TO_CART, {
    refetchQueries: [GET_COUPONS_IN_CART_COUNT],
  });

  const addCouponToCartOnClick = () => {
    if (totalOrder.amount > 0) {
      if (isUserStored()) {
        addToCart({
          variables: { shoppingCartCouponInput: totalOrder },
        });
      } else {
        addCouponToLocalCart(totalOrder);
      }
    }
  };

  const [stockData, setStockData] = useState([{ id, amount: currentAvailableAmount }]);

  const buyModalCallback = useCallback(async () => {
    const fixedOrder = { ...totalOrder, amount: currentAvailableAmount };
    setCouponOrder(fixedOrder);
    await buyNowCallback(fixedOrder);
  }, [currentAvailableAmount]);

  const buyButtonOnClick = useCallback(async () => {
    const checkCouponAmount = async () => {
      const res = await couponDataRefetch();
      const { findCouponById: { currentAvailableAmount } } = res.data;
      const { amount } = totalOrder;
      if (currentAvailableAmount >= amount) {
        setIsByNow(true);
        setCouponOrder(totalOrder);
        await buyNowCallback(totalOrder);
      } else {
        setStockData([{ id, amount: currentAvailableAmount }]);
        setIsActive(true);
      }
    };
    if (isUserStored()) {
      await checkCouponAmount();
    } else if (mobileCondition) {
      navigate('/auth', { state: pathname });
      setProcessingCallback(checkCouponAmount);
    } else {
      clearLocalCartData();
      setProcessingCallback(checkCouponAmount);
      setSignUpActive();
    }
  }, [totalOrder]);

  const customerClassName = `${s.customer} ${mobileCondition && s.mobile}`;

  return (
    <OrderContext.Provider value={costContext}>
      {(loading) && <Loader />}
      <div className={customerClassName}>
        <PriceBlock />
        {couponOptions.length > 0 && (mobileCondition ? <MobileOptions /> : <Options />)}
        <div className={s.buttons}>
          <button
            disabled={buyLoading}
            onClick={buyButtonOnClick}
            className={s.buy}
          >
            {buyLoading ? <LoadingOutlined color="#333333" /> : 'Buy now'}
          </button>
          <AddToCartButton addToCartCallback={addCouponToCartOnClick} />
        </div>
      </div>
      <OutOfStockModal
        isActive={isActive}
        hideModalHandler={hideModal}
        isInCouponPage
        stockData={stockData}
        isActionLoading={buyLoading}
        cancelButtonAction={resetOrder}
        buyButtonCallback={buyModalCallback}
      />
    </OrderContext.Provider>
  );
};

CustomerBlock.propTypes = {
  addCouponToLocalCart: PropTypes.func.isRequired,
};

export default memo(CustomerBlock);
